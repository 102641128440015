export const newsConstants = {
  NEWS_CATEGORY_ALL: '',
  NEWS_CATEGORY_OBJECT: 'Gebäude',
  NEWS_CATEGORY_CONTRACT: 'Vertrag',

  NEWS_DELETE_ERROR: 'NEWS_DELETE_ERROR',
  NEWS_DELETE_REQUEST: 'NEWS_DELETE_REQUEST',
  NEWS_DELETE_SUCCESS: 'NEWS_DELETE_SUCCESS',

  NEWS_GET_ALL_ERROR: 'NEWS_GET_ALL_ERROR',
  NEWS_GET_ALL_REQUEST: 'NEWS_GET_ALL_REQUEST',
  NEWS_GET_ALL_SUCCESS: 'NEWS_GET_ALL_SUCCESS',

  NEWS_GET_ALL_ALERTS_ERROR: 'NEWS_GET_ALL_ALERTS_ERROR',
  NEWS_GET_ALL_ALERTS_REQUEST: 'NEWS_GET_ALL_ALERTS_REQUEST',
  NEWS_GET_ALL_ALERTS_SUCCESS: 'NEWS_GET_ALL_ALERTS_SUCCESS',

  NEWS_GET_ALL_TYPES_ERROR: 'NEWS_GET_ALL_TYPES_ERROR',
  NEWS_GET_ALL_TYPES_REQUEST: 'NEWS_GET_ALL_TYPES_REQUEST',
  NEWS_GET_ALL_TYPES_SUCCESS: 'NEWS_GET_ALL_TYPES_SUCCESS',

  NEWS_GET_BY_ID_ERROR: 'NEWS_GET_BY_ID_ERROR',
  NEWS_GET_BY_ID_REQUEST: 'NEWS_GET_BY_ID_REQUEST',
  NEWS_GET_BY_ID_SUCCESS: 'NEWS_GET_BY_ID_SUCCESS',

  NEWS_CONTENT_REQUIRED: 'Titel und Inhalt erforderlich',
  NEWS_DELETED: 'News gelöscht',
  NEWS_FOLDER_REQUIRED: 'Gebäude oder Vertrag erforderlich',
  NEWS_ID_REQUIRED: 'ID erforderlich',
  NEWS_SAVED: 'News gespeichert',
  NEWS_UPDATED: 'News aktualisiert',

  NEWS_SAVE_ERROR: 'NEWS_SAVE_ERROR',
  NEWS_SAVE_REQUEST: 'NEWS_SAVE_REQUEST',
  NEWS_SAVE_SUCCESS: 'NEWS_SAVE_SUCCESS',

  NEWS_TYPE_ANY: '',
  NEWS_TYPE_TECH: 'technisch',
  NEWS_TYPE_LEGAL: 'rechtlich',
  NEWS_TYPE_BUSINESS: 'kaufmännisch',

  NEWS_UPDATE_ERROR: 'NEWS_UPDATE_ERROR',
  NEWS_UPDATE_REQUEST: 'NEWS_UPDATE_REQUEST',
  NEWS_UPDATE_SUCCESS: 'NEWS_UPDATE_SUCCESS',
}
