import { history } from '../api'
import { notificationConstants as NC } from '../constants'
import { notificationService as NS, tokenService as TS } from '../services'
import { alertActions as AA } from '.'

const getAll = () => {
  const failure = error => ({ type: NC.GET_ALL_ERROR, error })
  const request = () => ({ type: NC.GET_ALL_REQUEST })
  const success = notifications => ({ type: NC.GET_ALL_SUCCESS, notifications })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      NS.getAll()
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(`Notifications ${message}`))
        })
    )
  }
}

const requestData = data => {
  const { email, folderID, percent, sensorDeviceID } = data

  return { data: { attributes: { sensorDeviceID, folderID, email, percent } } }
}

const remove = id => {
  const failure = error => ({ type: NC.REMOVE_NOTIFICATION_ERROR, error })
  const request = () => ({ type: NC.REMOVE_NOTIFICATION_REQUEST })
  const success = notifications => ({
    type: NC.REMOVE_NOTIFICATION_SUCCESS,
    notifications,
  })

  return dispatch => {
    if (!id) return dispatch(AA.error(NC.NOTIFICATION_ID_REQUIRED))

    dispatch(request())

    NS.remove(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(NC.NOTIFICATION_DELETED))

        history.push('/notifications')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(`Notifications ${message}`))
      })
  }
}

const save = data => {
  const { folder } = data

  return dispatch => {
    if (!folder) return dispatch(AA.error(NC.NOTIFICATION_FOLDER_REQUIRED))

    dispatch(saveData(data))
  }
}

const saveData = data => {
  const failure = error => ({ type: NC.SAVE_NOTIFICATION_ERROR, error })
  const request = () => ({ type: NC.SAVE_NOTIFICATION_REQUEST })
  const success = notification => ({
    type: NC.SAVE_NOTIFICATION_SUCCESS,
    notification,
  })

  return dispatch => {
    dispatch(request())

    NS.save(requestData(data))
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(NC.NOTIFICATION_SAVED))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(`Notifications ${message}`))
      })
  }
}

export const notificationActions = {
  getAll,
  remove,
  save,
}
