import { API } from 'aws-amplify'
import axios from 'axios'
import { authHeader } from '../api'

const API_NAME = 'karmaApi'

const { REACT_APP_API_URL } = process.env

const getAllPayload = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/system/payload`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('systemService.getAllPayload', error)
  }
}

const getAppVersion = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/system/appversion`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('systemService.getAppVersion', error)
  }
}

const getIsInMaintenance = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/system/maintenance/mode`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('systemService.getIsInMaintenance', error)
  }
}

export const setIsInMaintenance2 = async mode => {
  const config = {
    body: { mode },
    headers: authHeader(),
    method: 'POST',
    url: `${REACT_APP_API_URL}/system/maintenance/mode`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('systemService.setIsInMaintenance', error)
  }
}

const setIsInMaintenance = async mode => {
  const endpoint = `/system/maintenance/mode`
  const options = {
    body: { mode },
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

const getMaintenanceText = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/system/maintenance/message`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('systemService.getMaintenanceText', error)
  }
}

export const setMaintenanceText2 = async message => {
  const config = {
    body: { message },
    headers: authHeader(),
    method: 'POST',
    url: `${REACT_APP_API_URL}/system/maintenance/message`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('systemService.setMaintenanceText', error)
  }
}

const setMaintenanceText = async message => {
  const endpoint = `/system/maintenance/message`
  const options = {
    body: { message },
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

export const systemService = {
  getAllPayload,
  getAppVersion,
  getIsInMaintenance,
  setIsInMaintenance,
  getMaintenanceText,
  setMaintenanceText,
}
