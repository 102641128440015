// modules
import { Avatar, IconButton, MenuItem } from '@mui/material'
import {
  AddTwoTone,
  AnnouncementTwoTone,
  CalendarTodayTwoTone,
  CategoryTwoTone,
  DeleteTwoTone,
  FaceTwoTone,
  HomeWorkTwoTone,
  MoreVertTwoTone,
  SaveTwoTone,
  TitleTwoTone,
  ViewHeadlineTwoTone,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
// scripts
import { newsActions } from '../../actions'
import {
  AM,
  AMItem,
  KPage,
  KSelect,
  KTextField,
  Preloader,
} from '../../components'
import {
  useContractPool,
  useFolderPool,
  useNews,
  useNewsTypes,
} from '../../hooks'

export const NewsEdit = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const editMode = id ? true : false

  const {
    data: contractPool,
    isLoading: isContractPoolLoading,
  } = useContractPool()

  const { data: folderPool } = useFolderPool()
  const { data: newsTypes } = useNewsTypes()

  const [actionMenu, setActionMenu] = useState(null)

  const [title, setTitle] = useState('')
  const [headline, setHeadline] = useState('')
  const [author, setAuthor] = useState('')
  const [content_text, setContent_text] = useState('')
  const [start_time, setStart_time] = useState('')

  const [news_type_id, setNews_type_id] = useState('')
  const [news_type_idSelect, setNews_type_idSelect] = useState(null)

  const [folder, setFolder] = useState()
  const [contract, setContract] = useState()

  const {
    data: newsData,
    isError: newsError,
    isLoading: newsLoading,
  } = useNews(id)

  useEffect(
    () => {
      if (editMode && newsData) {
        const {
          author,
          content_text,
          contracts,
          folder_id,
          headline,
          label,
          news_type_id,
          start_time,
          title,
        } = newsData

        setTitle(title)
        setHeadline(headline)
        setAuthor(author)
        setContent_text(content_text)
        setStart_time(start_time)
        setNews_type_id(news_type_id)
        setNews_type_idSelect({ value: news_type_id, label: label })

        if (folder_id)
          setFolder(
            folderPool.filter(f => f.id === String(folder_id)).map(item => {
              const { name, name_short } = item.attributes

              return {
                value: item.id,
                label: name + (name_short ? ' (' + name_short + ')' : ''),
              }
            })
          )

        if (contracts)
          setContract(
            contracts.map(contract => ({
              value: contract.entity_id,
              label: contract.name,
            }))[0]
          )
      }
    },
    [newsData, folderPool, editMode]
  )

  const onSubmit = action => {
    const record = {
      author,
      content_text,
      contract_id: [contract],
      folder_id: (folder && folder.value) || null,
      headline,
      news_type_id,
      start_time,
      title,
    }

    switch (action) {
      case 'save':
        dispatch(newsActions.save(record))
        break

      case 'update':
        dispatch(newsActions.update(id, record))
        break

      case 'delete':
        dispatch(newsActions.remove(id, folder.value))
        break

      default:
        console.error('unhandled action', action)
        break
    }
  }

  const isPageLoading = (editMode && newsLoading) || isContractPoolLoading
  const isPageError = editMode && newsError

  return (
    <>
      {/* preloader */}
      <Preloader
        caption={'News laden ...'}
        error={isPageError}
        isLoading={isPageLoading}
      />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'News'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'news'}
      >
        <AMItem
          caption={'Neu'}
          icon={<AddTwoTone />}
          onClick={() => history.push('/news/new')}
        />

        {editMode && (
          <AMItem
            caption={'Speichern'}
            icon={<SaveTwoTone />}
            onClick={() => onSubmit('update')}
          />
        )}

        <AMItem
          disabled={!editMode}
          caption={'Löschen'}
          icon={<DeleteTwoTone />}
          onClick={() => onSubmit('delete')}
        />

        {!editMode && (
          <AMItem
            caption={'Speichern'}
            icon={<SaveTwoTone />}
            onClick={() => onSubmit('save')}
          />
        )}
      </AM>
      {/* action menu end */}

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={event => setActionMenu(event.currentTarget)}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar>
              <AnnouncementTwoTone />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={'News bearbeiten'}
          subheader={title.length === 0 ? 'Neue News' : title}
        >
          {/* Titel */}
          <KTextField
            error={!title || title === ''}
            icon={<TitleTwoTone />}
            label={'Titel'}
            onChange={event => setTitle(event.target.value)}
            required
            value={title || ''}
          />
          {/* Headline */}
          <KTextField
            icon={<TitleTwoTone />}
            label={'Headline'}
            onChange={event => setHeadline(event.target.value)}
            value={headline || ''}
          />
          {/* Rubrik */}
          <KSelect
            icon={<CategoryTwoTone />}
            label={'Rubrik'}
            onChange={event => {
              setNews_type_idSelect({
                label: event.target.label,
                value: event.target.value,
              })
            }}
            value={(news_type_idSelect && news_type_idSelect.value) || ''}
          >
            {newsTypes.map((item, key) => {
              const { name } = item.attributes

              return (
                <MenuItem key={key} value={item.id}>
                  {name}
                </MenuItem>
              )
            })}
          </KSelect>
          {/* Author */}
          <KTextField
            icon={<FaceTwoTone />}
            label={'Autor'}
            onChange={event => setAuthor(event.target.value)}
            value={author || ''}
          />
          {/* Release */}
          <KTextField
            icon={<CalendarTodayTwoTone />}
            label={'Release'}
            onChange={event => setStart_time(event.target.value)}
            type={'datetime-local'}
            value={start_time || ''}
          />
          {/* Inhalt */}
          <KTextField
            icon={<ViewHeadlineTwoTone />}
            label={'Inhalt'}
            maxRows={30}
            multiline
            onChange={event => setContent_text(event.target.value)}
            value={content_text}
          />
          {/* Gebäude */}
          <KSelect
            icon={<HomeWorkTwoTone />}
            label={'Gebäude'}
            onChange={event => setFolder(event.target)}
            value={(folder && folder.value) || ''}
          >
            <MenuItem value={''}>Bitte wählen ...</MenuItem>
            {folderPool.map((item, key) => {
              const { name, name_short } = item.attributes

              return (
                <MenuItem key={key} value={item.id}>
                  {name} {name_short && ` (${name_short})`}
                </MenuItem>
              )
            })}
          </KSelect>
          {/* Vertrag */}
          <KSelect
            icon={<HomeWorkTwoTone />}
            label={'Vertrag'}
            onChange={event => setContract(event.target)}
            value={(contract && contract.value) || ''}
          >
            <MenuItem value={''}>Bitte wählen ...</MenuItem>
            {contractPool.map((item, key) => {
              const { name } = item.attributes

              return (
                <MenuItem key={key} value={item.id}>
                  {name}
                </MenuItem>
              )
            })}
          </KSelect>
        </KPage>
      )}
    </>
  )
}
