import { Typography } from '@mui/material'
import React from 'react'

export const FilterByStatus = row => (
  <Typography
    sx={{
      color:
        row.value === 'green'
          ? '#85cc00'
          : row.value === 'yellow'
            ? '#ffbf00'
            : '#ff2e00',
    }}
  >
    {row.value}
  </Typography>
)
