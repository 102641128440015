import { MemoryTwoTone } from '@mui/icons-material'
import { Avatar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { missingDataActions } from '../../actions'
import { userLocales } from '../../api'
import { KPage, Preloader } from '../../components'
import { useOperator } from '../../hooks'

export const MissingData = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const { data: operatorData, loading: operatorLoading } = useOperator()
  const { oneMissingData } = useSelector(state => state.missingData)
  const [missing, setMissing] = useState({ items: [], isLoading: true })

  useEffect(
    () => {
      if (!operatorLoading && !operatorData?.isAdmin) history.push('/')
    },
    [history, operatorData, operatorLoading]
  )

  useEffect(
    () => {
      if (operatorData?.isAdmin) dispatch(missingDataActions.getById(id))
    },
    [dispatch, id, operatorData]
  )

  useEffect(
    () => {
      if (!oneMissingData) return
      const { error, item, loading } = oneMissingData

      if (item) setMissing({ items: item, isLoading: loading })

      if (error) console.error(error)
    },
    [oneMissingData]
  )

  // Take items from dispatch and put them into an array as an timestamp
  const dateArray = []
  missing.items.forEach(dates => {
    if (dates.days)
      dateArray.push(dates.years + '-' + dates.months + '-' + dates.days)
  })

  // Create a second array with all the days between the first day of sensor until now
  const getDaysArray = (start, end) => {
    const arr = []

    for (let dt = start; dt <= end; dt.setDate(dt.getDate() + 1))
      arr.push(new Date(dt))

    return arr
  }

  let daylist = getDaysArray(new Date(dateArray[0]), new Date())

  // Iterate trough array of days to make them a string again
  let i = 0

  daylist.forEach(day => {
    const dateString = day.toString()

    daylist[i++] = dateString
  })

  // Iterate trough array of days from db and check if the day is in the array of days, if yes delete it from array
  dateArray.forEach(date => {
    const dateString = new Date(date).toString()

    if (daylist.includes(dateString))
      daylist = daylist.filter(f => f !== dateString)
  })

  const firstRecord = new Date(dateArray[0]).toLocaleString(userLocales)

  daylist.reverse()

  const isPageLoading = missing.isLoading

  return (
    <>
      {/* preloader */}
      <Preloader isLoading={isPageLoading} />
      {/* preloader end */}

      {!isPageLoading && (
        <KPage
          avatar={
            <Avatar>
              <MemoryTwoTone />
            </Avatar>
          }
          title={`Sensor ID: ${id} Fehlende Messtage`}
        >
          <Typography>
            Seit {firstRecord} fehlen <strong>{daylist.length}</strong>{' '}
            Einträge.
          </Typography>

          <Typography>
            An diesen Tagen hat SensorID {id} nicht gesendet:
          </Typography>

          <ul>
            {daylist.map(day => (
              <li key={day}>
                {day
                  .toLocaleString(userLocales)
                  .slice(0, 15)
                  .replace(',', '')}
              </li>
            ))}
          </ul>
        </KPage>
      )}
    </>
  )
}
