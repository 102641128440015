import { API } from 'aws-amplify'
import axios from 'axios'
import { authHeader } from '../api'

const API_NAME = 'karmaApi'

const { REACT_APP_API_URL } = process.env

const ERR_MISSING_CONTRACT = new Error('Kein Vertrag gewählt')
const ERR_INVALID_CONTRACT = new Error('Ungültiger Vertrag')

const getAll = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/contracts/admin/contracts`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('CONTRACTSERVICE::GETALL', error)
    const { config, message, request, response } = error

    if (response) {
      const { headers, data, status } = response
      // request was made; server responded with status code other than 2xx
      console.log(data)
      console.log(status)
      console.log(headers)
    } else if (request) {
      // request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in browser and an instance of http.ClientRequest in node.js
      console.log(request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', message)
    }

    console.log(config)
  }
}

const getById = async (id, includes = []) => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_CONTRACT)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_CONTRACT)

  let request = id
  if (includes.length > 0) {
    request += '?include='
    includes.forEach(val => {
      request += encodeURIComponent(val)
      request += ','
    })
  }

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/contracts/admin/contracts/${request}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('contractsService.getById', error)
  }
}

const remove = async id => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/contracts/admin/contracts/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('contractsService.remove', error)
  }
}

const save = async body => {
  const endpoint = `/contracts/admin/contracts`
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'POST',
  }

  return await API.post(API_NAME, endpoint, options)
}

export const save2 = async body => {
  const config = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'POST',
    url: `${REACT_APP_API_URL}/contracts/admin/contracts`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('contractsService.save', error)
  }
}

const update = async (id, body) => {
  const endpoint = `/contracts/admin/contracts/${id}`
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

export const update2 = async (id, body) => {
  const config = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    url: `${REACT_APP_API_URL}/contracts/admin/contracts/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('contractsService.update', error)
  }
}

export const contractService = {
  getAll,
  getById,
  remove,
  save,
  update,
}
