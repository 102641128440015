// modules
import { AddTwoTone } from '@mui/icons-material'
import {
  Collapse,
  Container,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
// scripts
import { setStorage, getStorage } from '../../api'
import { Preloader } from '../../components'
import {
  useDashboardPool,
  useFolderPool,
  useSensorPool,
  useSensorPricePool,
} from '../../hooks'
import { DashboardComponent } from './components'
import { DialogCreateDashboard } from './components/DialogCreateDashboard'

export const Dashboards = () => {
  const {
    data: folderPool,
    isLoading: isFolderPoolLoading,
    isError: isFolderPoolError,
  } = useFolderPool()

  const {
    data: sensorPool,
    isLoading: isSensorPoolLoading,
    isError: isSensorPoolError,
  } = useSensorPool()

  const {
    data: dashboardPool,
    isLoading: isDashboardPoolLoading,
    isError: isDashboardPoolError,
  } = useDashboardPool()

  const { data: sensorPricePool } = useSensorPricePool()

  const PAGE_CONFIG = 'settings_dashboards'
  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  const [expanded, setExpanded] = useState(config.tab)
  const [showCreateDialog, setShowCreateDialog] = useState(false)

  const toggleDialog = () => setShowCreateDialog(prev => !prev)

  const handleChange = id => (_, isExpanded) => {
    // if expanded, set id to open/expand, close it otherwise
    setExpanded(isExpanded ? id : null)
    setConfig(prev => ({ ...prev, tab: id }))
  }

  useEffect(() => setStorage(PAGE_CONFIG, config), [config])

  const isError = isFolderPoolError || isSensorPoolError || isDashboardPoolError
  const isLoading =
    isFolderPoolLoading || isSensorPoolLoading || isDashboardPoolLoading

  return (
    <>
      {/* dialogs */}
      <DialogCreateDashboard open={showCreateDialog} onClose={toggleDialog} />
      {/* dialogs */}

      {/* preloader */}
      <Preloader error={isError} isLoading={isLoading} />
      {/* preloader end */}

      <Collapse in={!isLoading}>
        {!isLoading &&
          dashboardPool.map((dashboard, key) => (
            <DashboardComponent
              dashboard={dashboard}
              key={key}
              folderPool={folderPool}
              sensorPool={sensorPool}
              sensorPricePool={sensorPricePool}
              onChange={handleChange(key)}
              expanded={expanded === key}
            />
          ))}
        {dashboardPool.length === 0 && (
          <Container maxWidth={'xl'}>
            <Typography
              component={'h5'}
              sx={{ textAlign: 'center', p: 5 }}
              variant={'h6'}
            >
              Mit Klick auf &apos;+&apos; können Sie ein Dashboard erstellen.
            </Typography>
            ´
          </Container>
        )}
        <Tooltip title={'Neues Dashboard'}>
          <IconButton onClick={toggleDialog}>
            <AddTwoTone />
          </IconButton>
        </Tooltip>
      </Collapse>
    </>
  )
}
