import { DeleteTwoTone, SaveTwoTone } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { dashboardActions } from '../../../actions'
import { useAppConfig } from '../../../hooks'

export const DialogEditDashboard = ({
  dashboardId,
  name: initialName,
  granularity,
  onChangeName,
  onChangeGranularity,
  open,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { global } = useAppConfig()
  const { user_id } = global
  const [name, setName] = useState(initialName)
  const changeName = event => {
    setName(event.target.value)
  }
  const deleteDashboard = () => dispatch(dashboardActions.remove(dashboardId))
  const onUpdate = () => {
    dispatch(dashboardActions.update(dashboardId, { name, user_id }))
    onChangeName(name)
  }

  const granularityData = [
    { label: 'letzter voller Monat', value: 'lastFullMonth' },
    { label: 'letzten 30 Tage', value: 'lastThirtyDays' },
    { label: 'letzten 2 Monate', value: 'lastTwoMonths' },
    { label: 'letzten 6 Monate', value: 'lastSixMonths' },
    { label: 'dieses Jahr (Januar bis heute)', value: 'thisYear' },
    { label: 'letztes Jahr', value: 'lastYear' },
  ]

  return (
    <Dialog maxWidth={'xl'} open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack>Dashboard Einstellungen</Stack>
          <Tooltip title={'Dashboard löschen'}>
            <IconButton color={'error'} onClick={deleteDashboard}>
              <DeleteTwoTone />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <TextField
          error={name === ''}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <Tooltip title={'Speichern'}>
                  <span>
                    <IconButton
                      disabled={name === initialName}
                      onClick={onUpdate}
                      edge={'end'}
                    >
                      <SaveTwoTone />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          label={'Name'}
          onChange={changeName}
          sx={{ mx: 0 }}
          value={name}
        />

        <TextField
          fullWidth
          label={'Zeitrahmen'}
          onChange={onChangeGranularity}
          select
          sx={{ mx: 0 }}
          value={granularity}
        >
          {granularityData.map((granularity, key) => (
            <MenuItem key={key} value={granularity?.value}>
              {granularity?.label}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
    </Dialog>
  )
}

DialogEditDashboard.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeGranularity: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
