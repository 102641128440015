import { FormControl, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SORT_ATTR_NAME } from '../../api'
import { useFolderPool } from '../../hooks'

export const FolderSelector = () => {
  const history = useHistory()
  const { oneFolder } = useSelector(state => state.folder)

  const { data: folderPool, isLoading: isFolderPoolLoading } = useFolderPool()
  const [folderId, setFolderId] = useState(0)

  const handleFolderSelect = event =>
    history.push(`/folder/${event.target.value}/home/performance`)

  useEffect(
    () => {
      const id = oneFolder?.item?.data?.id

      if (id) setFolderId(Number(id))
    },
    [oneFolder]
  )

  return (
    <FormControl sx={{ width: 600 }}>
      {!isFolderPoolLoading && (
        <TextField onChange={handleFolderSelect} select value={folderId}>
          <MenuItem disabled value={0}>
            Meine Gebäude
          </MenuItem>

          {folderPool.sort(SORT_ATTR_NAME).map((folder, key) => {
            const { name } = folder.attributes

            return (
              <MenuItem key={key} value={folder.id}>
                {name}
              </MenuItem>
            )
          })}
        </TextField>
      )}
    </FormControl>
  )
}
