import { setStorage } from '../api'
import { userConstants as UC } from '../constants'

const initialState = { userConfig: { loading: true } }

export const config = (state = initialState, action) => {
  const { config, error, type } = action
  let userConfig

  switch (type) {
    case UC.USER_GET_CONFIG_REQUEST:
      return { ...state, userConfig: { loading: true } }

    case UC.USER_GET_CONFIG_SUCCESS:
      userConfig = {
        s3: {
          bucketName: (config && config.S3_BUCKET_NAME) || null,
          region: (config && config.S3_REGION) || null,
          accessKeyId: (config && config.S3_ACCESSKEYID) || null,
          secrectAccessKey: (config && config.S3_SECRETACCESSKEY) || null,
        },
      }
      setStorage('config', userConfig)
      return { ...state, userConfig: { loading: false } }

    case UC.USER_GET_CONFIG_ERROR:
      return { ...state, userConfig: { error, loading: false } }

    default:
      return state
  }
}
