import React, { useState } from 'react'
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {
  ArrowDropDownTwoTone,
  ArrowDropUpTwoTone,
  Star,
} from '@mui/icons-material'
import PropTypes from 'prop-types'

export const ChildSensorSelector = ({
  childSensorData,
  userLocales,
  DDMMYYYY,
  isAdmin,
  selected,
  setSelected,
  showSensorInfo,
  data,
  SORT_HANDLER,
  successorChild,
}) => {
  const [openIds, setOpenIds] = useState([])
  const handleOpenIDs = id => {
    setOpenIds(
      prevOpenIds =>
        prevOpenIds.includes(id)
          ? prevOpenIds.filter(openId => openId !== id)
          : [...prevOpenIds, id]
    )
  }
  const getAllWithSuccessorId = entity_id => {
    return data.filter(data => data.successor_id === entity_id)
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {childSensorData.map((childSensor, childKey) => {
        const childDate = new Date(
          childSensor.data_sampling_date
        ).toLocaleDateString(userLocales, DDMMYYYY)

        const isLastItem = childKey === childSensorData.length - 1

        const childSensorInfo = (
          <Box>
            <Box>
              Zählernummer:{' '}
              {childSensor.meter_number ? childSensor.meter_number : 'n/a'}
            </Box>
            <Box>Datenbeginn: {childDate}</Box>
            <Box>
              Messverfahren:{' '}
              {childSensor.sampling_mode === 1
                ? 'Zählerstand'
                : childSensor.sampling_mode === 2
                  ? 'Verbrauch'
                  : '-'}
            </Box>
            {isAdmin && (
              <>
                <Box>Sensor ID: {childSensor.entity_id}</Box>
              </>
            )}
          </Box>
        )
        const successorData = getAllWithSuccessorId(childSensor.entity_id)
        const isChildSelected = selected.includes(childSensor.entity_id)

        const childToggle = (entity_id, successorData) => {
          let newValue

          if (isChildSelected) {
            newValue = selected.filter(f => f !== entity_id)
          } else {
            newValue = [...selected, entity_id]
          }

          successorData.forEach(successorSensor => {
            if (isChildSelected) {
              newValue = newValue.filter(f => f !== successorSensor.entity_id)
            } else {
              newValue = [...newValue, successorSensor.entity_id]
            }
          })

          setSelected(
            data
              .sort(SORT_HANDLER)
              .map(sensor => sensor.entity_id)
              .filter(f => newValue.includes(f))
          )
        }

        return (
          <Box key={childKey} sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                left: successorChild ? '50px' : '28px',
                top: '0',
                bottom: '0',
                borderLeft: '1px solid rgb(0, 0, 0)',
                height:
                  isLastItem && openIds.includes(childKey)
                    ? '27%'
                    : isLastItem
                      ? '51%'
                      : '100%',
              }}
            />
            <List
              component={'div'}
              disablePadding
              onClick={() => childToggle(childSensor.entity_id, successorData)}
            >
              <ListItemButton
                sx={{ pl: successorChild ? 6 : 4 }}
                selected={isChildSelected}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    left: successorChild ? '50px' : '28px',
                    top: '50%',
                    width: '10px',
                    borderBottom: '1px solid #000',
                    transform: 'translateY(-50%)',
                  }}
                />
                <ListItemIcon sx={{ color: childSensor.chartColor }}>
                  <Star />
                </ListItemIcon>
                <ListItemText
                  primary={childSensor.name}
                  secondary={showSensorInfo ? childSensorInfo : null}
                />
                {successorData.length > 0 &&
                  (openIds.includes(childKey) ? (
                    <ArrowDropUpTwoTone
                      onClick={event => {
                        event.stopPropagation()
                        handleOpenIDs(childKey)
                      }}
                    />
                  ) : (
                    <ArrowDropDownTwoTone
                      onClick={event => {
                        event.stopPropagation()
                        handleOpenIDs(childKey)
                      }}
                    />
                  ))}
              </ListItemButton>
            </List>
            {successorData.length > 0 ? (
              <Collapse
                in={openIds.includes(childKey)}
                timeout={'auto'}
                unmountOnExit
              >
                {successorData.map((successorSensor, successorKey) => {
                  const successorDate = new Date(
                    successorSensor.data_sampling_date
                  ).toLocaleDateString(userLocales, DDMMYYYY)
                  const successorSensorInfo = (
                    <Box>
                      <Box>
                        Zählernummer:{' '}
                        {successorSensor.meter_number
                          ? successorSensor.meter_number
                          : 'n/a'}
                      </Box>
                      <Box>Datenbeginn: {successorDate}</Box>
                      <Box>
                        Messverfahren{' '}
                        {successorSensor.sampling_mode === 1
                          ? 'Zählerstand'
                          : successorSensor.sampling_mode === 2
                            ? 'Verbrauch'
                            : '-'}
                      </Box>
                      {isAdmin && (
                        <>
                          <Box>Sensor ID: {successorSensor.entity_id}</Box>
                        </>
                      )}
                    </Box>
                  )
                  const isSuccessorSelected = selected.includes(
                    successorSensor.entity_id
                  )
                  const successorToggle = entity_id => {
                    const newValue = isSuccessorSelected
                      ? selected.filter(f => f !== entity_id)
                      : [...selected, entity_id]

                    setSelected(
                      data
                        .sort(SORT_HANDLER)
                        .map(sensor => sensor.entity_id)
                        .filter(f => newValue.includes(f))
                    )
                  }
                  return (
                    <>
                      <List
                        component={'div'}
                        disablePadding
                        key={successorKey}
                        onClick={() =>
                          successorToggle(successorSensor.entity_id)
                        }
                      >
                        <ListItemButton selected={isSuccessorSelected}>
                          <ListItemIcon
                            sx={{ color: successorSensor.chartColor, pl: 2.5 }}
                          >
                            <Star />
                          </ListItemIcon>

                          <ListItemText
                            primary={successorSensor.name}
                            secondary={
                              showSensorInfo ? successorSensorInfo : null
                            }
                            primaryTypographyProps={{ fontSize: '0.9rem' }}
                          />
                        </ListItemButton>
                      </List>
                    </>
                  )
                })}
              </Collapse>
            ) : (
              <> </>
            )}
          </Box>
        )
      })}
    </Box>
  )
}

export default ChildSensorSelector

ChildSensorSelector.propTypes = {
  childSensorData: PropTypes.array.isRequired,
  userLocales: PropTypes.array.isRequired,
  DDMMYYYY: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelected: PropTypes.func.isRequired,
  showSensorInfo: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  SORT_HANDLER: PropTypes.func.isRequired,
  successorChild: PropTypes.bool,
}
