import { history } from '../api'
import { contractConstants as CC } from '../constants'
import { contractService as CS, tokenService as TS } from '../services'
import { alertActions as AA } from './'

const recordData = data => {
  const {
    date_begin,
    date_expire,
    description,
    name,
    primary_coach_id,
    sensor_id,
    user_id,
  } = data

  return {
    data: {
      attributes: {
        date_begin,
        date_expire,
        description,
        name,
        primary_coach_id,
      },
      relationships: {
        users: {
          data: user_id
            ? user_id.map(item => ({ type: 'user', id: item.value }))
            : [],
        },
        sensors: {
          data: sensor_id
            ? sensor_id.map(item => ({ type: 'sensor', id: item.value }))
            : [],
        },
      },
    },
  }
}

const getAll = () => {
  const failure = error => ({ type: CC.GET_ALL_CONTRACTS_FAILURE, error })
  const request = () => ({ type: CC.GET_ALL_CONTRACTS_REQUEST })
  const success = contracts => ({
    type: CC.GET_ALL_CONTRACTS_SUCCESS,
    contracts,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      CS.getAll()
        .then(result => dispatch(success(result)))
        .catch(error => {
          dispatch(failure(error.message))
          dispatch(AA.error(error.message))
        })
    )
  }
}

const getById = (id, includes = []) => {
  const failure = error => ({ type: CC.GET_BY_ID_ERROR, error })
  const request = contract => ({ type: CC.GET_BY_ID_REQUEST, contract })
  const success = contract => ({ type: CC.GET_BY_ID_SUCCESS, contract })

  return dispatch => {
    dispatch(request({ id }))

    TS.refreshToken().then(() =>
      CS.getById(id, includes).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const remove = id => {
  const failure = error => ({ type: CC.REMOVE_CONTRACT_ERROR, error })
  const request = () => ({ type: CC.REMOVE_CONTRACT_REQUEST })
  const success = contract => ({ type: CC.REMOVE_CONTRACT_SUCCESS, contract })

  return dispatch => {
    if (!id) return dispatch(AA.error(CC.CONTRACT_ID_REQUIRED))

    dispatch(request())

    CS.remove(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(CC.CONTRACT_DELETED))

        history.push('/contracts')
      })
      .catch(error => {
        dispatch(failure(error.message))
        dispatch(AA.error(error.message))
      })
  }
}

const save = data => {
  const failure = error => ({ type: CC.SAVE_CONTRACT_ERROR, error })
  const request = () => ({ type: CC.SAVE_CONTRACT_REQUEST })
  const success = contract => ({ type: CC.SAVE_CONTRACT_SUCCESS, contract })

  const { description, name } = data

  return dispatch => {
    if (!name && !description)
      return dispatch(AA.error(CC.CONTRACT_NAME_REQUIRED))

    dispatch(request())

    CS.save(recordData(data))
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(CC.CONTRACT_SAVED))
      })
      .catch(error => {
        dispatch(failure(error.message))
        dispatch(AA.error(error.message))
      })
  }
}

const update = (id, data) => {
  const failure = error => ({ type: CC.UPDATE_CONTRACT_ERROR, error })
  const request = () => ({ type: CC.UPDATE_CONTRACT_REQUEST })
  const success = contract => ({ type: CC.UPDATE_CONTRACT_SUCCESS, contract })

  const { description, name } = data

  return dispatch => {
    if (!name && !description)
      return dispatch(AA.error(CC.CONTRACT_NAME_REQUIRED))

    dispatch(request())

    CS.update(id, recordData(data))
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(CC.CONTRACT_UPDATED))
      })
      .catch(error => {
        dispatch(failure(error.message))
        dispatch(AA.error(error.message))
      })
  }
}

export const contractActions = {
  getAll,
  getById,
  remove,
  save,
  update,
}
