export const sensorConstants = {
  DELETE_SENSOR_ERROR: 'SENSOR_DELETE_SENSOR_ERROR',
  DELETE_SENSOR_REQUEST: 'SENSOR_DELETE_SENSOR_REQUEST',
  DELETE_SENSOR_SUCCESS: 'SENSOR_DELETE_SENSOR_SUCCESS',

  GET_ALL_ERROR: 'SENSOR_GET_ALL_ERROR',
  GET_ALL_REQUEST: 'SENSOR_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'SENSOR_GET_ALL_SUCCESS',

  GET_ALL_UNLINKED_ERROR: 'SENSOR_GET_ALL_UNLINKED_ERROR',
  GET_ALL_UNLINKED_REQUEST: 'SENSOR_GET_ALL_UNLINKED_REQUEST',
  GET_ALL_UNLINKED_SUCCESS: 'SENSOR_GET_ALL_UNLINKED_SUCCESS',

  GET_ATTRIBUTES_GROUP_FAILURE: 'SENSOR_GET_ATTRIBUTES_GROUP_ERROR',
  GET_ATTRIBUTES_GROUP_REQUEST: 'SENSOR_GET_ATTRIBUTES_GROUP_REQUEST',
  GET_ATTRIBUTES_GROUP_SUCCESS: 'SENSOR_GET_ATTRIBUTES_GROUP_SUCCESS',

  GET_BY_ID_ERROR: 'SENSOR_GET_BY_ID_ERROR',
  GET_BY_ID_REQUEST: 'SENSOR_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'SENSOR_GET_BY_ID_SUCCESS',

  GET_CALCULATIONS_ERROR: 'SENSOR_GET_CALCULATIONS_ERROR',
  GET_CALCULATIONS_REQUEST: 'SENSOR_GET_CALCULATIONS_REQUEST',
  GET_CALCULATIONS_SUCCESS: 'SENSOR_GET_CALCULATIONS_SUCCESS',

  GET_TIME_SERIES_ERROR: 'SENSOR_GET_TIME_SERIES_ERROR',
  GET_TIME_SERIES_REQUEST: 'SENSOR_GET_TIME_SERIES_REQUEST',
  GET_TIME_SERIES_SUCCESS: 'SENSOR_GET_TIME_SERIES_SUCCESS',

  SAVE_SENSOR_ERROR: 'SENSOR_SAVE_SENSOR_ERROR',
  SAVE_SENSOR_REQUEST: 'SENSOR_SAVE_SENSOR_REQUEST',
  SAVE_SENSOR_SUCCESS: 'SENSOR_SAVE_SENSOR_SUCCESS',

  SENSOR_SET_USERID_BY_SENSORS_ERROR: 'SENSOR_SET_USERID_BY_SENSORS_ERROR',
  SENSOR_SET_USERID_BY_SENSORS_REQUEST: 'SENSOR_SET_USERID_BY_SENSORS_REQUEST',
  SENSOR_SET_USERID_BY_SENSORS_SUCCESS: 'SENSOR_SET_USERID_BY_SENSORS_SUCCESS',

  SENSOR_DELETED: 'Sensor gelöscht',
  SENSOR_DEVICE_REQUIRED: 'Name und Device erforderlich',
  SENSOR_SAVED: 'Sensor gespeichert',
  SENSOR_UPDATED: 'Sensor aktualisiert',

  SAVE_MISSING_SENSOR_DATA_ERROR: 'Fehlende Sensor Daten Fehler',
  SAVE_MISSING_SENSOR_DATA_REQUEST: 'Fehlende Sensor Daten Anfrage',
  SAVE_MISSING_SENSOR_DATA_SUCCESS: 'Fehlende Sensor Daten gepseichert',
}
