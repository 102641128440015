import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ImageListItem,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { categoryByType, SVGStatus } from '../../../api'
import { useSensorPool } from '../../../hooks'
import defaultImage from '../../../assets/img/image_placeholder.jpg'

export const FolderStatus = ({ folder }) => {
  const history = useHistory()
  const { data, isLoading } = useSensorPool()
  const {
    entity_id,
    image_url,
    name,
    name_short,
    sensor_group_status: sgs,
  } = folder.attributes

  const targetUrl = `/folder/${entity_id}/home`

  const statusToTooltip = status => {
    switch (status) {
      case 'green':
        return 'Alles OK'
      case 'yellow':
        return 'Mindestens ein Sensor befindet sich nahe dem kritischen Bereich'
      case 'red':
        return 'Mindestens ein Sensor befindet sich im kritischen Bereich'
      default:
        return 'Kein Sensor registriert'
    }
  }

  // color status of categories
  const [colorStatus, setColorStatus] = useState({
    feeling: 'neutral',
    security: 'neutral',
    performance: 'neutral',
    correlation: 'neutral',
    installation: 'neutral',
  })

  useEffect(
    () => {
      if (!isLoading && folder.id && sgs) {
        // filters
        const FILTER_CURRENT_FOLDER = f =>
          f.attributes.folder_id === Number(folder.id)

        const FILTER_PERFORMANCE = f =>
          categoryByType(f.attributes.sensor_type) === 'consumption' ||
          categoryByType(f.attributes.sensor_type) === 'performance'

        const FILTER_SECURITY = f =>
          f.attributes.sensor_target !== null
            ? f.attributes.sensor_target === 'security'
            : categoryByType(f.attributes.sensor_type) === 'security'

        const FILTER_FEELING = f =>
          f.attributes.sensor_target !== null
            ? f.attributes.sensor_target === 'feeling'
            : categoryByType(f.attributes.sensor_type) === 'feeling'

        const FILTER_CORRELATION = f =>
          ![
            'door',
            'smokeDetektor',
            'Removal_detection',
            'Battery_end_of_life',
            'Smoke_alarm',
            'Acoustic_alarm_failure',
            'waterDetected',
            'external_digital_button',
            'external_digital_button_2',
          ].includes(f.attributes.sensor_type)

        const P_SENSORS = isLoading
          ? []
          : data.filter(FILTER_CURRENT_FOLDER).filter(FILTER_PERFORMANCE)

        const S_SENSORS = isLoading
          ? []
          : data.filter(FILTER_CURRENT_FOLDER).filter(FILTER_SECURITY)

        const W_SENSORS = isLoading
          ? []
          : data.filter(FILTER_CURRENT_FOLDER).filter(FILTER_FEELING)

        const CAN_CORRELATE =
          [...W_SENSORS, ...S_SENSORS, ...P_SENSORS].filter(FILTER_CORRELATION)
            .length >= 2 // sensors correlate if atleast 2 sensors with certain sensor types are in an object

        const performance = sgs.find(f => f.type === 'status_performance')
        const security = sgs.find(f => f.type === 'status_security')
        const feeling = sgs.find(f => f.type === 'status_well_being')
        const installations = sgs.find(f => f.type === 'status_installation')

        if (P_SENSORS.length === 0) performance.status = 'neutral'
        if (S_SENSORS.length === 0) security.status = 'neutral'
        if (W_SENSORS.length === 0) feeling.status = 'neutral'

        setColorStatus({
          performance: performance.status,
          security: security.status,
          feeling: feeling.status,
          correlation: CAN_CORRELATE ? 'green' : 'neutral',
          installation: installations.status,
        })
      }
    },
    [folder.id, sgs, data, isLoading]
  )

  return (
    <ImageListItem>
      <Card sx={{ borderRadius: 5, border: '1px solid #e0e0e0' }}>
        <CardHeader
          title={name}
          subheader={name_short}
          onClick={() => history.push(targetUrl)}
          sx={{ cursor: 'pointer' }}
        />

        <CardContent
          sx={{ p: 0, overflow: 'hidden', height: 360, position: 'relative' }}
        >
          <Box
            component={'img'}
            draggable={false}
            src={image_url || defaultImage}
            onClick={() => history.push(targetUrl)}
            sx={{
              cursor: 'pointer',
              height: 360,
              minWidth: 1,
              width: 1,
              position: 'absolute',
              top: 0,
              left: 0,
              '&:hover': { transform: 'scale(1.1)' },
              objectFit: 'cover',
            }}
          />
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          {[
            'performance',
            'installation',
            'correlation',
            'security',
            'feeling',
          ].map((category, key) => (
            <Tooltip
              key={key}
              placement={'top'}
              title={statusToTooltip(colorStatus[category])}
            >
              <Box
                onClick={() => history.push(`${targetUrl}/${category}`)}
                sx={{ cursor: 'pointer', ml: 1, width: 50 }}
              >
                <SVGStatus type={category} status={colorStatus[category]} />
              </Box>
            </Tooltip>
          ))}
        </CardActions>
      </Card>
    </ImageListItem>
  )
}

FolderStatus.propTypes = {
  folder: PropTypes.object,
}
