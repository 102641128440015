export const notificationConstants = {
  GET_ALL_ERROR: 'GET_ALL_ERROR',
  GET_ALL_REQUEST: 'GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'GET_ALL_SUCCESS',

  NOTIFICATION_DELETED: 'Notification gelöscht',
  NOTIFICATION_FOLDER_REQUIRED: 'Gebäude erforderlich',
  NOTIFICATION_ID_REQUIRED: 'ID erforderlich',
  NOTIFICATION_SAVED: 'Notification gespeichert',

  REMOVE_NOTIFICATION_ERROR: 'REMOVE_NOTIFICATION_ERROR',
  REMOVE_NOTIFICATION_REQUEST: 'REMOVE_NOTIFICATION_REQUEST',
  REMOVE_NOTIFICATION_SUCCESS: 'REMOVE_NOTIFICATION_SUCCESS',

  SAVE_NOTIFICATION_ERROR: 'SAVE_NOTIFICATION_ERROR',
  SAVE_NOTIFICATION_REQUEST: 'SAVE_NOTIFICATION_REQUEST',
  SAVE_NOTIFICATION_SUCCESS: 'SAVE_NOTIFICATION_SUCCESS',
}
