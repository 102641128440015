// import modules
import React from 'react'
// import scripts
import { DDMMYYYY, userLocales } from '../../api'
import avatar from '../../assets/img/default-avatar.png'

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

const Thumbnail = row => {
  const { image_url, name } = row.original

  return (
    <img
      alt={name}
      key={`thumb_${name}`}
      src={image_url ? image_url : avatar}
      style={{ width: '75px', height: '75px' }}
    />
  )
}

export const contractColumns = [
  {
    accessor: 'name',
    filterAll: true,
    Header: 'Name',
    id: 'name',
  },
  {
    accessor: 'date_begin',
    filterAll: true,
    Header: 'Beginn',
    id: 'date_begin',
  },
  {
    accessor: 'date_expire',
    filterAll: true,
    Header: 'Ablauf',
    id: 'date_expire',
  },
]

export const folderColumns = [
  {
    accessor: 'image_url',
    Cell: Thumbnail,
    filterable: false,
    filterAll: true,
    Header: '',
    id: 'image_url',
    sortable: false,
    width: 100,
  },
  {
    accessor: 'name',
    filterAll: true,
    Header: 'Name',
    id: 'name',
  },
  {
    accessor: 'address',
    filterAll: true,
    Header: 'Anschrift',
    id: 'address',
  },
]

export const tableRowContract = contract => {
  const { attributes, id } = contract
  const { date_begin, date_expire, name } = attributes

  return {
    id: id,
    date_begin: new Date(date_begin).toLocaleDateString(userLocales, DDMMYYYY),
    date_expire: new Date(date_expire).toLocaleDateString(
      userLocales,
      DDMMYYYY
    ),
    name: name,
  }
}

export const tableRowFolder = folder => {
  const { attributes, id } = folder
  const { city, country, image_url, name, name_short, street, zip } = attributes

  return {
    id: id,
    image_url: image_url,
    name: name + (name_short ? ` (${name_short})` : ''),
    address: `${street}, ${zip}, ${city} (${countries.getName(country, 'de')})`,
  }
}
