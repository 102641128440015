// modules
import { AccountBalanceTwoTone, MoreVertTwoTone } from '@mui/icons-material'
import {
  Box,
  Collapse,
  Divider,
  Hidden,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { alertActions } from '../../actions'
// scripts
import { categoryByType, SensorIcon } from '../../api'
import { AM, AMItem, KPage, Preloader } from '../../components'
import { useFolderPool, useSensorPool } from '../../hooks'
import { Correlation, Feeling, Installation, Performance, Security } from './'
import { Weather } from './components'

export const HomeFolder = () => {
  // input parameters
  const { id: folderId, category } = useParams()

  const dispatch = useDispatch()
  const history = useHistory()

  // page config
  const [actionMenu, setActionMenu] = useState(null)
  const topRef = useRef(null)

  // folder pool
  const {
    data: folderPool,
    isLoading: folderPoolLoading,
    isError: folderPoolError,
  } = useFolderPool()

  // sensor pool
  const {
    data: sensorPool,
    isLoading: sensorPoolLoading,
    isError: sensorPoolError,
  } = useSensorPool()

  // color status of categories
  const [colorStatus, setColorStatus] = useState([
    { type: 'performance', status: 'neutral' },
    { type: 'installation', status: 'neutral' },
    { type: 'correlation', status: 'neutral' },
    { type: 'security', status: 'neutral' },
    { type: 'feeling', status: 'neutral' },
  ])

  // tab data
  const TAB_CONSUMPTION = 0
  const TAB_INSTALLATION = 1
  const TAB_CORRELATION = 2
  const TAB_SECURITY = 3
  const TAB_FEELING = 4

  const [tab, setTab] = useState(TAB_FEELING)
  const tabData = [
    {
      category: 'performance',
      color: colorStatus.performance,
      title: 'Leistung',
    },
    {
      category: 'installation',
      color: colorStatus.installation,
      title: 'Anlagen',
    },
    {
      category: 'correlation',
      color: colorStatus.correlation,
      title: 'Korrelation',
    },
    {
      category: 'security',
      color: colorStatus.security,
      title: 'Sicherheit',
    },
    {
      category: 'feeling',
      color: colorStatus.feeling,
      title: 'Wohlbefinden',
    },
  ]

  // filters
  const FILTER_CURRENT_FOLDER = f => f.attributes.folder_id === Number(folderId)

  const FILTER_PERFORMANCE = f =>
    categoryByType(f.attributes.sensor_type) === 'consumption' ||
    categoryByType(f.attributes.sensor_type) === 'performance'

  const FILTER_SECURITY = f =>
    f.attributes.sensor_target !== null
      ? f.attributes.sensor_target === 'security'
      : categoryByType(f.attributes.sensor_type) === 'security'

  const FILTER_FEELING = f =>
    f.attributes.sensor_target !== null
      ? f.attributes.sensor_target === 'feeling'
      : categoryByType(f.attributes.sensor_type) === 'feeling'

  const FILTER_CORRELATION = f =>
    ![
      'door',
      'smokeDetektor',
      'Removal_detection',
      'Battery_end_of_life',
      'Smoke_alarm',
      'Acoustic_alarm_failure',
      'waterDetected',
      'external_digital_button',
      'external_digital_button_2',
    ].includes(f.attributes.sensor_type)

  const P_SENSORS = sensorPoolLoading
    ? []
    : sensorPool.filter(FILTER_CURRENT_FOLDER).filter(FILTER_PERFORMANCE)

  const S_SENSORS = sensorPoolLoading
    ? []
    : sensorPool.filter(FILTER_CURRENT_FOLDER).filter(FILTER_SECURITY)

  const W_SENSORS = sensorPoolLoading
    ? []
    : sensorPool.filter(FILTER_CURRENT_FOLDER).filter(FILTER_FEELING)

  const CAN_CORRELATE =
    [...W_SENSORS, ...S_SENSORS, ...P_SENSORS].filter(FILTER_CORRELATION)
      .length >= 2 // sensors correlate if atleast 2 sensors with certain sensor types are in an object

  // weather
  const [city, setCity] = useState('')

  useEffect(
    () => {
      switch (category) {
        case 'feeling':
          setTab(TAB_FEELING)
          break
        case 'security':
          setTab(TAB_SECURITY)
          break
        case 'performance':
          setTab(TAB_CONSUMPTION)
          break
        case 'correlation':
          setTab(TAB_CORRELATION)
          break
        case 'installation':
          setTab(TAB_INSTALLATION)
          break
        default:
          console.error('unhandled category', category)
          break
      }

      // on change scroll to top
      const { current } = topRef
      if (current)
        current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    [category]
  )

  useEffect(
    () => {
      if (!sensorPoolLoading && !folderPoolLoading && folderPool.length > 0) {
        const FOLDER_WITH_FOLDERID = f => Number(f.id) === Number(folderId)
        const data = folderPool.find(FOLDER_WITH_FOLDERID)

        if (data) {
          const { city, sensor_group_status: sgs } = data.attributes

          if (city) setCity(city)

          if (sgs) {
            const performance = sgs.find(f => f.type === 'status_performance')
            const security = sgs.find(f => f.type === 'status_security')
            const feeling = sgs.find(f => f.type === 'status_well_being')
            const installations = sgs.find(
              f => f.type === 'status_installation'
            )

            if (P_SENSORS.length === 0) performance.status = 'neutral'
            if (S_SENSORS.length === 0) security.status = 'neutral'
            if (W_SENSORS.length === 0) feeling.status = 'neutral'

            setColorStatus({
              performance: performance.status,
              security: security.status,
              feeling: feeling.status,
              correlation: CAN_CORRELATE ? 'green' : 'neutral',
              installation: installations.status,
            })
          } else dispatch(alertActions.error('No sensor group status found'))
        } else
          dispatch(alertActions.error(`Gebäude ID ${folderId} existiert nicht`))
      }
    },
    [
      dispatch,
      folderId,
      folderPool,
      folderPoolLoading,
      sensorPoolLoading,
      CAN_CORRELATE,
      P_SENSORS.length,
      S_SENSORS.length,
      W_SENSORS.length,
    ]
  )

  const isPageLoading = folderPoolLoading || sensorPoolLoading
  const isPageError = folderPoolError || sensorPoolError

  return (
    <>
      {/* preloader */}
      <Preloader error={isPageError} isLoading={isPageLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Gebäude'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'dashboard'}
      >
        <AMItem
          caption={'Details zum Gebäude'}
          icon={<AccountBalanceTwoTone />}
          onClick={() => history.push(`/folder/${folderId}`)}
        />

        <Divider />

        <AMItem
          caption={'Leistung'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('performance', colorStatus.performance)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => setTab(TAB_CONSUMPTION)}
        />

        <AMItem
          caption={'Anlagen'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('installation', colorStatus.installation)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => setTab(TAB_INSTALLATION)}
        />

        <AMItem
          caption={'Korrelation'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('correlation', colorStatus.correlation)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => setTab(TAB_CORRELATION)}
        />

        <AMItem
          caption={'Sicherheit'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('security', colorStatus.security)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => setTab(TAB_SECURITY)}
        />

        <AMItem
          caption={'Wohlbefinden'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('feeling', colorStatus.feeling)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => setTab(TAB_FEELING)}
        />
      </AM>
      {/* action menu end */}

      <Weather city={city} />

      <br />

      <KPage
        action={
          <IconButton onClick={event => setActionMenu(event.currentTarget)}>
            <MoreVertTwoTone />
          </IconButton>
        }
        isLoading={isPageLoading}
        innerRef={topRef}
      >
        <Tabs
          centered
          indicatorColor={'primary'}
          onChange={(event, category) => {
            setTab(category)

            const targetUrl = `/folder/${folderId}/home/${
              [
                'performance',
                'installation',
                'correlation',
                'security',
                'feeling',
              ][category]
            }`

            history.push(targetUrl)
          }}
          scrollButtons={'auto'}
          sx={{ mb: 5 }}
          textColor={'primary'}
          value={tab}
        >
          {/* tabData */}
          {tabData.map((item, key) => {
            return (
              <Tooltip key={key} title={item.title}>
                <Tab
                  icon={
                    <Box
                      alt={''}
                      component={'img'}
                      src={SensorIcon(item.category, item.color)}
                      sx={{ width: 80 }}
                      title={item.title}
                    />
                  }
                  label={<Hidden mdDown>{item.title}</Hidden>}
                />
              </Tooltip>
            )
          })}
        </Tabs>
        <Collapse in={tab === TAB_CONSUMPTION}>
          {tab === TAB_CONSUMPTION && <Performance folderId={folderId} />}
        </Collapse>
        <Collapse in={tab === TAB_INSTALLATION}>
          {tab === TAB_INSTALLATION && <Installation folderId={folderId} />}
        </Collapse>
        <Collapse in={tab === TAB_CORRELATION}>
          {tab === TAB_CORRELATION && (
            <Correlation folderId={folderId} isCorrelational={CAN_CORRELATE} />
          )}
        </Collapse>
        <Collapse in={tab === TAB_SECURITY}>
          {tab === TAB_SECURITY && <Security folderId={folderId} />}
        </Collapse>
        <Collapse in={tab === TAB_FEELING}>
          {tab === TAB_FEELING && <Feeling folderId={folderId} />}
        </Collapse>
      </KPage>
    </>
  )
}
