export const userConstants = {
  USER_CHANGE_PASSWORD_ERROR: 'USER_CHANGE_PASSWORD_ERROR',
  USER_CHANGE_PASSWORD_REQUEST: 'USER_CHANGE_PASSWORD_REQUEST',
  USER_CHANGE_PASSWORD_SUCCESS: 'USER_CHANGE_PASSWORD_SUCCESS',

  USER_DELETE_ERROR: 'USER_DELETE_ERROR',
  USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',

  USER_FORGOT_PASSWORD_ERROR: 'USER_FORGOT_PASSWORD_ERROR',
  USER_FORGOT_PASSWORD_REQUEST: 'USER_FORGOT_PASSWORD_REQUEST',
  USER_FORGOT_PASSWORD_SUCCESS: 'USER_FORGOT_PASSWORD_SUCCESS',

  USER_GET_ALL_ROLE_ERROR: 'USER_GET_ALL_ROLE_ERROR',
  USER_GET_ALL_ROLE_REQUEST: 'USER_GET_ALL_ROLE_REQUEST',
  USER_GET_ALL_ROLE_SUCCESS: 'USER_GET_ALL_ROLE_SUCCESS',

  USER_GET_ALL_USERS_ERROR: 'USER_GET_ALL_USERS_ERROR',
  USER_GET_ALL_USERS_REQUEST: 'USER_GET_ALL_USERS_REQUEST',
  USER_GET_ALL_USERS_SUCCESS: 'USER_GET_ALL_USERS_SUCCESS',

  USER_GET_BY_ID_ERROR: 'USER_GET_BY_ID_ERROR',
  USER_GET_BY_ID_REQUEST: 'USER_GET_BY_ID_REQUEST',
  USER_GET_BY_ID_SUCCESS: 'USER_GET_BY_ID_SUCCESS',

  USER_GET_CONFIG_ERROR: 'USER_GET_CONFIG_ERROR',
  USER_GET_CONFIG_REQUEST: 'USER_GET_CONFIG_REQUEST',
  USER_GET_CONFIG_SUCCESS: 'USER_GET_CONFIG_SUCCESS',

  USER_GET_CURRENT_USER_ERROR: 'USER_GET_CURRENT_USER_ERROR',
  USER_GET_CURRENT_USER_REQUEST: 'USER_GET_CURRENT_USER_REQUEST',
  USER_GET_CURRENT_USER_SUCCESS: 'USER_GET_CURRENT_USER_SUCCESS',

  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',

  USER_GET_FOLDER_SENSORS_ERROR: 'USER_GET_FOLDER_SENSORS_ERROR',
  USER_GET_FOLDER_SENSORS_REQUEST: 'USER_GET_FOLDER_SENSORS_REQUEST',
  USER_GET_FOLDER_SENSORS_SUCCESS: 'USER_GET_FOLDER_SENSORS_SUCCESS',

  USER_LOGOUT: 'USER_LOGOUT',

  USER_REGISTER_ERROR: 'USER_REGISTER_ERROR',
  USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',

  USER_RESEND_LINK_ERROR: 'USER_RESEND_LINK_ERROR',
  USER_RESEND_LINK_REQUEST: 'USER_RESEND_LINK_REQUEST',
  USER_RESEND_LINK_SUCCESS: 'USER_RESEND_LINK_SUCCESS',

  USER_SAVE_ERROR: 'USER_SAVE_ERROR',
  USER_SAVE_REQUEST: 'USER_SAVE_REQUEST',
  USER_SAVE_SUCCESS: 'USER_SAVE_SUCCESS',

  USER_SET_SENSORID_BY_USERS_ERROR: 'USER_SET_SENSORID_BY_USERS_ERROR',
  USER_SET_SENSORID_BY_USERS_REQUEST: 'USER_SET_SENSORID_BY_USERS_REQUEST',
  USER_SET_SENSORID_BY_USERS_SUCCESS: 'USER_SET_SENSORID_BY_USERS_SUCCESS',

  USER_UPDATE_ERROR: 'USER_UPDATE_ERROR',
  USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',

  USER_CURRENT_USER_UPDATE_ERROR: 'USER_CURRENT_USER_UPDATE_ERROR',
  USER_CURRENT_USER_UPDATE_REQUEST: 'USER_CURRENT_USER_UPDATE_REQUEST',
  USER_CURRENT_USER_UPDATE_SUCCESS: 'USER_CURRENT_USER_UPDATE_SUCCESS',

  USER_DATA_UPLOAD_ERROR: 'USER_DATA_UPLOAD_ERROR',
  USER_DATA_UPLOAD_REQUEST: 'USER_DATA_UPLOAD_REQUEST',
  USER_DATA_UPLOAD_SUCCESS: 'USER_DATA_UPLOAD_SUCCESS',

  USER_CODE_SENT: 'Code gesendet',
  USER_DELETED: 'Benutzer gelöscht',
  USER_ID_REQUIRED: 'ID erforderlich',
  USER_MAIL_SENT: 'E-Mail gesendet',
  USER_PASSWORD_CHANGED: 'Passwort aktualisiert',
  USER_REGISTRATION_SUCCESS: 'Registrierung erfolgreich',
  USER_SAVED: 'Benutzer gespeichert',
  USER_UPDATED: 'Benutzer aktualisiert',

  USER_ROLE_ANY: '',
  USER_ROLE_ADMIN: 'Admin',
  USER_ROLE_COACH: 'Coach',
  USER_ROLE_USER: 'Benutzer',
  USER_ROLE_GUEST: 'Gast',

  USER_STATUS_ANY: '',
  USER_STATUS_ACTIVE: 'aktiv',
  USER_STATUS_INACTIVE: 'inaktiv',
  USER_STATUS_NOT_CONFIRMED: 'unbestätigt',
}
