// modules
import {
  AccountBalanceTwoTone,
  ArrowBackTwoTone,
  ArrowForwardTwoTone,
  CancelTwoTone,
  CreateTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  ExpandMoreTwoTone,
  MemoryTwoTone,
  SaveTwoTone,
  HomeTwoTone,
  MoreHorizTwoTone,
  MoreVertTwoTone,
  InfoTwoTone,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import { useDispatch } from 'react-redux'
import { dashboardActions } from '../../../actions'
import {
  colorSchemeConsumption,
  germanDecimals,
  userLocales,
  getHoursArray,
  getDaysArray,
  getMonthsArray,
  MMMYY,
  DDMMYYYY,
  DDMMYY,
  DDMMYYYYHHMM,
} from '../../../api'
import { useOperator } from '../../../hooks'

import usePagination from './Pagination'

export const PanelComponent = ({
  panel,
  panelStartDate,
  panelEndDate,
  panelGranularity,
  sensorPool,
  sensorPricePool,
  folderPool,
}) => {
  const dispatch = useDispatch()

  const { data: operatorData } = useOperator()

  const { id: panelID, consumptionData } = panel
  const consumptions = consumptionData
  const {
    dashboard_id: dashboardID,
    name,
    position,
    indicator,
    graphic_type,
    presentation_level,
  } = panel.attributes

  const extractSensorIds = data => data.map(item => Number(item.id))
  const extractedSensorIDs = extractSensorIds(consumptions)
  const extractedSensorNames = []
  const extractedFolderIDs = []
  const extractedFolderNames = []
  let extractedUnit = 'kWh'

  extractedSensorIDs.forEach(sensorId => {
    const foundSensor = sensorPool.find(
      sensor => sensor.attributes.entity_id === sensorId
    )
    const foundFolder = folderPool.find(folder =>
      folder.attributes.folder_sensors.some(
        sensor => Number(sensor.sensor_id) === Number(sensorId)
      )
    )
    if (foundSensor) {
      extractedSensorNames.push(
        `${foundSensor.attributes.name} | Gebäude ${
          foundFolder.attributes.name
        }`
      )
      extractedUnit = foundSensor.attributes.unit
    }

    if (foundFolder) {
      extractedFolderIDs.push(Number(foundFolder.id))
      extractedFolderNames.push(foundFolder.attributes.name)
    }
  })
  // Anchor States
  const [anchorEl, setAnchorEl] = useState(false)
  const [editPanelAnchorEl, setEditPanelAnchorEl] = useState(false)
  const [deletePanelAnchorEl, setDeletePanelAnchorEl] = useState(false)
  const [editPanelSensorAnchorEl, setEditPanelSensorAnchorEl] = useState(false)

  // Panel States
  const [panelName, setPanelName] = useState(name)
  const [panelPositon] = useState(position)
  const [panelIndicator, setPanelIndicator] = useState(indicator)
  const [panelGraphicType, setPanelGraphicType] = useState(graphic_type)
  const [panelPresentationLevel, setPanelPresentationLevel] = useState(
    presentation_level
  )
  const [panelColumnStacked, setPanelColumnStacked] = useState(true)
  const [showWarning, setShowWarning] = useState(false)
  const [warningText, setWarningText] = useState(false)
  const [warningSensors, setWarningSensors] = useState([])
  const [warningSensorsIds, setWarningSensorsIds] = useState([])
  const [warningAnchorEl, setWarningAnchorEl] = useState(false)
  // Chart States
  const [total, setTotal] = useState(0)
  const [chartData, setChartData] = useState([])
  const [noPieChartData, setNoPieChartData] = useState(false)

  // Sensor States
  const [activeSensors, setActiveSensors] = useState([
    ...new Set(extractedSensorIDs),
  ])
  const [activeFolders, setActiveFolders] = useState([
    ...new Set(extractedFolderIDs),
  ])
  const [extractedFolders] = useState([...new Set(extractedFolderIDs)])

  const [activeFoldersNames, setActiveFoldersNames] = useState([
    ...new Set(extractedFolderNames),
  ])
  const [activeSensorsNames, setActiveSensorsNames] = useState([
    ...new Set(extractedSensorNames),
  ])
  const [energyChecked, setEnergyChecked] = useState(false)
  const [waterChecked, setWaterChecked] = useState(false)
  const [gasChecked, setGasChecked] = useState(false)
  const [panelUnit, setPanelUnit] = useState(extractedUnit)
  const [showFolderSensors, setShowFolderSensors] = useState(false)
  const [showSelectedFolderSensors, setShowSelectedFolderSensors] = useState(
    false
  )
  const [showSelectedFolders, setShowSelectedFolders] = useState(false)

  // Pagination States
  const [page, setPage] = useState(1)
  const [sensorsSearch, setSensorsSearch] = useState('')
  const [foldersSearch, setFoldersSearch] = useState('')
  const PER_PAGE = 10
  const [energyData, setEnergyData] = useState([])
  const [waterData, setWaterData] = useState([])
  const [gasData, setGasData] = useState([])
  const [count, setCount] = useState(0)

  const [showChartLegend, setShowChartLegend] = useState(true)

  // 1000000 -> 1.000.000
  const digitGrouping = digit =>
    Number(digit).toLocaleString(userLocales, germanDecimals)

  const chartTypes = {
    pie_chart: 1,
    column_chart: 2,
    table: 3,
  }

  const indicatorType = {
    consumption: 1,
    consumption_per_sqm: 2,
    cost: 3,
    cost_per_sqm: 4,
    co2: 5,
    co2_per_sqm: 6,
  }

  const chartType =
    panelGraphicType === chartTypes.pie_chart
      ? 'PieChart'
      : panelGraphicType === chartTypes.column_chart
        ? 'ColumnChart'
        : panelGraphicType === chartTypes.table
          ? 'Table'
          : 'LineChart'

  const chartIndicator =
    panelIndicator === indicatorType.consumption
      ? 'Verbrauch'
      : panelIndicator === indicatorType.consumption_per_sqm
        ? 'Verbrauch/m²'
        : panelIndicator === indicatorType.cost
          ? 'Kosten'
          : panelIndicator === indicatorType.cost_per_sqm
            ? 'Kosten/m²'
            : panelIndicator === indicatorType.co2
              ? 'CO₂-Ausstoß'
              : 'CO₂-Ausstoß/m²'

  const chartUnit =
    panelIndicator === indicatorType.consumption ||
    panelIndicator === indicatorType.consumption_per_sqm
      ? panelUnit
      : panelIndicator === indicatorType.cost ||
        panelIndicator === indicatorType.cost_per_sqm
        ? '€'
        : panelIndicator === indicatorType.co2 ||
          panelIndicator === indicatorType.co2_per_sqm
          ? 'kg'
          : 'kWh'

  const graphicTypeMenuData = [
    { label: 'Kreisdiagramm', value: 1 },
    { label: 'Balkendiagramm', value: 2 },
    { label: 'Ranking', value: 3 },
  ]

  const indicatorMenuData = [
    { label: 'Verbrauch', value: 1 },
    { label: 'Verbrauch/m²', value: 2 },
    { label: 'Kosten', value: 3 },
    { label: 'Kosten/m²', value: 4 },
    { label: 'CO₂-Ausstoß', value: 5 },
    { label: 'CO₂-Ausstoß/m²', value: 6 },
  ]

  useEffect(
    () => {
      if (!panel || !consumptions) return

      let total = 0
      let totalObject = 0

      const getSensorById = id =>
        sensorPool.find(s => Number(s.id) === Number(id))

      const calculateAmount = data => {
        const {
          total_consumption,
          total_consumption_per_sqm,
          total_co2,
          total_co2_per_sqm,
          total_cost,
          total_cost_per_sqm,
        } = data.attributes.consumptions

        return panelIndicator === indicatorType.cost
          ? total_cost
          : panelIndicator === indicatorType.cost_per_sqm
            ? total_cost_per_sqm
            : panelIndicator === indicatorType.co2
              ? total_co2
              : panelIndicator === indicatorType.co2_per_sqm
                ? total_co2_per_sqm
                : panelIndicator === indicatorType.consumption
                  ? total_consumption
                  : panelIndicator === indicatorType.consumption_per_sqm
                    ? total_consumption_per_sqm
                    : 0
      }

      const granularityTitle = granularity =>
        granularity === 'h'
          ? 'Stündlich'
          : granularity === 'd'
            ? 'Täglich'
            : granularity === 'm'
              ? 'Monatlich'
              : granularity

      const allSensorConsumptionData = []

      const createConsumptionValuesMap = (values, userLocales, format) => {
        const consumptionValuesMap = new Map(
          values.map(value => [
            new Date(value.timepoint).toLocaleString(userLocales, format),
            value,
          ])
        )
        return consumptionValuesMap
      }

      const formatDateToLocaleString = (date, userLocales, format) => {
        if (typeof date === 'string') return date
        return new Date(date).toLocaleString(userLocales, format)
      }

      const createBaseDataColumnChart = granularity => {
        let columns = [granularityTitle(granularity)]

        const format =
          granularity === 'h'
            ? DDMMYYYYHHMM
            : granularity === 'd'
              ? DDMMYY
              : granularity === 'm'
                ? MMMYY
                : DDMMYY

        let data =
          granularity === 'h'
            ? getHoursArray(new Date(panelStartDate), new Date(panelEndDate))
            : granularity === 'd'
              ? getDaysArray(new Date(panelStartDate), new Date(panelEndDate))
              : granularity === 'm'
                ? getMonthsArray(panelStartDate, panelEndDate)
                : []

        if (granularity === 'd') data = data.map(day => [day])
        if (granularity === 'm') data = data.map(day => [day])

        consumptions.forEach(sensor => {
          const { attributes } = sensor
          const { consumptions: consumption, name } = attributes
          allSensorConsumptionData.push(sensor)

          const addConsumption = record => {
            const consumptionValuesMap = createConsumptionValuesMap(
              consumption.values,
              userLocales,
              format
            )
            const timepoint = formatDateToLocaleString(
              record[0],
              userLocales,
              format
            )

            const data = consumptionValuesMap.get(timepoint)
            const aggregated = data
              ? panelIndicator === indicatorType.cost
                ? data.cost === null
                  ? 0
                  : data.cost
                : panelIndicator === indicatorType.cost_per_sqm
                  ? data.cost_per_sqm === null
                    ? 0
                    : data.cost_per_sqm
                  : panelIndicator === indicatorType.co2
                    ? data.co2 === null
                      ? 0
                      : data.co2
                    : panelIndicator === indicatorType.co2_per_sqm
                      ? data.co2_per_sqm === null
                        ? 0
                        : data.co2_per_sqm
                      : panelIndicator === indicatorType.consumption
                        ? data.value === null
                          ? 0
                          : data.value
                        : panelIndicator === indicatorType.consumption_per_sqm
                          ? data.value_per_sqm === null
                            ? 0
                            : data.value_per_sqm
                          : 0
              : 0
            const roundedValue = aggregated != null ? +aggregated.toFixed(2) : 0
            return [...record, roundedValue || 0]
          }

          columns = [...columns, name]

          data = data.map(record => addConsumption(record))
        })

        data.sort(
          (a, b) =>
            new Date(a[0]).getTime() > new Date(b[0]).getTime() ? 1 : -1
        )

        // fill columns data to minimum if no data
        if (data.length === 0) data = [granularity, granularity]

        // add columns header (sensor names) on top of data
        data = [columns, ...data]

        // add dummy data if no data to render
        if (data.length === 1) data.push(columns.map(() => 0))

        return data
      }

      const createBaseDataFolderColumnChart = (granularity, consumptions) => {
        let columns = [granularityTitle(granularity)]

        const format =
          granularity === 'h'
            ? DDMMYYYYHHMM
            : granularity === 'd'
              ? DDMMYY
              : granularity === 'm'
                ? MMMYY
                : DDMMYY

        let data =
          granularity === 'h'
            ? getHoursArray(new Date(panelStartDate), new Date(panelEndDate))
            : granularity === 'd'
              ? getDaysArray(new Date(panelStartDate), new Date(panelEndDate))
              : granularity === 'm'
                ? getMonthsArray(panelStartDate, panelEndDate)
                : []

        if (granularity === 'd') data = data.map(day => [day])
        if (granularity === 'm') data = data.map(day => [day])

        consumptions.forEach(sensor => {
          const { attributes } = sensor
          const { consumptions: consumption, name } = attributes
          allSensorConsumptionData.push(sensor)

          const addConsumption = record => {
            const consumptionValuesMap = createConsumptionValuesMap(
              consumption.values,
              userLocales,
              format
            )
            const timepoint = formatDateToLocaleString(
              record[0],
              userLocales,
              format
            )

            const data = consumptionValuesMap.get(timepoint)

            const aggregated = data
              ? panelIndicator === indicatorType.cost
                ? data.cost === null
                  ? 0
                  : data.cost
                : panelIndicator === indicatorType.cost_per_sqm
                  ? data.cost_per_sqm === null
                    ? 0
                    : data.cost_per_sqm
                  : panelIndicator === indicatorType.co2
                    ? data.co2 === null
                      ? 0
                      : data.co2
                    : panelIndicator === indicatorType.co2_per_sqm
                      ? data.co2_per_sqm === null
                        ? 0
                        : data.co2_per_sqm
                      : panelIndicator === indicatorType.consumption
                        ? data.value === null
                          ? 0
                          : data.value
                        : panelIndicator === indicatorType.consumption_per_sqm
                          ? data.value_per_sqm === null
                            ? 0
                            : data.value_per_sqm
                          : 0
              : 0

            let roundedValue = aggregated != null ? +aggregated.toFixed(2) : 0
            if (
              panelIndicator === 2 ||
              panelIndicator === 4 ||
              panelIndicator === 6
            ) {
              roundedValue = roundedValue / consumptions.length
            }
            return [...record, roundedValue || 0]
          }

          columns = [...columns, name]

          data = data.map(record => addConsumption(record))
        })

        data.sort(
          (a, b) =>
            new Date(a[0]).getTime() > new Date(b[0]).getTime() ? 1 : -1
        )

        // fill columns data to minimum if no data
        if (data.length === 0) data = [granularity, granularity]

        // add columns header (sensor names) on top of data
        data = [columns, ...data]

        // add dummy data if no data to render
        if (data.length === 1) data.push(columns.map(() => 0))

        return data
      }

      const addTooltipColumnColumnChart = data => {
        const combined = []

        const dateFormat =
          panelGranularity === 'h'
            ? {
                weekday: 'short',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              }
            : panelGranularity === 'd'
              ? {
                  weekday: 'short',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }
              : panelGranularity === 'm'
                ? {
                    year: 'numeric',
                    month: 'long',
                  }
                : DDMMYYYY

        data.forEach((row, i) => {
          if (i === 0) {
            combined.push([row[i]])

            row.forEach((col, j) => {
              if (j > 0)
                combined[i].push(col, {
                  type: 'string',
                  role: 'tooltip',
                  p: { html: true },
                })
            })
          } else {
            combined[i] = [row[0]]
            row.forEach((col, j) => {
              if (j > 0) {
                const name = data[0][j]
                const unit = chartUnit
                const timestamp = combined[i][0].toLocaleString(
                  userLocales,
                  dateFormat
                )

                combined[i][0] = timestamp
                combined[i].push(
                  col,
                  `<div style="padding:5px 10px; min-width:220px;"><p><strong>${timestamp}</strong></p><p>${name}: <strong>${digitGrouping(
                    col
                  )} ${unit}</strong></p></div>`
                )
              }
            })
          }
        })

        return combined
      }

      if (panelPresentationLevel === 2) {
        let numberOfSensors = 0
        if (consumptions) {
          consumptions.map(item => {
            if (item) {
              const amount = calculateAmount(item)
              total = total + amount
              numberOfSensors++
            }

            return null
          })
          let data
          if (chartType === 'ColumnChart') {
            const chartData = createBaseDataColumnChart(panelGranularity)
            const chartDataWithToolTip = addTooltipColumnColumnChart(chartData)
            data = chartDataWithToolTip
          } else if (chartType === 'PieChart') {
            data = consumptions.map(item => {
              const sensor = getSensorById(item.id)
              if (sensor) {
                const { attributes } = sensor
                const { name } = attributes

                const amount = calculateAmount(item)

                const tooltipContent = `<div style="padding:5px 10px; min-width:220px;">
              <p>${name}: <strong>${digitGrouping(
                  amount
                )} ${chartUnit}</strong></p>
            </div>`
                return [name, amount, tooltipContent]
              }
              return null
            })
            data.unshift([
              'Messpunkt',
              'Verbrauch',
              {
                role: 'tooltip',
                type: 'string',
                p: { html: true },
              },
            ])
          } else {
            data = consumptions.map(item => {
              const sensor = getSensorById(item.id)
              if (sensor) {
                const { attributes } = sensor
                const { name, entity_id } = attributes

                const amount = calculateAmount(item)
                const folder = folderPool.find(f =>
                  f.attributes.folder_sensors.some(
                    sensor => Number(sensor.sensor_id) === Number(entity_id)
                  )
                )
                return [
                  `${name}  |  ${folder ? folder.attributes.name : ''}`,
                  {
                    v: amount,
                    f: ` ${digitGrouping(amount)} ${chartUnit}`,
                  },
                ]
              }
              return null
            })
            data.sort((a, b) => {
              const valueA = parseFloat(
                a[1].f.replace(/[^\d.,]/g, '').replace(',', '.')
              )
              const valueB = parseFloat(
                b[1].f.replace(/[^\d.,]/g, '').replace(',', '.')
              )

              return valueB - valueA
            })
            data.unshift(['Messpunkt', `${chartIndicator}`])
          }

          if (
            panelIndicator === 2 ||
            panelIndicator === 4 ||
            panelIndicator === 6
          ) {
            if (numberOfSensors > 0) {
              total /= numberOfSensors
            }
          }

          setNoPieChartData(total === 0)
          setChartData(data)
          setTotal(total)
        }
      } else {
        let _data = []
        let numberOfSensors = 0
        let numberOfFolders = 0
        extractedFolders.forEach(folderId => {
          const folder = folderPool.find(f => Number(f.id) === Number(folderId))
          if (!folder) return

          const { folder_sensors } = folder.attributes

          const targetSensorIds = folder_sensors.map(item =>
            item.sensor_id.toString()
          )

          const filteredConsumptions = consumptions.filter(item =>
            targetSensorIds.includes(item.id.toString())
          )
          numberOfSensors = numberOfSensors + filteredConsumptions.length

          if (!filteredConsumptions) return
        })

        extractedFolders.forEach(folderId => {
          const folder = folderPool.find(f => Number(f.id) === Number(folderId))
          if (!folder) return

          const { name, folder_sensors } = folder.attributes

          const targetSensorIds = folder_sensors.map(item =>
            item.sensor_id.toString()
          )

          const filteredConsumptions = consumptions.filter(item =>
            targetSensorIds.includes(item.id.toString())
          )

          if (!filteredConsumptions) return
          const numberOfSensors = filteredConsumptions.length
          numberOfFolders++

          filteredConsumptions.map(item => {
            if (item) {
              const amount = calculateAmount(item)
              if (
                panelIndicator === 2 ||
                panelIndicator === 4 ||
                panelIndicator === 6
              ) {
                totalObject = totalObject + amount / numberOfSensors
              } else {
                totalObject = totalObject + amount
              }
            }

            return null
          })
          let sensorsTotal = 0
          if (chartType === 'ColumnChart') {
            const chartData = createBaseDataFolderColumnChart(
              panelGranularity,
              filteredConsumptions
            )
            chartData.map((item, index) => {
              if (index === 0) return null
              const date = item[0]
              let sum = item.slice(1)
              sum = sum.reduce((partialSum, a) => partialSum + a, 0)
              chartData[index] = [date, sum]
              return null
            })
            chartData[0] = ['Zeit', name]
            _data.push(chartData)
          } else if (chartType === 'PieChart') {
            filteredConsumptions.map(item => {
              const sensor = getSensorById(item.id)
              if (sensor) {
                const amount = calculateAmount(item)
                sensorsTotal = sensorsTotal + amount
              }
              return null
            })
            if (
              panelIndicator === 2 ||
              panelIndicator === 4 ||
              panelIndicator === 6
            ) {
              sensorsTotal = sensorsTotal / numberOfSensors
            }
            const tooltipContent = `<div style="padding:5px 10px; min-width:220px;">
            <p>${name}: <strong>${digitGrouping(
              sensorsTotal
            )} ${chartUnit}</strong></p>
          </div>`
            _data.push([name, sensorsTotal, tooltipContent])
          } else {
            const data = filteredConsumptions.map(item => {
              const sensor = getSensorById(item.id)
              if (sensor) {
                const { attributes } = sensor
                const { entity_id } = attributes
                const amount = calculateAmount(item)
                sensorsTotal = sensorsTotal + amount
                const folder = folderPool.find(f =>
                  f.attributes.folder_sensors.some(
                    sensor => Number(sensor.sensor_id) === Number(entity_id)
                  )
                )
                return [
                  `${name}  |  ${folder ? folder.attributes.name : ''}`,
                  ` ${digitGrouping(amount)} ${chartUnit}`,
                ]
              }
              return null
            })
            data.sort((a, b) => {
              const valueA = parseFloat(
                a[1].replace(/[^\d.,]/g, '').replace(',', '.')
              )
              const valueB = parseFloat(
                b[1].replace(/[^\d.,]/g, '').replace(',', '.')
              )

              return valueB - valueA
            })
            if (
              panelIndicator === 2 ||
              panelIndicator === 4 ||
              panelIndicator === 6
            ) {
              sensorsTotal = sensorsTotal / numberOfSensors
            }
            _data.push([
              `${name}`,
              {
                v: sensorsTotal,
                f: ` ${digitGrouping(sensorsTotal)} ${chartUnit}`,
              },
            ])
          }
        })
        if (chartType === 'ColumnChart') {
          let combinedData = []
          if (_data.length > 0) {
            combinedData = _data[0]
            _data.forEach((objects, index) => {
              if (index === 0) return
              objects.forEach((object, i) => {
                combinedData[i].push(object[1])
              })
            })
          }
          _data = addTooltipColumnColumnChart(combinedData)
        }
        if (chartType === 'PieChart') {
          _data.unshift([
            'Messpunkt',
            'Verbrauch',
            {
              role: 'tooltip',
              type: 'string',
              p: { html: true },
            },
          ])
        }
        if (chartType === 'Table') {
          _data.unshift(['Gebäude ', `${chartIndicator}`])
        }

        if (
          panelIndicator === 2 ||
          panelIndicator === 4 ||
          panelIndicator === 6
        ) {
          if (numberOfFolders > 0) {
            totalObject /= numberOfFolders
          }
        }

        setChartData(_data)
        setTotal(totalObject)
      }
    },
    [
      activeFolders,
      activeSensors,
      chartIndicator,
      chartType,
      chartTypes.table,
      chartUnit,
      consumptions,
      folderPool,
      indicatorType.co2,
      indicatorType.co2_per_sqm,
      indicatorType.consumption,
      indicatorType.consumption_per_sqm,
      indicatorType.cost,
      indicatorType.cost_per_sqm,
      panel,
      panelEndDate,
      panelGranularity,
      panelGraphicType,
      panelIndicator,
      panelPresentationLevel,
      panelStartDate,
      sensorPool,
      sensorPricePool,
      panelUnit,
      extractedFolders,
    ]
  )

  const updatePanel = () => {
    const editPanel = {
      entity_id: panelID,
      dashboard_id: dashboardID,
      name: panelName,
      position: panelPositon,
      indicator: panelIndicator,
      graphic_type: panelGraphicType,
      start_date: new Date(panelStartDate).toISOString().split('T')[0],
      end_date: new Date(panelEndDate).toISOString().split('T')[0],
      presentaton_level: panelPresentationLevel,
      granularity: panelGranularity,
    }

    const editDataPoint = {
      panel_id: panelID,
      sensor_ids: activeSensors.filter(
        sensor => !warningSensorsIds.includes(sensor)
      ),
    }

    dispatch(dashboardActions.updatePanel(editPanel))
    dispatch(dashboardActions.updateDataPoints(editDataPoint, dashboardID))
  }

  const deletePanel = () =>
    dispatch(dashboardActions.removePanel(dashboardID, panelID))

  const _ENERGYDATA = usePagination(energyData, PER_PAGE)
  const _WATTERDATA = usePagination(waterData, PER_PAGE)
  const _GASDATA = usePagination(gasData, PER_PAGE)

  const handlePageChange = (event, p) => {
    setPage(p)
    _ENERGYDATA.jump(p)
    _WATTERDATA.jump(p)
    _GASDATA.jump(p)
  }

  const handleCheckedSensors = (entity_id, name, folderId, folderName) => {
    const _activeSensors = activeSensors.includes(entity_id)
      ? activeSensors.filter(f => f !== entity_id)
      : [...activeSensors, entity_id]

    const _activeSensorNames = activeSensorsNames.includes(name)
      ? activeSensorsNames.filter(f => f !== name)
      : [...activeSensorsNames, name]

    let _activeFolders = [...activeFolders]
    let _activeFoldersNames = [...activeFoldersNames]

    if (!_activeFolders.includes(folderId)) {
      _activeFolders.push(folderId)
      _activeFoldersNames.push(folderName)
    }

    const remainingSensors = sensorPool.some(
      sensor =>
        sensor.attributes.folder_id === folderId &&
        _activeSensors.includes(entity_id)
    )

    if (!remainingSensors && panelPresentationLevel === 2) {
      _activeFolders = _activeFolders.filter(f => f !== folderId)
      _activeFoldersNames = _activeFoldersNames.filter(f => f !== folderName)
    }

    setActiveSensors(_activeSensors)
    setActiveSensorsNames(_activeSensorNames)
    setActiveFolders(_activeFolders)
    setActiveFoldersNames(_activeFoldersNames)
  }

  const handleCheckedFolders = (entity_id, name) => {
    const _activeFolders = activeFolders.includes(entity_id)
      ? activeFolders.filter(f => f !== entity_id)
      : [...activeFolders, entity_id]

    const _activeFoldersNames = activeFoldersNames.includes(name)
      ? activeFoldersNames.filter(f => f !== name)
      : [...activeFoldersNames, name]

    let _activeSensors = [...activeSensors]
    let _activeSensorNames = [...activeSensorsNames]

    if (!_activeFolders.includes(entity_id)) {
      const remainingSensors = sensorPool.filter(
        sensor => sensor.attributes.folder_id === entity_id
      )
      const entityIds = remainingSensors.map(item => item.attributes.entity_id)
      _activeSensors = _activeSensors.filter(item => !entityIds.includes(item))
      _activeSensorNames = _activeSensorNames.filter(
        item => !item.includes(name)
      )
    }

    setActiveFolders(_activeFolders)
    setActiveFoldersNames(_activeFoldersNames)
    setActiveSensors(_activeSensors)
    setActiveSensorsNames(_activeSensorNames)
  }

  const displayFolders = data => {
    const FILTER_SEARCH = f =>
      f.attributes.name.toLowerCase().includes(foldersSearch.toLowerCase())

    const FILTER_SELECTED_FOLDERS = folder =>
      showSelectedFolders
        ? activeFolders.includes(folder.attributes.entity_id)
        : true

    return data
      .filter(FILTER_SEARCH)
      .filter(FILTER_SELECTED_FOLDERS)
      .map(folder => {
        const { name, entity_id } = folder.attributes
        const checked = activeFolders.includes(Number(entity_id))
        const onChange = () => handleCheckedFolders(entity_id, name)
        const control = (
          <Checkbox checked={checked} name={name} onChange={onChange} />
        )

        return (
          <FormControlLabel control={control} key={entity_id} label={name} />
        )
      })
  }

  const displaySensors = data =>
    data.currentData().map(sensor => {
      const { entity_id, folder_id, name: sensorName } = sensor.attributes
      const folder = folderPool.find(f => Number(f.id) === Number(folder_id))
      const folderName = folder ? folder.attributes.name : ''

      const label = `${sensorName} | Gebäude ${folderName}`

      const checked = activeSensors.includes(entity_id)
      const onChange = () =>
        handleCheckedSensors(entity_id, label, folder_id, folderName)
      const control = (
        <Checkbox checked={checked} name={label} onChange={onChange} />
      )

      return (
        <FormControlLabel control={control} key={entity_id} label={label} />
      )
    })

  const displayNames = (data, type) =>
    data.map((name, key) => {
      const color = colorSchemeConsumption[key % colorSchemeConsumption.length]
      const icon = type === 'sensor' ? <MemoryTwoTone /> : <HomeTwoTone />
      return (
        <ListItem key={key}>
          <ListItemIcon sx={{ color }}>{icon}</ListItemIcon>

          <ListItemText
            primary={<span style={{ fontSize: '0.75rem' }}>{name}</span>}
          />
        </ListItem>
      )
    })

  const handleToggleAllSensors = (event, type) => {
    const sensors =
      type === 'energy'
        ? [...energyData]
        : type === 'water'
          ? [...waterData]
          : type === 'gas'
            ? [...gasData]
            : null
    const panelUnit = type === 'water' ? 'm³' : 'kWh'

    const updatedEntityIds = []
    const updatedSensorNames = []
    const uptadedFolderIds = []
    const uptadedFolderNames = []
    let _activeFolders = [...activeFolders]
    let _activeFoldersNames = [...activeFoldersNames]

    sensors.map(sensor => {
      updatedEntityIds.push(sensor.attributes.entity_id)
      const folder = folderPool.find(
        f => Number(f.id) === Number(sensor.attributes.folder_id)
      )
      const folderName = folder ? folder.attributes.name : ''

      const label = `${sensor.attributes.name} | Gebäude ${folderName}`
      updatedSensorNames.push(label)
      uptadedFolderNames.push(uptadedFolderNames)
      uptadedFolderIds.push(sensor.attributes.folder_id)

      if (folder && !_activeFolders.includes(folder.attributes.entity_id)) {
        _activeFolders.push(folder.attributes.entity_id)
        _activeFoldersNames.push(folderName)
      }
      return null
    })

    let updatedActiveEntityIds = event.target.checked
      ? [...activeSensors, ...updatedEntityIds]
      : activeSensors.filter(entityId => !updatedEntityIds.includes(entityId))
    let updatedActiveNames = event.target.checked
      ? [...activeSensorsNames, ...updatedSensorNames]
      : activeSensors.filter(name => !updatedSensorNames.includes(name))

    if (event.target.checked === false && updatedActiveEntityIds.length === 0) {
      _activeFolders = []
      _activeFoldersNames = []
      updatedActiveEntityIds = []
      updatedActiveNames = []
    }

    setActiveSensors(updatedActiveEntityIds)
    setActiveSensorsNames(updatedActiveNames)
    setActiveFolders(_activeFolders)
    setActiveFoldersNames(_activeFoldersNames)
    setPanelUnit(panelUnit)
  }

  useEffect(
    () => {
      if (!sensorPool || !folderPool) return

      const FILTER_INDICATOR = sensor =>
        indicator === indicatorType.consumption
          ? true
          : indicator === indicatorType.consumption_per_sqm
            ? sensor.attributes.area_in_sqm !== null
            : indicator === indicatorType.cost
              ? sensorPricePool.find(
                  s => s.sensor_id === sensor.attributes.entity_id
                )
              : indicator === indicatorType.cost_per_sqm
                ? sensorPricePool.find(
                    s => s.sensor_id === sensor.attributes.entity_id
                  ) && sensor.attributes.area_in_sqm !== null
                : indicator === indicatorType.co2
                  ? sensor.attributes.co2_factor !== null
                  : sensor.attributes.co2_factor !== null &&
                    sensor.attributes.area_in_sqm !== null

      const FILTER_FOLDERS = f =>
        panelPresentationLevel === 1
          ? activeFolders.includes(Number(f.attributes.folder_id))
          : true

      const FILTER_FOLDER_SENSORS = sensor =>
        showFolderSensors ? sensor.attributes.folder_id !== null : true

      const FILTER_SELECTED_FOLDER_SENSORS = sensor =>
        showSelectedFolderSensors
          ? activeSensors.includes(sensor.attributes.entity_id)
          : true

      const FILTER_SEARCH = sensor => {
        const { name, folder_id } = sensor.attributes
        const folder = folderPool.find(f => Number(f.id) === Number(folder_id))
        const label = `${name}  |  ${folder ? folder.attributes.name : ''}`

        return label.toLowerCase().includes(sensorsSearch.toLowerCase())
      }

      const FILTER_BY_TYPE = type =>
        sensorPool
          .filter(
            sensor =>
              sensor.attributes.name !== null &&
              (sensor.attributes.type_in_folder === type ||
                sensor.attributes.type_in_folder === `${type}_detail`)
          )
          .filter(FILTER_INDICATOR)
          .filter(FILTER_SEARCH)
          .filter(FILTER_FOLDERS)
          .filter(FILTER_FOLDER_SENSORS)
          .filter(FILTER_SELECTED_FOLDER_SENSORS)

      const count = Math.ceil(FILTER_BY_TYPE('energy').length / PER_PAGE)
      setCount(count)

      setEnergyData(FILTER_BY_TYPE('energy'))
      setWaterData(FILTER_BY_TYPE('water'))
      setGasData(FILTER_BY_TYPE('gas'))
    },
    [
      sensorsSearch,
      activeFolders,
      sensorPool,
      panelPresentationLevel,
      folderPool,
      indicator,
      indicatorType.consumption,
      indicatorType.consumption_per_sqm,
      indicatorType.cost,
      indicatorType.cost_per_sqm,
      indicatorType.co2,
      sensorPricePool,
      showFolderSensors,
      showSelectedFolderSensors,
      activeSensors,
    ]
  )

  useEffect(
    () => {
      let _showWarning = false
      let _warningText = ''
      const _warningSensors = []
      const _warningSensorsIds = []

      const indicatorType = {
        consumption: 1,
        consumption_per_sqm: 2,
        cost: 3,
        cost_per_sqm: 4,
        co2: 5,
        co2_per_sqm: 6,
      }

      const getSensorById = id =>
        sensorPool.find(s => Number(s.id) === Number(id))
      const getConsumptionBySensorById = id =>
        consumptions.find(s => Number(s.id) === Number(id))
      const indicatorWarnings = {
        [indicatorType.consumption]:
          'Es gibt Sensoren ohne passenden Indikator: Verbrauch',
        [indicatorType.consumption_per_sqm]:
          'Es gibt Sensoren ohne passende Indikatoren: Verbrauch & Fläche',
        [indicatorType.cost]:
          'Es gibt Sensoren ohne passenden Indikator: Kosten',
        [indicatorType.cost_per_sqm]:
          'Es gibt Sensoren ohne passende Indikatoren: Kosten & Fläche',
        [indicatorType.co2]: 'Es gibt Sensoren ohne passenden Indikator: CO2',
        [indicatorType.co2_per_sqm]:
          'Es gibt Sensoren ohne passende Indikatoren: CO2 & Fläche',
      }

      const indicatorAttributes = {
        [indicatorType.consumption]: 'total_consumption',
        [indicatorType.consumption_per_sqm]: 'total_consumption_per_sqm',
        [indicatorType.cost]: 'total_cost',
        [indicatorType.cost_per_sqm]: 'total_cost_per_sqm',
        [indicatorType.co2]: 'total_co2',
        [indicatorType.co2_per_sqm]: 'total_co2_per_sqm',
      }

      if (activeSensors.length > 1) {
        activeSensors.forEach(sensorId => {
          const sensor = getSensorById(sensorId)
          const sensorConsumption = getConsumptionBySensorById(sensorId)

          if (!sensor) return

          const attribute = indicatorAttributes[panelIndicator]
          const warningText = indicatorWarnings[panelIndicator]

          if (sensorConsumption?.attributes.consumptions[attribute] === null) {
            _showWarning = true
            _warningText = warningText
            _warningSensors.push(sensor.attributes.name)
            _warningSensorsIds.push(sensor.attributes.entity_id)
          }
        })
      }
      setShowWarning(_showWarning)
      setWarningText(_warningText)
      setWarningSensors(_warningSensors)
      setWarningSensorsIds(_warningSensorsIds)
    },
    [activeSensors, consumptions, panelIndicator, sensorPool]
  )

  const listSource =
    panelPresentationLevel === 2
      ? [...new Set(extractedSensorNames)]
      : [...new Set(extractedFolderNames)]
  const listLabel = panelPresentationLevel === 2 ? 'sensor' : 'folder'
  const chartAreaHeight = '500px'
  const chartAreaWidth = '600px'
  const chartAreaTop =
    chartType === 'PieChart' ? 32 : chartType === 'ColumnChart' ? 32 : 0
  const chartAreaLeft =
    chartType === 'PieChart' ? 32 : chartType === 'ColumnChart' ? 64 : 0
  const chartAreaBottom =
    chartType === 'PieChart' ? 32 : chartType === 'ColumnChart' ? 56 : 0
  const chartAreaRight =
    chartType === 'PieChart' ? 32 : chartType === 'ColumnChart' ? 0 : 0

  return (
    <Card
      sx={{
        minWidth: 275,
        position: 'relative',
        border: 'solid 1px #ccc',
        borderRadius: 5,
        m: 1,
      }}
    >
      <CardHeader
        action={
          <>
            {chartType === 'ColumnChart' && (
              <ToggleButtonGroup size={'small'} exclusive>
                {[
                  { icon: <MoreHorizTwoTone />, tooltip: 'horizontal stapeln' },
                  { icon: <MoreVertTwoTone />, tooltip: 'vertikal stapeln' },
                ].map((item, key) => {
                  const { icon, tooltip } = item

                  return (
                    <ToggleButton
                      key={key}
                      value={Boolean(key)}
                      onClick={() => setPanelColumnStacked(Boolean(key))}
                      selected={panelColumnStacked === Boolean(key)}
                    >
                      <Tooltip title={tooltip}>{icon}</Tooltip>
                    </ToggleButton>
                  )
                })}
                )
              </ToggleButtonGroup>
            )}
            <Tooltip title={'Einstellungen'}>
              <IconButton onClick={() => setEditPanelAnchorEl(true)}>
                <EditTwoTone />
              </IconButton>
            </Tooltip>
          </>
        }
        avatar={
          <Avatar>
            {panelPresentationLevel === 2 ? (
              <MemoryTwoTone />
            ) : (
              <AccountBalanceTwoTone />
            )}
          </Avatar>
        }
        subheader={`${chartIndicator}: ${digitGrouping(total)} ${chartUnit}`}
        title={panelName}
      />
      <Divider />
      <CardContent sx={{ p: '0 !important' }}>
        <Collapse in={showWarning} sx={{ position: 'relative' }}>
          <Alert severity={'warning'}>{`${warningText}`}</Alert>
          <Tooltip title={'Messpunkte in Übersicht'}>
            <IconButton
              onClick={event =>
                setWarningAnchorEl(anchorEl ? null : event.currentTarget)
              }
              sx={{ position: 'absolute', top: 5, right: 20 }}
            >
              <InfoTwoTone />
            </IconButton>
          </Tooltip>

          <Popover
            anchorEl={warningAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            onClose={() => setWarningAnchorEl(false)}
            open={Boolean(warningAnchorEl)}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Box>
              <Typography sx={{ fontWeight: 700, px: 2, py: 1 }}>
                Messpunkte in Übersicht
              </Typography>

              <Divider />

              <List sx={{ p: 1 }}>
                {warningSensors.map((sensor, key) => (
                  <ListItem key={key}>
                    <ListItemText sx={{ fontWeight: 10 }} primary={sensor} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Popover>
        </Collapse>
        {chartData.length === 0 ? (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress size={200} />
          </Box>
        ) : (
          <>
            {noPieChartData && chartType === 'PieChart' ? (
              <Collapse in={noPieChartData}>
                <Alert severity={'warning'}>
                  Keine Daten zum Darstellen für diesen Zeitraum
                </Alert>
              </Collapse>
            ) : (
              <Stack direction={'row'}>
                <Chart
                  chartLanguage={'de_DE'}
                  chartType={chartType}
                  data={chartData}
                  options={{
                    chartArea: {
                      height: chartAreaHeight,
                      width: chartAreaWidth,
                      top: chartAreaTop,
                      left: chartAreaLeft,
                      bottom: chartAreaBottom,
                      right: chartAreaRight,
                    },
                    height: '500px',
                    width: '500px',
                    backgroundColor: 'white',
                    tooltip: { isHtml: true },
                    legend: 'none',
                    vAxis: { format: `#,###.## ${panelUnit}` },
                    hAxis: {
                      viewWindow: {
                        min:
                          activeSensorsNames.length === 0
                            ? new Date(panelStartDate)
                            : undefined,
                        max:
                          activeSensorsNames.length === 0
                            ? new Date(panelEndDate)
                            : undefined,
                      },
                      ticks: [
                        ...new Set(
                          chartData
                            .map((item, key) => {
                              if (key === 0) return null
                              return { v: item[0], f: item[0] }
                            })
                            .filter(f => f !== null)
                        ),
                      ],
                      textStyle: {
                        fontSize: 10,
                      },
                    },
                    colors: colorSchemeConsumption,
                    sliceVisibilityThreshold: 0,
                    isStacked: panelColumnStacked,
                    pieStartAngle: 100,
                    sortColumn: 1,
                    sortAscending: false,
                    sort: 'enable',
                  }}
                />
                {chartType !== 'Table' && (
                  <Stack>
                    <Tooltip
                      title={
                        showChartLegend === true
                          ? 'Weniger anzeigen'
                          : 'Mehr anzeigen'
                      }
                    >
                      <IconButton
                        onClick={() => setShowChartLegend(prev => !prev)}
                      >
                        {showChartLegend === true ? (
                          <ArrowBackTwoTone />
                        ) : (
                          <ArrowForwardTwoTone />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
                {(chartType === 'PieChart' || chartType === 'ColumnChart') &&
                  showChartLegend === true && (
                    <List sx={{ borderLeft: 'solid 1px #ccc' }}>
                      {displayNames(listSource, listLabel)}
                    </List>
                  )}
              </Stack>
            )}
          </>
        )}
      </CardContent>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(false)}
      >
        <MenuItem
          caption={'bearbeiten'}
          onClick={() => {
            setEditPanelAnchorEl(true)
            setAnchorEl(null)
          }}
        >
          <ListItemIcon>
            <CreateTwoTone />
          </ListItemIcon>
          <ListItemText>bearbeiten</ListItemText>
        </MenuItem>
        <MenuItem
          caption={'Löschen'}
          onClick={() => {
            setDeletePanelAnchorEl(true)
            setAnchorEl(null)
          }}
        >
          <ListItemIcon>
            <DeleteTwoTone />
          </ListItemIcon>
          <ListItemText>Löschen</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={() => {
          setEditPanelAnchorEl(null)
          setActiveSensors([...new Set(extractedSensorIDs)])
          setActiveFolders([...new Set(extractedFolderIDs)])
          setActiveFoldersNames([...new Set(extractedFolderNames)])
          setActiveSensorsNames([...new Set(extractedSensorNames)])
        }}
        open={Boolean(editPanelAnchorEl)}
      >
        <DialogTitle>Kachel Einstellungen</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Stack direction={'row'}>
            {/* Name */}
            <TextField
              fullWidth
              label={'Kachel Name'}
              onChange={event => setPanelName(event.target.value)}
              value={panelName || ''}
            />
            {/* Indikator */}
            <TextField
              fullWidth
              label={'Indikator'}
              onChange={event => {
                setPanelIndicator(event.target.value)
              }}
              select
              value={panelIndicator || ''}
            >
              {indicatorMenuData.map((item, key) => (
                <MenuItem key={key} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </TextField>
            {/* graphic type */}
            <TextField
              fullWidth
              label={'Grafiktyp'}
              onChange={event => setPanelGraphicType(event.target.value)}
              select
              value={panelGraphicType || ''}
            >
              {graphicTypeMenuData.map((item, key) => (
                <MenuItem key={key} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          {/* presentation Level */}
          <FormControl>
            <FormLabel>
              Darstellungsebene{' '}
              <Tooltip
                title={
                  'Eine Kachel kann auf Gebäude- oder Messpunktebene erstellt werden. In der Gebäudeebene werden alle ausgewählten Messpunkte unter dem Gebäudenamen zusammengefasst. In der Messpunktebene wird jeder Messpunkt einzeln aufgelistet.'
                }
              >
                <InfoTwoTone />
              </Tooltip>
            </FormLabel>
            <RadioGroup
              onChange={event => {
                setPanelPresentationLevel(Number(event.target.value))
                setActiveFolders([])
                setActiveSensors([])
                setActiveFoldersNames([])
                setActiveSensorsNames([])
                setEnergyChecked(false)
                setWaterChecked(false)
                setGasChecked(false)
              }}
              row
              value={panelPresentationLevel}
            >
              <FormControlLabel
                value={'1'}
                control={<Radio />}
                label={'Gebäude'}
                labelPlacement={'start'}
              />
              <FormControlLabel
                value={'2'}
                control={<Radio />}
                label={'Messpunkt'}
                labelPlacement={'start'}
              />
            </RadioGroup>
          </FormControl>

          {panelPresentationLevel === 1 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
                <Typography>
                  {`Gebäude (${activeFolders.length} / ${folderPool.length})`}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container spacing={2} sx={{ overflow: 'auto' }}>
                  <Grid item xs={12} sm={12}>
                    <FormGroup>
                      <FormControlLabel
                        label={'Nur ausgewählte Gebäude anzeigen'}
                        control={
                          <Checkbox
                            checked={showSelectedFolders}
                            onClick={() =>
                              setShowSelectedFolders(!showSelectedFolders)
                            }
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Input
                      onChange={event => setFoldersSearch(event.target.value)}
                      placeholder={'Suchen...'}
                      sx={{ m: 0, minHeight: 23, pl: 1 }}
                      value={foldersSearch}
                      variant={'standard'}
                    />
                    <FormGroup>{displayFolders(folderPool)}</FormGroup>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
              <Typography>
                {`Messpunkte (${activeSensors.length} / ${sensorPool.length})`}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                {operatorData?.isAdmin && (
                  <Grid item xs={12} sm={12}>
                    <FormGroup>
                      <FormControlLabel
                        label={'Nur zugeordnete Sensoren anzeigen'}
                        control={
                          <Checkbox
                            checked={showFolderSensors}
                            onClick={() =>
                              setShowFolderSensors(!showFolderSensors)
                            }
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                )}

                <Grid item xs={12} sm={12}>
                  <FormGroup>
                    <FormControlLabel
                      label={'Nur ausgewählte Sensoren anzeigen'}
                      control={
                        <Checkbox
                          checked={showSelectedFolderSensors}
                          onClick={() =>
                            setShowSelectedFolderSensors(
                              !showSelectedFolderSensors
                            )
                          }
                        />
                      }
                    />
                  </FormGroup>
                </Grid>

                {/* Strom */}
                <Grid item xs={12} sm={4}>
                  <FormGroup>
                    <FormControlLabel
                      label={'Strom'}
                      control={
                        <Checkbox
                          checked={energyChecked}
                          onChange={event => {
                            handleToggleAllSensors(event, 'energy')
                            setEnergyChecked(!energyChecked)
                          }}
                          disabled={gasChecked || waterChecked}
                        />
                      }
                    />
                    {displaySensors(_ENERGYDATA, 'energy')}
                  </FormGroup>
                </Grid>

                {/* Water */}
                <Grid item xs={12} sm={4}>
                  <FormGroup>
                    <FormControlLabel
                      label={'Wasser'}
                      control={
                        <Checkbox
                          checked={waterChecked}
                          onChange={event => {
                            handleToggleAllSensors(event, 'water')
                            setWaterChecked(!waterChecked)
                          }}
                          disabled={gasChecked || energyChecked}
                        />
                      }
                    />
                    {displaySensors(_WATTERDATA, 'water')}
                  </FormGroup>
                </Grid>

                {/* Gas */}
                <Grid item xs={12} sm={4}>
                  <FormGroup>
                    <FormControlLabel
                      label={'Wärme'}
                      control={
                        <Checkbox
                          checked={gasChecked}
                          onChange={event => {
                            handleToggleAllSensors(event, 'gas')
                            setGasChecked(!gasChecked)
                          }}
                          disabled={energyChecked || waterChecked}
                        />
                      }
                    />
                    {displaySensors(_GASDATA, 'gas')}
                  </FormGroup>
                </Grid>
              </Grid>

              <Input
                onChange={event => setSensorsSearch(event.target.value)}
                placeholder={'Suchen...'}
                sx={{ m: 0, minHeight: 23, pl: 1 }}
                value={sensorsSearch}
                variant={'standard'}
              />
              <Pagination
                count={count}
                size={'large'}
                page={page}
                variant={'outlined'}
                shape={'rounded'}
                onChange={handlePageChange}
              />
            </AccordionDetails>
          </Accordion>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => deletePanel(panelID)}>
            <DeleteTwoTone />
            Löschen
          </Button>
          <Button onClick={() => setEditPanelSensorAnchorEl(true)}>
            Zusammenfassung
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={() => setEditPanelSensorAnchorEl(null)}
        open={Boolean(editPanelSensorAnchorEl)}
      >
        <DialogTitle>Zusammenfassung</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {activeFolders.length > 0 && (
                <Stack sx={{ border: 'solid 1px #eee', borderRadius: 3 }}>
                  <Typography sx={{ m: 2 }}>Ausgewählte Gebäude</Typography>
                  <Divider />
                  <List sx={{ maxHeight: 400, overflow: 'auto' }}>
                    {activeFoldersNames.map((folder, key) => (
                      <ListItem key={key}>
                        <ListItemText primary={folder} />
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {activeSensors.length > 0 && (
                <Stack sx={{ border: 'solid 1px #eee', borderRadius: 3 }}>
                  <Typography sx={{ m: 2 }}>Ausgewählte Messpunkte</Typography>
                  <Divider />
                  <List sx={{ maxHeight: 400, overflow: 'auto' }}>
                    {activeSensorsNames.map((sensor, key) => (
                      <ListItem key={key}>
                        <ListItemText
                          primary={sensor}
                          sx={{
                            color: warningSensors.some(str =>
                              sensor.includes(str)
                            )
                              ? 'red'
                              : 'inherit',
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setEditPanelSensorAnchorEl(null)}>
            Zurück
          </Button>
          <Button onClick={() => updatePanel(panelID)}>
            <SaveTwoTone />
            Aktualisieren
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={() => setDeletePanelAnchorEl(null)}
        open={Boolean(deletePanelAnchorEl)}
      >
        <DialogTitle>Bestätigung erforderlich</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Alert severity={'info'} sx={{ p: 2 }}>
            Möchten Sie Kachel <strong>{panelName}</strong> wirklich löschen?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button color={'error'} onClick={() => deletePanel(panelID)}>
            <DeleteTwoTone />
            Löschen
          </Button>
          <Button onClick={() => setDeletePanelAnchorEl(false)}>
            <CancelTwoTone />
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

PanelComponent.propTypes = {
  panel: PropTypes.object,
  consumptions: PropTypes.array,
  panelStartDate: PropTypes.string,
  panelEndDate: PropTypes.string,
  panelGranularity: PropTypes.string,
  cardStyle: PropTypes.object,
  sensorPool: PropTypes.array,
  sensorPricePool: PropTypes.array,
  folderPool: PropTypes.array,
}
