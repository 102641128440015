export const folderConstants = {
  DELETE_FOLDER_ERROR: 'FOLDER_DELETE_FOLDER_ERROR',
  DELETE_FOLDER_REQUEST: 'FOLDER_DELETE_FOLDER_REQUEST',
  DELETE_FOLDER_SUCCESS: 'FOLDER_DELETE_FOLDER_SUCCESS',

  FOLDER_DELETED: 'Gebäude gelöscht',
  FOLDER_ID_REQUIRED: 'Gebäude ID erforderlich',
  FOLDER_NAME_REQUIRED: 'Name erforderlich',
  FOLDER_SAVED: 'Gebäude gespeichert',
  FOLDER_UPDATED: 'Gebäude aktualisiert',

  GET_ALL_ERROR: 'FOLDER_GET_ALL_ERROR',
  GET_ALL_REQUEST: 'FOLDER_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'FOLDER_GET_ALL_SUCCESS',

  GET_BY_ID_ERROR: 'FOLDER_GET_BY_ID_ERROR',
  GET_BY_ID_REQUEST: 'FOLDER_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'FOLDER_GET_BY_ID_SUCCESS',

  GET_CUSTOM_ENERGY_CONSUMPTION_ERROR:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_ERROR',
  GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST',
  GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS',

  GET_COUNTER_READING_ERROR: 'FOLDER_GET_COUNTER_READING_ERROR',
  GET_COUNTER_READING_REQUEST: 'FOLDER_GET_COUNTER_READING_REQUEST',
  GET_COUNTER_READING_SUCCESS: 'FOLDER_GET_COUNTER_READING_SUCCESS',

  GET_CUSTOM_ENERGY_YEAR_CONSUMPTION_ERROR:
    'FOLDER_GET_CUSTOM_ENERGY_YEAR_CONSUMPTION_ERROR',
  GET_CUSTOM_ENERGY_YEAR_CONSUMPTION_REQUEST:
    'FOLDER_GET_CUSTOM_ENERGY_YEAR_CONSUMPTION_REQUEST',
  GET_CUSTOM_ENERGY_YEAR_CONSUMPTION_SUCCESS:
    'FOLDER_GET_CUSTOM_ENERGY_YEAR__CONSUMPTION_SUCCESS',

  GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_ERROR:
    'FOLDER_GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_ERROR',
  GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_REQUEST:
    'FOLDER_GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_REQUEST',
  GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_SUCCESS:
    'FOLDER_GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_SUCCESS',

  GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_24:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_24',
  GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_24:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_24',
  GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_24:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_24',

  GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_MONTHLY:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_MONTHLY',
  GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_MONTHLY:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_MONTHLY',
  GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_MONTHLY:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_MONTHLY',

  GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_YEARLY:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_YEARLY',
  GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_YEARLY:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_YEARLY',
  GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_YEARLY:
    'FOLDER_GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_YEARLY',

  GET_ENERGY_CONSUMPTION_ERROR: 'FOLDER_GET_ENERGY_CONSUMPTION_ERROR',
  GET_ENERGY_CONSUMPTION_REQUEST: 'FOLDER_GET_ENERGY_CONSUMPTION_REQUEST',
  GET_ENERGY_CONSUMPTION_SUCCESS: 'FOLDER_GET_ENERGY_CONSUMPTION_SUCCESS',

  SAVE_FOLDER_ERROR: 'FOLDER_SAVE_FOLDER_ERROR',
  SAVE_FOLDER_REQUEST: 'FOLDER_SAVE_FOLDER_REQUEST',
  SAVE_FOLDER_SUCCESS: 'FOLDER_SAVE_FOLDER_SUCCESS',

  SAVE_HELP_LOCATION_ERROR: 'FOLDER_SAVE_HELP_LOCATION_ERROR',
  SAVE_HELP_LOCATION_REQUEST: 'FOLDER_SAVE_HELP_LOCATION_REQUEST',
  SAVE_HELP_LOCATION_SUCCESS: 'FOLDER_SAVE_HELP_LOCATION_SUCCESS',

  SAVE_OFFICE_HOURS_ERROR: 'FOLDER_SAVE_OFFICE_HOURS_ERROR',
  SAVE_OFFICE_HOURS_REQUEST: 'FOLDER_SAVE_OFFICE_HOURS_REQUEST',
  SAVE_OFFICE_HOURS_SUCCESS: 'FOLDER_SAVE_OFFICE_HOURS_SUCCESS',

  UPDATE_FOLDER_ERROR: 'FOLDER_UPDATE_FOLDER_ERROR',
  UPDATE_FOLDER_REQUEST: 'FOLDER_UPDATE_FOLDER_REQUEST',
  UPDATE_FOLDER_SUCCESS: 'FOLDER_UPDATE_FOLDER_SUCCESS',

  TYPE_ANY: '',
  TYPE_DEFAULT: 'Gebäude',
  TYPE_SUB: 'untergebäude',
}
