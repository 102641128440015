import { MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const FilterStatus = ({
  column,
  label = 'Alle Status',
  width = 130,
}) => {
  const { filterValue, setFilter } = column
  const NO_FILTER = 'all'

  const data = [
    { label: 'Grün', value: 'green' },
    { label: 'Gelb', value: 'yellow' },
    { label: 'Rot', value: 'red' },
  ]

  const onChange = event => {
    const value = event.target.value

    setFilter(value === NO_FILTER ? undefined : value || undefined)
  }

  const value = filterValue ?? NO_FILTER

  return (
    <Select onChange={onChange} sx={{ width }} value={value}>
      <MenuItem value={NO_FILTER}>{label}</MenuItem>

      {data.map((item, key) => (
        <MenuItem key={key} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  )
}

FilterStatus.propTypes = {
  column: PropTypes.object,
  label: PropTypes.string,
  width: PropTypes.number,
}
