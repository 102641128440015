import { Alert, AlertTitle, Typography } from '@mui/material'
import React from 'react'

export const AboutAboRecipients = () => (
  <Alert severity={'info'} sx={{ my: 2 }}>
    <AlertTitle>Hinzufügen von E-Mail Adressen zur Empfänger-Liste</AlertTitle>

    <Typography>
      Tragen Sie eine E-Mail Adresse ein, die zur Empfänger-Liste hinzugefügt
      werden soll. Klicken Sie zum Bestätigen auf <strong>+</strong>.
    </Typography>

    <Typography>
      Wiederholen Sie diesen Vorgang, bis Sie mit der Empfänger-Liste zufrieden
      sind und klicken Sie dann auf <strong>Abonnieren</strong> bzw.{' '}
      <strong>Abo aktualisieren</strong>.
    </Typography>

    <Typography sx={{ mt: 2 }}>
      Hinweis: Das <strong>+</strong> Zeichen ist solange deaktiviert, bis die
      E-Mail Adresse ein gültiges Schema enthält.
    </Typography>

    <Typography>
      Hinweis: Unerwünschte E-Mail Adressen lassen sich über den Papierkorb
      wieder entfernen.
    </Typography>
  </Alert>
)
