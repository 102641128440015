import {
  KeyboardDoubleArrowLeftTwoTone,
  KeyboardDoubleArrowRightTwoTone,
} from '@mui/icons-material'
import { IconButton, Stack, TextField, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { getNextMonth, getPrevMonth, userLocales } from '../../../api'

export const AdHoc = ({
  reportDate: initialReportDate,
  contractBegin,
  contractExpire,
  onChange,
}) => {
  const [isLeftButtonDisabled, setIsLeftButtonDisabled] = useState(true)
  const [isRightButtonDisabled, setIsRightButtonDisabled] = useState(true)

  const options = { month: 'long', year: 'numeric' }

  // ad-hoc related
  const [reportDate, setReportDate] = useState(initialReportDate)

  const TOOLTIP_FUTURE_REPORT_WARNING = `Der Bericht für ${getNextMonth(
    reportDate
  ).toLocaleDateString(userLocales, options)} steht ab 1. ${getNextMonth(
    reportDate,
    2
  ).toLocaleDateString(userLocales, options)} zur Verfügung.`

  const TOOLTIP_CONTRACT_START = `Vertragsbeginn ist ${new Date(
    contractBegin
  ).toLocaleDateString(
    userLocales,
    options
  )}. Für den Zeitraum davor stehen keine Berichte zur Verfügung.`

  const TOOLTIP_PREV_MONTH = `${getPrevMonth(reportDate).toLocaleDateString(
    userLocales,
    options
  )}`

  const TOOLTIP_NEXT_MONTH = `${getNextMonth(reportDate).toLocaleDateString(
    userLocales,
    options
  )}`

  const renderedReportDate = `${reportDate.getFullYear()}-${String(
    reportDate.getMonth() + 1
  ).padStart(2, '0')}`

  const contractMinDate = `${contractBegin?.getFullYear()}-${String(
    contractBegin?.getMonth() + 1
  ).padStart(2, '0')}`

  const contractMaxDate = `${contractExpire?.getFullYear()}-${String(
    contractExpire?.getMonth() + 1
  ).padStart(2, '0')}`

  const handlePrevMonth = () =>
    setReportDate(prev => {
      const tmp = new Date(prev)
      tmp.setMonth(prev.getMonth() - 1)
      return tmp
    })

  const handleNextMonth = () =>
    setReportDate(prev => {
      const maxDate = new Date()
      maxDate.setMonth(maxDate.getMonth() - 1)

      const newDate = new Date(prev)
      newDate.setMonth(prev.getMonth() + 1)

      if (
        newDate.getFullYear() > maxDate.getFullYear() ||
        (newDate.getFullYear() === maxDate.getFullYear() &&
          newDate.getMonth() > maxDate.getMonth())
      )
        return prev

      return newDate
    })

  const onSelectReportDate = event => {
    const { value: requestDate } = event.target
    const _DATE = requestDate.split('-')

    const newDate = new Date()
    newDate.setMonth(Number(_DATE[1]) - 1)
    newDate.setYear(Number(_DATE[0]))

    setReportDate(newDate)
  }

  // handle disabling of monthwise nav arrow buttons
  useEffect(
    () => {
      const handleNavButtons = () => {
        const maxDate = new Date()
        maxDate.setMonth(maxDate.getMonth() - 1)

        const isLeftDisabled =
          reportDate.getFullYear() < contractBegin?.getFullYear() ||
          (reportDate.getFullYear() === contractBegin?.getFullYear() &&
            reportDate.getMonth() <= contractBegin?.getMonth())

        const isRightDisabled =
          reportDate.getFullYear() > maxDate.getFullYear() ||
          (reportDate.getFullYear() === maxDate.getFullYear() &&
            reportDate.getMonth() >= maxDate.getMonth())

        setIsLeftButtonDisabled(isLeftDisabled)
        setIsRightButtonDisabled(isRightDisabled)
      }

      handleNavButtons()
    },
    [reportDate, contractBegin]
  )

  useEffect(
    () => {
      onChange(reportDate)
    },
    [onChange, reportDate]
  )

  return (
    <Stack alignItems={'center'} direction={'row'}>
      <Stack>
        <Tooltip
          title={
            isLeftButtonDisabled ? TOOLTIP_CONTRACT_START : TOOLTIP_PREV_MONTH
          }
        >
          <span>
            <IconButton
              disabled={isLeftButtonDisabled}
              onClick={handlePrevMonth}
            >
              <KeyboardDoubleArrowLeftTwoTone />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>

      <TextField
        fullWidth
        InputProps={{
          inputProps: {
            min: contractMinDate,
            max: contractMaxDate,
          },
        }}
        label={'Zeitraum'}
        onChange={onSelectReportDate}
        type={'month'}
        value={renderedReportDate}
      />

      <Stack>
        <Tooltip
          title={
            isRightButtonDisabled
              ? TOOLTIP_FUTURE_REPORT_WARNING
              : TOOLTIP_NEXT_MONTH
          }
        >
          <span>
            <IconButton
              disabled={isRightButtonDisabled}
              onClick={handleNextMonth}
            >
              <KeyboardDoubleArrowRightTwoTone />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

AdHoc.propTypes = {
  reportDate: PropTypes.instanceOf(Date).isRequired,
  contractBegin: PropTypes.instanceOf(Date),
  contractExpire: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
}
