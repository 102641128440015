import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
//import { createLogger } from 'redux-logger'
import { rootReducer } from '../reducers'

const isDev = process.env.REACT_APP_ENV !== 'production'
const isBrowser = typeof window !== 'undefined'

//const loggerMiddleware = createLogger()
const devtools =
  isDev && isBrowser && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__
    : () => fn => fn

const middlewares = []
if (isDev) {
  middlewares.push(applyMiddleware(thunkMiddleware /*, loggerMiddleware*/))
  middlewares.push(devtools())
} else middlewares.push(applyMiddleware(thunkMiddleware))

export const store = createStore(rootReducer, compose(...middlewares))
