import {
  BugReportTwoTone,
  LocalShippingTwoTone,
  MoreVertTwoTone,
} from '@mui/icons-material'
import {
  Avatar,
  Badge,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import matchSorter from 'match-sorter'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactJson from 'react-json-view'
import { useParams } from 'react-router-dom'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { DDMMYYYYHHMMSS, getRelativeDate, userLocales } from '../../api'
import { AM, AMItem, HStack, KPage, Preloader } from '../../components'
import { usePayloadPool } from '../../hooks'

const GermanDate = data =>
  new Date(data.value).toLocaleDateString(userLocales, DDMMYYYYHHMMSS)

const isValidJson = payload => {
  let result = false

  try {
    JSON.parse(
      payload
        .replace(/'/g, '"')
        .replace(/None/g, '"undefined"')
        .replace(/True/g, '"True"')
        .replace(/False/g, '"False"')
    )
    result = true
  } catch (error) {
    result = false
  }

  return result
}

const tableColumns = [
  {
    accessor: 'device_id',
    filterAll: true,
    Header: 'Device ID',
    id: 'device_id',
  },
  {
    accessor: 'payload',
    filterAll: true,
    Header: 'Payload',
    id: 'payload',
  },
  {
    accessor: 'timestamp',
    Cell: GermanDate,
    filterAll: true,
    Header: 'letzte Aktivität',
    id: 'timestamp',
    width: 150,
  },
]

export const Payloads = () => {
  const { device_id } = useParams()
  const { data: payloadPool, isLoading } = usePayloadPool()

  const [actionMenu, setActionMenu] = useState(null)
  const [showIssues, setShowIssues] = useState(false)
  const [payloadIssues, setPayloadIssues] = useState(0)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
    //setFilter,
  } = useTable(
    {
      columns: useMemo(() => tableColumns, []),
      data: useMemo(
        () => {
          const singlePayload = data => {
            if (!data) return

            const { device_id, payload, timestamp } = data
            const parseJSON = (device_id, timestamp, payload) => {
              let parsedJSON

              const issues = []

              try {
                parsedJSON = JSON.parse(payload)
              } catch (error) {
                issues.push({ device_id, timestamp, payload, error })
              }

              return parsedJSON
            }
            const json = parseJSON(
              device_id,
              timestamp,
              payload
                .replace(/'/g, '"')
                .replace(/None/g, '"undefined"')
                .replace(/True/g, '"True"')
                .replace(/False/g, '"False"')
            )
            return {
              device_id,
              payload: <ReactJson collapsed={'true'} src={json} />,
              timestamp,
              id: data.id,
            }
          }

          return !isLoading
            ? payloadPool.map(payload => singlePayload(payload))
            : []
        },
        [isLoading, payloadPool]
      ),
      defaultColumn: useMemo(() => ({ Filter: () => null }), []),
      filterTypes: useMemo(
        () => ({
          text: (rows, id, filterValue) =>
            matchSorter(rows, filterValue, { keys: [id] }),
        }),
        []
      ),
      initialState: useMemo(
        () => ({
          sortBy: [{ id: 'timestamp', desc: true }],
          filters: [{ id: 'device_id', value: device_id || '' }],
          pageSize: !isLoading ? 50 : 0,
        }),
        [device_id, isLoading]
      ),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const onFilter = useCallback(() => {
    console.log('onFilter')
  }, [])

  useEffect(
    () => {
      if (onFilter) onFilter()
    },
    [pageIndex, pageSize, onFilter]
  )

  useEffect(
    () => {
      if (payloadPool?.filter(item => !isValidJson(item.payload)).length > 0)
        setPayloadIssues(
          payloadPool?.filter(item => !isValidJson(item.payload)).length
        )
    },
    [payloadPool]
  )

  return (
    <>
      {/* preloader */}
      <Preloader isLoading={isLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Payloads'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'sensors'}
      >
        <AMItem
          caption={'Ungültige Payloads'}
          icon={<BugReportTwoTone />}
          onClick={() => setShowIssues(true)}
        />
      </AM>
      {/* action menu end */}

      {/* payloads with issues */}
      <Dialog
        maxWidth={'xl'}
        onClose={() => setShowIssues(false)}
        open={showIssues}
      >
        <DialogTitle>
          Ungültige Payloads{' '}
          <Chip
            color={payloadIssues === 0 ? 'success' : 'error'}
            label={payloadIssues}
            sx={{ ml: 1 }}
          />
        </DialogTitle>

        <DialogContent>
          <List>
            {payloadPool
              ?.filter(item => !isValidJson(item.payload))
              .map((item, key) => {
                const primary = item.device_id
                const secondary = `${item.timestamp} | ${getRelativeDate(
                  item.timestamp
                )}`

                return (
                  <ListItem key={key}>
                    <Stack>
                      <ListItemButton>
                        <ListItemText primary={primary} secondary={secondary} />
                      </ListItemButton>

                      <Collapse in={true}>
                        <Typography>
                          <code>{item.payload}</code>
                        </Typography>
                      </Collapse>
                    </Stack>
                  </ListItem>
                )
              })}
          </List>
        </DialogContent>
      </Dialog>
      {/* payloads with issues end */}

      {!isLoading && (
        <KPage
          action={
            <>
              <Tooltip title={'Ungültige Payloads'}>
                <IconButton onClick={() => setShowIssues(true)}>
                  <Badge
                    badgeContent={payloadIssues}
                    color={payloadIssues === 0 ? 'success' : 'error'}
                  >
                    <BugReportTwoTone />
                  </Badge>
                </IconButton>
              </Tooltip>

              <IconButton onClick={event => setActionMenu(event.currentTarget)}>
                <MoreVertTwoTone />
              </IconButton>
            </>
          }
          avatar={
            <Avatar>
              <LocalShippingTwoTone />
            </Avatar>
          }
          isLoading={isLoading}
          title={
            isLoading ? (
              'laden'
            ) : (
              <>
                Payloads
                <Chip label={payloadPool.length} sx={{ ml: 1 }} />
              </>
            )
          }
        >
          <TableContainer sx={{ border: 'solid 1px #DDD', borderRadius: 2 }}>
            <Table sx={{ width: 1 }} {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup, key) => (
                  <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, innerKey) => (
                      <TableCell
                        key={innerKey}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {page.map((row, key) => {
                  prepareRow(row)
                  return (
                    <TableRow key={key} {...row.getRowProps()}>
                      {row.cells.map((cell, innerKey) => (
                        <TableCell key={innerKey} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider sx={{ my: 2 }} />

          {/* Pagination Controls */}
          <HStack>
            <Button disabled={pageIndex === 0} onClick={() => previousPage()}>
              Zurück
            </Button>
            <Button
              disabled={pageIndex >= pageCount - 1}
              onClick={() => nextPage()}
            >
              Weiter
            </Button>

            <TablePagination
              component={'div'}
              count={pageCount}
              onPageChange={(event, newPage) => gotoPage(newPage)}
              onRowsPerPageChange={event =>
                setPageSize(Number(event.target.value))
              }
              page={pageIndex}
              rowsPerPage={Number(pageSize)}
              rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
            />
          </HStack>
        </KPage>
      )}
    </>
  )
}
