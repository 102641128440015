import { Auth } from 'aws-amplify'
import { getStorage, removeStorage, setStorage } from '../api'
import { jwtDecode } from 'jwt-decode'

const refreshToken = async () => {
  const PAGE_CONFIG = 'user'
  const config = getStorage(PAGE_CONFIG)

  if (config?.token) {
    const { token } = config
    const NOW = (Date.now() / 1000) | 0
    const decodedToken = jwtDecode(token)

    if (NOW + 10 >= decodedToken.exp)
      return await Auth.currentSession()
        .then(session =>
          setStorage(PAGE_CONFIG, {
            ...config,
            token: session.idToken.jwtToken,
          })
        )
        .catch(error => {
          removeStorage(PAGE_CONFIG)

          return Promise.reject(error)
        })
  }

  return await Promise.resolve()
}

export const tokenService = {
  refreshToken,
}
