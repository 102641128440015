import { API } from 'aws-amplify'
import axios from 'axios'
import { authHeader } from '../api'

const API_NAME = 'karmaApi'

const { REACT_APP_API_URL } = process.env

const deleteSensorFactor = async sensorFactorId => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/sensorfactor/${sensorFactorId}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorFactorService.deleteSensorFactor', error)
  }
}

const getAll = async sensorId => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/sensorfactor/all/${sensorId}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorFactorService.getAll', error)
  }
}

const save = async body => {
  const endpoint = '/sensorfactor/'
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }

  return await API.post(API_NAME, endpoint, options)
}

const update = async (body, sensorFactorId) => {
  const config = {
    data: body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    url: `${REACT_APP_API_URL}/sensorfactor/${sensorFactorId}`,
  }
  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorFactorService.updateSensorFactor', error)
  }
}

export const sensorFactorService = {
  deleteSensorFactor,
  getAll,
  save,
  update,
}
