import {
  AccountBalanceTwoTone,
  AnnouncementTwoTone,
  AssignmentTwoTone,
  FingerprintTwoTone,
  HomeTwoTone,
  MemoryTwoTone,
  NotificationsTwoTone,
  PeopleTwoTone,
  PersonAddTwoTone,
  SettingsRemoteTwoTone,
  SpeedTwoTone,
  StorageTwoTone,
} from '@mui/icons-material'
import React from 'react'
import {
  AllVirtual,
  Calculon,
  Overview,
  Dashboards,
  EditPrice,
  FolderAll,
  FolderEdit,
  FolderSingle,
  HomeFolder,
  InstallationDetail,
  LoginPage,
  ImportSensorData,
  MissingData,
  MissingDataQueryGenerator,
  NewsAll,
  NewsEdit,
  NewsSingle,
  NotificationAll,
  NotificationEdit,
  Payloads,
  PerformanceDetail,
  RecalculateSensorData,
  ReportGenerator,
  SensorAll,
  SensorEdit,
  SensorVirtualEdit,
  FeelingHistory,
  SecurityHistory,
  UserAll,
  UserEdit,
  UserProfile,
  UserRegister,
} from '../pages'

const dashRoutes = [
  {
    collapse: true,
    icon: <HomeTwoTone />,
    name: 'Gebäudeübersicht',
    hidden: true,
    path: '//',
    views: [
      {
        component: Overview,
        name: 'Gebäudeübersicht',
        hidden: true,
        path: '//',
      },
      {
        component: PerformanceDetail,
        name: 'Object View Type',
        hidden: true,
        path: '/folder/:id/detail/:type',
      },
      {
        component: HomeFolder,
        name: 'Object View Type',
        hidden: true,
        path: '/folder/:id/home/:category',
      },
      {
        component: FeelingHistory,
        name: 'Object View Type',
        hidden: true,
        path: '/folder/:id/feeling/:medium/:sensorid',
      },
      {
        component: SecurityHistory,
        name: 'Object View Type',
        hidden: true,
        path: '/folder/:id/security/:medium/:sensorid',
      },
      {
        component: InstallationDetail,
        name: 'Object View Installation',
        hidden: true,
        path: '/folder/:id/installation/:installationid',
      },
    ],
  },
  {
    component: Dashboards,
    icon: <SpeedTwoTone />,
    name: 'Dashboard',
    path: '/dashboard',
  },
  {
    collapse: true,
    name: 'Page',
    hidden: true,
    path: '-page',
    views: [
      {
        component: UserProfile,
        name: 'User Profile',
        path: '/user-page/:tabId',
      },
      {
        component: UserProfile,
        name: 'User Profile',
        path: '/user-page',
      },
      {
        component: UserRegister,
        icon: <PersonAddTwoTone />,
        name: 'Register Page',
        path: '/pages/register-page',
        short: 'Register',
      },
      {
        component: LoginPage,
        icon: <FingerprintTwoTone />,
        name: 'Login Page',
        path: '/pages/login-page',
        short: 'Login',
      },
    ],
  },
  {
    collapse: true,
    icon: <AssignmentTwoTone />,
    name: 'Berichte',
    hidden: true,
    path: '/reports',
    views: [
      {
        component: ReportGenerator,
        name: 'Report Generator',
        path: '/reports/:folder_id',
      },
    ],
  },
  {
    component: ReportGenerator,
    icon: <AssignmentTwoTone />,
    name: 'Berichte',
    path: '/reports',
  },
  {
    collapse: true,
    name: 'Benachrichtigungen',
    hidden: true,
    path: '/notifications',
    views: [
      {
        isAdmin: true,
        component: NotificationEdit,
        path: '/notifications/new',
      },
      {
        isAdmin: true,
        component: NotificationEdit,
        path: '/notifications/:id/edit',
      },
    ],
  },
  {
    isAdmin: true,
    component: NotificationAll,
    icon: <NotificationsTwoTone />,
    name: 'Benachrichtigungen',
    path: '/notifications',
  },
  {
    isAdmin: true,
    collapse: true,
    name: 'Gebäude',
    hidden: true,
    path: '/folder',
    views: [
      {
        isAdmin: true,
        component: FolderEdit,
        path: '/folder/new',
      },
      {
        isAdmin: true,
        component: FolderEdit,
        path: '/folder/:id/edit/:section',
      },
      {
        isAdmin: true,
        component: FolderEdit,
        path: '/folder/:id/edit',
      },
      {
        isAdmin: true,
        component: FolderSingle,
        path: '/folder/:id/:section',
      },
      {
        isAdmin: true,
        component: FolderSingle,
        path: '/folder/:id',
      },
    ],
  },
  {
    isAdmin: true,
    component: FolderAll,
    icon: <AccountBalanceTwoTone />,
    name: 'Gebäude',
    path: '/folder',
  },
  {
    isAdmin: true,
    collapse: true,
    icon: <MemoryTwoTone />,
    name: 'Sensoren',
    hidden: true,
    path: '/sensors',
    views: [
      {
        isAdmin: true,
        component: Calculon,
        name: 'Sensor Berechnungen',
        hidden: true,
        path: '/sensors/calculon',
      },
      {
        isAdmin: true,
        component: EditPrice,
        name: 'Preise',
        hidden: true,
        path: '/sensors/price',
      },
      {
        isAdmin: true,
        component: MissingDataQueryGenerator,
        name: 'Sensordaten Generator',
        hidden: true,
        path: '/sensors/missing-data-query-generator',
      },
      {
        isAdmin: true,
        component: Payloads,
        name: 'Payloads',
        hidden: true,
        path: '/sensors/payloads/:device_id',
      },
      {
        isAdmin: true,
        component: Payloads,
        name: 'Payloads',
        hidden: true,
        path: '/sensors/payloads',
      },
      {
        isAdmin: true,
        component: MissingData,
        name: 'Fehlende Messtage',
        hidden: true,
        path: '/sensors/:id/missing',
      },
      {
        isAdmin: true,
        component: ImportSensorData,
        name: 'Fehlende Sensor Daten importieren',
        hidden: true,
        path: '/sensors/:id/import',
      },
      {
        isAdmin: true,
        component: SensorEdit,
        name: 'Sensor bearbeiten',
        hidden: true,
        path: '/sensors/:id',
      },
      {
        isAdmin: true,
        component: RecalculateSensorData,
        icon: <StorageTwoTone />,
        name: 'Daten',
        hidden: true,
        path: '/data',
      },
    ],
  },
  {
    isAdmin: true,
    component: SensorAll,
    icon: <MemoryTwoTone />,
    name: 'Sensoren',
    path: '/sensors',
  },
  {
    collapse: true,
    name: 'Virtuelle Sensoren',
    hidden: true,
    path: '/virtualsensors',
    views: [
      {
        isAdmin: true,
        component: SensorVirtualEdit,
        path: '/virtualsensors/new',
      },
      {
        isAdmin: true,
        component: SensorVirtualEdit,
        path: '/virtualsensors/:id/edit',
      },
    ],
  },
  {
    isAdmin: true,
    component: AllVirtual,
    icon: <SettingsRemoteTwoTone />,
    name: 'Virtuelle Sensoren',
    hidden: true,
    path: '/virtualsensors',
  },
  {
    isAdmin: true,
    collapse: true,
    name: 'News',
    hidden: true,
    path: '/news',
    views: [
      {
        isAdmin: true,
        component: NewsEdit,
        path: '/news/new',
      },
      {
        isAdmin: true,
        component: NewsEdit,
        path: '/news/:id/edit',
      },
      {
        isAdmin: true,
        component: NewsSingle,
        path: '/news/:id/:nc',
      },
    ],
  },
  {
    isAdmin: true,
    component: NewsAll,
    icon: <AnnouncementTwoTone />,
    name: 'News',
    path: '/news',
  },
  {
    isAdmin: true,
    collapse: true,
    name: 'Benutzer',
    hidden: true,
    path: '/users',
    views: [
      {
        isAdmin: true,
        component: UserEdit,
        path: '/users/new',
      },
      {
        isAdmin: true,
        component: UserEdit,
        path: '/users/:id/edit',
      },
    ],
  },
  {
    isAdmin: true,
    component: UserAll,
    icon: <PeopleTwoTone />,
    name: 'Benutzer',
    path: '/users',
  },
]

// add redirect to dashboard at last element
dashRoutes.push({
  name: 'Dashboard',
  path: '/',
  pathTo: '/dashboard',
  redirect: true,
  exact: true,
})

export default dashRoutes
