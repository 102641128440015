import { notificationConstants as NC } from '../constants'

const initialState = { allNotifications: { loading: true } }

export const notifications = (state = initialState, action) => {
  const { error, notifications, type } = action

  switch (type) {
    case NC.GET_ALL_ERROR:
      return {
        ...state,
        allNotifications: {
          error,
          fetchDate: Date.now(),
          loading: true,
        },
      }

    case NC.GET_ALL_REQUEST:
      return {
        ...state,
        allNotifications: {
          loading: true,
        },
      }

    case NC.GET_ALL_SUCCESS:
      return {
        ...state,
        allNotifications: {
          fetchDate: Date.now(),
          item: notifications,
          loading: false,
        },
      }

    default:
      return state
  }
}
