// modules
import { Grid, Typography } from '@mui/material'
import React from 'react'
// scripts
import {
  DDMMYYHHMM,
  FILTER_BUSINESS_NEWS_TYPE,
  FILTER_LEGAL_NEWS_TYPE,
  FILTER_TECH_NEWS_TYPE,
  FILTER_UNSPECIFIED_NEWS_TYPE,
  getRelativeDate,
  userLocales,
} from '../../../api'
import { useNewsPool } from '../../../hooks'

export const NewsPoolStat = () => {
  const { data, fetchDate } = useNewsPool()

  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1, mb: 2 }}>
        <Typography>Poolgröße</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{data.length}</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>
          <strong>Kategorie</strong>
        </Typography>
      </Grid>
      <Grid item xs={6} />

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Vertrags News</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {data.filter(f => f.attributes.contract_id !== null).length}
        </Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Gebäude News</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {data.filter(f => f.attributes.folder_id !== null).length}
        </Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1, mb: 2 }}>
        <Typography>unspezifiziert</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {
            data.filter(
              f =>
                f.attributes.contract_id === null &&
                f.attributes.folder_id === null
            ).length
          }
        </Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>
          <strong>Rubrik</strong>
        </Typography>
      </Grid>
      <Grid item xs={6} />

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>technisch</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{data.filter(FILTER_TECH_NEWS_TYPE).length}</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>rechtlich</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{data.filter(FILTER_LEGAL_NEWS_TYPE).length}</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>kaufmännisch</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{data.filter(FILTER_BUSINESS_NEWS_TYPE).length}</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>unspezifiziert</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {data.filter(FILTER_UNSPECIFIED_NEWS_TYPE).length}
        </Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1, mt: 2 }}>
        <Typography>gefetcht</Typography>
      </Grid>
      <Grid item xs={6} sx={{ mt: 2 }}>
        <Typography>
          {new Date(fetchDate).toLocaleString(userLocales, DDMMYYHHMM)}
          <br />
          {getRelativeDate(fetchDate)}
        </Typography>
      </Grid>
    </Grid>
  )
}
