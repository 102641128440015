import { MoreVertTwoTone, PollTwoTone } from '@mui/icons-material'
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFilters, useSortBy, useTable } from 'react-table'
import { sensorActions } from '../../actions'
import { AM, HStack, KPage, KTextField, Preloader } from '../../components'

const columns = [
  {
    accessor: 'sensor_id',
    Header: 'Sensor ID',
    sortMethod: (a, b) => Number(a) - Number(b),
    width: 100,
  },
  {
    accessor: 'summe_monat',
    Header: 'Summe Monat',
  },
  {
    accessor: 'summe_tag',
    Header: 'Summe Tag',
  },
  {
    accessor: 'abweichung_tag',
    Header: 'Abweichung',
    Cell: data => {
      const summe_monat = Number(data?.row?.original?.summe_monat)
      const summe_tag = Number(data?.row?.original?.summe_tag)
      const diff = 100 - Math.round((summe_tag / summe_monat) * 100)

      return `${diff} %`
    },
  },
  {
    accessor: 'summe_stunden',
    Header: 'Summe Stunden',
  },
  {
    accessor: 'abweichung_stunden',
    Header: 'Abweichung',
    Cell: data => {
      const summe_monat = Number(data?.row?.original?.summe_monat)
      const summe_stunden = Number(data?.row?.original?.summe_stunden)
      const diff = 100 - Math.round((summe_stunden / summe_monat) * 100)

      return `${diff} %`
    },
  },
]

export const Calculon = () => {
  const dispatch = useDispatch()

  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [year, setYear] = useState(new Date().getFullYear())

  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)

  const { calculations } = useSelector(state => state.sensor)

  const requestCalculations = () => {
    setLoading(true)
    dispatch(sensorActions.getCalculations(month, year, limit, offset))
  }

  const [isLoading, setLoading] = useState(false)
  const [actionMenu, setActionMenu] = useState(null)
  const [requestError, setRequestError] = useState(null)

  useEffect(
    () => {
      const { error, items } = calculations

      if (items || error) setLoading(false)

      if (error) setRequestError(error)
    },
    [calculations]
  )

  const data = useMemo(
    () => (calculations.items ? calculations.items.data : []),
    [calculations.items]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: useMemo(() => columns, []),
      data,
      initialState: { pageSize: data.length },
    },
    useFilters,
    useSortBy
  )

  return (
    <>
      {/* preloader */}
      <Preloader error={requestError} isLoading={isLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Sensoren'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'sensors'}
      >
        <Divider />
      </AM>
      {/* action menu end */}

      <KPage
        action={
          <IconButton onClick={event => setActionMenu(event.currentTarget)}>
            <MoreVertTwoTone />
          </IconButton>
        }
        avatar={
          <Avatar>
            <PollTwoTone />
          </Avatar>
        }
        isLoading={isLoading}
        title={'Abweichungen aggregierter Daten'}
      >
        <HStack>
          <KTextField
            defaultValue={`${year}-${month < 10 ? '0' + month : month}`}
            onChange={event => {
              const _DATE = event.target.value.split('-')

              setMonth(Number(_DATE[1]))
              setYear(Number(_DATE[0]))
            }}
            label={'Zeitraum'}
            type={'month'}
          />

          <KTextField
            label={'Limit'}
            onChange={event => setLimit(event.target.value)}
            type={'number'}
            value={limit}
          />

          <KTextField
            label={'Offset'}
            onChange={event => setOffset(event.target.value)}
            type={'number'}
            value={offset}
          />

          <Button disabled={isLoading} onClick={requestCalculations}>
            Laden
          </Button>
        </HStack>

        {calculations.items && (
          <TableContainer sx={{ border: 'solid 1px #DDD', borderRadius: 2 }}>
            <Table {...getTableProps()} sx={{ width: 1 }}>
              <TableHead>
                {headerGroups.map((headerGroup, key) => (
                  <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, innerKey) => (
                      <TableCell
                        key={innerKey}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {rows.map((row, key) => {
                  prepareRow(row)
                  return (
                    <TableRow key={key} {...row.getRowProps()}>
                      {row.cells.map((cell, innerKey) => (
                        <TableCell key={innerKey} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </KPage>
    </>
  )
}
