// modules
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@mui/material'
import {
  HighlightOffTwoTone,
  MemoryTwoTone,
  MoreVertTwoTone,
  SearchTwoTone,
  SettingsRemoteTwoTone,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// scripts
import {
  getStorage,
  isFilterMatch,
  setStorage,
  SORT_ATTR_NAME,
} from '../../api'
import { AM, AMItem, KPage, KTextField, Preloader } from '../../components'
import { useVirtualSensorPool } from '../../hooks'

export const AllVirtual = () => {
  const history = useHistory()
  const {
    data: virtualSensorPool,
    isError: isVirtualSensorPoolError,
    isLoading: isVirtualSensorPoolLoading,
  } = useVirtualSensorPool()

  const PAGE_CONFIG = 'settings_virtual_sensors_all'

  const [badge, setBadge] = useState('laden')
  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  const [actionMenu, setActionMenu] = useState(null)

  const inputFilter = vSensor =>
    config.filter
      ? isFilterMatch(config.filter, vSensor.attributes.name) ||
        isFilterMatch(
          config.filter,
          vSensor.attributes.combined_virtual_sensor_id
        ) ||
        isFilterMatch(
          config.filter,
          vSensor.attributes.combined_virtual_sensor_device_id
        ) ||
        isFilterMatch(config.filter, vSensor.attributes.original_sensors_id) ||
        isFilterMatch(config.filter, vSensor.attributes.virtual_sensor_type) ||
        Number(config.filter) === Number(vSensor.id)
      : vSensor

  useEffect(() => setStorage(PAGE_CONFIG, config), [config])

  useEffect(
    () =>
      setBadge(isVirtualSensorPoolLoading ? 'laden' : virtualSensorPool.length),
    [virtualSensorPool, isVirtualSensorPoolLoading]
  )

  const isPageLoading = isVirtualSensorPoolLoading

  return (
    <>
      {/* preloader */}
      <Preloader
        caption={'Virtuelle Sensoren laden ...'}
        error={isVirtualSensorPoolError}
        isLoading={isPageLoading}
      />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Sensoren'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'sensors'}
      >
        <AMItem
          caption={'Neu'}
          icon={<MemoryTwoTone />}
          onClick={() => history.push('/virtualsensors/new')}
        />

        <Divider />
      </AM>
      {/* action menu end */}

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={event => setActionMenu(event.currentTarget)}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar>
              <SettingsRemoteTwoTone />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={`${badge} virtuelle Sensoren`}
          subheader={
            config.filter &&
            config.filter.length > 0 &&
            `${virtualSensorPool.filter(inputFilter).length} Treffer`
          }
        >
          {/* filter */}
          <KTextField
            icon={<SearchTwoTone />}
            onChange={event => {
              const filter = event.target.value

              setConfig(prev => ({ ...prev, filter }))
            }}
            sx={{ mb: 5 }}
            unit={
              config.filter &&
              config.filter.length > 0 && (
                <Tooltip title={'Filter löschen'}>
                  <IconButton
                    onClick={() => setConfig(prev => ({ ...prev, filter: '' }))}
                  >
                    <HighlightOffTwoTone />
                  </IconButton>
                </Tooltip>
              )
            }
            value={config.filter}
          />

          <List
            sx={{
              mt: 3,
              maxHeight: 600,
              overflow: 'auto',
              overflowX: 'hidden',
              width: '100%',
            }}
          >
            {virtualSensorPool
              .filter(inputFilter)
              .sort(SORT_ATTR_NAME)
              .map((sensor, key) => {
                const {
                  combined_virtual_sensor_id,
                  combined_virtual_sensor_device_id,
                  name,
                  original_sensors_id,
                  virtual_sensor_type,
                } = sensor.attributes

                return (
                  <ListItem
                    button
                    key={key}
                    onClick={() =>
                      history.push(`/virtualsensors/${sensor.id}/edit`)
                    }
                  >
                    <ListItemAvatar>
                      <Tooltip title={`virtuelle Sensor ID: ${sensor.id}`}>
                        <Avatar alt={''}>
                          <SettingsRemoteTwoTone />
                        </Avatar>
                      </Tooltip>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ width: '25%', wordWrap: 'break-word' }}
                      primary={name || ''}
                      secondary={`kombinierte Sensor ID: ${combined_virtual_sensor_id}`}
                    />
                    <ListItemText
                      sx={{ width: '25%', wordWrap: 'break-word' }}
                      primary={`Device ID`}
                      secondary={combined_virtual_sensor_device_id}
                    />
                    <ListItemText
                      sx={{ width: '25%', wordWrap: 'break-word' }}
                      primary={`Original Sensoren`}
                      secondary={original_sensors_id.replaceAll(',', ', ')}
                    />
                    <ListItemText
                      sx={{ width: '25%', wordWrap: 'break-word' }}
                      primary={`Sensor Typ`}
                      secondary={virtual_sensor_type}
                    />
                  </ListItem>
                )
              })}
          </List>
        </KPage>
      )}
    </>
  )
}
