export const reportsConstants = {
  CREATE_REPORT_SETTINGS_FAILURE: 'CREATE_REPORT_SETTINGS_FAILURE',
  CREATE_REPORT_SETTINGS_REQUEST: 'CREATE_REPORT_SETTINGS_REQUEST',
  CREATE_REPORT_SETTINGS_SUCCESS: 'CREATE_REPORT_SETTINGS_SUCCESS',

  DELETE_REPORT_SETTINGS_FAILURE: 'DELETE_REPORT_SETTINGS_FAILURE',
  DELETE_REPORT_SETTINGS_REQUEST: 'DELETE_REPORT_SETTINGS_REQUEST',
  DELETE_REPORT_SETTINGS_SUCCESS: 'DELETE_REPORT_SETTINGS_SUCCESS',

  GENERATE_PDF_FAILURE: 'GENERATE_PDF_ERROR',
  GENERATE_PDF_REQUEST: 'GENERATE_PDF_REQUEST',
  GENERATE_PDF_SUCCESS: 'GENERATE_PDF_SUCCESS',

  GET_REPORT_SETTINGS_FAILURE: 'GET_REPORT_SETTINGS_FAILURE',
  GET_REPORT_SETTINGS_REQUEST: 'GET_REPORT_SETTINGS_REQUEST',
  GET_REPORT_SETTINGS_SUCCESS: 'GET_REPORT_SETTINGS_SUCCESS',

  UPDATE_REPORT_SETTINGS_FAILURE: 'UPDATE_REPORT_SETTINGS_FAILURE',
  UPDATE_REPORT_SETTINGS_REQUEST: 'UPDATE_REPORT_SETTINGS_REQUEST',
  UPDATE_REPORT_SETTINGS_SUCCESS: 'UPDATE_REPORT_SETTINGS_SUCCESS',
}
