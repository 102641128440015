import { reportsConstants as RC } from '../constants'
import { reportsService as RS, tokenService as TS } from '../services'
import { alertActions as AA } from './'

const generateMBR = (folderId, month, year) => {
  const failure = error => ({ type: RC.GENERATE_PDF_FAILURE, error })
  const request = () => ({ type: RC.GENERATE_PDF_REQUEST })
  const success = report => ({ type: RC.GENERATE_PDF_SUCCESS, report })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      RS.generateMBR(folderId, month, year)
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const generateQBR = (folderId, quarter, year) => {
  const failure = error => ({ type: RC.GENERATE_PDF_FAILURE, error })
  const request = () => ({ type: RC.GENERATE_PDF_REQUEST })
  const success = report => ({ type: RC.GENERATE_PDF_SUCCESS, report })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      RS.generateQBR(folderId, quarter, year)
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const generateYBR = (folderId, year) => {
  const failure = error => ({ type: RC.GENERATE_PDF_FAILURE, error })
  const request = () => ({ type: RC.GENERATE_PDF_REQUEST })
  const success = report => ({ type: RC.GENERATE_PDF_SUCCESS, report })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      RS.generateYBR(folderId, year)
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const generateSelected = (folderId, month, year, list) => {
  const failure = error => ({ type: RC.GENERATE_PDF_FAILURE, error })
  const request = () => ({ type: RC.GENERATE_PDF_REQUEST })
  const success = report => ({ type: RC.GENERATE_PDF_SUCCESS, report })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      RS.generateSelected(folderId, month, year, list)
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const getReportSettings = folderId => {
  const failure = error => ({ type: RC.GET_REPORT_SETTINGS_FAILURE, error })
  const request = () => ({ type: RC.GET_REPORT_SETTINGS_REQUEST })
  const success = report => ({ type: RC.GET_REPORT_SETTINGS_SUCCESS, report })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      RS.getReportSettings(folderId)
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const createReportSettings = (folderId, recipients, sensorIds) => {
  const failure = error => ({ type: RC.CREATE_REPORT_SETTINGS_FAILURE, error })
  const request = () => ({ type: RC.CREATE_REPORT_SETTINGS_REQUEST })
  const success = report => ({
    type: RC.CREATE_REPORT_SETTINGS_SUCCESS,
    report,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      RS.createReportSettings(folderId, recipients, sensorIds)
        .then(result => {
          dispatch(AA.success('Report abonniert'))
          dispatch(success(result))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const deleteReportSettings = folderId => {
  const failure = error => ({ type: RC.DELETE_REPORT_SETTINGS_FAILURE, error })
  const request = () => ({ type: RC.DELETE_REPORT_SETTINGS_REQUEST })
  const success = report => ({
    type: RC.DELETE_REPORT_SETTINGS_SUCCESS,
    report,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      RS.deleteReportSettings(folderId)
        .then(result => {
          dispatch(AA.success('Abonnement gelöscht'))
          dispatch(success(result))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const updateReportSettings = (folderId, recipients, sensorIds) => {
  const failure = error => ({ type: RC.UPDATE_REPORT_SETTINGS_FAILURE, error })
  const request = () => ({ type: RC.UPDATE_REPORT_SETTINGS_REQUEST })
  const success = report => ({
    type: RC.UPDATE_REPORT_SETTINGS_SUCCESS,
    report,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      RS.updateReportSettings(folderId, recipients, sensorIds)
        .then(result => {
          dispatch(AA.success('Abonnement aktualisiert'))
          dispatch(success(result))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

export const reportsActions = {
  generateMBR,
  generateQBR,
  generateYBR,
  generateSelected,
  getReportSettings,
  createReportSettings,
  deleteReportSettings,
  updateReportSettings,
}
