// modules
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
// scripts
import { userActions } from '../../../actions'
import { useFolderPool, useOperator } from '../../../hooks'

export const ConsumptionTableColsDialog = ({
  open,
  onClose,
  roomsMode,
  onConfirm,
  folderId,
}) => {
  const { data: folderPool } = useFolderPool()
  const dispatch = useDispatch()
  const [userId, setUserId] = useState(null)
  const { data: operatorData } = useOperator()

  const data = [
    { label: 'Datum', accessor: 'date' },
    { label: 'IST-Verbrauch', accessor: 'actual_consumption' },
    { label: 'Trend', accessor: 'trend' },
    { label: 'CO₂-Emissionen', accessor: 'emissions' },
    { label: 'Verbrauch / m²', accessor: 'area_consumption' },
    { label: `Verbrauch / ${roomsMode}`, accessor: 'employee_consumption' },
    { label: 'IST-Kosten', accessor: 'actual_costs' },
    { label: 'Kosten / m²', accessor: 'area_costs' },
    { label: 'Benchmark', accessor: 'benchmark' },
    { label: 'Zählerstand', accessor: 'counter_reading' },
    { label: `Kosten / ${roomsMode}`, accessor: 'employee_costs' },
  ]

  const [selectedFolderId, setSelectedFolderId] = useState(null)

  const [cols, setCols] = useState({
    date: true,
    actual_consumption: true,
    trend: true,
    emissions: true,
    area_consumption: true,
    employee_consumption: true,
    actual_costs: true,
    area_costs: true,
    benchmark: true,
    counter_reading: true,
    employee_costs: true,
  })

  useEffect(
    () => {
      if (operatorData === null) return

      const { id, view_options } = operatorData

      const data = [
        { label: 'Datum', accessor: 'date' },
        { label: 'IST-Verbrauch', accessor: 'actual_consumption' },
        { label: 'Trend', accessor: 'trend' },
        { label: 'CO₂-Emissionen', accessor: 'emissions' },
        { label: 'Verbrauch / m²', accessor: 'area_consumption' },
        { label: `Verbrauch / ${roomsMode}`, accessor: 'employee_consumption' },
        { label: 'IST-Kosten', accessor: 'actual_costs' },
        { label: 'Kosten / m²', accessor: 'area_costs' },
        { label: 'Benchmark', accessor: 'benchmark' },
        { label: 'Zählerstand', accessor: 'counter_reading' },
        { label: `Kosten / ${roomsMode}`, accessor: 'employee_costs' },
      ]

      // do we have view options for all folders? (view options but no folderId in record)
      const voAllFolders = view_options.find(f => f.folder_id === null)

      // do we have view options for the current folder? (view options with folderId matching the folderId in record)
      const voCurrentFolder = view_options.find(f => f.folder_id === folderId)

      setSelectedFolderId(voCurrentFolder ? voCurrentFolder.folder_id : null)

      const cols = {
        date: true,
        actual_consumption: true,
        trend: true,
        emissions: true,
        area_consumption: true,
        employee_consumption: true,
        actual_costs: true,
        area_costs: true,
        benchmark: true,
        counter_reading: true,
        employee_costs: true,
      }

      if (voAllFolders) {
        const vo = voAllFolders.columns.split(',').map(f => Boolean(Number(f)))

        data.forEach((col, key) => (cols[col.accessor] = vo[key]))
      }

      if (voCurrentFolder) {
        const vo = voCurrentFolder.columns
          .split(',')
          .map(f => Boolean(Number(f)))

        data.forEach((col, key) => (cols[col.accessor] = vo[key]))
      }

      setCols(cols)
      setUserId(id)
    },
    [operatorData, folderId, roomsMode]
  )

  return (
    <Dialog maxWidth={'lg'} open={open} onClose={onClose}>
      <DialogTitle>Tabellenspalten</DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Alert severity={'info'}>
          <AlertTitle>Spaltenkonfiguration</AlertTitle>

          <Typography>
            Bestimmen Sie hier, welche Spalten in der Tabelle angezeigt werden
            sollen.
          </Typography>

          <Typography>
            Die Spalte Datum lässt sich nicht deaktivieren.
          </Typography>
        </Alert>

        <List>
          {data.map((cell, key) => {
            const { accessor, label } = cell
            const checked = cols[accessor]

            const toggle = () => {
              setCols(prev => {
                const newVO = { ...prev }
                newVO[accessor] = !prev[accessor]

                return newVO
              })
            }

            return (
              <ListItem key={key} sx={{ p: 0 }}>
                <ListItemButton disabled={key === 0} onClick={toggle}>
                  <ListItemText>{label}</ListItemText>

                  <ListItemSecondaryAction>
                    <Checkbox checked={checked} disabled={key === 0} />
                  </ListItemSecondaryAction>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>

        <Collapse in={folderPool.length > 1}>
          <Divider />

          <Alert severity={'info'}>
            <AlertTitle>Gültigkeit</AlertTitle>

            <Typography>
              Da Sie über mehrere Gebäude verfügen, können Sie die Gültigkeit
              der Spaltenkonfiguration reglementieren.
            </Typography>
          </Alert>

          <FormControl sx={{ m: 2 }}>
            <FormLabel>Anwenden auf</FormLabel>

            <RadioGroup defaultValue={'current'}>
              <FormControlLabel
                value={'current'}
                control={<Radio />}
                label={'aktuelles Gebäude'}
                onClick={() => setSelectedFolderId(folderId)}
              />

              <FormControlLabel
                value={'all'}
                control={<Radio />}
                label={'alle meine Gebäude'}
                onClick={() => setSelectedFolderId(0)}
              />
            </RadioGroup>
          </FormControl>
        </Collapse>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            const { view_options } = operatorData

            const updatedViewOptions =
              view_options.length > 0
                ? view_options.map(option => ({
                    ...option,
                    folder_id:
                      selectedFolderId === 0 ? selectedFolderId : folderId,
                    columns: Object.values(cols)
                      .map(item => Number(Boolean(item)))
                      .toString(),
                  }))
                : [
                    {
                      entity_id: null,
                      user_id: userId,
                      folder_id:
                        selectedFolderId === 0 ? selectedFolderId : folderId,
                      columns: Object.values(cols)
                        .map(item => Number(Boolean(item)))
                        .toString(),
                    },
                  ]

            const updatedUserData = {
              ...operatorData,
              view_options: updatedViewOptions,
              lars: 'echt dufter Typ',
            }

            dispatch(userActions.updateOperator(userId, updatedUserData))
            onConfirm(cols)
            onClose()
          }}
        >
          Speichern
        </Button>

        <Button onClick={onClose}>Verwerfen</Button>
      </DialogActions>
    </Dialog>
  )
}

ConsumptionTableColsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  roomsMode: PropTypes.string,
  onConfirm: PropTypes.func,
  folderId: PropTypes.number,
}
