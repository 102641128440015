// modules
import {
  AssignmentIndTwoTone,
  EditLocationTwoTone,
  FaceTwoTone,
  HttpTwoTone,
  MailTwoTone,
  PeopleTwoTone,
  PhoneTwoTone,
  PrintTwoTone,
  SmartphoneTwoTone,
} from '@mui/icons-material'
import { Avatar, MenuItem, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
// scripts
import { isValidEmail, isValidLength } from '../../../api'
import { KSelect, KTextField } from '../../../components'
import { useUserRolesPool } from '../../../hooks'
// assets
import avatar from '../../../assets/img/default-avatar.png'

const countryPool = [
  { value: 'DEU', label: 'Deutschland' },
  { value: 'AUS', label: 'Österreich' },
  { value: 'CHE', label: 'Schweiz' },
]

export const UserCoreDataMask = ({
  user,
  setUser,
  isEditMode,
  isCurrent,
  onClickAvatar,
}) => {
  const { data: userRolePool } = useUserRolesPool()

  const {
    city,
    country,
    email,
    email_contact,
    fax,
    firstname,
    homepage,
    image_url,
    is_active,
    lastname,
    role_id,
    street,
    telephone,
    telephone_mobile,
    zip,
  } = user

  //handler

  const handleFirstname = event => {
    const firstname = event.target.value

    setUser(prev => ({ ...prev, firstname }))
  }

  const handleLastname = event => {
    const lastname = event.target.value

    setUser(prev => ({ ...prev, lastname }))
  }

  const handleEmail = event => {
    const email = event.target.value

    setUser(prev => ({ ...prev, email }))
  }

  const handleContactEmail = event => {
    const email_contact = event.target.value

    setUser(prev => ({ ...prev, email_contact }))
  }

  const handleRoleId = event => {
    const role_id = event.target.value

    setUser(prev => ({ ...prev, role_id }))
  }

  const handleHomepage = event => {
    const homepage = event.target.value

    setUser(prev => ({ ...prev, homepage }))
  }

  const handleTelephone = event => {
    const telephone = event.target.value

    setUser(prev => ({ ...prev, telephone }))
  }

  const handleTelephoneMobile = event => {
    const telephone_mobile = event.target.value

    setUser(prev => ({ ...prev, telephone_mobile }))
  }

  const handleFax = event => {
    const fax = event.target.value

    setUser(prev => ({ ...prev, fax }))
  }

  const handleStreet = event => {
    const street = event.target.value

    setUser(prev => ({ ...prev, street }))
  }

  const handleZip = event => {
    const zip = event.target.value

    setUser(prev => ({ ...prev, zip }))
  }

  const handleCity = event => {
    const city = event.target.value

    setUser(prev => ({ ...prev, city }))
  }

  const handleCountry = event => {
    const record = countryPool.find(f => f.value === event.target.value)
    if (!record) return

    const { value: country } = record

    setUser(prev => ({ ...prev, country }))
  }

  return (
    <>
      <Stack direction={'row'}>
        <Avatar
          onClick={onClickAvatar}
          src={image_url ? String(image_url) : avatar}
          sx={{
            cursor: 'pointer',
            height: 360,
            mx: 1,
            mt: 1,
            width: 360,
          }}
          variant={'rounded'}
        >
          <PeopleTwoTone />
        </Avatar>

        <Stack
          direction={'column'}
          justifyContent={'space-evenly'}
          sx={{ width: '100%' }}
        >
          {/* Vorname */}
          <KTextField
            disabled={!is_active}
            error={!isValidLength(firstname, 2)}
            helperText={
              isValidLength(firstname, 2)
                ? ''
                : 'Vorname erfordert mind. 2 Zeichen'
            }
            icon={<FaceTwoTone />}
            label={'Vorname'}
            onChange={handleFirstname}
            required
            value={firstname}
          />

          {/* Name */}
          <KTextField
            disabled={!is_active}
            error={!isValidLength(lastname, 2)}
            helperText={
              isValidLength(lastname, 2) ? '' : 'Name erfordert mind. 2 Zeichen'
            }
            icon={<FaceTwoTone />}
            label={'Name'}
            onChange={handleLastname}
            required
            value={lastname}
          />

          {/* E-Mail */}
          <KTextField
            disabled={isEditMode || !is_active}
            error={!isValidEmail(email)}
            helperText={isValidEmail(email) ? '' : 'E-Mail ungültig'}
            icon={<MailTwoTone />}
            label={'E-Mail'}
            onChange={handleEmail}
            type={'email'}
            value={email}
          />

          {/* Kontakt E-Mail */}
          <KTextField
            disabled={!is_active}
            error={
              email_contact !== '' && !isValidEmail(email_contact) && is_active
            }
            helperText={
              email_contact !== '' && !isValidEmail(email_contact) && is_active
                ? 'E-Mail ungültig'
                : ''
            }
            icon={<MailTwoTone />}
            label={'Kontakt E-Mail'}
            onChange={handleContactEmail}
            type={'email'}
            value={email_contact || ''}
          />

          {/* Rolle */}
          {/* special case: client is not allowed to change (promote) its own role for security reason. */}
          <KSelect
            disabled={isCurrent || !is_active}
            icon={<AssignmentIndTwoTone />}
            label={`Rolle (ID:${role_id})`}
            onChange={handleRoleId}
            value={role_id && role_id}
          >
            {userRolePool.map((role, key) => {
              const { entity_id, label } = role.attributes

              return (
                <MenuItem key={key} value={entity_id}>
                  {label}
                </MenuItem>
              )
            })}
          </KSelect>
        </Stack>
      </Stack>

      {/* Homepage */}
      <KTextField
        disabled={!is_active}
        icon={<HttpTwoTone />}
        label={'Homepage'}
        onChange={handleHomepage}
        type={'url'}
        value={homepage || ''}
      />

      {/* Telefon geschäftlich */}
      <KTextField
        disabled={!is_active}
        icon={<PhoneTwoTone />}
        label={'Telefon geschäftlich'}
        onChange={handleTelephone}
        type={'tel'}
        value={telephone || ''}
      />

      {/* Mobil */}
      <KTextField
        disabled={!is_active}
        icon={<SmartphoneTwoTone />}
        label={'Mobil'}
        onChange={handleTelephoneMobile}
        type={'tel'}
        value={telephone_mobile || ''}
      />

      {/* Fax */}
      <KTextField
        disabled={!is_active}
        icon={<PrintTwoTone />}
        label={'Fax'}
        onChange={handleFax}
        type={'tel'}
        value={fax || ''}
      />

      {/* Anschrift */}
      <KTextField
        disabled={!is_active}
        icon={<EditLocationTwoTone />}
        label={'Anschrift'}
        onChange={handleStreet}
        value={street || ''}
      />

      {/* PLZ */}
      <KTextField
        disabled={!is_active}
        icon={<EditLocationTwoTone />}
        label={'PLZ'}
        onChange={handleZip}
        value={zip || ''}
      />

      {/* Ort */}
      <KTextField
        disabled={!is_active}
        icon={<EditLocationTwoTone />}
        label={'Ort'}
        onChange={handleCity}
        value={city || ''}
      />

      {/* Land */}
      <KSelect
        disabled={!is_active}
        icon={<EditLocationTwoTone />}
        label={'Land'}
        onChange={handleCountry}
        value={country}
      >
        {countryPool.map((country, key) => {
          const { label, value } = country

          return (
            <MenuItem key={key} value={value}>
              {label}
            </MenuItem>
          )
        })}
      </KSelect>
    </>
  )
}

UserCoreDataMask.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  isEditMode: PropTypes.bool,
  isCurrent: PropTypes.bool,
  onClickAvatar: PropTypes.func,
}
