// TODO Changed consumption_type to sensor_id in aws and database

// modules
import { Avatar, IconButton, MenuItem, Tooltip } from '@mui/material'
import {
  AddTwoTone,
  ArrowBackTwoTone,
  DeleteTwoTone,
  EmailTwoTone,
  MoreVertTwoTone,
  NotificationsTwoTone,
  SaveTwoTone,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
// scripts
import { notificationActions } from '../../actions'
import {
  AM,
  AMItem,
  KPage,
  KSelect,
  KTextField,
  Preloader,
} from '../../components'
import { useFolderPool, useNotificationPool, useSensorPool } from '../../hooks'

export const NotificationEdit = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  // if id is provided then edit mode
  // if id is missing then create mode
  const { id } = useParams()

  const { data: folderPool } = useFolderPool()
  const {
    data: notificationPool,
    isError: isNotificationPoolError,
    isLoading: isNotificationPoolLoading,
  } = useNotificationPool()
  const { data: sensorPool, isLoading: isSensorPoolLoading } = useSensorPool()

  const [actionMenu, setActionMenu] = useState(null)

  const [sensorDeviceID, setSensorDeviceID] = useState('')
  const [folderID, setFolderID] = useState('')
  const [email, setEmail] = useState('')
  const [percent, setPercent] = useState('')

  const isPageLoading = isNotificationPoolLoading || isSensorPoolLoading

  useEffect(
    () => {
      if (!isPageLoading) {
        const record = notificationPool.find(f => Number(f.id) === Number(id))

        if (record) {
          const { consumption_type, folder_id, email, percent } = record

          setSensorDeviceID(consumption_type)
          setFolderID(folder_id)
          setEmail(email)
          setPercent(percent)
        }
      }
    },
    [isPageLoading, notificationPool, id]
  )

  return (
    <>
      {/* preloader */}
      <Preloader
        caption={'Benachrichtigungen laden ...'}
        error={isNotificationPoolError}
        isLoading={isPageLoading}
      />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Benachrichtigung'}
        open={Boolean(actionMenu)}
        onClose={() => setActionMenu(null)}
        historyUrlTarget={'notifications'}
      >
        <AMItem
          caption={'Zurück'}
          disabled={history.action === 'POP'}
          icon={<ArrowBackTwoTone />}
          onClick={() => history.action !== 'POP' && history.goBack()}
        />
        <AMItem
          caption={'Neu'}
          disabled={!id}
          icon={<AddTwoTone />}
          onClick={() => history.push('/notifications/new')}
        />

        {!id && (
          <AMItem
            caption={'Speichern'}
            icon={<SaveTwoTone />}
            onClick={() =>
              dispatch(
                notificationActions.save({
                  folderID,
                  sensorDeviceID,
                  percent,
                  email,
                })
              )
            }
          />
        )}
        {id && (
          <AMItem
            caption={'Speichern'}
            icon={<SaveTwoTone />}
            /*onClick={() => onSubmit('update')}*/
          />
        )}
        {id && (
          <AMItem
            caption={'Löschen'}
            icon={<DeleteTwoTone />}
            onClick={() => dispatch(notificationActions.remove(id))}
          />
        )}
      </AM>

      <KPage
        action={
          <IconButton onClick={event => setActionMenu(event.currentTarget)}>
            <MoreVertTwoTone />
          </IconButton>
        }
        avatar={
          <Avatar>
            <Tooltip title={`ID: ${id}`}>
              <NotificationsTwoTone />
            </Tooltip>
          </Avatar>
        }
        isLoading={isPageLoading}
        title={'Benachrichtigung bearbeiten'}
      >
        <KSelect
          label={`Gebäude (ID:${folderID})`}
          onChange={event => setFolderID(event.target.value)}
          value={folderID}
        >
          {folderPool.map((item, key) => {
            const { name } = item.attributes

            return (
              <MenuItem key={key} value={item.id}>
                {name}
              </MenuItem>
            )
          })}
        </KSelect>

        <KSelect
          label={`Sensor (ID:${sensorDeviceID})`}
          onChange={event => setSensorDeviceID(event.target.value)}
          value={sensorDeviceID}
        >
          {sensorPool.map((item, key) => {
            const { device_id, sensor_type } = item.attributes

            return (
              <MenuItem key={key} value={item.id}>
                {device_id}
                ::
                {sensor_type}
              </MenuItem>
            )
          })}
        </KSelect>

        <KTextField
          label={'Auslöser'}
          onChange={event => setPercent(event.target.value)}
          type={'number'}
          unit={'% Abweichung'}
          value={percent}
        />

        <KTextField
          icon={<EmailTwoTone />}
          label={'Empfänger'}
          onChange={event => setEmail(event.target.value)}
          type={'email'}
          value={email}
        />
      </KPage>
    </>
  )
}
