// modules
import {
  Alert,
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  AddTwoTone,
  AnnouncementTwoTone,
  HighlightOffTwoTone,
  InfoTwoTone,
  MoreVertTwoTone,
  SearchTwoTone,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// scripts
import { ASC, DESC, getStorage, isFilterMatch, setStorage } from '../../api'
import {
  AM,
  AMItem,
  HStack,
  KPage,
  KTextField,
  Preloader,
} from '../../components'
import { newsConstants as NC } from '../../constants'
import { useFolderPool, useNewsPool } from '../../hooks'
import {
  NewsCategorySelector,
  NewsPoolStat,
  NewsSortSelector,
  NewsTile,
} from './components'

export const NewsAll = () => {
  const history = useHistory()
  const { data: folderPool } = useFolderPool()
  const {
    data: newsPool,
    isError: isNewsPoolError,
    isLoading: isNewsPoolLoading,
  } = useNewsPool()

  const PAGE_CONFIG = 'settings_news_all'
  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  const [actionMenu, setActionMenu] = useState(null)

  const inputFilter = f =>
    config.filter
      ? isFilterMatch(config.filter, f.attributes.title) ||
        isFilterMatch(config.filter, f.attributes.headline) ||
        isFilterMatch(config.filter, f.attributes.content_text) ||
        Number(config.filter) === Number(f.id)
      : f

  const categoryFilter = f =>
    config.category === NC.NEWS_CATEGORY_CONTRACT
      ? f.attributes.contract_id !== null
      : config.category === NC.NEWS_CATEGORY_OBJECT
        ? f.attributes.folder_id !== null
        : f

  const typeFilter = f =>
    config.type === NC.NEWS_TYPE_TECH
      ? f.attributes.news_type_id === 1
      : config.type === NC.NEWS_TYPE_LEGAL
        ? f.attributes.news_type_id === 2
        : config.type === NC.NEWS_TYPE_BUSINESS
          ? f.attributes.news_type_id === 3
          : f

  const sortFilter = (a, b) => {
    const { order, orderby } = config

    if (orderby === 'title' && a.attributes[orderby] < b.attributes[orderby])
      return order === DESC ? 1 : -1

    if (orderby === 'entity_id' && Number(a.id) < Number(b.id))
      return order === DESC ? 1 : -1

    return order === DESC ? -1 : 1
  }

  useEffect(() => setStorage(PAGE_CONFIG, config), [config])

  const isPageError = isNewsPoolError
  const isPageLoading = isNewsPoolLoading

  const changeOrderByFilter = (event, orderby) => {
    const { order } = config
    if (orderby !== null) setConfig(prev => ({ ...prev, orderby }))
    else {
      const newOrder = order === DESC ? ASC : DESC
      setConfig(prev => ({ ...prev, order: newOrder }))
    }
  }

  const changeCategoryFilter = (event, status) => {
    if (status !== null) setConfig(prev => ({ ...prev, status }))
  }

  return (
    <>
      {/* preloader */}
      <Preloader error={isPageError} isLoading={isPageLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'News'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'dashboard'}
      >
        <AMItem
          caption={'Neu'}
          icon={<AddTwoTone />}
          onClick={() => history.push('/news/new')}
        />

        <AMItem
          caption={'Info'}
          icon={<InfoTwoTone />}
          onClick={() => setConfig(prev => ({ ...prev, poolInfo: true }))}
        />
      </AM>
      {/* action menu end */}

      {/* pool stat */}
      <Dialog
        open={config.poolInfo}
        onClose={() => setConfig(prev => ({ ...prev, poolInfo: false }))}
      >
        <DialogTitle>News Pool Info</DialogTitle>
        <DialogContent>
          <NewsPoolStat />
        </DialogContent>
      </Dialog>
      {/* pool stat end */}

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={event => setActionMenu(event.currentTarget)}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar>
              <AnnouncementTwoTone />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={'News'}
        >
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={{ mx: 1, mb: 1 }}
          >
            <HStack>
              <Stack
                alignItems={'center'}
                direction={{ lg: 'column', xl: 'row' }}
                spacing={1}
              >
                <NewsCategorySelector
                  onChange={changeCategoryFilter}
                  value={config.category}
                />

                <Typography>Kategorie</Typography>
                <ToggleButtonGroup
                  exclusive
                  onChange={(event, category) => {
                    if (category !== null)
                      setConfig(prev => ({ ...prev, category }))
                  }}
                  value={config.category}
                >
                  {[
                    { label: 'Alle', value: NC.NEWS_CATEGORY_ALL },
                    {
                      label: NC.NEWS_CATEGORY_CONTRACT,
                      value: NC.NEWS_CATEGORY_CONTRACT,
                    },
                    {
                      label: NC.NEWS_CATEGORY_OBJECT,
                      value: NC.NEWS_CATEGORY_OBJECT,
                    },
                  ].map((item, key) => (
                    <ToggleButton key={key} value={item.value}>
                      {item.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>

              <Stack
                alignItems={'center'}
                direction={{ lg: 'column', xl: 'row' }}
                spacing={1}
              >
                <Typography>Rubrik</Typography>
                <ToggleButtonGroup
                  exclusive
                  onChange={(event, type) => {
                    if (type !== null) setConfig(prev => ({ ...prev, type }))
                  }}
                  value={config.type}
                >
                  {[
                    { label: 'Alle', value: NC.NEWS_TYPE_ANY },
                    { label: NC.NEWS_TYPE_TECH, value: NC.NEWS_TYPE_TECH },
                    { label: NC.NEWS_TYPE_LEGAL, value: NC.NEWS_TYPE_LEGAL },
                    {
                      label: NC.NEWS_TYPE_BUSINESS,
                      value: NC.NEWS_TYPE_BUSINESS,
                    },
                  ].map((item, key) => (
                    <ToggleButton key={key} value={item.value}>
                      {item.label}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
            </HStack>

            <NewsSortSelector
              onChange={changeOrderByFilter}
              order={config.order}
              orderby={config.orderby}
            />
          </Stack>

          {/* filter */}
          <KTextField
            icon={<SearchTwoTone />}
            onChange={event => {
              const filter = event.target.value

              setConfig(prev => ({ ...prev, filter }))
            }}
            unit={
              config.filter && (
                <Tooltip title={'Filter löschen'}>
                  <IconButton
                    onClick={() => setConfig(prev => ({ ...prev, filter: '' }))}
                  >
                    <HighlightOffTwoTone />
                  </IconButton>
                </Tooltip>
              )
            }
            value={config.filter}
          />

          {/* hit hint */}
          <Alert severity={'info'} sx={{ mx: 1 }}>
            <Stack direction={'row'}>
              <Typography>
                <strong>
                  {
                    newsPool
                      .filter(categoryFilter)
                      .filter(typeFilter)
                      .filter(inputFilter).length
                  }
                </strong>{' '}
                News entsprechen dem Suchfilter{' '}
                <strong>
                  &quot;
                  {config.filter}
                  &quot;
                </strong>
              </Typography>
            </Stack>
          </Alert>

          {/* newslist */}
          <List
            sx={{
              m: 1,
              maxHeight: 200,
              minHeight: `calc(100vh - 560px)`,
              overflow: 'auto',
            }}
          >
            {newsPool
              .filter(categoryFilter)
              .filter(typeFilter)
              .filter(inputFilter)
              .sort(sortFilter)
              .map((news, key) => (
                <NewsTile key={key} news={news} folderPool={folderPool} />
              ))}
          </List>
        </KPage>
      )}
    </>
  )
}
