import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppConfig, useOperator } from '../../../hooks'

export const OperatorGreeting = () => {
  const { data } = useOperator()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const { daytimeGreeting, global } = useAppConfig()

  useEffect(
    () => {
      if (!data) return
      const { first_name, last_name } = data

      setFirstName(first_name)
      setLastName(last_name)
    },
    [data]
  )

  return (
    <Typography
      component={'h4'}
      sx={{ textAlign: 'center', p: 5 }}
      variant={'h4'}
    >
      {daytimeGreeting()},{' '}
      {global.salutationMode === 'default'
        ? `${firstName} ${lastName}`
        : firstName}
      !
    </Typography>
  )
}
