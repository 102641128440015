import { API } from 'aws-amplify'
import axios from 'axios'
import { authHeader, sanitizeJSON } from '../api'

const API_NAME = 'karmaApi'

const { REACT_APP_API_URL } = process.env

const ERR_MISSING_SENSOR_ID = new Error('Kein Sensor gewählt')
const ERR_INVALID_SENSOR_ID = new Error('Ungültige Sensor ID')

const getAll = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/sensors/admin/sensors`,
  }

  try {
    const response = await axios(config)
    let { data } = response

    // dev: typeof data = object
    // staging + prod: typeof data = string
    if (typeof data === 'object') data = JSON.stringify(data)

    // fix potential backend json issues
    data = sanitizeJSON(data)

    return JSON.parse(data)
  } catch (error) {
    const { config, message, request, response } = error

    if (response) {
      const { headers, data, status } = response
      // request was made; server responded with status code other than 2xx
      console.log(data)
      console.log(status)
      console.log(headers)

      return Promise.reject({ status, message: data.message })
    } else if (request) {
      // request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in browser and an instance of http.ClientRequest in node.js
      console.log(request)
    } else {
      // something happened in setting up the request that triggered an Error
      console.log('Error', message)
    }

    console.log(config)
  }
}

const getAllUnlinked = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/sensors/admin/unlinked-sensors`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorService.getAllUnlinked', error)
  }
}

const getAttributesGroup = async type => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/sensors/admin/attribute-groups/${type}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorService.getAttributesGroup', error)
  }
}

const getById = async (id, includes = []) => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_SENSOR_ID)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_SENSOR_ID)

  let request = id
  if (includes.length > 0) {
    request += '?include='
    includes.forEach(item => (request += `${encodeURIComponent(item)},`))
  }

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/sensors/admin/sensors/${request}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    const { config, message, request, response } = error

    if (response) {
      const { headers, data, status } = response
      // request was made; server responded with status code other than 2xx
      console.log(data)
      console.log(status)
      console.log(headers)

      return Promise.reject({ status, message: data.message })
    } else if (request) {
      // request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in browser and an instance of http.ClientRequest in node.js
      console.log(request)
    } else {
      // something happened in setting up the request that triggered an Error
      console.log('Error', message)
    }

    console.log(config)
  }
}

const getTimeSeries = async (id, from = null, to = null, period = null) => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_SENSOR_ID)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_SENSOR_ID)

  if (from === null) {
    const d = new Date()
    const startDate = d.getDate() - 1

    d.setDate(startDate)
    from = d.toISOString()
  }

  if (to === null) to = new Date().toISOString()
  if (period === null) period = ''

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/sensors/admin/history/${id}?from=${from}&to=${to}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorService.getTimeSeries', error)
  }
}

const remove = async id => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/sensors/admin/sensors/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorService.remove', error)
  }
}

const save = async (body, id) => {
  const endpoint = `/sensors/admin/sensors/${id}`
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

export const save2 = async (body, id) => {
  const config = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    url: `${REACT_APP_API_URL}/sensors/admin/sensors/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorService.save', error)
  }
}

const getCalculations = async (month, year, limit, offset) => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/sensors/admin/v2-sensors-calculations/?month=${month}&year=${year}&limit=${limit}&offset=${offset}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorService.getCalculations', error)
  }
}

const importData = async (body, id) => {
  const endpoint = `/sensors/admin/sensors/${id}/import`
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

const setSensorsByUserId = async (userId, sensors) => {
  const endpoint = `/user/admin/users/${userId}/sensors/`
  const options = {
    body: { data: { sensors } },
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

const insertMissingSensorData = async data => {
  const config = {
    data: { data },
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'POST',
    url: `${REACT_APP_API_URL}/iot/missing-data`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('sensorService.insertMissingSensorData', error)
  }
}

export const sensorService = {
  getAll,
  getAllUnlinked,
  getAttributesGroup,
  getById,
  getCalculations,
  getTimeSeries,
  remove,
  save,
  importData,
  setSensorsByUserId,
  insertMissingSensorData,
}
