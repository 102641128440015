// modules
import {
  AddTwoTone,
  CancelTwoTone,
  FilterAltTwoTone,
  HighlightOffTwoTone,
  InfoTwoTone,
  MoreVertTwoTone,
  PeopleTwoTone,
  PersonAddTwoTone,
  SearchTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  //ListSubheader,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// scripts
import { ASC, DESC, getStorage, isFilterMatch, setStorage } from '../../api'
import {
  AM,
  AMItem,
  HStack,
  KPage,
  KSelect,
  KTextField,
  Preloader,
} from '../../components'
import { userConstants as UC } from '../../constants'
import { useUserPool } from '../../hooks'
import {
  UserListRow,
  UserPoolStatistics,
  UserRoleSelector,
  UserSortSelector,
  UserStatusSelector,
} from './components'

export const UserAll = ({ serviceName = 'Benutzer' }) => {
  const history = useHistory()
  const URL_USER_NEW = `/users/new`

  // config
  const PAGE_CONFIG = 'settings_users_all'
  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  useEffect(() => setStorage(PAGE_CONFIG, config), [config])
  const [actionMenu, setActionMenu] = useState(null)
  const { filter, order, orderby, poolInfo, role, status } = config

  // pools
  const { data, isError, isLoading } = useUserPool()

  // filter
  const FILTER_ROLE = item =>
    role ? role.includes(item.attributes.label) : item

  const FILTER_SEARCH = item =>
    filter
      ? isFilterMatch(filter, item.attributes.firstname) ||
        isFilterMatch(filter, item.attributes.lastname) ||
        isFilterMatch(filter, item.attributes.email) ||
        isFilterMatch(filter, item.attributes.label) ||
        Number(filter) === Number(item.id)
      : item

  const FILTER_STATUS = item =>
    status
      ? status.includes(
          item.attributes.is_active === 1
            ? UC.USER_STATUS_ACTIVE
            : UC.USER_STATUS_INACTIVE
        )
      : item

  const SORT_FILTER = (a, b) => {
    // catch: orderby = lastname & lastname = null
    if (a.attributes[orderby] === null) a.attributes[orderby] = ''
    if (b.attributes[orderby] === null) b.attributes[orderby] = ''

    if (
      (orderby === 'firstname' || orderby === 'lastname') &&
      a.attributes[orderby].toLowerCase() < b.attributes[orderby].toLowerCase()
    )
      return order === DESC ? 1 : -1

    if (orderby === 'entity_id' && Number(a.id) < Number(b.id))
      return order === DESC ? 1 : -1

    return order === DESC ? -1 : 1
  }

  // amount of user pool hits after applying all filters
  const SEARCHFILTER_COUNT = data
    .filter(FILTER_STATUS)
    .filter(FILTER_ROLE)
    .filter(FILTER_SEARCH).length

  // handler

  const changeOrderByFilter = (event, orderby) => {
    if (orderby !== null) setConfig(prev => ({ ...prev, orderby }))
    else {
      const newOrder = order === DESC ? ASC : DESC
      setConfig(prev => ({ ...prev, order: newOrder }))
    }
  }

  const changeRoleFilter = (event, role) => {
    if (role !== null) setConfig(prev => ({ ...prev, role }))
  }
  const changeSearchFilter = event =>
    setConfig(prev => ({ ...prev, filter: event.target.value }))
  const changeStatusFilter = (event, status) => {
    if (status !== null) setConfig(prev => ({ ...prev, status }))
  }
  const clearSearchFilter = () => setConfig(prev => ({ ...prev, filter: '' }))
  const closeActionMenu = () => setActionMenu(null)
  const closePoolStatistics = () =>
    setConfig(prev => ({ ...prev, poolInfo: false }))
  const gotoCreateUser = () => history.push(URL_USER_NEW)
  const openActionMenu = event => setActionMenu(event.currentTarget)
  const openPoolStatistics = () =>
    setConfig(prev => ({ ...prev, poolInfo: true }))

  // render component
  return (
    <>
      {/* preloader */}
      <Preloader error={isError} isLoading={isLoading} />

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={serviceName}
        onClose={closeActionMenu}
        open={Boolean(actionMenu)}
        historyUrlTarget={'dashboard'}
      >
        <AMItem
          caption={'Neu'}
          icon={<AddTwoTone />}
          onClick={gotoCreateUser}
        />
        <AMItem
          caption={'Info'}
          icon={<InfoTwoTone />}
          onClick={openPoolStatistics}
        />
      </AM>

      {/* pool stat */}
      <Dialog open={poolInfo} onClose={closePoolStatistics}>
        <DialogTitle>{serviceName} Pool Info</DialogTitle>
        <DialogContent>
          <UserPoolStatistics />
        </DialogContent>
      </Dialog>

      <KPage
        action={
          <IconButton onClick={openActionMenu}>
            <MoreVertTwoTone />
          </IconButton>
        }
        avatar={
          <Avatar>
            <PeopleTwoTone />
          </Avatar>
        }
        title={serviceName}
      >
        {/* toolbar */}
        <HStack justifyContent={'space-between'} sx={{ mx: 1 }}>
          <HStack>
            <UserRoleSelector onChange={changeRoleFilter} value={role} />
            <UserStatusSelector onChange={changeStatusFilter} value={status} />

            <KSelect
              icon={<FilterAltTwoTone />}
              label={'Domain Filter'}
              sx={{ minWidth: 110 }}
              value={filter}
            >
              <MenuItem
                disabled={filter === ''}
                onClick={clearSearchFilter}
                value={''}
              >
                <CancelTwoTone sx={{ mr: 1 }} />
                Zurücksetzen
              </MenuItem>

              {[
                ...new Set(
                  data
                    .map(item => item.attributes.email)
                    .map(email => email.substring(email.indexOf('@')))
                    .sort()
                ),
              ].map((domain, key) => {
                const onClick = () =>
                  setConfig(prev => ({ ...prev, filter: domain }))

                return (
                  <MenuItem onClick={onClick} key={key} value={domain}>
                    {domain}
                  </MenuItem>
                )
              })}
            </KSelect>

            <Tooltip title={'Benutzer hinzufügen'}>
              <IconButton onClick={gotoCreateUser} sx={{ bgcolor: '#DDD' }}>
                <PersonAddTwoTone />
              </IconButton>
            </Tooltip>
          </HStack>

          <UserSortSelector
            onChange={changeOrderByFilter}
            order={order}
            orderby={orderby}
          />
        </HStack>

        {/* search */}
        <KTextField
          icon={<SearchTwoTone />}
          onChange={changeSearchFilter}
          unit={
            filter && (
              <Tooltip title={'Filter löschen'}>
                <IconButton onClick={clearSearchFilter}>
                  <HighlightOffTwoTone />
                </IconButton>
              </Tooltip>
            )
          }
          value={filter}
        />

        {/* hits info */}
        <Alert severity={'info'} sx={{ mx: 1 }}>
          <HStack justifyContent={'space-between'}>
            <Typography>
              <Box component={'span'} fontWeight={'bold'}>
                {SEARCHFILTER_COUNT}
              </Box>{' '}
              {serviceName} entsprechen dem Suchfilter &quot;
              <Box component={'span'} fontWeight={'bold'}>
                {filter}
              </Box>
              &quot;{' '}
            </Typography>
          </HStack>
        </Alert>

        {/* user pool */}
        <Collapse in={!isLoading}>
          <List
            /*
            pending: 

            subheader={
              <ListSubheader
                component={'div'}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box sx={{ width: 1 / 5 }}>Avatar</Box>
                <Box sx={{ width: 1 / 5 }}>Name</Box>
                <Box sx={{ width: 1 / 5 }}>Rolle</Box>
                <Box sx={{ width: 1 / 5 }}>Status</Box>
                <Box sx={{ width: 1 / 5 }}>erstellt am</Box>
              </ListSubheader>
            }
              */
            sx={{
              m: 1,
              maxHeight: 200,
              minHeight: `calc(100vh - 560px)`,
              overflow: 'auto',
            }}
          >
            {data
              .filter(FILTER_STATUS)
              .filter(FILTER_ROLE)
              .filter(FILTER_SEARCH)
              .sort(SORT_FILTER)
              .map((user, key) => (
                <UserListRow key={key} user={user} />
              ))}
          </List>
        </Collapse>
      </KPage>
    </>
  )
}

UserAll.propTypes = {
  serviceName: PropTypes.string,
}
