import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material'
import {
  AccountBalanceTwoTone,
  BusinessTwoTone,
  EditTwoTone,
  FaceTwoTone,
  MemoryTwoTone,
  MessageTwoTone,
  MoreVertTwoTone,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Coach, ContractStatus, FolderNews, OfficeHours } from '../'
import {
  categoryByType,
  getCorrelationStatusColorDetailPage,
  getRelativeDate,
  normalizedSensorType,
  SensorIcon,
  sensorType,
  SORT,
} from '../../api'
import { alertActions } from '../../actions'
import { AM, AMItem, KPage, Preloader } from '../../components'
import { useFolder, useOperator, useSensor } from '../../hooks'

const { REACT_APP_API_GOOGLEMAPS } = process.env

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

export const FolderSingle = () => {
  const theme = useTheme()
  const success = theme.palette.success.main
  const error = theme.palette.error.main

  const dispatch = useDispatch()
  const history = useHistory()
  const { id: folderId, section } = useParams()

  const [folderIcon, setFolderIcon] = useState(null)
  const [primaryCoachId, setPrimaryCoachId] = useState()
  const [tab, setTab] = useState(Number(section) || 0)

  const [bridgeSensor, setBridgeSensor] = useState(null)

  // color status of categories
  const [colorStatus, setColorStatus] = useState([
    { type: 'performance', status: 'neutral' },
    { type: 'installation', status: 'neutral' },
    { type: 'correlation', status: 'neutral' },
    { type: 'security', status: 'neutral' },
    { type: 'feeling', status: 'neutral' },
  ])

  const {
    data: operatorData,
    isError: operatorError,
    isLoading: operatorLoading,
  } = useOperator()

  const {
    data: folderData,
    isError: folderError,
    isLoading: folderLoading,
  } = useFolder(folderId)

  useEffect(
    () => {
      if (folderData) {
        const { image_url, sensors, sensor_group_status: sgs } = folderData

        if (image_url !== null) setFolderIcon(image_url)

        if (!sensors || !sensors[0])
          dispatch(alertActions.error('Kein Sensor im Gebäude gefunden'))
        else setBridgeSensor(sensors[0].entity_id)

        if (sgs) {
          const performance = sgs.find(f => f.type === 'status_performance')
          const security = sgs.find(f => f.type === 'status_security')
          const feeling = sgs.find(f => f.type === 'status_well_being')
          const installations = sgs.find(f => f.type === 'status_installation')
          setColorStatus({
            performance: performance.status,
            security: security.status,
            feeling: feeling.status,
            correlation: getCorrelationStatusColorDetailPage(sensors, folderId)
              ? 'green'
              : 'neutral',
            installation: installations.status,
          })
        }
      }

      if (folderError) console.error(folderError)
    },
    [dispatch, folderData, folderError, folderId]
  )

  const {
    data: sensorData,
    isLoading: sensorLoading,
    isError: sensorError,
  } = useSensor(bridgeSensor)

  useEffect(
    () => {
      if (sensorData) {
        const { contracts } = sensorData

        if (contracts && contracts[0]) {
          const { primary_coach_id } = contracts[0]

          if (primary_coach_id) setPrimaryCoachId(primary_coach_id)
        }
      }
    },
    [sensorData]
  )

  const renderFolder = () => {
    if (
      !folderId ||
      folderId === '' ||
      folderLoading ||
      !folderData ||
      folderError
    )
      return <Box />

    const {
      city,
      country,
      name,
      office_hours,
      news,
      sensors,
      street,
      zip,
    } = folderData

    const RegularMap = ''

    if (!sensors) return <Box />

    return (
      <>
        <Tabs
          centered
          indicatorColor={'primary'}
          onChange={(event, tab) => {
            setTab(tab)
            history.push(`/folder/${folderId}/${tab}`)
          }}
          scrollButtons={'auto'}
          sx={{ mb: 10 }}
          textColor={'primary'}
          value={tab}
        >
          <Tab value={0} label={'Info'} icon={<BusinessTwoTone />} />
          <Tab value={1} label={'Status'} icon={<MemoryTwoTone />} />
          <Tab value={2} label={'News'} icon={<MessageTwoTone />} disabled />
          {/*<Tab value={3} label={'Notfall'} icon={<LocalHospitalTwoTone />} />*/}
          <Tab value={4} label={'Coach'} icon={<FaceTwoTone />} />
        </Tabs>

        {/* info */}
        <Collapse in={tab === 0}>
          {folderLoading && <LinearProgress color={'primary'} />}
          <Collapse in={!folderLoading}>
            <Typography component={'h4'} variant={'h4'}>
              {name}
            </Typography>
            <Typography component={'h6'} variant={'h6'}>
              {street}
            </Typography>
            <Typography component={'h6'} variant={'h6'}>
              {zip} {city}
              <br />
              {countries.getName(country, 'de')}
            </Typography>
            {RegularMap && (
              <RegularMap
                googleMapURL={REACT_APP_API_GOOGLEMAPS}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `280px`,
                      borderRadius: '6px',
                      overflow: 'hidden',
                      border: 'solid 1px black',
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
              />
            )}
            <OfficeHours data={office_hours} />

            <ContractStatus folderId={Number(folderId)} />
          </Collapse>
        </Collapse>

        {/* status */}
        <Collapse in={tab === 1}>
          <Grid container spacing={5}>
            {[...new Set(sensors.map(i => i.name))]
              .sort(SORT)
              .map((item, key) => {
                const sensorTypes = [
                  ...new Set(sensors.filter(f => f.name === item)),
                ].sort()

                let sensor_type = sensors.find(f => f.name === item)
                sensor_type = sensor_type.sensor_type || ''

                let time = sensors.find(f => f.name === item)
                time = time.time || ''

                let type_in_folder = sensors.find(f => f.name === item)
                type_in_folder = type_in_folder.type_in_folder || ''

                const hours =
                  new Date(Date.now() - new Date(time)).getTime() /
                  1000 /
                  60 /
                  60
                const days = Math.trunc(hours / 24)

                let severity = 'success'
                if (days >= 3) severity = 'error'
                if (days >= 1 && days < 3) severity = 'warning'
                if (days <= 0) severity = 'success'

                return (
                  <Grid item xs={12} sm={12} md={6} lg={4} key={key}>
                    <Card sx={{ backgroundColor: 'lightgray' }}>
                      <CardHeader
                        avatar={
                          <Avatar>
                            <Box
                              alt={''}
                              component={'img'}
                              src={SensorIcon(
                                categoryByType(sensor_type, type_in_folder)
                              )}
                              sx={{ width: 40 }}
                            />
                          </Avatar>
                        }
                        title={item}
                      />
                      <CardContent>
                        {severity !== 'success' && (
                          <Alert severity={severity}>
                            {severity === 'warning' && (
                              <>zuletzt aktualisiert vor mehr als 24h</>
                            )}
                            {severity === 'error' && (
                              <>zuletzt aktualisiert vor mehr als 72h</>
                            )}
                          </Alert>
                        )}

                        <List>
                          {sensorTypes.map((item, key) => {
                            const {
                              entity_id,
                              is_active,
                              sensor_type,
                              status,
                              time,
                              type_in_folder,
                              value,
                              unit,
                            } = item

                            let image_url = SensorIcon(
                              normalizedSensorType(sensor_type, type_in_folder),
                              status
                            )

                            if (!image_url)
                              image_url = SensorIcon(
                                type_in_folder,
                                [error, success][is_active]
                              )

                            return (
                              <ListItem
                                button
                                key={key}
                                onClick={() =>
                                  operatorData?.isAdmin &&
                                  history.push(`/sensors/${entity_id}`)
                                }
                                sx={{
                                  cursor: operatorData?.isAdmin
                                    ? 'pointer'
                                    : 'default',
                                  ...(!operatorData?.isAdmin && {
                                    pointerEvents: 'none',
                                    backgroundColor: '#D3D3D3',
                                  }),
                                }}
                              >
                                <ListItemAvatar>
                                  <Avatar src={image_url} width={80} />
                                </ListItemAvatar>

                                <ListItemText
                                  primary={sensorType(
                                    sensor_type,
                                    type_in_folder
                                  )}
                                  secondary={`${value} ${unit} ${getRelativeDate(
                                    String(new Date(Date.parse(time)))
                                  )}`}
                                />
                              </ListItem>
                            )
                          })}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              })}
          </Grid>
        </Collapse>

        {/* news */}
        <Collapse in={tab === 2}>
          <FolderNews news={news} />
        </Collapse>

        {/* coach */}
        <Collapse in={tab === 4}>
          <Coach primaryCoachId={primaryCoachId} />
        </Collapse>
      </>
    )
  }

  const renderSubFolder = () => {
    return !folderLoading &&
      folderData &&
      folderData.folders &&
      folderData.folders.length > 0 ? (
      <Collapse>
        <FormControl sx={{ mb: 5, mt: 10 }} fullWidth>
          <Typography component={'h6'} variant={'h6'}>
            Niederlassungen
          </Typography>
        </FormControl>

        <List>
          {folderData.folders.map((item, key) => {
            const { city, entity_id, image_url, name, street, zip } = item

            return (
              <ListItem
                button
                key={key}
                onClick={() => history.push(`/folder/${entity_id}`)}
              >
                <ListItemAvatar>
                  <Avatar src={image_url} width={80} />
                </ListItemAvatar>
                <ListItemText
                  primary={name}
                  secondary={`${street}, ${zip} ${city}`}
                />

                <ListItemSecondaryAction>
                  <IconButton
                    edge={'end'}
                    onClick={() => history.push(`/folder/${entity_id}/edit`)}
                  >
                    <EditTwoTone />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      </Collapse>
    ) : (
      <Box />
    )
  }

  const [actionMenu, showActionMenu] = useState(null)
  const isPageLoading =
    folderLoading ||
    operatorLoading ||
    sensorLoading ||
    (!folderData ||
      (folderData && Number(folderData.entity_id) !== Number(folderId)))
  const isPageError = folderError || operatorError || sensorError

  if (operatorLoading) return <Box />

  return (
    <>
      {/* preloader */}
      <Preloader
        caption={`laden ...`}
        error={isPageError}
        isLoading={isPageLoading}
      />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Gebäude'}
        open={Boolean(actionMenu)}
        onClose={() => showActionMenu(null)}
      >
        {operatorData &&
          operatorData.isAdmin && (
            <AMItem
              caption={'Bearbeiten'}
              icon={<EditTwoTone />}
              onClick={() => history.push(`/folder/${folderId}/edit`)}
            />
          )}

        <Divider />
        <AMItem
          caption={'Leistung'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('performance', colorStatus.performance)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/performance`)}
        />
        <AMItem
          caption={'Anlagen'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('installation', colorStatus.installation)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/installation`)}
        />
        <AMItem
          caption={'Korrelation'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('correlation', colorStatus.correlation)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/correlation`)}
        />
        <AMItem
          caption={'Sicherheit'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('security', colorStatus.security)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/security`)}
        />
        <AMItem
          caption={'Wohlbefinden'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('feeling', colorStatus.feeling)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/feeling`)}
        />
      </AM>

      <KPage
        avatar={
          <Avatar>
            {folderIcon === null ? (
              <AccountBalanceTwoTone />
            ) : (
              <Box
                alt={''}
                component={'img'}
                src={folderIcon}
                sx={{ width: 40, height: 40 }}
              />
            )}
          </Avatar>
        }
        action={
          <IconButton onClick={event => showActionMenu(event.currentTarget)}>
            <MoreVertTwoTone />
          </IconButton>
        }
        isLoading={isPageLoading}
        subheader={''}
        title={
          !folderLoading &&
          folderData &&
          folderData.name +
            (folderData.name_short !== null
              ? ` (${folderData.name_short})`
              : '')
        }
      >
        {renderFolder()}
        {renderSubFolder()}
      </KPage>
    </>
  )
}
