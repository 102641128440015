// modules
import { HighlightOffTwoTone } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Collapse,
  Container,
  FormControl,
  Grid,
  IconButton,
  ImageList,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// scripts
import { newsActions, systemActions } from '../../actions'
import { ASC, DESC, getStorage, setStorage } from '../../api'
import { KTextField, Preloader } from '../../components'
import { useAppConfig, useFolderPool, useSensorPool } from '../../hooks'
import { DashboardAlerts, FolderStatus, OperatorGreeting } from './components'

export const Overview = () => {
  const dispatch = useDispatch()

  const theme = useTheme()
  const { breakpoints } = theme
  const isXS = useMediaQuery(breakpoints.only('xs'))
  const isSM = useMediaQuery(breakpoints.only('sm'))
  const isMD = useMediaQuery(breakpoints.only('md'))
  const isLG = useMediaQuery(breakpoints.only('lg'))
  const isXL = useMediaQuery(breakpoints.only('xl'))

  const determineCols = () =>
    isXL ? 3 : isLG ? 2 : isMD ? 2 : isSM ? 1 : isXS ? 1 : 0

  const { isInMaintenance, maintenanceText } = useSelector(
    state => state.system
  )

  const PAGE_CONFIG = 'settings_dashboard'
  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))

  const { global } = useAppConfig()
  const {
    data: folderPool,
    isLoading: isFolderPoolLoading,
    isError: isFolderPoolError,
  } = useFolderPool()
  const {
    isLoading: isSensorPoolLoading,
    isError: isSensorPoolError,
  } = useSensorPool()

  const [order, setOrder] = useState(ASC)
  const [orderby, setOrderBy] = useState('name')
  const [messageActive, setMessageActive] = useState(0)

  const inputFilter = f =>
    f.attributes.name.toLowerCase().includes(config.objectFilter.toLowerCase())

  const sortFilter = (a, b) => {
    if (a.attributes[orderby] < b.attributes[orderby])
      return order === DESC ? 1 : -1

    return order === DESC ? -1 : 1
  }

  useEffect(
    () => {
      dispatch(systemActions.getIsInMaintenance())
      dispatch(systemActions.getMaintenanceText())
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (isInMaintenance) {
        const { status } = isInMaintenance

        if (status) {
          const { data } = status

          if (data) {
            const { is_in_maintenance } = data

            if (is_in_maintenance) setMessageActive(is_in_maintenance)
          }
        }
      }
    },
    [isInMaintenance]
  )

  useEffect(
    () => {
      if (maintenanceText) {
        const { text } = maintenanceText

        if (text) {
          const { data } = text

          if (data) {
            const { maintenance_message } = data

            if (maintenance_message) setMessageActive(maintenance_message)
          }
        }
      }
    },
    [maintenanceText]
  )

  useEffect(
    () => {
      if (global.alertsMax && global.mask) {
        const status = 'red'
        const page = 1

        dispatch(
          newsActions.getAllAlerts(
            `?page=${page}&limit=${global.alertsMax}&status=${status}&mask=${
              global.mask
            }`
          )
        )
      }
    },
    [dispatch, global.alertsMax, global.mask]
  )

  useEffect(() => setStorage(PAGE_CONFIG, config), [config, PAGE_CONFIG])

  const isError = isFolderPoolError || isSensorPoolError
  const isPageLoading = isFolderPoolLoading || isSensorPoolLoading

  const { global: appConfig } = useAppConfig()
  const isWideScreen = appConfig?.wideScreen || false

  return (
    <>
      {/* preloader */}
      <Preloader error={isError} isLoading={isPageLoading} />
      {/* preloader end */}

      <Collapse in={!isPageLoading}>
        <Container maxWidth={isWideScreen === true ? 'false' : 'xl'}>
          {global.salutationMode !== 'noSalutation' && <OperatorGreeting />}

          {messageActive === 1 && (
            <Alert severity={'info'}>
              <AlertTitle>WARTUNGSARBEITEN</AlertTitle>
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  wordWrap: 'break-word',
                }}
              >
                {global.maintenanceMessage}
              </Typography>
            </Alert>
          )}

          {global.alertsEnabled && !isPageLoading && <DashboardAlerts />}

          <Grid container justifyContent={'flex-end'} sx={{ mb: 2 }}>
            <FormControl>
              <KTextField
                label={'Filter'}
                onChange={event =>
                  setConfig(prev => ({
                    ...prev,
                    objectFilter: event.target.value,
                  }))
                }
                unit={
                  config.objectFilter && (
                    <Tooltip title={'Filter löschen'}>
                      <IconButton
                        onClick={() =>
                          setConfig(prev => ({ ...prev, objectFilter: '' }))
                        }
                      >
                        <HighlightOffTwoTone />
                      </IconButton>
                    </Tooltip>
                  )
                }
                value={config.objectFilter}
              />
            </FormControl>
            <FormControl>
              <InputLabel>Sortierung</InputLabel>
              <Select
                inputProps={{ name: 'sort', id: 'sort-helper' }}
                onChange={event => setOrderBy(event.target.value)}
                value={orderby}
              >
                {[
                  { label: 'Name', value: 'name' },
                  { label: 'Ort', value: 'city' },
                  { label: 'Datum', value: 'created_at' },
                ].map((item, key) => (
                  <MenuItem key={key} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Reihenfolge</InputLabel>
              <Select
                inputProps={{ name: 'order', id: 'order-helper' }}
                onChange={event => setOrder(event.target.value)}
                value={order}
              >
                {[
                  { label: 'Aufsteigend', value: ASC },
                  { label: 'Absteigend', value: DESC },
                ].map((item, key) => (
                  <MenuItem key={key} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <ImageList cols={determineCols()} gap={10} sx={{ width: 1 }}>
            {!isPageLoading &&
              folderPool
                .filter(inputFilter)
                .sort(sortFilter)
                .map((folder, key) => (
                  <FolderStatus folder={folder} key={key} />
                ))}
          </ImageList>
        </Container>
      </Collapse>
    </>
  )
}
