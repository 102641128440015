import PropTypes from 'prop-types'
import React from 'react'
import { svgConstants as SVG } from '../constants'
import { useTheme } from '@mui/material'

// purpose of this function is to return a "normalized" sensor_type
// examples:
// - 'energy_detail' returns 'energy'
//

export const normalizedSensorType = (
  type,
  typeInFolder,
  subSensorTarget = null
) => {
  switch (type || subSensorTarget) {
    // category consumption
    case `coldwater_puls`:
    case `counterChannelA`:
    case `counterChannelB`:
    case `counter-channelA_electricity`:
    case `counter-channelA_gas`:
    case `counter-channelA_water`:
    case `counter-channelB_water`:
    case `count_0`:
    case `count_1`:
    case `count_2`:
    case `count_3`:
    case `count_4`:
    case `count_5`:
    case `count_6`:
    case `count_7`:
    case `count_8`:
    case `count_9`:
    case `count_value`:
    case `electricity_puls`:
    case `gas_puls`:
    case `heat_puls`:
    case `hotwater_puls`:
    case `power`:
    case `pulse_count`:
    case `pulse_count_2`:
    case `register1now`:
    case `register2now`:
    case `water_puls`:
    case `heating_energy`:
    case `cold_water`:
    case `active_energy`:
    case `active_power`:
    case `volume`:
    case `voltage`:
      if (typeInFolder === `energy`) return 'energy'
      if (typeInFolder === `energy_detail`) return 'energy'
      if (typeInFolder === `gas`) return 'gas'
      if (typeInFolder === `gas_detail`) return 'gas'
      if (typeInFolder === `water`) return 'water'
      if (typeInFolder === `water_detail`) return 'water'
      return type

    // category feeling
    case 'temperature_0':
    case 'temperature_1':
    case 'external_temp_1':
    case 'Ni1000':
    case 'degrees-celsius':
      return 'temperature'

    // category security
    case 'external_digital_button':
    case 'external_digital_button_1':
      if (subSensorTarget === `elevator`) return 'elevator'
      if (subSensorTarget === `lifting`) return 'lifting'
      return type

    default:
      return type
  }
}

export const sensorType = (
  type,
  typeInFolder = null,
  subSensorTarget = null
) => {
  if (typeInFolder === `energy`) return 'Strom'
  if (typeInFolder === `energy_detail`) return 'Strom'
  if (typeInFolder === `gas`) return 'Gas'
  if (typeInFolder === `gas_detail`) return 'Gas'
  if (typeInFolder === `water`) return 'Wasser'
  if (typeInFolder === `water_detail`) return 'Wasser'

  switch (type || subSensorTarget) {
    case `co2`:
      return `Luftqualität`

    case `electricity_puls`:
    case `energy`:
    case `energy_detail`:
      return `Strom`

    case `gas`:
    case `gas_puls`:
      return `Wärme`

    case `humidity`:
      return `Luftfeuchtigkeit`

    case `light`:
      return `Lichtstärke`

    case `temperature`:
    case `temperature_0`:
    case `temperature_1`:
    case `external_temp_1`:
    case 'Ni1000':
    case 'degrees-celsius':
      return `Temperatur`

    case `pressure`:
      return `Anlagendruck `

    case `smokeDetektor`:
      return `Rauchmelder `

    case `Smoke_alarm`:
      return `Alarmauslösung`

    case `Battery_end_of_life`:
      return `Batteriestatus`

    case `Removal_detection`:
      return `Entfernungserkennung`

    case `Acoustic_alarm_failure`:
      return `Fehler akustisches Signal`

    case `water`:
    case `water_puls`:
    case `hotwater_puls`:
      return `Wasser`

    case `waterDetected`:
    case `payload`:
      return `Wasser Detektor`

    case `tor1CurrentState`:
    case `tor1PreviousState`:
      return `Türschloss`

    case `door`:
    case `tor2CurrentState`:
    case `tor2PreviousState`:
    case `door_channel1`:
    case `door_channel2`:
    case `door_channel3`:
    case `door_channel4`:
      return `Tür`

    case `motion`:
      return `Bewegungsmelder`

    case 'external_digital_button':
    case 'external_digital_button_1':
      if (subSensorTarget === `elevator`) return 'Aufzug'
      if (subSensorTarget === `lifting`) return 'Hebeanlage'
      return type

    default:
      return type
  }
}

export const categoryByType = (
  type,
  typeInFolder = null,
  subSensorTarget = null
) => {
  if (typeInFolder === `energy`) return 'performance'
  if (typeInFolder === `energy_detail`) return 'performance'
  if (typeInFolder === `gas`) return 'performance'
  if (typeInFolder === `gas_detail`) return 'performance'
  if (typeInFolder === `water`) return 'performance'
  if (typeInFolder === `water_detail`) return 'performance'
  if (type === null) return type

  switch (type || subSensorTarget) {
    case `coldwater_puls`:
    case `count_0`:
    case `count_1`:
    case `count_2`:
    case `count_3`:
    case `count_4`:
    case `count_5`:
    case `count_6`:
    case `count_7`:
    case `count_8`:
    case `count_9`:
    case `counter-channelA_electricity`:
    case `counter-channelA_gas`:
    case `counter-channelA_water`:
    case `counter-channelB_water`:
    case `counterChannelA`:
    case `counterChannelB`:
    case `current`:
    case `electricity_A_Plus`:
    case `electricity_puls`:
    case `energy`:
    case `energy_detail`:
    case `gas`:
    case `gas_puls`:
    case `heat_energy`:
    case `heat_puls`:
    case `hotwater_puls`:
    case `power`:
    case `water`:
    case `water_puls`:
    case `heating_energy`:
    case `cold_water`:
    case `active_energy`:
    case `active_power`:
    case `volume`:
    case `voltage`:
      return `performance`

    case `co2`:
    case `humidity`:
    case `light`:
    case `temperature`:
      return `feeling`

    case `Acoustic_alarm_failure`:
    case `Battery_end_of_life`:
    case `door`:
    case `door_channel1`:
    case `door_channel2`:
    case `door_channel3`:
    case `door_channel4`:
    case `elevator`:
    case `external_temp_1`:
    case `external_digital_button`:
    case `lifting`:
    case `motion`:
    case `payload`:
    case `pressure`:
    case `Removal_detection`:
    case `Smoke_alarm`:
    case `smokeDetektor`:
    case `temperature_0`:
    case `temperature_1`:
    case `tor1CurrentState`:
    case `tor1PreviousState`:
    case `tor2CurrentState`:
    case `tor2PreviousState`:
    case `waterDetected`:
    case `Ni1000`:
    case `degrees-celsius`:
      return `security`

    default:
      console.error('unhandled categoryByType:', type)
      return type
  }
}

export const sensorUnit = (
  type,
  typeInFolder = null,
  subSensorTarget = null
) => {
  if (typeInFolder === `energy`) return 'kWh'
  if (typeInFolder === `energy_detail`) return 'kWh'
  if (typeInFolder === `gas`) return 'kWh'
  if (typeInFolder === `gas_detail`) return 'kWh'
  if (typeInFolder === `water`) return 'm³'
  if (typeInFolder === `water_detail`) return 'm³'

  switch (type || subSensorTarget) {
    case `co2`:
      return `ppm`

    case `electricity_puls`:
    case `energy`:
    case `energy_detail`:
    case `gas`:
    case `gas_puls`:
    case `count_0`:
    case `count_1`:
    case `heating_energy`:
    case `active_energy`:
    case `active_power`:
    case `voltage`:
      return `kWh`

    case `humidity`:
      return `%`

    case `light`:
      return `lx`

    case `temperature`:
    case `temperature_0`:
    case `temperature_1`:
    case `external_temp_1`:
    case `Ni1000`:
    case `degrees-celsius`:
      return `°C`

    case `pressure`:
      return `Bar`

    case `water`:
    case `water_puls`:
    case `volume`:
      return `m³`

    case `tor1CurrentState`:
    case `tor2CurrentState`:
    case `tor1PreviousState`:
    case `tor2PreviousState`:
    case `door`:
    case `door_channel1`:
    case `door_channel2`:
    case `door_channel3`:
    case `door_channel4`:
    case `payload`:
    case `motion`:
    case `waterDetected`:
    case 'external_digital_button':
    case 'external_digital_button_1':
    case `elevator`:
    case `lifting`:
    case `smokeDetektor`:
    case `Smoke_alarm`:
    case `Battery_end_of_life`:
    case `Removal_detection`:
    case `Acoustic_alarm_failure`:
      return ``

    default:
      return type
  }
}

export const SensorIcon = (
  type,
  status = null,
  isOpen = false,
  subSensorTarget = null
) => {
  switch (type || subSensorTarget) {
    // correlation - edge case icon
    case 'correlation':
      switch (status) {
        case 'green':
          return SVG.CORRELATION_GOOD

        case 'yellow':
          return SVG.CORRELATION_WARNING

        case 'red':
          return SVG.CORRELATION_ALERT

        default:
          return SVG.CORRELATION_INACTIVE
      }

    // feeling - category icon
    case 'feeling':
      switch (status) {
        case 'green':
          return SVG.FEELING_GOOD

        case 'yellow':
          return SVG.FEELING_WARNING

        case 'red':
          return SVG.FEELING_ALERT

        default:
          return SVG.FEELING_INACTIVE
      }

    // security - category icon
    case 'security':
      switch (status) {
        case 'green':
          return SVG.SECURITY_GOOD

        case 'yellow':
          return SVG.SECURITY_WARNING

        case 'red':
          return SVG.SECURITY_ALERT

        default:
          return SVG.SECURITY_INACTIVE
      }

    // performance - category icon
    case 'consumption':
    case 'performance':
      switch (status) {
        case 'green':
          return SVG.PERFORMANCE_GOOD

        case 'yellow':
          return SVG.PERFORMANCE_WARNING

        case 'red':
          return SVG.PERFORMANCE_ALERT

        default:
          return SVG.PERFORMANCE_INACTIVE
      }

    // installation - category icon
    case 'installation':
      switch (status) {
        case 'green':
          return SVG.INSTALLATION_GOOD

        case 'yellow':
          return SVG.INSTALLATION_WARNING

        case 'red':
          return SVG.INSTALLATION_ALERT

        default:
          return SVG.INSTALLATION_INACTIVE
      }

    case 'energy':
    case 'energy_detail':
    case 'gas':
    case 'gas_detail':
    case 'water':
    case 'water_detail':
      switch (status) {
        case 'green':
          return SVG.CONSUMPTION_GOOD

        case 'yellow':
          return SVG.CONSUMPTION_WARNING

        case 'red':
          return SVG.CONSUMPTION_ALERT

        default:
          return SVG.CONSUMPTION_INACTIVE
      }

    case 'co2':
      switch (status) {
        case 'green':
          return SVG.CO2_GOOD

        case 'yellow':
          return SVG.CO2_WARNING

        case 'red':
          return SVG.CO2_ALERT

        default:
          return SVG.CO2_INACTIVE
      }

    case 'humidity':
      switch (status) {
        case 'green':
          return SVG.HUMIDITY_GOOD

        case 'yellow':
          return SVG.HUMIDITY_WARNING

        case 'red':
          return SVG.HUMIDITY_ALERT

        default:
          return SVG.HUMIDITY_INACTIVE
      }

    case 'light':
      switch (status) {
        case 'green':
          return SVG.LIGHT_GOOD

        case 'yellow':
          return SVG.LIGHT_WARNING

        case 'red':
          return SVG.LIGHT_ALERT

        default:
          return SVG.LIGHT_INACTIVE
      }

    case 'temperature':
      switch (status) {
        case 'green':
          return SVG.TEMPERATURE_GOOD

        case 'yellow':
          return SVG.TEMPERATURE_WARNING

        case 'red':
          return SVG.TEMPERATURE_ALERT

        default:
          return SVG.TEMPERATURE_INACTIVE
      }

    // Rücklauf Temperatur und Anlagendruck
    case 'external_temp_1':
    case 'pressure':
    case 'temperature_0':
    case 'temperature_1':
    case 'Ni1000':
    case 'degrees-celsius':
      switch (status) {
        case 'green':
          return SVG.TEMPERATURE_GOOD

        case 'yellow':
          return SVG.TEMPERATURE_WARNING

        case 'red':
          return SVG.TEMPERATURE_ALERT

        default:
          return SVG.TEMPERATURE_INACTIVE
      }

    // Türschloss
    case `tor1CurrentState`:
    case `tor1PreviousState`:
      switch (status) {
        case 'green':
          return SVG.DOOR_LOCKED_GOOD

        case 'yellow':
          return SVG.DOOR_LOCKED_WARNING

        case 'red':
          return SVG.DOOR_LOCKED_ALERT

        default:
          return SVG.DOOR_LOCKED_INACTIVE
      }

    // Tür
    case 'door':
    case 'door_channel1':
    case 'door_channel2':
    case 'door_channel3':
    case 'door_channel4':
    case 'tor2CurrentState':
    case 'tor2PreviousState':
      switch (status) {
        case 'green':
          return isOpen ? SVG.DOOR_OPEN_GOOD : SVG.DOOR_CLOSED_GOOD

        case 'yellow':
          return isOpen ? SVG.DOOR_OPEN_WARNING : SVG.DOOR_CLOSED_WARNING

        case 'red':
          return isOpen ? SVG.DOOR_OPEN_ALERT : SVG.DOOR_CLOSED_ALERT

        default:
          return isOpen ? SVG.DOOR_OPEN_INACTIVE : SVG.DOOR_CLOSED_INACTIVE
      }

    // Wasserdetektor
    case 'waterDetected':
      switch (status) {
        case 'green':
          return SVG.WATER_DETECTOR_GOOD

        case 'yellow':
          return SVG.WATER_DETECTOR_WARNING

        case 'red':
          return SVG.WATER_DETECTOR_ALERT

        default:
          return SVG.WATER_DETECTOR_INACTIVE
      }

    // Aufzug
    case 'elevator':
      switch (status) {
        case 'green':
          return SVG.ELEVATOR_GOOD

        case 'red':
          return SVG.ELEVATOR_ALERT

        default:
          return SVG.ELEVATOR_INACTIVE
      }

    // Hebeanlage
    case 'lifting':
      switch (status) {
        case 'green':
          return SVG.LIFTING_STATION_GOOD

        case 'red':
          return SVG.LIFTING_STATION_ALERT

        case 'yellow':
          return SVG.LIFTING_STATION_WARNING

        default:
          return SVG.LIFTING_STATION_INACTIVE
      }

    // Rauchmelder
    case 'smokeDetektor':
    case 'Smoke_alarm':
    case 'Battery_end_of_life':
    case 'Removal_detection':
    case 'Acoustic_alarm_failure':
      switch (status) {
        case 'green':
          return SVG.SMOKE_DETECTOR_GOOD

        case 'red':
          return SVG.SMOKE_DETECTOR_ALERT

        case 'yellow':
          return SVG.SMOKE_DETECTOR_WARNING

        default:
          return SVG.SMOKE_DETECTOR_INACTIVE
      }

    default:
      return SVG.UNKNOWN_SENSOR
  }
}

export const SVGStatus = ({ type, status = null }) => {
  const theme = useTheme()
  const { white: color } = theme.palette
  const success = theme.palette.success.main
  const warning = theme.palette.warning.main
  const error = theme.palette.error.main
  const correlation = theme.palette.correlation.main

  let bgcolor

  switch (status) {
    case 'green':
      bgcolor = type === 'correlation' ? correlation : success
      break

    case 'yellow':
      bgcolor = warning
      break

    case 'red':
      bgcolor = error
      break

    default:
      bgcolor = '#C4C5C5'
      break
  }

  switch (type) {
    case 'correlation':
      return (
        <svg viewBox={'0 0 40 40'}>
          <circle cx={20} cy={20} r={20} style={{ fill: bgcolor }} />
          <path
            d={
              'M30.84,24.97l-3.56,0.25c-0.25-0.66-0.89-1.13-1.63-1.13c-0.05,0-0.09,0.01-0.14,0.01l-1.32-3.41l0.78-0.88 c0.25,0.14,0.54,0.23,0.85,0.23c0.89,0,1.62-0.68,1.73-1.54l3.41-0.53c0.27-0.04,0.46-0.3,0.42-0.57c-0.04-0.27-0.3-0.46-0.57-0.42 l-3.42,0.54c-0.29-0.57-0.88-0.97-1.56-0.97c-0.96,0-1.75,0.79-1.75,1.75c0,0.29,0.08,0.56,0.2,0.8l-0.48,0.54l-3.07-7.94 c0.41-0.32,0.68-0.82,0.68-1.38c0-0.96-0.79-1.75-1.75-1.75s-1.75,0.79-1.75,1.75c0,0.45,0.17,0.85,0.45,1.16l-4,6.47 c-0.15-0.04-0.29-0.07-0.45-0.07c-0.53,0-1,0.24-1.32,0.62l-1.93-0.86V8.5c0-0.55-0.45-1-1-1s-1,0.45-1,1v22.04c0,0.55,0.45,1,1,1 h20c0.55,0,1-0.45,1-1s-0.45-1-1-1H11.17l2.01-2.34c0.22,0.1,0.46,0.16,0.71,0.16c0.85,0,1.56-0.61,1.72-1.41l2.41-0.23 c0.26,0.64,0.89,1.09,1.62,1.09c0.96,0,1.75-0.79,1.75-1.75c0-0.34-0.1-0.65-0.27-0.92l2.32-2.61l1.13,2.93 c-0.41,0.32-0.68,0.82-0.68,1.38c0,0.96,0.79,1.75,1.75,1.75c0.83,0,1.53-0.59,1.71-1.37l3.55-0.25c0.28-0.02,0.48-0.26,0.46-0.53 C31.35,25.16,31.12,24.94,30.84,24.97z M25.81,17.54c0.41,0,0.75,0.34,0.75,0.75s-0.34,0.75-0.75,0.75s-0.75-0.34-0.75-0.75 S25.4,17.54,25.81,17.54z M13.9,26.36c-0.41,0-0.75-0.34-0.75-0.75s0.34-0.75,0.75-0.75s0.75,0.34,0.75,0.75 S14.31,26.36,13.9,26.36z M19.65,25.81c-0.41,0-0.75-0.34-0.75-0.75s0.34-0.75,0.75-0.75s0.75,0.34,0.75,0.75 S20.06,25.81,19.65,25.81z M20.38,23.47c-0.22-0.1-0.47-0.17-0.73-0.17c-0.85,0-1.56,0.61-1.72,1.41l-2.41,0.23 c-0.26-0.64-0.89-1.09-1.62-1.09c-0.96,0-1.75,0.79-1.75,1.75c0,0.35,0.1,0.67,0.28,0.94l-1.78,2.06v-9.88l1.52,0.68 c-0.01,0.07-0.02,0.14-0.02,0.22c0,0.96,0.79,1.75,1.75,1.75s1.75-0.79,1.75-1.75c0-0.45-0.17-0.85-0.45-1.16l4-6.47 c0.15,0.04,0.29,0.07,0.45,0.07c0.05,0,0.09-0.01,0.14-0.01l3.26,8.42L20.38,23.47z'
            }
            style={{ fill: color }}
          />
        </svg>
      )

    case 'feeling':
      return (
        <svg viewBox={'0 0 118 118'}>
          <g transform={'matrix(2.66667,0,0,2.66667,-103.744,-356.521)'}>
            <g transform={'matrix(0,-1,-1,0,60.904,135.695)'}>
              <path
                d={
                  'M-20,-20C-31.046,-20 -40,-11.046 -40,0C-40,11.045 -31.046,20 -20,20C-8.954,20 0,11.045 0,0C0,-11.046 -8.954,-20 -20,-20'
                }
                style={{ fill: bgcolor }}
              />
            </g>
            <g transform={'matrix(0,-1,-1,0,60.904,135.695)'}>
              <circle
                cx={-20}
                cy={0}
                r={20}
                style={{ fill: 'none', stroke: bgcolor, strokeWidth: '4px' }}
              />
            </g>
            <g transform={'matrix(1,0,0,1,64.9054,168.368)'}>
              <path
                d={
                  'M0,-7.872L0,-4.728L3.396,-0.372C3.586,-0.128 3.871,0 4.159,0C4.367,0 4.577,-0.068 4.753,-0.205C5.174,-0.533 5.249,-1.142 4.92,-1.562L0,-7.872Z'
                }
                style={{ fill: color }}
              />
            </g>
            <g transform={'matrix(1,0,0,1,56.9064,168.492)'}>
              <path
                d={
                  'M0,-7.995L-4.92,-1.686C-5.249,-1.266 -5.173,-0.657 -4.752,-0.329C-4.331,0 -3.724,-0.075 -3.395,-0.496L0,-4.85L0,-7.995Z'
                }
                style={{ fill: color }}
              />
            </g>
            <g transform={'matrix(0,-1,-1,0,60.904,142.695)'}>
              <path
                d={
                  'M-2.5,-2.5C-3.881,-2.5 -5,-1.381 -5,0C-5,1.381 -3.881,2.5 -2.5,2.5C-1.119,2.5 0,1.381 0,0C0,-1.381 -1.119,-2.5 -2.5,-2.5'
                }
                style={{ fill: color }}
              />
            </g>
            <g transform={'matrix(1,0,0,1,72.9152,152.695)'}>
              <path
                d={
                  'M0,-2L-4.4,-2L-8.009,-0.112L-8.009,0L0,0C0.547,0 0.989,-0.448 0.989,-1C0.989,-1.552 0.547,-2 0,-2'
                }
                style={{ fill: color }}
              />
            </g>
            <g transform={'matrix(1,0,0,1,56.9064,150.809)'}>
              <path
                d={
                  'M0,1.772L-3.608,-0.114L-8.014,-0.114C-8.56,-0.114 -9.002,0.334 -9.002,0.886C-9.002,1.439 -8.56,1.886 -8.014,1.886L0,1.886L0,1.772Z'
                }
                style={{ fill: color }}
              />
            </g>
            <g transform={'matrix(1,0,0,1,73.2634,171.018)'}>
              <path
                d={
                  'M0,-25.064C-0.257,-25.554 -0.859,-25.742 -1.35,-25.487L-9.428,-21.26C-9.951,-21.971 -11.063,-22.322 -12.359,-22.322C-13.654,-22.322 -14.765,-21.973 -15.29,-21.264L-23.37,-25.487C-23.858,-25.742 -24.463,-25.553 -24.719,-25.064C-24.975,-24.574 -24.786,-23.97 -24.296,-23.714L-15.357,-19.042L-15.357,-0.45C-15.357,0.157 -14.966,0.678 -14.359,0.678C-13.752,0.678 -13.359,0.285 -13.359,-0.322L-13.359,-11.322L-11.359,-11.322L-11.359,-0.322C-11.359,0.285 -10.966,0.678 -10.359,0.678C-9.752,0.678 -9.359,0.285 -9.359,-0.322L-9.357,-19.04L-0.422,-23.714C0.067,-23.971 0.256,-24.575 0,-25.064'
                }
                style={{ fill: color }}
              />
            </g>
          </g>
        </svg>
      )

    // security - category icon
    case 'security':
      return (
        <svg viewBox={'0 0 118 118'}>
          <g transform={'matrix(2.66667,0,0,2.66667,-103.744,-538.779)'}>
            <g id={'inner'} transform={'matrix(0,-1,-1,0,60.904,204.042)'}>
              <path
                d={
                  'M-20,-20C-31.045,-20 -40,-11.046 -40,0C-40,11.045 -31.045,20 -20,20C-8.954,20 0,11.045 0,0C0,-11.046 -8.954,-20 -20,-20'
                }
                style={{ fill: bgcolor }}
              />
            </g>
            <g transform={'matrix(0,-1,-1,0,60.904,204.042)'}>
              <circle
                cx={-20}
                cy={0}
                r={20}
                style={{ fill: 'none', stroke: bgcolor, strokeWidth: '4px' }}
              />
            </g>
            <g transform={'matrix(1,0,0,1,70.2223,230.558)'}>
              <path
                d={
                  'M0,-12.299C-0.047,-12.69 -0.304,-13.023 -0.668,-13.169L-9.067,-16.548C-9.326,-16.652 -9.614,-16.652 -9.873,-16.548L-18.271,-13.169C-18.637,-13.023 -18.892,-12.69 -18.941,-12.299C-18.955,-12.18 -19.283,-9.376 -18.354,-5.944C-17.114,-1.359 -14.21,2.163 -9.958,4.244C-9.808,4.317 -9.646,4.353 -9.483,4.353C-9.321,4.353 -9.16,4.317 -9.01,4.244C-4.747,2.165 -1.835,-1.359 -0.59,-5.942C0.342,-9.374 0.014,-12.18 0,-12.299M-2.674,-6.508C-3.697,-2.741 -5.912,0.098 -9.262,1.943L-9.275,-14.302L-2.102,-11.418C-2.067,-10.495 -2.095,-8.639 -2.674,-6.508'
                }
                style={{ fill: color }}
              />
            </g>
          </g>
        </svg>
      )

    case 'consumption':
    case 'performance':
      return (
        <svg viewBox={'0 0 118 118'}>
          <g transform={'matrix(2.66667,0,0,2.66667,-103.744,-721.035)'}>
            <g transform={'matrix(0,-1,-1,0,60.904,272.388)'}>
              <path
                d={
                  'M-20,-20C-31.046,-20 -40,-11.046 -40,0C-40,11.045 -31.046,20 -20,20C-8.955,20 0,11.045 0,0C0,-11.046 -8.955,-20 -20,-20'
                }
                style={{ fill: bgcolor }}
              />
            </g>
            <g transform={'matrix(0,-1,-1,0,60.904,272.388)'}>
              <circle
                cx={-20}
                cy={0}
                r={20}
                style={{ fill: 'none', stroke: bgcolor, strokeWidth: '4px' }}
              />
            </g>
            <g transform={'matrix(1,0,0,1,65.7555,295.15)'}>
              <path
                d={
                  'M0,-5.647L-1.682,-5.647L1.028,-14.32C1.186,-14.826 0.953,-15.371 0.478,-15.608C0.003,-15.843 -0.572,-15.698 -0.878,-15.264L-10.582,-1.576C-10.815,-1.248 -10.845,-0.818 -10.661,-0.46C-10.476,-0.102 -10.107,0.124 -9.704,0.124L-7.76,0.124L-10.47,8.798C-10.627,9.306 -10.391,9.854 -9.914,10.087C-9.762,10.162 -9.601,10.196 -9.442,10.196C-9.097,10.196 -8.761,10.031 -8.554,9.731L0.886,-3.959C1.115,-4.288 1.14,-4.717 0.954,-5.07C0.768,-5.425 0.401,-5.647 0,-5.647'
                }
                style={{ fill: color }}
              />
            </g>
          </g>
        </svg>
      )

    case 'installation':
      return (
        <svg viewBox={'0 0 44 44'}>
          <g id={'G_Ring'}>
            <path
              style={{ fill: bgcolor }}
              d={
                'M22,44C9.87,44,0,34.13,0,22S9.87,0,22,0c12.13,0,22,9.87,22,22S34.13,44,22,44z M22,4C12.07,4,4,12.07,4,22 c0,9.92,8.07,18,18,18s18-8.08,18-18C40,12.07,31.93,4,22,4z'
              }
            />
            <circle cx={22} cy={22} r={22} style={{ fill: bgcolor }} />
          </g>
          <g id={'Anlagenmonitoring'}>
            <g>
              <path
                style={{ fill: color }}
                d={
                  'M14.7,22.09c0.08,0.3,0.21,0.59,0.38,0.84l-0.53,0.77c0.25,0.32,0.54,0.6,0.88,0.83l0.79-0.54 c0.24,0.13,0.51,0.23,0.78,0.3l0.17,0.92c0.18,0.02,0.36,0.04,0.55,0.04c0.23,0,0.46-0.02,0.69-0.06l0.17-0.94 c0.25-0.07,0.49-0.18,0.71-0.31l0.78,0.54c0.34-0.25,0.63-0.54,0.88-0.88l-0.54-0.78c0.13-0.22,0.23-0.46,0.31-0.71l0.94-0.17 c0.04-0.22,0.06-0.45,0.06-0.69c0-0.19-0.01-0.37-0.04-0.55l-0.92-0.17c-0.07-0.28-0.17-0.54-0.3-0.78l0.54-0.79 c-0.23-0.33-0.51-0.63-0.83-0.88l-0.77,0.53c-0.26-0.17-0.54-0.29-0.84-0.38l-0.17-0.94c-0.22-0.04-0.44-0.06-0.66-0.06 c-0.18,0-0.35,0.01-0.52,0.03l-0.17,0.92c-0.33,0.07-0.64,0.2-0.92,0.37l-0.78-0.54c-0.31,0.23-0.59,0.51-0.83,0.83l0.54,0.78 c-0.17,0.28-0.29,0.59-0.37,0.92l-0.92,0.17c-0.02,0.17-0.03,0.34-0.03,0.52c0,0.23,0.02,0.45,0.06,0.66L14.7,22.09z M17.71,19.46 c0.99,0,1.79,0.8,1.79,1.79c0,0.99-0.8,1.79-1.79,1.79s-1.79-0.8-1.79-1.79C15.92,20.27,16.72,19.46,17.71,19.46z'
                }
              />
              <path
                style={{ fill: color }}
                d={
                  'M22.27,20.3c0.08,0.3,0.21,0.59,0.38,0.84l-0.53,0.77c0.25,0.32,0.54,0.6,0.88,0.83l0.79-0.54 c0.24,0.13,0.51,0.23,0.78,0.3l0.17,0.92c0.18,0.02,0.36,0.04,0.55,0.04c0.23,0,0.46-0.02,0.69-0.06l0.17-0.94 c0.25-0.07,0.49-0.18,0.71-0.31l0.78,0.54c0.34-0.25,0.63-0.54,0.88-0.88l-0.54-0.78c0.13-0.22,0.23-0.46,0.31-0.71l0.94-0.17 c0.04-0.22,0.06-0.45,0.06-0.69c0-0.19-0.01-0.37-0.04-0.55l-0.92-0.17c-0.07-0.28-0.17-0.54-0.3-0.78l0.54-0.79 c-0.23-0.33-0.51-0.63-0.83-0.88l-0.77,0.53c-0.26-0.17-0.54-0.29-0.84-0.38l-0.17-0.94c-0.22-0.04-0.44-0.06-0.66-0.06 c-0.18,0-0.35,0.01-0.52,0.03l-0.17,0.92c-0.33,0.07-0.64,0.2-0.92,0.37l-0.78-0.54c-0.31,0.23-0.59,0.51-0.83,0.83l0.54,0.78 c-0.17,0.28-0.29,0.59-0.37,0.92l-0.92,0.17c-0.02,0.17-0.03,0.34-0.03,0.52c0,0.23,0.02,0.45,0.06,0.66L22.27,20.3z M25.27,17.67 c0.99,0,1.79,0.8,1.79,1.79c0,0.99-0.8,1.79-1.79,1.79c-0.99,0-1.79-0.8-1.79-1.79C23.48,18.47,24.28,17.67,25.27,17.67z'
                }
              />
              <path
                style={{ fill: color }}
                d={
                  'M34,12.48H10c-0.55,0-1,0.45-1,1v15.56c0,0.55,0.45,1,1,1h9.56L19.13,33H18c-0.55,0-1,0.45-1,1s0.45,1,1,1h8 c0.55,0,1-0.45,1-1s-0.45-1-1-1h-1.13l-0.43-2.96H34c0.55,0,1-0.45,1-1V13.48C35,12.93,34.55,12.48,34,12.48z M33,26H11V14.48h22 V26z'
                }
              />
            </g>
          </g>
        </svg>
      )

    default:
      return (
        <svg
          viewBox={'0 0 107 107'}
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
          }}
        >
          <g transform={'matrix(2.66667,0,0,2.66667,-289.517,-726.369)'}>
            <g transform={'matrix(1,0,0,1,128.569,272.388)'}>
              <path
                d={
                  'M0,40C11.046,40 20,31.046 20,20C20,8.955 11.046,0 0,0C-11.045,0 -20,8.955 -20,20C-20,31.046 -11.045,40 0,40'
                }
                style={{ fill: 'rgb(196, 197, 197)' }}
              />
            </g>
            <g transform={'matrix(32.1456,0,0,32.1456,121.71,303.376)'}>
              <path
                d={
                  'M0.406,-0.542C0.406,-0.499 0.4,-0.466 0.389,-0.444C0.377,-0.421 0.353,-0.395 0.318,-0.366C0.282,-0.336 0.257,-0.312 0.244,-0.295C0.231,-0.277 0.224,-0.257 0.224,-0.236L0.224,-0.201L0.161,-0.201C0.152,-0.219 0.148,-0.239 0.148,-0.262C0.148,-0.285 0.156,-0.306 0.171,-0.326C0.186,-0.345 0.211,-0.37 0.245,-0.399C0.279,-0.428 0.302,-0.452 0.313,-0.469C0.324,-0.486 0.33,-0.51 0.33,-0.542C0.33,-0.572 0.319,-0.596 0.298,-0.611C0.277,-0.626 0.243,-0.634 0.197,-0.634C0.168,-0.634 0.125,-0.628 0.068,-0.616L0.042,-0.611L0.037,-0.672C0.104,-0.692 0.16,-0.702 0.204,-0.702C0.273,-0.702 0.323,-0.69 0.357,-0.665C0.39,-0.64 0.406,-0.599 0.406,-0.542ZM0.15,-0.001L0.15,-0.113L0.232,-0.113L0.232,-0.001L0.15,-0.001Z'
                }
                style={{ fill: 'white', fillRule: 'nonzero' }}
              />
            </g>
          </g>
        </svg>
      )
  }
}

SVGStatus.propTypes = {
  type: PropTypes.string,
  status: PropTypes.string,
}

export const getCorrelationStatusColorDetailPage = (sensors, folderId) => {
  const FILTER_CURRENT_FOLDER = f =>
    Number(f.folder_id) === Number(folderId) ||
    (f.attributes && Number(f.attributes.folder_id)) === Number(folderId)

  const FILTER_IS_TYPE_SECURITY = f => {
    const isSecurityTarget =
      f.sensor_target === 'security' ||
      (f.attributes && f.attributes.sensor_target === 'security')

    const isSecurityType =
      categoryByType(f.sensor_type) === 'security' ||
      (f.attributes && categoryByType(f.attributes.sensor_type) === 'security')

    return isSecurityTarget || isSecurityType
  }

  const FILTER_PERFORMANCE = f => {
    const sensorType = categoryByType(f.sensor_type)
    const attributesSensorType = f.attributes
      ? categoryByType(f.attributes.sensor_type)
      : undefined

    return (
      sensorType === 'consumption' ||
      sensorType === 'performance' ||
      attributesSensorType === 'consumption' ||
      attributesSensorType === 'performance'
    )
  }

  const FILTER_FEELING = f => {
    const isFeelingTarget =
      f.sensor_target === 'feeling' ||
      (f.attributes && f.attributes.sensor_target === 'feeling')

    const isFeelingType =
      categoryByType(f.sensor_type) === 'feeling' ||
      (f.attributes && categoryByType(f.attributes.sensor_type) === 'feeling')

    return isFeelingTarget || isFeelingType
  }

  const FILTER_CORRELATION = f =>
    ![
      'door',
      'smokeDetektor',
      'Removal_detection',
      'Battery_end_of_life',
      'Smoke_alarm',
      'Acoustic_alarm_failure',
      'waterDetected',
      'external_digital_button',
      'external_digital_button_2',
    ].includes(f.sensor_type)

  const P_SENSORS = sensors
    .filter(FILTER_CURRENT_FOLDER)
    .filter(FILTER_PERFORMANCE)

  const S_SENSORS = sensors
    .filter(FILTER_CURRENT_FOLDER)
    .filter(FILTER_IS_TYPE_SECURITY)

  const W_SENSORS = sensors.filter(FILTER_CURRENT_FOLDER).filter(FILTER_FEELING)

  const CAN_CORRELATE =
    [...W_SENSORS, ...S_SENSORS, ...P_SENSORS].filter(FILTER_CORRELATION)
      .length >= 2 // sensors correlate if atleast 2 sensors with certain sensor types are in an object

  return CAN_CORRELATE
}
