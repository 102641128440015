// modules
import {
  AccountBalanceTwoTone,
  ArrowBackIosTwoTone,
  ArrowForwardIosTwoTone,
  AssignmentTwoTone,
  InfoTwoTone,
  MoreVertTwoTone,
  StarBorderTwoTone,
  StarTwoTone,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { TimeSeries } from 'pondjs'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  ChartContainer,
  ChartRow,
  Charts,
  Legend,
  LineChart,
  Resizable,
  styler,
  YAxis,
} from 'react-timeseries-charts'
// scripts
import {
  addWeekdays,
  getCorrelationStatusColorDetailPage,
  categoryByType,
  colorSchemeConsumption,
  colorSchemeFeeling,
  daysAgo,
  daysOnward,
  DDMMYY,
  DDMMYYHHMM,
  germanDecimals,
  getDaysArray,
  getHoursArray,
  getMonthsArray,
  getSession,
  getStatusColor,
  installationStatus,
  MMMYY,
  normalizedSensorType,
  noDecimals,
  SensorIcon,
  sensorType,
  sensorUnit,
  setSession,
  userLocales,
  WDDDMMYY,
  WDDDMMYYYYHHMMSS,
  DDMMYYYY,
} from '../../api'
import { AM, AMItem, KPage, Preloader } from '../../components'
import {
  useConsumptionByFolderId,
  useFolder,
  useOperator,
  useSensorPool,
  useTimeSeries,
  useTimeSeriesDataListReload,
} from '../../hooks'

const isMainSensor = sensor => !sensor.parent_id

// if given date is in future, return current date
const limitTodayMax = date =>
  date > new Date(Date.now()).toISOString().split('T')[0]
    ? new Date(Date.now()).toISOString().split('T')[0]
    : date

export const InstallationDetail = () => {
  const theme = useTheme()
  const { white: bgcolor } = theme.palette
  const { main: color } = theme.palette.secondary

  const history = useHistory()
  const { id: folderId, installationid } = useParams()
  const PAGE_CONFIG = 'settings_correlation'
  const [config, setConfig] = useState(getSession(PAGE_CONFIG))
  const { data: sensorPool, isLoading: isSensorPoolLoading } = useSensorPool()

  const [consumptionSensors, setConsumptionSensors] = useState([])
  const [feelingSensors, setFeelingSensors] = useState([])
  const { data: operatorData } = useOperator()

  const [energyType, setEnergyType] = useState('energy')
  const [feelingType, setFeelingType] = useState('temperature')
  const [installation, setInstallation] = useState(installationid)
  const [installations, setInstallations] = useState([])
  const [activeInstallationStatus, setActiveInstallationStatus] = useState(
    'green'
  )
  const [showSensorInfo, setShowSensorInfo] = useState(false)

  // color status of categories
  const [colorStatus, setColorStatus] = useState([
    { type: 'performance', status: 'neutral' },
    { type: 'installation', status: 'neutral' },
    { type: 'correlation', status: 'neutral' },
    { type: 'security', status: 'neutral' },
    { type: 'feeling', status: 'neutral' },
  ])

  useEffect(
    () => {
      const ids = sensorPool.map(item => Number(item.id))

      setOperatorSensorIds(ids)
    },
    [sensorPool]
  )

  const [operatorSensorIds, setOperatorSensorIds] = useState([])

  // data
  const [activeSensors, setActiveSensors] = useState([])
  const [activeFeelingSensors, setActiveFeelingSensors] = useState([])

  const [feelingSensorId, setFeelingSensorId] = useState(null)

  const arrRemove = (arr, value) => arr.filter(f => f !== value)

  const period = 'day'

  const toDate =
    new Date(config.toDate).setHours(0, 0, 0, 0) ===
    new Date().setHours(0, 0, 0, 0)
      ? new Date()
      : `${config.toDate} 23:59:59`

  const dataTimeRange = {
    ui: {
      period: period,
      min_period: -10000,
      max_period: 10000,
    },
    widget: [
      {
        type: 'bid',
        data: [
          [Date.parse(`${config.fromDate} 00:00:00`), -10000],
          [Date.parse(toDate), 10000],
        ],
        display: 'absolute',
      },
    ],
  }
  const pointsTimeRange = dataTimeRange.widget[0].data
  const requestsSeries = new TimeSeries({
    name: 'Temperature',
    columns: ['time', 'temp'],
    points: pointsTimeRange,
  })
  const [dataEnergy, setDataEnergy] = useState([])
  const [dataFeeling, setDataFeeling] = useState([])
  const [lineCharts, setLineCharts] = useState([])
  const [showSecondAxis, setShowSecondAxis] = useState(false)
  const [style, setStyle] = useState([])
  const [activeTableSensor, setActiveTableSensor] = useState({})
  const [legend, setLegend] = useState([])
  const [maxConsumption, setMaxConsumption] = useState(0)
  const [minFeeling, setMinFeeling] = useState()
  const [maxFeeling, setMaxFeeling] = useState(0)
  const [allSeries, setAllSeries] = useState([])
  const [tracker, setTracker] = useState()
  const [trackerX, setTrackerX] = useState()
  const [mouseX, setMouseX] = useState()
  const [mouseY, setMouseY] = useState()

  const [timeSeriesDataList, setTimeSeriesDataList] = useState({ items: [] })
  const [timeSeriesDataListLoading, setTimeSeriesDataListLoading] = useState(
    false
  )
  const [timeRange, setTimeRange] = useState(requestsSeries.range())
  const [reloadTrigger, setReloadTrigger] = useState(false)

  const [periodWarning, setPeriodWarning] = useState(null)
  const [periodInDays, setPeriodInDays] = useState()

  // export
  const [exportModal, setExportModal] = useState(false)
  const [tableDataExportExcel, setTableDataExportExcel] = useState({
    Korrelation: [],
  })

  useEffect(
    () =>
      document.addEventListener('mousemove', event => {
        setMouseX(event.pageX)
        setMouseY(event.pageY)
      }),
    []
  )

  const [actionMenu, setActionMenu] = useState(null)

  const [range, setRange] = useState({
    fromDate: config.fromDate,
    toDate: config.toDate,
    granularity: config.granularity,
  })

  const {
    data: folderData,
    isError: folderError,
    isLoading: folderLoading,
  } = useFolder(folderId)

  useEffect(() => setSession(PAGE_CONFIG, config), [config])

  useEffect(
    () => {
      if (folderData) {
        const { installations, sensors, sensor_group_status: sgs } = folderData

        if (sensors && sensors[0]) {
          installations.forEach(installation => {
            const installationSensors = sensors
              .filter(f => operatorSensorIds.includes(Number(f.entity_id)))
              .filter(f => f.installation_id === installation.entity_id)

            installation['sensors'] = installationSensors
          })

          if (installations && installations[0]) setInstallations(installations)
        }

        if (sensors)
          setFeelingSensors(
            sensors.filter(
              f =>
                f.installation_id === Number(installation) &&
                (categoryByType(f.sensor_type) === 'feeling' ||
                  categoryByType(f.sensor_type) === 'security')
            )
          )

        const feelingSensor = sensors.find(f => f.sensor_type === feelingType)
        const status = installationStatus(
          sensors.filter(f => f.installation_id === Number(installation))
        )

        if (feelingSensor) {
          const { name, sensor_type } = feelingSensor

          setFeelingType(sensor_type)
          setActiveTableSensor(name)
          setActiveInstallationStatus(status)
        }

        if (sgs) {
          const performance = sgs.find(f => f.type === 'status_performance')
          const security = sgs.find(f => f.type === 'status_security')
          const feeling = sgs.find(f => f.type === 'status_well_being')
          const installations = sgs.find(f => f.type === 'status_installation')

          setColorStatus({
            performance: performance.status,
            security: security.status,
            feeling: feeling.status,
            correlation: getCorrelationStatusColorDetailPage(sensors, folderId)
              ? 'green'
              : 'neutral',
            installation: installations.status,
          })
        }
      }
    },
    [feelingType, folderData, folderId, installation, operatorSensorIds]
  )

  useEffect(
    () => {
      if (feelingSensors) {
        const feelingSensor = feelingSensors.find(
          f => normalizedSensorType(f.sensor_type) === feelingType
        )

        if (feelingSensor) {
          const { entity_id, name } = feelingSensor

          setFeelingSensorId(entity_id)
          setActiveFeelingSensors([entity_id])
          setActiveTableSensor(name)
        }
      }
    },
    [feelingSensors, feelingType]
  )

  const {
    data: consumptionData,
    isLoading: consumptionLoading,
  } = useConsumptionByFolderId(
    folderId,
    config.granularity,
    config.fromDate,
    config.toDate
  )

  const { data: timeSeriesData, isLoading: timeSeriesLoading } = useTimeSeries(
    feelingSensorId,
    config.fromDate + ' 00:00:00',
    config.toDate + ' 23:59:59',
    config.granularity
  )

  const {
    timeSeries: reloadedTimeSeriesData,
    isLoading: reloadedTimeSeriesLoading,
  } = useTimeSeriesDataListReload(
    reloadTrigger,
    timeSeriesDataList,
    config.fromDate + ' 00:00:00',
    config.toDate + ' 23:59:59'
  )

  useEffect(
    () => {
      if (reloadTrigger)
        if (!reloadedTimeSeriesLoading)
          if (reloadedTimeSeriesData) {
            setReloadTrigger(false)
            setTimeSeriesDataList(reloadedTimeSeriesData)
          }
    },
    [reloadedTimeSeriesData, reloadedTimeSeriesLoading, reloadTrigger]
  )

  useEffect(
    () => {
      if (!timeSeriesDataListLoading) {
        const style = []
        const legend = []
        const legendStyle = {}
        const labelStyle = {
          normal: {
            fontSize: 'normal',
            color: '#000',
            cursor: 'default',
            marginRight: 5,
          },
        }

        const valueStyle = {
          normal: { fontSize: 'normal', color: '#000', cursor: 'default' },
        }

        consumptionSensors.map((sensor, key) => {
          const { entity_id, name } = sensor
          const isActive = activeSensors.includes(entity_id)
          const colorIndex = key % colorSchemeConsumption.length
          const color = colorSchemeConsumption[colorIndex]

          if (isActive) {
            style.push({ key: entity_id, color })
            style.push({ key: name, color })
            legend.push({ key: name, label: name, disabled: !isActive })
            legendStyle[name] = {
              symbol: {
                normal: { stroke: color, fill: color, strokeWidth: 10 },
              },
              label: labelStyle,
              value: valueStyle,
            }
          }

          return false
        })

        feelingSensors.map((sensor, key) => {
          const { entity_id, name } = sensor
          const colorIndex = key % colorSchemeFeeling.length
          const color = colorSchemeFeeling[colorIndex]
          const isActive = activeFeelingSensors.includes(entity_id)

          if (isActive) {
            style.push({ key: entity_id, color })
            style.push({ key: name, color })
            legend.push({ key: name, label: name, disabled: false })
            legendStyle[name] = {
              symbol: {
                normal: { stroke: color, fill: color, strokeWidth: 10 },
              },
              label: labelStyle,
              value: valueStyle,
            }
          }

          return true
        })

        setStyle(styler(style))
        setLegend(legend)
      }
    },
    [
      activeFeelingSensors,
      activeSensors,
      consumptionSensors,
      feelingSensors,
      timeSeriesDataListLoading,
    ]
  )

  useEffect(
    () => {
      if (timeSeriesData && !timeSeriesLoading && !timeSeriesDataListLoading)
        if (!(feelingSensorId in timeSeriesDataList.items)) {
          const newItems = timeSeriesDataList.items

          newItems[feelingSensorId] = timeSeriesData
          setTimeSeriesDataList({ items: newItems })
        }
    },
    [
      feelingSensorId,
      timeSeriesData,
      timeSeriesDataList,
      timeSeriesDataListLoading,
      timeSeriesLoading,
    ]
  )

  useEffect(
    () => {
      const toDate =
        new Date(config.toDate).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0)
          ? new Date()
          : `${config.toDate} 23:59:59`

      const dataTimeRange = {
        ui: { period, min_period: -10000, max_period: 10000 },
        widget: [
          {
            type: 'bid',
            data: [
              [Date.parse(`${config.fromDate} 00:00:00`), -10000],
              [Date.parse(toDate), 10000],
            ],
            display: 'absolute',
          },
        ],
      }

      const pointsTimeRange = dataTimeRange.widget[0].data
      const requestsSeries = new TimeSeries({
        name: 'Temperature',
        columns: ['time', 'temp'],
        points: pointsTimeRange,
      })

      setTimeRange(requestsSeries.range())
    },
    [config.fromDate, config.toDate, period]
  )

  useEffect(
    () => {
      if (timeSeriesDataListLoading)
        if (!timeSeriesLoading) setTimeSeriesDataListLoading(false)
    },
    [timeSeriesDataListLoading, timeSeriesLoading]
  )

  useEffect(
    () => {
      const _charts = []
      const _seriesList = []
      let _showSecondAxis = false

      if (!timeSeriesDataListLoading) {
        Object.keys(dataEnergy).forEach(key => {
          const seriesConsumption = new TimeSeries({
            name: key,
            columns: ['time', key],
            points: dataEnergy[key].widget[0].data,
          })

          _seriesList.push(seriesConsumption)
          _charts.push(
            <LineChart
              key={'consumption'}
              axis={'consumption'}
              series={seriesConsumption}
              columns={[key]}
              style={style}
              interpolation={'curveBasis'}
            />
          )

          _showSecondAxis = true
        })

        Object.keys(dataFeeling).forEach(key => {
          const seriesFeeling = new TimeSeries({
            name: key,
            columns: ['time', key],
            points: dataFeeling[key].widget[0].data,
          })

          _seriesList.push(seriesFeeling)
          _charts.push(
            <LineChart
              key={'feeling'}
              axis={'feeling'}
              series={seriesFeeling}
              columns={[key]}
              style={style}
              interpolation={'curveBasis'}
            />
          )
        })

        setLineCharts(_charts)
        setAllSeries(_seriesList)
        setShowSecondAxis(_showSecondAxis)
      }
    },
    [
      dataEnergy,
      dataFeeling,
      style,
      timeSeriesData,
      timeSeriesDataList,
      timeSeriesDataListLoading,
    ]
  )

  const changeFeelingSensors = entity_id => {
    let _activeFeelingSensors = activeFeelingSensors
    const _timeSeriesDataList = timeSeriesDataList

    if (_activeFeelingSensors.includes(entity_id)) {
      _activeFeelingSensors = _activeFeelingSensors.filter(f => f !== entity_id)
      delete _timeSeriesDataList.items[entity_id]
    } else _activeFeelingSensors = [..._activeFeelingSensors, entity_id]

    const feelingSensor = folderData.sensors.find(
      f => f.entity_id === entity_id
    )

    setFeelingSensorId(entity_id)
    setActiveTableSensor(feelingSensor.name)
    setTimeSeriesDataListLoading(true)
    setTimeSeriesDataList(_timeSeriesDataList)
    setActiveFeelingSensors(_activeFeelingSensors)
  }

  const changeActiveSensors = (entity_id, sensorType) => {
    setActiveSensors(
      activeSensors.includes(entity_id)
        ? arrRemove(activeSensors, entity_id)
        : [...activeSensors, entity_id]
    )

    const consumptionSensor = folderData.sensors.find(
      f => f.entity_id === entity_id
    )

    setActiveTableSensor(consumptionSensor.name)
    setEnergyType(sensorType)
  }

  const axisStyle = {
    label: {
      strokeOpacity: 0.8,
      fontWeight: 400,
      'font-size': '16px',
    },
    values: {
      stroke: 'none',
      fill: '#000',
      'font-size': '14px',
    },
    ticks: {
      fill: 'none',
      stroke: '#000',
      opacity: 0.2,
    },
    axis: {
      fill: 'none',
      stroke: '#AAA',
      opacity: 1,
      fontWeight: 100,
      'font-size': '14px',
    },
  }

  const itemRows = []

  if (tracker) {
    allSeries.forEach((value, index) => {
      const id = value._data._root.entries[0][1]

      let name = id
      let unit

      const sensor = folderData.sensors.find(f => f.entity_id === Number(id))

      if (sensor) {
        name = sensor.name
        unit = sensor.unit ? sensor.unit : ''
      }

      if (allSeries[index].bisect(tracker)) {
        const markerIndex =
          tracker &&
          allSeries[index].at(allSeries[index].bisect(tracker)).get(id)

        const row = (
          <>
            <br />
            {name}:{' '}
            <strong>
              {markerIndex.toLocaleString(userLocales, germanDecimals)} {unit}
            </strong>{' '}
            <br />
          </>
        )

        itemRows.push(row)
      }
    })
  }

  useEffect(
    () => {
      const { fromDate, granularity, toDate } = config

      const _1H = 60 * 60 * 1000
      const _24H = 24 * _1H

      const _periodInDays =
        (new Date(toDate).getTime() - new Date(fromDate).getTime()) / _24H

      setPeriodInDays(_periodInDays)

      switch (granularity) {
        case 'd':
          // timespan max a week
          setPeriodWarning(
            _periodInDays > 6
              ? 'Diese Granularität ist bei einer Auswahl von bis zu 7 Tagen verfügbar.'
              : null
          )
          break
        case 'm':
          // timespan max. half a year
          setPeriodWarning(
            _periodInDays > 182.625
              ? 'Diese Granularität ist bei einer Auswahl von bis zu 6 Monaten verfügbar.'
              : null
          )
          break
        case 'y':
          // no timespan limit
          setPeriodWarning(null)
          break
        default:
          break
      }
    },
    [config, config.fromDate, config.toDate, config.granularity]
  )

  useEffect(
    () => {
      if (consumptionData) {
        const _consumptionSensors = consumptionData
        const mainSensor = consumptionData
          .filter(f => operatorSensorIds.includes(f.entity_id))
          .filter(f => f.type_in_folder === energyType)
          .find(f => isMainSensor(f))

        if (mainSensor) {
          const _data = []
          const _columnsChart = []
          let _maxConsumption = 0
          _consumptionSensors.forEach(sensor => {
            const { consumption, entity_id } = sensor

            if (activeSensors.includes(entity_id)) {
              consumption.values.forEach((item, key) => {
                const { timepoint, value } = item
                const val = Number(value.toFixed(2))

                if (_data[key]) _data[key] = _data[key].concat([val])
                else _data[key] = [timepoint, val]
              })

              if (!_columnsChart[entity_id]) {
                _columnsChart[entity_id] = {
                  ui: { min_period: 0, period, max_period: 0 },
                  widget: [
                    { period, type: 'bid', data: [], display: 'absolute' },
                  ],
                }
                let _minPeriod = 0
                let _maxPeriod = 0

                const { ui, widget } = _columnsChart[entity_id]

                consumption.values.forEach(item => {
                  const { timepoint, value } = item
                  const time = new Date(timepoint).getTime()
                  const val = Number(value.toFixed(2))

                  if (val > _maxPeriod) _maxPeriod = val
                  else if (val < _minPeriod) _minPeriod = val

                  widget[0].data.push([time, val])
                })

                ui.min_period = _minPeriod
                ui.max_period = _maxPeriod

                if (_maxPeriod > _maxConsumption) _maxConsumption = _maxPeriod

                if (widget[0].data.length === 0)
                  widget[0].data.push([new Date().getTime(), 0])
              }
            }
          })

          setMaxConsumption(_maxConsumption)
          setConsumptionSensors(_consumptionSensors)
          setDataEnergy(_columnsChart)
        }
      }
    },
    [
      activeSensors,
      config.granularity,
      consumptionData,
      energyType,
      operatorSensorIds,
      period,
      range.granularity,
    ]
  )

  useEffect(
    () => {
      if (!timeSeriesDataListLoading) {
        const _columnsChart = []
        let _maxFeeling = 0
        let _minFeeling = 0
        if (activeFeelingSensors.length > 0) _minFeeling = 1000000
        let _averageFeeling = 0

        Object.keys(timeSeriesDataList.items).forEach(key => {
          if (activeFeelingSensors.includes(Number(key)))
            if (!_columnsChart[key]) {
              _columnsChart[key] = {
                ui: { min_period: 0, period, max_period: 0 },
                widget: [
                  { period, type: 'bid', data: [], display: 'absolute' },
                ],
              }

              let _minPeriod = 10000
              let _maxPeriod = -10000

              const { ui, widget } = _columnsChart[key]

              const orderedResult = timeSeriesDataList.items[key].sort(
                (a, b) => a.timestamp - b.timestamp
              )

              orderedResult.forEach(item => {
                const { timestamp, value } = item
                const val = Number(value.toFixed(2))

                if (val > _maxPeriod) _maxPeriod = val
                else if (val < _minPeriod) _minPeriod = val

                _averageFeeling = _averageFeeling + val
                widget[0].data.push([timestamp * 1000, val])
              })

              ui.min_period = _minPeriod
              ui.max_period = _maxPeriod

              if (_maxPeriod > _maxFeeling) _maxFeeling = _maxPeriod
              if (_minPeriod < _minFeeling) _minFeeling = _minPeriod
            }
        })

        if (_minFeeling >= 0) _minFeeling = 0

        setMinFeeling(_minFeeling)
        setMaxFeeling(_maxFeeling)
        setDataFeeling(_columnsChart)
      }
    },
    [
      activeFeelingSensors,
      config.granularity,
      period,
      timeSeriesData,
      timeSeriesDataList.items,
      timeSeriesDataListLoading,
    ]
  )

  useEffect(
    () => {
      const _result = []
      let _format
      let _daylist = []
      const _sumArray = [['Summe']]
      const _excelArray = []

      switch (config.granularity) {
        case 'd':
          _format = DDMMYYHHMM
          _daylist = getHoursArray(
            new Date(config.fromDate),
            new Date(config.toDate)
          )
          break
        case 'm':
          _format = DDMMYY
          _daylist = getDaysArray(
            new Date(config.fromDate),
            new Date(config.toDate)
          )
          break
        case 'y':
          _format = MMMYY
          _daylist = getMonthsArray(config.fromDate, config.toDate)
          break
        default:
          break
      }

      _daylist = _daylist.map(day => [day.toLocaleString(userLocales, _format)])
      _result['Korrelation'] = []

      consumptionSensors.forEach(sensor => {
        const { consumption, entity_id, name, unit } = sensor

        if (activeSensors.includes(entity_id)) {
          let sum = 0
          _excelArray[name] = []
          _daylist.forEach(day => {
            const value = consumption.values.find(
              f =>
                new Date(f.timepoint).toLocaleString(userLocales, _format) ===
                day[0]
            )

            if (value) {
              day.push(`${value.value} ${unit ? unit : ''}`)
              sum += value.value
              _excelArray[name].push([
                day[0],
                `${value.value.toLocaleString(userLocales, germanDecimals)} ${
                  unit ? unit : ''
                }`,
              ])
            } else {
              day.push(0)
              _excelArray[name].push([day[0], `0 ${unit ? unit : ''}`])
              sum += 0
            }
          })

          const record = `${sum.toLocaleString(userLocales, germanDecimals)} ${
            unit ? unit : ''
          }`

          _sumArray[0].push(record)
          _excelArray[name].push(['Summe', record])
        }
      })

      Object.keys(timeSeriesDataList.items).map(sensor => {
        if (activeFeelingSensors.includes(Number(sensor))) {
          let sensorName = ''

          if (folderData.sensors.length !== 0)
            sensorName = folderData.sensors.find(
              f => f.entity_id === Number(sensor)
            ).name

          _excelArray[sensorName] = []
          _daylist.forEach(day => {
            let dayDate

            if (config.granularity === 'd') {
              dayDate = day[0].split('.')
              dayDate = new Date(
                `20${dayDate[2].substring(2, 4)}`,
                dayDate[1] - 1,
                dayDate[0],
                dayDate[2].substring(6, 7)
              )
            }

            if (config.granularity === 'm') {
              dayDate = day[0].substring(0, 10).split('.')
              dayDate = new Date(`20${dayDate[2]}`, dayDate[1] - 1, dayDate[0])
            }

            if (config.granularity === 'y') {
              dayDate = day[0].substring(0, 10).split('.')
              dayDate = new Date(`20${dayDate[2]}`, dayDate[1] - 1, dayDate[0])
            }

            const timestamp = dayDate.getTime() / 1000
            const closest =
              timeSeriesDataList.items[sensor].length !== 0
                ? timeSeriesDataList.items[sensor].reduce(
                    (prev, curr) =>
                      Math.abs(curr.timestamp - timestamp) <
                      Math.abs(prev.timestamp - timestamp)
                        ? curr
                        : prev
                  )
                : { value: 0, timestamp: timestamp }

            const record = `${closest.value.toLocaleString(
              userLocales,
              germanDecimals
            )} ${sensorUnit(feelingType)}`

            day.push(record)
            _excelArray[sensorName].push([day[0], record])
          })

          _sumArray[0].push('-')
          _excelArray[sensorName].push(['Summe', '-'])
        }

        return true
      })

      // add weekday to dates
      _daylist = addWeekdays(_daylist, config.granularity)
      Object.keys(_excelArray).map(data => {
        _excelArray[data] = addWeekdays(_excelArray[data], config.granularity)
        return true
      })

      _daylist = _daylist.concat(_sumArray)
      _result['Korrelation'] = _daylist
      setTableDataExportExcel(_excelArray)
    },
    [
      activeSensors,
      activeFeelingSensors,
      config.fromDate,
      config.granularity,
      config.toDate,
      consumptionSensors,
      energyType,
      feelingType,
      folderData,
      timeSeriesDataList,
    ]
  )

  const handleTrackerChanged = (t, scale) => {
    setTracker(t)
    setTrackerX(t && scale(t))
  }

  const contractBoundaries = date => {
    const date_begin = folderData?.contract?.date_begin
    const date_expire = folderData?.contract?.date_expire

    if (date < date_begin) return date_begin
    if (date > date_expire) return date_expire
    return date
  }

  const sanitize = date => limitTodayMax(contractBoundaries(date))
  const getSensorById = id =>
    folderData.sensors.find(f => Number(f.entity_id) === Number(id))

  const getTableHeads = () => {
    const _tableHeads = []
    _tableHeads.push(<TableCell>Datum</TableCell>)

    activeSensors.forEach(id => {
      const sensor = getSensorById(id)

      if (sensor.name === activeTableSensor)
        _tableHeads.push(<TableCell align={'right'}>{sensor.name}</TableCell>)
    })

    activeFeelingSensors.forEach(id => {
      const sensor = getSensorById(id)

      if (sensor.name === activeTableSensor)
        _tableHeads.push(<TableCell align={'right'}>{sensor.name}</TableCell>)
    })

    return <>{_tableHeads}</>
  }

  const getTableRows = row => {
    const _tableRows = []

    _tableRows.push(
      <TableCell component={'th'} scope={'row'}>
        {row[0]}
      </TableCell>
    )

    for (let i = 1; i < row.length; i++)
      _tableRows.push(<TableCell align={'right'}>{row[i]}</TableCell>)

    return <>{_tableRows}</>
  }

  const granularityCaption = granularity => {
    switch (granularity) {
      case 'd':
        return 'Stündlich'
      case 'm':
        return 'Täglich'
      case 'y':
        return 'Monatlich'
      default:
        console.error('unhandled granularityCaption', granularityCaption)
    }
  }

  const isPageLoading =
    folderLoading || consumptionLoading || reloadTrigger || isSensorPoolLoading
  const isPageError = folderError

  return (
    <>
      {/* preloader */}
      <Preloader error={isPageError} isLoading={isPageLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={installation.name}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'installation'}
        historyUrlId={folderId}
      >
        <AMItem
          caption={'Details zum Gebäude'}
          icon={<AccountBalanceTwoTone />}
          onClick={() => history.push(`/folder/${folderId}`)}
        />

        <Divider />

        <AMItem
          caption={'Leistung'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('performance', colorStatus.performance)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/performance`)}
        />

        <AMItem
          caption={'Anlagen'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('installation', colorStatus.installation)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/installation`)}
        />

        <AMItem
          caption={'Korrelation'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('correlation', colorStatus.correlation)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/correlation`)}
        />

        <AMItem
          caption={'Sicherheit'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('security', colorStatus.security)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/security`)}
        />

        <AMItem
          caption={'Wohlbefinden'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('feeling', colorStatus.feeling)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/feeling`)}
        />
      </AM>
      {/* action menu end */}

      {/* export */}
      <Dialog open={exportModal} onClose={() => setExportModal(false)}>
        <DialogTitle onClose={() => setExportModal(false)}>
          Daten exportieren
        </DialogTitle>
      </Dialog>
      {/* export end */}

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={event => setActionMenu(event.currentTarget)}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('installation', activeInstallationStatus)}
              sx={{ width: 40 }}
            />
          }
          isLoading={isPageLoading}
          title={
            folderData?.name &&
            folderData?.name +
              (folderData?.name_short ? ` (${folderData?.name_short})` : '')
          }
        >
          {consumptionData.isLoading || folderData?.isLoading ? (
            ''
          ) : (
            <Card variant={'installation'}>
              <CardHeader
                sx={{ py: 0 }}
                title={
                  <Stack
                    direction={'row'}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Stack alignItems={'center'} direction={'row'}>
                      <TextField
                        label={'Anlagen'}
                        onChange={event => {
                          setInstallation(event.target.value)
                          setActiveSensors([])
                          setActiveFeelingSensors([])
                          setReloadTrigger(true)
                          setDataFeeling([])
                          setDataEnergy([])
                          setAllSeries([])
                          setMinFeeling(0)
                          setMaxFeeling(0)
                          setLineCharts([])
                          setLegend([])
                        }}
                        select
                        type={'text'}
                        value={installation}
                        variant={'standard'}
                      >
                        {installations.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item.entity_id}>
                              <Stack direction={'row'}>
                                <ListItemIcon>
                                  <Box
                                    alt={''}
                                    component={'img'}
                                    src={SensorIcon(
                                      'installation',
                                      getStatusColor(item.sensors)
                                    )}
                                    sx={{ width: 25 }}
                                  />
                                </ListItemIcon>
                                <ListItemText>{item.name}</ListItemText>
                              </Stack>
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    </Stack>

                    <Stack alignItems={'center'} direction={'row'}>
                      <IconButton
                        disabled={
                          periodWarning ||
                          folderData?.contract?.date_begin >= range.fromDate
                        }
                        onClick={() => {
                          const newConfig = {
                            ...config,
                            fromDate: daysAgo(
                              config.fromDate,
                              periodInDays + 1
                            ),
                            toDate: daysAgo(config.toDate, periodInDays + 1),
                          }

                          setConfig(newConfig)
                          setRange({
                            ...range,
                            fromDate: newConfig.fromDate,
                            toDate: newConfig.toDate,
                          })
                          setConfig(newConfig)
                          setRange({
                            ...range,
                            fromDate: newConfig.fromDate,
                            toDate: newConfig.toDate,
                          })
                          setReloadTrigger(true)
                        }}
                      >
                        <ArrowBackIosTwoTone />
                      </IconButton>
                      <IconButton
                        disabled={
                          periodWarning ||
                          folderData?.contract?.date_expire <= range.toDate ||
                          new Date(Date.now()).toISOString().split('T')[0] <=
                            config.toDate
                        }
                        onClick={() => {
                          const newConfig = {
                            ...config,
                            fromDate: daysOnward(
                              config.fromDate,
                              periodInDays + 1
                            ),
                            toDate: daysOnward(config.toDate, periodInDays + 1),
                          }
                          setConfig(newConfig)
                          setRange({
                            ...range,
                            fromDate: newConfig.fromDate,
                            toDate: newConfig.toDate,
                          })
                          setConfig(newConfig)
                          setRange({
                            ...range,
                            fromDate: newConfig.fromDate,
                            toDate: newConfig.toDate,
                          })
                          setReloadTrigger(true)
                        }}
                      >
                        <ArrowForwardIosTwoTone />
                      </IconButton>

                      <TextField
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          onChange: event => {
                            const newRange = {
                              ...range,
                              fromDate: sanitize(event.target.value),
                              ...(sanitize(event.target.value) >
                                range.toDate && {
                                toDate: sanitize(event.target.value),
                              }),
                            }
                            setRange(newRange)
                          },
                          min: folderData?.contract?.date_begin,
                          max: folderData?.contract?.date_expire,
                        }}
                        label={'von'}
                        type={'date'}
                        value={range.fromDate}
                        variant={'standard'}
                      />

                      <TextField
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          onChange: event => {
                            const newRange = {
                              ...range,
                              ...(sanitize(event.target.value) <
                                range.fromDate && {
                                fromDate: sanitize(event.target.value),
                              }),
                              toDate: sanitize(event.target.value),
                            }
                            setRange(newRange)
                          },
                          min: folderData?.contract?.date_begin,
                          max: folderData?.contract?.date_expire,
                        }}
                        label={'bis'}
                        type={'date'}
                        value={range.toDate}
                        variant={'standard'}
                      />

                      <Button
                        color={'primary'}
                        disabled={
                          config.fromDate === range.fromDate &&
                          config.toDate === range.toDate &&
                          config.granularity === range.granularity
                        }
                        onClick={() => {
                          setReloadTrigger(true)
                          setConfig({
                            ...config,
                            fromDate: range.fromDate,
                            toDate: range.toDate,
                            granularity: range.granularity,
                          })
                        }}
                      >
                        Anwenden
                      </Button>
                    </Stack>
                  </Stack>
                }
                variant={'installation'}
              />
              <CardContent sx={{ p: '0px !important' }}>
                <Grid container sx={{ my: 0 }}>
                  <Grid item xs={12} md={9} sx={{ bgcolor }}>
                    <Box sx={{ position: 'relative', top: 16, right: 20 }}>
                      <Legend
                        type={'line'}
                        style={style}
                        categories={legend}
                        align={'right'}
                      />
                    </Box>

                    {trackerX &&
                    trackerX > 80 &&
                    ((showSecondAxis && trackerX < 1350) ||
                      (!showSecondAxis && trackerX < 1403)) &&
                    allSeries.length > 0 ? (
                      <Box
                        sx={{
                          bgcolor,
                          border: 'solid 1px #C8C8C8',
                          color: 'black',
                          left: mouseX - 260,
                          padding: '20px 10px',
                          pointerEvents: 'None',
                          position: 'absolute',
                          top: mouseY,
                          zIndex: 10000,
                        }}
                      >
                        <strong>
                          {new Date(tracker).toLocaleDateString(
                            userLocales,
                            WDDDMMYYYYHHMMSS
                          )}
                        </strong>
                        <br />
                        {itemRows}
                      </Box>
                    ) : null}

                    <Resizable>
                      <ChartContainer
                        enableDragZoom
                        format={f => f.toLocaleString(userLocales, WDDDMMYY)}
                        maxTime={requestsSeries.range().end()}
                        minTime={requestsSeries.range().begin()}
                        onTimeRangeChanged={range => setTimeRange(range)}
                        onTrackerChanged={handleTrackerChanged}
                        padding={20}
                        paddingTop={5}
                        paddingBottom={0}
                        timeAxisStyle={axisStyle}
                        timeAxisTickCount={5}
                        timeRange={timeRange}
                        trackerPosition={tracker}
                        trackerStyle={{
                          line: {
                            cursor: 'crosshair',
                            pointerEvents: 'none',
                            stroke: '#a62011',
                          },
                        }}
                      >
                        <ChartRow height={'500'} width={'100'}>
                          <YAxis
                            format={f =>
                              f.toLocaleString(
                                userLocales,
                                maxFeeling <= 10 ? germanDecimals : noDecimals
                              )
                            }
                            hideAxisLine
                            id={'feeling'}
                            label={`${sensorType(feelingType)} in ${sensorUnit(
                              feelingType
                            )}`}
                            max={maxFeeling}
                            min={minFeeling}
                            style={axisStyle}
                            type={'linear'}
                            width={'80'}
                          />
                          <Charts>{lineCharts}</Charts>
                          <YAxis
                            format={f =>
                              f.toLocaleString(
                                userLocales,
                                maxConsumption <= 10
                                  ? germanDecimals
                                  : noDecimals
                              )
                            }
                            hideAxisLine
                            id={'consumption'}
                            label={`${sensorType(energyType)} in ${sensorUnit(
                              energyType
                            )}`}
                            labelOffset={20}
                            max={maxConsumption}
                            min={0}
                            style={axisStyle}
                            type={'linear'}
                            visible={showSecondAxis}
                            width={'100'}
                          />
                        </ChartRow>
                      </ChartContainer>
                    </Resizable>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} sx={{ bgcolor }}>
                    <Stack
                      direction={'row'}
                      sx={{
                        bgcolor,
                        borderBottom: 'solid 1px #DDD',
                        borderLeft: 'solid 1px #DDD',
                      }}
                    >
                      <Typography
                        sx={{
                          height: 42,
                          lineHeight: 2.5,
                          px: 2,
                          py: 1,
                          width: '75%',
                        }}
                      >
                        Messpunkte
                      </Typography>
                      <Tooltip
                        title={
                          showSensorInfo === true
                            ? 'Informationen zum Messpunkt ausblenden'
                            : 'Informationen zum Messpunkt einblenden'
                        }
                      >
                        <IconButton
                          onClick={() => setShowSensorInfo(!showSensorInfo)}
                        >
                          {showSensorInfo ? (
                            <InfoTwoTone sx={{ color }} />
                          ) : (
                            <InfoTwoTone />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <List
                      height={420}
                      sx={{
                        borderLeft: 'solid 1px #D3D3D3',
                        minHeight: 1,
                        maxHeight: 420,
                        overflow: 'auto',
                      }}
                      width={300}
                    >
                      {folderData?.sensors
                        .filter(
                          f =>
                            categoryByType(f.sensor_type) === 'performance' &&
                            f.installation_id === Number(installation)
                        )
                        .map((item, key) => {
                          const {
                            name,
                            entity_id,
                            installation_id,
                            type_in_folder,
                            data_sampling_date,
                          } = item
                          const date = new Date(
                            data_sampling_date
                          ).toLocaleDateString(userLocales, DDMMYYYY)

                          const sensorInfo = (
                            <span>
                              {operatorData?.isAdmin && (
                                <>
                                  <span>Sensor ID: {entity_id}</span>
                                  <br />
                                </>
                              )}
                              <>
                                <span>Datenbeginn: {date}</span>
                              </>
                            </span>
                          )

                          if (installation_id === Number(installation))
                            return (
                              <ListItem
                                button
                                key={key}
                                onClick={() =>
                                  changeActiveSensors(entity_id, type_in_folder)
                                }
                                selected={activeSensors.includes(entity_id)}
                                disabled={timeSeriesLoading}
                              >
                                <ListItemIcon
                                  sx={{ color: colorSchemeConsumption[key] }}
                                >
                                  {isMainSensor(item) ? (
                                    <StarTwoTone />
                                  ) : (
                                    <StarBorderTwoTone />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={name}
                                  secondary={showSensorInfo ? sensorInfo : null}
                                />
                              </ListItem>
                            )
                          return true
                        })}
                      {folderData?.sensors
                        .filter(
                          f =>
                            f.installation_id === Number(installation) &&
                            (categoryByType(f.sensor_type) === 'feeling' ||
                              categoryByType(f.sensor_type) === 'security')
                        )
                        .map((item, key) => {
                          const {
                            name,
                            entity_id,
                            installation_id,
                            sensor_type,
                            data_sampling_date,
                          } = item
                          const date = new Date(
                            data_sampling_date
                          ).toLocaleDateString(userLocales, DDMMYYYY)
                          const sensorInfo = (
                            <span>
                              {operatorData?.isAdmin && (
                                <>
                                  <span>Sensor ID: {entity_id}</span>
                                  <br />
                                </>
                              )}
                              <>
                                <span>Datenbeginn: {date}</span>
                              </>
                            </span>
                          )
                          if (installation_id === Number(installation))
                            return (
                              <ListItem
                                button
                                key={key}
                                onClick={() =>
                                  changeFeelingSensors(entity_id, sensor_type)
                                }
                                selected={activeFeelingSensors.includes(
                                  entity_id
                                )}
                                disabled={timeSeriesLoading}
                              >
                                <ListItemIcon
                                  sx={{ color: colorSchemeFeeling[key] }}
                                >
                                  {isMainSensor(item) ? (
                                    <StarTwoTone />
                                  ) : (
                                    <StarBorderTwoTone />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={name}
                                  secondary={showSensorInfo ? sensorInfo : null}
                                />
                              </ListItem>
                            )
                          return true
                        })}
                    </List>
                    Kennzahlen
                    <List
                      height={420}
                      sx={{ maxHeight: 420, overflow: 'auto' }}
                      width={300}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}

          {/* table data   */}
          <Collapse in={true}>
            <Grid container>
              <Grid item xs={12} sm={9} md={9}>
                {Object.keys(tableDataExportExcel).map((item, key) => {
                  return item === activeTableSensor ? (
                    <TableContainer key={key} component={Paper} sx={{ mt: 2 }}>
                      <Table sx={{ width: '100%' }}>
                        <TableHead>
                          <TableRow>{getTableHeads()}</TableRow>
                        </TableHead>
                        <TableBody>
                          {tableDataExportExcel[item].map(row => (
                            <TableRow key={row[0]}>
                              {getTableRows(row)}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    ''
                  )
                })}
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <List sx={{ overflow: 'auto' }} width={300}>
                  {Object.keys(tableDataExportExcel).map((item, key) => {
                    return (
                      <ListItem
                        button
                        key={key}
                        onClick={() => setActiveTableSensor(item)}
                        selected={activeTableSensor === item}
                      >
                        <ListItemIcon>
                          <AssignmentTwoTone />
                        </ListItemIcon>
                        <ListItemText
                          primary={item}
                          secondary={granularityCaption(config.granularity)}
                        />
                      </ListItem>
                    )
                  })}
                </List>
              </Grid>
            </Grid>
          </Collapse>
          {/* table data end */}
        </KPage>
      )}
    </>
  )
}
