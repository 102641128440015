import { removeStorage, setStorage } from '../api'
import { userConstants as UC } from '../constants'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user ? { loggedIn: true, user } : {}

export const authentication = (state = initialState, action) => {
  const { type, user } = action

  let simpleUser = {}

  switch (type) {
    case UC.USER_LOGIN_ERROR:
      return { loggingIn: true }

    case UC.USER_LOGIN_REQUEST:
      return { loggingIn: true, user }

    case UC.USER_LOGIN_SUCCESS:
      if (user.signInUserSession.idToken.jwtToken) {
        simpleUser = {
          id: user.signInUserSession.idToken.payload.sub,
          email: user.signInUserSession.idToken.payload.email,
          firstName: user.signInUserSession.idToken.payload.name,
          lastName: user.signInUserSession.idToken.payload.family_name,
          token: user.signInUserSession.idToken.jwtToken,
        }
        setStorage('user', simpleUser)
      }
      return { loggedIn: false, user: simpleUser }

    case UC.USER_LOGOUT:
      removeStorage('user')
      return { loggingIn: true }

    default:
      return state
  }
}
