import { Alert, CssBaseline, Snackbar } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import { alertActions } from './actions'
import {
  createStorageConfig,
  getSession,
  getStorage,
  history,
  initialChartConfig,
  setSession,
  setStorage,
} from './api'
import { Layout, Page } from './layouts'
import { theme } from './pages/karma.theme'

export const App = () => {
  // redux selections
  const { message, type } = useSelector(state => state.alert)

  const [notification, setNotification] = useState(true)

  createStorageConfig()

  // sensor edit
  if (!getStorage('settings_sensor_edit'))
    setStorage('settings_sensor_edit', {
      tab: 0,
    })

  // reports
  if (!getStorage('settings_report_generator'))
    setStorage('settings_report_generator', {
      tab: 0,
    })

  if (!getStorage('settings_virtual_sensors_all'))
    setStorage('settings_virtual_sensors_all', {
      maxResults: 20,
    })

  // performance
  if (!getStorage('settings_performance'))
    setStorage('settings_performance', {
      chartType: 'ColumnChart',
      view: 'daily',
    })

  // performance detail
  if (!getSession('settings_performance_detail'))
    setSession('settings_performance_detail', initialChartConfig)

  // correlation
  if (!getSession('settings_correlation'))
    setSession('settings_correlation', initialChartConfig)

  // dashboards
  if (!getStorage('settings_dashboards'))
    setStorage('settings_dashboards', { tab: 0 })

  setSession('items_per_page', 50)

  const dispatch = useDispatch()

  useEffect(
    () => {
      if (message) {
        setNotification(true)
        const timeout = setTimeout(() => setNotification(false), 5000)

        return () => {
          dispatch(alertActions.clear())
          clearTimeout(timeout)
        }
      }
    },
    [dispatch, message]
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {message && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={notification}
        >
          <Alert
            severity={type}
            sx={{
              boxShadow: `0 0 20px -10px rgba(0, 0, 0, .5) !important`,
              p: '10px 30px 10px 30px',
              width: '100%',
            }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <Router history={history}>
        <Switch>
          <Route
            component={Page}
            key={'pages'}
            name={'Seiten'}
            path={`/pages`}
          />
          <Route
            component={Layout}
            key={'dashboard'}
            name={'Home'}
            path={`/`}
          />
        </Switch>
      </Router>
    </ThemeProvider>
  )
}
