import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const CellBoilerplate = ({ value }) => (
  <Typography sx={{ width: 100 }}>{value}</Typography>
)

CellBoilerplate.propTypes = {
  value: PropTypes.any,
}
