import { MenuItem, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const KPISelector = ({
  label = 'Einheit',
  variant = 'standard',
  ...rest
}) => {
  const data = [
    { label: 'Verbrauch', value: 'consumption' },
    { label: 'CO₂', value: 'co2' },
    { label: 'Kosten', value: 'price' },
  ]

  return (
    <TextField label={label} variant={variant} select {...rest}>
      {data.map((item, key) => {
        const { label, value } = item

        return (
          <MenuItem key={key} value={value}>
            {label}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

KPISelector.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
}
