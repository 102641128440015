import { sensorPriceConstants as SPC } from '../constants'

const initialState = { allSensorPrice: { loading: true } }

export const sensorPrice = (state = initialState, action) => {
  const { error, sensorprice, type } = action

  switch (type) {
    case SPC.DELETE_SENSOR_PRICE_REQUEST:
      return { ...state, deleteSensorPrice: { loading: true } }

    case SPC.DELETE_SENSOR_PRICE_SUCCESS:
      return {
        ...state,
        deleteSensorPrice: { item: sensorprice, loading: false },
      }

    case SPC.DELETE_SENSOR_PRICE_ERROR:
      return {
        ...state,
        deleteSensorPrice: { error, loading: true },
      }

    case SPC.GET_ALL_REQUEST:
      return { ...state, allSensorPrice: { loading: true } }

    case SPC.GET_ALL_SUCCESS:
      return {
        ...state,
        allSensorPrice: { item: sensorprice, loading: false },
      }

    case SPC.GET_ALL_ERROR:
      return {
        ...state,
        allSensorPrice: { error, loading: true },
      }

    case SPC.SAVE_SENSOR_PRICE_REQUEST:
      return { ...state, save: { loading: true } }

    case SPC.SAVE_SENSOR_PRICE_SUCCESS:
      return {
        ...state,
        save: { item: sensorprice, loading: false },
      }

    case SPC.SAVE_SENSOR_PRICE_ERROR:
      return {
        ...state,
        save: { error, loading: true },
      }

    default:
      return state
  }
}
