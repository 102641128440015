import { AccountBalanceTwoTone } from '@mui/icons-material'
import {
  Avatar,
  CircularProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { categoryByType } from '../../../api'
import { useFolderPool, useSensorPool } from '../../../hooks'

export const FolderSelector = ({ folderId, onChange: onChangeFolder }) => {
  const theme = useTheme()
  const bgcolor = theme.palette.primary.main

  const { data: folderPool, isLoading: isFLoading } = useFolderPool()
  const { data: sensorPool, isLoading: isSLoading } = useSensorPool()

  const onChange = event => onChangeFolder(event.target.value)

  return isFLoading || isSLoading ? (
    <CircularProgress />
  ) : (
    <Select fullWidth onChange={onChange} value={folderId}>
      <MenuItem value={0}>
        <Stack alignItems={'center'} direction={'row'}>
          <Tooltip title={'ID:0'}>
            <Avatar sx={{ bgcolor, mr: 2 }}>
              <AccountBalanceTwoTone />
            </Avatar>
          </Tooltip>

          <Typography>Gebäude wählen ...</Typography>
        </Stack>
      </MenuItem>

      {folderPool.map((folder, key) => {
        const { image_url, name } = folder.attributes
        const FLATTEN = f => f.attributes
        const FILTER_CONSUMPTION = f =>
          f.sensor_target === 'consumption' ||
          f.sensor_target === 'performance' ||
          categoryByType(f.sensor_type, f.type_in_folder) === 'consumption' ||
          categoryByType(f.sensor_type, f.type_in_folder) === 'performance'
        const fsData = sensorPool
          .map(FLATTEN)
          .filter(f => f.folder_id === Number(folder.id))
          .filter(FILTER_CONSUMPTION)
        const NO_CONSUMPTION_SENSORS = fsData.length === 0
        const secondary = `${fsData.length} Verbrauchssensoren`

        return (
          <MenuItem
            disabled={NO_CONSUMPTION_SENSORS}
            key={key}
            value={folder.id}
          >
            <Stack alignItems={'center'} direction={'row'}>
              <Tooltip title={`ID:${folder.id}`}>
                <Avatar src={image_url} sx={{ mr: 2 }} />
              </Tooltip>

              <ListItemText primary={name} secondary={secondary} />
            </Stack>
          </MenuItem>
        )
      })}
    </Select>
  )
}

FolderSelector.propTypes = {
  folderId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}
