import { BedtimeTwoTone, CheckTwoTone } from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { userConstants as UC } from '../../../constants'

export const UserStatusSelector = ({
  data = [
    {
      icon: <CheckTwoTone sx={{ mr: 1 }} />,
      label: UC.USER_STATUS_ACTIVE,
      value: UC.USER_STATUS_ACTIVE,
    },
    {
      icon: <BedtimeTwoTone sx={{ mr: 1 }} />,
      label: UC.USER_STATUS_INACTIVE,
      value: UC.USER_STATUS_INACTIVE,
    },
  ],
  onChange,
  value,
}) => (
  <ToggleButtonGroup onChange={onChange} value={value}>
    {data.map((item, key) => (
      <Tooltip key={key} title={item.label}>
        <ToggleButton key={key} value={item.value}>
          {item.icon} {item.label}
        </ToggleButton>
      </Tooltip>
    ))}
  </ToggleButtonGroup>
)

UserStatusSelector.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
}
