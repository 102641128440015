// modules
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { getRelativeDate, userLocales, DDMMYYHHMM } from '../../../api'
import { useNotificationPool } from '../../../hooks'

export const NotificationPoolStat = () => {
  const { data, fetchDate } = useNotificationPool()

  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1, mb: 2 }}>
        <Typography>Poolgröße</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{data.length}</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Verwendung in Gebäuden</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {[...new Set(data.map(i => i.folder_id))].length}
        </Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>überwachte Sensoren</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {[...new Set(data.map(i => i.sensor_id))].length}
        </Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>E-Mail Topics</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {[...new Set(data.map(i => i.email_topic))].length}
        </Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Identifiers</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {[...new Set(data.map(i => i.identifier))].length}
        </Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1, mt: 2 }}>
        <Typography>gefetcht</Typography>
      </Grid>
      <Grid item xs={6} sx={{ mt: 2 }}>
        <Typography>
          {new Date(fetchDate).toLocaleString(userLocales, DDMMYYHHMM)}
          <br />
          {getRelativeDate(fetchDate)}
        </Typography>
      </Grid>
    </Grid>
  )
}
