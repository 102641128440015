import { history } from '../api'
import { newsConstants as NC } from '../constants'
import { newsService as NS, tokenService as TS } from '../services'
import { alertActions as AA } from '.'

const getAll = () => {
  const failure = error => ({ type: NC.NEWS_GET_ALL_ERROR, error })
  const request = () => ({ type: NC.NEWS_GET_ALL_REQUEST })
  const success = news => ({ type: NC.NEWS_GET_ALL_SUCCESS, news })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      NS.getAll().then(
        result => dispatch(success(result)),
        error => dispatch(failure(`${error.status} ${error.message}`))
      )
    )
  }
}

const getAllAlerts = (includes = '') => {
  const failure = error => ({ type: NC.NEWS_GET_ALL_ALERTS_ERROR, error })
  const request = () => ({ type: NC.NEWS_GET_ALL_ALERTS_REQUEST })
  const success = alerts => ({ type: NC.NEWS_GET_ALL_ALERTS_SUCCESS, alerts })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      NS.getAllAlerts(includes).then(
        result => dispatch(success(result)),
        error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        }
      )
    )
  }
}

const getAllNewsTypes = () => {
  const failure = error => ({ type: NC.NEWS_GET_ALL_TYPES_ERROR, error })
  const request = () => ({ type: NC.NEWS_GET_ALL_TYPES_REQUEST })
  const success = newsTypes => ({
    type: NC.NEWS_GET_ALL_TYPES_SUCCESS,
    newsTypes,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      NS.getAllNewsTypes()
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const getById = (id, includes = []) => {
  const failure = error => ({ type: NC.NEWS_GET_BY_ID_ERROR, error })
  const request = news => ({ type: NC.NEWS_GET_BY_ID_REQUEST, news })
  const success = news => ({ type: NC.NEWS_GET_BY_ID_SUCCESS, news })

  return dispatch => {
    dispatch(request({ id }))

    TS.refreshToken().then(() =>
      NS.getById(id, includes).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const remove = id => {
  const failure = error => ({ type: NC.NEWS_DELETE_ERROR, error })
  const request = () => ({ type: NC.NEWS_DELETE_REQUEST })
  const success = news => ({ type: NC.NEWS_DELETE_SUCCESS, news })

  return dispatch => {
    if (!id) return dispatch(AA.error(NC.NEWS_ID_REQUIRED))

    dispatch(request())

    NS.remove(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(NC.NEWS_DELETED))

        history.push('/news')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const save = data => {
  const {
    author,
    content_text,
    contract_id,
    folder_id,
    headline,
    news_type_id,
    start_time,
    title,
  } = data

  const failure = error => ({ type: NC.NEWS_SAVE_ERROR, error })
  const request = () => ({ type: NC.NEWS_SAVE_REQUEST })
  const success = news => ({ type: NC.NEWS_SAVE_SUCCESS, news })

  return dispatch => {
    if (!title && !content_text)
      return dispatch(AA.error(NC.NEWS_CONTENT_REQUIRED))

    if (!folder_id && !contract_id)
      return dispatch(AA.error(NC.NEWS_FOLDER_REQUIRED))

    dispatch(request())

    NS.save({
      data: {
        attributes: {
          author,
          content_text,
          folder_id: folder_id ? folder_id.value : null,
          headline,
          news_type_id,
          start_time,
          title,
        },
        relationships: {
          contracts: {
            data: contract_id.map(item => ({
              type: 'contract',
              id: item.value,
            })),
          },
        },
      },
    })
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(NC.NEWS_SAVED))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const update = (id, data) => {
  const {
    author,
    content_text,
    contract_id,
    folder_id,
    headline,
    news_type_id,
    start_time,
    title,
  } = data

  const failure = error => ({ type: NC.NEWS_UPDATE_ERROR, error })
  const request = () => ({ type: NC.NEWS_UPDATE_REQUEST })
  const success = news => ({ type: NC.NEWS_UPDATE_SUCCESS, news })

  return dispatch => {
    if (!title && !content_text)
      return dispatch(AA.error(NC.NEWS_CONTENT_REQUIRED))

    if (!folder_id && !contract_id)
      return dispatch(AA.error(NC.NEWS_FOLDER_REQUIRED))

    dispatch(request())

    NS.update(id, {
      data: {
        attributes: {
          folder_id: folder_id ? folder_id.value : null,
          title,
          headline,
          author,
          content_text,
          start_time,
          news_type_id,
        },
        relationships: {
          contracts: {
            data: contract_id.map(item => ({
              type: 'contract',
              id: item.value,
            })),
          },
        },
      },
    })
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(NC.NEWS_UPDATED))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

export const newsActions = {
  getAll,
  getAllAlerts,
  getAllNewsTypes,
  getById,
  remove,
  save,
  update,
}
