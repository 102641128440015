import { AddTwoTone, EmailTwoTone } from '@mui/icons-material'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  TextField,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { isValidEmail } from '../../../api'

export const AddAboRecipient = ({ onAdd }) => {
  const theme = useTheme()
  const borderColor = theme.palette.lightgray.dark
  const bgcolor = theme.palette.lightgray.light
  const border = `solid 1px ${borderColor}`

  const [email, setEmail] = useState('')

  const disabled = !isValidEmail(email)

  const onChange = event => setEmail(event.target.value)

  const onClick = () => {
    onAdd(email)
    setEmail('')
  }

  return (
    <Card sx={{ border, borderRadius: '10px' }}>
      <CardHeader avatar={<EmailTwoTone />} title={'Empfänger hinzufügen'} />

      <Divider />

      <CardContent>
        <Stack alignItems={'center'} direction={'row'} sx={{ width: 1 }}>
          <TextField
            fullWidth
            label={'E-Mail'}
            onChange={onChange}
            value={email}
          />

          <Stack>
            <IconButton disabled={disabled} onClick={onClick} sx={{ bgcolor }}>
              <AddTwoTone />
            </IconButton>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

AddAboRecipient.propTypes = {
  onAdd: PropTypes.func.isRequired,
}
