import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { newsActions } from '../actions'

const newsPoolContext = createContext({})

export const ProvideNewsPool = ({ children }) => {
  const value = useNews()

  return (
    <newsPoolContext.Provider value={value}>
      {children}
    </newsPoolContext.Provider>
  )
}
ProvideNewsPool.propTypes = { children: PropTypes.any }

export const useNewsPool = () => useContext(newsPoolContext)

const useNews = () => {
  const dispatch = useDispatch()
  const { allNews } = useSelector(state => state.news)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(
    () => {
      dispatch(newsActions.getAll())
      setLoading(true)
    },
    [dispatch]
  )

  useEffect(
    () => {
      const { error, fetchDate, items } = allNews

      if (items) {
        const { data } = items

        if (data) {
          setData(data)
          setLoading(false)
          setFetchDate(fetchDate)
        }
      }

      if (error) {
        setError(error)
        setLoading(false)
        setFetchDate(fetchDate)
      }
    },
    [allNews]
  )

  return { data, isError, isLoading, fetchDate }
}
