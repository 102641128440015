import axios from 'axios'
import { authHeader } from '../api'

const { REACT_APP_API_URL, REACT_APP_VER_HISTORY_AGGREGATED } = process.env

const ERR_MISSING_CONTRACT = new Error('Kein Vertrag gewählt')
const ERR_INVALID_CONTRACT_ID = new Error('Ungültiger Vertrag')

const getAllMissingData = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/missingdata/admin/missingdata?${REACT_APP_VER_HISTORY_AGGREGATED}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('missingDataService.getAllMissingData', error)
  }
}

const getById = async (id /*, includes = []*/) => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_CONTRACT)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_CONTRACT_ID)

  /*
  var request
  if (includes.length > 0) {
    request += '?include='
    includes.forEach(val => {
      request += encodeURIComponent(val)
      request += ','
    })
  }
  */

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/missingdata/admin/missingdata/${id}?${REACT_APP_VER_HISTORY_AGGREGATED}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('missingDataService.getById', error)
  }
}

export const missingDataService = { getAllMissingData, getById }
