import { SaveTwoTone } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { dashboardActions } from '../../../actions'
import { useAppConfig } from '../../../hooks'

export const DialogCreateDashboard = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { global } = useAppConfig()
  const { user_id } = global
  const [name, setName] = useState('')
  const changeName = event => setName(event.target.value)
  const createDashboard = () =>
    dispatch(dashboardActions.save({ name, user_id }))

  return (
    <Dialog maxWidth={'lg'} {...rest}>
      <DialogTitle>Neues Dashboard</DialogTitle>

      <DialogContent>
        <TextField label={'Name'} onChange={changeName} value={name} />
      </DialogContent>

      <DialogActions>
        <Button
          sx={{
            '&.Mui-disabled': {
              color: '#c0c0c0',
            },
          }}
          disabled={name === ''}
          onClick={createDashboard}
        >
          <SaveTwoTone />
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}
