import { Alert, AlertTitle, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const AboutAbos = () => {
  const data = [
    'durch das Abonnement erhalten Sie automatisiert monatlich einen  Bericht per E-Mail',
    'abonnierte Berichte werden automatisiert am 1. des Monats für den jeweiligen Vormonat erstellt und an die Empfänger-Liste per E-Mail verschickt',
    'die Zustellung per E-Mail erfolgt gegen 08:00 Uhr',
    'Sensoren können für die Berichterstellung beliebig an- oder abgewählt werden',
  ]

  return (
    <Alert severity={'info'} sx={{ my: 2 }}>
      <AlertTitle>Was ist ein Abonnement?</AlertTitle>

      {data.map((item, key) => (
        <Typography key={key}>&bull; {item}</Typography>
      ))}

      <Typography>
        &bull; Abonnements können unter dem Reiter{' '}
        <strong>Berichte Abos</strong> in den{' '}
        <Link to={'/user-page/3'}>Accounteinstellungen</Link> eingesehen werden
      </Typography>
    </Alert>
  )
}
