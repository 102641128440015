import { history, setStorage } from '../api'
import { userConstants as UC } from '../constants'
import { userService as US, tokenService as TS } from '../services'
import { alertActions as AA } from './'

const addUser = data => {
  const failure = error => ({ type: UC.USER_SAVE_ERROR, error })
  const request = () => ({ type: UC.USER_SAVE_REQUEST })
  const success = user => ({ type: UC.USER_SAVE_SUCCESS, user })

  const saveData = data => {
    const {
      city,
      contracts,
      country,
      email,
      email_contact,
      fax,
      firstname,
      folders,
      homepage,
      image_url,
      is_active,
      lastname,
      password,
      telephone,
      telephone_mobile,
      role_id,
      street,
      zip,
    } = data

    // save user in cognito
    const cognitoData = {
      email,
      firstName: firstname,
      lastName: lastname,
      password,
    }

    const recordData = {
      data: {
        attributes: {
          city,
          country,
          email,
          email_contact,
          fax,
          firstname,
          homepage,
          image_url,
          is_active,
          lastname,
          role_id,
          street,
          telephone,
          telephone_mobile,
          zip,
        },
        relationships: {
          contracts: {
            data: contracts.map(contract => ({
              type: 'contract',
              id: contract.entity_id,
            })),
          },
          folders: {
            data: folders.map(folder => ({
              type: 'folder',
              id: folder.entity_id,
            })),
          },
        },
      },
    }

    return dispatch => {
      US.register(cognitoData)
        .then(() => {
          dispatch(request())

          US.addUser(recordData)
            .then(result => {
              dispatch(success(result))
              dispatch(AA.success(UC.USER_SAVED))

              history.goBack()
            })
            .catch(error => {
              const { message } = error

              dispatch(failure(message))
              dispatch(AA.error(message))
            })
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    }
  }

  return dispatch => {
    if (data.image_url instanceof Object) {
      US.uploadImage(data.image_url, 'users')
        .then(result => {
          data.image_url = result.location

          dispatch(saveData(data))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    } else dispatch(saveData(data))
  }
}

const deleteUser = id => {
  const failure = error => ({ type: UC.USER_DELETE_ERROR, error })
  const request = () => ({ type: UC.USER_DELETE_REQUEST })
  const success = user => ({ type: UC.USER_DELETE_SUCCESS, user })

  return dispatch => {
    if (!id) return dispatch(AA.error(UC.USER_ID_REQUIRED))

    dispatch(request())

    US.deleteUser(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(UC.USER_DELETED))

        history.goBack()
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const getOperator = () => {
  const failure = error => ({ type: UC.USER_GET_CURRENT_USER_ERROR, error })
  const request = () => ({ type: UC.USER_GET_CURRENT_USER_REQUEST })
  const success = user => ({ type: UC.USER_GET_CURRENT_USER_SUCCESS, user })

  const failureConfig = error => ({ type: UC.USER_GET_CONFIG_ERROR, error })
  const requestConfig = () => ({ type: UC.USER_GET_CONFIG_REQUEST })
  const successConfig = config => ({ type: UC.USER_GET_CONFIG_SUCCESS, config })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      US.getOperator()
        .then(result => dispatch(success(result)))
        .then(() => {
          dispatch(requestConfig())

          US.getS3Config()
            .then(result => dispatch(successConfig(result)))
            .catch(error => dispatch(failureConfig(error.message)))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const getRoles = () => {
  const failure = error => ({ type: UC.USER_GET_ALL_ROLE_ERROR, error })
  const request = () => ({ type: UC.USER_GET_ALL_ROLE_REQUEST })
  const success = userRoles => ({
    type: UC.USER_GET_ALL_ROLE_SUCCESS,
    userRoles,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      US.getRoles()
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const getUser = id => {
  const failure = error => ({ type: UC.USER_GET_BY_ID_ERROR, error })
  const request = () => ({ type: UC.USER_GET_BY_ID_REQUEST })
  const success = userById => ({ type: UC.USER_GET_BY_ID_SUCCESS, userById })

  return async dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      US.getUser(id).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const getUsers = () => {
  const failure = error => ({ type: UC.USER_GET_ALL_USERS_ERROR, error })
  const request = () => ({ type: UC.USER_GET_ALL_USERS_REQUEST })
  const success = userPool => ({
    type: UC.USER_GET_ALL_USERS_SUCCESS,
    userPool,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      US.getUsers().then(
        result => dispatch(success(result)),
        error => dispatch(failure(`${error.status} ${error.message}`))
      )
    )
  }
}

const login = (email, password) => {
  const failure = error => ({ type: UC.USER_LOGIN_ERROR, error })
  const request = user => ({ type: UC.USER_LOGIN_REQUEST, user })
  const success = user => ({ type: UC.USER_LOGIN_SUCCESS, user })

  const failureConfig = error => ({ type: UC.USER_GET_CONFIG_ERROR, error })
  const requestConfig = () => ({ type: UC.USER_GET_CONFIG_REQUEST })
  const successConfig = config => ({ type: UC.USER_GET_CONFIG_SUCCESS, config })

  return dispatch => {
    dispatch(request({ email }))

    US.login(email, password)
      .then(result => {
        dispatch(success(result))

        history.push('/')
      })
      .then(() => {
        dispatch(requestConfig())

        US.getS3Config()
          .then(result => dispatch(successConfig(result)))
          .catch(error => dispatch(failureConfig(error.message)))
      })
      .catch(error => {
        const { code, message } = error

        switch (code) {
          case 'UserNotConfirmedException':
            setStorage('errorNotConfirmed', true)

            dispatch(AA.error(message))
            break
          case 'UserNotFoundException':
            dispatch(AA.error(message))
            break
          case 'NotAuthorizedException':
            dispatch(AA.error(message))
            break

          default:
            dispatch(AA.error(message))
        }

        dispatch(failure(message))
      })
  }
}

const logout = () => {
  US.logout()

  return { type: UC.USER_LOGOUT }
}

const register = data => {
  const failure = error => ({ type: UC.USER_REGISTER_ERROR, error })
  const request = user => ({ type: UC.USER_REGISTER_REQUEST, user })
  const success = user => ({ type: UC.USER_REGISTER_SUCCESS, user })

  return dispatch => {
    dispatch(request(data))

    US.register(data)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(UC.USER_REGISTRATION_SUCCESS))

        history.push('/pages/register-page/confirm')
      })
      .catch(error => {
        const { code, message } = error

        switch (code) {
          case 'UsernameExistsException':
            dispatch(AA.error(message))
            break
          case 'NotAuthorizedException':
            dispatch(AA.error(message))
            break
          default:
            dispatch(AA.error(message))
        }

        dispatch(failure(message))
      })
  }
}

const resetPassword = (email, password, code) => {
  const failure = error => ({ type: UC.USER_CHANGE_PASSWORD_ERROR, error })
  const request = user => ({ type: UC.USER_CHANGE_PASSWORD_REQUEST, user })
  const success = user => ({ type: UC.USER_CHANGE_PASSWORD_SUCCESS, user })

  return dispatch => {
    dispatch(request({ email }))

    US.resetPassword(email, password, code)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(UC.USER_PASSWORD_CHANGED))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const sendResetCode = email => {
  const failure = error => ({ type: UC.USER_FORGOT_PASSWORD_ERROR, error })
  const request = user => ({ type: UC.USER_FORGOT_PASSWORD_REQUEST, user })
  const success = user => ({ type: UC.USER_FORGOT_PASSWORD_SUCCESS, user })

  return dispatch => {
    dispatch(request({ email }))

    US.sendResetCode(email)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(UC.USER_CODE_SENT))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const sendSignup = email => {
  const failure = error => ({ type: UC.USER_RESEND_LINK_ERROR, error })
  const request = user => ({ type: UC.USER_RESEND_LINK_REQUEST, user })
  const success = user => ({ type: UC.USER_RESEND_LINK_SUCCESS, user })

  return dispatch => {
    dispatch(request({ email }))

    US.sendSignup(email)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(UC.USER_MAIL_SENT))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const updateOperator = (id, data) => {
  const failure = error => ({ type: UC.USER_CURRENT_USER_UPDATE_ERROR, error })
  const request = () => ({ type: UC.USER_CURRENT_USER_UPDATE_REQUEST })
  const success = user => ({ type: UC.USER_CURRENT_USER_UPDATE_SUCCESS, user })
  let view_options

  const updateData = (id, user) => {
    return dispatch => {
      dispatch(request())
      if (user.view_options) {
        view_options = user.view_options
        delete user.view_options
      }
      US.updateOperator(id, {
        data: {
          type: 'user',
          id,
          attributes: user,
          view_options: view_options,
        },
      })
        .then(() => dispatch(getOperator()))
        .then(result => {
          dispatch(success(result))
          dispatch(AA.success(UC.USER_UPDATED))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    }
  }

  return dispatch => {
    if (data.image_url instanceof Object) {
      US.uploadImage(data.image_url, 'users')
        .then(result => {
          data.image_url = result.location

          dispatch(updateData(id, data))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    } else dispatch(updateData(id, data))
  }
}

const updatePassword = (fromPassword, toPassword) => {
  const failure = error => ({ type: UC.USER_CHANGE_PASSWORD_ERROR, error })
  const request = () => ({ type: UC.USER_CHANGE_PASSWORD_REQUEST })
  const success = user => ({ type: UC.USER_CHANGE_PASSWORD_SUCCESS, user })

  return dispatch => {
    dispatch(request())

    US.updatePassword(fromPassword, toPassword)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(UC.USER_PASSWORD_CHANGED))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const updateUser = (id, data) => {
  const failure = error => ({ type: UC.USER_UPDATE_ERROR, error })
  const request = () => ({ type: UC.USER_UPDATE_REQUEST })
  const success = user => ({ type: UC.USER_UPDATE_SUCCESS, user })

  const updateData = (id, data) => {
    const {
      city,
      contracts,
      country,
      email_contact,
      fax,
      firstname,
      folders,
      homepage,
      image_url,
      is_active,
      lastname,
      telephone_mobile,
      telephone,
      role_id,
      street,
      zip,
    } = data

    const recordData = {
      data: {
        attributes: {
          city,
          country,
          email_contact,
          fax,
          firstname,
          homepage,
          image_url,
          is_active,
          lastname,
          role_id,
          street,
          telephone,
          telephone_mobile,
          zip,
        },
        relationships: {
          contracts: {
            data: contracts.map(contract => ({
              type: 'contract',
              id: contract.entity_id,
            })),
          },
          folders: {
            data: folders.map(folder => ({
              type: 'folder',
              id: folder.entity_id,
            })),
          },
        },
      },
    }

    return dispatch => {
      dispatch(request())
      US.updateUser(id, recordData)
        .then(result => {
          dispatch(success(result))
          dispatch(AA.success(UC.USER_UPDATED))
          //todo: update allUsers context

          history.goBack()
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    }
  }

  return dispatch => {
    if (data.image_url instanceof Object) {
      US.uploadImage(data.image_url, 'users')
        .then(result => {
          data.image_url = result.location

          dispatch(updateData(id, data))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    } else dispatch(updateData(id, data))
  }
}

const uploadImage = data => {
  const failure = error => ({ type: UC.USER_DATA_UPLOAD_ERROR, error })
  const request = file => ({ type: UC.USER_DATA_UPLOAD_REQUEST, file })
  const success = file => ({ type: UC.USER_DATA_UPLOAD_SUCCESS, file })

  return dispatch => {
    dispatch(request(data))

    US.uploadImage(data)
      .then(result => dispatch(success(result)))
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const getFoldersSensors = folderIds => {
  const failure = error => ({ type: UC.USER_GET_FOLDER_SENSORS_ERROR, error })
  const request = folderIds => ({
    type: UC.USER_GET_FOLDER_SENSORS_REQUEST,
    folderIds,
  })
  const success = folderIds => ({
    type: UC.USER_GET_FOLDER_SENSORS_SUCCESS,
    folderIds,
  })

  return dispatch => {
    dispatch(request(folderIds))

    US.getFoldersSensors(folderIds)
      .then(result => dispatch(success(result)))
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const setUsersBySensorId = (sensorId, users) => {
  const failure = error => ({
    type: UC.USER_SET_SENSORID_BY_USERS_ERROR,
    error,
  })
  const request = (sensorId, users) => ({
    type: UC.USER_SET_SENSORID_BY_USERS_REQUEST,
    sensorId,
    users,
  })
  const success = () => ({ type: UC.USER_SET_SENSORID_BY_USERS_SUCCESS })

  return dispatch => {
    dispatch(request(sensorId, users))

    US.setUsersBySensorId(sensorId, users)
      .then(result => dispatch(success(result)))
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

export const userActions = {
  addUser,
  deleteUser,
  getOperator,
  getRoles,
  getUser,
  getUsers,
  login,
  logout,
  register,
  resetPassword,
  sendResetCode,
  sendSignup,
  updateOperator,
  updatePassword,
  updateUser,
  uploadImage,
  getFoldersSensors,
  setUsersBySensorId,
}
