import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../actions'

const userContext = createContext({})

export const ProvideUserPool = ({ children }) => {
  const value = useUsers()

  return <userContext.Provider value={value}>{children}</userContext.Provider>
}
ProvideUserPool.propTypes = { children: PropTypes.any }

export const useUserPool = () => useContext(userContext)

const useUsers = () => {
  const dispatch = useDispatch()
  const { userPool } = useSelector(state => state.user)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(
    () => {
      dispatch(userActions.getUsers())
      setLoading(true)
    },
    [dispatch]
  )

  useEffect(
    () => {
      const { error, fetchDate, items } = userPool

      if (items) {
        const { data } = items

        if (data) {
          setData(data)
          setLoading(false)
          setFetchDate(fetchDate)
        }
      }

      if (error) {
        setError(error)
        setLoading(false)
        setFetchDate(fetchDate)
      }
    },
    [userPool]
  )

  return { data, isError, isLoading, fetchDate }
}
