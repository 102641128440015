import { missingDataConstants as MDC } from '../constants'
import { missingDataService as MDS, tokenService as TS } from '../services'
import { alertActions as AA } from './'

const getById = id => {
  const failure = error => ({ type: MDC.GET_BY_ID_ERROR, error })
  const request = missingData => ({ type: MDC.GET_BY_ID_REQUEST, missingData })
  const success = missingData => ({ type: MDC.GET_BY_ID_SUCCESS, missingData })

  return dispatch => {
    dispatch(request({ id }))

    TS.refreshToken().then(() =>
      MDS.getById(id).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

export const missingDataActions = {
  getById,
}
