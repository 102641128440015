import { userConstants as UC } from '../constants'

export const registration = (state = {}, action) => {
  const { type } = action

  switch (type) {
    case UC.USER_REGISTER_ERROR:
      return {}

    case UC.USER_REGISTER_REQUEST:
      return { registering: true }

    case UC.USER_REGISTER_SUCCESS:
      return {}

    default:
      return state
  }
}
