import { history } from '../api'
import { sensorConstants as SC, userConstants as UC } from '../constants'
import { sensorService as SS, tokenService as TS } from '../services'
import { alertActions as AA } from './'

const remove = data => {
  const failure = error => ({ type: SC.DELETE_SENSOR_ERROR, error })
  const request = () => ({ type: SC.DELETE_SENSOR_REQUEST })
  const success = () => ({ type: SC.DELETE_SENSOR_SUCCESS })

  const { sensorId } = data

  return dispatch => {
    dispatch(request())

    return SS.remove(sensorId)
      .then(() => {
        dispatch(success())
        dispatch(AA.success(SC.SENSOR_DELETED))

        history.push(`/sensors`)
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const getAllSensors = (page = 1, limit = 200) => {
  const failure = error => ({ type: SC.GET_ALL_ERROR, error })
  const request = () => ({ type: SC.GET_ALL_REQUEST })
  const success = allSensors => ({ type: SC.GET_ALL_SUCCESS, allSensors })

  return async dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      SS.getAll(page, limit)
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const getAllUnlinked = (page = 1, limit = 200) => {
  const failure = error => ({ type: SC.GET_ALL_UNLINKED_ERROR, error })
  const request = () => ({ type: SC.GET_ALL_UNLINKED_REQUEST })
  const success = allUnlinkedSensors => ({
    type: SC.GET_ALL_UNLINKED_SUCCESS,
    allUnlinkedSensors,
  })

  return async dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      SS.getAllUnlinked(page, limit)
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const getAttributesGroup = type => {
  const failure = error => ({ type: SC.GET_ATTRIBUTES_GROUP_FAILURE, error })
  const request = () => ({ type: SC.GET_ATTRIBUTES_GROUP_REQUEST })
  const success = attrGroup => ({
    type: SC.GET_ATTRIBUTES_GROUP_SUCCESS,
    attrGroup,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      SS.getAttributesGroup(type)
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    )
  }
}

const getById = (id, includes = []) => {
  const failure = error => ({ type: SC.GET_BY_ID_ERROR, error })
  const request = () => ({ type: SC.GET_BY_ID_REQUEST })
  const success = sensor => ({ type: SC.GET_BY_ID_SUCCESS, sensor })

  return async dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      SS.getById(id, includes).then(
        result => dispatch(success(result)),
        error => {
          const message = `${error.message}:${id}`

          dispatch(failure(message))
          dispatch(AA.error(message))
        }
      )
    )
  }
}

const getTimeSeries = (id, from = null, to = null, period) => {
  const failure = error => ({ type: SC.GET_TIME_SERIES_ERROR, error })
  const request = () => ({ type: SC.GET_TIME_SERIES_REQUEST })
  const success = timeSeries => ({
    id,
    timeSeries,
    type: SC.GET_TIME_SERIES_SUCCESS,
  })

  return async dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      SS.getTimeSeries(id, from, to, period).then(
        result => dispatch(success(result)),
        error => {
          let errMessage = error.message
          if (error.response) errMessage += '. ' + error.response.data.message

          dispatch(failure(errMessage))
          dispatch(AA.error(errMessage))
        }
      )
    )
  }
}

const recordData = data => {
  const {
    area_in_sqm,
    attributes,
    battery_comment,
    battery_date,
    contract_id,
    counter_reading,
    counter_reading_date,
    factor,
    folder_id,
    fuel_value,
    installation,
    is_active,
    name,
    number_of_employees,
    parent_id,
    state_number,
    converter_factor,
    sensor_target,
    sub_sensor_target,
    subcategory,
    sampling_mode,
    time_periods,
    type_in_folder,
    co2_factor,
    unit,
    meter_number,
    correction_value,
    successor_id,
  } = data

  return {
    data: {
      attributes: {
        area_in_sqm,
        attributes,
        battery_comment,
        battery_date,
        counter_reading,
        counter_reading_date,
        factor: factor ? factor : 1,
        fuel_value: fuel_value ? fuel_value : 1,
        is_active: is_active ? 1 : 0,
        name,
        number_of_employees,
        parent_id: parent_id ? parent_id : null,
        sensor_target,
        state_number: state_number ? state_number : 1,
        converter_factor: converter_factor ? converter_factor : 1,
        subcategory: subcategory ? subcategory.value : null,
        sub_sensor_target,
        type_in_folder: type_in_folder ? type_in_folder : null,
        co2_factor: co2_factor ? co2_factor : 1,
        unit: unit ? unit : null,
        sampling_mode: sampling_mode ? sampling_mode : null,
        meter_number: meter_number ? meter_number : null,
        correction_value:
          correction_value !== 0 && correction_value !== '0'
            ? correction_value
            : null,
      },
      relationships: {
        contracts: {
          data: contract_id.map(item => ({ type: 'contract', id: item })),
        },
        factor_periods: time_periods,
        folders: {
          data: folder_id ? [{ type: 'folder', id: folder_id }] : [],
        },
        installations: installation,
        sensors_order: {
          data: { successor_id: successor_id ? successor_id : null },
        },
        sensors_relation: {
          data: {
            parent_id: parent_id ? parent_id : null,
          },
        },
      },
    },
  }
}

const save = data => {
  const failure = error => ({ type: SC.SAVE_SENSOR_ERROR, error })
  const request = () => ({ type: SC.SAVE_SENSOR_REQUEST })
  const success = sensor => ({ type: SC.SAVE_SENSOR_SUCCESS, sensor })

  const { device_id, name } = data

  return dispatch => {
    if (!name || device_id.length === 0)
      return dispatch(AA.error(SC.SENSOR_DEVICE_REQUIRED))

    device_id.forEach((item, i) => {
      // set timeout to avoid deadlock
      setTimeout(() => {
        dispatch(request())

        SS.save(recordData(data), item.value)
          .then(result => {
            dispatch(success(result))
            dispatch(AA.success(SC.SENSOR_SAVED))
          })
          .catch(error => {
            const { message } = error

            dispatch(failure(message))
            dispatch(AA.error(message))
          })
      }, i * 1000)
    })
  }
}

const update = data => {
  const failure = error => ({ type: SC.SAVE_SENSOR_ERROR, error })
  const request = () => ({ type: SC.SAVE_SENSOR_REQUEST })
  const success = sensor => ({ type: SC.SAVE_SENSOR_SUCCESS, sensor })

  const { sensorId } = data

  return dispatch => {
    if (!sensorId) return dispatch(AA.error(SC.SENSOR_DEVICE_REQUIRED))

    dispatch(request())

    return SS.save(recordData(data), sensorId)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(SC.SENSOR_UPDATED))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const getCalculations = (month, year, limit = 50, offset = 1) => {
  const failure = error => ({ type: SC.GET_CALCULATIONS_ERROR, error })
  const request = () => ({ type: SC.GET_CALCULATIONS_REQUEST })
  const success = calculations => ({
    type: SC.GET_CALCULATIONS_SUCCESS,
    calculations,
  })

  return async dispatch => {
    dispatch(request())

    TS.refreshToken().then(() =>
      SS.getCalculations(month, year, limit, offset)
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(`V2 Sensor Calculations: ${message}`))
        })
    )
  }
}

const importData = (data, id) => {
  const failure = error => ({ type: SC.SAVE_SENSOR_ERROR, error })
  const request = () => ({ type: SC.SAVE_SENSOR_REQUEST })
  const success = sensor => ({ type: SC.SAVE_SENSOR_SUCCESS, sensor })

  return dispatch => {
    if (!id) return dispatch(AA.error(SC.SENSOR_DEVICE_REQUIRED))

    dispatch(request())

    return SS.importData(data, id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(SC.SENSOR_UPDATED))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const setSensorsByUserId = (userId, sensors) => {
  const failure = error => ({
    type: UC.USER_SET_SENSORID_BY_USERS_ERROR,
    error,
  })
  const request = (userId, sensors) => ({
    type: UC.USER_SET_SENSORID_BY_USERS_REQUEST,
    userId,
    sensors,
  })
  const success = () => ({ type: UC.USER_SET_SENSORID_BY_USERS_SUCCESS })

  return dispatch => {
    dispatch(request(userId, sensors))

    SS.setSensorsByUserId(userId, sensors)
      .then(result => dispatch(success(result)))
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const insertMissingSensorData = missingData => {
  const failure = error => ({ type: SC.SAVE_MISSING_SENSOR_DATA_ERROR, error })
  const request = () => ({ type: SC.SAVE_MISSING_SENSOR_DATA_REQUEST })
  const success = sensor => ({
    type: SC.SAVE_MISSING_SENSOR_DATA_SUCCESS,
    sensor,
  })

  return dispatch => {
    dispatch(request(missingData))
    SS.insertMissingSensorData(missingData)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(SC.SAVE_MISSING_SENSOR_DATA_SUCCESS))
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
        dispatch(AA.error(SC.SAVE_MISSING_SENSOR_DATA_ERROR))
      })
  }
}

export const sensorActions = {
  getAllSensors,
  getAllUnlinked,
  getAttributesGroup,
  getById,
  getCalculations,
  getTimeSeries,
  remove,
  save,
  update,
  importData,
  setSensorsByUserId,
  insertMissingSensorData,
}
