import { MenuItem, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const GranularitySelector = ({
  label = 'Granularität',
  variant = 'standard',
  ...rest
}) => {
  const data = [
    { label: 'Stündlich', value: 'd' },
    { label: 'Täglich', value: 'm' },
    { label: 'Monatlich', value: 'y' },
  ]

  return (
    <TextField label={label} select variant={variant} {...rest}>
      {data.map((item, key) => {
        const { label, value } = item

        return (
          <MenuItem key={key} value={value}>
            {label}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

GranularitySelector.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
}
