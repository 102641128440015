import { AppBar, Box, Toolbar, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getStorage } from '../api'
import { svgConstants as SVG } from '../constants'
import {
  ProvideAlertsPool,
  ProvideAppConfig,
  ProvideAppVersion,
  ProvideContractPool,
  ProvideDashboardPool,
  ProvideFolderPool,
  ProvideNewsPool,
  ProvideNewsTypes,
  ProvideNotificationPool,
  ProvideOperator,
  ProvidePayloadPool,
  ProvideSensorPool,
  ProvideSensorPrices,
  ProvideUserPool,
  ProvideUserRoles,
  ProvideVirtualSensorPool,
} from '../hooks'
import dashboardRoutes from '../routes/routes'
import {
  FolderSelector,
  GlobalSettings,
  OperatorMenu,
  Sidebar,
} from './components'
// style
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { BtnUpdate } from './components/BtnUpdate'
import { HStack } from '../components'

const RouteSwitcher = ({ routes }) => (
  <Switch>
    {routes.map((route, key) => {
      const { collapse, component, path, pathTo, redirect, views } = route

      return redirect ? (
        <Redirect from={path} to={pathTo} key={key} />
      ) : collapse ? (
        views.map((item, key) => (
          <Route path={item.path} component={item.component} key={key} />
        ))
      ) : (
        <Route path={path} component={component} key={key} />
      )
    })}
  </Switch>
)

RouteSwitcher.propTypes = {
  routes: PropTypes.array,
}

const ProviderWrapper = ({ children }) => (
  <ProvideOperator>
    <ProvideAlertsPool>
      <ProvideAppConfig>
        <ProvideAppVersion>
          <ProvideContractPool>
            <ProvideDashboardPool>
              <ProvideFolderPool>
                <ProvideNewsPool>
                  <ProvideNewsTypes>
                    <ProvideNotificationPool>
                      <ProvidePayloadPool>
                        <ProvideSensorPool>
                          <ProvideSensorPrices>
                            <ProvideUserPool>
                              <ProvideUserRoles>
                                <ProvideVirtualSensorPool>
                                  {children}
                                </ProvideVirtualSensorPool>
                              </ProvideUserRoles>
                            </ProvideUserPool>
                          </ProvideSensorPrices>
                        </ProvideSensorPool>
                      </ProvidePayloadPool>
                    </ProvideNotificationPool>
                  </ProvideNewsTypes>
                </ProvideNewsPool>
              </ProvideFolderPool>
            </ProvideDashboardPool>
          </ProvideContractPool>
        </ProvideAppVersion>
      </ProvideAppConfig>
    </ProvideAlertsPool>
  </ProvideOperator>
)

ProviderWrapper.propTypes = {
  children: PropTypes.node,
}

export const Layout = ({ location, ...rest }) => {
  const theme = useTheme()
  const { white: bgcolor } = theme.palette

  const { appVersion } = useSelector(state => state.system)

  const buildInfo = getStorage('buildInfo') || { environment: '', build: '' }

  const getRoute = () => location.pathname !== '/maps/full-screen-maps'

  const [isOutdated, setIsOutDated] = useState(false)

  useEffect(
    () => {
      const checkVersion = () => {
        const actual = appVersion?.item?.data?.actual_version
        const build = buildInfo.build

        const latest = Date.parse(actual)
        const current = Date.parse(build)

        setIsOutDated(current < latest)
      }

      checkVersion()
    },
    [appVersion, buildInfo.build]
  )

  const NOT_LOGGED_IN = getStorage('user') === null

  return NOT_LOGGED_IN ? (
    <Redirect to={'/pages/login-page'} />
  ) : (
    <ProviderWrapper>
      {/* einstellungen */}
      <GlobalSettings />
      {/* einstellungen end */}

      <Sidebar
        color={'blue'}
        logo={SVG.KARMA_LOGO}
        routes={dashboardRoutes}
        {...rest}
      />

      <Box
        sx={{
          float: 'right',
          maxHeight: '100%',
          overflow: 'auto',
          overflowScrolling: 'touch',
          position: 'relative',
          width: '100%',
          [theme.breakpoints.up('md')]: { width: `calc(100% - 260px)` },
        }}
      >
        <AppBar sx={{ bgcolor, pl: '260px' }}>
          <Toolbar
            sx={{
              minHeight: '50px',
              px: '15px',
              mx: 'auto',
              '&:before, &:after': { display: 'table', content: '" "' },
              '&:after': { clear: 'both' },
              [theme.breakpoints.down('xs')]: { px: '0px !important' },
              width: '95%',
              alignItems: 'center',
              color: 'black',
              display: 'flex',
              justifyContent: 'space-between',
              '& > button': { color: 'black' },
              '& > div > button': { color: 'black' },
            }}
          >
            <Box sx={{ flex: 1, textAlign: 'center' }}>
              <FolderSelector />
            </Box>

            <HStack spacing={3}>
              {isOutdated && <BtnUpdate />}
              <OperatorMenu />
            </HStack>
          </Toolbar>
        </AppBar>

        {/* On the /maps/full-screen-maps route we want the map to be on full screen
            - this is not possible if the content and conatiner classes are present 
            because they have some paddings which would make the map smaller */}

        {getRoute() ? (
          <Box
            sx={{
              '&:before, &:after': { display: 'table', content: '" "' },
              '&:after': { clear: 'both' },
              mx: 'auto',
              mt: '100px',
              //minHeight: '50px',
              minHeight: 'calc(100vh - 180px)',
              px: '15px',
              [theme.breakpoints.down('xs')]: {
                m: '5px !important',
                mt: '80px !important',
                p: '30px 0px !important',
              },
            }}
          >
            <RouteSwitcher routes={dashboardRoutes} />
          </Box>
        ) : (
          <Box sx={{ mt: '80px' }}>
            <RouteSwitcher routes={dashboardRoutes} />
          </Box>
        )}

        {getRoute() && (
          <Typography sx={{ p: '15px 0', textAlign: 'center' }}>
            Copyright &copy; {new Date(Date.now()).getFullYear()} KARMA. Die
            digitale Lösung für jedes Gebäude.
          </Typography>
        )}
      </Box>
    </ProviderWrapper>
  )
}

Layout.propTypes = {
  location: PropTypes.object,
}
