// modules
import { TroubleshootTwoTone } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
// scripts
import {
  getStatusColor,
  normalizedSensorType,
  SensorIcon,
  sensorType,
} from '../../../api'
import { ToggleDateType } from '../../../components'
import { IntervalAlert } from './'

const RoomSensor = ({ data, onClick }) => {
  const theme = useTheme()
  const success = theme.palette.success.main
  const error = theme.palette.error.main

  const {
    is_active,
    sensor_type,
    status,
    sub_sensor_target,
    time,
    type_in_folder,
    value,
    unit,
  } = data

  const nst = normalizedSensorType(
    sensor_type,
    type_in_folder,
    sub_sensor_target
  )

  let image_url = SensorIcon(nst, status)
  if (!image_url)
    image_url = SensorIcon(
      type_in_folder,
      [error, success][is_active],
      sub_sensor_target
    )

  // hr = human readable
  const hrType = sensorType(sensor_type, type_in_folder, sub_sensor_target)
  const hrUnit = unit ? unit : ''

  const SENSOR_SUPPORTS_HISTORY =
    sensor_type === `pressure` ||
    sensor_type === `temperature` ||
    sensor_type === `temperature_0` ||
    sensor_type === `temperature_1` ||
    sensor_type === `Ni1000` ||
    sensor_type === `degrees-celsius`

  const SENSOR_SUPPORTS_VALUE =
    sensor_type !== `waterDetected` &&
    sensor_type !== `smokeDetektor` &&
    sensor_type !== `external_digital_button`

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar src={image_url} width={80} />
      </ListItemAvatar>

      <ListItemText
        primary={hrType}
        secondary={<ToggleDateType date={new Date(Date.parse(time))} />}
        sx={{ width: 0.7 }}
      />

      <ListItemText
        disableTypography
        primary={
          <>
            <strong>{SENSOR_SUPPORTS_VALUE && value}</strong>
            &nbsp;
            {hrUnit}
          </>
        }
        sx={{
          display: 'flex',
          fontWeight: 'bold',
          justifyContent: 'flex-end',
          mr: 2,
          width: 0.3,
        }}
      />

      <Divider flexItem orientation={'vertical'} sx={{ mr: 2 }} />

      <ListItemSecondaryAction>
        {SENSOR_SUPPORTS_HISTORY && (
          <Tooltip title={`Details ${hrType}`}>
            <IconButton onClick={onClick}>
              <TroubleshootTwoTone />
            </IconButton>
          </Tooltip>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

RoomSensor.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
}

export const SecurityRoom = ({ folderId, title, sensors }) => {
  const history = useHistory()
  const roomSensors = sensors.filter(f => f.name === title).sort()

  const avatar = (
    <Avatar>
      <Box
        alt={''}
        component={'img'}
        src={SensorIcon('security', getStatusColor(roomSensors))}
        sx={{ width: 40 }}
      />
    </Avatar>
  )

  return (
    <Card variant={'security'}>
      <CardHeader
        avatar={avatar}
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
        variant={'security'}
      />
      <CardContent sx={{ p: '0 !important' }}>
        {/* display interval alert, if applicable  */}
        <IntervalAlert title={title} sensors={sensors} />

        <List>
          {roomSensors.map((item, key) => {
            const { entity_id, sensor_type } = item
            const target = `/folder/${folderId}/security/${sensor_type}/${entity_id}`
            const hasDetailPage =
              sensor_type === `pressure` ||
              sensor_type === `temperature` ||
              sensor_type === `temperature_0` ||
              sensor_type === `temperature_1` ||
              sensor_type === `Ni1000` ||
              sensor_type === `degrees-celsius`

            return (
              <RoomSensor
                data={item}
                key={key}
                onClick={hasDetailPage ? () => history.push(target) : null}
              />
            )
          })}
        </List>
      </CardContent>
    </Card>
  )
}

SecurityRoom.propTypes = {
  folderId: PropTypes.number,
  title: PropTypes.string,
  sensors: PropTypes.array,
}
