export const sensorPriceConstants = {
  DELETE_SENSOR_PRICE_REQUEST: 'DELETE_SENSOR_PRICE_REQUEST',
  DELETE_SENSOR_PRICE_SUCCESS: 'DELETE_SENSOR_PRICE_SUCCESS',
  DELETE_SENSOR_PRICE_ERROR: 'DELETE_SENSOR_PRICE_ERROR',

  GET_ALL_REQUEST: 'SENSOR_PRICE_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'SENSOR_PRICE_GET_ALL_SUCCESS',
  GET_ALL_ERROR: 'SENSOR_PRICE_GET_ALL_ERROR',

  SAVE_SENSOR_PRICE_REQUEST: 'SAVE_SENSOR_PRICE_REQUEST',
  SAVE_SENSOR_PRICE_SUCCESS: 'SAVE_SENSOR_PRICE_SUCCESS',
  SAVE_SENSOR_PRICE_ERROR: 'SAVE_SENSOR_PRICE_ERROR',

  UPDATE_SENSOR_PRICE_REQUEST: 'UPDATE_SENSOR_PRICE_REQUEST',
  UPDATE_SENSOR_PRICE_SUCCESS: 'UPDATE_SENSOR_PRICE_SUCCESS',
  UPDATE_SENSOR_PRICE_ERROR: 'UPDATE_SENSOR_PRICE_ERROR',

  SENSORPRICE_ID_REQUIRED: 'ID erforderlich',
  SENSORPRICE_SENSOR_REQUIRED: 'Sensor erforderlich',

  SENSORPRICE_DELETED: 'Sensor Price gelöscht',
  SENSORPRICE_SAVED: 'Sensor Price gespeichert',
  SENSORPRICE_UPDATED: 'Sensor Price geupdated',
}
