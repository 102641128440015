import axios from 'axios'
import { authHeader } from '../api'

const {
  REACT_APP_API_URL,
  REACT_APP_REPORTS_API_PREFIX,
  REACT_APP_REPORTS_ENV,
} = process.env

const generateMBR = async (folderId, month, year) => {
  let result = 'unset'
  const config = {
    method: 'post',
    url: `https://${REACT_APP_REPORTS_API_PREFIX}.execute-api.eu-central-1.amazonaws.com/${REACT_APP_REPORTS_ENV}/reports/mbr?folder_id=${folderId}&year=${year}&month=${month}`,
    headers: authHeader(),
    data: '',
  }

  result = await axios(config)
    .then(response => {
      result = JSON.stringify(response.data)

      return result
    })
    .catch(error => {
      result = new Error(error.message)
    })

  return result
}

const generateQBR = async (folderId, quarter, year) => {
  let result = 'unset'
  const config = {
    method: 'post',
    url: `https://${REACT_APP_REPORTS_API_PREFIX}.execute-api.eu-central-1.amazonaws.com/${REACT_APP_REPORTS_ENV}/reports/qbr?folder_id=${folderId}&year=${year}&quarter=${quarter}`,
    headers: authHeader(),
    data: '',
  }

  result = await axios(config)
    .then(response => {
      result = JSON.stringify(response.data)

      return result
    })
    .catch(error => {
      result = new Error(error.message)
    })

  return result
}

const generateYBR = async (folderId, year) => {
  let result = 'unset'
  const config = {
    method: 'post',
    url: `https://${REACT_APP_REPORTS_API_PREFIX}.execute-api.eu-central-1.amazonaws.com/${REACT_APP_REPORTS_ENV}/reports/ybr?folder_id=${folderId}&year=${year}`,
    headers: authHeader(),
    data: '',
  }

  result = await axios(config)
    .then(response => {
      result = JSON.stringify(response.data)

      return result
    })
    .catch(error => {
      result = new Error(error.message)
    })

  return result
}

const generateSelected = async (folderId, month, year, list) => {
  let result = 'unset'
  const config = {
    method: 'post',
    url: `https://${REACT_APP_REPORTS_API_PREFIX}.execute-api.eu-central-1.amazonaws.com/${REACT_APP_REPORTS_ENV}/reports/get?folder_id=${folderId}&year=${year}&month=${month}&sensors=${list}`,
    headers: authHeader(),
    data: '',
  }

  result = await axios(config)
    .then(response => {
      result = JSON.stringify(response.data)

      return result
    })
    .catch(error => {
      const { config, message, request, response } = error

      if (response) {
        const { headers, data, status } = response
        // request was made; server responded with status code other than 2xx
        console.log(data)
        console.log(status)
        console.log(headers)

        return Promise.reject({ status, message: data.message })
      } else if (request) {
        // request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in browser and an instance of http.ClientRequest in node.js
        console.log(request)
      } else {
        // something happened in setting up the request that triggered an Error
        console.log('Error', message)
      }
      console.log(config)

      console.log(error, error.message)
      result = new Error(error.message)
    })

  return result
}

const getReportSettings = async folder_id => {
  const config = {
    data: { folder_id },
    headers: authHeader(),
    method: 'get',
    url: `${REACT_APP_API_URL}/user/report-settings`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return JSON.stringify(data)
  } catch (error) {
    console.error('reportsService.get', error)
  }
}

const createReportSettings = async (folder_id, mailto, sensor_ids) => {
  const config = {
    data: { folder_id, mailto, sensor_ids },
    headers: authHeader(),
    method: 'post',
    url: `${REACT_APP_API_URL}/user/report-settings`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return JSON.stringify(data)
  } catch (error) {
    console.error('reportsService.create', error)
  }
}

const deleteReportSettings = async folder_id => {
  const config = {
    data: { folder_id },
    headers: authHeader(),
    method: 'delete',
    url: `${REACT_APP_API_URL}/user/report-settings`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return JSON.stringify(data)
  } catch (error) {
    console.error('reportsService.delete', error)
  }
}

const updateReportSettings = async (folder_id, mailto, sensor_ids) => {
  const config = {
    data: { folder_id, mailto, sensor_ids },
    headers: authHeader(),
    method: 'patch',
    url: `${REACT_APP_API_URL}/user/report-settings`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return JSON.stringify(data)
  } catch (error) {
    console.error('reportsService.update', error)
  }
}

export const reportsService = {
  generateMBR,
  generateQBR,
  generateYBR,
  generateSelected,
  getReportSettings,
  createReportSettings,
  deleteReportSettings,
  updateReportSettings,
}
