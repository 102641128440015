// filters for folders
export const FILTER_MAIN_FOLDER = f => f.attributes.parent_id === null
export const FILTER_SUB_FOLDER = f => f.attributes.parent_id !== null

// filters for news types
export const FILTER_TECH_NEWS_TYPE = f =>
  Number(f.attributes.news_type_id) === 1
export const FILTER_LEGAL_NEWS_TYPE = f =>
  Number(f.attributes.news_type_id) === 2
export const FILTER_BUSINESS_NEWS_TYPE = f =>
  Number(f.attributes.news_type_id) === 3
export const FILTER_UNSPECIFIED_NEWS_TYPE = f =>
  Number(f.attributes.news_type_id) === null

// filters for sensors
export const FILTER_MAIN_SENSOR = f => f.attributes.parent_id === null
export const FILTER_SUB_SENSOR = f => f.parent_id !== null
export const FILTER_ENERGY_SENSOR = f =>
  f.attributes.sensor_type === 'energy' ||
  f.attributes.sensor_type === 'energy_detail' ||
  f.attributes.type_in_folder === 'energy' ||
  f.attributes.type_in_folder === 'energy_detail'
export const FILTER_WATER_SENSOR = f =>
  f.attributes.sensor_type === 'water' ||
  f.attributes.sensor_type === 'water_detail' ||
  f.attributes.type_in_folder === 'water' ||
  f.attributes.type_in_folder === 'water_detail'
export const FILTER_GAS_SENSOR = f =>
  f.attributes.sensor_type === 'gas' ||
  f.attributes.sensor_type === 'gas_detail' ||
  f.attributes.type_in_folder === 'gas' ||
  f.attributes.type_in_folder === 'gas_detail'
export const FILTER_ACTIVE_SENSOR = f => Number(f.attributes.is_active) === 1
export const FILTER_INACTIVE_SENSOR = f => Number(f.attributes.is_active) === 0

export const FILTER_TARGET_CONSUMPTION = f => f.sensor_target === 'consumption'
export const FILTER_TARGET_FEELING = f => f.sensor_target === 'feeling'
export const FILTER_TARGET_SECURITY = f => f.sensor_target === 'security'
export const FILTER_TARGET_PERFORMANCE = f => f.sensor_target === 'performance'

export const FILTER_TARGET_NOT_CONSUMPTION = f =>
  f.sensor_target !== 'consumption'
export const FILTER_TARGET_NOT_FEELING = f => f.sensor_target !== 'feeling'
export const FILTER_TARGET_NOT_SECURITY = f => f.sensor_target !== 'security'
export const FILTER_TARGET_NOT_PERFORMANCE = f =>
  f.sensor_target !== 'performance'

// filters for users
export const FILTER_ACTIVE_USER = f => Number(f.attributes.is_active) === 1
export const FILTER_INACTIVE_USER = f => Number(f.attributes.is_active) === 0
export const FILTER_UNCONFIRMED_USER = f =>
  !f.attributes.aws_id || f.attributes.aws_id === 0

// filters for user roles
export const FILTER_ADMIN_ROLE = f => Number(f.attributes.role_id) === 1
export const FILTER_GUEST_ROLE = f => Number(f.attributes.role_id) === 2
export const FILTER_COACH_ROLE = f => Number(f.attributes.role_id) === 3
export const FILTER_USER_ROLE = f => Number(f.attributes.role_id) === 4

// type filters
export const FILTER_TYPE_COACH = f => f.type === 'coach'
export const FILTER_TYPE_CONTRACT = f => f.type === 'contract'
export const FILTER_TYPE_FOLDER = f => f.type === 'folder'
export const FILTER_TYPE_HELP_LOCATION = f => f.type === 'help_location'
export const FILTER_TYPE_INSTALLATION = f => f.type === 'installations'
export const FILTER_TYPE_NEWS = f => f.type === 'news'
export const FILTER_TYPE_OFFICE_HOURS = f => f.type === 'office_hours'
export const FILTER_TYPE_PERIOD = f => f.type === 'period'
export const FILTER_TYPE_SENSOR = f => f.type === 'sensor'
export const FILTER_TYPE_USER = f => f.type === 'user'
