export const virtualSensorConstants = {
  DELETE_VIRTUAL_SENSOR_REQUEST: 'DELETE_VIRTUAL_SENSOR_REQUEST',
  DELETE_VIRTUAL_SENSOR_SUCCESS: 'DELETE_VIRTUAL_SENSOR_SUCCESS',
  DELETE_VIRTUAL_SENSOR_ERROR: 'DELETE_VIRTUAL_SENSOR_ERROR',

  GET_ALL_REQUEST: 'VIRTUAL_SENSOR_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'VIRTUAL_SENSOR_GET_ALL_SUCCESS',
  GET_ALL_ERROR: 'VIRTUAL_SENSOR_GET_ALL_ERROR',

  SAVE_VIRTUAL_SENSOR_REQUEST: 'SAVE_VIRTUAL_SENSOR_REQUEST',
  SAVE_VIRTUAL_SENSOR_SUCCESS: 'SAVE_VIRTUAL_SENSOR_SUCCESS',
  SAVE_VIRTUAL_SENSOR_ERROR: 'SAVE_VIRTUAL_SENSOR_ERROR',

  UPDATE_VIRTUAL_SENSOR_REQUEST: 'UPDATE_VIRTUAL_SENSOR_REQUEST',
  UPDATE_VIRTUAL_SENSOR_SUCCESS: 'UPDATE_VIRTUAL_SENSOR_SUCCESS',
  UPDATE_VIRTUAL_SENSOR_ERROR: 'UPDATE_VIRTUAL_SENSOR_ERROR',

  VIRTUAL_SENSOR_ID_REQUIRED: 'ID erforderlich',
  VIRTUAL_SENSOR_SENSOR_REQUIRED: 'Sensor erforderlich',

  VIRTUAL_SENSOR_DELETED: 'Virtueller Sensor gelöscht',
  VIRTUAL_SENSOR_SAVED: 'Virtueller Sensor gespeichert',
  VIRTUAL_SENSOR_UPDATED: 'Virtueller Sensor geupdated',
}
