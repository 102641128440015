import {
  AddTwoTone,
  ImportExportTwoTone,
  InfoTwoTone,
  LocalShippingTwoTone,
  MemoryTwoTone,
  MoreVertTwoTone,
  PollTwoTone,
  SaveTwoTone,
  SettingsRemoteTwoTone,
  StorageTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import matchSorter from 'match-sorter'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import ReactExport from 'react-export-excel'
import { useHistory } from 'react-router'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { ASC, DESC, getStorage, setStorage } from '../../api'
import {
  AM,
  AMItem,
  CellBoilerplate,
  CellDate,
  CellIsActive,
  DefaultColumnFilter,
  FilterByStatus,
  FilterStatus,
  HStack,
  KPage,
  Preloader,
} from '../../components'
import { useFolderPool, useOperator, useSensorPool } from '../../hooks'
import { SensorPoolStat } from './components'

export const SensorAll = () => {
  const PAGE_CONFIG = 'settings_sensors_all'

  const { ExcelFile } = ReactExport
  const { ExcelColumn, ExcelSheet } = ExcelFile

  const history = useHistory()

  const { data: folderPool, isLoading: isFolderPoolLoading } = useFolderPool()
  const { data: sensorPool, isLoading: isSensorPoolLoading } = useSensorPool()
  const { data: operator } = useOperator()

  /* application storage */
  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))

  const [actionMenu, setActionMenu] = useState(null)
  const [exportDialog, setExportDialog] = useState(false)

  const [sensorCount, setSensorCount] = useState('0/0')

  const [sensors, setSensors] = useState([])
  const [tableDataExport, setTableDataExport] = useState([])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
    rows,
  } = useTable(
    {
      columns: useMemo(
        () => [
          {
            accessor: 'id',
            Header: 'ID',
            sortType: (a, b) => Number(a.original.id) - Number(b.original.id),
            width: 80,
          },
          {
            accessor: 'is_active',
            Cell: CellIsActive,
            disableFilters: true,
            disableSortBy: true,
            Header: 'aktiviert',
            width: 80,
          },
          { accessor: 'name', Header: 'Name' },
          { accessor: 'device_id', Header: 'Device ID' },
          { accessor: 'sensor_type', Header: 'Typ' },
          { accessor: 'provider', Cell: CellBoilerplate, Header: 'Provider' },
          { accessor: 'value', Header: 'Wert' },
          { accessor: 'folder_name', Cell: CellBoilerplate, Header: 'Gebäude' },
          {
            accessor: 'status',
            Cell: FilterByStatus,
            Filter: FilterStatus,
            Header: 'Status',
          },
          { accessor: 'time', Cell: CellDate, Header: 'letzte Aktivität' },
        ],
        []
      ),
      data: useMemo(
        () => sensors.map(sensor => ({ ...sensor.attributes, id: sensor.id })),
        [sensors]
      ),
      defaultColumn: useMemo(() => ({ Filter: DefaultColumnFilter }), []),
      filterTypes: useMemo(
        () => ({
          text: (rows, id, filterValue) =>
            matchSorter(rows, filterValue, { keys: [id] }),

          statusFilter: (rows, id, filterValue) => {
            if (filterValue === 'all') return rows
            return rows.filter(row => row.values[id] === filterValue)
          },
        }),
        []
      ),
      initialState: useMemo(
        () => ({
          sortBy: [{ id: config.orderby, desc: config.order !== 'ASC' }],
          filters: [{ id: 'folder_id', value: 'true' }],
          pageSize: config.maxResults,
        }),
        [config]
      ),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  useEffect(() => setStorage(PAGE_CONFIG, config), [config])

  useEffect(
    () => {
      if (folderPool.length === 0 || sensorPool.length === 0) return

      const updatedSensors = sensorPool.map(sensor => {
        const { folder_id } = sensor.attributes
        const record = folderPool.find(f => Number(f.id) === Number(folder_id))
        const folder_name = record ? record.attributes.name : '-'

        return { ...sensor, attributes: { ...sensor.attributes, folder_name } }
      })

      setSensors(updatedSensors)
    },
    [folderPool, operator, sensorPool]
  )

  useEffect(() => setSensorCount(`${config?.maxResults}/${rows.length}`), [
    config,
    rows,
  ])

  useEffect(() => setTableDataExport(page), [page])

  const handleRowClick = useCallback(id => history.push(`/sensors/${id}`), [
    history,
  ])

  const onPageChange = (event, newPage) => gotoPage(newPage)
  const onPrevPage = () => previousPage()
  const onNextPage = () => nextPage()
  const onRowsPerPageChange = event => setPageSize(Number(event.target.value))

  const onAddSensor = () => history.push('/sensors/new')

  const onCloseAM = () => setActionMenu(null)
  const onOpenAM = event => setActionMenu(event.currentTarget)

  const onCloseStats = () => setConfig(prev => ({ ...prev, showStats: false }))
  const onOpenStats = () => setConfig(prev => ({ ...prev, showStats: true }))

  const onExport = () => setExportDialog(true)
  const onCloseExport = () => setExportDialog(false)

  const onGotoVirtualSensors = () => history.push('/virtualsensors')
  const onGotoDeviations = () => history.push('/sensors/calculon')
  const onGotoPayloads = () => history.push('/sensors/payloads')
  const onGotoData = () => history.push('/data')

  const isPageLoading = isFolderPoolLoading || isSensorPoolLoading

  return operator?.isAdmin ? (
    <>
      {/* preloader */}
      <Preloader isLoading={isPageLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Sensoren'}
        onClose={onCloseAM}
        open={Boolean(actionMenu)}
        historyUrlTarget={'dashboard'}
      >
        <AMItem caption={'Neu'} icon={<AddTwoTone />} onClick={onAddSensor} />

        <AMItem caption={'Info'} icon={<InfoTwoTone />} onClick={onOpenStats} />

        <Divider />

        <AMItem
          caption={'Exportieren'}
          icon={<ImportExportTwoTone />}
          onClick={onExport}
        />

        <AMItem
          caption={'Virtuelle Sensoren'}
          icon={<SettingsRemoteTwoTone />}
          onClick={onGotoVirtualSensors}
        />

        <AMItem
          caption={'Abweichungen'}
          icon={<PollTwoTone />}
          onClick={onGotoDeviations}
        />

        <AMItem
          caption={'Payloads'}
          icon={<LocalShippingTwoTone />}
          onClick={onGotoPayloads}
        />

        <AMItem
          caption={'Daten'}
          icon={<StorageTwoTone />}
          onClick={onGotoData}
        />
      </AM>
      {/* action menu end */}

      {/* pool stat */}
      <Dialog open={config.showStats || false} onClose={onCloseStats}>
        <DialogTitle>Sensor Pool Info</DialogTitle>

        <DialogContent>
          <SensorPoolStat />
        </DialogContent>
      </Dialog>
      {/* pool stat end */}

      {/* exportieren */}
      <Dialog open={exportDialog} onClose={onCloseExport}>
        <DialogTitle onClose={onCloseExport}>Sensoren exportieren</DialogTitle>

        <DialogContent>
          <CSVLink
            data={tableDataExport}
            filename={'Sensor Übersicht.csv'}
            headers={[
              { label: 'ID', key: 'id' },
              { label: 'aktiviert', key: 'is_active' },
              { label: 'Name', key: 'name' },
              { label: 'Device ID', key: 'device_id' },
              { label: 'Sensor Type', key: 'sensor_type' },
              { label: 'Provider', key: 'provider' },
              { label: 'Wert', key: 'value' },
              { label: 'GebäudeName', key: 'folder_name' },
              { label: 'Status', key: 'status' },
              { label: 'Letzte Aktivität', key: 'time' },
            ]}
            target={'_blank'}
          >
            <Button>
              <SaveTwoTone />
              CSV
            </Button>
          </CSVLink>{' '}
          <ExcelFile
            filename={'Sensor Übersicht'}
            element={
              <Button>
                <SaveTwoTone />
                Excel
              </Button>
            }
          >
            <ExcelSheet name={'Sensoren'} data={tableDataExport}>
              <ExcelColumn label={'ID'} value={'id'} />
              <ExcelColumn label={'aktiviert'} value={'is_active'} />
              <ExcelColumn label={'Name'} value={'name'} />
              <ExcelColumn label={'Device ID'} value={'device_id'} />
              <ExcelColumn label={'Sensor Typ'} value={'sensor_type'} />
              <ExcelColumn label={'Provider Typ'} value={'provider'} />
              <ExcelColumn label={'Wert'} value={'value'} />
              <ExcelColumn label={'GebäudeName'} value={'folder_name'} />
              <ExcelColumn label={'Status'} value={'status'} />
              <ExcelColumn label={'Letzte Aktivität'} value={'time'} />
            </ExcelSheet>
          </ExcelFile>
        </DialogContent>
      </Dialog>
      {/* exportieren end */}

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={onOpenAM}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar>
              <MemoryTwoTone />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={`${sensorCount} Sensoren`}
        >
          <TableContainer sx={{ border: 'solid 1px #DDD', borderRadius: 2 }}>
            <Table sx={{ width: 1 }} {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup, key) => (
                  <>
                    <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, innerKey) => (
                        <TableCell
                          key={innerKey}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          sx={{ fontSize: 16, width: column.width }}
                        >
                          {column.render('Header')}
                          <TableSortLabel
                            active={column.isSorted}
                            direction={column.isSortedDesc ? DESC : ASC}
                          />
                        </TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      {headerGroup.headers.map((column, innerKey) => (
                        <TableCell
                          key={innerKey}
                          sx={{ p: 0 }}
                          {...column.getHeaderProps()}
                        >
                          {column.canFilter ? column.render('Filter') : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {page.map((row, key) => {
                  const onClick = () => handleRowClick(row.original.id)

                  prepareRow(row)

                  return (
                    <TableRow
                      key={key}
                      onClick={onClick}
                      sx={{ cursor: 'pointer' }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell, innerKey) => (
                        <TableCell
                          key={innerKey}
                          {...cell.getCellProps()}
                          sx={{ fontSize: 16 }}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination Controls */}
          <HStack sx={{ width: 1 }}>
            <TablePagination
              component={'div'}
              count={pageCount}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              page={pageIndex}
              rowsPerPage={Number(pageSize)}
              rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
            />

            <Button disabled={pageIndex === 0} onClick={onPrevPage}>
              Zurück
            </Button>

            <Button disabled={pageIndex >= pageCount - 1} onClick={onNextPage}>
              Weiter
            </Button>
          </HStack>
        </KPage>
      )}
    </>
  ) : (
    <Alert severity={'info'}>Admin Bereich</Alert>
  )
}
