import 'fix-date'

export const userLocales = 'de-DE'

const D2 = '2-digit'
const L = 'long'
const N = 'numeric'
const S = 'short'

// 01.01.70
export const WD = { weekday: S }
// 01.01.70
export const DDMMYY = { day: D2, month: D2, year: D2 }
// 01.01.1970
export const DDMMYYYY = { day: D2, month: D2, year: N }
// 1. Januar 1970
export const DMMMMYYYY = { day: N, month: L, year: N }
// Montag, 01.01.70
export const WDDDMMYY = { weekday: S, day: D2, month: D2, year: D2 }
// Montag, 01.01.1970
export const WDDDMMYYYY = { weekday: S, day: D2, month: D2, year: N }
// 1. Januar 1970 00:00:00
export const DMMMMYYYYHHMMSS = {
  day: N,
  hour: D2,
  minute: D2,
  month: L,
  second: D2,
  year: N,
}
// Montag, 01.01.1970 00:00:00
export const WDDDMMYYYYHHMMSS = {
  weekday: S,
  day: D2,
  month: D2,
  year: N,
  hour: D2,
  minute: D2,
  second: D2,
}
// Montag, 01.01.1970 00:00:00
export const WDDDMMYYHHMM = {
  weekday: S,
  day: D2,
  month: D2,
  year: D2,
  hour: D2,
  minute: D2,
}
// 01.01.70 00:00
export const DDMMYYHHMM = { day: D2, month: D2, year: D2, hour: D2, minute: D2 }
// 01.01.1970 00:00
export const DDMMYYYYHHMM = {
  day: D2,
  month: D2,
  year: N,
  hour: D2,
  minute: D2,
}
// 01.01.1970 00:00:00
export const DDMMYYYYHHMMSS = {
  day: D2,
  hour: D2,
  minute: D2,
  month: D2,
  second: D2,
  year: N,
}
// 00:00
export const HHMM = { hour: D2, minute: D2 }
// 00:00:00
export const HHMMSS = { hour: D2, minute: D2, second: D2 }
// 01. Januar
export const DDMMMM = { day: D2, month: L }
// 01.01.
export const DDMM = { day: D2, month: D2 }
// 1970
export const YYYY = { year: N }
// Januar
export const MMMM = { month: L }
// Jan 70
export const MMMYY = { month: S, year: D2 }
//
export const MMMMYYYY = { month: L, year: N }
// 1.
export const D = { day: N }
// 1. Jan 00:00
export const DMMMHHMM = { day: N, month: S, hour: D2, minute: D2 }
// 1. Januar 00:00
export const DMMMMHHMM = { day: N, month: L, hour: D2, minute: D2 }

// get current year, month or day
export const currentYear = new Date().toLocaleString(userLocales, YYYY)
export const currentMonth = new Date().toLocaleString(userLocales, MMMM)
export const currentDay = new Date().toLocaleString(userLocales, D)

// Uhrzeit von einem Datums-Objekt liefern
export const timeOfDay = date =>
  new Date(date).toLocaleString(userLocales, HHMMSS)

export const A_MONTH_AGO = new Date().setMonth(new Date().getMonth() - 1)

export const daysAgo = (date, days) =>
  new Date(new Date(date).getTime() - 1000 * 60 * 60 * 24 * days)
    .toISOString()
    .split('T')[0]

export const daysOnward = (date, days) =>
  new Date(new Date(date).getTime() + 1000 * 60 * 60 * 24 * days)
    .toISOString()
    .split('T')[0]

export const monthsAgo = (date, numberOfMonths, firstOfMonth) => {
  date = new Date(date)
  date.setDate(1)
  date.setMonth(date.getMonth() - numberOfMonths)

  if (!firstOfMonth) {
    date = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    date.setDate(date.getDate() + 1)
  }

  return date.toISOString().split('T')[0]
}

export const monthsOnward = (date, numberOfMonths, firstOfMonth) => {
  date = new Date(date)
  date.setDate(1)
  date.setMonth(date.getMonth() + numberOfMonths)

  if (!firstOfMonth) {
    date = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    date.setDate(date.getDate() + 1)
  }

  return date.toISOString().split('T')[0]
}

export const noDecimals = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

export const germanDecimals = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const getRelativeDate = date => {
  const MINUTE = 60,
    HOUR = MINUTE * 60,
    DAY = HOUR * 24,
    WEEK = DAY * 7
  const delta = Math.round((new Date() - new Date(date)) / 1000)
  let result = date

  if (delta < MINUTE) {
    result = 'vor ein paar Sekunden'
  } else if (delta < 2 * MINUTE) {
    result = 'vor 1 Minute'
  } else if (delta < HOUR) {
    result = 'vor ' + Math.floor(delta / MINUTE) + ' Minuten'
  } else if (Math.floor(delta / HOUR) === 1) {
    result = 'vor 1 Stunde'
  } else if (delta < DAY) {
    result = 'vor ' + Math.floor(delta / HOUR) + ' Stunden'
  } else if (delta < DAY * 2) {
    result = 'gestern'
  } else if (delta < DAY * 3) {
    result = 'vorgestern'
  } else if (delta < WEEK) {
    result = 'vor 1 Woche'
  } else if (delta < WEEK * 2) {
    result = 'vor 2 Wochen'
  } else if (delta < WEEK * 3) {
    result = 'vor 3 Wochen'
  } else if (delta < WEEK * 4) {
    result = 'vor 4 Wochen'
  } else if (delta < DAY * 30) {
    result = 'vor über 30 Tagen'
  } else if (delta < DAY * 60) {
    result = 'vor über 60 Tagen'
  } else if (delta < DAY * 90) {
    result = 'vor über 90 Tagen'
  } else if (delta < DAY * 180) {
    result = 'vor über 180 Tagen'
  } else if (delta < DAY * 365) {
    result = 'vor über 1 Jahr'
  } else if (delta < DAY * 730) {
    result = 'vor über 2 Jahren'
  } else if (delta < DAY * 1095) {
    result = 'vor über 3 Jahren'
  } else if (delta < DAY * 1460) {
    result = 'vor über 4 Jahren'
  } else if (delta < DAY * 1825) {
    result = 'vor über 5 Jahren'
  }

  return result
}

// get full array of days with every hour between two dates
export const getHoursArray = (startDate, endDate) => {
  const daysArray = []
  const hoursArray = []
  const date = new Date(startDate)
  const todaysDate = new Date()

  for (daysArray; date <= endDate; date.setDate(date.getDate() + 1))
    daysArray.push(new Date(date))

  daysArray.forEach(dateObject => {
    for (let i = 0; i < 24; i++) {
      const hour = new Date(
        `${dateObject.getFullYear()}-${dateObject.getMonth() +
          1}-${dateObject.getDate()} ${i}:00:00`
      )

      if (hour <= todaysDate) hoursArray.push(hour)
    }
  })

  return hoursArray
}

// get full array of days between two dates
export const getDaysArray = (startDate, endDate) => {
  const daysArray = []
  const date = new Date(startDate)

  for (daysArray; date <= endDate; date.setDate(date.getDate() + 1))
    daysArray.push([new Date(date)])

  return daysArray
}

// get full array of months between two dates
export const getMonthsArray = (startDate, endDate) => {
  const start = startDate.split('-')
  const end = endDate.split('-')
  const startYear = Number(start[0])
  const endYear = Number(end[0])
  const dates = []

  for (let yearIndex = startYear; yearIndex <= endYear; yearIndex++) {
    const endMonth = yearIndex !== endYear ? 11 : Number(end[1]) - 1
    const startMon = yearIndex === startYear ? Number(start[1]) - 1 : 0

    for (
      let monthIndex = startMon;
      monthIndex <= endMonth;
      monthIndex = monthIndex > 12 ? monthIndex % 12 || 11 : monthIndex + 1
    ) {
      const month = monthIndex + 1
      const displayMonth = month < 10 ? '0' + month : month

      dates.push(new Date([yearIndex, displayMonth, '01'].join('-')))
    }
  }

  return dates
}

// add the weekday to an array of dates
export const addWeekdays = (daysArray, granularity) => {
  if (granularity !== 'y') {
    daysArray = daysArray.map(_day => {
      if (_day[0].includes('.')) {
        let weekDay = _day[0].split('.')
        if (granularity === 'd') {
          weekDay = _day[0].substring(0, 10).split('.')
        }

        weekDay = new Date(`20${weekDay[2]}`, weekDay[1] - 1, weekDay[0])

        _day[0] = `${weekDay.toLocaleString(userLocales, WD)}, ${_day[0]} `
      }
      return _day
    })
  }
  return daysArray
}

export const isFullMonth = (dateFrom, dateTo) => {
  dateFrom = new Date(dateFrom)
  dateTo = new Date(dateTo)

  const isLastDay = (year, month) => new Date(year, month + 1, 0).getDate()

  if (dateFrom.getDate() !== 1) return false

  return dateTo.getDate() === isLastDay(dateTo.getFullYear(), dateTo.getMonth())
    ? true
    : false
}

export const isMoreThenSevenDays = (dateFrom, dateTo) => {
  dateFrom = new Date(dateFrom)
  dateTo = new Date(dateTo)

  const range = dateTo - dateFrom
  const difference = range / (1000 * 60 * 60 * 24)

  return difference > 7 ? true : false
}

export const isMoreThenSixMonths = (dateFrom, dateTo) => {
  dateFrom = new Date(dateFrom)
  dateTo = new Date(dateTo)

  const difference =
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())

  return difference > 5 && dateFrom.getDate() < dateTo.getDate() ? true : false
}
