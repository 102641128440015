// modules
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { MemoryTwoTone, SettingsRemoteTwoTone } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
// scripts
import { virtualSensorsActions } from '../../actions'
import { AM, AMItem, KPage, KTextField, Preloader } from '../../components'
import { useSensorPool, useVirtualSensorPool } from '../../hooks'
import { SORT_ATTR_NAME } from '../../api'

export const SensorVirtualEdit = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const { data: sensorPool, isLoading: isSensorPoolLoading } = useSensorPool()
  const {
    data: virtualSensorPool,
    isError: isVirtualSensorPoolError,
    isLoading: isVirtualSensorPoolLoading,
  } = useVirtualSensorPool()

  const [actionMenu, setActionMenu] = useState(null)
  const [name, setName] = useState('')
  const [virtualSensorType, setVirtualSensorType] = useState('')
  const [originalSensorId, setOriginalSensorId] = useState([])

  const [combineId, setCombineId] = useState('')
  const [combinedVirtualSensorId, setCombinedVirtualSensorId] = useState('')
  const [
    combinedVirtualSensorDeviceId,
    setCombinedVirtualSensorDeviceId,
  ] = useState('')

  useEffect(
    () => {
      if (!isVirtualSensorPoolLoading && !isSensorPoolLoading) {
        const record = virtualSensorPool.filter(
          f => Number(f.id) === Number(id)
        )

        if (record && record[0]) {
          const {
            original_sensors_id,
            virtual_sensor_type,
            combine_id,
            combined_virtual_sensor_id,
            combined_virtual_sensor_device_id,
          } = record[0].attributes

          const dbSensor = sensorPool.find(
            s => s.attributes.entity_id === combined_virtual_sensor_id
          )

          const { name } = dbSensor.attributes
          const array = original_sensors_id.replace(/\s/g, '').split(',')
          const sensorsArray = []

          array.forEach(s => {
            const actualSensor = sensorPool.find(sensor => sensor.id === s)

            if (actualSensor)
              sensorsArray.push({
                value: actualSensor.attributes.entity_id,
                label: `${actualSensor.attributes.name}:${
                  actualSensor.attributes.device_id
                }:${actualSensor.attributes.sensor_type}`,
                sensor_type: `${actualSensor.attributes.sensor_type}`,
              })
          })

          setName(name)
          setVirtualSensorType(virtual_sensor_type)
          setOriginalSensorId(sensorsArray)
          setCombineId(combine_id)
          setCombinedVirtualSensorId(combined_virtual_sensor_id)
          setCombinedVirtualSensorDeviceId(combined_virtual_sensor_device_id)
        }
      }
    },
    [
      isSensorPoolLoading,
      id,
      sensorPool,
      isVirtualSensorPoolLoading,
      virtualSensorPool,
    ]
  )

  if (originalSensorId.length > 0 && virtualSensorType === '')
    setVirtualSensorType(originalSensorId[0].sensor_type)

  const sensorList = sensorPool
    ? sensorPool.filter(f => f.attributes.name !== null)
    : []

  if (sensorList.length > 0) sensorList.sort(SORT_ATTR_NAME)

  const isPageLoading = isVirtualSensorPoolLoading || isSensorPoolLoading

  return (
    <>
      {/* preloader */}
      <Preloader
        caption={'Virtuelle Sensoren laden ...'}
        error={isVirtualSensorPoolError}
        isLoading={isPageLoading}
      />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Sensoren'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'virtualsensors'}
      >
        <AMItem
          caption={'Neu'}
          icon={<MemoryTwoTone />}
          onClick={() => history.push('/virtualsensors/new')}
        />

        <Divider />
      </AM>
      {/* action menu end */}

      {!isPageLoading && (
        <KPage
          avatar={
            <Avatar>
              <SettingsRemoteTwoTone />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={`Virtueller Sensor ${id ? 'bearbeiten' : 'hinzufügen'}`}
        >
          <Grid container>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Sensoren</InputLabel>
                <Select
                  autoWidth
                  multiple
                  onChange={event => setOriginalSensorId(event.target.value)}
                  renderValue={selected => (
                    <Box sx={{ flexWrap: 'wrap', display: 'flex' }}>
                      {selected.map((value, key) => {
                        return (
                          <Chip
                            key={key}
                            label={value.label}
                            onDelete={() => {
                              setOriginalSensorId(chips =>
                                chips.filter(f => f.value !== value.value)
                              )
                            }}
                            onMouseDown={event => event.stopPropagation()}
                          />
                        )
                      })}
                    </Box>
                  )}
                  value={originalSensorId || ''}
                >
                  {sensorList.map((item, key) => {
                    const { device_id, name, sensor_type } = item.attributes

                    if (
                      name &&
                      sensor_type !== 'temperature' &&
                      sensor_type !== 'co2' &&
                      sensor_type !== 'humidity' &&
                      sensor_type !== 'light' &&
                      sensor_type !== 'waterdetector' &&
                      sensor_type !== 'waterdetected' &&
                      sensor_type !== 'vdd' &&
                      sensor_type !== 'motion'
                    ) {
                      return (
                        !originalSensorId
                          .map(sensor => Number(sensor.value))
                          .includes(Number(item.id)) && (
                          <MenuItem
                            key={key}
                            value={{
                              value: item.id,
                              label: `${name}:${device_id}:${sensor_type}`,
                              sensor_type: `${sensor_type}`,
                            }}
                          >
                            {name}:{device_id}:{sensor_type}
                          </MenuItem>
                        )
                      )
                    }

                    return null
                  })}
                </Select>
              </FormControl>

              <KTextField
                label={'Name'}
                onChange={event => setName(event.target.value)}
                value={name}
              />
            </Grid>
          </Grid>

          {!id ? (
            <Button
              onClick={() => {
                const sensors = []
                originalSensorId.forEach(s => sensors.push(s.value))
                const sensorsString = sensors.join(', ')

                dispatch(
                  virtualSensorsActions.save({
                    originalSensorId: sensorsString,
                    name,
                    sensor_type: virtualSensorType,
                  })
                )
              }}
            >
              Hinzufügen
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  const sensors = []
                  originalSensorId.forEach(s => sensors.push(s.value))
                  const sensorsString = sensors.join(',')

                  dispatch(
                    virtualSensorsActions.update({
                      originalSensorId: sensorsString,
                      name,
                      sensor_type: virtualSensorType,
                      combine_id: combineId,
                      combined_virtual_sensor_id: combinedVirtualSensorId,
                      combined_virtual_sensor_device_id: combinedVirtualSensorDeviceId,
                    })
                  )
                }}
              >
                Aktualisieren
              </Button>
              <Button
                color={'secondary'}
                onClick={() => dispatch(virtualSensorsActions.remove(id))}
              >
                Löschen
              </Button>
            </>
          )}
        </KPage>
      )}
    </>
  )
}
