// modules
import {
  AppBar,
  Box,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
// scripts
import { LoginPage, UserRegister } from '../pages'
// assets
import bgImage from '../assets/img/background.jpg'

const routes = [
  {
    component: UserRegister,
    hidden: true,
    name: 'Registrieren',
    path: '/pages/register-page/:status',
    short: 'Registrieren',
  },
  {
    component: UserRegister,
    name: 'Registrieren',
    path: '/pages/register-page',
    short: 'Registrieren',
  },
  {
    component: LoginPage,
    name: 'Anmeldung',
    path: '/pages/login-page',
    short: 'Anmeldung',
  },
  {
    name: 'Registrieren',
    path: '/pages',
    pathTo: '/pages/register-page',
    redirect: true,
  },
]

export const Page = ({ location }) => {
  const theme = useTheme()
  const { white: color } = theme.palette

  const isActive = route => location.pathname.indexOf(route) > -1

  useEffect(() => {
    document.body.style.overflow = 'unset'
  }, [])

  return (
    <>
      <AppBar sx={{ backgroundColor: 'transparent' }}>
        <Toolbar>
          <List sx={{ display: 'flex', flexDirection: 'row' }}>
            {routes.filter(f => f.hidden !== true).map((route, key) => {
              const { path, redirect, short } = route

              if (redirect) return null

              return (
                <NavLink key={key} to={path}>
                  <ListItem
                    sx={{
                      backgroundColor: isActive(path)
                        ? 'rgba(255, 255, 255, 0.1)'
                        : 'none',
                      color,
                      textTransform: 'uppercase',
                    }}
                  >
                    <ListItemText disableTypography={true} primary={short} />
                  </ListItem>
                </NavLink>
              )
            })}
          </List>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          display: 'flex!important',
          minHeight: '100vh',
        }}
      >
        <Switch>
          {routes.map((item, key) => {
            const { collapse, component, path, pathTo, redirect } = item

            if (collapse) return null
            if (redirect) return <Redirect from={path} to={pathTo} key={key} />

            return <Route path={path} component={component} key={key} />
          })}
        </Switch>

        <Typography
          sx={{
            bottom: 0,
            color,
            position: 'absolute',
            textAlign: 'center',
            width: '100%',
          }}
        >
          Copyright &copy; {new Date(Date.now()).getFullYear()} KARMA. Die
          digitale Lösung für jedes Gebäude.
        </Typography>
      </Box>
    </>
  )
}

Page.propTypes = { location: PropTypes.object }
