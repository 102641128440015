import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../actions'
import {
  FILTER_TYPE_CONTRACT,
  FILTER_TYPE_FOLDER,
  FILTER_TYPE_SENSOR,
} from '../api'

export const useUser = id => {
  const dispatch = useDispatch()

  const [data, setData] = useState(null)
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const { userById } = useSelector(state => state.user)

  useEffect(
    () => {
      if (Number(id) > 0) {
        setLoading(true)
        dispatch(userActions.getUser(id))
      }
    },
    [dispatch, id]
  )

  useEffect(
    () => {
      const { error, item } = userById

      if (item) {
        const { data, included } = item
        const { attributes } = data

        setData({
          ...attributes,
          folders: included
            ? included
                .filter(FILTER_TYPE_FOLDER)
                .map(folder => folder.attributes)
            : [],
          contracts: included
            ? included
                .filter(FILTER_TYPE_CONTRACT)
                .map(contract => contract.attributes)
            : [],
          sensors: included
            ? included
                .filter(FILTER_TYPE_SENSOR)
                .map(sensor => Number(sensor.id))
            : [],
        })
        setLoading(false)
      }

      if (error) {
        const { message } = error

        setError(message)
        setLoading(false)
      }
    },
    [userById]
  )

  return { isLoading, isError, data, setData }
}
