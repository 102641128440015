import { history } from '../api'
import { sensorPriceConstants as SPC } from '../constants'
import { sensorPriceService as SPS, tokenService as TS } from '../services'
import { alertActions as AA } from '.'

const remove = id => {
  const failure = error => ({ type: SPC.DELETE_SENSOR_PRICE_ERROR, error })
  const request = () => ({ type: SPC.DELETE_SENSOR_PRICE_REQUEST })
  const success = sensorprice => ({
    type: SPC.DELETE_SENSOR_PRICE_SUCCESS,
    sensorprice,
  })

  return dispatch => {
    if (!id) return dispatch(AA.error(SPC.SENSORPRICE_ID_REQUIRED))

    dispatch(request())

    SPS.deleteSensorPrice(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(SPC.SENSORPRICE_DELETED))

        history.push('/system/custom-notifications')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const deleteSensorPrice = id => {
  const failure = error => ({ type: SPC.DELETE_SENSOR_PRICE_ERROR, error })
  const request = () => ({ type: SPC.DELETE_SENSOR_PRICE_REQUEST })
  const success = sensorprice => ({
    type: SPC.DELETE_SENSOR_PRICE_SUCCESS,
    sensorprice,
  })

  return dispatch => {
    if (!id) return dispatch(AA.error(SPC.SENSORPRICE_ID_REQUIRED))

    dispatch(request())

    SPS.deleteSensorPrice(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(SPC.SENSORPRICE_ID_REQUIRED))

        history.push('/folder/price')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const getAll = () => {
  const failure = error => ({ type: SPC.GET_ALL_ERROR, error })
  const request = () => ({ type: SPC.GET_ALL_REQUEST })
  const success = sensorprice => ({ type: SPC.GET_ALL_SUCCESS, sensorprice })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() => {
      SPS.getAll()
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    })
  }
}

const recordData = data => {
  const { entity_id, sensor_id, price_for_year, price } = data
  return {
    data: {
      attributes: {
        entity_id: entity_id,
        sensor_id: sensor_id,
        price_for_year: price_for_year,
        price: price,
      },
    },
  }
}

const save = data => {
  const failure = error => ({ type: SPC.SAVE_SENSOR_PRICE_ERROR, error })
  const request = () => ({ type: SPC.SAVE_SENSOR_PRICE_REQUEST })
  const success = sensorprice => ({
    type: SPC.SAVE_SENSOR_PRICE_SUCCESS,
    sensorprice,
  })

  const saveSensorPriceData = data => {
    return dispatch => {
      dispatch(request())

      SPS.save(recordData(data))
        .then(result => {
          dispatch(success(result))
          dispatch(AA.success(SPC.SENSORPRICE_SAVED))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    }
  }

  const { sensor_id } = data

  return dispatch => {
    if (!sensor_id) return dispatch(AA.error(SPC.SENSORPRICE_SENSOR_REQUIRED))

    dispatch(saveSensorPriceData(data))
  }
}

const update = data => {
  const failure = error => ({ type: SPC.UPDATE_SENSOR_PRICE_ERROR, error })
  const request = () => ({ type: SPC.UPDATE_SENSOR_PRICE_REQUEST })
  const success = sensorprice => ({
    type: SPC.UPDATE_SENSOR_PRICE_SUCCESS,
    sensorprice,
  })

  const updateData = data => {
    return dispatch => {
      dispatch(request())

      SPS.update(recordData(data))
        .then(result => {
          dispatch(success(result))
          dispatch(AA.success(SPC.SENSORPRICE_UPDATED))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    }
  }

  const { sensor_id } = data

  return dispatch => {
    if (!sensor_id) return dispatch(AA.error(SPC.SENSORPRICE_SENSOR_REQUIRED))

    dispatch(updateData(data))
  }
}

export const sensorPriceActions = {
  remove,
  deleteSensorPrice,
  getAll,
  save,
  update,
}
