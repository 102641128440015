// modules
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
// scripts
import { categoryByType, normalizedSensorType, SensorIcon } from '../../../api'
import { svgConstants as SVG } from '../../../constants'

const CONSUMPTION_MEDIUM_ENERGY = 'energy'
const CONSUMPTION_MEDIUM_HEATING = 'gas'
const CONSUMPTION_MEDIUM_WATER = 'water'

export const SensorItem = ({ sensor, onClick, isActive, isAdmin }) => {
  const {
    device_id,
    entity_id,
    name,
    meter_number,
    provider,
    sensor_target,
    sensor_type,
    sub_sensor_target,
    type_in_folder,
  } = sensor

  let image_url = SensorIcon(categoryByType(sensor_type, type_in_folder))
  const normalized = normalizedSensorType(sensor_type, type_in_folder)

  switch (normalized) {
    case CONSUMPTION_MEDIUM_ENERGY:
      image_url = SVG.MEDIUM_ENERGY
      break
    case CONSUMPTION_MEDIUM_WATER:
      image_url = SVG.MEDIUM_WATER
      break
    case CONSUMPTION_MEDIUM_HEATING:
      image_url = SVG.MEDIUM_HEATING
      break
    default:
      console.error('unhandled normalizedSensorType', normalized)
      break
  }

  const adminInfo = `Sensor ID: ${entity_id}`

  return (
    <ListItemButton onClick={onClick} value={entity_id}>
      <ListItemAvatar>
        <Avatar alt={''} src={image_url} />
      </ListItemAvatar>

      <ListItemText
        sx={{ width: 1 / 2 }}
        primary={`Name: ${name}`}
        secondary={isAdmin && adminInfo}
      />

      <ListItemText
        sx={{ width: 1 / 4 }}
        primary={'Zählernummer'}
        secondary={meter_number || 'n/a'}
      />

      {isAdmin && (
        <ListItemText
          sx={{ width: 1 / 4 }}
          primary={'Typ Casting'}
          secondary={type_in_folder}
        />
      )}

      {isAdmin && (
        <ListItemText
          sx={{ width: 1 / 4 }}
          primary={'Target'}
          secondary={`${sensor_target}::${sub_sensor_target}`}
        />
      )}

      {isAdmin && (
        <ListItemText
          sx={{ width: 1 / 2 }}
          primary={`Hardware Information`}
          secondary={`${provider}::${device_id}::${sensor_type}`}
        />
      )}

      <ListItemIcon>
        <Switch checked={isActive} />
      </ListItemIcon>
    </ListItemButton>
  )
}

SensorItem.propTypes = {
  sensor: PropTypes.object,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isAdmin: PropTypes.bool,
}
