import { Alert, AlertTitle, Collapse, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const ReportNotAvailable = ({ folderName, condition }) => {
  const theme = useTheme()
  const borderColor = theme.palette.error.main
  const border = `solid 1px ${borderColor}`

  return (
    <Collapse in={condition} sx={{ mx: 1, my: 2 }}>
      <Alert severity={'error'} sx={{ border }}>
        <AlertTitle>Berichterstellung nicht verfügbar</AlertTitle>
        Das Gebäude <strong>{folderName}</strong> enthält keine
        Verbrauchssensoren.
      </Alert>
    </Collapse>
  )
}

ReportNotAvailable.propTypes = {
  folderName: PropTypes.string,
  condition: PropTypes.bool,
}
