import { virtualSensorConstants as VSC } from '../constants'

const initialState = { allVirtualSensor: { loading: true } }

export const virtualSensor = (state = initialState, action) => {
  const { error, type, virtualSensor } = action

  switch (type) {
    case VSC.DELETE_VIRTUAL_SENSOR_REQUEST:
      return { ...state, deleteVirtualSensor: { loading: true } }

    case VSC.DELETE_VIRTUAL_SENSOR_SUCCESS:
      return {
        ...state,
        deleteVirtualSensor: { item: virtualSensor, loading: false },
      }

    case VSC.DELETE_VIRTUAL_SENSOR_ERROR:
      return {
        ...state,
        deleteVirtualSensor: { error, loading: true },
      }

    case VSC.GET_ALL_REQUEST:
      return { ...state, allVirtualSensor: { loading: true } }

    case VSC.GET_ALL_SUCCESS:
      return {
        ...state,
        allVirtualSensor: { item: virtualSensor, loading: false },
      }

    case VSC.GET_ALL_ERROR:
      return {
        ...state,
        allVirtualSensor: { error, loading: true },
      }

    case VSC.SAVE_VIRTUAL_SENSOR_REQUEST:
      return { ...state, save: { loading: true } }

    case VSC.SAVE_VIRTUAL_SENSOR_SUCCESS:
      return {
        ...state,
        save: { item: virtualSensor, loading: false },
      }

    case VSC.SAVE_VIRTUAL_SENSOR_ERROR:
      return {
        ...state,
        save: { error, loading: true },
      }

    default:
      return state
  }
}
