// modules
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import { MoreHorizTwoTone, MoreVertTwoTone } from '@mui/icons-material'
import React from 'react'

export const StackSelector = ({ ...rest }) => {
  const data = [
    { icon: <MoreHorizTwoTone />, tooltip: 'horizontal stapeln' },
    { icon: <MoreVertTwoTone />, tooltip: 'vertikal stapeln' },
  ]

  return (
    <ToggleButtonGroup exclusive {...rest}>
      {data.map((item, key) => {
        const { icon, tooltip } = item

        return (
          <ToggleButton key={key} value={Boolean(key)}>
            <Tooltip title={tooltip}>{icon}</Tooltip>
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}
