import { ExpandLessTwoTone, ExpandMoreTwoTone } from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { ASC, DESC } from '../../../api'

export const NotificationSortSelector = ({
  data = [
    { label: 'Name', value: 'name' },
    { label: 'ID', value: 'entity_id' },
  ],
  onChange,
  order,
  orderby,
}) => (
  <ToggleButtonGroup exclusive onChange={onChange} value={orderby}>
    {data.map((item, key) => (
      <ToggleButton key={key} value={item.value}>
        {item.label}
        {item.value === orderby && order === ASC && <ExpandLessTwoTone />}
        {item.value === orderby && order === DESC && <ExpandMoreTwoTone />}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
)

NotificationSortSelector.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  order: PropTypes.string,
  orderby: PropTypes.string,
}
