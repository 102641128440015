import {
  AccountBalanceTwoTone,
  AddTwoTone,
  AnnouncementTwoTone,
  EditTwoTone,
  MoreVertTwoTone,
} from '@mui/icons-material'
import {
  Avatar,
  Badge,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import matchSorter from 'match-sorter'
import React, { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFilters, useSortBy, useTable } from 'react-table'
import { ASC, DESC, WDDDMMYYYYHHMMSS, userLocales } from '../../api'
import { AM, AMItem, KPage, Preloader } from '../../components'
import {
  useContractPool,
  useFolderPool,
  useNews,
  useOperator,
} from '../../hooks'
import {
  contractColumns,
  folderColumns,
  tableRowContract,
  tableRowFolder,
} from './Single.table'
import { DefaultColumnFilter } from '../../components'

export const NewsSingle = () => {
  const history = useHistory()
  const { id: newsId, nc } = useParams()
  const { data: operator } = useOperator()
  const { data: folderPool } = useFolderPool()
  const {
    data: contractPool,
    isError: isContractPoolError,
    isLoading: isContractPoolLoading,
  } = useContractPool()
  const { data: news, error: newsError, loading: newsLoading } = useNews(newsId)

  const [actionMenu, setActionMenu] = useState(null)
  const isPageError = newsError || isContractPoolError
  const isPageLoading = newsLoading || isContractPoolLoading

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns: useMemo(
        () => (news?.folder_id === null ? contractColumns : folderColumns),
        [news]
      ),
      data: useMemo(
        () => {
          if (news?.folder_id === null) {
            return contractPool
              .filter(contract =>
                news?.contracts
                  .map(nc => String(nc.entity_id))
                  .includes(contract.id)
              )
              .map(contract => tableRowContract(contract))
          } else {
            return folderPool
              .filter(folder => Number(folder.id) === Number(news?.folder_id))
              .map(folder => tableRowFolder(folder))
          }
        },
        [news, contractPool, folderPool]
      ),
      defaultColumn: useMemo(() => ({ Filter: DefaultColumnFilter }), []),
      initialState: { sortBy: [{ id: 'name', asc: true }] },
      filterTypes: {
        text: (rows, id, filterValue) =>
          matchSorter(rows, filterValue, { keys: [id] }),
      },
    },
    useFilters,
    useSortBy
  )

  return (
    <>
      {/* preloader */}
      <Preloader error={isPageError} isLoading={isPageLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'News'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
        historyUrlTarget={'news'}
      >
        <AMItem
          caption={'Neu'}
          icon={<AddTwoTone />}
          onClick={() => history.push('/news/new')}
        />

        {operator?.isAdmin && (
          <AMItem
            caption={'Bearbeiten'}
            icon={<EditTwoTone />}
            onClick={() => history.push(`/news/${newsId}/edit`)}
          />
        )}
      </AM>
      {/* action menu end */}

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={event => setActionMenu(event.currentTarget)}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar>
              <AnnouncementTwoTone />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={`News: ${news?.title}`}
        >
          <dl>
            <dt>ID</dt>
            <dd>{newsId}</dd>

            <dt>Autor</dt>
            <dd>
              {nc === '1' ? news?.headline : news?.author ? news.author : '-'}
            </dd>

            <dt>Datum</dt>
            <dd>
              {new Date(news?.created_at).toLocaleString(
                userLocales,
                WDDDMMYYYYHHMMSS
              )}
            </dd>

            <dt>Inhalt</dt>
            <dd>
              <div dangerouslySetInnerHTML={{ __html: news?.content_text }} />
            </dd>
          </dl>

          {news?.folder_id !== null && (
            <>
              <Badge
                badgeContent={
                  !isPageLoading
                    ? folderPool.filter(
                        folder => Number(folder.id) === Number(news?.folder_id)
                      ).length
                    : null
                }
                color={'primary'}
                sx={{ mr: 2 }}
              >
                <AccountBalanceTwoTone />
              </Badge>
              Gebäude
            </>
          )}

          {news?.folder_id === null && (
            <>
              <Badge
                badgeContent={isPageLoading ? null : news?.contracts?.length}
                color={'primary'}
                sx={{ mr: 2 }}
              >
                <AccountBalanceTwoTone />
              </Badge>
              Verträge
            </>
          )}

          <TableContainer sx={{ border: 'solid 1px #DDD', borderRadius: 2 }}>
            <Table sx={{ width: '100%' }} {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup, key) => (
                  <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, innerKey) => (
                      <TableCell
                        key={innerKey}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? DESC : ASC}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {rows.map((row, key) => {
                  prepareRow(row)

                  const onClick = () => {
                    const urlChunk =
                      news?.folder_id === null ? 'contracts' : 'folder'
                    const id = row.original.id

                    history.push(`/${urlChunk}/${id}`)
                  }

                  return (
                    <TableRow
                      key={key}
                      onClick={onClick}
                      sx={{ cursor: 'pointer' }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell, innerKey) => (
                        <TableCell key={innerKey} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </KPage>
      )}
    </>
  )
}
