/* preloader */

import { HomeTwoTone, RefreshTwoTone } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Tooltip,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const Preloader = ({
  caption = 'laden...',
  isLoading,
  error = null,
}) => {
  const theme = useTheme()
  const { white: color } = theme.palette

  const history = useHistory()

  // handler

  const gotoHome = () => history.push('/')
  const reloadPage = () => document.location.reload(true)

  return (
    <Dialog open={isLoading}>
      <DialogTitle variant={'preloader'}>
        {error ? 'API Fehler' : caption}
      </DialogTitle>

      <Collapse in={isLoading && !error}>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Collapse>

      <Collapse in={error}>
        <DialogContent sx={{ py: 0 }}>
          <Alert severity={'error'}>
            <AlertTitle>{error}</AlertTitle>
          </Alert>
        </DialogContent>

        <DialogActions>
          <Tooltip title={'Startseite'}>
            <IconButton onClick={gotoHome} sx={{ color }}>
              <HomeTwoTone />
            </IconButton>
          </Tooltip>

          <Tooltip title={'Wiederholen'}>
            <IconButton onClick={reloadPage} sx={{ color }}>
              <RefreshTwoTone />
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Collapse>
    </Dialog>
  )
}

Preloader.propTypes = {
  caption: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
}
