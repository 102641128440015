import { API } from 'aws-amplify'
import axios from 'axios'
import { authHeader } from '../api'

const API_NAME = 'karmaApi'

const { REACT_APP_API_URL } = process.env

const ERR_MISSING_NEWS = new Error('Keine News gewählt')
const ERR_INVALID_NEWS = new Error('Ungültige News')

const getAll = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/news/admin/news`,
  }

  try {
    const response = await axios(config)

    return response.data
  } catch (error) {
    const { config, message, request, response } = error

    // request was made; server responded with status code other than 2xx
    if (response) {
      const { data, status } = response
      const { message } = data

      console.log(data)
      console.log(status)

      return Promise.reject({ status, message })
    }
    // request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in browser and an instance of http.ClientRequest in node.js
    else if (request) {
      console.log(request)
    }
    // something happened in setting up the request that triggered an Error
    else {
      console.log('Error', message)
    }

    console.log(config)
  }
}

const getAllAlerts = async (includes = '') => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/messages${includes}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    const { config, message, request, response } = error

    // request was made; server responded with status code other than 2xx
    if (response) {
      const { data, status } = response
      const { message } = data
      console.log(data)
      console.log(status)

      return Promise.reject({ status, message })
    }
    // request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in browser and an instance of http.ClientRequest in node.js
    else if (request) {
      console.log(request)
    }
    // something happened in setting up the request that triggered an Error
    else {
      console.log('Error', message)
    }

    console.log(config)
  }
}

const getAllNewsTypes = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/news/admin/news-types`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('newsService.getAllNewsTypes', error)
  }
}

const getById = async (id, includes = []) => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_NEWS)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_NEWS)

  let request = id
  if (includes.length > 0) {
    request += '?include='
    includes.forEach(val => {
      request += encodeURIComponent(val)
      request += ','
    })
  }

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/news/admin/news/${request}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('newsService.getById', error)
  }
}

const remove = async id => {
  const config = {
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/news/admin/news/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('newsService.remove', error)
  }
}

const save = async body => {
  const endpoint = '/news/admin/news'
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'POST',
  }

  return await API.post(API_NAME, endpoint, options)
}

export const save2 = async body => {
  const config = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'POST',
    url: `${REACT_APP_API_URL}/news/admin/news`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('newsService.save', error)
  }
}

const update = async (id, body) => {
  const endpoint = `/news/admin/news/${id}`
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

export const update2 = async (id, body) => {
  const config = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    url: `${REACT_APP_API_URL}/news/admin/news/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('newsService.update', error)
  }
}

export const newsService = {
  getAll,
  getAllAlerts,
  getAllNewsTypes,
  getById,
  remove,
  save,
  update,
}
