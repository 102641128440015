// modules
import {
  ArrowBackIosTwoTone,
  ArrowForwardIosTwoTone,
  ExpandMoreTwoTone,
  ImportExportTwoTone,
  NotificationImportantTwoTone,
  SaveTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  MenuItem,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { TimeSeries } from 'pondjs'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import ReactExport from 'react-data-export'
import { useParams } from 'react-router-dom'
import {
  Baseline,
  ChartContainer,
  ChartRow,
  Charts,
  Legend,
  LineChart,
  Resizable,
  styler,
  YAxis,
} from 'react-timeseries-charts'
// scripts
import {
  addWeekdays,
  colorSchemeConsumption,
  colorSchemeFeeling,
  colorSchemeSecurity,
  DDMMYY,
  DDMMYYYYHHMM,
  DMMMMYYYY,
  FILTER_TARGET_NOT_PERFORMANCE,
  FILTER_TARGET_NOT_SECURITY,
  FILTER_TARGET_SECURITY,
  FILTER_SUB_SENSOR,
  germanDecimals,
  noDecimals,
  getDaysArray,
  getHoursArray,
  getMonthsArray,
  getSession,
  MMMYY,
  SensorIcon,
  sensorUnit,
  sensorType,
  setSession,
  SORT_NAME,
  userLocales,
  WDDDMMYY,
  WDDDMMYYYYHHMMSS,
  getStatusColor,
  daysAgo,
  daysOnward,
  normalizedSensorType,
} from '../../api'
import { AM, AMItem, Preloader } from '../../components'
import {
  useAppConfig,
  useConsumptionByFolderId,
  useFolder,
  useSensorPool,
  useTimeSeries,
  useTimeSeriesByFeelingTypes,
} from '../../hooks'
import {
  CorrelationConsumptionItem,
  CorrelationFeelingItem,
  CorrelationSecurityItem,
} from './components'
import PropTypes from 'prop-types'

const germanDate = date =>
  new Date(date).toLocaleDateString(userLocales, DMMMMYYYY)

// if given date is in future, return current date
const limitTodayMax = date =>
  date > new Date(Date.now()).toISOString().split('T')[0]
    ? new Date(Date.now()).toISOString().split('T')[0]
    : date

export const Correlation = ({ isCorrelational }) => {
  const theme = useTheme()
  const { white: bgcolor } = theme.palette

  const { id: folderId } = useParams()
  const PAGE_CONFIG = 'settings_correlation'
  const [config, setConfig] = useState(getSession(PAGE_CONFIG))
  const { global } = useAppConfig()
  const { salutationMode } = global

  const [consumptionSensors, setConsumptionSensors] = useState([])
  const [feelingSensors, setFeelingSensors] = useState([])
  const [securitySensors, setSecuritySensors] = useState([])

  // data
  const [activeSensors, setActiveSensors] = useState([])
  const [activeFeelingSensors, setActiveFeelingSensors] = useState([])
  const [feelingSensorId, setFeelingSensorId] = useState(null)

  //KAR 1103
  const period = 'day'
  const toDate =
    new Date(config.toDate).setHours(0, 0, 0, 0) ===
    new Date().setHours(0, 0, 0, 0)
      ? new Date()
      : `${config.toDate} 23:59:59`

  const dataTimeRange = {
    ui: { period, min_period: -10000, max_period: 10000 },
    widget: [
      {
        type: 'bid',
        data: [
          [Date.parse(`${config.fromDate} 00:00:00`), -10000],
          [Date.parse(toDate), 10000],
        ],
        display: 'absolute',
      },
    ],
  }

  const pointsTimeRange = dataTimeRange.widget[0].data
  const requestsSeries = new TimeSeries({
    name: 'Temperature',
    columns: ['time', 'temp'],
    points: pointsTimeRange,
  })

  const [dataEnergy, setDataEnergy] = useState([])
  const [dataFeeling, setDataFeeling] = useState([])
  const [lineCharts, setLineCharts] = useState([])
  const [showFirstAxis, setShowFirstAxis] = useState(false)
  const [showSecondAxis, setShowSecondAxis] = useState(false)
  const [style, setStyle] = useState([])
  const [legendStyle, setLegendStyle] = useState({})
  const [legend, setLegend] = useState([])
  const [maxConsumption, setMaxConsumption] = useState()
  const [minFeeling, setMinFeeling] = useState(0)
  const [maxFeeling, setMaxFeeling] = useState(100)
  const [allSeries, setAllSeries] = useState([])
  const [tracker, setTracker] = useState()
  const [trackerX, setTrackerX] = useState()
  const [mouseX, setMouseX] = useState()
  const [mouseY, setMouseY] = useState()
  const [consumptionLabel, setConsumptionLabel] = useState('')
  const [feelingLabel, setFeelingLabel] = useState('')

  const [timeSeriesDataList, setTimeSeriesDataList] = useState({ items: [] })
  const [timeSeriesDataListLoading, setTimeSeriesDataListLoading] = useState(
    false
  )
  const [timeSeriesDataSensorId, setTimeSeriesDataSensorId] = useState()
  const [feelingTypeLoading, setFeelingTypeLoading] = useState(false)
  const [timeRange, setTimeRange] = useState(requestsSeries.range())

  // period warning
  const [periodWarning, setPeriodWarning] = useState(null)

  // KPIs
  const [timeSpan, setTimeSpan] = useState('')
  const [periodInDays, setPeriodInDays] = useState()

  // export
  const [exportModal, setExportModal] = useState(false)
  const [fileName, setFileName] = useState('')
  const [tableDataExport, setTableDataExport] = useState([])
  const [tableDataExportExcel, setTableDataExportExcel] = useState({
    Korrelation: [],
  })

  const [limitModal, setLimitModal] = useState(false)
  const [baselines, setBaselines] = useState([])

  const [baselineFeelingSensors, setBaselineFeelingSensors] = useState([])
  const [
    activeBaselineFeelingSensors,
    setActiveBaselineFeelingSensors,
  ] = useState([])
  const [disableBaselineSwitch, setDisableBaselineSwitch] = useState(false)

  const { ExcelFile } = ReactExport
  const { ExcelSheet } = ExcelFile

  const [operatorSensorIds, setOperatorSensorIds] = useState([])
  const [
    operatorHasMainEnergySensor,
    setOperatorHasMainEnergySensor,
  ] = useState(true)
  const [operatorHasMainWaterSensor, setOperatorHasMainWaterSensor] = useState(
    true
  )
  const [operatorHasMainGasSensor, setOperatorHasMainGasSensor] = useState(true)

  const [actionMenu, setActionMenu] = useState(null)

  const [range, setRange] = useState({
    fromDate: config.fromDate,
    toDate: config.toDate,
    granularity: config.granularity,
  })

  const granularityData = [
    { value: 'd', label: 'Stündlich' },
    { value: 'm', label: 'Täglich' },
  ]

  const {
    data: folderData,
    isError: folderError,
    isLoading: folderLoading,
  } = useFolder(folderId)

  useEffect(() => setSession(PAGE_CONFIG, config), [config])

  useEffect(
    () =>
      document.addEventListener('mousemove', event => {
        setMouseX(event.pageX)
        setMouseY(event.pageY)
      }),
    []
  )

  useEffect(
    () => {
      if (folderData) {
        const { name, sensors } = folderData

        if (name) setFileName(`${name}_Korrelation`)

        if (sensors) {
          setSecuritySensors(
            sensors
              .filter(FILTER_TARGET_NOT_PERFORMANCE)
              .filter(FILTER_TARGET_SECURITY)
              .sort(SORT_NAME)
          )

          setFeelingSensors(
            sensors
              .filter(FILTER_TARGET_NOT_PERFORMANCE)
              .filter(FILTER_TARGET_NOT_SECURITY)
              .sort(SORT_NAME)
          )
        }
      }
    },
    [folderData]
  )

  const {
    data: consumptionData,
    isLoading: consumptionLoading,
  } = useConsumptionByFolderId(
    folderId,
    config.granularity,
    config.fromDate,
    config.toDate
  )

  const { data: timeSeriesData, isLoading: timeSeriesLoading } = useTimeSeries(
    feelingSensorId,
    config.fromDate + ' 00:00:00',
    config.toDate + ' 23:59:59',
    config.granularity
  )

  const {
    timeSeries: timeSeriesDataByFeelingTypes,
    isLoading: timeSeriesDataByFeelingTypesLoading,
  } = useTimeSeriesByFeelingTypes(
    folderData,
    config.fromDate + ' 00:00:00',
    config.toDate + ' 23:59:59'
  )

  useEffect(
    () => {
      if (!timeSeriesDataByFeelingTypesLoading)
        setTimeSeriesDataList(timeSeriesDataByFeelingTypes)
    },
    [timeSeriesDataByFeelingTypesLoading, timeSeriesDataByFeelingTypes]
  )

  // KAR-1103
  useEffect(
    () => {
      if (!timeSeriesDataListLoading) {
        const style = []
        const legend = []
        const legendStyle = {}
        const labelStyle = {
          normal: {
            fontSize: 'normal',
            color: '#000',
            cursor: 'default',
            marginRight: 5,
          },
        }
        const consumptionTypes = []
        const feelingTypes = []

        const valueStyle = {
          normal: { fontSize: 'normal', color: '#000', cursor: 'default' },
        }

        consumptionSensors.map((sensor, key) => {
          const { entity_id, name, type_in_folder } = sensor
          const isActive = activeSensors.includes(entity_id)
          const colorIndex = key % colorSchemeConsumption.length
          const color = colorSchemeConsumption[colorIndex]

          if (isActive) {
            style.push({ key: entity_id, color })
            style.push({ key: name, color })
            legend.push({
              key: name + entity_id,
              label:
                name +
                ` (${sensor.unit}
              )`,
              disabled: !isActive,
            })
            legendStyle[name + entity_id] = {
              symbol: {
                normal: { stroke: color, fill: color, strokeWidth: 10 },
              },
              label: labelStyle,
              value: valueStyle,
            }
            consumptionTypes.push(type_in_folder)
          }

          return false
        })

        feelingSensors.map((sensor, key) => {
          const { entity_id, name, sensor_type } = sensor
          const colorIndex = key % colorSchemeFeeling.length
          const color = colorSchemeFeeling[colorIndex]
          const isActive = activeFeelingSensors.includes(entity_id)

          if (isActive) {
            style.push({ key: entity_id, color })
            style.push({ key: name, color })
            legend.push({
              key: name + entity_id,
              label:
                name +
                ` (${sensor.unit ? sensor.unit : ''}
              )`,
              disabled: !isActive,
            })
            legendStyle[name + entity_id] = {
              symbol: {
                normal: { stroke: color, fill: color, strokeWidth: 10 },
              },
              label: labelStyle,
              value: valueStyle,
            }
            feelingTypes.push(sensor_type)
          }

          return true
        })

        securitySensors.map((sensor, key) => {
          const { entity_id, name, sensor_type, unit } = sensor
          const colorIndex = key % colorSchemeSecurity.length
          const color = colorSchemeSecurity[colorIndex]
          const isActive = activeFeelingSensors.includes(entity_id)

          if (isActive) {
            style.push({ key: entity_id, color, strokeWidth: 2 })
            style.push({ key: name, color })
            legend.push({
              key: name + entity_id,
              label:
                name +
                ` (${unit ? unit : ''}
              )`,
              disabled: !isActive,
            })
            legendStyle[name + entity_id] = {
              symbol: {
                normal: { stroke: color, fill: color, strokeWidth: 10 },
              },
              label: labelStyle,
              value: valueStyle,
            }
            feelingTypes.push(sensor_type)
          }

          return true
        })

        setConsumptionLabel(createAxisLabel(consumptionTypes))
        setFeelingLabel(createAxisLabel(feelingTypes))
        setStyle(styler(style))
        setLegend(legend)
        setLegendStyle(legendStyle)
      }
    },
    [
      activeFeelingSensors,
      activeSensors,
      consumptionSensors,
      feelingSensors,
      securitySensors,
      timeSeriesDataListLoading,
    ]
  )

  useEffect(
    () => {
      if (
        timeSeriesData &&
        !timeSeriesLoading &&
        !timeSeriesDataListLoading &&
        !feelingTypeLoading
      )
        if (!(feelingSensorId in timeSeriesDataList.items)) {
          const newItems = timeSeriesDataList.items

          newItems[feelingSensorId] = timeSeriesData
          setTimeSeriesDataList({ items: newItems })
        }
    },
    [
      feelingSensorId,
      feelingTypeLoading,
      timeSeriesData,
      timeSeriesDataList,
      timeSeriesDataListLoading,
      timeSeriesLoading,
    ]
  )

  useEffect(
    () => {
      if (feelingTypeLoading) {
        setTimeSeriesDataSensorId(feelingSensorId)

        if (timeSeriesDataSensorId === feelingSensorId)
          if (!timeSeriesLoading) setFeelingTypeLoading(false)
      }
    },
    [
      feelingSensorId,
      feelingTypeLoading,
      timeSeriesDataSensorId,
      timeSeriesLoading,
    ]
  )

  useEffect(
    () => {
      const toDate =
        new Date(config.toDate).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0)
          ? new Date()
          : `${config.toDate} 23:59:59`

      const dataTimeRange = {
        ui: {
          period: period,
          min_period: -10000,
          max_period: 10000,
        },
        widget: [
          {
            type: 'bid',
            data: [
              [Date.parse(`${config.fromDate} 00:00:00`), -10000],
              [Date.parse(toDate), 10000],
            ],
            display: 'absolute',
          },
        ],
      }
      const pointsTimeRange = dataTimeRange.widget[0].data
      const requestsSeries = new TimeSeries({
        name: 'Temperature',
        columns: ['time', 'temp'],
        points: pointsTimeRange,
      })
      setTimeRange(requestsSeries.range())
    },
    [config.fromDate, config.toDate, period]
  )

  useEffect(
    () => {
      if (timeSeriesDataListLoading)
        if (!timeSeriesLoading) setTimeSeriesDataListLoading(false)
    },
    [timeSeriesLoading, timeSeriesDataListLoading]
  )

  useEffect(
    () => {
      const _charts = []
      const _seriesList = []
      let _showFirstAxis = false
      let _showSecondAxis = false

      if (!timeSeriesDataListLoading) {
        Object.keys(dataEnergy).forEach(key => {
          const seriesConsumption = new TimeSeries({
            name: key,
            columns: ['time', key],
            points: dataEnergy[key].widget[0].data,
          })

          _seriesList.push(seriesConsumption)

          _charts.push(
            <LineChart
              key={'consumption'}
              axis={'consumption'}
              series={seriesConsumption}
              columns={[key]}
              style={style}
              breakLine={true}
            />
          )

          _showSecondAxis = true
        })

        Object.keys(dataFeeling).forEach(key => {
          const seriesFeeling = new TimeSeries({
            name: key,
            columns: ['time', key],
            points: dataFeeling[key].widget[0].data,
          })

          _seriesList.push(seriesFeeling)

          _charts.push(
            <LineChart
              key={'feeling'}
              axis={'feeling'}
              series={seriesFeeling}
              columns={[key]}
              style={style}
              interpolation={'curveBasis'}
              breakLine={true}
            />
          )
          _showFirstAxis = true
        })

        setLineCharts(_charts)
        setAllSeries(_seriesList)
        setShowFirstAxis(_showFirstAxis)
        setShowSecondAxis(_showSecondAxis)
      }
    },
    [
      dataEnergy,
      dataFeeling,
      style,
      timeSeriesData,
      timeSeriesDataList,
      timeSeriesDataListLoading,
    ]
  )
  //KAR-1103
  const changeFeelingSensors = entity_id => {
    let _activeFeelingSensors = [...activeFeelingSensors]
    let _baselineFeelingSensors = [...baselineFeelingSensors]
    let _activeBaseLineFeelingSensors = [...activeBaselineFeelingSensors]

    if (_activeFeelingSensors.includes(entity_id)) {
      _activeFeelingSensors = _activeFeelingSensors.filter(f => f !== entity_id)
      delete timeSeriesDataList.items[entity_id]

      const index = _activeBaseLineFeelingSensors.findIndex(
        s => s.entity_id === entity_id
      )
      if (index !== -1) {
        _activeBaseLineFeelingSensors = _activeBaseLineFeelingSensors.filter(
          f => f.entity_id !== entity_id
        )
      }
    } else {
      _activeFeelingSensors.push(entity_id)
    }

    if (_baselineFeelingSensors.includes(entity_id)) {
      _baselineFeelingSensors = _baselineFeelingSensors.filter(
        f => f !== entity_id
      )
    } else {
      _baselineFeelingSensors.push(entity_id)
    }

    setTimeSeriesDataList({ ...timeSeriesDataList })
    setActiveFeelingSensors(_activeFeelingSensors)
    setBaselineFeelingSensors(_baselineFeelingSensors)
    setActiveBaselineFeelingSensors(_activeBaseLineFeelingSensors)
  }

  const handleTrackerChanged = (t, scale) => {
    setTracker(t)
    setTrackerX(t && scale(t))
  }

  const axisStyle = {
    label: {
      strokeOpacity: 0.8,
      fontWeight: 400,
      fontSize: '16px',
    },
    values: {
      stroke: 'none',
      fill: '#000',
      fontSize: '14px',
    },
    ticks: {
      fill: 'none',
      stroke: '#000',
      opacity: 0.2,
    },
    axis: {
      fill: 'none',
      stroke: '#AAA',
      opacity: 1,
      fontWeight: 100,
      fontSize: '14px',
    },
  }

  const itemRows = []

  if (tracker) {
    allSeries.forEach(series => {
      const id = series._data._root.entries[0][1]
      const sensor = folderData.sensors.find(
        sensor => sensor.entity_id === Number(id)
      )

      const name = sensor ? sensor.name : id
      const unit = sensor.unit ? sensor.unit : ''

      if (series.bisect(tracker)) {
        const markerIndex = series.at(series.bisect(tracker)).get(id)
        const row = (
          <>
            <br />
            {name}:{' '}
            <strong>
              {markerIndex} {unit}
            </strong>
            <br />
          </>
        )

        itemRows.push(row)
      }
    })
  }

  useEffect(
    () => {
      const { fromDate, granularity, toDate } = range

      const _1H = 60 * 60 * 1000
      const _24H = 24 * _1H

      const _periodInDays =
        (new Date(toDate).getTime() - new Date(fromDate).getTime()) / _24H

      setPeriodInDays(_periodInDays)

      setTimeSpan(`${germanDate(fromDate)} - ${germanDate(toDate)}`)

      switch (granularity) {
        case 'd':
          // timespan max a week
          setPeriodWarning(
            _periodInDays > 6
              ? 'Diese Granularität ist bei einer Auswahl von bis zu 7 Tagen verfügbar.'
              : activeSensors.length > 4
                ? 'Diese Ansicht erlaubt maximal 4 Messpunkte aus dem Bereich Leistung.'
                : null
          )
          break
        case 'm':
          // timespan max. half a year
          setPeriodWarning(
            _periodInDays > 182.625
              ? 'Diese Granularität ist bei einer Auswahl von bis zu 6 Monaten verfügbar.'
              : null
          )
          break
        case 'y':
          // no timespan limit
          setPeriodWarning(null)
          break
        default:
          break
      }
    },
    [range, range.fromDate, range.toDate, range.granularity, activeSensors]
  )

  const { data: sensorPool } = useSensorPool()

  useEffect(
    () => {
      const ids = sensorPool.map(item => Number(item.id))
      const checkForMainSensor = type =>
        sensorPool.some(
          sensor =>
            sensor.attributes.parent_id === null &&
            sensor.attributes.type_in_folder === type &&
            Number(sensor.attributes.folder_id) === Number(folderId)
        )
      setOperatorHasMainEnergySensor(checkForMainSensor('energy'))
      setOperatorHasMainWaterSensor(checkForMainSensor('water'))
      setOperatorHasMainGasSensor(checkForMainSensor('gas'))
      setOperatorSensorIds(ids)
    },
    [folderId, sensorPool]
  )

  useEffect(
    () => {
      if (consumptionData.length > 0 && operatorSensorIds.length > 0) {
        const _consumptionSensors = consumptionData.filter(f =>
          operatorSensorIds.includes(Number(f.entity_id))
        )

        const FILTER_MAIN_SENSOR = f => f.parent_id === null

        const mainSensor = consumptionData
          .filter(f => operatorSensorIds.includes(Number(f.entity_id)))
          .find(
            operatorHasMainEnergySensor ||
            operatorHasMainGasSensor ||
            operatorHasMainWaterSensor
              ? FILTER_MAIN_SENSOR
              : FILTER_SUB_SENSOR
          )

        if (mainSensor) {
          const _data = []
          const _columnsChart = []
          let _maxConsumption = 0

          _consumptionSensors.forEach(sensor => {
            const { consumption, entity_id } = sensor

            if (activeSensors.includes(entity_id)) {
              consumption.values.forEach((item, key) => {
                const { timepoint, value } = item
                const val = Number(value.toFixed(2))

                if (_data[key]) _data[key] = _data[key].concat([val])
                else _data[key] = [timepoint, val]
              })

              if (!_columnsChart[entity_id]) {
                _columnsChart[entity_id] = {
                  ui: { min_period: 0, period, max_period: 0 },
                  widget: [
                    { period, type: 'bid', data: [], display: 'absolute' },
                  ],
                }

                let _minPeriod = 0
                let _maxPeriod = 0
                const { ui, widget } = _columnsChart[entity_id]

                consumption.values.forEach(item => {
                  const { timepoint, value } = item
                  const time = new Date(timepoint).getTime()
                  const val = Number(value.toFixed(2))

                  if (val > _maxPeriod) _maxPeriod = val
                  else if (val < _minPeriod) _minPeriod = val

                  widget[0].data.push([time, val])
                })

                ui.min_period = _minPeriod
                ui.max_period = _maxPeriod

                if (_maxPeriod > _maxConsumption) _maxConsumption = _maxPeriod

                if (widget[0].data.length > 0) {
                  const firstEntry = widget[0].data

                  widget[0].data.unshift([
                    firstEntry[0] - 86400000,
                    firstEntry[1],
                  ])
                }

                if (widget[0].data.length === 0) {
                  const timestamp = new Date().getTime()

                  widget[0].data.push([timestamp, 0])
                }
              }
            }
          })

          // sort sensors by its names (label)
          _consumptionSensors.sort(SORT_NAME)
          setMaxConsumption(_maxConsumption)
          setConsumptionSensors(_consumptionSensors)
          setDataEnergy(_columnsChart)
        }
      }
    },
    [
      activeSensors,
      config.granularity,
      consumptionData,
      operatorHasMainEnergySensor,
      operatorHasMainGasSensor,
      operatorHasMainWaterSensor,
      operatorSensorIds,
      period,
      range.granularity,
    ]
  )

  useEffect(
    () => {
      if (!timeSeriesDataListLoading) {
        const _columnsChart = []
        let _maxFeeling = 0
        let _minFeeling = 0
        Object.keys(timeSeriesDataList.items).forEach(key => {
          if (activeFeelingSensors.includes(Number(key)))
            if (!_columnsChart[key]) {
              _columnsChart[key] = {
                ui: { min_period: 0, period, max_period: 0 },
                widget: [
                  { period, type: 'bid', data: [], display: 'absolute' },
                ],
              }

              let _minPeriod = 10000
              let _maxPeriod = -10000
              const { ui, widget } = _columnsChart[key]

              const orderedResult = timeSeriesDataList.items[key].sort(
                (a, b) => a.timestamp - b.timestamp
              )

              orderedResult.forEach(item => {
                const { timestamp, value } = item
                const val = Number(value.toFixed(2))

                if (val > _maxPeriod) _maxPeriod = val
                else if (val < _minPeriod) _minPeriod = val

                widget[0].data.push([timestamp * 1000, val])
              })

              ui.min_period = _minPeriod
              ui.max_period = _maxPeriod

              if (_minPeriod < _minFeeling) _minFeeling = _minPeriod
              if (_maxPeriod > _maxFeeling) _maxFeeling = _maxPeriod
            }
        })

        if (_minFeeling >= 0) _minFeeling = 0

        setMinFeeling(_minFeeling)
        setMaxFeeling(_maxFeeling)
        setDataFeeling(_columnsChart)
      }
    },
    [
      activeFeelingSensors,
      config.granularity,
      period,
      timeSeriesData,
      timeSeriesDataList,
      timeSeriesDataList.items,
      timeSeriesDataListLoading,
    ]
  )

  useEffect(
    () => {
      const _result = []
      let _format
      let _daylist = []
      const _sumArray = [['Summe']]
      const _excelArray = []

      switch (config.granularity) {
        case 'd':
          _format = DDMMYYYYHHMM
          _daylist = getHoursArray(
            new Date(config.fromDate),
            new Date(config.toDate)
          )
          break
        case 'm':
          _format = DDMMYY
          _daylist = getDaysArray(
            new Date(config.fromDate),
            new Date(config.toDate)
          )
          break
        case 'y':
          _format = MMMYY
          _daylist = getMonthsArray(config.fromDate, config.toDate)
          break
        default:
          break
      }

      _daylist = _daylist.map(day => [day.toLocaleString(userLocales, _format)])
      _result['Korrelation'] = []

      consumptionSensors.forEach(sensor => {
        const { consumption, entity_id, name, unit } = sensor

        if (activeSensors.includes(entity_id)) {
          let sum = 0
          _excelArray[name] = []
          _daylist.forEach(day => {
            const value = consumption.values.find(
              item =>
                new Date(item.timepoint).toLocaleString(
                  userLocales,
                  _format
                ) === day[0]
            )

            if (value) {
              const record = `${value.value} ${unit ? unit : ''}`

              day.push(record)
              _excelArray[name].push([day[0], record])
              sum += value.value
            } else {
              day.push(0)
              _excelArray[name].push([day[0], 0])
              sum += 0
            }
          })

          _sumArray[0].push(`${sum.toFixed(2)} ${unit ? unit : ''}`)
          _excelArray[name].push([
            'Summe',
            `${sum.toFixed(2)} ${unit ? unit : ''}`,
          ])
        }
      })

      Object.keys(timeSeriesDataList.items).map(sensor => {
        if (activeFeelingSensors.includes(Number(sensor))) {
          let sensorName = ''

          if (folderData.sensors.length !== 0)
            sensorName = folderData.sensors.find(
              f => f.entity_id === Number(sensor)
            ).name

          _excelArray[sensorName] = []
          _daylist.forEach(day => {
            let dayDate

            if (config.granularity === 'd') {
              dayDate = day[0].split('.')
              dayDate = new Date(
                `20${dayDate[2].substring(2, 4)}`,
                dayDate[1] - 1,
                dayDate[0],
                dayDate[2].substring(6, 7)
              )
            }

            if (config.granularity === 'm') {
              dayDate = day[0].substring(0, 10).split('.')
              dayDate = new Date(`20${dayDate[2]}`, dayDate[1] - 1, dayDate[0])
            }

            if (config.granularity === 'y') {
              dayDate = day[0].substring(0, 10).split('.')
              dayDate = new Date(`20${dayDate[2]}`, dayDate[1] - 1, dayDate[0])
            }

            const timestamp = dayDate.getTime() / 1000
            const closest =
              timeSeriesDataList.items[sensor].length !== 0
                ? timeSeriesDataList.items[sensor].reduce(
                    (prev, curr) =>
                      Math.abs(curr.timestamp - timestamp) <
                      Math.abs(prev.timestamp - timestamp)
                        ? curr
                        : prev
                  )
                : { value: 0, timestamp: timestamp }

            const record = `${closest.value} ${sensor.unit ? sensor.unit : ''}}`

            day.push(record)
            _excelArray[sensorName].push([day[0], record])
          })

          _sumArray[0].push('-')
          _excelArray[sensorName].push(['Summe', '-'])
        }

        return true
      })

      // add weekday to dates
      _daylist = addWeekdays(_daylist, config.granularity)

      Object.keys(_excelArray).map(data => {
        _excelArray[data] = addWeekdays(_excelArray[data], config.granularity)
        return true
      })

      _daylist = _daylist.concat(_sumArray)
      _result['Korrelation'] = _daylist
      setTableDataExport(_result)
      setTableDataExportExcel(_excelArray)
    },
    [
      activeFeelingSensors,
      activeSensors,
      config.fromDate,
      config.granularity,
      config.toDate,
      consumptionSensors,
      folderData,
      timeSeriesDataList,
    ]
  )

  useEffect(
    () => {
      const baselineStyle = {
        line: {
          stroke: 'red',
          strokeWidth: 1,
          opacity: 0.4,
          strokeDasharray: 'none',
        },
        label: {
          fill: 'red',
        },
      }
      const _baselines = []
      const _combinationsMin = []
      const _combinationsMax = []
      let _maxValue = 0

      const _disabled = activeBaselineFeelingSensors.length >= 6 ? true : false

      activeBaselineFeelingSensors.forEach(sensor => {
        if (sensor.alarm_min) {
          if (_combinationsMin[sensor.alarm_min] === undefined) {
            _combinationsMin[sensor.alarm_min] = `${sensor.name}-${sensor.unit}`
          } else {
            _combinationsMin[sensor.alarm_min] += `, ${sensor.name}-${
              sensor.unit
            }`
          }
        }
        if (sensor.alarm_max) {
          if (_combinationsMax[sensor.alarm_max] === undefined) {
            _combinationsMax[sensor.alarm_max] = `${sensor.name}-${sensor.unit}`
          } else {
            _combinationsMax[sensor.alarm_max] += `, ${sensor.name}-${
              sensor.unit
            }`
          }
        }
      })

      for (const [key, value] of Object.entries(_combinationsMin)) {
        _baselines.push(
          <Baseline
            axis={'feeling'}
            style={baselineStyle}
            value={key}
            label={`GMin (${value})`}
            position={'right'}
          />
        )
      }
      for (const [key, value] of Object.entries(_combinationsMax)) {
        _baselines.push(
          <Baseline
            axis={'feeling'}
            style={baselineStyle}
            value={key}
            label={`GMax (${value})`}
            position={'right'}
          />
        )
        if (key > _maxValue) _maxValue = key
      }

      setDisableBaselineSwitch(_disabled)
      setBaselines(_baselines)
      if (_maxValue > maxFeeling) setMaxFeeling(_maxValue)
    },
    [activeBaselineFeelingSensors, maxFeeling]
  )
  const contractBoundaries = date => {
    const date_begin = folderData?.contract?.date_begin
    const date_expire = folderData?.contract?.date_expire

    if (date < date_begin) return date_begin
    if (date > date_expire) return date_expire
    return date
  }

  const sanitize = date => limitTodayMax(contractBoundaries(date))

  const getSensorById = id =>
    folderData.sensors.find(f => Number(f.entity_id) === Number(id))

  const tableData2ExcelData = data => {
    const alignment = { horizontal: 'center' }

    const border = trbl => {
      return {
        top: { style: trbl[0] === '2' ? 'medium' : 'thin' },
        right: { style: trbl[1] === '2' ? 'medium' : 'thin' },
        bottom: { style: trbl[2] === '2' ? 'medium' : 'thin' },
        left: { style: trbl[3] === '2' ? 'medium' : 'thin' },
      }
    }

    const fill = color => ({ fgColor: { rgb: color }, patternType: 'solid' })
    const fontColor = color => ({ color: { rgb: color } })

    const excelData = [
      {
        // LB requests tableData starting at B2, not A1
        xSteps: 1,
        ySteps: 1,

        // columns
        columns: [
          {
            title: 'Datum',
            style: {
              alignment,
              border: border('2122'),
              fill: fill('FFCCCCCC'),
            },
          },
          {
            title: 'Wert',
            width: { wpx: 150 },
            style: {
              alignment,
              border: border('2121'),
              fill: fill('FFCCCCCC'),
            },
          },
          { width: { wpx: 150 } },
        ],

        data: data.map((row, rowKey) => {
          const lastRow = data.length - 1

          const trimmer = (data, col) => {
            switch (col) {
              case 1:
                return data
              case 3:
                return data
              case 4:
              case 5:
              case 6:
              case 9:
                return data
              default:
                return data
            }
          }

          const result = row.map((col, colKey) => {
            // last line?
            const isSummaryRow = rowKey === lastRow

            // trend
            const isUpTrend = String(col).includes('▲')
            const isDownTrend = String(col).includes('▼')

            // calculate cell color; default = black
            // if trend is increasing, thats negative (red)
            // if trend is decreasing, thats positive (green)
            const cellColor = isUpTrend
              ? fontColor('FFFF0000')
              : isDownTrend
                ? fontColor('FF009000')
                : fontColor('FF000000')

            return {
              //value: `${trimmer(col, colKey)} ${cellUnits[colKey]}`,
              value: trimmer(col, colKey),
              style: {
                font: cellColor,
                alignment,
                border: isSummaryRow
                  ? border(`2${colKey === 9 ? 2 : 1}2${colKey === 0 ? 2 : 1}`)
                  : border(`1${colKey === 9 ? 2 : 1}1${colKey === 0 ? 2 : 1}`),
                fill: isSummaryRow ? fill('FFCCCCCC') : fill('FFFFFFFF'),
              },
            }
          })

          return result
        }),
      },
    ]

    return excelData
  }

  const getCSVHeaders = () => {
    const _csvHeaders = []
    _csvHeaders.push('Datum')

    activeSensors.forEach(entity_id => {
      const sensor = getSensorById(entity_id)
      const _consumptionHeader = `IST-VERBRAUCH ${sensor.name} ${
        sensor.unit ? sensor.unit : ''
      }`

      _csvHeaders.push(_consumptionHeader)
    })

    activeFeelingSensors.forEach(entity_id => {
      const sensor = getSensorById(entity_id)
      const _feelingHeader = `${sensor.name} ${sensor.unit ? sensor.unit : ''}`

      _csvHeaders.push(_feelingHeader)
    })

    _csvHeaders.push(timeSpan)
    return _csvHeaders
  }

  const removeBaselineSensor = (array, entity_id, type, value) => {
    let index
    type === 'alarm_min'
      ? (index = array.findIndex(
          sensor => sensor.entity_id === entity_id && sensor.alarm_min === value
        ))
      : (index = array.findIndex(
          sensor => sensor.entity_id === entity_id && sensor.alarm_max === value
        ))
    return index >= 0
      ? [...array.slice(0, index), ...array.slice(index + 1)]
      : array
  }

  const getFeelingLimitSwitches = sensors => {
    return sensors.map((sensor, key) => {
      const _disabled = disableBaselineSwitch
      const { entity_id, name, attributes_values, unit } = sensor

      let alarm_min = attributes_values.find(
        av => av.attribute_code === 'alarm_min'
      )
      if (alarm_min !== undefined) {
        alarm_min = alarm_min.value
      } else {
        alarm_min = 'Nicht hinterlegt'
      }

      let alarm_max = attributes_values.find(
        av => av.attribute_code === 'alarm_max'
      )
      if (alarm_max !== undefined) {
        alarm_max = alarm_max.value
      } else {
        alarm_max = 'Nicht hinterlegt'
      }

      const minObj = {
        entity_id: entity_id,
        alarm_min: alarm_min,
        name: name,
        unit: unit,
      }

      const maxObj = {
        entity_id: entity_id,
        alarm_max: alarm_max,
        name: name,
        unit: unit,
      }

      if (activeFeelingSensors.includes(entity_id)) {
        let _disabledMin = _disabled
        let _disabledMax = _disabled

        if (
          activeBaselineFeelingSensors.filter(
            s => s.entity_id === entity_id && s.alarm_min === alarm_min
          ).length > 0
        )
          _disabledMin = false

        if (
          activeBaselineFeelingSensors.filter(
            s => s.entity_id === entity_id && s.alarm_max === alarm_max
          ).length > 0
        )
          _disabledMax = false

        return (
          <Grid container spacing={2} key={key}>
            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
              <Typography sx={{ p: '8px 0' }}>{name}</Typography>
            </Grid>

            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      activeBaselineFeelingSensors.filter(
                        s =>
                          s.entity_id === entity_id && s.alarm_min === alarm_min
                      ).length > 0
                    }
                    onChange={() => {
                      if (
                        activeBaselineFeelingSensors.filter(
                          s =>
                            s.entity_id === entity_id &&
                            s.alarm_min === alarm_min
                        ).length > 0
                      ) {
                        const newData = removeBaselineSensor(
                          activeBaselineFeelingSensors,
                          entity_id,
                          'alarm_min',
                          alarm_min
                        )
                        setActiveBaselineFeelingSensors(newData)
                      } else {
                        setActiveBaselineFeelingSensors([
                          ...activeBaselineFeelingSensors,
                          minObj,
                        ])
                      }
                    }}
                  />
                }
                label={`${alarm_min} ${unit}`}
                key={key}
                disabled={_disabledMin}
              />
            </Grid>

            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      activeBaselineFeelingSensors.filter(
                        s =>
                          s.entity_id === entity_id && s.alarm_max === alarm_max
                      ).length > 0
                    }
                    onChange={() => {
                      if (
                        activeBaselineFeelingSensors.filter(
                          s =>
                            s.entity_id === entity_id &&
                            s.alarm_max === alarm_max
                        ).length > 0
                      ) {
                        const newData = removeBaselineSensor(
                          activeBaselineFeelingSensors,
                          entity_id,
                          'alarm_max',
                          alarm_max
                        )
                        setActiveBaselineFeelingSensors(newData)
                      } else {
                        setActiveBaselineFeelingSensors([
                          ...activeBaselineFeelingSensors,
                          maxObj,
                        ])
                      }
                    }}
                  />
                }
                label={`${alarm_max} ${unit}`}
                key={key}
                disabled={_disabledMax}
              />
            </Grid>
          </Grid>
        )
      }
      return true
    })
  }

  const handleActivateAllConsumptionSensorsCheckbox = (
    event,
    allActive,
    sensors
  ) => {
    event.stopPropagation()

    let _activeSensors = [...activeSensors]

    sensors.forEach(sensor => {
      const { entity_id } = sensor
      allActive
        ? (_activeSensors = _activeSensors.filter(item => item !== entity_id))
        : _activeSensors.push(entity_id)
    })
    setActiveSensors(_activeSensors)
  }

  const handleActivateAllFeelingSensorsCheckbox = (
    event,
    allActive,
    sensors
  ) => {
    event.stopPropagation()

    let _activeSensors = [...activeFeelingSensors]
    let _activeBaseLineFeelingSensors = [...activeBaselineFeelingSensors]

    sensors.forEach(sensor => {
      const { entity_id } = sensor

      if (!allActive) {
        _activeSensors.push(entity_id)
      } else {
        _activeSensors = _activeSensors.filter(item => item !== entity_id)
        _activeBaseLineFeelingSensors = _activeBaseLineFeelingSensors.filter(
          f => f.entity_id !== entity_id
        )
      }
    })

    setActiveFeelingSensors(_activeSensors)
    setActiveBaselineFeelingSensors(_activeBaseLineFeelingSensors)
  }

  const createAxisLabel = types => {
    if (types.length === 0) return ''
    types = [...new Set(types)]
    const typeNameWithUnit = types.map(
      feelingType => `${sensorType(feelingType)} (${sensorUnit(feelingType)})`
    )
    return `${typeNameWithUnit}`
  }

  const accordionDetailList = (sensors, sensor_type, Component) => {
    const _isConsumptionSensor = ['energy', 'gas', 'water'].includes(
      sensor_type
    )
    const FILTER_SENSOR_TYPE = f =>
      normalizedSensorType(f.sensor_type, f.type_in_folder) === sensor_type ||
      normalizedSensorType(f.sensor_type, f.type_in_folder) ===
        `${sensor_type}_detail`

    const isSelected = entity_id => {
      return _isConsumptionSensor
        ? activeSensors.includes(entity_id)
        : activeFeelingSensors.includes(entity_id)
    }

    return (
      <List>
        {sensors.filter(FILTER_SENSOR_TYPE).map((sensor, key) => {
          const { entity_id } = sensor
          return (
            <Component
              data={sensor}
              feelingSensorId={feelingSensorId}
              isLoading={
                timeSeriesDataByFeelingTypesLoading || timeSeriesDataListLoading
              }
              key={key}
              onClick={() => {
                const _sensor = folderData.sensors.find(
                  sensor => sensor.entity_id === Number(entity_id)
                )
                if (_sensor) {
                  if (_isConsumptionSensor) {
                    setActiveSensors(
                      activeSensors.includes(entity_id)
                        ? activeSensors.filter(f => f !== entity_id)
                        : [...activeSensors, entity_id]
                    )
                  } else {
                    setFeelingSensorId(entity_id)
                    changeFeelingSensors(entity_id)
                  }
                }
              }}
              selected={isSelected(entity_id)}
            />
          )
        })}
      </List>
    )
  }

  const consumptionAccordion = type => {
    const _consumptionSensors = consumptionSensors.filter(
      s =>
        normalizedSensorType(
          s.sensor_type.replace('_detail', ''),
          s.type_in_folder
        ) === type
    )
    const _allSensorsOfTypeActivated = _consumptionSensors.every(sensor =>
      activeSensors.includes(sensor.entity_id)
    )
    const _someSensorsOfTypeActivated = _consumptionSensors.some(sensor =>
      activeSensors.includes(sensor.entity_id)
    )

    return (
      <Accordion sx={{ color: '#000' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreTwoTone />}
          sx={{
            justifyContent: 'space-between',
            borderLeft: `solid 10px ${colorSchemeConsumption[0]}`,
          }}
        >
          <Grid item xs={10}>
            <Typography sx={{ mt: '8px' }}>{sensorType(type)}</Typography>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2}>
            <Checkbox
              color={'primary'}
              onClick={event =>
                handleActivateAllConsumptionSensorsCheckbox(
                  event,
                  _allSensorsOfTypeActivated,
                  _consumptionSensors
                )
              }
              checked={_allSensorsOfTypeActivated}
              indeterminate={
                _someSensorsOfTypeActivated && !_allSensorsOfTypeActivated
              }
            />
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderLeft: `solid 10px ${colorSchemeConsumption[0]}`,
            p: `10px 45px 20px 0px;`,
          }}
        >
          {accordionDetailList(
            _consumptionSensors,
            type,
            CorrelationConsumptionItem
          )}
        </AccordionDetails>
      </Accordion>
    )
  }

  const feelingAccordion = type => {
    const _feelingSensors = feelingSensors.filter(
      s => normalizedSensorType(s.sensor_type) === type
    )
    const _allSensorsOfTypeActivated = _feelingSensors.every(sensor =>
      activeFeelingSensors.includes(sensor.entity_id)
    )
    const _someSensorsOfTypeActivated = _feelingSensors.some(sensor =>
      activeFeelingSensors.includes(sensor.entity_id)
    )

    return (
      <Accordion sx={{ color: '#000' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreTwoTone />}
          sx={{
            justifyContent: 'space-between',
            borderLeft: `solid 10px ${colorSchemeFeeling[0]}`,
          }}
        >
          <Grid item xs={10}>
            <Typography sx={{ mt: '8px' }}>{sensorType(type)}</Typography>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2}>
            <Checkbox
              color={'primary'}
              onClick={event =>
                handleActivateAllFeelingSensorsCheckbox(
                  event,
                  _allSensorsOfTypeActivated,
                  _feelingSensors
                )
              }
              checked={_allSensorsOfTypeActivated}
              indeterminate={
                _someSensorsOfTypeActivated && !_allSensorsOfTypeActivated
              }
            />
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderLeft: `solid 10px ${colorSchemeFeeling[0]}`,
            p: `10px 45px 20px 0px;`,
          }}
        >
          {accordionDetailList(_feelingSensors, type, CorrelationFeelingItem)}
        </AccordionDetails>
      </Accordion>
    )
  }

  const securityAccordion = type => {
    const _securitySensors = securitySensors.filter(
      s => normalizedSensorType(s.sensor_type) === type
    )
    const _allSensorsOfTypeActivated = _securitySensors.every(sensor =>
      activeFeelingSensors.includes(sensor.entity_id)
    )
    const _someSensorsOfTypeActivated = _securitySensors.some(sensor =>
      activeFeelingSensors.includes(sensor.entity_id)
    )

    return (
      <Accordion sx={{ color: '#000' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreTwoTone />}
          sx={{
            justifyContent: 'space-between',
            borderLeft: `solid 10px ${colorSchemeSecurity[0]}`,
          }}
        >
          <Grid item xs={10}>
            <Typography sx={{ mt: '8px' }}>{sensorType(type)}</Typography>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2}>
            <Checkbox
              color={'primary'}
              onClick={event =>
                handleActivateAllFeelingSensorsCheckbox(
                  event,
                  _allSensorsOfTypeActivated,
                  _securitySensors
                )
              }
              checked={_allSensorsOfTypeActivated}
              indeterminate={
                _someSensorsOfTypeActivated && !_allSensorsOfTypeActivated
              }
            />
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderLeft: `solid 10px ${colorSchemeSecurity[0]}`,
            p: `10px 45px 20px 0px;`,
          }}
        >
          {accordionDetailList(_securitySensors, type, CorrelationSecurityItem)}
        </AccordionDetails>
      </Accordion>
    )
  }

  const isPageLoading =
    folderLoading || consumptionLoading || feelingTypeLoading
  const isPageError = folderError

  return (
    <Collapse in={!isPageLoading}>
      {/* preloader */}
      <Preloader error={isPageError} isLoading={isPageLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Korrelation'}
        open={Boolean(actionMenu)}
        onClose={() => setActionMenu(null)}
        tiny
        historyUrlTarget={'correlation'}
      >
        <AMItem
          caption={'Exportieren'}
          icon={<ImportExportTwoTone />}
          onClick={() => setExportModal(true)}
        />
      </AM>
      {/* action menu end */}

      {/* export */}
      <Dialog open={exportModal} onClose={() => setExportModal(false)}>
        <DialogTitle onClose={() => setExportModal(false)}>
          Daten exportieren
        </DialogTitle>
        <DialogContent>
          <Stack>
            <CSVLink
              headers={getCSVHeaders()}
              data={
                tableDataExport['Korrelation']
                  ? tableDataExport['Korrelation']
                  : []
              }
              filename={`${fileName}.csv`}
              target={'_blank'}
            >
              <Button>
                <SaveTwoTone />
                CSV
              </Button>
            </CSVLink>{' '}
            <ExcelFile
              element={
                <Button>
                  <SaveTwoTone />
                  Excel
                </Button>
              }
              filename={fileName}
            >
              {Object.keys(tableDataExportExcel).length > 0
                ? Object.keys(tableDataExportExcel).map((data, key) => {
                    return (
                      <ExcelSheet
                        key={key}
                        dataSet={tableData2ExcelData(
                          tableDataExportExcel[data],
                          data
                        )}
                        name={data.replace(/[^a-zA-Z0-9_äÄöÖüÜß+,\s]/g, '_')}
                      />
                    )
                  })
                : ''}
            </ExcelFile>
          </Stack>
        </DialogContent>
      </Dialog>
      {/* export end */}

      {/* limits */}
      <Dialog open={limitModal} onClose={() => setLimitModal(false)}>
        <DialogTitle onClose={() => setLimitModal(false)}>
          Grenzwerte der Messpunkte
        </DialogTitle>
        <DialogContent>
          <Stack>
            <Alert severity={'info'} sx={{ mt: 2, py: 1 }}>
              Hier {salutationMode === 'default' ? 'können Sie' : 'kannst Du'}{' '}
              auswählen, ob für die ausgewählten Messpunkte Grenzwertlinien
              eingeblendetet werden sollen.
              {<br />} (Maximal 6 Stück)
            </Alert>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography sx={{ py: 1, textAlign: 'center' }}>
                  Messpunkt
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography sx={{ py: 1, textAlign: 'center' }}>
                  Grenzwert Minimum
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography sx={{ py: 1, textAlign: 'center' }}>
                  Grenzwert Maximum
                </Typography>
              </Grid>
            </Grid>
            {getFeelingLimitSwitches(feelingSensors)}
            {getFeelingLimitSwitches(securitySensors)}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLimitModal(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>
      {/* limits end */}

      {!isCorrelational ? (
        <Alert severity={'info'}>
          Diese Funktion ist verfügbar, sobald mindestens zwei Messpunkte
          gebucht sind.
        </Alert>
      ) : (
        <>
          <Collapse in={!isPageLoading}>
            <Card variant={'correlation'} sx={{ minHeight: 'auto' }}>
              <CardHeader
                sx={{ py: 0 }}
                title={
                  <Stack
                    direction={'row'}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Stack alignItems={'center'} direction={'row'}>
                      <TextField
                        label={'Granularität'}
                        onChange={event => {
                          const newRange = {
                            ...range,
                            granularity: event.target.value,
                          }

                          setRange(newRange)
                        }}
                        select
                        type={'text'}
                        value={range.granularity}
                        variant={'standard'}
                      >
                        {granularityData.map((item, key) => (
                          <MenuItem key={key} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Tooltip title={'Grenzwerte'}>
                        <ToggleButton
                          onClick={() => setLimitModal(true)}
                          value={limitModal}
                        >
                          <NotificationImportantTwoTone />
                        </ToggleButton>
                      </Tooltip>
                    </Stack>

                    <Stack alignItems={'center'} direction={'row'}>
                      <Tooltip title={`Um ${'todo'} Tage zurück blättern`}>
                        <span>
                          <IconButton
                            disabled={
                              periodWarning ||
                              folderData?.contract?.date_begin >=
                                range.fromDate ||
                              range.fromDate !== config.fromDate ||
                              range.toDate !== config.toDate ||
                              range.granularity !== config.granularity
                            }
                            onClick={() => {
                              const newConfig = {
                                ...config,
                                fromDate: daysAgo(
                                  config.fromDate,
                                  periodInDays + 1
                                ),
                                toDate: daysAgo(
                                  config.toDate,
                                  periodInDays + 1
                                ),
                              }

                              setConfig(newConfig)
                              setRange({
                                ...range,
                                fromDate: newConfig.fromDate,
                                toDate: newConfig.toDate,
                              })
                              setDataFeeling([])
                              setAllSeries([])
                              setMinFeeling(0)
                              setMaxFeeling(0)
                              setLineCharts([])
                              setLegend([])
                            }}
                          >
                            <ArrowBackIosTwoTone />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title={`Um ${'todo'} Tage weiter blättern`}>
                        <span>
                          <IconButton
                            disabled={
                              periodWarning ||
                              folderData?.contract?.date_expire <=
                                range.toDate ||
                              new Date(Date.now())
                                .toISOString()
                                .split('T')[0] <= config.toDate ||
                              range.fromDate !== config.fromDate ||
                              range.toDate !== config.toDate ||
                              range.granularity !== config.granularity
                            }
                            onClick={() => {
                              const newConfig = {
                                ...config,
                                fromDate: daysOnward(
                                  config.fromDate,
                                  periodInDays + 1
                                ),
                                toDate: daysOnward(
                                  config.toDate,
                                  periodInDays + 1
                                ),
                              }
                              setConfig(newConfig)
                              setRange({
                                ...range,
                                fromDate: newConfig.fromDate,
                                toDate: newConfig.toDate,
                              })
                              setDataFeeling([])
                              setAllSeries([])
                              setMinFeeling(0)
                              setMaxFeeling(0)
                              setLineCharts([])
                              setLegend([])
                            }}
                          >
                            <ArrowForwardIosTwoTone />
                          </IconButton>
                        </span>
                      </Tooltip>

                      <TextField
                        inputProps={{
                          onChange: event => {
                            const newRange = {
                              ...range,
                              fromDate: sanitize(event.target.value),
                              ...(sanitize(event.target.value) >
                                range.toDate && {
                                toDate: sanitize(event.target.value),
                              }),
                            }
                            setRange(newRange)
                          },
                          min: folderData?.contract?.date_begin,
                          max: folderData?.contract?.date_expire,
                        }}
                        label={'von'}
                        sx={{ width: 150 }}
                        type={'date'}
                        value={range.fromDate}
                        variant={'standard'}
                      />
                      <TextField
                        inputProps={{
                          onChange: event => {
                            const newRange = {
                              ...range,
                              ...(sanitize(event.target.value) <
                                range.fromDate && {
                                fromDate: sanitize(event.target.value),
                              }),
                              toDate: sanitize(event.target.value),
                            }

                            setRange(newRange)
                          },
                          min: folderData?.contract?.date_begin,
                          max: folderData?.contract?.date_expire,
                        }}
                        label={'bis'}
                        sx={{ width: 150 }}
                        type={'date'}
                        value={range.toDate}
                        variant={'standard'}
                      />
                      {/* aktuell */}

                      <Tooltip
                        title={`Zeitspanne ändern: ${Number(
                          new Date(config.toDate) - new Date(config.fromDate)
                        ) /
                          1000 /
                          60 /
                          60 /
                          24} > ${Number(
                          new Date(range.toDate) - new Date(range.fromDate)
                        ) /
                          1000 /
                          60 /
                          60 /
                          24} Tag(e)`}
                      >
                        <span>
                          <Button
                            color={'primary'}
                            disabled={
                              config.fromDate === range.fromDate &&
                              config.toDate === range.toDate &&
                              config.granularity === range.granularity
                            }
                            onClick={() => {
                              setConfig({
                                ...config,
                                fromDate: range.fromDate,
                                toDate: range.toDate,
                                granularity: range.granularity,
                              })
                            }}
                          >
                            Anwenden
                          </Button>
                        </span>
                      </Tooltip>
                    </Stack>
                  </Stack>
                }
                titleTypographyProps={{ variant: 'h6' }}
                variant={'correlation'}
              />
              <CardContent sx={{ p: '0px !important' }}>
                <Collapse in={periodWarning}>
                  {periodWarning && (
                    <Alert severity={'warning'}>
                      <AlertTitle>Achtung!</AlertTitle>
                      {periodWarning}
                    </Alert>
                  )}
                </Collapse>

                <Collapse in={!periodWarning}>
                  <Grid container sx={{ bgcolor }}>
                    {trackerX &&
                      trackerX > 80 &&
                      ((showSecondAxis && trackerX < 1350) ||
                        (!showSecondAxis && trackerX < 1403)) &&
                      allSeries.length > 0 &&
                      !periodWarning && (
                        <Box
                          sx={{
                            bgcolor,
                            border: 'solid 1px #C8C8C8',
                            color: 'black',
                            left: mouseX - 260,
                            p: '20px 10px',
                            pointerEvents: 'None',
                            position: 'absolute',
                            top: mouseY,
                            zIndex: 10000,
                          }}
                        >
                          <strong>
                            {new Date(tracker).toLocaleDateString(
                              userLocales,
                              WDDDMMYYYYHHMMSS
                            )}
                          </strong>
                          <br />
                          {itemRows}
                        </Box>
                      )}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          position: 'relative',
                          top: 20,
                        }}
                      >
                        <Legend
                          type={'line'}
                          style={legendStyle}
                          categories={legend}
                          align={'right'}
                        />
                      </Box>

                      <Resizable>
                        <ChartContainer
                          enableDragZoom
                          format={f => f.toLocaleString(userLocales, WDDDMMYY)}
                          padding={40}
                          paddingBottom={0}
                          paddingTop={5}
                          maxTime={requestsSeries.range().end()}
                          minTime={requestsSeries.range().begin()}
                          onTimeRangeChanged={range => setTimeRange(range)}
                          onTrackerChanged={handleTrackerChanged}
                          timeAxisStyle={axisStyle}
                          timeAxisTickCount={5}
                          timeRange={timeRange}
                          trackerPosition={tracker}
                          trackerStyle={{
                            line: {
                              cursor: 'crosshair',
                              pointerEvents: 'none',
                              stroke: '#a62011',
                            },
                          }}
                        >
                          <ChartRow key={timeRange} height={500} width={100}>
                            <YAxis
                              format={f =>
                                f.toLocaleString(
                                  userLocales,
                                  maxFeeling <= 10 ? germanDecimals : noDecimals
                                )
                              }
                              hideAxisLine
                              id={'feeling'}
                              label={feelingLabel}
                              min={minFeeling}
                              max={maxFeeling}
                              style={axisStyle}
                              type={'linear'}
                              visible={showFirstAxis}
                              width={'80'}
                            />
                            <Charts>
                              {lineCharts}
                              {baselines}
                            </Charts>
                            <YAxis
                              format={f =>
                                f.toLocaleString(
                                  userLocales,
                                  maxConsumption <= 10
                                    ? germanDecimals
                                    : noDecimals
                                )
                              }
                              hideAxisLine
                              id={'consumption'}
                              label={consumptionLabel}
                              labelOffset={20}
                              max={maxConsumption}
                              style={axisStyle}
                              type={'linear'}
                              visible={showSecondAxis}
                              width={'80'}
                            />
                          </ChartRow>
                        </ChartContainer>
                      </Resizable>
                    </Grid>
                  </Grid>
                </Collapse>
              </CardContent>
            </Card>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Card variant={'correlation'}>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <Box
                          alt={''}
                          component={'img'}
                          src={SensorIcon(
                            'performance',
                            getStatusColor(
                              consumptionSensors
                                .map(item => item.entity_id)
                                .map(item =>
                                  sensorPool.find(
                                    f => f.attributes.entity_id === item
                                  )
                                )
                                .map(item => ({ ...item, ...item.attributes }))
                            )
                          )}
                          sx={{ width: 40 }}
                        />
                      </Avatar>
                    }
                    title={'Leistung'}
                    titleTypographyProps={{ variant: 'h6' }}
                    variant={'correlation'}
                  />
                  {['energy', 'gas', 'water'].map((sensorType, key) => {
                    return (
                      consumptionSensors.find(
                        s =>
                          normalizedSensorType(
                            s.sensor_type,
                            s.type_in_folder
                          ) === sensorType
                      ) && (
                        <Stack key={key}>
                          {consumptionAccordion(sensorType)}
                        </Stack>
                      )
                    )
                  })}
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card variant={'correlation'}>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <Box
                          alt={''}
                          component={'img'}
                          src={SensorIcon(
                            'feeling',
                            getStatusColor(feelingSensors)
                          )}
                          sx={{ width: 40 }}
                        />
                      </Avatar>
                    }
                    title={'Wohlbefinden'}
                    titleTypographyProps={{ variant: 'h6' }}
                    variant={'correlation'}
                  />
                  {['temperature', 'co2', 'humidity', 'light'].map(
                    (sensorType, key) =>
                      feelingSensors.find(
                        s => normalizedSensorType(s.sensor_type) === sensorType
                      ) && (
                        <Stack key={key}>{feelingAccordion(sensorType)}</Stack>
                      )
                  )}
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card variant={'correlation'}>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <Box
                          alt={''}
                          component={'img'}
                          src={SensorIcon(
                            'security',
                            getStatusColor(securitySensors)
                          )}
                          sx={{ width: 40 }}
                        />
                      </Avatar>
                    }
                    title={'Sicherheit'}
                    titleTypographyProps={{ variant: 'h6' }}
                    variant={'correlation'}
                  />
                  {['temperature', 'co2', 'humidity', 'light', 'pressure'].map(
                    (sensorType, key) =>
                      securitySensors.find(
                        s => normalizedSensorType(s.sensor_type) === sensorType
                      ) && (
                        <Stack key={key}>{securityAccordion(sensorType)}</Stack>
                      )
                  )}
                </Card>
              </Grid>
            </Grid>
          </Collapse>
        </>
      )}
    </Collapse>
  )
}

Correlation.propTypes = {
  isCorrelational: PropTypes.bool,
}
