import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  Paper,
} from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'
import { useAppConfig } from '../../hooks'

export const KPage = ({ isLoading, children, ...rest }) => {
  const { global: appConfig } = useAppConfig()
  const isWideScreen = appConfig?.wideScreen || false

  return (
    <Collapse in={!isLoading}>
      <Paper>
        <Card>
          <CardHeader {...rest} />

          <CardContent sx={{ minHeight: 'calc(100vh - 270px)' }}>
            <Container maxWidth={isWideScreen === true ? 'false' : 'xl'}>
              {children}
            </Container>
          </CardContent>
        </Card>
      </Paper>
    </Collapse>
  )
}

KPage.propTypes = {
  children: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
}
