export const colorSchemeConsumption = [
  '#0C79B1',
  '#FF7D2D',
  '#239F39',
  '#DA252F',
  '#9469BA',
  '#8E564D',
  '#E579C0',
  '#7F7F7F',
  '#BDBB3C',
  '#00BECD',
  '#113D28',
  '#0000FF',
  '#458B74',
  '#8B4789',
  '#CDCD00',
  '#8B6508',
  '#551A8B',
  '#C71585',
  '#8B0000',
  '#0D0D0D',
]

export const colorSchemeFeeling = [
  '#8968CD',
  '#68838B',
  '#AF0FAD',
  '#59515B',
  '#CD5555',
  '#8B2323',
  '#2E8B57',
  '#969273',
  '#0F1400',
  '#651F70',
  '#00BFFF',
  '#458B74',
  '#8B7355',
  '#708090',
  '#8B4500',
  '#FFA500',
  '#FF1493',
  '#8B636C',
  '#551A8B',
  '#8B8B00',
]

export const colorSchemeSecurity = [
  '#8B864E',
  '#8B3A3A',
  '#9ACD32',
  '#00008B',
  '#BC8F8F',
  '#444D7A',
  '#9B30FF',
  '#CD6600',
  '#B03060',
  '#667267',
  '#B8860B',
  '#5A057F',
  '#EE2C2C',
  '#698B22',
  '#708090',
  '#CD3278',
  '#00868B',
  '#8B3A3A',
  '#8B008B',
  '#00008B',
]
