/* action menu */

import {
  Box,
  Divider,
  Fade,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Radio,
  useTheme,
} from '@mui/material'
import {
  ArrowBackTwoTone,
  RefreshTwoTone,
  CheckTwoTone,
  WidthWideTwoTone,
} from '@mui/icons-material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAppConfig } from '../hooks'

export const AM = ({
  caption,
  children,
  tiny = false,
  historyUrlTarget = '',
  historyUrlId,
  ...rest
}) => {
  const { global: appConfig, setGlobal: setAppConfig } = useAppConfig()

  const history = useHistory()
  let historyUrl = ''

  switch (historyUrlTarget) {
    case 'dashboard':
    case 'folder':
    case 'sensors':
    case 'news':
    case 'users':
    case 'notifications':
    case 'reports':
    case 'virtualsensors':
      historyUrl = `/${historyUrlTarget}`
      break

    case 'feeling':
    case 'security':
    case 'performance':
    case 'installation':
      historyUrl = `/folder/${historyUrlId}/home/${historyUrlTarget}`
      break

    default:
      historyUrl = '/dashboard'
  }

  // handler

  const reloadPage = () => window.location.reload()
  const goBack = () => historyUrl !== '' && history.push(historyUrl)

  return (
    <Menu {...rest} TransitionComponent={Fade}>
      <ListSubheader>{caption}</ListSubheader>

      {children}

      {!tiny && (
        <Box>
          <Divider />
          <ListSubheader>Seite</ListSubheader>
          <AMItem
            caption={'Wide Screen'}
            icon={<WidthWideTwoTone />}
            selected={appConfig?.wideScreen || false}
            onClick={() =>
              setAppConfig(prev => ({ ...prev, wideScreen: !prev.wideScreen }))
            }
          />
          <AMItem
            caption={'Neu laden'}
            icon={<RefreshTwoTone />}
            onClick={reloadPage}
          />
          <AMItem
            caption={'Zurück'}
            icon={<ArrowBackTwoTone />}
            onClick={goBack}
          />
        </Box>
      )}
    </Menu>
  )
}

AM.propTypes = {
  caption: PropTypes.string,
  children: PropTypes.any,
  tiny: PropTypes.bool,
  historyUrlTarget: PropTypes.string,
  historyUrlId: PropTypes.number,
}

export const AMItem = ({ caption, icon, ...rest }) => (
  <MenuItem {...rest}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText>{caption}</ListItemText>
  </MenuItem>
)

AMItem.propTypes = {
  caption: PropTypes.string,
  icon: PropTypes.object,
}

export const AMRadio = ({ caption, disabled = false, onClick, ...rest }) => {
  const theme = useTheme()
  const { white: color } = theme.palette

  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <ListItemIcon>
        <Radio
          checkedIcon={<CheckTwoTone />}
          icon={<CheckTwoTone sx={{ color }} />}
          onChange={onClick}
          value={caption}
          {...rest}
        />
      </ListItemIcon>

      <ListItemText>{caption}</ListItemText>
    </MenuItem>
  )
}

AMRadio.propTypes = {
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
