import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { newsConstants as NC } from '../../../constants'

export const NewsCategorySelector = ({
  data = [
    {
      label: NC.NEWS_CATEGORY_CONTRACT,
      value: NC.NEWS_CATEGORY_CONTRACT,
    },
    {
      label: NC.NEWS_CATEGORY_OBJECT,
      value: NC.NEWS_CATEGORY_OBJECT,
    },
  ],
  onChange,
  value,
}) => (
  <ToggleButtonGroup onChange={onChange} value={value}>
    {data.map((role, key) => (
      <Tooltip title={role.label} key={key}>
        <ToggleButton key={key} value={role.value}>
          {role.label}
        </ToggleButton>
      </Tooltip>
    ))}
  </ToggleButtonGroup>
)

NewsCategorySelector.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
}
