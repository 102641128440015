import { useEffect, useState } from 'react'
import { authHeader } from '../api'
import { API } from 'aws-amplify'

import { useDispatch } from 'react-redux'

export const useTimeSeriesDataListReload = (
  reloadTrigger,
  timeSeriesDataList,
  fromDate,
  toDate
) => {
  const dispatch = useDispatch()
  const [isError, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [timeSeries, setTimeSeries] = useState({ items: [] })

  useEffect(
    () => {
      if (reloadTrigger) {
        setIsLoading(true)
        setTimeSeries({ items: [] })
        const fetchTimeSeries = async (id, fromDate, toDate) => {
          let result = 'unset'

          const API_NAME = 'karmaApi'
          const endpoint = `/sensors/admin/history/${id}?from=${fromDate}&to=${toDate}`
          const options = { method: 'GET', headers: authHeader() }

          result = await API.get(API_NAME, endpoint, options)
            .then(response => {
              return response
            })
            .catch(error => setError(error))

          if (result.data.attributes) {
            const newItems = timeSeries.items
            newItems[id] = result.data.attributes.time_series
            setTimeSeries({
              items: newItems,
            })
            if (timeSeries.items.length === timeSeriesDataList.items.length)
              setIsLoading(false)
          }
        }
        Object.keys(timeSeriesDataList.items).forEach(key => {
          fetchTimeSeries(key, fromDate + ' 00:00:00', toDate + ' 23:59:59')
        })
      }
    },
    [dispatch, fromDate, reloadTrigger, timeSeries, timeSeriesDataList, toDate]
  )

  return { isLoading, isError, timeSeries }
}
