import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../actions'

const userRolesContext = createContext({})

export const ProvideUserRoles = ({ children }) => {
  const value = useUserRoles()

  return (
    <userRolesContext.Provider value={value}>
      {children}
    </userRolesContext.Provider>
  )
}
ProvideUserRoles.propTypes = { children: PropTypes.any }

export const useUserRolesPool = () => useContext(userRolesContext)

const useUserRoles = () => {
  const dispatch = useDispatch()
  const { userRoles } = useSelector(state => state.user)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(
    () => {
      dispatch(userActions.getRoles())
      setLoading(true)
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (userRoles) {
        const { error, fetchDate, items } = userRoles

        if (items) {
          const { data } = items

          if (data) {
            setData(data)
            setLoading(false)
            setFetchDate(fetchDate)
          }
        }

        if (error) {
          setError(error)
          setLoading(false)
          setFetchDate(fetchDate)
        }
      }
    },
    [userRoles]
  )

  return { data, isError, isLoading, fetchDate }
}
