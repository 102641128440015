export const sensorFactorConstants = {
  DELETE_SENSOR_FACTOR_REQUEST: 'DELETE_SENSOR_FACTOR_REQUEST',
  DELETE_SENSOR_FACTOR_SUCCESS: 'DELETE_SENSOR_FACTOR_SUCCESS',
  DELETE_SENSOR_FACTOR_ERROR: 'DELETE_SENSOR_FACTOR_ERROR',

  GET_ALL_REQUEST: 'SENSOR_FACTOR_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'SENSOR_FACTOR_GET_ALL_SUCCESS',
  GET_ALL_ERROR: 'SENSOR_FACTOR_GET_ALL_ERROR',

  SAVE_SENSOR_FACTOR_REQUEST: 'SAVE_SENSOR_FACTOR_REQUEST',
  SAVE_SENSOR_FACTOR_SUCCESS: 'SAVE_SENSOR_FACTOR_SUCCESS',
  SAVE_SENSOR_FACTOR_ERROR: 'SAVE_SENSOR_FACTOR_ERROR',

  UPDATE_SENSOR_FACTOR_REQUEST: 'UPDATE_SENSOR_FACTOR_REQUEST',
  UPDATE_SENSOR_FACTOR_SUCCESS: 'UPDATE_SENSOR_FACTOR_SUCCESS',
  UPDATE_SENSOR_FACTOR_ERROR: 'UPDATE_SENSOR_FACTOR_ERROR',

  SENSORFACTOR_ID_REQUIRED: 'ID erforderlich',
  SENSORFACTOR_SENSOR_REQUIRED: 'Sensor erforderlich',

  SENSORFACTOR_DELETED: 'Sensor Faktor gelöscht',
  SENSORFACTOR_SAVED: 'Sensor Faktor gespeichert',
  SENSORFACTOR_UPDATED: 'Sensor Faktor geupdated',
}
