import { ClearTwoTone } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const DefaultColumnFilter = ({
  column: { filterValue, setFilter },
  placeholder = 'Filter ...',
  size = 'small',
}) => {
  const onChange = event => setFilter(event.target.value || undefined)
  const clearFilter = () => setFilter(undefined)
  const value = filterValue || ''

  return (
    <TextField
      InputProps={{
        endAdornment: value && (
          <InputAdornment
            position={'end'}
            sx={{ position: 'absolute', right: 0 }}
          >
            <IconButton
              onClick={clearFilter}
              edge={'end'}
              size={'small'}
              sx={{ p: 1 }}
            >
              <ClearTwoTone />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      placeholder={placeholder}
      size={size}
      value={value}
    />
  )
}

DefaultColumnFilter.propTypes = {
  column: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
}
