export const contractConstants = {
  CONTRACT_DELETED: 'Vertrag gelöscht',
  CONTRACT_ID_REQUIRED: 'ID erforderlich',
  CONTRACT_NAME_REQUIRED: 'Name und Beschreibung erforderlich',
  CONTRACT_SAVED: 'Vertrag gespeichert',
  CONTRACT_UPDATED: 'Vertrag aktualisiert',

  GET_ALL_CONTRACTS_FAILURE: 'GET_ALL_CONTRACTS_ERROR',
  GET_ALL_CONTRACTS_REQUEST: 'GET_ALL_CONTRACTS_REQUEST',
  GET_ALL_CONTRACTS_SUCCESS: 'GET_ALL_CONTRACTS_SUCCESS',

  GET_BY_ID_ERROR: 'CONTRACT_GET_BY_ID_ERROR',
  GET_BY_ID_REQUEST: 'CONTRACT_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'CONTRACT_GET_BY_ID_SUCCESS',

  REMOVE_CONTRACT_ERROR: 'REMOVE_CONTRACT_ERROR',
  REMOVE_CONTRACT_REQUEST: 'REMOVE_CONTRACT_REQUEST',
  REMOVE_CONTRACT_SUCCESS: 'REMOVE_CONTRACT_SUCCESS',

  SAVE_CONTRACT_ERROR: 'SAVE_CONTRACT_ERROR',
  SAVE_CONTRACT_REQUEST: 'SAVE_CONTRACT_REQUEST',
  SAVE_CONTRACT_SUCCESS: 'SAVE_CONTRACT_SUCCESS',

  UPDATE_CONTRACT_ERROR: 'UPDATE_CONTRACT_ERROR',
  UPDATE_CONTRACT_REQUEST: 'UPDATE_CONTRACT_REQUEST',
  UPDATE_CONTRACT_SUCCESS: 'UPDATE_CONTRACT_SUCCESS',
}
