// modules
import {
  CloseTwoTone,
  DashboardCustomizeTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  ExpandMoreTwoTone,
  SettingsTwoTone,
  InfoTwoTone,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Input,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  Stack,
  Divider,
  CardActions,
  CardHeader,
  SpeedDial,
  SpeedDialAction,
  Chip,
  AlertTitle,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ListManager } from 'react-beautiful-dnd-grid'
import { useDispatch } from 'react-redux'
import { dashboardActions } from '../../../actions'
import {
  useDataPointsByPanelIds,
  useConsumptionByDashboardId,
  usePanels,
  useOperator,
} from '../../../hooks'
import usePagination from './Pagination'
import { PanelComponent } from './'
import { KTextField } from '../../../components'
// scripts
import { userLocales, DDMMYYYY } from '../../../api'

import './style.css'
import { DialogEditDashboard } from './DialogEditDashboard'
import { DialogConfirmDeleteDashboard } from './DialogConfirmDeleteDashboard'

export const DashboardComponent = ({
  dashboard,
  folderPool,
  sensorPool,
  sensorPricePool,
  expanded,
  onChange,
}) => {
  const dispatch = useDispatch()

  const dashboardId = dashboard.id
  const { name } = dashboard.attributes
  const [dashboardName, setDashboardName] = useState(name)

  const getStartAndEndDate = () => {
    const currentDate = new Date()
    const y = currentDate.getFullYear()
    const m = currentDate.getMonth()

    return {
      newPanelStartDate: new Date(y, m - 1, 1),
      newPanelEndDate: new Date(y, m, 0),
    }
  }

  const { newPanelStartDate, newPanelEndDate } = getStartAndEndDate()
  const newPanelGranularity = 'd'

  const { data: panelPool, isLoading: isPanelPoolLoading } = usePanels(
    dashboardId
  )

  const {
    data: panelConsumptionPool,
    isLoading: isPanelConsumptionPoolLoading,
    isFactorError,
    factorErrorSensor,
  } = useConsumptionByDashboardId(dashboardId)

  const { data: operatorData } = useOperator()

  const [editDashboardAnchorEl, setEditDashboardAnchorEl] = useState(false)
  const [deleteDashboardAnchorEl, setDeleteDashboardAnchorEl] = useState(false)

  const [newPanelAnchorEl, setNewPanelAnchorEl] = useState(false)
  const [newPanelSummaryAnchorEl, setNewPanelSummaryAnchorEl] = useState(false)

  // Sensor States
  const [activeSensors, setActiveSensors] = useState([])
  const [activeFolders, setActiveFolders] = useState([])
  const [activeFoldersNames, setActiveFoldersNames] = useState([])
  const [activeSensorsNames, setActiveSensorsNames] = useState([])
  const [energyChecked, setEnergyChecked] = useState(false)
  const [waterChecked, setWaterChecked] = useState(false)
  const [gasChecked, setGasChecked] = useState(false)

  // Pagination States
  const [page, setPage] = useState(1)
  const [sensorsSearch, setSensorsSearch] = useState('')
  const [foldersSearch, setFoldersSearch] = useState('')
  const PER_PAGE = 10
  const [energyData, setEnergyData] = useState([])
  const [waterData, setWaterData] = useState([])
  const [gasData, setGasData] = useState([])
  const [count, setCount] = useState(0)

  // default panel object shape
  const basicPanel = {
    name: null,
    start_date: null,
    end_date: null,
    presentation_level: null,
    indicator: 1,
    graphic_type: 1,
    dashboard_id: dashboardId,
  }

  const [newPanelName, setNewPanelName] = useState(basicPanel.name)
  const [newPanelPositon, setNewPanelPosition] = useState(0)
  const [newPanelIndicator, setNewPanelIndicator] = useState(
    basicPanel.indicator
  )
  const [newPanelGraphicType, setNewPanelGraphicType] = useState(
    basicPanel.graphic_type
  )
  const [newPanelPresentationLevel, setNewPanelPresentationLevel] = useState(
    basicPanel.presentation_level
  )

  const [panels, setPanels] = useState([])
  const [panelsConsumptions, setPanelsConsumptions] = useState([])
  const [panelsDataPoints, setPanelsDataPoints] = useState([])
  const [panelsAndConsumptions, setPanelsAndConsumptions] = useState([])
  const [panelsStartDate, setPanelsStartDate] = useState()
  const [panelsEndDate, setPanelsEndDate] = useState()
  const [panelsGranularity, setPanelsGranularity] = useState('lastFullMonth')
  const [dashboardGranularityData, setDashboardGranularityData] = useState(
    'lastFullMonth'
  )

  const [newPanelElement, setNewPanelElement] = useState(false)
  const [loadTrigger, setLoadTrigger] = useState(false)
  const [showFolderSensors, setShowFolderSensors] = useState(false)

  const {
    dataPoints: dataPointPool,
    isLoading: isDataPointPoolLoading,
  } = useDataPointsByPanelIds(loadTrigger, dashboardId, panels)

  const GermanDate = date => {
    return new Date(date).toLocaleDateString(userLocales, DDMMYYYY)
  }

  const indicatorType = {
    consumption: 1,
    consumption_per_sqm: 2,
    cost: 3,
    cost_per_sqm: 4,
    co2: 5,
    co2_per_sqm: 6,
  }

  useEffect(
    () => {
      if (isPanelPoolLoading || !panelPool) return

      setPanels(panelPool)
    },
    [dispatch, panelPool, isPanelPoolLoading]
  )

  useEffect(
    () => {
      if (isPanelConsumptionPoolLoading || !panelConsumptionPool) return

      const uniqueData = panelConsumptionPool.filter(
        (item, index, self) => index === self.findIndex(t => t.id === item.id)
      )

      setPanelsConsumptions(uniqueData)
    },
    [dispatch, isPanelConsumptionPoolLoading, panelConsumptionPool]
  )

  useEffect(
    () => {
      if (!panels && !isPanelPoolLoading) return

      setLoadTrigger(true)
    },
    [isPanelPoolLoading, panels]
  )

  useEffect(
    () => {
      if (dataPointPool && !isDataPointPoolLoading && loadTrigger) {
        setLoadTrigger(false)
        setPanelsDataPoints(dataPointPool)
      }
    },
    [dataPointPool, isDataPointPoolLoading, loadTrigger]
  )

  useEffect(
    () => {
      if (!panels || !panelsConsumptions || !panelsDataPoints) return

      const modifiedData = panels.map(panel => {
        const sensorIds = panelsDataPoints.items[panel.id]
          ? panelsDataPoints.items[panel.id]
          : []
        const consumptionData = panelsConsumptions
          .filter(sensor => sensorIds.includes(Number(sensor.id)))
          .map(sensor => ({ ...sensor, id: Number(sensor.id) }))

        return { ...panel, consumptionData }
      })

      const sortedData = modifiedData.sort(
        (a, b) => a.attributes.position - b.attributes.position
      )

      setPanelsAndConsumptions(sortedData)
    },
    [panels, panelsConsumptions, panelsDataPoints]
  )

  useEffect(
    () => {
      if (panels.length === 0) {
        setNewPanelElement(true)
        setDashboardGranularityData('lastFullMonth')
      } else {
        const { start_date, end_date } = panels[0].attributes

        const today = new Date()
        today.setHours(0, 0, 0, 0)

        const startDate = new Date(start_date)
        startDate.setHours(0, 0, 0, 0)

        const endDate = new Date(end_date)
        endDate.setHours(0, 0, 0, 0)

        const lastFullMonthStart = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        )

        const lastThirtyDaysStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 30
        )

        const lastTwoMonthsStart = new Date(
          today.getFullYear(),
          today.getMonth() - 2,
          1
        )

        const lastSixMonthsStart = new Date(
          today.getFullYear(),
          today.getMonth() - 6,
          1
        )
        const thisYearStart = new Date(today.getFullYear(), 0, 1)
        const lastYearStart = new Date(today.getFullYear() - 1, 0, 1)
        const lastYearEnd = new Date(today.getFullYear() - 1, 12, 0)

        let dashboardGranularityData = 'lastFullMonth'

        if (startDate.getTime() === lastFullMonthStart.getTime()) {
          dashboardGranularityData = 'lastFullMonth'
        } else if (startDate.getTime() === lastThirtyDaysStart.getTime()) {
          dashboardGranularityData = 'lastThirtyDays'
        } else if (startDate.getTime() === lastTwoMonthsStart.getTime()) {
          dashboardGranularityData = 'lastTwoMonths'
        } else if (startDate.getTime() === lastSixMonthsStart.getTime()) {
          dashboardGranularityData = 'lastSixMonths'
        } else if (startDate.getTime() === thisYearStart.getTime()) {
          dashboardGranularityData = 'thisYear'
        } else if (
          startDate.getTime() === lastYearStart.getTime() &&
          endDate.getTime() === lastYearEnd.getTime()
        ) {
          dashboardGranularityData = 'lastYear'
        }
        setNewPanelElement(false)
        setDashboardGranularityData(dashboardGranularityData)
      }
    },
    [panels]
  )

  useEffect(
    () => {
      if (isPanelPoolLoading) return

      if (panels.length >= 1) {
        const lastPanel = panels[panels.length - 1]

        const { indicator, graphic_type, position } = lastPanel.attributes

        setNewPanelIndicator(indicator)
        setNewPanelGraphicType(graphic_type)
        setNewPanelPosition(position + 1)
      }
    },
    [isPanelPoolLoading, panels]
  )

  const onSavePanel = () => {
    const startYear = newPanelStartDate.getFullYear()
    const startMonth = ('0' + (newPanelStartDate.getMonth() + 1)).slice(-2)
    const startDay = ('0' + newPanelStartDate.getDate()).slice(-2)

    const endYear = newPanelEndDate.getFullYear()
    const endMonth = ('0' + (newPanelEndDate.getMonth() + 1)).slice(-2)
    const endDay = ('0' + newPanelEndDate.getDate()).slice(-2)
    const newPanel = {
      dashboard_id: dashboardId,
      name: newPanelName,
      position: Number(newPanelPositon),
      indicator: Number(newPanelIndicator),
      graphic_type: Number(newPanelGraphicType),
      start_date: startYear + '-' + startMonth + '-' + startDay,
      end_date: endYear + '-' + endMonth + '-' + endDay,
      granularity: newPanelGranularity,
      presentation_level: newPanelPresentationLevel,
      sensor_ids: activeSensors,
    }
    dispatch(dashboardActions.savePanel(newPanel))
  }

  const reorderList = (startIndex, endIndex) => {
    const result = Array.from(panelsAndConsumptions)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    sortList(result, startIndex, endIndex)
  }

  const sortList = (list, startIndex, endIndex) => {
    const panel = list[endIndex]
    const dashboardId = Number(panel.attributes.dashboard_id)
    const panelId = Number(panel.id)
    const movePanel = {
      old_position: Number(startIndex),
      new_position: Number(endIndex),
    }
    dispatch(dashboardActions.movePanel(movePanel, dashboardId, panelId))
    setPanelsAndConsumptions(list)
  }

  const displayFolders = data => {
    const FILTER_SEARCH = f =>
      f.attributes.name.toLowerCase().includes(foldersSearch.toLowerCase())

    return data.filter(FILTER_SEARCH).map(folder => {
      const { name: folderName, entity_id: id } = folder.attributes
      const checked = activeFolders.includes(id)
      const onChange = () => handleCheckedFolders(id, folderName)
      const control = (
        <Checkbox checked={checked} onChange={onChange} name={folderName} />
      )

      return <FormControlLabel key={id} control={control} label={folderName} />
    })
  }

  const displaySensors = data =>
    data.currentData().map(sensor => {
      const { entity_id, folder_id, name: sensorName } = sensor.attributes
      const folder = folderPool.find(f => Number(f.id) === Number(folder_id))
      const folderName = folder ? folder.attributes.name : ''

      const label = `${sensorName} | Gebäude ${folderName}`

      const checked = activeSensors.includes(entity_id)
      const onChange = () =>
        handleCheckedSensors(entity_id, label, folder_id, folderName)
      const control = (
        <Checkbox checked={checked} name={label} onChange={onChange} />
      )

      return (
        <FormControlLabel control={control} key={entity_id} label={label} />
      )
    })

  useEffect(
    () => {
      if (!sensorPool || !folderPool) return

      const FILTER_INDICATOR = sensor =>
        newPanelIndicator === indicatorType.consumption
          ? true
          : newPanelIndicator === indicatorType.consumption_per_sqm
            ? sensor.attributes.area_in_sqm !== null
            : newPanelIndicator === indicatorType.cost
              ? sensorPricePool.find(
                  s => s.sensor_id === sensor.attributes.entity_id
                )
              : newPanelIndicator === indicatorType.cost_per_sqm
                ? sensorPricePool.find(
                    s => s.sensor_id === sensor.attributes.entity_id
                  ) && sensor.attributes.area_in_sqm !== null
                : newPanelIndicator === indicatorType.co2
                  ? sensor.attributes.co2_factor !== null
                  : sensor.attributes.co2_factor !== null &&
                    sensor.attributes.area_in_sqm !== null

      const FILTER_FOLDERS = f =>
        newPanelPresentationLevel === 1
          ? activeFolders.includes(Number(f.attributes.folder_id))
          : true

      const FILTER_FOLDER_SENSORS = sensor =>
        showFolderSensors ? sensor.attributes.folder_id !== null : true

      const FILTER_SEARCH = sensor => {
        const { name: sensorName, folder_id } = sensor.attributes
        const folder = folderPool.find(f => Number(f.id) === Number(folder_id))
        const label = `${sensorName} | Gebäude ${
          folder ? folder.attributes.name : ''
        }`

        return label.toLowerCase().includes(sensorsSearch.toLowerCase())
      }

      const FILTER_BY_TYPE = type =>
        sensorPool
          .filter(
            sensor =>
              sensor.attributes.name !== null &&
              (sensor.attributes.type_in_folder === type ||
                sensor.attributes.type_in_folder === `${type}_detail`)
          )
          .filter(FILTER_INDICATOR)
          .filter(FILTER_SEARCH)
          .filter(FILTER_FOLDERS)
          .filter(FILTER_FOLDER_SENSORS)

      const count = Math.ceil(FILTER_BY_TYPE('energy').length / PER_PAGE)
      setCount(count)

      setEnergyData(FILTER_BY_TYPE('energy'))
      setWaterData(FILTER_BY_TYPE('water'))
      setGasData(FILTER_BY_TYPE('gas'))
    },
    [
      sensorsSearch,
      activeFolders,
      sensorPool,
      newPanelPresentationLevel,
      folderPool,
      sensorPricePool,
      newPanelIndicator,
      indicatorType.consumption,
      indicatorType.consumption_per_sqm,
      indicatorType.cost,
      indicatorType.cost_per_sqm,
      indicatorType.co2,
      showFolderSensors,
    ]
  )

  useEffect(
    () => {
      const today = new Date()
      let startDate
      let endDate

      const formatDate = date => {
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()

        return `${year}-${month < 10 ? '0' + month : month}-${
          day < 10 ? '0' + day : day
        }`
      }

      switch (dashboardGranularityData) {
        case 'lastFullMonth':
          startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1)
          break
        case 'lastThirtyDays':
          startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 30
          )
          endDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
          )
          break
        case 'lastTwoMonths':
          startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1)
          break
        case 'lastSixMonths':
          startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1)
          break
        case 'thisYear':
          startDate = new Date(today.getFullYear(), 0, 1)
          endDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
          )
          break
        case 'lastYear':
          startDate = new Date(today.getFullYear() - 1, 0, 1)
          endDate = new Date(today.getFullYear() - 1, 12, 0)
          break
        default:
          startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1)
          break
      }

      endDate = endDate || new Date(today.getFullYear(), today.getMonth(), 0)

      setPanelsStartDate(formatDate(startDate))
      setPanelsEndDate(formatDate(endDate))
      setPanelsGranularity(
        dashboardGranularityData === 'lastFullMonth' ||
        dashboardGranularityData === 'lastThirtyDays'
          ? 'd'
          : 'm'
      )
    },
    [dashboardGranularityData]
  )

  const graphicTypeMenuData = [
    { label: 'Kreisdiagramm', value: 1 },
    { label: 'Balkendiagramm', value: 2 },
    { label: 'Ranking', value: 3 },
  ]

  const indicatorMenuData = [
    { label: 'Verbrauch', value: 1 },
    { label: 'Verbrauch/m²', value: 2 },
    { label: 'Kosten', value: 3 },
    { label: 'Kosten/m²', value: 4 },
    { label: 'CO₂-Ausstoß', value: 5 },
    { label: 'CO₂-Ausstoß/m²', value: 6 },
  ]

  const _ENERGYDATA = usePagination(energyData, PER_PAGE)
  const _WATTERDATA = usePagination(waterData, PER_PAGE)
  const _GASDATA = usePagination(gasData, PER_PAGE)

  const handlePageChange = (event, page) => {
    setPage(page)
    _ENERGYDATA.jump(page)
    _WATTERDATA.jump(page)
    _GASDATA.jump(page)
  }

  const handleCheckedSensors = (entity_id, name, folderId, folderName) => {
    const _activeSensors = activeSensors.includes(entity_id)
      ? activeSensors.filter(f => f !== entity_id)
      : [...activeSensors, entity_id]

    const _activeSensorNames = activeSensorsNames.includes(name)
      ? activeSensorsNames.filter(f => f !== name)
      : [...activeSensorsNames, name]

    let _activeFolders = [...activeFolders]
    let _activeFoldersNames = [...activeFoldersNames]

    if (!_activeFolders.includes(folderId)) {
      _activeFolders.push(folderId)
      _activeFoldersNames.push(folderName)
    }

    const remainingSensors = sensorPool.some(
      sensor =>
        sensor.attributes.folder_id === folderId &&
        _activeSensors.includes(entity_id)
    )

    if (!remainingSensors && newPanelPresentationLevel === 2) {
      _activeFolders = _activeFolders.filter(f => f !== folderId)
      _activeFoldersNames = _activeFoldersNames.filter(f => f !== folderName)
    }

    setActiveSensors(_activeSensors)
    setActiveSensorsNames(_activeSensorNames)
    setActiveFolders(_activeFolders)
    setActiveFoldersNames(_activeFoldersNames)
  }

  const handleCheckedFolders = (entity_id, name) => {
    setActiveFolders(
      activeFolders.includes(entity_id)
        ? activeFolders.filter(f => f !== entity_id)
        : [...activeFolders, entity_id]
    )

    setActiveFoldersNames(
      activeFoldersNames.includes(name)
        ? activeFoldersNames.filter(f => f !== name)
        : [...activeFoldersNames, name]
    )
  }

  const handleToggleAllSensors = (event, type) => {
    const sensors =
      type === 'energy'
        ? [...energyData]
        : type === 'water'
          ? [...waterData]
          : type === 'gas'
            ? [...gasData]
            : null

    const updatedEntityIds = []
    const updatedSensorNames = []
    const uptadedFolderIds = []
    const uptadedFolderNames = []
    let _activeFolders = [...activeFolders]
    let _activeFoldersNames = [...activeFoldersNames]

    sensors.map(sensor => {
      updatedEntityIds.push(sensor.attributes.entity_id)
      const folder = folderPool.find(
        f => Number(f.id) === Number(sensor.attributes.folder_id)
      )
      const folderName = folder ? folder.attributes.name : ''

      const label = `${sensor.attributes.name} | Gebäude ${folderName}`
      updatedSensorNames.push(label)
      uptadedFolderNames.push(uptadedFolderNames)
      uptadedFolderIds.push(sensor.attributes.folder_id)

      if (folder && !_activeFolders.includes(folder.attributes.entity_id)) {
        _activeFolders.push(folder.attributes.entity_id)
        _activeFoldersNames.push(folderName)
      }
      return null
    })

    let updatedActiveEntityIds = event.target.checked
      ? [...activeSensors, ...updatedEntityIds]
      : activeSensors.filter(entityId => !updatedEntityIds.includes(entityId))
    let updatedActiveNames = event.target.checked
      ? [...activeSensorsNames, ...updatedSensorNames]
      : activeSensors.filter(name => !updatedSensorNames.includes(name))

    if (event.target.checked === false && updatedActiveEntityIds.length === 0) {
      _activeFolders = []
      _activeFoldersNames = []
      updatedActiveEntityIds = []
      updatedActiveNames = []
    }

    setActiveSensors(updatedActiveEntityIds)
    setActiveSensorsNames(updatedActiveNames)
    setActiveFolders(_activeFolders)
    setActiveFoldersNames(_activeFoldersNames)
  }

  const dashboardGranularityDataSwitch = [
    { label: 'letzter voller Monat', value: 'lastFullMonth' },
    { label: 'letzten 30 Tage', value: 'lastThirtyDays' },
    { label: 'letzten 2 Monate', value: 'lastTwoMonths' },
    { label: 'letzten 6 Monate', value: 'lastSixMonths' },
    { label: 'dieses Jahr (Januar bis heute)', value: 'thisYear' },
    { label: 'letztes Jahr', value: 'lastYear' },
  ]

  const handleDashboardGranularityChange = value => {
    const today = new Date()
    let startDate
    let endDate
    let granularity

    const formatDate = date => {
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()

      return `${year}-${month}-${day}`
    }

    switch (value) {
      case 'lastFullMonth':
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        granularity = 'd'
        break
      case 'lastThirtyDays':
        startDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 30
        )
        endDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        )
        granularity = 'd'
        break
      case 'lastTwoMonths':
        startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1)
        granularity = 'm'
        break
      case 'lastSixMonths':
        startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1)
        granularity = 'm'
        break
      case 'thisYear':
        startDate = new Date(today.getFullYear(), 0, 1)
        endDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        )
        granularity = 'm'
        break
      case 'lastYear':
        startDate = new Date(today.getFullYear() - 1, 0, 1)
        endDate = new Date(today.getFullYear() - 1, 12, 0)
        granularity = 'm'
        break
      default:
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        break
    }

    endDate = endDate || new Date(today.getFullYear(), today.getMonth(), 0)
    dispatch(
      dashboardActions.updatePanelsByDashboardId(dashboardId, {
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
        granularity: granularity,
      })
    )
  }

  const actions = [
    {
      icon: <DashboardCustomizeTwoTone />,
      name: 'Kachel hinzufügen',
      onClick: () => setNewPanelAnchorEl(true),
    },
    {
      icon: <EditTwoTone />,
      name: `${dashboardName} bearbeiten`,
      onClick: () => setEditDashboardAnchorEl(true),
    },
    {
      icon: <DeleteTwoTone />,
      name: `${dashboardName} löschen`,
      onClick: () => setDeleteDashboardAnchorEl(true),
    },
  ]
  const dateRangeLabel = `${GermanDate(panelsStartDate)} - ${GermanDate(
    panelsEndDate
  )}`
  return (
    <Accordion onChange={onChange} expanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
        <Typography variant={'h6'}>{dashboardName}</Typography>
        <Chip label={dateRangeLabel} sx={{ ml: 2 }} />
      </AccordionSummary>

      <AccordionDetails>
        <Card>
          <CardContent
            sx={{
              '& a': { color: 'black' },
              flex: '1 1 auto',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <FormControl>
              <KTextField
                select
                onChange={event => {
                  setDashboardGranularityData(event.target.value)
                  handleDashboardGranularityChange(event.target.value)
                }}
                value={dashboardGranularityData}
                sx={{
                  pointerEvents: 'auto',
                  mt: '-15px',
                  ml: '-15px',
                }}
              >
                {dashboardGranularityDataSwitch.map((item, key) => {
                  const { label, value } = item

                  return (
                    <MenuItem key={key} value={value}>
                      {label}
                    </MenuItem>
                  )
                })}
              </KTextField>
            </FormControl>
            {!isPanelPoolLoading &&
              !isPanelConsumptionPoolLoading &&
              !isDataPointPoolLoading && (
                <>
                  <Collapse in={isFactorError}>
                    <Alert severity={'warning'}>
                      Bitte prüfen Sie die Faktoren für Messpunkt{' '}
                      {factorErrorSensor}.
                    </Alert>
                  </Collapse>

                  <ListManager
                    items={panelsAndConsumptions}
                    direction={'horizontal'}
                    maxItems={2}
                    render={panel => (
                      <PanelComponent
                        panel={panel}
                        panelStartDate={panelsStartDate}
                        panelEndDate={panelsEndDate}
                        panelGranularity={panelsGranularity}
                        folderPool={folderPool}
                        sensorPool={sensorPool}
                        sensorPricePool={sensorPricePool}
                      />
                    )}
                    onDragEnd={reorderList}
                    className={'listmanager'}
                  />
                </>
              )}
            <Grid container justifyContent={'center'}>
              {newPanelElement && (
                <Grid item xs={12} sm={6}>
                  <Card sx={{ border: '1px dotted black', height: '250px' }}>
                    <CardHeader
                      action={
                        <Tooltip title={'Dashboard Einstellungen'}>
                          <IconButton onClick={() => setNewPanelElement(false)}>
                            <CloseTwoTone />
                          </IconButton>
                        </Tooltip>
                      }
                    />

                    <CardContent>
                      <Alert severity={'info'}>
                        <AlertTitle>Erstellen Sie Ihre erste Kachel</AlertTitle>
                      </Alert>
                    </CardContent>

                    <CardActions>
                      <Button onClick={() => setNewPanelAnchorEl(true)}>
                        Neue Kachel
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>

        {expanded && (
          <SpeedDial
            ariaLabel={'SpeedDial'}
            icon={<SettingsTwoTone />}
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
          >
            {actions.map((action, key) => (
              <SpeedDialAction
                icon={action.icon}
                key={action.name}
                onClick={action.onClick}
                tooltipTitle={action.name}
                sx={key === 2 ? { color: 'red' } : null}
              />
            ))}
          </SpeedDial>
        )}
      </AccordionDetails>

      <DialogEditDashboard
        dashboardId={dashboardId}
        name={dashboardName}
        onClose={() => setEditDashboardAnchorEl(null)}
        open={Boolean(editDashboardAnchorEl)}
        granularity={dashboardGranularityData}
        onChangeGranularity={event => {
          setDashboardGranularityData(event.target.value)
          handleDashboardGranularityChange(event.target.value)
        }}
        onChangeName={name => setDashboardName(name)}
      />

      <DialogConfirmDeleteDashboard
        dashboardId={dashboardId}
        name={dashboardName}
        onClose={() => setDeleteDashboardAnchorEl(null)}
        open={Boolean(deleteDashboardAnchorEl)}
      />

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={() => {
          setNewPanelAnchorEl(false)
          setNewPanelPresentationLevel(null)
          setActiveFolders([])
          setActiveSensors([])
          setActiveFoldersNames([])
          setActiveSensorsNames([])
          setEnergyChecked(false)
          setWaterChecked(false)
          setGasChecked(false)
        }}
        open={Boolean(newPanelAnchorEl)}
      >
        <DialogTitle>Neue Kachel</DialogTitle>
        <DialogContent>
          <Stack direction={'row'} sx={{ width: 1 }}>
            <TextField
              label={'Kachel Name'}
              onChange={event => setNewPanelName(event.target.value)}
              value={newPanelName || ''}
            />

            <TextField
              label={'Indikator'}
              onChange={event => setNewPanelIndicator(event.target.value)}
              select
              value={newPanelIndicator || ''}
            >
              {indicatorMenuData.map((item, key) => (
                <MenuItem key={key} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label={'Grafiktyp'}
              onChange={event => setNewPanelGraphicType(event.target.value)}
              select
              value={newPanelGraphicType || ''}
            >
              {graphicTypeMenuData.map((item, key) => (
                <MenuItem key={key} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>

          {/* presentation Level */}
          <FormControl>
            <FormLabel>
              Darstellungsebene{' '}
              <Tooltip
                title={
                  'Eine Kachel kann auf Gebäude- oder Messpunktebene erstellt werden. In der Gebäudeebene werden alle ausgewählten Messpunkte unter dem Gebäudenamen zusammengefasst. In der Messpunktebene wird jeder Messpunkt einzeln aufgelistet.'
                }
              >
                <InfoTwoTone />
              </Tooltip>
            </FormLabel>

            <RadioGroup
              row
              onChange={event => {
                setNewPanelPresentationLevel(Number(event.target.value))
                setActiveFolders([])
                setActiveSensors([])
                setActiveFoldersNames([])
                setActiveSensorsNames([])
                setEnergyChecked(false)
                setWaterChecked(false)
                setGasChecked(false)
              }}
              onClick={event => {
                setNewPanelPresentationLevel(Number(event.target.value))
              }}
            >
              <FormControlLabel
                value={'1'}
                control={<Radio />}
                label={'Gebäude'}
                labelPlacement={'start'}
              />
              <FormControlLabel
                value={'2'}
                control={<Radio />}
                label={'Messpunkte'}
                labelPlacement={'start'}
              />
            </RadioGroup>
          </FormControl>

          {newPanelPresentationLevel === 1 &&
            newPanelPresentationLevel !== null && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
                  <Typography>
                    {`Gebäude (${activeFolders.length} / ${folderPool.length})`}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid
                    container
                    spacing={2}
                    sx={{ height: '500px', overflow: 'auto' }}
                  >
                    <Grid item xs={12} sm={4}>
                      <Input
                        onChange={event => setFoldersSearch(event.target.value)}
                        placeholder={'Suchen...'}
                        sx={{ m: 0, minHeight: 23, pl: 1 }}
                        value={foldersSearch}
                        variant={'standard'}
                      />
                      <FormGroup>{displayFolders(folderPool)}</FormGroup>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
          {newPanelPresentationLevel !== null && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
                <Typography>
                  {`Messpunkte (${activeSensors.length} / ${
                    sensorPool.length
                  })`}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container spacing={2}>
                  {operatorData?.isAdmin && (
                    <Grid item xs={12} sm={12}>
                      <FormGroup>
                        <FormControlLabel
                          label={'Nur zugeordnete Sensoren anzeigen'}
                          control={
                            <Checkbox
                              checked={showFolderSensors}
                              onClick={() =>
                                setShowFolderSensors(!showFolderSensors)
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </Grid>
                  )}
                  {/* Strom */}
                  <Grid item xs={12} sm={4}>
                    <FormGroup>
                      <FormControlLabel
                        label={'Strom'}
                        control={
                          <Checkbox
                            checked={energyChecked}
                            onChange={event => {
                              handleToggleAllSensors(event, 'energy')
                              setEnergyChecked(!energyChecked)
                            }}
                          />
                        }
                      />
                      {displaySensors(_ENERGYDATA)}
                    </FormGroup>
                  </Grid>
                  {/* Water */}
                  <Grid item xs={12} sm={4}>
                    <FormGroup>
                      <FormControlLabel
                        label={'Wasser'}
                        control={
                          <Checkbox
                            checked={waterChecked}
                            onChange={event => {
                              handleToggleAllSensors(event, 'water')
                              setWaterChecked(!waterChecked)
                            }}
                          />
                        }
                      />
                      {displaySensors(_WATTERDATA)}
                    </FormGroup>
                  </Grid>
                  {/* Gas */}
                  <Grid item xs={12} sm={4}>
                    <FormGroup>
                      <FormControlLabel
                        label={'Wärme'}
                        control={
                          <Checkbox
                            checked={gasChecked}
                            onChange={event => {
                              handleToggleAllSensors(event, 'gas')
                              setGasChecked(!gasChecked)
                            }}
                          />
                        }
                      />
                      {displaySensors(_GASDATA)}
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      onChange={event => setSensorsSearch(event.target.value)}
                      placeholder={'Suchen...'}
                      sx={{ m: 0, minHeight: 23, pl: 1 }}
                      value={sensorsSearch}
                      variant={'standard'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Pagination
                      count={count}
                      size={'large'}
                      page={page}
                      variant={'outlined'}
                      shape={'rounded'}
                      onChange={handlePageChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              newPanelPresentationLevel === null || newPanelName === null
            }
            sx={{
              '&.Mui-disabled': {
                color: '#c0c0c0',
              },
            }}
            onClick={() => setNewPanelSummaryAnchorEl(true)}
          >
            Zusammenfassung
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={() => setNewPanelSummaryAnchorEl(null)}
        open={Boolean(newPanelSummaryAnchorEl)}
      >
        <DialogTitle>Zusammenfassung</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {activeFolders.length > 0 && (
                <Stack sx={{ border: 'solid 1px #eee', borderRadius: 3 }}>
                  <Typography sx={{ m: 2 }}>Ausgewählte Gebäude</Typography>
                  <Divider />
                  <List sx={{ maxHeight: 400, overflow: 'auto' }}>
                    {activeFoldersNames.map((folder, key) => (
                      <ListItem key={key}>
                        <ListItemText primary={folder} />
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {activeSensors.length > 0 && (
                <Stack sx={{ border: 'solid 1px #eee', borderRadius: 3 }}>
                  <Typography sx={{ m: 2 }}>Ausgewählte Messpunkte</Typography>
                  <Divider />
                  <List sx={{ maxHeight: 400, overflow: 'auto' }}>
                    {activeSensorsNames.map((sensor, key) => (
                      <ListItem key={key}>
                        <ListItemText primary={sensor} />
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setNewPanelSummaryAnchorEl(null)}>
            Zurück
          </Button>
          <Button
            disabled={
              newPanelPresentationLevel === null || newPanelName === null
            }
            sx={{
              '&.Mui-disabled': {
                color: '#c0c0c0',
              },
            }}
            onClick={onSavePanel}
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </Accordion>
  )
}

DashboardComponent.propTypes = {
  dashboard: PropTypes.object,
  folderPool: PropTypes.array,
  sensorPool: PropTypes.array,
  sensorPricePool: PropTypes.array,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
}
