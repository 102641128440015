import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { dashboardActions } from '../../../actions'
import { CancelTwoTone, DeleteTwoTone } from '@mui/icons-material'

export const DialogConfirmDeleteDashboard = ({
  dashboardId,
  name,
  open,
  onClose,
}) => {
  const dispatch = useDispatch()

  const deleteDashboard = dashboardId =>
    dispatch(dashboardActions.remove(dashboardId))

  const onDelete = () => {
    deleteDashboard(dashboardId)
    onClose()
  }

  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle>Bestätigung erforderlich</DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Alert severity={'info'} sx={{ p: 2 }}>
          Möchten Sie Dashboard <strong>{name}</strong> wirklich löschen?
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button color={'error'} onClick={onDelete}>
          <DeleteTwoTone />
          Löschen
        </Button>

        <Button onClick={onClose}>
          <CancelTwoTone />
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogConfirmDeleteDashboard.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  dashboardId: PropTypes.string.isRequired,
}
