import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Collapse,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material'
import {
  AssignmentIndTwoTone,
  EditLocationTwoTone,
  FaceTwoTone,
  HttpTwoTone,
  MailTwoTone,
  MoreVertTwoTone,
  PhoneTwoTone,
  PrintTwoTone,
  SaveTwoTone,
  SmartphoneTwoTone,
  VpnKeyTwoTone,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// scripts
import { userActions } from '../../actions'
import {
  decodeAppleModel,
  isValidEmail,
  isValidLength,
  isValidPassword,
} from '../../api'
import {
  AM,
  AMItem,
  Preloader,
  KPage,
  KSelect,
  KTextField,
  DialogProfilePicture,
} from '../../components'
import { svgConstants as SVG } from '../../constants'
import {
  useFolderPool,
  useOperator,
  useSensorPool,
  useUserRolesPool,
} from '../../hooks'
// styles
import avatar from '../../assets/img/default-avatar.png'

export const UserProfile = () => {
  const dispatch = useDispatch()
  const { tabId } = useParams()

  const { fileUpload } = useSelector(state => state.user)
  const { data: userRolePool } = useUserRolesPool()
  const { data: folderPool, isLoading: isFolderPoolLoading } = useFolderPool()
  const { data: sensorPool, isLoading: isSensorPoolLoading } = useSensorPool()

  const [tab, setTab] = useState(Number(tabId) || 0)
  const [userId, setUserId] = useState(null)
  const [userData, setUserData] = useState({})

  const [fromPassword, setFromPassword] = useState('')
  const [toPassword, setToPassword] = useState('')

  const [pushDevices, setPushDevices] = useState([])
  const [reports, setReports] = useState([])
  const [actionMenu, showActionMenu] = useState(null)

  const renderReportLevel = mask => {
    let build = []

    switch (mask) {
      case 0:
        build = []
        break
      case 1:
        build = [SVG.CATEGORY_FEELING_INACTIVE]
        break
      case 2:
        build = [SVG.CATEGORY_SECURITY_INACTIVE]
        break
      case 3:
        build = [SVG.CATEGORY_FEELING_INACTIVE, SVG.CATEGORY_SECURITY_INACTIVE]
        break
      case 4:
        build = [SVG.CATEGORY_PERFORMANCE_INACTIVE]
        break
      case 5:
        build = [
          SVG.CATEGORY_FEELING_INACTIVE,
          SVG.CATEGORY_PERFORMANCE_INACTIVE,
        ]
        break
      case 6:
        build = [
          SVG.CATEGORY_SECURITY_INACTIVE,
          SVG.CATEGORY_PERFORMANCE_INACTIVE,
        ]
        break
      case 7:
        build = [
          SVG.CATEGORY_FEELING_INACTIVE,
          SVG.CATEGORY_SECURITY_INACTIVE,
          SVG.CATEGORY_PERFORMANCE_INACTIVE,
        ]
        break
      default:
        console.error('unhandled mask:', mask)
        break
    }

    return build.map((item, key) => {
      return (
        <Box
          alt={''}
          component={'img'}
          key={key}
          src={`${window.location.origin}${item}`}
          sx={{ width: 30, mr: 1 }}
        />
      )
    })
  }

  const {
    data: operatorData,
    isError: operatorError,
    isLoading: operatorLoading,
  } = useOperator()

  useEffect(
    () => {
      if (operatorData) {
        const {
          city,
          country,
          devices,
          email,
          email_contact,
          fax,
          first_name,
          homepage,
          id,
          image_url,
          last_name,
          telephone,
          telephone_mobile,
          reports,
          role_id,
          street,
          zip,
        } = operatorData
        const { fileUploading, location } = fileUpload

        let role
        for (const item of userRolePool.filter(f => Number(f.id) === role_id)) {
          const { label } = item.attributes

          role = `${label}`
        }

        setUserData({
          city,
          country,
          email,
          email_contact,
          fax,
          first_name,
          homepage,
          image_url: fileUploading ? image_url : location,
          last_name,
          telephone,
          telephone_mobile,
          role,
          street,
          zip,
        })

        setUserId(id)

        if (devices) setPushDevices(devices)

        if (reports) setReports(reports)
      }
    },
    [operatorData, fileUpload, userRolePool]
  )

  const [openProfilePicture, setOpenProfilePicture] = useState(false)

  const isPageLoading =
    operatorLoading || isFolderPoolLoading || isSensorPoolLoading

  return (
    <>
      {/* preloader */}
      <Preloader
        caption={'Profil laden ...'}
        error={operatorError}
        isLoading={isPageLoading}
      />
      {/* preloader end */}

      {/* profile picture */}
      <DialogProfilePicture
        open={openProfilePicture}
        onClose={() => setOpenProfilePicture(false)}
      />
      {/* profile picture end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Benutzer Profil'}
        open={Boolean(actionMenu)}
        onClose={() => showActionMenu(null)}
        historyUrlTarget={'users'}
      >
        <AMItem
          caption={'Speichern'}
          disabled={!userData.first_name || !userData.last_name}
          icon={<SaveTwoTone />}
          onClick={() => dispatch(userActions.updateOperator(userId, userData))}
        />
      </AM>
      {/* action menu end */}

      {!isPageLoading && (
        <KPage
          avatar={
            <Avatar
              onClick={() => setOpenProfilePicture(true)}
              src={userData.image_url ? String(userData.image_url) : avatar}
            />
          }
          action={
            <IconButton onClick={event => showActionMenu(event.currentTarget)}>
              <MoreVertTwoTone />
            </IconButton>
          }
          isLoading={isPageLoading}
          title={
            !isPageLoading && `${userData.first_name} ${userData.last_name}`
          }
        >
          <Tabs
            centered
            indicatorColor={'primary'}
            onChange={(event, newTab) => setTab(newTab)}
            sx={{ my: 5 }}
            textColor={'primary'}
            value={tab}
          >
            <Tab label={'Stammdaten'} />
            <Tab label={'Passwort'} />
            <Tab label={'Push Geräte'} />
            <Tab label={'Berichte Abos'} />
          </Tabs>

          {/* Stammdaten */}
          <Collapse in={tab === 0}>
            <form
              name={'form'}
              onSubmit={event => {
                event.preventDefault()
                event.stopPropagation()
              }}
            >
              {/* Vorname */}
              <KTextField
                error={!isValidLength(userData.first_name, 2)}
                icon={<FaceTwoTone />}
                label={`Vorname`}
                onChange={event => {
                  const first_name = event.target.value
                  setUserData(prev => ({ ...prev, first_name }))
                }}
                required
                type={'text'}
                value={userData.first_name || ''}
              />

              {/* Name */}
              <KTextField
                error={!isValidLength(userData.last_name, 2)}
                icon={<FaceTwoTone />}
                label={`Name`}
                onChange={event => {
                  const last_name = event.target.value
                  setUserData(prev => ({ ...prev, last_name }))
                }}
                required
                type={'text'}
                value={userData.last_name || ''}
              />

              {/* Rolle */}
              <KTextField
                disabled
                icon={<AssignmentIndTwoTone />}
                label={`Rolle`}
                type={'text'}
                value={userData.role || ''}
              />

              {/* KarmaID */}
              <KTextField
                disabled
                error={!isValidEmail(userData.email)}
                helperText={
                  'bei Registrierung verwendete E-Mail (nicht änderbar)'
                }
                icon={<MailTwoTone />}
                label={`KarmaID`}
                required
                value={userData.email || ''}
              />

              {/* Kontakt E-Mail */}
              <KTextField
                error={
                  Boolean(userData.email_contact) &&
                  !isValidEmail(userData.email_contact)
                }
                icon={<MailTwoTone />}
                label={`Kontakt E-Mail`}
                onChange={event => {
                  const email_contact = event.target.value
                  setUserData(prev => ({ ...prev, email_contact }))
                }}
                type={'email'}
                value={userData.email_contact || ''}
              />

              {/* Telefon Büro */}
              <KTextField
                icon={<PhoneTwoTone />}
                label={`Telefon Büro`}
                onChange={event => {
                  const telephone = event.target.value
                  setUserData(prev => ({ ...prev, telephone }))
                }}
                type={'tel'}
                value={userData.telephone || ''}
              />

              {/* Telefon Mobil */}
              <KTextField
                icon={<SmartphoneTwoTone />}
                label={`Telefon Mobil`}
                onChange={event => {
                  const telephone_mobile = event.target.value
                  setUserData(prev => ({ ...prev, telephone_mobile }))
                }}
                type={'tel'}
                value={userData.telephone_mobile || ''}
              />

              {/* Fax */}
              <KTextField
                icon={<PrintTwoTone />}
                label={`Fax`}
                onChange={event => {
                  const fax = event.target.value
                  setUserData(prev => ({ ...prev, fax }))
                }}
                type={'tel'}
                value={userData.fax || ''}
              />

              {/* Homepage */}
              <KTextField
                icon={<HttpTwoTone />}
                label={`Homepage`}
                onChange={event => {
                  const homepage = event.target.value
                  setUserData(prev => ({ ...prev, homepage }))
                }}
                type={'url'}
                value={userData.homepage || ''}
              />

              {/* Anschrift */}
              <KTextField
                icon={<EditLocationTwoTone />}
                label={`Anschrift`}
                onChange={event => {
                  const street = event.target.value
                  setUserData(prev => ({ ...prev, street }))
                }}
                type={'text'}
                value={userData.street || ''}
              />

              {/* PLZ */}
              <KTextField
                icon={<EditLocationTwoTone />}
                label={`PLZ`}
                onChange={event => {
                  const zip = event.target.value
                  setUserData(prev => ({ ...prev, zip }))
                }}
                type={'number'}
                value={userData.zip || ''}
              />

              {/* Ort */}
              <KTextField
                icon={<EditLocationTwoTone />}
                label={`Ort`}
                onChange={event => {
                  const city = event.target.value
                  setUserData(prev => ({ ...prev, city }))
                }}
                type={'text'}
                value={userData.city || ''}
              />

              {/* Land */}
              <KSelect
                icon={<EditLocationTwoTone />}
                label={'Land'}
                onChange={event => {
                  const country = event.target.value
                  setUserData(prev => ({ ...prev, country }))
                }}
                type={'text'}
                value={userData.country || ''}
              >
                <MenuItem value={'DEU'}>Deutschland</MenuItem>
                <MenuItem value={'AUS'}>Österreich</MenuItem>
                <MenuItem value={'CHE'}>Schweiz</MenuItem>
              </KSelect>
            </form>
          </Collapse>

          {/* Passwort */}
          <Collapse in={tab === 1}>
            <form
              name={'pw_form'}
              onSubmit={event => {
                event.preventDefault()

                if (
                  isValidPassword(fromPassword) &&
                  isValidPassword(toPassword)
                ) {
                  dispatch(userActions.updatePassword(fromPassword, toPassword))

                  // reset form
                  setFromPassword('')
                  setToPassword('')
                }
              }}
            >
              {/* leave the following input field for accessibility; */}
              {/* https://goo.gl/9p2vKq */}
              <input
                autoComplete={'username'}
                defaultValue={userData.email || ''}
                hidden
                type={'text'}
              />

              {/* aktuelles Password */}
              <KTextField
                autoComplete={'current-password'}
                error={!isValidPassword(fromPassword) && fromPassword !== ''}
                icon={<VpnKeyTwoTone />}
                label={`Altes Passwort`}
                onChange={event => setFromPassword(event.target.value)}
                type={'password'}
                value={fromPassword}
              />

              {/* neues Password */}
              <KTextField
                autoComplete={'new-password'}
                error={!isValidPassword(toPassword) && toPassword !== ''}
                icon={<VpnKeyTwoTone />}
                label={`Neues Passwort`}
                onChange={event => setToPassword(event.target.value)}
                type={'password'}
                value={toPassword}
              />

              <Container sx={{ mt: 2, mb: 5, textAlign: 'right' }}>
                <Button
                  disabled={
                    !isValidPassword(fromPassword) ||
                    !isValidPassword(toPassword)
                  }
                  type={'submit'}
                >
                  Aktualisieren
                </Button>
              </Container>

              <Alert severity={'info'}>
                <AlertTitle>Komplexitätsanforderung für Passworte</AlertTitle>
                <Typography>
                  &bull; Passworte müssen aus mindestens acht Zeichen bestehen
                  <br />
                  &bull; Passworte müssen mindestens eine Ziffer enthalten
                  <br />
                  &bull; Passworte müssen mindestens einen Groß- und einen
                  Kleinbuchstaben enthalten
                </Typography>
              </Alert>
            </form>
          </Collapse>

          {/* Push Geräte */}
          <Collapse in={tab === 2}>
            <TableContainer component={Paper} elevation={1}>
              <Table>
                <TableHead sx={{ backgroundColor: 'lightgrey' }}>
                  <TableRow>
                    <TableCell>Gerät</TableCell>
                    <TableCell align={'right'}>Report Level</TableCell>
                    <TableCell align={'right'}>Status</TableCell>
                    <TableCell align={'right'}>registriert am</TableCell>
                    <TableCell align={'right'}>aktualisiert am</TableCell>
                    <TableCell align={'right'}>Dienstanbieter</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pushDevices.map((device, key) => (
                    <TableRow key={key}>
                      <TableCell>{decodeAppleModel(device.model)}</TableCell>
                      <TableCell align={'right'}>
                        {renderReportLevel(device.push_mask)}
                      </TableCell>
                      <TableCell align={'right'}>
                        {['deakiviert', 'aktiviert'][device.enabled]}
                      </TableCell>
                      <TableCell align={'right'}>
                        {new Date(device.created_at).toLocaleString()}
                      </TableCell>
                      <TableCell align={'right'}>
                        {new Date(device.updated_at).toLocaleString()}
                      </TableCell>
                      <TableCell align={'right'}>{device.provider}</TableCell>
                    </TableRow>
                  ))}
                  {pushDevices.length === 0 && (
                    <TableRow>
                      <TableCell align={'center'} colSpan={3}>
                        Kein Gerät registriert.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>

          {/* Berichte Abos */}
          <Collapse in={tab === 3}>
            <Alert severity={'info'}>
              <AlertTitle>Übersicht Berichte Abonnements</AlertTitle>
              <Typography>
                Abonnements werden am 1. eines jeden Monats automatisiert für
                den Vormonat erstellt und per Mail verschickt. Die Zustellung
                erfolgt gegen 08:00 Uhr.
              </Typography>
              <Typography>
                Gebäudesensoren können für die Berichterstellung beliebig an-
                oder abgewählt werden.
              </Typography>
            </Alert>

            <TableContainer component={Paper} elevation={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Gebäude</TableCell>
                    <TableCell>Sensorname</TableCell>
                    <TableCell>Empfänger</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.map((report, key) => {
                    let folderName = folderPool.find(
                      f => Number(f.id) === report.folder_id
                    )
                    if (folderName) folderName = folderName.attributes.name

                    return (
                      <TableRow key={key}>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          {folderName}
                        </TableCell>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          {report.sensor_ids.split(',').map((item, key) => {
                            let sensorName = sensorPool.find(
                              f => Number(f.id) === Number(item)
                            )
                            sensorName = sensorName
                              ? sensorName.attributes.name
                              : Number(item)

                            return (
                              <Box key={key}>
                                {sensorName}
                                <br />
                              </Box>
                            )
                          })}
                        </TableCell>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          {report.mailto.split(',').map((item, key) => {
                            return (
                              <Box key={key}>
                                {item}
                                <br />
                              </Box>
                            )
                          })}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {reports.length === 0 && (
                    <TableRow>
                      <TableCell align={'center'} colSpan={3}>
                        Kein Berichte abonniert.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </KPage>
      )}
    </>
  )
}
