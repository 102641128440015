// modules
import 'fix-date'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// scripts
import { consumptionActions } from '../../actions'
import {
  A_MONTH_AGO,
  germanDecimals,
  MMMMYYYY,
  sensorUnit,
  SORT_TIMEPOINT,
  userLocales,
} from '../../api'
import { Preloader } from '../../components'
import { svgConstants as SVG } from '../../constants'
import {
  FILTER_MAIN_SENSOR,
  FILTER_SUB_SENSOR,
  FILTER_ENERGY_SENSOR,
  FILTER_WATER_SENSOR,
  FILTER_GAS_SENSOR,
} from '../../api'
import { useFolder, useSensor, useSensorPool, useUserPool } from '../../hooks'
import { ConsumptionChart, PackageUpgrade } from './components'

export const Performance = ({ folderId }) => {
  const dispatch = useDispatch()

  const _endDate = new Date()
  _endDate.setMonth(new Date().getMonth() - 1)
  const _endDateYear = new Date()
  _endDateYear.setFullYear(new Date().getFullYear() - 1)
  _endDateYear.setMonth(new Date().getMonth() + 1)
  _endDateYear.setDate(1)
  const _startDateYear = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0,
    23,
    59,
    59
  )
  const _endDateDay = new Date()
  _endDateDay.setDate(new Date().getDate() - 1)

  const monthlyFrom = _endDate.toISOString().split('T')[0]

  const monthlyTo = new Date().toISOString().split('T')[0]
  const dailyFrom = _endDateDay.toISOString().split('T')[0]
  const dailyTo = new Date().toISOString().split('T')[0]
  const yearlyFrom = _endDateYear.toISOString().split('T')[0]
  const yearlyTo = _startDateYear.toISOString().split('T')[0]

  const { consumption24, consumptionMonthly, consumptionYearly } = useSelector(
    state => state.consumption
  )

  const [dailyEnergy, setDailyEnergy] = useState([])
  const [dailyWater, setDailyWater] = useState([])
  const [dailyGas, setDailyGas] = useState([])

  const [monthlyEnergy, setMonthlyEnergy] = useState([])
  const [monthlyWater, setMonthlyWater] = useState([])
  const [monthlyGas, setMonthlyGas] = useState([])

  const [yearlyEnergy, setYearlyEnergy] = useState([])
  const [yearlyWater, setYearlyWater] = useState([])
  const [yearlyGas, setYearlyGas] = useState([])

  const [isLoading, setLoading] = useState(true)

  const [coachData, setCoachData] = useState({})
  const { data: userPool } = useUserPool()

  const [bridgeSensor, setBridgeSensor] = useState(null)
  const [operatorSensorIds, setOperatorSensorIds] = useState([])
  const [
    operatorHasMainEnergySensor,
    setOperatorHasMainEnergySensor,
  ] = useState(false)
  const [operatorHasMainWaterSensor, setOperatorHasMainWaterSensor] = useState(
    false
  )
  const [operatorHasMainGasSensor, setOperatorHasMainGasSensor] = useState(
    false
  )

  const [energySensors, setEnergySensors] = useState([])
  const [watersSensors, setWatersSensors] = useState([])
  const [gasSensors, setGasSensors] = useState([])

  const energy = []
  const water = []
  const gas = []

  useEffect(
    () =>
      dispatch(
        consumptionActions.getAll(folderId, 'h', dailyFrom, dailyTo, false)
      ),
    [dispatch, folderId, dailyFrom, dailyTo]
  )

  useEffect(
    () =>
      dispatch(
        consumptionActions.getAll(folderId, 'h', dailyFrom, dailyTo, true)
      ),
    [dispatch, folderId, dailyFrom, dailyTo]
  )

  useEffect(
    () =>
      dispatch(
        consumptionActions.getAll(folderId, 'd', monthlyFrom, monthlyTo, true)
      ),
    [dispatch, folderId, monthlyFrom, monthlyTo]
  )

  useEffect(
    () =>
      dispatch(
        consumptionActions.getAll(folderId, 'm', yearlyFrom, yearlyTo, true)
      ),
    [dispatch, folderId, yearlyFrom, yearlyTo]
  )

  const { data: folderData, isLoading: folderLoading } = useFolder(folderId)

  useEffect(
    () => {
      if (folderData) {
        const { sensors } = folderData

        if (sensors && sensors[0]) {
          const { entity_id } = sensors[0]

          if (entity_id) setBridgeSensor(entity_id)
        }
      }
    },
    [folderData]
  )

  const { data: bridgeData } = useSensor(bridgeSensor)

  useEffect(
    () => {
      if (bridgeData) {
        const { contracts } = bridgeData

        if (contracts && contracts[0]) {
          const { primary_coach_id } = contracts[0]

          if (primary_coach_id)
            setCoachData(userPool.find(f => Number(f.id) === primary_coach_id))
        }
      }
    },
    [bridgeData, userPool]
  )

  const { data: sensorPool } = useSensorPool()

  useEffect(
    () => {
      if (
        consumption24 &&
        !consumption24.loading &&
        (consumptionMonthly && !consumptionMonthly.loading) &&
        (consumptionYearly && !consumptionYearly.loading)
      ) {
        const ids = sensorPool.map(item => Number(item.id))

        const getAllConsumptionIds = (daily, monthly, yearly) => {
          const allIds = [
            ...daily.item.data.map(entry => Number(entry.id)),
            ...monthly.item.data.map(entry => Number(entry.id)),
            ...yearly.item.data.map(entry => Number(entry.id)),
          ]

          return [...new Set(allIds)]
        }

        const consumptionIds = getAllConsumptionIds(
          consumption24,
          consumptionMonthly,
          consumptionYearly
        )

        const checkForMainSensor = type =>
          sensorPool.some(
            sensor =>
              sensor.attributes.parent_id === null &&
              sensor.attributes.type_in_folder === type &&
              Number(sensor.attributes.folder_id) === Number(folderId) &&
              consumptionIds.includes(Number(sensor.attributes.entity_id))
          )
        setOperatorHasMainEnergySensor(checkForMainSensor('energy'))
        setOperatorHasMainWaterSensor(checkForMainSensor('water'))
        setOperatorHasMainGasSensor(checkForMainSensor('gas'))
        setOperatorSensorIds(ids)
      }
    },
    [folderId, sensorPool, consumption24, consumptionMonthly, consumptionYearly]
  )

  const DATA_REDUCER = (acc, cur) => {
    const { timepoint } = cur

    acc[timepoint] = acc[timepoint] || {}

    for (const attr in cur) {
      if (attr === 'tick')
        acc[timepoint][attr] = acc[timepoint][attr]
          ? acc[timepoint][attr]
          : cur[attr]

      if (attr === 'timepoint')
        acc[timepoint][attr] = acc[timepoint][attr]
          ? acc[timepoint][attr]
          : cur[attr]

      if (attr === 'value')
        acc[timepoint][attr] = acc[timepoint][attr]
          ? acc[timepoint][attr] + cur[attr]
          : cur[attr]

      // status handling needs to be done; but status comes neutral so far
      if (attr === 'status')
        acc[timepoint][attr] = acc[timepoint][attr]
          ? acc[timepoint][attr] + ' und ' + cur[attr]
          : cur[attr]
    }

    return acc
  }

  const FILTER_OLDER_THAN_YESTERDAY = f => {
    const _1S = 1000
    const _1M = 60 * _1S
    const _1H = 60 * _1M
    const _24H = 24 * _1H
    const NOW = Date.now()
    const YESTERDAY = NOW - _24H
    const TZO = new Date().getTimezoneOffset() * _1M
    const date = new Date(f.timepoint)
    date.setTime(date.getTime() - TZO)

    return date.getTime() > YESTERDAY
  }

  const FILTER_OLDER_THAN_A_MONTH = f =>
    new Date(f.timepoint).getTime() > A_MONTH_AGO

  const FILTER_OLDER_THAN_A_YEAR = f => {
    const NOW = Date.now()
    const A_YEAR_AGO = new Date(NOW).setFullYear(
      new Date(NOW).getFullYear() - 1
    )

    return new Date(f.timepoint).getTime() > A_YEAR_AGO
  }

  useEffect(
    () => {
      if (consumption24) {
        const { item } = consumption24
        if (item) {
          const { data } = item

          if (data) {
            let dailyEnergy = data
              // filter main sensors or sub sensors
              .filter(
                operatorHasMainEnergySensor
                  ? FILTER_MAIN_SENSOR
                  : FILTER_SUB_SENSOR
              )
              // filter energy based sensors
              .filter(FILTER_ENERGY_SENSOR)
              // restrict to operators sensor ids
              .filter(f => operatorSensorIds.includes(Number(f.id)))
              // flatten to consumption values
              .map(item => item.attributes.consumptions.values)
            // reduce consumption values of each sensor into single list
            // remove records older than 24h

            dailyEnergy = Object.keys(dailyEnergy)
              .reduce((acc, cur) => acc.concat(dailyEnergy[cur]), [])
              .filter(FILTER_OLDER_THAN_YESTERDAY)

            // sum up and reduce consumption values based on timestamp
            dailyEnergy = Object.values(dailyEnergy.reduce(DATA_REDUCER, {}))
              .sort(SORT_TIMEPOINT)
              .map(item => {
                const { timepoint, value } = item
                const tooltip = `${value.toLocaleString(
                  userLocales,
                  germanDecimals
                )} ${sensorUnit('energy')}`

                return [new Date(timepoint), value, tooltip]
              })

            setDailyEnergy([
              [
                '',
                sensorUnit('energy'),
                { role: 'tooltip', type: 'string', p: { html: true } },
              ],
              ...dailyEnergy,
            ])

            // filter water sensors of consumption sensors
            let dailyWater = data
              // filter main sensors
              .filter(
                operatorHasMainWaterSensor
                  ? FILTER_MAIN_SENSOR
                  : FILTER_SUB_SENSOR
              )
              // filter water based sensors
              .filter(FILTER_WATER_SENSOR)
              // restrict to operators sensor ids
              .filter(f => operatorSensorIds.includes(Number(f.id)))
              // flatten to consumption values
              .map(item => item.attributes.consumptions.values)

            // remove records older than 24h
            dailyWater = Object.keys(dailyWater)
              .reduce((acc, cur) => acc.concat(dailyWater[cur]), [])
              .filter(FILTER_OLDER_THAN_YESTERDAY)

            // sum up and reduce consumption values based on timestamp
            dailyWater = Object.values(dailyWater.reduce(DATA_REDUCER, {}))
              .sort(SORT_TIMEPOINT)
              .map(item => {
                const { timepoint, value } = item
                const tooltip = `${value.toLocaleString(
                  userLocales,
                  germanDecimals
                )} ${sensorUnit('water')}`

                return [new Date(timepoint), value, tooltip]
              })

            setDailyWater([
              [
                '',
                sensorUnit('water'),
                { role: 'tooltip', type: 'string', p: { html: true } },
              ],
              ...dailyWater,
            ])

            let dailyGas = data
              // filter main sensors
              .filter(
                operatorHasMainGasSensor
                  ? FILTER_MAIN_SENSOR
                  : FILTER_SUB_SENSOR
              )
              // filter heating based sensors
              .filter(FILTER_GAS_SENSOR)
              // restrict to operators sensor ids
              .filter(f => operatorSensorIds.includes(Number(f.id)))
              // flatten to consumption values
              .map(item => item.attributes.consumptions.values)

            // remove records older than 24h
            dailyGas = Object.keys(dailyGas)
              .reduce((acc, cur) => acc.concat(dailyGas[cur]), [])
              .filter(FILTER_OLDER_THAN_YESTERDAY)

            // sum up and reduce consumption values based on timestamp
            dailyGas = Object.values(dailyGas.reduce(DATA_REDUCER, {}))
              .sort(SORT_TIMEPOINT)
              .map(item => {
                const { timepoint, value } = item
                const tooltip = `${value.toLocaleString(
                  userLocales,
                  germanDecimals
                )} ${sensorUnit('gas')}`

                return [new Date(timepoint), value, tooltip]
              })

            setDailyGas([
              [
                '',
                sensorUnit('gas'),
                { role: 'tooltip', type: 'string', p: { html: true } },
              ],
              ...dailyGas,
            ])
          }
        }
      }
    },
    [
      consumption24,
      operatorHasMainEnergySensor,
      operatorHasMainGasSensor,
      operatorHasMainWaterSensor,
      operatorSensorIds,
    ]
  )

  useEffect(
    () => {
      if (consumptionMonthly) {
        const { item } = consumptionMonthly

        if (item) {
          const { data } = item

          if (data) {
            let monthlyEnergy = data
              .filter(
                operatorHasMainEnergySensor
                  ? FILTER_MAIN_SENSOR
                  : FILTER_SUB_SENSOR
              )
              .filter(FILTER_ENERGY_SENSOR)
              // restrict to operators sensor ids
              .filter(f => operatorSensorIds.includes(Number(f.id)))
              .map(item => item.attributes.consumptions.values)

            // filter older than a month
            monthlyEnergy = Object.keys(monthlyEnergy)
              .reduce((acc, cur) => acc.concat(monthlyEnergy[cur]), [])
              .filter(FILTER_OLDER_THAN_A_MONTH)

            // sum up and reduce consumption values based on timestamp
            monthlyEnergy = Object.values(
              monthlyEnergy.reduce(DATA_REDUCER, {})
            )
              .sort(SORT_TIMEPOINT)
              .map(item => {
                const { timepoint, value } = item
                const tooltip = `${value.toLocaleString(
                  userLocales,
                  germanDecimals
                )} ${sensorUnit('energy')}`

                return [new Date(timepoint), value, tooltip]
              })

            setMonthlyEnergy([
              [
                '',
                sensorUnit('energy'),
                { role: 'tooltip', type: 'string', p: { html: true } },
              ],
              ...monthlyEnergy,
            ])

            let monthlyWater = data
              .filter(
                operatorHasMainWaterSensor
                  ? FILTER_MAIN_SENSOR
                  : FILTER_SUB_SENSOR
              )
              .filter(FILTER_WATER_SENSOR)
              // restrict to operators sensor ids
              .filter(f => operatorSensorIds.includes(Number(f.id)))
              .map(item => item.attributes.consumptions.values)

            monthlyWater = Object.keys(monthlyWater)
              .reduce((acc, cur) => acc.concat(monthlyWater[cur]), [])
              .filter(FILTER_OLDER_THAN_A_MONTH)

            monthlyWater = Object.values(monthlyWater.reduce(DATA_REDUCER, {}))
              .sort(SORT_TIMEPOINT)
              .map(item => {
                const { timepoint, value } = item
                const tooltip = `${value.toLocaleString(
                  userLocales,
                  germanDecimals
                )} ${sensorUnit('water')}`

                return [new Date(timepoint), value, tooltip]
              })

            setMonthlyWater([
              [
                '',
                sensorUnit('water'),
                { role: 'tooltip', type: 'string', p: { html: true } },
              ],
              ...monthlyWater,
            ])

            let monthlyGas = data
              .filter(
                operatorHasMainGasSensor
                  ? FILTER_MAIN_SENSOR
                  : FILTER_SUB_SENSOR
              )
              .filter(FILTER_GAS_SENSOR)
              // restrict to operators sensor ids
              .filter(f => operatorSensorIds.includes(Number(f.id)))
              .map(item => item.attributes.consumptions.values)

            monthlyGas = Object.keys(monthlyGas)
              .reduce((acc, cur) => acc.concat(monthlyGas[cur]), [])
              .filter(FILTER_OLDER_THAN_A_MONTH)

            monthlyGas = Object.values(monthlyGas.reduce(DATA_REDUCER, {}))
              .sort(SORT_TIMEPOINT)
              .map(item => {
                const { timepoint, value } = item
                const tooltip = `${value.toLocaleString(
                  userLocales,
                  germanDecimals
                )} ${sensorUnit('gas')}`

                return [new Date(timepoint), value, tooltip]
              })

            setMonthlyGas([
              [
                '',
                sensorUnit('gas'),
                { role: 'tooltip', type: 'string', p: { html: true } },
              ],
              ...monthlyGas,
            ])
          }
        }
      }
    },
    [
      consumptionMonthly,
      operatorHasMainEnergySensor,
      operatorHasMainGasSensor,
      operatorHasMainWaterSensor,
      operatorSensorIds,
    ]
  )

  useEffect(
    () => {
      if (consumptionYearly) {
        const { item } = consumptionYearly

        if (item) {
          const { data } = item

          if (data) {
            let yearlyEnergy = data
              .filter(
                operatorHasMainEnergySensor
                  ? FILTER_MAIN_SENSOR
                  : FILTER_SUB_SENSOR
              )
              .filter(FILTER_ENERGY_SENSOR)
              // restrict to operators sensor ids
              .filter(f => operatorSensorIds.includes(Number(f.id)))
              .map(item => item.attributes.consumptions.values)

            yearlyEnergy = Object.keys(yearlyEnergy)
              .reduce((acc, cur) => acc.concat(yearlyEnergy[cur]), [])
              .filter(FILTER_OLDER_THAN_A_YEAR)

            yearlyEnergy = Object.values(yearlyEnergy.reduce(DATA_REDUCER, {}))
              .sort(SORT_TIMEPOINT)
              .map(item => {
                const { timepoint, value } = item
                const date = new Date(timepoint).toLocaleString(
                  userLocales,
                  MMMMYYYY
                )
                const tooltip = `${value.toLocaleString(
                  userLocales,
                  germanDecimals
                )} ${sensorUnit('energy')}`

                return [date, value, tooltip]
              })

            setYearlyEnergy([
              [
                '',
                sensorUnit('energy'),
                { role: 'tooltip', type: 'string', p: { html: true } },
              ],
              ...yearlyEnergy,
            ])

            let yearlyWater = data
              .filter(
                operatorHasMainWaterSensor
                  ? FILTER_MAIN_SENSOR
                  : FILTER_SUB_SENSOR
              )
              .filter(FILTER_WATER_SENSOR)
              // restrict to operators sensor ids
              .filter(f => operatorSensorIds.includes(Number(f.id)))
              .map(item => item.attributes.consumptions.values)

            yearlyWater = Object.keys(yearlyWater)
              .reduce((acc, cur) => acc.concat(yearlyWater[cur]), [])
              .filter(FILTER_OLDER_THAN_A_YEAR)

            yearlyWater = Object.values(yearlyWater.reduce(DATA_REDUCER, {}))
              .sort(SORT_TIMEPOINT)
              .map(item => {
                const { timepoint, value } = item
                const date = new Date(timepoint).toLocaleString(
                  userLocales,
                  MMMMYYYY
                )
                const tooltip = `${value.toLocaleString(
                  userLocales,
                  germanDecimals
                )} ${sensorUnit('water')}`

                return [date, value, tooltip]
              })

            setYearlyWater([
              [
                '',
                sensorUnit('water'),
                { role: 'tooltip', type: 'string', p: { html: true } },
              ],
              ...yearlyWater,
            ])

            let yearlyGas = data
              .filter(
                operatorHasMainGasSensor
                  ? FILTER_MAIN_SENSOR
                  : FILTER_SUB_SENSOR
              )
              .filter(FILTER_GAS_SENSOR)
              // restrict to operators sensor ids
              .filter(f => operatorSensorIds.includes(Number(f.id)))
              .map(item => item.attributes.consumptions.values)

            yearlyGas = Object.keys(yearlyGas)
              .reduce((acc, cur) => acc.concat(yearlyGas[cur]), [])
              .filter(FILTER_OLDER_THAN_A_YEAR)

            yearlyGas = Object.values(yearlyGas.reduce(DATA_REDUCER, {}))
              .sort(SORT_TIMEPOINT)
              .map(item => {
                const { timepoint, value } = item
                const date = new Date(timepoint).toLocaleString(
                  userLocales,
                  MMMMYYYY
                )
                const tooltip = `${value.toLocaleString(
                  userLocales,
                  germanDecimals
                )} ${sensorUnit('gas')}`

                return [date, value, tooltip]
              })

            setYearlyGas([
              [
                '',
                sensorUnit('gas'),
                { role: 'tooltip', type: 'string', p: { html: true } },
              ],
              ...yearlyGas,
            ])
          }
        }
      }
    },
    [
      consumptionYearly,
      operatorHasMainEnergySensor,
      operatorHasMainGasSensor,
      operatorHasMainWaterSensor,
      operatorSensorIds,
    ]
  )

  useEffect(
    () => {
      if (
        !folderLoading &&
        (consumption24 && !consumption24.loading) &&
        (consumptionMonthly && !consumptionMonthly.loading) &&
        (consumptionYearly && !consumptionYearly.loading)
      ) {
        const getSensorNamesByEnergyType = type => {
          const HAS_MAIN_SENSOR =
            type === 'water'
              ? operatorHasMainWaterSensor
              : type === 'gas'
                ? operatorHasMainGasSensor
                : operatorHasMainEnergySensor

          return folderData && folderData.sensors
            ? folderData.sensors
                .filter(
                  f =>
                    HAS_MAIN_SENSOR
                      ? f.sensor_type === type || f.sub_sensor_target === type
                      : f.sensor_type === type ||
                        f.sensor_type === type + '_detail' ||
                        f.sub_sensor_target === type ||
                        f.sub_sensor_target === type + '_detail'
                )
                .filter(
                  f =>
                    HAS_MAIN_SENSOR
                      ? f.parent_id === null
                      : f.parent_id !== null
                )
                .map(sensor => sensor.name)
                .sort((a, b) => a.localeCompare(b))
            : []
        }

        const energySensors = getSensorNamesByEnergyType('energy')
        const watersSensors = getSensorNamesByEnergyType('water')
        const gasSensors = getSensorNamesByEnergyType('gas')

        setLoading(false)
        setEnergySensors(energySensors)
        setWatersSensors(watersSensors)
        setGasSensors(gasSensors)
      } else setLoading(true)
    },
    [
      folderData,
      folderLoading,
      consumption24,
      consumptionMonthly,
      consumptionYearly,
      operatorHasMainEnergySensor,
      operatorHasMainWaterSensor,
      operatorHasMainGasSensor,
    ]
  )

  if (!isLoading) {
    energy['day'] = dailyEnergy
    water['day'] = dailyWater
    gas['day'] = dailyGas

    energy['month'] = monthlyEnergy
    water['month'] = monthlyWater
    gas['month'] = monthlyGas

    energy['year'] = yearlyEnergy
    water['year'] = yearlyWater
    gas['year'] = yearlyGas
  }

  return isLoading ? (
    /* preloader */
    <Preloader isLoading={isLoading} />
  ) : (
    <>
      {energy.day.length <= 2 &&
      energy.month.length <= 2 &&
      energy.year.length <= 2 &&
      water.day.length <= 2 &&
      water.month.length <= 2 &&
      water.year.length <= 2 &&
      gas.day.length <= 2 &&
      gas.month.length <= 2 &&
      gas.year.length <= 2 &&
      coachData ? (
        <PackageUpgrade coach={coachData} />
      ) : (
        <>
          {(energy.day.length > 2 ||
            energy.month.length > 2 ||
            energy.year.length > 2) && (
            <ConsumptionChart
              data={energy}
              sensors={energySensors}
              folderId={folderId}
              name={'Strom'}
              type={'energy'}
              icon={SVG.MEDIUM_ENERGY}
            />
          )}
          {(water.day.length > 2 ||
            water.month.length > 2 ||
            water.year.length > 2) && (
            <ConsumptionChart
              data={water}
              sensors={watersSensors}
              folderId={folderId}
              name={'Wasser'}
              type={'water'}
              icon={SVG.MEDIUM_WATER}
            />
          )}
          {(gas.day.length > 2 ||
            gas.month.length > 2 ||
            gas.year.length > 2) && (
            <ConsumptionChart
              data={gas}
              sensors={gasSensors}
              folderId={folderId}
              name={'Wärme'}
              type={'gas'}
              icon={SVG.MEDIUM_HEATING}
            />
          )}
        </>
      )}
    </>
  )
}

Performance.propTypes = { folderId: PropTypes.string }
