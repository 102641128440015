import { reportsConstants as RC } from '../constants'

const initialState = {
  status: { message: 'bereit' },
}

export const report = (state = initialState, action) => {
  const { report, error, type } = action
  let result

  if (report)
    switch (JSON.parse(report).message) {
      case 'ERR_NO_FOLDERID':
      case 'ERR_NO_YEAR':
      case 'ERR_NO_MONTH':
      case 'ERR_NO_QUERY_STRING_PARAMETERS':
      case 'ERR_GETTING_FOLDERDATA':
      case 'ERR_GETTING_PRICEDATA':
      case 'ERR_SAVING_PDF':
        result = { error: JSON.parse(report).message, loading: false }
        break
      default:
        result = { message: JSON.parse(report).message, loading: false }
        break
    }

  switch (type) {
    case RC.CREATE_REPORT_SETTINGS_FAILURE:
      return {
        ...state,
        status: {
          error,
          loading: false,
        },
      }

    case RC.CREATE_REPORT_SETTINGS_REQUEST:
      return {
        ...state,
        status: {
          message: 'Dauerauftrag erstellt...',
          loading: true,
        },
      }

    case RC.CREATE_REPORT_SETTINGS_SUCCESS:
      return {
        ...state,
        status: result,
      }

    case RC.DELETE_REPORT_SETTINGS_FAILURE:
      return {
        ...state,
        status: {
          error,
          loading: false,
        },
      }

    case RC.DELETE_REPORT_SETTINGS_REQUEST:
      return {
        ...state,
        status: {
          message: 'Dauerauftrag gelöscht...',
          loading: true,
        },
      }

    case RC.DELETE_REPORT_SETTINGS_SUCCESS:
      return {
        ...state,
        status: result,
      }

    case RC.GENERATE_PDF_FAILURE:
      return {
        ...state,
        status: {
          error,
          loading: false,
        },
      }

    case RC.GENERATE_PDF_REQUEST:
      return {
        ...state,
        status: {
          message: 'Bericht wird generiert...',
          loading: true,
        },
      }

    case RC.GENERATE_PDF_SUCCESS:
      return {
        ...state,
        status: result,
      }

    case RC.GET_REPORT_SETTINGS_FAILURE:
      return {
        ...state,
        status: {
          error,
          loading: false,
        },
      }

    case RC.GET_REPORT_SETTINGS_REQUEST:
      return {
        ...state,
        status: {
          message: 'Einstellungen ermitteln...',
          loading: true,
        },
      }

    case RC.GET_REPORT_SETTINGS_SUCCESS:
      return {
        ...state,
        status: result,
      }

    case RC.UPDATE_REPORT_SETTINGS_FAILURE:
      return {
        ...state,
        status: {
          error,
          loading: false,
        },
      }

    case RC.UPDATE_REPORT_SETTINGS_REQUEST:
      return {
        ...state,
        status: {
          message: 'Dauerauftrag aktualisiert...',
          loading: true,
        },
      }

    case RC.UPDATE_REPORT_SETTINGS_SUCCESS:
      return {
        ...state,
        status: result,
      }

    default:
      return state
  }
}
