// modules
import {
  EmailTwoTone,
  PhoneTwoTone,
  SmartphoneTwoTone,
  TrendingUpTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from '@mui/material'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import DOMPurify from 'dompurify'
import { PropTypes } from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
// scripts
import { folderActions, sensorActions } from '../../../actions'
import {
  DDMMYYHHMM,
  DDMMYYYYHHMM,
  DMMMMYYYY,
  FILTER_MAIN_FOLDER,
  FILTER_SUB_FOLDER,
  getRelativeDate,
  SORT_WEEKDAY,
  userLocales,
} from '../../../api'
import {
  useContractPool,
  useFolder,
  useFolderPool,
  useUserPool,
} from '../../../hooks'

export const Coach = ({ primaryCoachId }) => {
  const { data: userPool } = useUserPool()

  if (!primaryCoachId) return <Box />

  const coach = userPool.find(f => Number(f.id) === primaryCoachId)
  if (!coach) return <Box />

  const {
    email,
    firstname,
    image_url,
    lastname,
    telephone,
    telephone_mobile,
  } = coach.attributes

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 600,
        m: 'auto',
      }}
    >
      <CardMedia
        component={'img'}
        image={image_url}
        alt={`${firstname} ${lastname}`}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', pl: 0 }}>
          <Typography
            component={'h4'}
            variant={'h4'}
            sx={{ textTransform: 'uppercase', mb: 3 }}
          >
            {firstname} {lastname}
          </Typography>
          {telephone && (
            <Typography component={'h6'} variant={'subtitle1'}>
              <PhoneTwoTone /> {telephone}
            </Typography>
          )}
          {telephone_mobile && (
            <Typography component={'h6'} variant={'subtitle1'}>
              <SmartphoneTwoTone /> {telephone_mobile}
            </Typography>
          )}
          <br />
          {email && (
            <Typography component={'h6'} variant={'subtitle2'}>
              <EmailTwoTone />
              <Link to={`mailto:${email}`} target={'_top'}>
                {email}
              </Link>
            </Typography>
          )}
        </CardContent>
      </Box>
    </Card>
  )
}

Coach.propTypes = { primaryCoachId: PropTypes.number }

export const ContractStatus = ({ folderId, tiny }) => {
  const { data, isLoading } = useFolder(folderId)

  if (isLoading === true || !data?.contract) return <Box />

  const { date_begin, date_expire, severity, status } = data.contract

  const FROM = new Date(date_begin).toLocaleDateString(userLocales, DMMMMYYYY)
  const TO = new Date(date_expire).toLocaleDateString(userLocales, DMMMMYYYY)
  const FULLSTR = `${FROM} - ${TO}`

  return (
    <Alert severity={severity}>
      Vertragslizenz: <strong>{status}</strong> {!tiny && FULLSTR}
    </Alert>
  )
}

ContractStatus.propTypes = {
  folderId: PropTypes.number.isRequired,
  tiny: PropTypes.bool,
}

export const FolderList = ({ folder }) => {
  const history = useHistory()

  const {
    city,
    created_at,
    name,
    image_url,
    parent_id,
    street,
    updated_at,
    zip,
  } = folder.attributes

  const CREATED_AT = new Date(created_at).toLocaleString(
    userLocales,
    DDMMYYYYHHMM
  )
  const UPDATED_AT = new Date(updated_at).toLocaleString(
    userLocales,
    DDMMYYYYHHMM
  )

  return (
    <ListItem button onClick={() => history.push(`/folder/${folder.id}/edit`)}>
      <ListItemAvatar>
        <Avatar alt={''} src={image_url} />
      </ListItemAvatar>

      <ListItemText
        primary={name}
        secondary={`${street}, ${zip} ${city}`}
        sx={{ width: '55%' }}
      />

      <Divider flexItem orientation={'vertical'} />

      <ListItemText
        primary={!parent_id ? 'Gebäude' : 'Untergebäude'}
        secondary={`ID: ${folder.id}`}
        sx={{ pl: 1, width: '10%' }}
      />

      <Divider flexItem orientation={'vertical'} />

      <ListItemText
        primary={`aktualisiert: ${UPDATED_AT}`}
        secondary={`angelegt: ${CREATED_AT}`}
        sx={{ pl: 1, width: '25%' }}
      />

      <ListItemSecondaryAction>
        <Tooltip title={'Leistungsübersicht'}>
          <IconButton
            edge={'end'}
            onClick={() =>
              history.push(`/folder/${folder.id}/home/performance`)
            }
          >
            <TrendingUpTwoTone />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

FolderList.propTypes = {
  folder: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      city: PropTypes.string,
      created_at: PropTypes.string,
      image_url: PropTypes.string,
      parent_id: PropTypes.number,
      name: PropTypes.string,
      street: PropTypes.string,
      updated_at: PropTypes.string,
      zip: PropTypes.string,
    }),
  }),
}

export const FolderNews = ({ news }) => {
  if (!news) return <Box />

  return (
    <Container>
      {news.map((item, key) => {
        const { content_text, headline, title } = item.attributes

        return (
          <Container key={key}>
            <Typography>{headline}</Typography>
            <Typography>{title}</Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(content_text),
              }}
            />
          </Container>
        )
      })}
    </Container>
  )
}

FolderNews.propTypes = {
  news: PropTypes.array,
}

export const FolderPoolStat = () => {
  const { data, fetchDate } = useFolderPool()

  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1, mb: 2 }}>
        <Typography>Poolgröße</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{data.length}</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Haupt Gebäude</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {[...new Set(data.filter(FILTER_MAIN_FOLDER))].length}
        </Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Unter Gebäude</Typography>
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          title={[...new Set(data.filter(FILTER_SUB_FOLDER))]
            .map(i => i.attributes.name)
            .join(', ')}
        >
          <Typography>
            {[...new Set(data.filter(FILTER_SUB_FOLDER))].length}
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1, mt: 2 }}>
        <Typography>gefetcht</Typography>
      </Grid>
      <Grid item xs={6} sx={{ mt: 2 }}>
        <Typography>
          {new Date(fetchDate).toLocaleString(userLocales, DDMMYYHHMM)}
          <br />
          {getRelativeDate(fetchDate)}
        </Typography>
      </Grid>
    </Grid>
  )
}

const DEFAULT_CENTER = { lat: 51.312801, lng: 9.481544 } // Kassel, Hessen, Germany

const { REACT_APP_API_GOOGLEMAPS } = process.env

const containerStyle = { width: '100%', height: '800px' }

export const FolderMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: REACT_APP_API_GOOGLEMAPS,
  })

  const { data: folderPool } = useFolderPool()

  const onLoad = useCallback(map => {
    const bounds = new window.google.maps.LatLngBounds(DEFAULT_CENTER)
    map.fitBounds(bounds)
  }, [])

  const onUnmount = useCallback(() => {}, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={DEFAULT_CENTER}
      zoom={7}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {folderPool.map((item, key) => {
        const { latitude, longitude, name, name_short } = item.attributes

        return (
          <Marker
            key={key}
            position={{ lat: Number(latitude), lng: Number(longitude) }}
            onClick={() => {
              console.log(name)
            }}
            title={name + (name_short ? ` (${name_short})` : '')}
          />
        )
      })}
    </GoogleMap>
  ) : (
    <></>
  )
}

export const IntegrityOC = () => {
  const { data: contracts, isLoading: cLoading } = useContractPool()
  const { data: folders, isLoading: fLoading } = useFolderPool()

  const SEVERITY_SUCCESS = 'success'
  const SEVERITY_ERROR = 'error'
  const SEVERITY_LOADING = 'info'

  const [severity, setSeverity] = useState(SEVERITY_LOADING)
  const [fNames, setFNames] = useState([])
  const [cNames, setCNames] = useState([])

  const isLoading = cLoading || fLoading

  useEffect(
    () => {
      if (!isLoading) {
        setFNames(folders.map(i => i.attributes.name).sort())
        setCNames(contracts.map(i => i.attributes.name).sort())

        if (contracts.length === folders.length) setSeverity(SEVERITY_SUCCESS)
        if (contracts.length !== folders.length) setSeverity(SEVERITY_ERROR)
      }
      if (isLoading) setSeverity(SEVERITY_LOADING)
    },
    [contracts, folders, isLoading]
  )

  return (
    <Alert severity={severity}>
      <AlertTitle>Synchronizität von Gebäuden & Verträgen</AlertTitle>

      <Collapse in={isLoading}>
        <LinearProgress />
      </Collapse>

      <Collapse in={!isLoading && severity === SEVERITY_SUCCESS}>
        Gleiche Anzahl von Gebäuden ({folders.length}) und Verträgen (
        {contracts.length}) erkannt.
      </Collapse>

      <Collapse in={!isLoading && severity !== SEVERITY_SUCCESS}>
        Ungleiche Anzahl von Gebäuden ({folders.length}) und Verträgen (
        {contracts.length}) erkannt.
      </Collapse>

      <Collapse in={!isLoading}>
        <br />
        <br />
        Ermittle Gebäude und Verträge, die kein Paar bilden:
        <br />
        <br />
        <Typography>
          <strong>
            GEBÄUDE OHNE GLEICHNAMIGEN VERTRAG (
            {fNames.filter(f => !cNames.includes(f)).length})
          </strong>
        </Typography>
        <br />
        {fNames
          .filter(f => !cNames.includes(f))
          .join(', ')
          .split('<br/>')}
        <br />
        <br />
        <Typography>
          <strong>
            VERTRÄGE OHNE GLEICHNAMIGES GEBÄUDE (
            {cNames.filter(f => !fNames.includes(f)).length})
          </strong>
        </Typography>
        <br />
        {cNames
          .filter(f => !fNames.includes(f))
          .join(', ')
          .split('<br/>')}
      </Collapse>
    </Alert>
  )
}

export const IntegritySensorCount = () => {
  const dispatch = useDispatch()

  const { oneFolder } = useSelector(state => state.folder)
  const { oneSensor } = useSelector(state => state.sensor)

  const { data: contracts, isLoading: cLoading } = useContractPool()
  const { data: folders, isLoading: fLoading } = useFolderPool()

  const [severity, setSeverity] = useState('info')
  const [log, setLog] = useState([])

  const isLoading = cLoading || fLoading

  // load all folders
  useEffect(
    () => {
      if (folders.length > 0) {
        const folderIds = folders
          .map(item => Number(item.id))
          .sort((a, b) => a - b)

        folderIds.forEach(item =>
          dispatch(folderActions.getById(item, ['sensors']))
        )
      }
    },
    [dispatch, folders]
  )

  // for each folder
  useEffect(
    () => {
      const { item } = oneFolder

      if (item && item.included) {
        const { id: folderId } = item.data
        const folderSensors = item.included
          .map(item => Number(item.id))
          .sort((a, b) => a - b)
        const count = folderSensors.length
        const name = folders.find(f => f.id === folderId).attributes.name

        if (count === 0) {
          setSeverity('error')
          setLog(log => [
            ...log,
            `ACHTUNG: Gebäude ${name} hat 0 zugewiesene Sensoren. Keine Vertragsinformationen verfügbar.`,
          ])
        }

        if (count > 0) {
          const sensorId = Number(folderSensors[0])

          dispatch(sensorActions.getById(sensorId, ['contracts']))
        }
      }
    },
    [dispatch, folders, oneFolder]
  )

  useEffect(
    () => {
      const { item } = oneSensor
      const { items } = contracts

      if (item && item.included && items && items.length > 0) {
        const { id: sensorId } = item.data
        const referenceSensor = item.included.map(item => Number(item.id))
        const count = referenceSensor.length

        if (count === 0)
          setLog(log => [
            ...log,
            `ACHTUNG: Sensor mit ID ${sensorId} ist keinem Vertrag zugeordnet. Es kann keine Beziehung zwischen Gebäude und Vertrag hergestellt werden.`,
          ])

        if (count > 0) {
          const record = contracts.find(
            f => Number(f.id) === Number(referenceSensor[0])
          )

          if (!record) {
            setLog(log => [
              ...log,
              `ACHTUNG: Vertrags ID ${
                referenceSensor[0]
              } ungültig (Vertrag nicht gefunden)`,
            ])
          }
        }
      }
    },
    [dispatch, oneSensor, contracts]
  )

  return (
    <Alert severity={severity}>
      <AlertTitle>BrückenSensor zwischen Gebäuden & Verträgen</AlertTitle>
      {isLoading && <LinearProgress />}
      {!isLoading &&
        log.map((item, key) => {
          return (
            <Box key={key}>
              {item}
              <br />
            </Box>
          )
        })}
    </Alert>
  )
}

const OHItem = ({ caption, disabled, status }) => (
  <ListItem disabled={disabled}>
    <ListItemText sx={{ width: 0.5 }}>{caption}</ListItemText>
    <ListItemText sx={{ width: 0.5 }}>{status}</ListItemText>
  </ListItem>
)
OHItem.propTypes = {
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  status: PropTypes.string,
}

export const OfficeHours = ({ data }) => {
  if (!data) return <Box />

  const FILTER_IS_REGULAR = item => item.exception !== 1
  const FILTER_IS_HOLIDAY = item => item.exception === 1

  const hasHoliday = data.filter(FILTER_IS_HOLIDAY).length > 0

  return (
    <List>
      <ListSubheader>Öffnungszeiten</ListSubheader>

      {data
        .filter(FILTER_IS_REGULAR)
        .sort(SORT_WEEKDAY)
        .map((item, key) => {
          const { closed, day_of_week } = item
          let { end_time, start_time } = item

          const caption = [
            'Montag',
            'Dienstag',
            'Mittwoch',
            'Donnerstag',
            'Freitag',
            'Samstag',
            'Sonntag',
          ][day_of_week - 1]

          if (!start_time) start_time = '00:00:00'
          if (!end_time) end_time = '00:00:00'
          start_time = start_time.slice(0, -3)
          end_time = end_time.slice(0, -3)

          const status =
            closed === 1 ? 'Geschlossen' : `${start_time} - ${end_time}`

          return (
            <OHItem
              caption={caption}
              disabled={closed === 1}
              key={key}
              status={status}
            />
          )
        })}

      {hasHoliday && <ListSubheader>Feiertage</ListSubheader>}

      {data.filter(FILTER_IS_HOLIDAY).map((item, key) => {
        const { closed, date_exception, name } = item
        let { end_time, start_time } = item

        const dateFormat = {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }
        const dateStr = new Date(date_exception).toLocaleString(
          userLocales,
          dateFormat
        )
        const caption = `${dateStr} - ${name}`

        if (!start_time) start_time = '00:00:00'
        if (!end_time) end_time = '00:00:00'
        start_time = start_time.slice(0, -3)
        end_time = end_time.slice(0, -3)

        const status =
          closed === 1 ? 'Geschlossen' : `${start_time} - ${end_time}`

        return <OHItem caption={caption} key={key} status={status} />
      })}
    </List>
  )
}

OfficeHours.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      closed: PropTypes.number,
      date_exception: PropTypes.string,
      day_of_week: PropTypes.number,
      end_time: PropTypes.string,
      entity_id: PropTypes.number,
      exception: PropTypes.number,
      folder_id: PropTypes.number,
      name: PropTypes.string,
      start_time: PropTypes.string,
    })
  ),
}
