import {
  Alert,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  TextField,
  Typography,
} from '@mui/material'
import { IndeterminateCheckBox, CheckBox } from '@mui/icons-material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useAppConfig } from '../../../../hooks'

export const DayFilterSelector = ({
  resetString,
  onChange,
  selectedDays,
  defaultSelectedDays,
  width = 100,
  label = 'Filter',
  variant = 'standard',
  ...rest
}) => {
  const { global } = useAppConfig()
  const { salutationMode } = global

  const [anchorEl, setAnchorEl] = useState(null)
  const [dayFilter, setDayFilter] = useState(selectedDays)

  const [dayFilterSelectString, setDayFilterSelectString] = useState('Mo-So')

  useEffect(
    () => {
      if (!resetString || dayFilterSelectString === 'Mo-So') return

      setDayFilter(defaultSelectedDays)
      setDayFilterSelectString('Mo-So')
    },
    [resetString, dayFilterSelectString, defaultSelectedDays]
  )

  useEffect(
    () => {
      const generateWeekdayString = weekdays => {
        const sortedWeekdays = weekdays.sort((a, b) => a - b)
        const ranges = []

        const weekdayToString = weekday =>
          ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'][weekday]

        let start = null
        let prev = null

        for (const weekday of sortedWeekdays) {
          if (start === null) start = weekday
          else if (weekday !== prev + 1) {
            ranges.push([start, prev])
            start = weekday
          }

          prev = weekday
        }

        if (start !== null) ranges.push([start, prev])

        return ranges
          .map(
            range =>
              range[0] === range[1]
                ? weekdayToString(range[0])
                : weekdayToString(range[0]) + '-' + weekdayToString(range[1])
          )
          .join(', ')
      }

      const {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        workingdays,
        weekends,
      } = dayFilter

      const weekdayArray = []

      if (monday) weekdayArray.push(0)
      if (tuesday) weekdayArray.push(1)
      if (wednesday) weekdayArray.push(2)
      if (thursday) weekdayArray.push(3)
      if (friday) weekdayArray.push(4)
      if (saturday) weekdayArray.push(5)
      if (sunday) weekdayArray.push(6)

      setDayFilterSelectString(generateWeekdayString(weekdayArray))

      if (!workingdays && monday && tuesday && wednesday && thursday && friday)
        setDayFilter(prev => ({ ...prev, workingdays: 1 }))

      if (!weekends && saturday && sunday)
        setDayFilter(prev => ({ ...prev, weekends: 1 }))
    },
    [dayFilter]
  )

  useEffect(() => onChange(dayFilter), [dayFilter, onChange])

  return (
    <>
      <TextField
        label={label}
        onClick={event => setAnchorEl(event.currentTarget)}
        value={dayFilterSelectString}
        variant={variant}
        {...rest}
        sx={{ width }}
      />

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
      >
        <Alert severity={'info'}>
          <Typography>
            {salutationMode === 'default' ? 'Sie können' : 'Du kannst'} die
            Darstellung
            {<br />} auf spezifische Wochentage {<br />} anpassen.
          </Typography>
        </Alert>

        <List>
          <ListItem>
            <ListItemText primary={'Werktage'} />

            <ListItemSecondaryAction>
              <Checkbox
                checked={
                  dayFilter.monday === 1 ||
                  dayFilter.tuesday === 1 ||
                  dayFilter.wednesday === 1 ||
                  dayFilter.thursday === 1 ||
                  dayFilter.friday === 1
                }
                onClick={() => {
                  const workingdays = dayFilter.workingdays === 0 ? 1 : 0
                  let {
                    monday,
                    tuesday,
                    wednesday,
                    thursday,
                    friday,
                  } = dayFilter

                  monday = tuesday = wednesday = thursday = friday =
                    workingdays === 0 ? 0 : 1

                  setDayFilter(prev => ({
                    ...prev,
                    workingdays,
                    monday,
                    tuesday,
                    wednesday,
                    thursday,
                    friday,
                  }))
                }}
                checkedIcon={
                  dayFilter.monday === 1 &&
                  dayFilter.tuesday === 1 &&
                  dayFilter.wednesday === 1 &&
                  dayFilter.thursday === 1 &&
                  dayFilter.friday === 1 ? (
                    <CheckBox />
                  ) : (
                    <IndeterminateCheckBox />
                  )
                }
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemText primary={'Wochenende'} />

            <ListItemSecondaryAction>
              <Checkbox
                checked={dayFilter.sunday === 1 || dayFilter.saturday === 1}
                onClick={() => {
                  const weekends = dayFilter.weekends === 0 ? 1 : 0
                  let { saturday, sunday } = dayFilter

                  saturday = sunday = weekends === 0 ? 0 : 1

                  setDayFilter(prev => ({
                    ...prev,
                    weekends,
                    saturday,
                    sunday,
                  }))
                }}
                checkedIcon={
                  dayFilter.sunday === 1 && dayFilter.saturday === 1 ? (
                    <CheckBox />
                  ) : (
                    <IndeterminateCheckBox />
                  )
                }
              />
            </ListItemSecondaryAction>
          </ListItem>

          <Divider />

          <ListItem>
            <ListItemText primary={'Montag'} />

            <ListItemSecondaryAction>
              <Checkbox
                checked={dayFilter.monday === 1}
                onClick={() => {
                  const monday = dayFilter.monday === 0 ? 1 : 0
                  const workingdays = monday === 0 ? 0 : 1

                  setDayFilter(prev => ({ ...prev, monday, workingdays }))
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemText primary={'Dienstag'} />

            <ListItemSecondaryAction>
              <Checkbox
                checked={dayFilter.tuesday === 1}
                onClick={() => {
                  const tuesday = dayFilter.tuesday === 0 ? 1 : 0
                  const workingdays = tuesday === 0 ? 0 : 1

                  setDayFilter(prev => ({ ...prev, tuesday, workingdays }))
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemText primary={'Mittwoch'} />

            <ListItemSecondaryAction>
              <Checkbox
                checked={dayFilter.wednesday === 1}
                onClick={() => {
                  const wednesday = dayFilter.wednesday === 0 ? 1 : 0
                  const workingdays = wednesday === 0 ? 0 : 1

                  setDayFilter(prev => ({ ...prev, wednesday, workingdays }))
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemText primary={'Donnerstag'} />

            <ListItemSecondaryAction>
              <Checkbox
                checked={dayFilter.thursday === 1}
                onClick={() => {
                  const thursday = dayFilter.thursday === 0 ? 1 : 0
                  const workingdays = thursday === 0 ? 0 : 1

                  setDayFilter(prev => ({ ...prev, thursday, workingdays }))
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemText primary={'Freitag'} />

            <ListItemSecondaryAction>
              <Checkbox
                checked={dayFilter.friday === 1}
                onClick={() => {
                  const friday = dayFilter.friday === 0 ? 1 : 0
                  const workingdays = friday === 0 ? 0 : 1

                  setDayFilter(prev => ({ ...prev, friday, workingdays }))
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemText primary={'Samstag'} />

            <ListItemSecondaryAction>
              <Checkbox
                checked={dayFilter.saturday === 1}
                onClick={() => {
                  const saturday = dayFilter.saturday === 0 ? 1 : 0
                  const weekends = saturday === 0 ? 0 : 1

                  setDayFilter(prev => ({ ...prev, saturday, weekends }))
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemText primary={'Sonntag'} />

            <ListItemSecondaryAction>
              <Checkbox
                checked={dayFilter.sunday === 1}
                onClick={() => {
                  const sunday = dayFilter.sunday === 0 ? 1 : 0
                  const weekends = sunday === 0 ? 0 : 1

                  setDayFilter(prev => ({ ...prev, sunday, weekends }))
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}

DayFilterSelector.propTypes = {
  resetString: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectedDays: PropTypes.object,
  defaultSelectedDays: PropTypes.object,
  width: PropTypes.number,
  label: PropTypes.string,
  variant: PropTypes.string,
}
