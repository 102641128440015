export const decodeAppleModel = model => {
  // decoded by database client records at 22/4/6

  switch (model) {
    // iphones
    // 6
    case 'iPhone6,1':
      return 'iPhone 5s'
    // 7
    case 'iPhone7,1':
      return 'iPhone 6 Plus'
    case 'iPhone7,2':
      return 'iPhone 6'
    // 8
    case 'iPhone8,1':
      return 'iPhone 6s'
    case 'iPhone8,4':
      return 'iPhone SE'
    // 9
    case 'iPhone9,1':
      return 'iPhone 7'
    case 'iPhone9,2':
      return 'iPhone 7 Plus'
    case 'iPhone9,3':
      return 'iPhone 7'
    case 'iPhone9,4':
      return 'iPhone 7 Plus'
    //10
    case 'iPhone10,1':
      return 'iPhone 8'
    case 'iPhone10,2':
      return 'iPhone 8 Plus'
    case 'iPhone10,3':
      return 'iPhone X'
    case 'iPhone10,4':
      return 'iPhone 8'
    case 'iPhone10,5':
      return 'iPhone 8 Plus'
    case 'iPhone10,6':
      return 'iPhone X'
    // 11
    case 'iPhone11,2':
      return 'iPhone XS'
    case 'iPhone11,6':
      return 'iPhone XS Max'
    case 'iPhone11,8':
      return 'iPhone XR'
    // 12
    case 'iPhone12,1':
      return 'iPhone 11'
    case 'iPhone12,3':
      return 'iPhone 11 Pro'
    case 'iPhone12,5':
      return 'iPhone 11 Pro Max'
    case 'iPhone12,8':
      return 'iPhone SE (2nd gen)'
    // 13
    case 'iPhone13,1':
      return 'iPhone 12 mini'
    case 'iPhone13,2':
      return 'iPhone 12'
    case 'iPhone13,3':
      return 'iPhone 12 Pro'
    case 'iPhone13,4':
      return 'iPhone 12 Pro Max'
    // 14
    case 'iPhone14,2':
      return 'iPhone 13 Pro'
    case 'iPhone14,3':
      return 'iPhone 13 Pro Max'
    // ipad (GSM model required)
    // todo
    //iPad5,4
    //iPad7,5
    //iPad8,12
    //iPad8,3
    //iPad8,7
    //iPad11,3
    //iPad11,6
    default:
      return model
  }
}
