import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { folderActions } from '../actions'
import {
  FILTER_TYPE_COACH,
  FILTER_TYPE_CONTRACT,
  FILTER_TYPE_FOLDER,
  FILTER_TYPE_HELP_LOCATION,
  FILTER_TYPE_INSTALLATION,
  FILTER_TYPE_NEWS,
  FILTER_TYPE_OFFICE_HOURS,
  FILTER_TYPE_SENSOR,
  FILTER_TYPE_USER,
} from '../api'

export const useFolder = id => {
  const dispatch = useDispatch()

  const [data, setData] = useState(null)
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const { oneFolder } = useSelector(state => state.folder)

  useEffect(
    () => {
      if (Number(id) > 0) {
        setLoading(true)
        dispatch(
          folderActions.getById(id, [
            'coaches',
            'users',
            'office_hours',
            'exception',
            'news',
            'help_locations',
            'sensors',
            'subfolders',
            'installations',
            'contracts',
          ])
        )
      }
    },
    [dispatch, id]
  )

  useEffect(
    () => {
      const { error, item } = oneFolder

      if (item) {
        const { data, included } = item
        const { attributes } = data

        setData({
          ...attributes,
          coaches: included
            ? included.filter(FILTER_TYPE_COACH).map(coach => coach.attributes)
            : [],
          contract: included
            ? included.find(FILTER_TYPE_CONTRACT)?.attributes
            : {},
          folders: included
            ? included
                .filter(FILTER_TYPE_FOLDER)
                .map(folder => folder.attributes)
            : [],
          help_locations: included
            ? included
                .filter(FILTER_TYPE_HELP_LOCATION)
                .map(hl => hl.attributes)
            : [],
          installations: included
            ? included
                .filter(FILTER_TYPE_INSTALLATION)
                .map(installations => installations.attributes)
            : [],
          news: included
            ? included.filter(FILTER_TYPE_NEWS).map(news => news.attributes)
            : [],
          office_hours: included
            ? included.filter(FILTER_TYPE_OFFICE_HOURS).map(oh => oh.attributes)
            : [],
          sensors: included
            ? included
                .filter(FILTER_TYPE_SENSOR)
                .map(sensor => sensor.attributes)
            : [],
          sensorIds: included
            ? included
                .filter(FILTER_TYPE_SENSOR)
                .map(sensor => sensor.id)
                .sort()
            : [],
          users: included
            ? included.filter(FILTER_TYPE_USER).map(user => user.attributes)
            : [],
        })
        setLoading(false)
      }

      if (error) {
        const { message } = error

        setError(message)
        setLoading(false)
      }
    },
    [oneFolder]
  )

  return { isLoading, isError, data }
}
