import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { systemActions } from '../actions'

const appVersionContext = createContext({})

export const ProvideAppVersion = ({ children }) => {
  const value = useVersion()

  return (
    <appVersionContext.Provider value={value}>
      {children}
    </appVersionContext.Provider>
  )
}
ProvideAppVersion.propTypes = { children: PropTypes.any }

export const useAppVersion = () => useContext(appVersionContext)

const useVersion = () => {
  const dispatch = useDispatch()
  const { appVersion } = useSelector(state => state.system)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(
    () => {
      dispatch(systemActions.getAppVersion())
      setLoading(true)
    },
    [dispatch]
  )

  useEffect(
    () => {
      const { error, fetchDate, item } = appVersion

      if (item) {
        const { data } = item

        if (data) {
          setData(data)
          setLoading(false)
          setFetchDate(fetchDate)
        }
      }

      if (error) {
        setError(error)
        setLoading(false)
        setFetchDate(fetchDate)
      }
    },
    [appVersion]
  )

  return { data, isError, isLoading, fetchDate }
}
