// modules
import { Avatar, Button, Card, CardContent, Divider, Grid } from '@mui/material'
import { CalendarTodayTwoTone, MemoryTwoTone } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
// scripts
import { missingDataActions } from '../../actions'
import { AM, KPage, KTextField, Preloader } from '../../components'
import { useOperator } from '../../hooks'

export const MissingDataQueryGenerator = () => {
  const history = useHistory()
  const { id } = useParams()

  const { oneMissingData } = useSelector(state => state.missingData)
  const { data: operatorData, loading: operatorLoading } = useOperator()

  const [startDay, setStartDay] = useState('')
  const [endDay, setEndDay] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [sensorID, setSensorID] = useState('')
  const [minValue, setMinValue] = useState('')
  const [maxValue, setMaxValue] = useState('')
  const [queries, setQueries] = useState([])
  //const [totalSum, setTotalSum] = useState(0)
  const [sensorIDCopyOld, setSensorIDCopyOld] = useState(0)
  const [sensorIDCopyNew, setSensorIDCopyNew] = useState(0)
  const [monthQuerie, setMonthQuerie] = useState('')

  const [missingLoading, setMissingLoading] = useState(true)

  const dispatch = useDispatch()

  useEffect(
    () => {
      if (!operatorLoading && !operatorData?.isAdmin) history.push('/')
    },
    [history, operatorData, operatorLoading]
  )

  useEffect(
    () => {
      dispatch(missingDataActions.getById(id))
    },
    [dispatch, id]
  )

  useEffect(
    () => {
      if (!oneMissingData) return
      const { loading } = oneMissingData

      if (!loading) setMissingLoading(false)
    },
    [oneMissingData]
  )

  const [actionMenu, setActionMenu] = useState(null)

  const randomnum = (minValue, maxValue) => {
    const min = Number(minValue)
    const max = Number(maxValue)

    return (Math.random() * (max - min) + min).toFixed(2)
  }

  const createSQL = (
    startDay,
    endDay,
    month,
    year,
    sensorID,
    minValue,
    maxValue
  ) => {
    const sqlStart = `INSERT INTO history_aggregated (sensor_id, value, hours, days, months, years) VALUES `
    const sqlValuesBeforeNumber = `(${sensorID}, `
    const sqlValuesAfterNumber = `, ${month}, ${year});`
    const totalSum = 0
    const queries = []

    while (startDay <= endDay) {
      const randomValue = randomnum(minValue, maxValue)

      queries.push(
        sqlStart +
          sqlValuesBeforeNumber +
          randomValue +
          ', NULL, ' +
          startDay +
          sqlValuesAfterNumber
      )
      //setTotalSum(Number(totalSum) + Number(randomValue))
      setStartDay(startDay + 1)
    }

    //setTotalSum(totalSum)
    setMonthQuerie(
      sqlStart +
        sqlValuesBeforeNumber +
        totalSum +
        ', NULL, Null' +
        sqlValuesAfterNumber
    )

    return queries
  }

  const createCopySQL = oldId => dispatch(missingDataActions.getById(oldId))

  const generateQueries = () =>
    setQueries(
      createSQL(startDay, endDay, month, year, sensorID, minValue, maxValue)
    )

  const copyDataQueries = () => {
    this.setState({
      copyData: createCopySQL(sensorIDCopyOld, sensorIDCopyNew),
    })
  }

  const dateArray = []
  const sqlStart =
    'INSERT INTO history_aggregated (sensor_id, value, hours, days, months, years) VALUES '

  if (sensorIDCopyNew !== 0)
    oneMissingData.item.forEach(dates => {
      dateArray.push(
        sqlStart +
          '(' +
          sensorIDCopyNew +
          ',' +
          dates.value +
          ',' +
          dates.hours +
          ',' +
          dates.days +
          ',' +
          dates.months +
          ',' +
          dates.years +
          ');'
      )
    })

  return (
    <>
      {/* preloader */}
      <Preloader isLoading={missingLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Sensoren'}
        onClose={() => setActionMenu(null)}
        open={Boolean(actionMenu)}
      >
        <Divider />
      </AM>
      {/* action menu end */}

      {missingLoading && (
        <KPage
          avatar={
            <Avatar>
              <MemoryTwoTone />
            </Avatar>
          }
          title={'Sensordaten Generator'}
        >
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <KTextField
                    icon={<CalendarTodayTwoTone />}
                    label={'Start Tag'}
                    onChange={event => setStartDay(event.target.value)}
                    value={startDay}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <KTextField
                    icon={<CalendarTodayTwoTone />}
                    label={'End Tag'}
                    onChange={event => setEndDay(event.target.value)}
                    value={endDay}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <KTextField
                    icon={<CalendarTodayTwoTone />}
                    label={'Monat'}
                    onChange={event => setMonth(event.target.value)}
                    value={month}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <KTextField
                    icon={<CalendarTodayTwoTone />}
                    label={'Jahr'}
                    onChange={event => setYear(event.target.value)}
                    value={year}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <KTextField
                    icon={<MemoryTwoTone />}
                    label={'Sensor ID'}
                    onChange={event => setSensorID(event.target.value)}
                    value={sensorID}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <KTextField
                    label={'Minimaler Wert'}
                    onChange={event => setMinValue(event.target.value)}
                    value={minValue}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <KTextField
                    label={'Maximaler Wert'}
                    onChange={event => setMaxValue(event.target.value)}
                    value={maxValue}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button onClick={() => generateQueries()}>
                    Queries erzeugen
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <h3>Queris der fehlenden Tage</h3>
                  {queries.map(query => query)}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <h3>Queris des fehlenden Monats</h3>
                  {monthQuerie}
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Grid item xs={12} sm={6}>
                <KTextField
                  label={'Alte Sensor ID'}
                  onChange={event => setSensorIDCopyOld(event.target.value)}
                  value={sensorIDCopyOld}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <KTextField
                  label={'Neue Sensor ID'}
                  onChange={event => setSensorIDCopyNew(event.target.value)}
                  value={sensorIDCopyNew}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button onClick={() => copyDataQueries()}>
                  Daten kopieren
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                {dateArray.map(query => query)}
              </Grid>
            </CardContent>
          </Card>
        </KPage>
      )}
    </>
  )
}
