import { systemConstants as SC } from '../constants'

const initialState = {
  allPayload: { loading: true },
  appVersion: { loading: true },
  isInMaintenance: { loading: true },
  maintenanceText: { loading: true },
}

export const system = (state = initialState, action) => {
  const {
    allPayload,
    appVersion,
    error,
    type,
    isInMaintenance,
    maintenanceText,
  } = action

  switch (type) {
    case SC.GET_ALL_PAYLOAD_FAILURE:
      return { ...state, allPayload: { error, loading: true } }

    case SC.GET_ALL_PAYLOAD_REQUEST:
      return { ...state, allPayload: { loading: true } }

    case SC.GET_ALL_PAYLOAD_SUCCESS:
      return { ...state, allPayload: { items: allPayload, loading: false } }

    case SC.GET_APP_VERSION_FAILURE:
      return { ...state, appVersion: { error, loading: true } }

    case SC.GET_APP_VERSION_REQUEST:
      return { ...state, appVersion: { loading: true } }

    case SC.GET_APP_VERSION_SUCCESS:
      return { ...state, appVersion: { item: appVersion, loading: false } }

    case SC.GET_IS_IN_MAINTENANCE_FAILURE:
      return { ...state, isInMaintenance: { error, loading: false } }

    case SC.GET_IS_IN_MAINTENANCE_REQUEST:
      return { ...state, isInMaintenance: { loading: true } }

    case SC.GET_IS_IN_MAINTENANCE_SUCCESS:
      return {
        ...state,
        isInMaintenance: { status: isInMaintenance, loading: false },
      }

    case SC.GET_MAINTENANCE_TEXT_FAILURE:
      return { ...state, maintenanceText: { error, loading: false } }

    case SC.GET_MAINTENANCE_TEXT_REQUEST:
      return { ...state, maintenanceText: { loading: true } }

    case SC.GET_MAINTENANCE_TEXT_SUCCESS:
      return {
        ...state,
        maintenanceText: { text: maintenanceText, loading: false },
      }

    default:
      return state
  }
}
