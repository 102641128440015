import { consumptionConstants as CC } from '../constants'

const initialState = {
  allConsumption: { loading: true },
  compareConsumption: { loading: true },
  meterReading: { loading: true },
}

export const consumption = (state = initialState, action) => {
  const { error, item, type } = action

  switch (type) {
    case CC.GET_ALL_REQUEST:
      return { ...state, allConsumption: { loading: true } }

    case CC.GET_ALL_SUCCESS:
      return {
        ...state,
        allConsumption: { item: item, loading: false },
      }

    case CC.GET_ALL_ERROR:
      return {
        ...state,
        allConsumption: { error, loading: true },
      }

    case CC.GET_CONSUMPTION_ERROR_24:
      return {
        ...state,
        consumption24: { loading: false, error },
      }

    case CC.GET_CONSUMPTION_REQUEST_24:
      return { ...state, consumption24: { loading: true } }

    case CC.GET_CONSUMPTION_SUCCESS_24:
      return {
        ...state,
        consumption24: { loading: false, item },
      }

    case CC.GET_CONSUMPTION_ERROR_MONTHLY:
      return {
        ...state,
        consumptionMonthly: { loading: false, error },
      }

    case CC.GET_CONSUMPTION_REQUEST_MONTHLY:
      return { ...state, consumptionMonthly: { loading: true } }

    case CC.GET_CONSUMPTION_SUCCESS_MONTHLY:
      return {
        ...state,
        consumptionMonthly: { loading: false, item },
      }

    case CC.GET_CONSUMPTION_ERROR_YEARLY:
      return {
        ...state,
        consumptionYearly: { loading: false, error },
      }

    case CC.GET_CONSUMPTION_REQUEST_YEARLY:
      return { ...state, consumptionYearly: { loading: true } }

    case CC.GET_CONSUMPTION_SUCCESS_YEARLY:
      return {
        ...state,
        consumptionYearly: { loading: false, item },
      }

    case CC.GET_ALL_METER_READING_REQUEST:
      return { ...state, meterReading: { loading: true } }

    case CC.GET_ALL_METER_READING_SUCCESS:
      return {
        ...state,
        meterReading: { item: item, loading: false },
      }

    case CC.GET_ALL_METER_READING_ERROR:
      return {
        ...state,
        meterReading: { error, loading: true },
      }

    case CC.GET_COMPARE_CONSUMPTION_ERROR:
      return {
        ...state,
        compareConsumption: { loading: false, error },
      }

    case CC.GET_COMPARE_CONSUMPTION_REQUEST:
      return { ...state, compareConsumption: { loading: true } }

    case CC.GET_COMPARE_CONSUMPTION_SUCCESS:
      return {
        ...state,
        compareConsumption: { loading: false, item },
      }

    default:
      return state
  }
}
