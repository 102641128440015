import { dashboardConstants as DC } from '../constants'

const initialState = {
  allDashboards: { loading: true },
  oneDashboard: { loading: true },
  newDashboard: { loading: true },
  allPanels: { loading: true },
  onePanel: { loading: true },
  newPanel: { loading: true },
  allDataPoints: { loading: true },
  oneDataPoint: { loading: true },
  newDataPoint: { loading: true },
}

export const dashboard = (state = initialState, action) => {
  const {
    dashboard,
    dashboards,
    panel,
    panels,
    dataPoint,
    dataPoints,
    error,
    type,
  } = action

  switch (type) {
    case DC.GET_ALL_DASHBOARDS_FAILURE:
      return { ...state, allDashboards: { error, loading: true } }

    case DC.GET_ALL_DASHBOARDS_REQUEST:
      return { ...state, allDashboards: { loading: true } }

    case DC.GET_ALL_DASHBOARDS_SUCCESS:
      return { ...state, allDashboards: { items: dashboards, loading: false } }

    case DC.GET_BY_ID_ERROR:
      return { ...state, oneDashboard: { error, loading: true } }

    case DC.GET_BY_ID_REQUEST:
      return { ...state, oneDashboard: { loading: true } }

    case DC.GET_BY_ID_SUCCESS:
      return { ...state, oneDashboard: { item: dashboard, loading: false } }

    case DC.REMOVE_DASHBOARD_ERROR:
      return { ...state, oneDashboard: { error, loading: true } }

    case DC.REMOVE_DASHBOARD_REQUEST:
      return { ...state, oneDashboard: { loading: true } }

    case DC.REMOVE_DASHBOARD_SUCCESS:
      return { ...state, oneDashboard: { loading: true } }

    case DC.SAVE_DASHBOARD_ERROR:
      return { ...state, oneDashboard: { error, loading: true } }

    case DC.SAVE_DASHBOARD_REQUEST:
      return { ...state, oneDashboard: { loading: true } }

    case DC.SAVE_DASHBOARD_SUCCESS:
      return { ...state, oneDashboard: { item: dashboard, loading: false } }

    case DC.UPDATE_DASHBOARD_ERROR:
      return { ...state, oneDashboard: { error, loading: true } }

    case DC.UPDATE_DASHBOARD_REQUEST:
      return { ...state, oneDashboard: { loading: true } }

    case DC.UPDATE_DASHBOARD_SUCCESS:
      return { ...state, oneDashboard: { loading: true } }

    // Panels
    case DC.GET_ALL_PANELS_FAILURE:
      return { ...state, allPanels: { error, loading: true } }

    case DC.GET_ALL_PANELS_REQUEST:
      return { ...state, allPanels: { loading: true } }

    case DC.GET_ALL_PANELS_SUCCESS:
      return { ...state, allPanels: { items: panels, loading: false } }

    case DC.GET_PANEL_BY_ID_ERROR:
      return { ...state, onePanel: { error, loading: true } }

    case DC.GET_PANEL_BY_ID_REQUEST:
      return { ...state, onePanel: { loading: true } }

    case DC.GET_PANEL_BY_ID_SUCCESS:
      return { ...state, onePanel: { item: panel, loading: false } }

    case DC.REMOVE_PANEL_ERROR:
      return { ...state, onePanel: { error, loading: true } }

    case DC.REMOVE_PANEL_REQUEST:
      return { ...state, onePanel: { loading: true } }

    case DC.REMOVE_PANEL_SUCCESS:
      return { ...state, onePanel: { loading: true } }

    case DC.SAVE_PANEL_ERROR:
      return { ...state, onePanel: { error, loading: true } }

    case DC.SAVE_PANEL_REQUEST:
      return { ...state, onePanel: { loading: true } }

    case DC.SAVE_PANEL_SUCCESS:
      return { ...state, onePanel: { item: panel, loading: false } }

    case DC.UPDATE_PANEL_ERROR:
      return { ...state, onePanel: { error, loading: true } }

    case DC.UPDATE_PANEL_REQUEST:
      return { ...state, onePanel: { loading: true } }

    case DC.UPDATE_PANEL_SUCCESS:
      return { ...state, onePanel: { loading: true } }

    // Data Points
    case DC.GET_ALL_DATA_POINTS_FAILURE:
      return { ...state, allDataPoints: { error, loading: true } }

    case DC.GET_ALL_DATA_POINTS_REQUEST:
      return { ...state, allDataPoints: { loading: true } }

    case DC.GET_ALL_DATA_POINTS_SUCCESS:
      return { ...state, allDataPoints: { items: dataPoints, loading: false } }

    case DC.GET_DATA_POINT_BY_ID_ERROR:
      return { ...state, oneDataPoint: { error, loading: true } }

    case DC.GET_DATA_POINT_BY_ID_REQUEST:
      return { ...state, oneDataPoint: { loading: true } }

    case DC.GET_DATA_POINT_BY_ID_SUCCESS:
      return { ...state, oneDataPoint: { item: dataPoint, loading: false } }

    case DC.REMOVE_DATA_POINT_ERROR:
      return { ...state, oneDataPoint: { error, loading: true } }

    case DC.REMOVE_DATA_POINT_REQUEST:
      return { ...state, oneDataPoint: { loading: true } }

    case DC.REMOVE_DATA_POINT_SUCCESS:
      return { ...state, oneDataPoint: { loading: true } }

    case DC.SAVE_DATA_POINT_ERROR:
      return { ...state, oneDataPoint: { error, loading: true } }

    case DC.SAVE_DATA_POINT_REQUEST:
      return { ...state, oneDataPoint: { loading: true } }

    case DC.SAVE_DATA_POINT_SUCCESS:
      return { ...state, oneDataPoint: { item: dataPoint, loading: false } }

    case DC.UPDATE_DATA_POINT_ERROR:
      return { ...state, oneDataPoint: { error, loading: true } }

    case DC.UPDATE_DATA_POINT_REQUEST:
      return { ...state, oneDataPoint: { loading: true } }

    case DC.UPDATE_DATA_POINT_SUCCESS:
      return { ...state, oneDataPoint: { loading: true } }

    default:
      return state
  }
}
