export const consumptionConstants = {
  GET_ALL_REQUEST: 'CONSUMPTION_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'CONSUMPTION_GET_ALL_SUCCESS',
  GET_ALL_ERROR: 'CONSUMPTION_GET_ALL_ERROR',

  GET_CONSUMPTION_ERROR_24: 'GET_CONSUMPTION_ERROR_24',
  GET_CONSUMPTION_REQUEST_24: 'GET_CONSUMPTION_REQUEST_24',
  GET_CONSUMPTION_SUCCESS_24: 'GET_CONSUMPTION_SUCCESS_24',

  GET_CONSUMPTION_ERROR_MONTHLY: 'GET_CONSUMPTION_ERROR_MONTHLY',
  GET_CONSUMPTION_REQUEST_MONTHLY: 'GET_CONSUMPTION_REQUEST_MONTHLY',
  GET_CONSUMPTION_SUCCESS_MONTHLY: 'GET_CONSUMPTION_SUCCESS_MONTHLY',

  GET_CONSUMPTION_ERROR_YEARLY: 'GET_CONSUMPTION_ERROR_YEARLY',
  GET_CONSUMPTION_REQUEST_YEARLY: 'GET_CONSUMPTION_REQUEST_YEARLY',
  GET_CONSUMPTION_SUCCESS_YEARLY: 'GET_CONSUMPTION_SUCCESS_YEARLY',

  GET_ALL_METER_READING_ERROR: 'GET_ALL_METER_READING_ERROR',
  GET_ALL_METER_READING_REQUEST: 'GET_ALL_METER_READING_REQUEST',
  GET_ALL_METER_READING_SUCCESS: 'GET_ALL_METER_READING_SUCCESS',

  GET_COMPARE_CONSUMPTION_ERROR: 'GET_COMPARE_CONSUMPTION_ERROR',
  GET_COMPARE_CONSUMPTION_REQUEST: 'GET_COMPARE_CONSUMPTION_REQUEST',
  GET_COMPARE_CONSUMPTION_SUCCESS: 'GET_COMPARE_CONSUMPTION_SUCCESS',
}
