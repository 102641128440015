import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { newsActions } from '../actions'

const newsTypesContext = createContext({})

export const ProvideNewsTypes = ({ children }) => {
  const value = useNews()

  return (
    <newsTypesContext.Provider value={value}>
      {children}
    </newsTypesContext.Provider>
  )
}
ProvideNewsTypes.propTypes = { children: PropTypes.any }

export const useNewsTypes = () => useContext(newsTypesContext)

const useNews = () => {
  const dispatch = useDispatch()
  const { newsTypes } = useSelector(state => state.news)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(
    () => {
      dispatch(newsActions.getAllNewsTypes())
      setLoading(true)
    },
    [dispatch]
  )

  useEffect(
    () => {
      const { error, fetchDate, item } = newsTypes

      if (item) {
        const { data } = item

        if (data) {
          setData(data)
          setLoading(false)
          setFetchDate(fetchDate)
        }
      }

      if (error) {
        setError(error)
        setLoading(false)
        setFetchDate(fetchDate)
      }
    },
    [newsTypes]
  )

  return { data, isError, isLoading, fetchDate }
}
