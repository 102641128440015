import { folderConstants as FC } from '../constants'

const initialState = {
  allFolders: { isLoading: true },
  customEnergyCompareConsumption: { loading: true },
  customEnergyConsumption: { loading: true },
  counterReading: { loading: true },
  energyConsumption: { loading: true },
  newFolder: { loading: true },
  newOfficeHours: { loading: true },
  oneFolder: { loading: true },
}

export const folder = (state = initialState, action) => {
  const { error, folder, folders, item, officeHours, type } = action

  switch (type) {
    case FC.DELETE_FOLDER_ERROR:
      return { ...state, remove: { loading: true, error } }

    case FC.DELETE_FOLDER_REQUEST:
      return { ...state, remove: { loading: true } }

    case FC.DELETE_FOLDER_SUCCESS:
      return { ...state, remove: { loading: true } }

    case FC.GET_ALL_ERROR:
      return {
        ...state,
        allFolders: { fetchDate: Date.now(), error, isLoading: true },
      }

    case FC.GET_ALL_REQUEST:
      return { ...state, allFolders: { isLoading: true } }

    case FC.GET_ALL_SUCCESS:
      return {
        ...state,
        allFolders: { fetchDate: Date.now(), isLoading: false, items: folders },
      }

    case FC.GET_BY_ID_ERROR:
      return { ...state, oneFolder: { loading: true, error } }

    case FC.GET_BY_ID_REQUEST:
      return { ...state, oneFolder: { loading: true } }

    case FC.GET_BY_ID_SUCCESS:
      return { ...state, oneFolder: { item: folder, loading: false } }

    case FC.GET_COUNTER_READING_ERROR:
      return {
        ...state,
        counterReading: { loading: true, error },
      }

    case FC.GET_COUNTER_READING_REQUEST:
      return { ...state, counterReading: { loading: true } }

    case FC.GET_COUNTER_READING_SUCCESS:
      return {
        ...state,
        counterReading: { loading: false, item },
      }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_ERROR:
      return {
        ...state,
        customEnergyConsumption: { loading: false, error },
      }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST:
      return { ...state, customEnergyConsumption: { loading: true } }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS:
      return {
        ...state,
        customEnergyConsumption: { loading: false, item },
      }

    case FC.GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_ERROR:
      return {
        ...state,
        customEnergyCompareConsumption: { loading: false, error },
      }

    case FC.GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_REQUEST:
      return { ...state, customEnergyCompareConsumption: { loading: true } }

    case FC.GET_CUSTOM_ENERGY_COMPARE_CONSUMPTION_SUCCESS:
      return {
        ...state,
        customEnergyCompareConsumption: { loading: false, item },
      }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_24:
      return {
        ...state,
        customEnergyConsumption24: { loading: false, error },
      }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_24:
      return { ...state, customEnergyConsumption24: { loading: true } }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_24:
      return {
        ...state,
        customEnergyConsumption24: { loading: false, item },
      }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_MONTHLY:
      return {
        ...state,
        customEnergyConsumptionMonthly: { loading: false, error },
      }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_MONTHLY:
      return { ...state, customEnergyConsumptionMonthly: { loading: true } }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_MONTHLY:
      return {
        ...state,
        customEnergyConsumptionMonthly: { loading: false, item },
      }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_ERROR_YEARLY:
      return {
        ...state,
        customEnergyConsumptionYearly: { loading: false, error },
      }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_REQUEST_YEARLY:
      return { ...state, customEnergyConsumptionYearly: { loading: true } }

    case FC.GET_CUSTOM_ENERGY_CONSUMPTION_SUCCESS_YEARLY:
      return {
        ...state,
        customEnergyConsumptionYearly: { loading: false, item },
      }

    case FC.GET_ENERGY_CONSUMPTION_ERROR:
      return { ...state, energyConsumption: { loading: false, error } }

    case FC.GET_ENERGY_CONSUMPTION_REQUEST:
      return { ...state, energyConsumption: { loading: true } }

    case FC.GET_ENERGY_CONSUMPTION_SUCCESS:
      return { ...state, energyConsumption: { loading: false, item } }

    case FC.SAVE_FOLDER_ERROR:
      return { ...state, newFolder: { loading: true, error } }

    case FC.SAVE_FOLDER_REQUEST:
      return { ...state, newFolder: { loading: true } }

    case FC.SAVE_OFFICE_HOURS_ERROR:
      return { ...state, newOfficeHours: { loading: true, error } }

    case FC.SAVE_OFFICE_HOURS_REQUEST:
      return { ...state, newOfficeHours: { loading: true } }

    case FC.SAVE_OFFICE_HOURS_SUCCESS:
      return { ...state, newOfficeHours: { item: officeHours, loading: false } }

    case FC.UPDATE_FOLDER_ERROR:
      return { ...state, update: { loading: true, error } }

    case FC.UPDATE_FOLDER_REQUEST:
      return { ...state, update: { loading: true } }

    case FC.UPDATE_FOLDER_SUCCESS:
      return { ...state, update: { loading: false } }

    default:
      return state
  }
}
