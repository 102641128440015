import {
  ExitToAppTwoTone,
  SettingsTwoTone,
  WidthWideTwoTone,
} from '@mui/icons-material'
import { Avatar, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppConfig, useOperator } from '../../hooks'
import avatar from '../../assets/img/default-avatar.png'
import { userActions } from '../../actions'

export const OperatorMenu = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { setGlobal: setAppConfig } = useAppConfig()
  const { data: operatorData, isLoading: operatorLoading } = useOperator()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuOpen = event => setAnchorEl(event.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  const gotoUserPage = () => {
    handleMenuClose()
    history.push('/user-page')
  }

  const logoutUser = () => {
    dispatch(userActions.logout())
    history.push('/pages/login-page')
  }

  const operatorPic = !operatorLoading
    ? operatorData?.image_url
      ? operatorData.image_url
      : avatar
    : null

  const operatorName = operatorData
    ? `${operatorData.first_name} ${operatorData.last_name}`
    : null

  const onClick = () => {
    handleMenuClose()
    setAppConfig(prev => ({ ...prev, showSettings: true }))
  }

  const data = [
    { fn: gotoUserPage, icon: null, label: operatorName },
    {
      fn: onClick,
      icon: <SettingsTwoTone sx={{ mr: 1 }} />,
      label: 'Einstellungen',
    },
    {
      fn: () =>
        setAppConfig(prev => ({ ...prev, wideScreen: !prev.wideScreen })),
      icon: <WidthWideTwoTone sx={{ mr: 1 }} />,
      label: 'Wide Screen',
    },
    {
      fn: logoutUser,
      icon: <ExitToAppTwoTone sx={{ mr: 1 }} />,
      label: 'Abmelden',
    },
  ]

  return (
    <>
      <Avatar
        onClick={handleMenuOpen}
        src={operatorPic}
        sx={{ pointer: 'cursor' }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {data.map((item, key) => {
          return (
            <MenuItem key={key} onClick={item.fn}>
              {item.icon}
              {item.label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
