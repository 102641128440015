export const systemConstants = {
  GET_ALL_PAYLOAD_FAILURE: 'NEWS_GET_PAYLOAD_ERROR',
  GET_ALL_PAYLOAD_REQUEST: 'NEWS_GET_PAYLOAD_REQUEST',
  GET_ALL_PAYLOAD_SUCCESS: 'NEWS_GET_PAYLOAD_SUCCESS',

  GET_APP_VERSION_FAILURE: 'GET_APP_VERSION_ERROR',
  GET_APP_VERSION_REQUEST: 'GET_APP_VERSION_REQUEST',
  GET_APP_VERSION_SUCCESS: 'GET_APP_VERSION_SUCCESS',

  GET_IS_IN_MAINTENANCE_FAILURE: 'GET_IS_IN_MAINTENANCE_FAILURE',
  GET_IS_IN_MAINTENANCE_REQUEST: 'GET_IS_IN_MAINTENANCE_REQUEST',
  GET_IS_IN_MAINTENANCE_SUCCESS: 'GET_IS_IN_MAINTENANCE_SUCCESS',

  GET_MAINTENANCE_TEXT_FAILURE: 'GET_MAINTENANCE_TEXT_FAILURE',
  GET_MAINTENANCE_TEXT_REQUEST: 'GET_MAINTENANCE_TEXT_REQUEST',
  GET_MAINTENANCE_TEXT_SUCCESS: 'GET_MAINTENANCE_TEXT_SUCCESS',

  SET_IS_IN_MAINTENANCE_FAILURE: 'SET_IS_IN_MAINTENANCE_FAILURE',
  SET_IS_IN_MAINTENANCE_REQUEST: 'SET_IS_IN_MAINTENANCE_REQUEST',
  SET_IS_IN_MAINTENANCE_SUCCESS: 'SET_IS_IN_MAINTENANCE_SUCCESS',

  SET_MAINTENANCE_TEXT_FAILURE: 'SET_MAINTENANCE_TEXT_FAILURE',
  SET_MAINTENANCE_TEXT_REQUEST: 'SET_MAINTENANCE_TEXT_REQUEST',
  SET_MAINTENANCE_TEXT_SUCCESS: 'SET_MAINTENANCE_TEXT_SUCCESS',
}
