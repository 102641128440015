import { FormControl, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const KHeadline = ({ children }) => (
  <FormControl fullWidth>
    <Typography component={'h6'} sx={{ my: 5 }} variant={'h6'}>
      {children}
    </Typography>
  </FormControl>
)

KHeadline.propTypes = {
  children: PropTypes.any.isRequired,
}
