import { authHeader } from '../api'
import { API } from 'aws-amplify'

import axios from 'axios'

const API_NAME = 'karmaApi'

const { REACT_APP_API_URL } = process.env

const ERR_MISSING_DASHBOARD = new Error('Kein Dashboard gewählt')
const ERR_INVALID_DASHBOARD = new Error('Ungültiges Dashboard')

const ERR_MISSING_DASHBOARD_PANEL = new Error('Kein Panel gewählt')
const ERR_INVALID_DASHBOARD_PANEL = new Error('Ungültiges Panel')

const ERR_MISSING_DASHBOARD_DATA_POINT = new Error('Kein Datenpunkt gewählt')
const ERR_INVALID_DASHBOARD_DATA_POINT = new Error('Ungültiger Datenpunkt')

const getAll = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/dashboard`,
  }

  try {
    const response = await axios(config)
    const { data } = response
    if (response) {
      const { status } = response
      if (status === 204) {
        return {
          items: {
            data: [],
          },
        }
      }
      return data
    } else {
      return data
    }
  } catch (error) {
    const { response } = error
    if (response) {
      const { status } = response
      if (status === 404) {
        return {
          items: {
            data: [],
          },
        }
      }
    } else {
      console.error('dashboardService.getAll', error)
    }
  }
}

const getById = async id => {
  if (!id || id === '') return Promise.reject(ERR_MISSING_DASHBOARD)
  if (Number(id) === 0) return Promise.reject(ERR_INVALID_DASHBOARD)

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/dashboard`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('dashboardsService.getById', error)
  }
}

const remove = async id => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/dashboard/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('dashboardsService.remove', error)
  }
}

const save = async body => {
  const endpoint = `/dashboard`
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'POST',
  }

  return await API.post(API_NAME, endpoint, options)
}

const update = async (id, body) => {
  const endpoint = `/dashboard/${id}`
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

const getAllPanels = async dashboardId => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/dashboard/${dashboardId}/panels`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('DASHBOARDSERVICE::GETALL', error)
    const { message, response } = error

    if (!response) {
      console.log('Error', message)
    }
  }
}

const getPanelById = async (dashboardId, panelId) => {
  if (!panelId || panelId === '')
    return Promise.reject(ERR_MISSING_DASHBOARD_PANEL)
  if (Number(panelId) === 0) return Promise.reject(ERR_INVALID_DASHBOARD_PANEL)

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/dashboard/${dashboardId}/panel/${panelId}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('dashboardsService.getById', error)
  }
}

const removePanel = async (dashboardId, panelId) => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/dashboard/${dashboardId}/panel/${panelId}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('dashboardsService.remove', error)
  }
}

const movePanel = async (body, dashboardId, panelId) => {
  const endpoint = `/dashboard/${dashboardId}/panel/${panelId}/move`
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

const savePanel = async (body, dashboardId) => {
  const endpoint = `/dashboard/${dashboardId}/panel`
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'POST',
  }
  return await API.post(API_NAME, endpoint, options)
}

const updatePanel = async (body, dashboardId, panelId) => {
  const endpoint = `/dashboard/${dashboardId}/panel/${panelId}`
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

const getAllDataPoints = async dashboardId => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/dashboard/${dashboardId}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('DASHBOARDSERVICE::GETALL', error)
    const { config, message, request, response } = error

    if (response) {
      const { headers, data, status } = response
      console.log(data)
      console.log(status)
      console.log(headers)
    } else if (request) {
      console.log(request)
    } else {
      console.log('Error', message)
    }

    console.log(config)
  }
}

const getDataPointById = async (dashboardId, panelId) => {
  if (!panelId || panelId === '')
    return Promise.reject(ERR_MISSING_DASHBOARD_DATA_POINT)
  if (Number(panelId) === 0)
    return Promise.reject(ERR_INVALID_DASHBOARD_DATA_POINT)

  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/dashboard/${dashboardId}/panel/${panelId}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('dashboardsService.getById', error)
  }
}

const removeDataPoint = async (dashboardID, panelID, dataPointID) => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/dashboard/${dashboardID}/panel/${panelID}/datapoint/${dataPointID}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('dashboardsService.remove', error)
  }
}

const saveDataPoint = async (body, dashboardId, panelId) => {
  const endpoint = `/dashboard/${dashboardId}/panel/${panelId}`
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'POST',
  }

  return await API.post(API_NAME, endpoint, options)
}

const updateDataPoints = async (body, dashboardId, panelId) => {
  const endpoint = `/dashboard/${dashboardId}/panel/${panelId}/datapoint`
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

const updatePanelsByDashboardId = async (dashboardId, body) => {
  const endpoint = `/dashboard/${dashboardId}/panel/`
  const options = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }

  return await API.patch(API_NAME, endpoint, options)
}

export const dashboardService = {
  getAll,
  getById,
  remove,
  save,
  update,
  getAllPanels,
  getPanelById,
  removePanel,
  movePanel,
  savePanel,
  updatePanel,
  getAllDataPoints,
  getDataPointById,
  removeDataPoint,
  saveDataPoint,
  updateDataPoints,
  updatePanelsByDashboardId,
}
