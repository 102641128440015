// modules
import {
  AccountBalanceTwoTone,
  BarChartTwoTone,
  DownloadTwoTone,
  MoreVertTwoTone,
  NotificationImportantTwoTone,
  SaveTwoTone,
  ShowChartTwoTone,
  TimelineTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import ReactExport from 'react-data-export'
import { useHistory, useParams } from 'react-router-dom'
// import scripts
import {
  categoryByType,
  DDMMYYYY,
  DMMMHHMM,
  DMMMMYYYY,
  normalizedSensorType,
  SensorIcon,
  sensorType,
  sensorUnit,
  DDMMYYYYHHMM,
  userLocales,
  SORT_NAME,
  getStatusColor,
  getCorrelationStatusColorDetailPage,
} from '../../api'
import { AM, AMItem, KPage, Preloader } from '../../components'
import { DataSampling, Statistics, TimeSeriesChart } from './components'
import { useAppConfig, useFolder, useSensor, useTimeSeries } from '../../hooks'

const { ExcelFile } = ReactExport
const { ExcelColumn, ExcelSheet } = ExcelFile

const removeDuplicates = data => {
  const results = new Set()

  return data.filter(f => {
    const key = new Date(f.datetime).toLocaleDateString(userLocales, DMMMHHMM)

    return results.has(key) ? false : results.add(key)
  })
}

const getAverage = data =>
  data.reduce((prev, cur) => prev + cur, 0) / data.length

export const SecurityHistory = () => {
  const history = useHistory()
  const { id: folderId, sensorid, medium: _medium } = useParams()
  const { global } = useAppConfig()
  const { salutationMode } = global

  const [sensorId, setSensorId] = useState(sensorid)
  const [dataSamplingDate, setDataSampingDate] = useState(false)
  const [medium, setMedium] = useState(normalizedSensorType(_medium))
  const [folderName, setFolderName] = useState('Gebäudename')

  const [sensorTypes, setSensorTypes] = useState([])
  const [allSensors, setAllSensors] = useState([])
  const [mediumSensors, setMediumSensors] = useState([])

  const [timeSeriesData, setTimeSeriesData] = useState([])

  const [actionMenu, setActionMenu] = useState(null)

  const [showPeriodWarning, setShowPeriodWarning] = useState(false)

  // range
  // YYYY-MM-DD
  const _YESTERDAY = new Date(new Date().setDate(new Date().getDate() - 1))
    .toISOString()
    .split('T')[0]
  const _TODAY = new Date().toISOString().split('T')[0]

  const [fromDate, setFromDate] = useState(_YESTERDAY)
  const [toDate, setToDate] = useState(_TODAY)

  // statistics
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(0)
  const [averageValue, setAverageValue] = useState(0)

  const [chartData, setChartData] = useState([])

  const [modal, setModal] = useState(false)
  const [chartType, setChartType] = useState('AreaChart')

  const timeSpan = `${new Date(fromDate).toLocaleDateString(
    userLocales,
    DMMMMYYYY
  )} - ${new Date(toDate).toLocaleDateString(userLocales, DMMMMYYYY)}`

  const [limitModal, setLimitModal] = useState(false)
  const [minLimit, setMinLimit] = useState(null)
  const [maxLimit, setMaxLimit] = useState(null)

  // color status of categories
  const [colorStatus, setColorStatus] = useState([
    { type: 'performance', status: 'neutral' },
    { type: 'installation', status: 'neutral' },
    { type: 'correlation', status: 'neutral' },
    { type: 'security', status: 'neutral' },
    { type: 'feeling', status: 'neutral' },
  ])

  const {
    data: folderData,
    isError: folderError,
    isLoading: folderLoading,
  } = useFolder(folderId)

  const {
    data: sensorData,
    isLoading: sensorLoading,
    isError: sensorError,
  } = useSensor(sensorid)

  const FILTER_IS_TYPE_SECURITY = f =>
    f.sensor_target === 'security' ||
    categoryByType(f.sensor_type) === 'security'

  const FILTER_IS_TYPE_MEDIUM = f =>
    f.sensor_target === 'security' ||
    (categoryByType(f.sensor_type) === 'security' &&
      f.sensor_target !== 'feeling')

  const germanDate = date =>
    new Date(date).toLocaleDateString(userLocales, DDMMYYYY)

  const { data: tsData } = useTimeSeries(sensorId, fromDate, toDate)

  useEffect(
    () => {
      if (folderData && medium) {
        const { name, sensors, sensor_group_status: sgs } = folderData
        setFolderName(name)

        if (sensors) {
          const FILTER_CURRENT_FOLDER = f =>
            Number(f.folder_id) === Number(folderId)

          setSensorTypes([
            ...new Set(
              sensors
                .filter(FILTER_IS_TYPE_SECURITY)
                .map(sensor => normalizedSensorType(sensor.sensor_type))
                // remove "water detector"s from sensor type selector,
                // as its detail page is undesired
                .filter(f => f === 'temperature')
            ),
          ])

          setAllSensors(sensors.filter(FILTER_CURRENT_FOLDER))

          setMediumSensors(
            sensors
              .filter(FILTER_CURRENT_FOLDER)
              .filter(f => normalizedSensorType(f.sensor_type) === medium)
              .filter(FILTER_IS_TYPE_MEDIUM)
          )
        }
        if (sgs) {
          const performance = sgs.find(f => f.type === 'status_performance')
          const security = sgs.find(f => f.type === 'status_security')
          const feeling = sgs.find(f => f.type === 'status_well_being')
          const installations = sgs.find(f => f.type === 'status_installation')

          setColorStatus({
            performance: performance.status,
            security: security.status,
            feeling: feeling.status,
            correlation: getCorrelationStatusColorDetailPage(sensors, folderId)
              ? 'green'
              : 'neutral',
            installation: installations.status,
          })
        }
      }
    },
    [folderData, folderId, medium]
  )

  useEffect(
    () => {
      if (tsData) {
        const sensor = allSensors.find(
          f => Number(f.entity_id) === Number(sensorId)
        )
        setTimeSeriesData(removeDuplicates(tsData))

        setAverageValue(getAverage(tsData.map(i => i.value)))
        setMinValue(Math.min(...tsData.map(i => i.value)))
        setMaxValue(Math.max(...tsData.map(i => i.value)))

        const data = tsData
          .sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
          .map(item => {
            const timestamp = new Date(item.datetime)
            const value = Number(item.value)
            const min = Number(minLimit)
            const max = Number(maxLimit)

            if (minLimit && maxLimit) return [timestamp, value, min, max]
            else if (maxLimit) return [timestamp, value, max]
            else if (minLimit) return [timestamp, value, min]
            else return [timestamp, value]
          })

        // add chart caption
        if (minLimit && maxLimit) {
          data.unshift(['Datum', sensorUnit(medium), 'GMin', 'GMax'])
        } else if (maxLimit) {
          data.unshift(['Datum', sensorUnit(medium), 'GMin'])
        } else if (minLimit) {
          data.unshift(['Datum', sensorUnit(medium), 'GMax'])
        } else {
          data.unshift(['Datum', sensorUnit(medium)])
        }

        if (data.length === 1) data.push([new Date(), 0])

        setChartData(data)
        if (sensor) setDataSampingDate(germanDate(sensor.data_sampling_date))
      }
    },
    [maxLimit, medium, minLimit, tsData, sensorId, allSensors]
  )

  const formatForExport = data => {
    if (!data) return

    const formated = []
    formated['Verbrauch'] = []

    data.forEach(item => {
      formated['Verbrauch'].push([
        new Date(item.timestamp * 1000).toLocaleDateString(
          userLocales,
          DDMMYYYYHHMM
        ) + ' UTC',
        item.value,
      ])
    })

    return formated
  }

  const tableDataExport = formatForExport(timeSeriesData)

  const isPageLoading = folderLoading || sensorLoading
  const isPageError = folderError || sensorError

  const renderFileName = () =>
    isPageLoading ? 'Export' : `${folderName}_${sensorData.name}`

  const tableData2ExcelData = (data, name) => {
    const alignment = { horizontal: 'center' }

    const border = trbl => {
      return {
        top: {
          style: trbl[0] === '2' ? 'medium' : trbl[0] === '1' ? 'thin' : 'none',
        },
        right: {
          style: trbl[1] === '2' ? 'medium' : trbl[1] === '1' ? 'thin' : 'none',
        },
        bottom: {
          style: trbl[2] === '2' ? 'medium' : trbl[2] === '2' ? 'thin' : 'none',
        },
        left: {
          style: trbl[3] === '2' ? 'medium' : trbl[3] === '2' ? 'thin' : 'none',
        },
      }
    }

    const fill = color => {
      return { fgColor: { rgb: color }, patternType: 'solid' }
    }
    const fontColor = color => {
      return { color: { rgb: color } }
    }

    const excelData = [
      {
        // LB requests tableData starting at B2, not A1
        xSteps: 1,
        ySteps: 1,

        // columns
        columns: [
          {
            title: 'Datum',
            // due to xSteps commented
            //width: { wpx: 150 },
            style: {
              alignment,
              border: border('2122'),
              fill: fill('FFCCCCCC'),
            },
          },
          {
            title: `Wert (${sensorUnit(medium)})`,
            width: { wpx: 200 },
            style: {
              alignment,
              border: border('2221'),
              fill: fill('FFCCCCCC'),
            },
          },
          {
            title: '',
            width: { wpx: 150 },
          },
          {
            title: 'Messpunkt',
            width: { wpx: 150 },
            style: {
              alignment,
              border: border('2122'),
              fill: fill('FFCCCCCC'),
            },
          },
          {
            title: `Minimum (${sensorUnit(medium)})`,
            width: { wpx: 250 },
            style: {
              alignment,
              border: border('2121'),
              fill: fill('FFCCCCCC'),
            },
          },
          {
            title: `Maximum (${sensorUnit(medium)})`,
            width: { wpx: 150 },
            style: {
              alignment,
              border: border('2121'),
              fill: fill('FFCCCCCC'),
            },
          },
          {
            title: `Durchschnitt (${sensorUnit(medium)})`,
            width: { wpx: 150 },
            style: {
              alignment,
              border: border('2221'),
              fill: fill('FFCCCCCC'),
            },
          },
          // due to xSteps, required for last column width
          { width: { wpx: 150 } },
        ],
        // data
        data: data.map((row, rowKey) => {
          const lastRow = data.length - 1
          // extend datagrid (2 col) by 1 spacer and 3 columns
          // 2 most upper rows are used only
          row = [...row, '', '', '', '', '']

          const result = row.map((col, colKey) => {
            const style = {
              font: fontColor('FF000000'),
              alignment,
              border: border(
                `1${colKey === 1 || colKey === 6 ? 2 : 1}${
                  rowKey === lastRow || (rowKey === 0 && colKey > 2) ? 2 : 1
                }${colKey === 0 || colKey === 3 ? 2 : 1}`
              ),
              fill: fill('FFFFFFFF'),
            }

            if (colKey === 3 && rowKey === 0) col = `${name || ''}`
            if (colKey === 4 && rowKey === 0) col = `${minValue.toFixed(2)}`
            if (colKey === 5 && rowKey === 0) col = `${maxValue.toFixed(2)}`
            if (colKey === 6 && rowKey === 0) col = `${averageValue.toFixed(2)}`

            return colKey === 2 || (colKey > 2 && rowKey > 0)
              ? { value: col }
              : { value: col, style }
          })

          return result
        }),
      },
    ]

    return excelData
  }

  return (
    <>
      {/* preloader */}
      <Preloader error={isPageError} isLoading={isPageLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Details Sicherheit'}
        open={Boolean(actionMenu)}
        onClose={() => setActionMenu(null)}
        historyUrlTarget={'security'}
        historyUrlId={Number(folderId)}
      >
        <AMItem
          caption={'Details zum Gebäude'}
          icon={<AccountBalanceTwoTone />}
          onClick={() => history.push(`/folder/${folderId}`)}
        />

        <Divider />

        <AMItem
          caption={'Leistung'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('performance', colorStatus.performance)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/performance`)}
        />

        <AMItem
          caption={'Anlagen'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('installation', colorStatus.installation)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/installation`)}
        />

        <AMItem
          caption={'Korrelation'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('correlation', colorStatus.correlation)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/correlation`)}
        />

        <AMItem
          caption={'Sicherheit'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('security', colorStatus.security)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/security`)}
        />

        <AMItem
          caption={'Wohlbefinden'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('feeling', colorStatus.feeling)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/feeling`)}
        />
      </AM>
      {/* action menu end */}

      {/* Export Dialog */}
      <Dialog open={modal} onClose={() => setModal(false)}>
        <DialogTitle onClose={() => setModal(false)}>
          Export von {`${folderName}`}
        </DialogTitle>
        <DialogContent>
          <Typography>Zeitraum: {timeSpan}</Typography>
          <Typography>Medium: {sensorType(medium)}</Typography>
        </DialogContent>
        <DialogActions>
          <CSVLink
            headers={['Datum', `Wert (${sensorUnit(medium)})`]}
            data={tableDataExport && tableDataExport['Verbrauch']}
            filename={`${renderFileName()}.csv`}
            target={'_blank'}
          >
            <Button>
              <SaveTwoTone /> CSV
            </Button>
          </CSVLink>

          {/* https://www.npmjs.com/package/react-export-excel */}
          <ExcelFile
            filename={renderFileName()}
            element={
              <Button>
                <SaveTwoTone />
                Excel
              </Button>
            }
          >
            <ExcelSheet
              dataSet={tableData2ExcelData(
                tableDataExport['Verbrauch'],
                (sensorData && sensorData.name) || ''
              )}
              name={renderFileName()}
            >
              <ExcelColumn label={'Datum'} value={0} />
              <ExcelColumn label={`Wert (${sensorUnit(medium)})`} value={1} />
            </ExcelSheet>
          </ExcelFile>
        </DialogActions>
      </Dialog>
      {/* Export Dialog */}

      {/* limits */}
      <Dialog open={limitModal} onClose={() => setLimitModal(false)}>
        <DialogTitle onClose={() => setLimitModal(false)}>
          Grenzwerte der Messpunkte
        </DialogTitle>
        <DialogContent>
          <Alert severity={'info'} sx={{ mt: 2, py: 1 }}>
            Hier {salutationMode === 'default' ? 'können Sie' : 'kannst Du'}{' '}
            auswählen, ob für den ausgewählten Messpunkt Grenzwertlinien
            eingeblendetet werden sollen.
          </Alert>
          {mediumSensors
            .filter(f => normalizedSensorType(f.sensor_type) === medium)
            .map((sensor, key) => {
              const { entity_id, name, attributes_values } = sensor

              let alarm_min = attributes_values.find(
                av => av.attribute_code === 'alarm_min'
              )

              if (alarm_min !== undefined) {
                alarm_min = alarm_min.value
              } else {
                alarm_min = 'Nicht hinterlegt'
              }

              let alarm_max = attributes_values.find(
                av => av.attribute_code === 'alarm_max'
              )

              if (alarm_max !== undefined) {
                alarm_max = alarm_max.value
              } else {
                alarm_max = 'Nicht hinterlegt'
              }

              if (Number(entity_id) === Number(sensorId)) {
                return (
                  <Grid container spacing={2} key={key}>
                    <Grid item xs={12} md={4}>
                      <Typography sx={{ py: 1, textAlign: 'center' }}>
                        Messpunkt
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography sx={{ py: 1, textAlign: 'center' }}>
                        Grenzwert Minimum
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography sx={{ py: 1, textAlign: 'center' }}>
                        Grenzwert Maximum
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                      <Typography sx={{ py: 1 }}>{name}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={minLimit}
                            onChange={() =>
                              setMinLimit(minLimit ? null : alarm_min)
                            }
                          />
                        }
                        label={`${alarm_min} ${sensorUnit(medium)}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={maxLimit}
                            onChange={() =>
                              setMaxLimit(maxLimit ? null : alarm_max)
                            }
                          />
                        }
                        label={`${alarm_max} ${sensorUnit(medium)}`}
                        sx={{ textAlignLast: 'center' }}
                      />
                    </Grid>
                  </Grid>
                )
              }

              return true
            })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLimitModal(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>
      {/* limits end */}

      <KPage
        action={
          <IconButton onClick={event => setActionMenu(event.currentTarget)}>
            <MoreVertTwoTone />
          </IconButton>
        }
        avatar={
          <Avatar>
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon(
                'security',
                getStatusColor(allSensors.filter(FILTER_IS_TYPE_MEDIUM))
              )}
              sx={{ width: 40 }}
            />
          </Avatar>
        }
        isLoading={isPageLoading}
        title={'Details Sicherheit'}
      >
        {/* Sensor Time Series */}
        <Card sx={{ minHeight: '100%' }} variant={'security'}>
          <CardHeader
            sx={{ py: 0 }}
            title={
              <Stack
                direction={'row'}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Stack alignItems={'center'} direction={'row'}>
                  <TextField
                    label={'Raumwert'}
                    onChange={event => {
                      const newMedium = event.target.value
                      const newSensorId = allSensors.filter(
                        f => f.sensor_type === newMedium
                      )

                      if (newSensorId && newSensorId[0]) {
                        setSensorId(newSensorId[0].entity_id)
                        setDataSampingDate(
                          germanDate(newSensorId[0].data_sampling_date)
                        )
                      }

                      setMedium(newMedium)
                      setMinLimit(null)
                      setMaxLimit(null)
                    }}
                    select
                    type={'text'}
                    value={medium || ''}
                    variant={'filled'}
                  >
                    {sensorTypes.map((medium, key) => (
                      <MenuItem key={key} value={medium}>
                        <Stack direction={'row'}>
                          <ListItemIcon>
                            <Box
                              alt={''}
                              component={'img'}
                              src={SensorIcon(
                                medium,
                                getStatusColor(
                                  allSensors
                                    .filter(FILTER_IS_TYPE_SECURITY)
                                    .filter(FILTER_IS_TYPE_MEDIUM)
                                    .filter(
                                      f =>
                                        normalizedSensorType(f.sensor_type) ===
                                        medium
                                    )
                                )
                              )}
                              sx={{ width: 25 }}
                            />
                          </ListItemIcon>
                          <ListItemText>{sensorType(medium)}</ListItemText>
                        </Stack>
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    label={'Messpunkt'}
                    onChange={event => {
                      setSensorId(event.target.value.entity_id)
                      setDataSampingDate(
                        germanDate(event.target.value.data_sampling_date)
                      )
                      setMinLimit(null)
                      setMaxLimit(null)
                    }}
                    select
                    type={'text'}
                    value={
                      mediumSensors.find(
                        f => Number(f.entity_id) === Number(sensorId)
                      ) || ''
                    }
                    variant={'filled'}
                  >
                    {mediumSensors
                      .filter(
                        f => normalizedSensorType(f.sensor_type) === medium
                      )
                      .sort(SORT_NAME)
                      .map((sensor, key) => (
                        <MenuItem key={key} value={sensor}>
                          <Stack direction={'row'}>
                            <ListItemIcon>
                              <Box
                                alt={''}
                                component={'img'}
                                src={SensorIcon(medium, sensor.status)}
                                sx={{ width: 25 }}
                              />
                            </ListItemIcon>
                            <ListItemText>{sensor.name}</ListItemText>
                          </Stack>
                        </MenuItem>
                      ))}
                  </TextField>

                  <Tooltip title={'Grenzwerte'}>
                    <ToggleButton onClick={() => setLimitModal(true)}>
                      <NotificationImportantTwoTone />
                    </ToggleButton>
                  </Tooltip>
                </Stack>

                <Stack alignItems={'center'} direction={'row'}>
                  <ToggleButtonGroup
                    exclusive
                    onChange={(event, chartType) => {
                      if (chartType !== null) setChartType(chartType)
                    }}
                    sx={{ mx: 1 }}
                    value={chartType}
                  >
                    {[
                      {
                        icon: <BarChartTwoTone />,
                        title: 'Säulendiagramm',
                        value: 'ColumnChart',
                      },
                      {
                        icon: <ShowChartTwoTone />,
                        title: 'Bereichsdiagramm',
                        value: 'AreaChart',
                      },
                      {
                        icon: <TimelineTwoTone />,
                        title: 'Liniendiagramm',
                        value: 'LineChart',
                      },
                    ].map((item, key) => (
                      <ToggleButton key={key} value={item.value}>
                        <Tooltip title={item.title}>{item.icon}</Tooltip>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>

                  <Tooltip title={'Exportieren'}>
                    <IconButton onClick={() => setModal(true)}>
                      <DownloadTwoTone />
                    </IconButton>
                  </Tooltip>

                  <TextField
                    label={'Datum'}
                    onChange={event => {
                      const fromDate = event.target.value
                      let toDate

                      if (new Date(fromDate) > Date.now())
                        setShowPeriodWarning(true)
                      else {
                        toDate = new Date(fromDate)
                        toDate.setDate(toDate.getDate() + 2)
                        setFromDate(fromDate)
                        setToDate(toDate.toISOString().substring(0, 10))
                        setShowPeriodWarning(false)
                      }
                    }}
                    sx={{ ml: 1 }}
                    type={'date'}
                    value={fromDate}
                    variant={'filled'}
                  />
                </Stack>
              </Stack>
            }
            variant={'security'}
          />
          {!isPageLoading && (
            <CardContent sx={{ p: '0px !important' }}>
              <Collapse in={showPeriodWarning}>
                <Alert severity={'warning'}>
                  Bitte wählen Sie ein Startdatum, für welches bereits Daten
                  erhoben wurden und nicht in der Zukunft liegt.
                </Alert>
              </Collapse>

              <Collapse in={tsData && tsData.length > 0 && !showPeriodWarning}>
                <DataSampling dataSamplingDate={dataSamplingDate} />

                <TimeSeriesChart
                  backgroundColor={'white'}
                  chartType={chartType}
                  data={chartData}
                  medium={medium}
                  unit={sensorUnit(medium)}
                  series={{
                    1: { type: 'line', color: 'red' },
                    2: { type: 'line', color: 'red' },
                  }}
                />

                <Statistics
                  average={averageValue}
                  minimum={minValue}
                  maximum={maxValue}
                  unit={sensorUnit(medium)}
                />
              </Collapse>
            </CardContent>
          )}
        </Card>
      </KPage>
    </>
  )
}
