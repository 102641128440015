import { Box, InputAdornment, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { sensorUnit, userLocales } from '../../../../api'

export const KPIViewer = ({
  exportModal,
  kpiData,
  medium,
  precision = 2,
  ...rest
}) => {
  const { average, max, min, total, type } = kpiData

  let metrics

  switch (type) {
    case 'consumption':
      metrics = { label: 'Verbrauch', unit: sensorUnit(medium) }
      break

    case 'price':
      metrics = { label: 'Kosten', unit: '€' }
      break

    case 'co2':
      metrics = { label: 'CO₂ Ausstoß', unit: 'kg' }
      break

    default:
      console.error('unhandled KPIViewer type', type)
  }

  const format = value =>
    isNaN(value)
      ? '-'
      : Number(value).toLocaleString(userLocales, {
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
        })

  const data = [
    { label: metrics.label, value: format(total) },
    { label: 'Minimum', value: format(min) },
    { label: 'Maximum', value: format(max) },
    { label: 'Durchschnitt', value: format(average) },
  ]

  return (
    <Box {...rest}>
      {data.map((item, key) => {
        const { label, value } = item

        return (
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>{metrics.unit}</InputAdornment>
              ),
              readOnly: true,
            }}
            key={key}
            label={label}
            sx={{ width: 150 }}
            value={value}
            variant={exportModal ? 'filled' : 'outlined'}
          />
        )
      })}
    </Box>
  )
}

KPIViewer.propTypes = {
  kpiData: PropTypes.shape({
    average: PropTypes.number.isRequired || PropTypes.string.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }),
  medium: PropTypes.string.isRequired,
  precision: PropTypes.number,
  exportModal: PropTypes.bool,
}
