// modules
import { HomeTwoTone } from '@mui/icons-material'
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material'
import PerfectScrollbar from 'perfect-scrollbar'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useOperator } from '../../hooks'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

let ps

const SidebarWrapper = ({ children }) => {
  let ref = useRef(null)

  useEffect(() => {
    const { platform } = navigator

    if (platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(ref, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
      ps.destroy()
    }
  }, [])

  return (
    <Box
      sx={{
        height: 'calc(100vh - 75px)',
        position: 'relative',
        width: 260,
        zIndex: 4,
      }}
      ref={r => (ref = r)}
    >
      {children}
    </Box>
  )
}

SidebarWrapper.propTypes = {
  children: PropTypes.node,
}

const Brand = ({ logo }) => {
  return (
    <Box
      sx={{
        p: '20px 0px',
        position: 'relative',
        zIndex: 4,
        '& img': {
          cursor: 'pointer',
          float: 'left',
          mx: '20px',
          width: 35,
        },
        '&:after': {
          backgroundColor: 'hsla(0,0%,100%,.3)',
          bottom: '0',
          content: '""',
          height: '1px',
          position: 'absolute',
          right: '15px',
          width: 'calc(100% - 30px)',
        },
      }}
    >
      <Link to={'/'}>
        <img src={logo} alt={'logo'} />
      </Link>
      <Link
        to={'/'}
        style={{
          color: 'white',
          display: 'block',
          fontSize: '18px',
          padding: '5px 0px',
        }}
      >
        KARMA
      </Link>
    </Box>
  )
}

Brand.propTypes = {
  logo: PropTypes.string,
}

export const Sidebar = ({ logo, routes }) => {
  const theme = useTheme()
  const color = theme.palette.primary.main
  const white = theme.palette.white

  const history = useHistory()
  const location = useLocation()

  const { data: operatorData, isLoading: operatorLoading } = useOperator()

  // handler

  const gotoHome = () => history.push('/')

  return (
    <Drawer
      anchor={'left'}
      sx={{
        '& .MuiDrawer-paper': {
          background: `linear-gradient(45deg, #0E28BB 0%, ${color} 50%, ${color} 100%)`,
          bottom: 0,
          height: '100vh',
          left: 0,
          overflow: 'hidden',
          position: 'fixed',
          top: 0,
          width: 260,
        },
      }}
      open
      variant={'permanent'}
    >
      <Brand logo={logo} />
      <SidebarWrapper>
        <List sx={{ mt: 2, p: 0 }}>
          {/* Dashboard */}
          <ListItemButton onClick={gotoHome}>
            <ListItemIcon sx={{ color: white }}>
              <HomeTwoTone />
            </ListItemIcon>

            <ListItemText primary={'Gebäudeübersicht'} sx={{ color: white }} />
          </ListItemButton>

          {!operatorLoading &&
            routes.map((route, key) => {
              const { isAdmin, icon, name, hidden, path, redirect } = route

              const gotoPath = () => history.push(path)
              const isSelected = location.pathname.includes(path)

              return hidden ||
                redirect ||
                (isAdmin && !operatorData?.isAdmin) ? null : (
                <ListItemButton
                  key={key}
                  onClick={gotoPath}
                  selected={isSelected}
                >
                  <ListItemIcon sx={{ color: white }}>{icon}</ListItemIcon>

                  <ListItemText primary={name} sx={{ color: white }} />
                </ListItemButton>
              )
            })}
        </List>
      </SidebarWrapper>
    </Drawer>
  )
}

Sidebar.propTypes = {
  logo: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
}
