import { Alert, useTheme } from '@mui/material'
import React from 'react'

export const HintSelectFolder = () => {
  const theme = useTheme()
  const borderColor = theme.palette.warning.main
  const border = `solid 1px ${borderColor}`

  return (
    <Alert severity={'warning'} sx={{ mx: 1, my: 2, border }}>
      Bitte wählen Sie ein Gebäude, um fortzufahren.
    </Alert>
  )
}
