// modules
import {
  Alert,
  AlertTitle,
  Box,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import { Link } from 'react-router-dom'
// scripts
import { germanDecimals, userLocales } from '../../../api'

export const IntervalAlert = ({ sensors, title }) => {
  let time = sensors.find(f => f.name === title)
  time = time.time || ''

  const hours = new Date(Date.now() - new Date(time)).getTime() / 1000 / 60 / 60
  const days = Math.trunc(hours / 24)

  let severity = 'success'
  if (days >= 3) severity = 'error'
  if (days >= 1 && days < 3) severity = 'warning'
  if (days <= 0) severity = 'success'

  return severity !== 'success' ? (
    <Alert severity={severity}>
      {severity === 'warning' && <>zuletzt aktualisiert vor mehr als 24h</>}
      {severity === 'error' && <>zuletzt aktualisiert vor mehr als 72h</>}
    </Alert>
  ) : (
    <></>
  )
}

IntervalAlert.propTypes = {
  sensors: PropTypes.array,
  title: PropTypes.string,
}

export const PackageUpgrade = ({ coach }) => {
  const theme = useTheme()
  const color = theme.palette.primary.main

  if (!coach || !coach.attributes) return <Box />

  const {
    firstname,
    lastname,
    email_contact,
    telephone,
    telephone_mobile,
  } = coach.attributes

  return (
    <Alert severity={'info'} sx={{ mb: 5 }}>
      <AlertTitle>Paket nicht gebucht</AlertTitle>
      Bei Interesse nehmen Sie bitte Kontakt zu Ihrem KARMA Coach auf:
      <Typography
        sx={{
          textTransform: 'uppercase',
          letterSpacing: 2,
          fontWeight: 'bold',
          mt: 5,
          mb: 2,
        }}
      >
        {firstname} {lastname}
      </Typography>
      <Typography>
        Telefon:{' '}
        <Link style={{ color }} to={`tel:${telephone}`}>
          {telephone}
        </Link>
      </Typography>
      <Typography>
        Mobil:{' '}
        <Link style={{ color }} to={`tel:${telephone_mobile}`}>
          {telephone_mobile}
        </Link>
      </Typography>
      <Typography>
        Mail:{' '}
        <Link style={{ color }} to={`mailto:${email_contact}`}>
          {email_contact}
        </Link>
      </Typography>
    </Alert>
  )
}

PackageUpgrade.propTypes = {
  coach: PropTypes.object.isRequired,
}

export const Statistics = ({ average, minimum, maximum, unit }) => {
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-evenly'}
      sx={{ color: '#9e9e9e', mx: 15, my: { xs: 1, md: 2 } }}
    >
      <Typography>
        <strong>Durchschnitt:</strong>{' '}
        {Number(average).toLocaleString(userLocales, germanDecimals)} {unit}
      </Typography>

      <Typography>
        <strong>Minimum:</strong>{' '}
        {Number(minimum).toLocaleString(userLocales, germanDecimals)} {unit}
      </Typography>

      <Typography>
        <strong>Maximum:</strong>{' '}
        {Number(maximum).toLocaleString(userLocales, germanDecimals)} {unit}
      </Typography>
    </Stack>
  )
}

Statistics.propTypes = {
  average: PropTypes.number.isRequired,
  minimum: PropTypes.number.isRequired,
  maximum: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
}

export const TimeSeriesChart = ({
  backgroundColor = 'none',
  chartArea = { height: '75%', width: '80%' },
  chartType = 'AreaChart',
  curveType = 'function',
  data,
  height = '400px',
  legend = 'none',
  medium,
  minValue = 0,
  textStyle = { fontSize: 10 },
  unit,
  series,
}) => {
  const theme = useTheme()
  const correlation = theme.palette.correlation.main
  const colors = [correlation]

  if (data && data.length > 0) {
    // data records contain 2 columns: [date, value]
    // add 3rd column caption for the tooltip: [date, value, tooltip]
    if (data[0].length === 2)
      data[0].push({ role: 'tooltip', type: 'string', p: { html: true } })

    // flood all records with 3rd column
    for (let i = 1; i < data.length; i++)
      if (data[i].length === 2) {
        const timestamp = new Date(data[i][0]).toLocaleTimeString(userLocales, {
          weekday: 'long',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
        const value = Number(data[i][1]).toLocaleString(userLocales)

        data[i].push(
          `<span style="margin:0px;padding:0px">${timestamp}: <strong>${value} ${unit}</strong></span>`
        )
      }
  }

  return (
    <Chart
      chartLanguage={'de_DE'}
      chartType={chartType}
      data={data}
      height={height}
      options={{
        backgroundColor,
        colors,
        chartArea,
        curveType,
        hAxis: { textStyle },
        legend,
        // render tooltip with html (vs svg)
        tooltip: { isHtml: true, trigger: 'visible' },
        vAxis: {
          format: medium !== 'humidity' ? `# ${unit}` : "#.#'%'",
          minValue,
          textStyle,
        },
        series: series ? series : {},
      }}
    />
  )
}

TimeSeriesChart.propTypes = {
  chartArea: PropTypes.object.isRequired,
  curveType: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  chartType: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  height: PropTypes.string.isRequired,
  legend: PropTypes.string.isRequired,
  medium: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  textStyle: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
  series: PropTypes.object,
}

export const Weather = ({ city }) => {
  if (city === '') city = 'Ludwigsburg'

  const appId = '4de3768c62b67fe359758977a3efc069'
  const lang = 'de'
  const units = 'metric'

  const [isLoaded, setLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [weather, setWeather] = useState([])

  useEffect(
    () => {
      const url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${appId}&lang=${lang}&units=${units}`

      fetch(url)
        .then(result => result.json())
        .then(result => {
          const { main, name, weather } = result
          const { description, icon } = weather[0]
          const { temp } = main

          setWeather({ description, icon, name, temp })
        })
        .then(() => setLoaded(true))
        .catch(error => setError(error))
    },
    [city]
  )

  if (!isLoaded) return <>Wetterdaten werden geladen...</>
  if (error) return <>Keine Wetterdaten verfügbar</>

  return (
    <Box
      sx={{
        position: 'relative',
        mt: '15px',
        textAlign: 'right',
        m: '0 50px 0 0',
      }}
    >
      <Box>
        {weather.name}
        <Box
          alt={'Wetter Icon'}
          component={'img'}
          src={`https://openweathermap.org/img/wn/${weather.icon}.png`}
          sx={{ position: 'absolute', top: '-20px' }}
          title={'Wohlbefinden'}
        />
      </Box>
      <Box>
        {Math.round(weather.temp)}
        &deg;C, {weather.description}
      </Box>
    </Box>
  )
}

Weather.propTypes = { city: PropTypes.string }

export const DataSampling = ({ dataSamplingDate }) => {
  const theme = useTheme()
  const { white: bgcolor } = theme.palette

  return (
    <>
      {dataSamplingDate && (
        <Stack
          direction={'row'}
          justifyContent={'left'}
          sx={{ bgcolor, pt: '20px', pl: '115px' }}
        >
          <Typography>Datenbeginn: {dataSamplingDate}</Typography>
        </Stack>
      )}
    </>
  )
}

DataSampling.propTypes = { dataSamplingDate: PropTypes.object }
