import { sensorType, sensorUnit, userLocales } from '../../api'

// helpers

const alignment = { horizontal: 'center' }

const border = trbl => ({
  top: { style: trbl[0] === '2' ? 'medium' : 'thin' },
  right: { style: trbl[1] === '2' ? 'medium' : 'thin' },
  bottom: { style: trbl[2] === '2' ? 'medium' : 'thin' },
  left: { style: trbl[3] === '2' ? 'medium' : 'thin' },
})
const fill = color => ({ fgColor: { rgb: color }, patternType: 'solid' })
const fontColor = color => ({ color: { rgb: color } })

const parseNumber = data =>
  typeof data === 'string'
    ? Number(data.replace('.', '').replace(',', '.'))
    : data

// excel colors; starting with FF followed by 6 hex digits, representing 'FFRRGGBB'
const XLS_BLACK = 'FF000000'
const XLS_WHITE = 'FFFFFFFF'
const XLS_GRAY = 'FFCCCCCC'
const XLS_GREEN = 'FF009000'
const XLS_RED = 'FFFF0000'

// excel export data

export const tableData2ExcelData = (
  data,
  name,
  roomsMode,
  priceData,
  folderData,
  kpiData,
  medium
) => {
  const unit = sensorUnit(medium)

  const COLUMN_0 = 'Datum'
  const COLUMN_1 = `IST Verbrauch (${unit})`
  const COLUMN_2 = 'Trend (%)'
  const COLUMN_3 = `CO₂-Emissionen (kg)`
  const COLUMN_4 = `Verbrauch / m² (${unit})`
  const COLUMN_5 = `Verbrauch / ${roomsMode} (${unit})`
  const COLUMN_6 = `IST Kosten (€)`
  const COLUMN_7 = `Kosten / m² (€)`
  const COLUMN_8 = `Benchmark (%)`
  const COLUMN_9 = `Zählerstand`
  const COLUMN_10 = `Kosten / ${roomsMode} (€)`

  const columns = [
    {
      style: { alignment, border: border('2122'), fill: fill(XLS_GRAY) },
      title: COLUMN_0,
      // due to xSteps commented
      //width: { wpx: 150 },
    },
    {
      style: { alignment, border: border('2121'), fill: fill(XLS_GRAY) },
      title: COLUMN_1,
      width: { wpx: 150 },
    },
    {
      style: { alignment, border: border('2121'), fill: fill(XLS_GRAY) },
      title: COLUMN_2,
      width: { wpx: 150 },
    },
    {
      style: { alignment, border: border('2121'), fill: fill(XLS_GRAY) },
      title: COLUMN_3,
      width: { wpx: 150 },
    },
    {
      style: { alignment, border: border('2121'), fill: fill(XLS_GRAY) },
      title: COLUMN_4,
      width: { wpx: 150 },
    },
    {
      style: { alignment, border: border('2121'), fill: fill(XLS_GRAY) },
      title: COLUMN_5,
      width: { wpx: 200 },
    },
    {
      style: { alignment, border: border('2121'), fill: fill(XLS_GRAY) },
      title: COLUMN_6,
      width: { wpx: 200 },
    },
    {
      style: { alignment, border: border('2121'), fill: fill(XLS_GRAY) },
      title: COLUMN_7,
      width: { wpx: 150 },
    },
    {
      style: { alignment, border: border('2121'), fill: fill(XLS_GRAY) },
      title: COLUMN_8,
      width: { wpx: 150 },
    },
    {
      style: { alignment, border: border('2121'), fill: fill(XLS_GRAY) },
      title: COLUMN_9,
      width: { wpx: 150 },
    },
    {
      style: { alignment, border: border('2221'), fill: fill(XLS_GRAY) },
      title: COLUMN_10,
      width: { wpx: 150 },
    },

    // due to xSteps, required for last column width
    { width: { wpx: 150 } },
  ]

  const excelData = [
    {
      // LB requests tableData starting at B2, not A1
      xSteps: 1,
      ySteps: 1,

      // columns
      columns,

      // map data to excel Arbeitsmappe
      data: data.map((row, rowKey) => {
        const lastRow = data.length - 1

        return row.map((value, colKey) => {
          const isLast = rowKey === lastRow
          const isUpTrend = String(value).includes('▲')
          const isDownTrend = String(value).includes('▼')
          const _fill = fill(isLast ? XLS_GRAY : XLS_WHITE)
          const font = fontColor(
            isUpTrend ? XLS_RED : isDownTrend ? XLS_GREEN : XLS_BLACK
          )
          const firstItem = colKey === 0
          const lastItem = colKey === row.length - 1
          const _border = isLast
            ? border(`2${lastItem ? 2 : 1}2${firstItem ? 2 : 1}`)
            : border(`1${lastItem ? 2 : 1}1${firstItem ? 2 : 1}`)
          const _value =
            colKey === 1
              ? parseNumber(`${value.toLocaleString(userLocales)}`)
              : value

          return {
            style: { alignment, border: _border, fill: _fill, font },
            value: _value === 'NaN' ? '-' : _value,
          }
        })
      }),
    },
  ]

  // Gesamtverbrauch
  if (name === 'Gesamtverbrauch') {
    const { average, max, min, total } = kpiData

    const MEDIUM_HEADER = 'Medium'
    const MEDIUM_VALUE = sensorType(medium)

    const NAME_HEADER = 'Zähler'
    const NAME_VALUE = name

    const KPI_HEADER_0 = `Verbrauch (${unit})`
    const KPI_HEADER_1 = `Minimum (${unit})`
    const KPI_HEADER_2 = `Maximum (${unit})`
    const KPI_HEADER_3 = `Durchschnitt (${unit})`

    const KPI_VALUE_0 =
      typeof total === 'string' ? total.toLocaleString(userLocales) : total
    const KPI_VALUE_1 =
      typeof min === 'string' ? min.toLocaleString(userLocales) : min
    const KPI_VALUE_2 =
      typeof max === 'string' ? max.toLocaleString(userLocales) : max
    const KPI_VALUE_3 =
      typeof average === 'string'
        ? average.toLocaleString(userLocales)
        : parseFloat(average.toFixed(2))

    excelData[0].data = [
      ...excelData[0].data,
      // Leerzeile
      [],
      // Zeile Medium, Zähler, Verbrauch , Minimum , Maximum , Durchschnitt
      [
        {
          style: { alignment, border: border('2112'), fill: fill(XLS_GRAY) },
          value: MEDIUM_HEADER,
        },
        {
          style: { alignment, border: border('2211'), fill: fill(XLS_GRAY) },
          value: NAME_HEADER,
        },
        '',
        {
          style: { alignment, border: border('2112'), fill: fill(XLS_GRAY) },
          value: KPI_HEADER_0,
        },
        {
          style: { alignment, border: border('2111'), fill: fill(XLS_GRAY) },
          value: KPI_HEADER_1,
        },
        {
          style: { alignment, border: border('2111'), fill: fill(XLS_GRAY) },
          value: KPI_HEADER_2,
        },
        {
          style: { alignment, border: border('2211'), fill: fill(XLS_GRAY) },
          value: KPI_HEADER_3,
        },
      ],
      // Zeile Strom|Gas|Wasser, Gesamtverbrauch
      [
        {
          style: { alignment, border: border('1122'), fill: fill(XLS_WHITE) },
          value: MEDIUM_VALUE,
        },
        {
          style: { alignment, border: border('1221'), fill: fill(XLS_WHITE) },
          value: NAME_VALUE,
        },
        '',
        {
          style: { alignment, border: border('1122'), fill: fill(XLS_WHITE) },
          value: KPI_VALUE_0,
        },
        {
          style: { alignment, border: border('1121'), fill: fill(XLS_WHITE) },
          value: KPI_VALUE_1,
        },
        {
          style: { alignment, border: border('1121'), fill: fill(XLS_WHITE) },
          value: KPI_VALUE_2,
        },
        {
          style: { alignment, border: border('1221'), fill: fill(XLS_WHITE) },
          value: KPI_VALUE_3,
        },
      ],
      // Leerzeile
      [],
      // Zeile Stammdaten
      [
        {
          style: { alignment, border: border('2112'), fill: fill(XLS_GRAY) },
          value: `Stammdaten`,
        },
        {
          style: { border: border('2211'), fill: fill(XLS_GRAY) },
          value: '',
        },
      ],
      // Zeile Zimmer/Mitarbeiter
      [
        {
          style: { alignment, border: border('1122'), fill: fill(XLS_WHITE) },
          value: `${roomsMode}`,
        },
        {
          style: { alignment, border: border('1221'), fill: fill(XLS_WHITE) },
          value: folderData?.number_of_employees || '-',
        },
      ],
    ]
  }

  // Einzel
  if (name !== 'Gesamtverbrauch') {
    const sensorByName = name =>
      folderData?.sensors?.find(sensor => sensor.name === name)
    const priceSensorId = sensorByName(name).entity_id
    const priceRecord = priceData.find(
      sensor => sensor.sensor_id === priceSensorId
    )

    const MEDIUM_COST_HEADER = `${sensorType(medium)}kosten / ${unit} (€)`
    const MEDIUM_COST_VALUE = priceRecord?.price
      ? parseNumber(priceRecord?.price.toLocaleString(userLocales))
      : '-'
    const FIXCOST_ANNUAL_HEADER = `Fixkosten / Jahr (€)`
    const FIXCOST_ANNUAL_VALUE = priceRecord?.price_for_year
      ? parseNumber(priceRecord?.price_for_year.toLocaleString(userLocales))
      : '-'

    // inject cost and fixcosts
    excelData[0].data = [
      ...excelData[0].data,
      [],
      [
        {
          style: { alignment, border: border('2112'), fill: fill(XLS_GRAY) },
          value: MEDIUM_COST_HEADER,
        },
        {
          style: { alignment, border: border('2211'), fill: fill(XLS_WHITE) },
          value: MEDIUM_COST_VALUE,
        },
      ],
      [
        {
          style: { alignment, border: border('1122'), fill: fill(XLS_GRAY) },
          value: FIXCOST_ANNUAL_HEADER,
        },
        {
          style: { alignment, border: border('1221'), fill: fill(XLS_WHITE) },
          value: FIXCOST_ANNUAL_VALUE,
        },
      ],
    ]
  }

  return excelData
}
