import { systemConstants as SC } from '../constants'
import { systemService as SS, tokenService as TS } from '../services'
import { alertActions as AA } from '.'

const getAllPayload = () => {
  const failure = error => ({ type: SC.GET_ALL_PAYLOAD_FAILURE, error })
  const request = () => ({ type: SC.GET_ALL_PAYLOAD_REQUEST })
  const success = allPayload => ({
    type: SC.GET_ALL_PAYLOAD_SUCCESS,
    allPayload,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() => {
      SS.getAllPayload()
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    })
  }
}

const getAppVersion = () => {
  const failure = error => ({ type: SC.GET_APP_VERSION_FAILURE, error })
  const request = () => ({ type: SC.GET_APP_VERSION_REQUEST })
  const success = appVersion => ({
    type: SC.GET_APP_VERSION_SUCCESS,
    appVersion,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() => {
      SS.getAppVersion()
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    })
  }
}

const getIsInMaintenance = () => {
  const failure = error => ({ type: SC.GET_IS_IN_MAINTENANCE_FAILURE, error })
  const request = () => ({ type: SC.GET_IS_IN_MAINTENANCE_REQUEST })
  const success = isInMaintenance => ({
    type: SC.GET_IS_IN_MAINTENANCE_SUCCESS,
    isInMaintenance,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() => {
      SS.getIsInMaintenance()
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    })
  }
}

const setIsInMaintenance = mode => {
  const failure = error => ({ type: SC.SET_IS_IN_MAINTENANCE_FAILURE, error })
  const request = () => ({ type: SC.SET_IS_IN_MAINTENANCE_REQUEST })
  const success = mode => ({
    type: SC.SET_IS_IN_MAINTENANCE_SUCCESS,
    mode,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() => {
      SS.setIsInMaintenance(mode)
        .then(result => {
          dispatch(success(result))
          dispatch(
            AA.success(
              `Wartungsmeldung ${['deaktiviert', 'aktiviert'][Number(mode)]}`
            )
          )
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    })
  }
}

const getMaintenanceText = () => {
  const failure = error => ({ type: SC.GET_MAINTENANCE_TEXT_FAILURE, error })
  const request = () => ({ type: SC.GET_MAINTENANCE_TEXT_REQUEST })
  const success = maintenanceText => ({
    type: SC.GET_MAINTENANCE_TEXT_SUCCESS,
    maintenanceText,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() => {
      SS.getMaintenanceText()
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    })
  }
}

const setMaintenanceText = message => {
  const failure = error => ({ type: SC.GET_MAINTENANCE_TEXT_FAILURE, error })
  const request = () => ({ type: SC.GET_MAINTENANCE_TEXT_REQUEST })
  const success = message => ({
    type: SC.GET_MAINTENANCE_TEXT_SUCCESS,
    message,
  })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() => {
      SS.setMaintenanceText(message)
        .then(result => {
          dispatch(success(result))
          dispatch(AA.success('Wartungsmeldung aktualisiert'))
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    })
  }
}

export const systemActions = {
  getAllPayload,
  getAppVersion,
  getIsInMaintenance,
  setIsInMaintenance,
  getMaintenanceText,
  setMaintenanceText,
}
