export const dashboardConstants = {
  DASHBOARD_DELETED: 'Dashboard gelöscht',
  DASHBOARD_ID_REQUIRED: 'ID erforderlich',
  DASHBOARD_NAME_REQUIRED: 'Name erforderlich',
  DASHBOARD_SAVED: 'Dashboard gespeichert',
  DASHBOARD_UPDATED: 'Dashboard aktualisiert',

  GET_ALL_DASHBOARDS_FAILURE: 'GET_ALL_DASHBOARDS_ERROR',
  GET_ALL_DASHBOARDS_REQUEST: 'GET_ALL_DASHBOARDS_REQUEST',
  GET_ALL_DASHBOARDS_SUCCESS: 'GET_ALL_DASHBOARDS_SUCCESS',

  GET_BY_ID_ERROR: 'DASHBOARD_GET_BY_ID_ERROR',
  GET_BY_ID_REQUEST: 'DASHBOARD_GET_BY_ID_REQUEST',
  GET_BY_ID_SUCCESS: 'DASHBOARD_GET_BY_ID_SUCCESS',

  REMOVE_DASHBOARD_ERROR: 'REMOVE_DASHBOARD_ERROR',
  REMOVE_DASHBOARD_REQUEST: 'REMOVE_DASHBOARD_REQUEST',
  REMOVE_DASHBOARD_SUCCESS: 'REMOVE_DASHBOARD_SUCCESS',

  SAVE_DASHBOARD_ERROR: 'SAVE_DASHBOARD_ERROR',
  SAVE_DASHBOARD_REQUEST: 'SAVE_DASHBOARD_REQUEST',
  SAVE_DASHBOARD_SUCCESS: 'SAVE_DASHBOARD_SUCCESS',

  UPDATE_DASHBOARD_ERROR: 'UPDATE_DASHBOARD_ERROR',
  UPDATE_DASHBOARD_REQUEST: 'UPDATE_DASHBOARD_REQUEST',
  UPDATE_DASHBOARD_SUCCESS: 'UPDATE_DASHBOARD_SUCCESS',

  PANEL_DELETED: 'Kachel gelöscht',
  PANEL_ID_REQUIRED: 'ID erforderlich',
  PANEL_NAME_REQUIRED: 'Kachel Name erforderlich',
  PANEL_SENSORS_REQUIRED: 'Kachel Sensoren erforderlich',
  PANEL_SAVED: 'Kachel gespeichert',
  PANEL_UPDATED: 'Kachel aktualisiert',

  GET_ALL_PANELS_FAILURE: 'GET_ALL_DASHBOARDS_PANEL_ERROR',
  GET_ALL_PANELS_REQUEST: 'GET_ALL_DASHBOARDS_PANEL_REQUEST',
  GET_ALL_PANELS_SUCCESS: 'GET_ALL_DASHBOARDS_PANEL_SUCCESS',

  GET_PANEL_BY_ID_ERROR: 'PANEL_GET_BY_ID_ERROR',
  GET_PANEL_BY_ID_REQUEST: 'PANEL_GET_BY_ID_REQUEST',
  GET_PANEL_BY_ID_SUCCESS: 'PANEL_GET_BY_ID_SUCCESS',

  REMOVE_PANEL_ERROR: 'REMOVE_PANEL_ERROR',
  REMOVE_PANEL_REQUEST: 'REMOVE_PANEL_REQUEST',
  REMOVE_PANEL_SUCCESS: 'REMOVE_PANEL_SUCCESS',

  MOVE_PANEL_ERROR: 'MOVE_PANEL_ERROR',
  MOVE_PANEL_REQUEST: 'MOVE_PANEL_REQUEST',
  MOVE_PANEL_SUCCESS: 'MOVE_PANEL_SUCCESS',

  PANEL_MOVED: 'Panel verschoben',

  SAVE_PANEL_ERROR: 'SAVE_PANEL_ERROR',
  SAVE_PANEL_REQUEST: 'SAVE_PANEL_REQUEST',
  SAVE_PANEL_SUCCESS: 'SAVE_PANEL_SUCCESS',

  UPDATE_PANEL_ERROR: 'UPDATE_PANEL_ERROR',
  UPDATE_PANEL_REQUEST: 'UPDATE_PANEL_REQUEST',
  UPDATE_PANEL_SUCCESS: 'UPDATE_PANEL_SUCCESS',

  DATA_POINT_DELETED: 'DATA_POINT gelöscht',
  DATA_POINT_ID_REQUIRED: 'ID erforderlich',
  DATA_POINT_NAME_REQUIRED: 'Name erforderlich',
  DATA_POINT_SAVED: 'DATA_POINT gespeichert',
  DATA_POINT_UPDATED: 'DATA_POINT aktualisiert',

  GET_ALL_DATA_POINT_FAILURE: 'GET_ALL_DASHBOARDS_ERROR',
  GET_ALL_DATA_POINT_REQUEST: 'GET_ALL_DASHBOARDS_REQUEST',
  GET_ALL_DATA_POINT_SUCCESS: 'GET_ALL_DASHBOARDS_SUCCESS',

  GET_DATA_POINT_BY_ID_ERROR: 'GET_BY_ID_ERROR',
  GET_DATA_POINT_BY_ID_REQUEST: 'GET_BY_ID_REQUEST',
  GET_DATA_POINT_BY_ID_SUCCESS: 'GET_BY_ID_SUCCESS',

  REMOVE_DATA_POINT_ERROR: 'REMOVE_ERROR',
  REMOVE_DATA_POINT_REQUEST: 'REMOVE_REQUEST',
  REMOVE_DATA_POINT_SUCCESS: 'REMOVE_SUCCESS',

  SAVE_DATA_POINT_ERROR: 'SAVE_ERROR',
  SAVE_DATA_POINT_REQUEST: 'SAVE_REQUEST',
  SAVE_DATA_POINT_SUCCESS: 'SAVE_SUCCESS',

  UPDATE_DATA_POINT_ERROR: 'UPDATE_ERROR',
  UPDATE_DATA_POINT_REQUEST: 'UPDATE_REQUEST',
  UPDATE_DATA_POINT_SUCCESS: 'UPDATE_SUCCESS',

  UPDATE_PANEL_BY_DASHBOARD_ID_ERROR: 'UPDATE_PANEL_BY_DASHBOARD_ID_ERROR',
  UPDATE_PANEL_BY_DASHBOARD_ID_REQUEST: 'UPDATE_PANEL_BY_DASHBOARD_ID_REQUEST',
  UPDATE_PANEL_BY_DASHBOARD_ID_SUCCESS: 'UPDATE_PANEL_BY_DASHBOARD_ID_SUCCESS',
}
