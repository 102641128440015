import { sensorConstants as SC } from '../constants'

const initialState = {
  allAttrGroup: { loading: true },
  allSensors: { loading: true },
  allUnlinkedSensors: { loading: true },
  newSensor: { loading: true },
  oneSensor: { loading: true },
  remove: { loading: true },
  timeSeries: { id: null, loading: true },
  calculations: { loading: true },
}

export const sensor = (state = initialState, action) => {
  const {
    allSensors,
    allUnlinkedSensors,
    attrGroup,
    calculations,
    error,
    id,
    sensor,
    timeSeries,
    type,
  } = action

  switch (type) {
    case SC.DELETE_SENSOR_ERROR:
      return { ...state, remove: { error, loading: true } }

    case SC.DELETE_SENSOR_REQUEST:
      return { ...state, remove: { loading: true } }

    case SC.DELETE_SENSOR_SUCCESS:
      return { ...state, remove: { loading: true } }

    case SC.GET_ALL_ERROR:
      return { ...state, allSensors: { error, loading: true } }

    case SC.GET_ALL_REQUEST:
      return { ...state, allSensors: { loading: true } }

    case SC.GET_ALL_SUCCESS:
      return {
        ...state,
        allSensors: {
          items: allSensors,
          loading: false,
          fetchDate: Date.now(),
        },
      }

    case SC.GET_ALL_UNLINKED_ERROR:
      return { ...state, allUnlinkedSensors: { error, loading: true } }

    case SC.GET_ALL_UNLINKED_REQUEST:
      return { ...state, allUnlinkedSensors: { loading: true } }

    case SC.GET_ALL_UNLINKED_SUCCESS:
      return {
        ...state,
        allUnlinkedSensors: { items: allUnlinkedSensors, loading: false },
      }

    case SC.GET_ATTRIBUTES_GROUP_FAILURE:
      return { ...state, allAttrGroup: { error, loading: true } }

    case SC.GET_ATTRIBUTES_GROUP_REQUEST:
      return { ...state, allAttrGroup: { loading: true } }

    case SC.GET_ATTRIBUTES_GROUP_SUCCESS:
      return { ...state, allAttrGroup: { items: attrGroup, loading: false } }

    case SC.GET_BY_ID_ERROR:
      return { ...state, oneSensor: { error, loading: true } }

    case SC.GET_BY_ID_REQUEST:
      return { ...state, oneSensor: { loading: true } }

    case SC.GET_BY_ID_SUCCESS:
      return { ...state, oneSensor: { item: sensor, loading: false } }

    case SC.GET_CALCULATIONS_ERROR:
      return { ...state, calculations: { error, loading: true } }

    case SC.GET_CALCULATIONS_REQUEST:
      return { ...state, calculations: { loading: true } }

    case SC.GET_CALCULATIONS_SUCCESS:
      return {
        ...state,
        calculations: { items: calculations, loading: false },
      }

    case SC.GET_TIME_SERIES_ERROR:
      return { ...state, timeSeries: { id: null, error, loading: true } }

    case SC.GET_TIME_SERIES_REQUEST:
      return { ...state, timeSeries: { id: null, loading: true } }

    case SC.GET_TIME_SERIES_SUCCESS:
      return {
        ...state,
        timeSeries: { id: id, items: timeSeries, loading: false },
      }

    case SC.SAVE_SENSOR_ERROR:
      return { ...state, newSensor: { error, loading: true } }

    case SC.SAVE_SENSOR_REQUEST:
      return { ...state, newSensor: { loading: true } }

    case SC.SAVE_SENSOR_SUCCESS:
      return { ...state, newSensor: { item: sensor, loading: false } }

    // set sensors by user id
    case SC.SENSOR_SET_USERID_BY_SENSORS_ERROR:
      return { ...state, sensorById: { error, loading: true } }

    case SC.SENSOR_SET_USERID_BY_SENSORS_REQUEST:
      return { ...state, sensorById: { loading: true } }

    case SC.SENSOR_SET_USERID_BY_SENSORS_SUCCESS:
      return { ...state, sensorById: { loading: false } }

    default:
      return state
  }
}
