// modules

import { Box, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
// scripts
import {
  FILTER_ADMIN_ROLE,
  FILTER_COACH_ROLE,
  FILTER_GUEST_ROLE,
  FILTER_INACTIVE_USER,
  FILTER_UNCONFIRMED_USER,
  FILTER_USER_ROLE,
} from '../../../api'
import { HStack, ToggleDateType } from '../../../components'
import { userConstants as UC } from '../../../constants'
import { useUserPool } from '../../../hooks'

export const UserPoolStatistics = () => {
  const { data, fetchDate } = useUserPool()

  const StatRecord = ({ label, value, ...rest }) => {
    return (
      <HStack spacing={2} sx={{ width: 1 }} {...rest}>
        <Box sx={{ flex: 1, textAlign: 'right' }}>
          <Typography>{label}</Typography>
        </Box>

        <Box sx={{ flex: 1, textAlign: 'left' }}>
          <Typography>{value}</Typography>
        </Box>
      </HStack>
    )
  }

  StatRecord.propTypes = {
    label: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  }

  return (
    <Stack sx={{ mt: 3 }}>
      <StatRecord label={'Poolgröße'} value={data.length} />
      <StatRecord
        label={<strong>Rollen</strong>}
        sx={{ my: 1, pr: 1, textAlign: 'right' }}
        value={''}
      />
      <StatRecord
        label={UC.USER_ROLE_USER}
        value={data.filter(FILTER_USER_ROLE).length}
      />
      <StatRecord
        label={UC.USER_ROLE_GUEST}
        value={data.filter(FILTER_GUEST_ROLE).length}
      />
      <StatRecord
        label={UC.USER_ROLE_COACH}
        value={data.filter(FILTER_COACH_ROLE).length}
      />
      <StatRecord
        label={UC.USER_ROLE_ADMIN}
        value={data.filter(FILTER_ADMIN_ROLE).length}
      />
      <StatRecord
        label={<strong>Inaktiv</strong>}
        sx={{ my: 1, pr: 1, textAlign: 'right' }}
        value={''}
      />
      <StatRecord
        label={UC.USER_STATUS_INACTIVE}
        value={data.filter(FILTER_INACTIVE_USER).length}
      />
      <StatRecord
        label={UC.USER_STATUS_NOT_CONFIRMED}
        value={data.filter(FILTER_UNCONFIRMED_USER).length}
      />
      <StatRecord
        label={'gefetcht'}
        sx={{ mb: 1, mt: 2, pr: 1, textAlign: 'right' }}
        value={<ToggleDateType date={fetchDate} />}
      />
    </Stack>
  )
}
