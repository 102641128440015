import { history } from '../api'
import { sensorFactorConstants as SFC } from '../constants'
import { sensorFactorService as SFS, tokenService as TS } from '../services'
import { alertActions as AA } from '.'

const remove = id => {
  const failure = error => ({ type: SFC.DELETE_SENSOR_FACTOR_ERROR, error })
  const request = () => ({ type: SFC.DELETE_SENSOR_FACTOR_REQUEST })
  const success = sensorfactor => ({
    type: SFC.DELETE_SENSOR_FACTOR_SUCCESS,
    sensorfactor,
  })

  return dispatch => {
    if (!id) return dispatch(AA.error(SFC.SENSORFACTOR_ID_REQUIRED))

    dispatch(request())

    SFS.deleteSensorFactor(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(SFC.SENSORFACTOR_DELETED))

        history.push('/system/custom-notifications')
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const deleteSensorFactor = (id, sensorId) => {
  const failure = error => ({ type: SFC.DELETE_SENSOR_FACTOR_ERROR, error })
  const request = () => ({ type: SFC.DELETE_SENSOR_FACTOR_REQUEST })
  const success = sensorfactor => ({
    type: SFC.DELETE_SENSOR_FACTOR_SUCCESS,
    sensorfactor,
  })

  return dispatch => {
    if (!id) return dispatch(AA.error(SFC.SENSORFACTOR_ID_REQUIRED))

    dispatch(request())

    SFS.deleteSensorFactor(id)
      .then(result => {
        dispatch(success(result))
        dispatch(AA.success(SFC.SENSORFACTOR_ID_REQUIRED))
        history.go(`/sensors/${sensorId}`)
      })
      .catch(error => {
        const { message } = error

        dispatch(failure(message))
        dispatch(AA.error(message))
      })
  }
}

const getAll = sensorId => {
  const failure = error => ({ type: SFC.GET_ALL_ERROR, error })
  const request = () => ({ type: SFC.GET_ALL_REQUEST })
  const success = sensorfactor => ({ type: SFC.GET_ALL_SUCCESS, sensorfactor })

  return dispatch => {
    dispatch(request())

    TS.refreshToken().then(() => {
      SFS.getAll(sensorId)
        .then(result => dispatch(success(result)))
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    })
  }
}

const recordData = data => {
  const {
    entity_id,
    sensor_id,
    period_start,
    period_end,
    basic_charge_per_year,
    price_per_unit,
    pulse_value,
    caloric_value,
    conversion_factor,
    transformer_ratio,
    co2_factor,
    number_of_employees,
    area_in_sqm,
  } = data

  const convertToNullIfEmpty = value => {
    if (value === null) return null
    return value.toString().trim().length === 0 ? null : parseFloat(value)
  }
  return {
    data: {
      id: entity_id,
      type: 'sensor_factor',
      attributes: {
        sensor_id: parseFloat(sensor_id),
        period_start: period_start,
        period_end: period_end,
        basic_charge_per_year: convertToNullIfEmpty(basic_charge_per_year),
        price_per_unit: convertToNullIfEmpty(price_per_unit),
        pulse_value: parseFloat(pulse_value),
        caloric_value: parseFloat(caloric_value),
        conversion_factor: parseFloat(conversion_factor),
        transformer_ratio: parseFloat(transformer_ratio),
        co2_factor: convertToNullIfEmpty(co2_factor),
        number_of_employees: convertToNullIfEmpty(number_of_employees),
        area_in_sqm: convertToNullIfEmpty(area_in_sqm),
      },
    },
  }
}

const save = data => {
  const failure = error => ({ type: SFC.SAVE_SENSOR_FACTOR_ERROR, error })
  const request = () => ({ type: SFC.SAVE_SENSOR_FACTOR_REQUEST })
  const success = sensorfactor => ({
    type: SFC.SAVE_SENSOR_FACTOR_SUCCESS,
    sensorfactor,
  })

  const saveSensorFactorData = data => {
    return dispatch => {
      dispatch(request())

      SFS.save(recordData(data))
        .then(result => {
          dispatch(success(result))
          dispatch(AA.success(SFC.SENSORFACTOR_SAVED))
          history.go(`/sensors/${data.sensor_id}`)
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    }
  }

  const { sensor_id } = data

  return dispatch => {
    if (!sensor_id) return dispatch(AA.error(SFC.SENSORFACTOR_SENSOR_REQUIRED))

    dispatch(saveSensorFactorData(data))
  }
}

const update = (data, sensorFactorId) => {
  const failure = error => ({ type: SFC.UPDATE_SENSOR_FACTOR_ERROR, error })
  const request = () => ({ type: SFC.UPDATE_SENSOR_FACTOR_REQUEST })
  const success = sensorfactor => ({
    type: SFC.UPDATE_SENSOR_FACTOR_SUCCESS,
    sensorfactor,
  })

  const updateData = data => {
    return dispatch => {
      dispatch(request())

      SFS.update(recordData(data), sensorFactorId)
        .then(result => {
          dispatch(success(result))
          dispatch(AA.success(SFC.SENSORFACTOR_UPDATED))
          history.go(`/sensors/${data.sensor_id}`)
        })
        .catch(error => {
          const { message } = error

          dispatch(failure(message))
          dispatch(AA.error(message))
        })
    }
  }

  const { sensor_id } = data

  return dispatch => {
    if (!sensor_id) return dispatch(AA.error(SFC.SENSORFACTOR_SENSOR_REQUIRED))

    dispatch(updateData(data))
  }
}

export const sensorFactorActions = {
  remove,
  deleteSensorFactor,
  getAll,
  save,
  update,
}
