import { NotificationsTwoTone } from '@mui/icons-material'
import { IconButton, Tooltip, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useAppConfig } from '../../hooks'

export const BtnUpdate = ({ title = 'Aktualisierung verfügbar' }) => {
  const { setGlobal: setAppConfig } = useAppConfig()
  const onClick = () =>
    setAppConfig(prev => ({ ...prev, showSettings: true, setupTab: 2 }))
  const theme = useTheme()
  const color = theme.palette.error.main

  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick}>
        <NotificationsTwoTone
          sx={{
            animation: 'pulse 1.5s infinite',
            '@keyframes pulse': {
              '0%': { transform: 'scale(1)' },
              '50%': { transform: 'scale(1.2)' },
              '100%': { transform: 'scale(1)' },
            },
            color,
          }}
        />
      </IconButton>
    </Tooltip>
  )
}

BtnUpdate.propTypes = {
  title: PropTypes.string,
}
