import { contractConstants as CC } from '../constants'

const initialState = {
  allContracts: { loading: true },
  oneContract: { loading: true },
  newContract: { loading: true },
}

export const contract = (state = initialState, action) => {
  const { contract, contracts, error, type } = action

  switch (type) {
    case CC.GET_ALL_CONTRACTS_FAILURE:
      return { ...state, allContracts: { error, loading: true } }

    case CC.GET_ALL_CONTRACTS_REQUEST:
      return { ...state, allContracts: { loading: true } }

    case CC.GET_ALL_CONTRACTS_SUCCESS:
      return { ...state, allContracts: { items: contracts, loading: false } }

    case CC.GET_BY_ID_ERROR:
      return { ...state, oneContract: { error, loading: true } }

    case CC.GET_BY_ID_REQUEST:
      return { ...state, oneContract: { loading: true } }

    case CC.GET_BY_ID_SUCCESS:
      return { ...state, oneContract: { item: contract, loading: false } }

    case CC.REMOVE_CONTRACT_ERROR:
      return { ...state, newContract: { error, loading: true } }

    case CC.REMOVE_CONTRACT_REQUEST:
      return { ...state, newContract: { loading: true } }

    case CC.REMOVE_CONTRACT_SUCCESS:
      return { ...state, newContract: { loading: true } }

    case CC.SAVE_CONTRACT_ERROR:
      return { ...state, newContract: { error, loading: true } }

    case CC.SAVE_CONTRACT_REQUEST:
      return { ...state, newContract: { loading: true } }

    case CC.SAVE_CONTRACT_SUCCESS:
      return { ...state, newContract: { item: contract, loading: false } }

    case CC.UPDATE_CONTRACT_ERROR:
      return { ...state, newContract: { error, loading: true } }

    case CC.UPDATE_CONTRACT_REQUEST:
      return { ...state, newContract: { loading: true } }

    case CC.UPDATE_CONTRACT_SUCCESS:
      return { ...state, newContract: { loading: true } }

    default:
      return state
  }
}
