// modules
import { Collapse, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
// scripts
import { categoryByType, SORT } from '../../api'
import { Preloader } from '../../components'
import { useFolder, useSensor, useUserPool } from '../../hooks'
import { FeelingRoom, PackageUpgrade } from './components'

export const Feeling = ({ folderId }) => {
  const [bridgeSensor, setBridgeSensor] = useState(null)
  const [mediumSensors, setMediumSensors] = useState([])
  const [coachData, setCoachData] = useState({})
  const { data: bridgeData } = useSensor(bridgeSensor)
  const { data: folderData, isError, isLoading } = useFolder(folderId)
  const { data: userPool } = useUserPool()

  useEffect(
    () => {
      if (folderData) {
        const { sensors } = folderData

        if (sensors && sensors[0]) {
          const { entity_id } = sensors[0]

          if (entity_id) setBridgeSensor(entity_id)

          const FILTER_CURRENT_FOLDER = f =>
            Number(f.folder_id) === Number(folderId)

          const FILTER_FEELING = f =>
            (categoryByType(f.sensor_type) === 'feeling' &&
              f.sensor_target !== 'security') ||
            f.sensor_target === 'feeling'

          setMediumSensors(
            sensors.filter(FILTER_CURRENT_FOLDER).filter(FILTER_FEELING)
          )
        }
      }
    },
    [folderData, folderId]
  )

  useEffect(
    () => {
      if (bridgeData) {
        const { contracts } = bridgeData

        if (contracts && contracts[0]) {
          const { primary_coach_id } = contracts[0]

          const FILTER_COACH = f => Number(f.id) === primary_coach_id

          if (primary_coach_id) setCoachData(userPool.find(FILTER_COACH))
        }
      }
    },
    [bridgeData, userPool]
  )

  return isLoading ? (
    <Preloader error={isError} isLoading={isLoading} />
  ) : (
    <Collapse in={!isLoading}>
      {bridgeSensor &&
        mediumSensors.length === 0 &&
        coachData && <PackageUpgrade coach={coachData} />}

      <Grid container spacing={5}>
        {[...new Set(mediumSensors.map(i => i.name))]
          .sort(SORT)
          .map((item, key) => (
            <Grid item xs={12} md={6} lg={4} key={key}>
              <FeelingRoom
                folderId={Number(folderId)}
                title={item}
                sensors={mediumSensors}
              />
            </Grid>
          ))}
      </Grid>
    </Collapse>
  )
}

Feeling.propTypes = {
  folderId: PropTypes.string,
}
