import {
  ArrowDropDownTwoTone,
  ArrowDropUpTwoTone,
  FilterListTwoTone,
  InfoTwoTone,
  Star,
} from '@mui/icons-material'
import {
  Box,
  Collapse,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ASC, DESC, userLocales, DDMMYYYY } from '../../../../api'
import { useOperator } from '../../../../hooks'
import { ChildSensorSelector } from './ChildSensorSelector'

const ToggleOrder = ({ onChange, order: initialOrder, width = 45 }) => {
  const [order, setOrder] = useState(initialOrder)
  const toggle = () => setOrder(prev => (prev === ASC ? DESC : ASC))

  const icon = order === ASC ? <ArrowDropUpTwoTone /> : <ArrowDropDownTwoTone />
  const tooltip = order === ASC ? 'A-Z' : 'Z-A'

  useEffect(() => onChange(order), [onChange, order])

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={toggle} sx={{ m: 0, width }}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}

ToggleOrder.propTypes = {
  onChange: PropTypes.func.isRequired,
  order: PropTypes.oneOf([ASC, DESC]).isRequired,
  width: PropTypes.number,
}

const ToggleSelectedOnly = ({ onChange, value = false, width = 45 }) => {
  const theme = useTheme()
  const { main: color } = theme.palette.secondary

  const [selectedOnly, setSelectedOnly] = useState(value)
  const toggle = () => setSelectedOnly(prev => !prev)

  const icon =
    selectedOnly === true ? (
      <FilterListTwoTone sx={{ color }} />
    ) : (
      <FilterListTwoTone />
    )

  const tooltip =
    selectedOnly === true ? 'Nur ausgewählte Sensoren' : 'Alle Sensoren'

  useEffect(() => onChange(selectedOnly), [onChange, selectedOnly])

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={toggle} sx={{ m: 0, width }}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}

ToggleSelectedOnly.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  width: PropTypes.number,
}

const ToggleShowSensorInfo = ({ onChange }) => {
  const theme = useTheme()
  const { main: color } = theme.palette.secondary

  const [showSensorInfo, setShowSensorInfo] = useState(false)
  const toggle = () => setShowSensorInfo(prev => !prev)

  const icon =
    showSensorInfo === true ? <InfoTwoTone sx={{ color }} /> : <InfoTwoTone />

  const tooltip =
    showSensorInfo === true
      ? 'Informationen zum Messpunkt ausblenden'
      : 'Informationen zum Messpunkt einblenden'

  useEffect(() => onChange(showSensorInfo), [onChange, showSensorInfo])

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={toggle}>{icon}</IconButton>
    </Tooltip>
  )
}

ToggleShowSensorInfo.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export const SensorSelector = ({
  border = 'solid 1px #DDD',
  data,
  height = 645,
  onChange,
  onOrderChange,
  medium,
  selected: initialSelected,
  toolbarColor = 'white',
  width = 300,
  order: initialOrder,
  exportModal,
  fullWidth,
}) => {
  const theme = useTheme()
  const { white: bgcolor } = theme.palette

  const { data: operatorData } = useOperator()

  const [order, setOrder] = useState(initialOrder)
  const [search, setSearch] = useState('')
  const [selectedOnly, setSelectedOnly] = useState(false)
  const [selected, setSelected] = useState(initialSelected)
  const [showSensorInfo, setShowSensorInfo] = useState(false)
  const [openIds, setOpenIds] = useState([])

  const handleOpenIDs = id => {
    setOpenIds(
      prevOpenIds =>
        prevOpenIds.includes(id)
          ? prevOpenIds.filter(openId => openId !== id)
          : [...prevOpenIds, id]
    )
  }
  const FILTER_SEARCH = f =>
    f.name.toLowerCase().includes(search.toLowerCase()) ||
    (f.meter_number &&
      f.meter_number.toLowerCase().includes(search.toLowerCase()))

  const FILTER_SELECTED_ONLY = f =>
    selectedOnly === true || exportModal === true
      ? selected.includes(f.entity_id)
      : f

  const FILTER_NOT_SUCCESSOR_SENSOR = f => f.successor_id === null

  const FILTER_SELECTED_SUCCESSOR_SENSOR = f => {
    const sensor = data.find(sensor => sensor.entity_id === f)
    return sensor ? sensor.successor_id === null : false
  }

  const SORT_HANDLER = (a, b) => {
    const result = a.name.localeCompare(b.name)

    return order === ASC ? result : -result
  }

  const handleKeyPress = event => {
    // deselect all // 109 = NumPad-
    if (event.keyCode === 109) setSelected([])

    // select all; 107 = NumPad+
    if (event.keyCode === 107)
      setSelected(data.sort(SORT_HANDLER).map(item => item.entity_id))
  }

  const getAllWithSuccessorId = entity_id => {
    return data
      .filter(FILTER_SELECTED_ONLY)
      .filter(FILTER_SEARCH)
      .filter(data => data.successor_id === entity_id)
  }
  const getAllWitParentId = entity_id => {
    return data
      .filter(FILTER_SELECTED_ONLY)
      .filter(FILTER_SEARCH)
      .filter(data => data.parent_id === entity_id)
  }

  const getPredessorData = data
    ? data.filter(data => data.successor_id !== null)
    : []

  document.onkeydown = handleKeyPress

  useEffect(() => onOrderChange(order), [onOrderChange, order])
  useEffect(() => onChange(selected), [onChange, order, selected])
  useEffect(() => setSelected([]), [medium])
  useEffect(() => setSelected(selected), [selected])

  const operatorHasParentSensor = data.some(entry => entry.parent_id === null)

  return (
    <>
      <Stack
        direction={'row'}
        sx={{
          background: toolbarColor,
          borderBottom: border,
          borderLeft: border,
        }}
      >
        <Typography
          sx={{
            borderRight: border,
            fontSize: '0.75rem',
            height: 42,
            lineHeight: 1,
            px: 2,
            py: 1,
          }}
        >
          Messpunkte ({selected.filter(FILTER_SELECTED_SUCCESSOR_SENSOR).length}
          /{data.filter(FILTER_NOT_SUCCESSOR_SENSOR).length})
        </Typography>

        <Input
          onChange={event => setSearch(event.target.value)}
          placeholder={'Suchen...'}
          sx={{ m: 0, minHeight: 23, pl: 1 }}
          value={search}
          variant={'standard'}
        />

        <ToggleOrder order={order} onChange={order => setOrder(order)} />
        <ToggleSelectedOnly onChange={only => setSelectedOnly(only)} />
        <ToggleShowSensorInfo
          onChange={showSensorInfo => setShowSensorInfo(showSensorInfo)}
        />
      </Stack>
      <Stack>
        <List
          height={height}
          sx={{
            bgcolor,
            borderLeft: 'solid 1px #D3D3D3',
            maxHeight: height,
            minHeight: fullWidth ? 1 : height,
            overflow: 'auto',
            pt: 0,
          }}
          width={width}
        >
          {data.sort(SORT_HANDLER).map((sensor, key) => {
            const {
              chartColor,
              entity_id,
              name,
              meter_number,
              data_sampling_date,
              parent_id,
              sampling_mode,
            } = sensor
            const date = new Date(data_sampling_date).toLocaleDateString(
              userLocales,
              DDMMYYYY
            )

            const filteredArray = getPredessorData.filter(
              item => item.entity_id === entity_id
            )

            if (filteredArray.length > 0) return null

            const successorData = getAllWithSuccessorId(entity_id)
            const childSensorData = getAllWitParentId(entity_id)
            const sensorInfo = (
              <Box>
                <Box>Zählernummer: {meter_number ?? 'n/a'}</Box>
                <Box>Datenbeginn: {date}</Box>
                <Box>
                  Messverfahren:{' '}
                  {sampling_mode === 1
                    ? 'Zählerstand'
                    : sampling_mode === 2
                      ? 'Verbrauch'
                      : '-'}
                </Box>
                {operatorData?.isAdmin && <Box>Sensor ID: {entity_id}</Box>}
              </Box>
            )

            const isSelected = selected.includes(entity_id)

            const toggle = entity_id => {
              const newValue = isSelected
                ? selected.filter(f => f !== entity_id)
                : [...selected, entity_id]

              setSelected(
                data
                  .sort(SORT_HANDLER)
                  .map(sensor => sensor.entity_id)
                  .filter(f => newValue.includes(f))
              )
            }

            if (
              (parent_id !== null && operatorHasParentSensor) ||
              (selectedOnly &&
                !isSelected &&
                childSensorData.length === 0 &&
                successorData.length === 0)
            )
              return null

            if (
              name.toLowerCase().includes(search.toLowerCase()) ||
              ((meter_number &&
                meter_number.toLowerCase().includes(search.toLowerCase())) ||
                childSensorData.length > 0 ||
                successorData.length > 0)
            )
              return (
                <ListItem
                  key={key}
                  sx={{
                    p: 0,
                    width: fullWidth ? '25%' : 'auto',
                    display: fullWidth ? 'inline-block' : 'block',
                  }}
                >
                  <ListItemButton
                    onClick={() => toggle(entity_id, successorData)}
                    selected={isSelected}
                  >
                    <ListItemIcon sx={{ color: chartColor }}>
                      <Star />
                    </ListItemIcon>
                    <ListItemText
                      primary={name}
                      secondary={showSensorInfo ? sensorInfo : null}
                    />
                    {successorData.length > 0 &&
                      (openIds.includes(key) ? (
                        <ArrowDropUpTwoTone
                          onClick={event => {
                            event.stopPropagation()
                            handleOpenIDs(key)
                          }}
                        />
                      ) : (
                        <ArrowDropDownTwoTone
                          onClick={event => {
                            event.stopPropagation()
                            handleOpenIDs(key)
                          }}
                        />
                      ))}
                  </ListItemButton>
                  {successorData.length > 0 ? (
                    <Collapse
                      in={openIds.includes(key)}
                      timeout={'auto'}
                      unmountOnExit
                    >
                      {successorData.map((successorSensor, successorKey) => {
                        const successorDate = new Date(
                          successorSensor.data_sampling_date
                        ).toLocaleDateString(userLocales, DDMMYYYY)
                        const successorSensorInfo = (
                          <Box>
                            <Box>
                              Zählernummer:{' '}
                              {successorSensor.meter_number
                                ? successorSensor.meter_number
                                : 'n/a'}
                            </Box>
                            <Box>Datenbeginn: {successorDate}</Box>
                            <Box>
                              Messverfahren{' '}
                              {successorSensor.sampling_mode === 1
                                ? 'Zählerstand'
                                : successorSensor.sampling_mode === 2
                                  ? 'Verbrauch'
                                  : '-'}
                            </Box>

                            {operatorData?.isAdmin && (
                              <Box>Sensor ID: {successorSensor.entity_id}</Box>
                            )}
                          </Box>
                        )
                        const isSuccessorSelected = selected.includes(
                          successorSensor.entity_id
                        )
                        const successorToggle = entity_id => {
                          const newValue = isSuccessorSelected
                            ? selected.filter(f => f !== entity_id)
                            : [...selected, entity_id]

                          setSelected(
                            data
                              .sort(SORT_HANDLER)
                              .map(sensor => sensor.entity_id)
                              .filter(f => newValue.includes(f))
                          )
                        }
                        const childSuccessorSensorData = getAllWitParentId(
                          successorSensor.entity_id
                        )
                        return (
                          <>
                            <List
                              component={'div'}
                              disablePadding
                              key={successorKey}
                              onClick={() =>
                                successorToggle(successorSensor.entity_id)
                              }
                            >
                              <ListItemButton selected={isSuccessorSelected}>
                                <ListItemIcon
                                  sx={{ color: successorSensor.chartColor }}
                                >
                                  <Star />
                                </ListItemIcon>

                                <ListItemText
                                  primary={successorSensor.name}
                                  secondary={
                                    showSensorInfo ? successorSensorInfo : null
                                  }
                                  primaryTypographyProps={{
                                    fontSize: '0.9rem',
                                  }}
                                />
                              </ListItemButton>
                            </List>
                            <ChildSensorSelector
                              childSensorData={childSuccessorSensorData}
                              userLocales={userLocales}
                              DDMMYYYY={DDMMYYYY}
                              isAdmin={operatorData?.isAdmin}
                              selected={selected}
                              setSelected={setSelected}
                              showSensorInfo={showSensorInfo}
                              data={data}
                              SORT_HANDLER={SORT_HANDLER}
                              successorChild={true}
                            />
                          </>
                        )
                      })}
                    </Collapse>
                  ) : (
                    <> </>
                  )}
                  {childSensorData.length > 0 ? (
                    <ChildSensorSelector
                      childSensorData={childSensorData}
                      userLocales={userLocales}
                      DDMMYYYY={DDMMYYYY}
                      isAdmin={operatorData?.isAdmin}
                      selected={selected}
                      setSelected={setSelected}
                      showSensorInfo={showSensorInfo}
                      data={data}
                      SORT_HANDLER={SORT_HANDLER}
                      successorChild={false}
                    />
                  ) : (
                    <> </>
                  )}
                </ListItem>
              )

            return null
          })}
        </List>
      </Stack>
    </>
  )
}

SensorSelector.propTypes = {
  border: PropTypes.string,
  data: PropTypes.array.isRequired,
  height: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
  medium: PropTypes.string,
  order: PropTypes.oneOf([ASC, DESC]).isRequired,
  selected: PropTypes.array.isRequired,
  toolbarColor: PropTypes.string,
  width: PropTypes.number,
  exportModal: PropTypes.bool,
  fullWidth: PropTypes.bool,
}
