import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardActions } from '../actions'

const dashboardContext = createContext({})

export const ProvideDashboardPool = ({ children }) => {
  const value = useDashboards()

  return (
    <dashboardContext.Provider value={value}>
      {children}
    </dashboardContext.Provider>
  )
}
ProvideDashboardPool.propTypes = { children: PropTypes.any }

export const useDashboardPool = () => useContext(dashboardContext)

const useDashboards = () => {
  const dispatch = useDispatch()
  const { allDashboards } = useSelector(state => state.dashboard)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(
    () => {
      dispatch(dashboardActions.getAll())
      setLoading(true)
    },
    [dispatch]
  )

  useEffect(
    () => {
      const { error, fetchDate, items } = allDashboards
      if (items) {
        const { data } = items

        if (data) {
          setData(data)
          setLoading(false)
          setFetchDate(fetchDate)
        } else {
          setData([])
          setLoading(false)
        }
      }

      if (error) {
        setError(error)
        setLoading(false)
        setFetchDate(fetchDate)
      }
    },
    [allDashboards]
  )

  return { data, isError, isLoading, fetchDate }
}
