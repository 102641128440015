import CATEGORY_FEELING_ALERT from '../assets/img/categories/feeling/alert.svg'
import CATEGORY_FEELING_GOOD from '../assets/img/categories/feeling/good.svg'
import CATEGORY_FEELING_INACTIVE from '../assets/img/categories/feeling/inactive.svg'
import CATEGORY_FEELING_WARNING from '../assets/img/categories/feeling/warning.svg'

import CATEGORY_PERFORMANCE_ALERT from '../assets/img/categories/performance/alert.svg'
import CATEGORY_PERFORMANCE_GOOD from '../assets/img/categories/performance/good.svg'
import CATEGORY_PERFORMANCE_INACTIVE from '../assets/img/categories/performance/inactive.svg'
import CATEGORY_PERFORMANCE_WARNING from '../assets/img/categories/performance/warning.svg'

import CATEGORY_SECURITY_ALERT from '../assets/img/categories/security/alert.svg'
import CATEGORY_SECURITY_GOOD from '../assets/img/categories/security/good.svg'
import CATEGORY_SECURITY_INACTIVE from '../assets/img/categories/security/inactive.svg'
import CATEGORY_SECURITY_WARNING from '../assets/img/categories/security/warning.svg'

import CO2_ALERT from '../assets/img/sensor_types/co2/alert.svg'
import CO2_GOOD from '../assets/img/sensor_types/co2/good.svg'
import CO2_INACTIVE from '../assets/img/sensor_types/co2/inactive.svg'
import CO2_WARNING from '../assets/img/sensor_types/co2/warning.svg'

import CONSUMPTION_ALERT from '../assets/img/consumption/alert.svg'
import CONSUMPTION_GOOD from '../assets/img/consumption/good.svg'
import CONSUMPTION_INACTIVE from '../assets/img/consumption/inactive.svg'
import CONSUMPTION_WARNING from '../assets/img/consumption/warning.svg'

import CORRELATION_ALERT from '../assets/img/categories/correlation/alert.svg'
import CORRELATION_GOOD from '../assets/img/categories/correlation/blue_ring.svg'
import CORRELATION_INACTIVE from '../assets/img/categories/correlation/inactive.svg'
import CORRELATION_WARNING from '../assets/img/categories/correlation/warning.svg'

import DOOR_CLOSED_ALERT from '../assets/img/sensor_types/door/closed/alert.svg'
import DOOR_CLOSED_GOOD from '../assets/img/sensor_types/door/closed/good.svg'
import DOOR_CLOSED_INACTIVE from '../assets/img/sensor_types/door/closed/inactive.svg'
import DOOR_CLOSED_WARNING from '../assets/img/sensor_types/door/closed/warning.svg'

import DOOR_LOCKED_ALERT from '../assets/img/sensor_types/door/locked/alert.svg'
import DOOR_LOCKED_GOOD from '../assets/img/sensor_types/door/locked/good.svg'
import DOOR_LOCKED_INACTIVE from '../assets/img/sensor_types/door/locked/inactive.svg'
import DOOR_LOCKED_WARNING from '../assets/img/sensor_types/door/locked/warning.svg'

import DOOR_OPEN_ALERT from '../assets/img/sensor_types/door/open/alert.svg'
import DOOR_OPEN_GOOD from '../assets/img/sensor_types/door/open/good.svg'
import DOOR_OPEN_INACTIVE from '../assets/img/sensor_types/door/open/inactive.svg'
import DOOR_OPEN_WARNING from '../assets/img/sensor_types/door/open/warning.svg'

import ELEVATOR_ALERT from '../assets/img/sensor_types/elevator/alert.svg'
import ELEVATOR_GOOD from '../assets/img/sensor_types/elevator/good.svg'
import ELEVATOR_INACTIVE from '../assets/img/sensor_types/elevator/inactive.svg'

import FEELING_ALERT from '../assets/img/categories/feeling/alert.svg'
import FEELING_GOOD from '../assets/img/categories/feeling/good.svg'
import FEELING_INACTIVE from '../assets/img/categories/feeling/inactive.svg'
import FEELING_WARNING from '../assets/img/categories/feeling/warning.svg'

import HUMIDITY_ALERT from '../assets/img/sensor_types/humidity/alert.svg'
import HUMIDITY_GOOD from '../assets/img/sensor_types/humidity/good.svg'
import HUMIDITY_INACTIVE from '../assets/img/sensor_types/humidity/inactive.svg'
import HUMIDITY_WARNING from '../assets/img/sensor_types/humidity/warning.svg'

import KARMA_LOGO from '../assets/img/karma-logo.svg'

import LIGHT_ALERT from '../assets/img/sensor_types/light/alert.svg'
import LIGHT_GOOD from '../assets/img/sensor_types/light/good.svg'
import LIGHT_INACTIVE from '../assets/img/sensor_types/light/inactive.svg'
import LIGHT_WARNING from '../assets/img/sensor_types/light/warning.svg'

import LIFTING_STATION_ALERT from '../assets/img/sensor_types/lifting_station/alert.svg'
import LIFTING_STATION_GOOD from '../assets/img/sensor_types/lifting_station/good.svg'
import LIFTING_STATION_INACTIVE from '../assets/img/sensor_types/lifting_station/inactive.svg'
import LIFTING_STATION_WARNING from '../assets/img/sensor_types/lifting_station/warning.svg'

import MEDIUM_ENERGY from '../assets/img/energy.svg'
import MEDIUM_HEATING from '../assets/img/heating.svg'
import MEDIUM_WATER from '../assets/img/water.svg'

import PERFORMANCE_ALERT from '../assets/img/categories/performance/alert.svg'
import PERFORMANCE_GOOD from '../assets/img/categories/performance/good.svg'
import PERFORMANCE_INACTIVE from '../assets/img/categories/performance/inactive.svg'
import PERFORMANCE_WARNING from '../assets/img/categories/performance/warning.svg'

import SECURITY_ALERT from '../assets/img/categories/security/alert.svg'
import SECURITY_GOOD from '../assets/img/categories/security/good.svg'
import SECURITY_INACTIVE from '../assets/img/categories/security/inactive.svg'
import SECURITY_WARNING from '../assets/img/categories/security/warning.svg'

import TEMPERATURE_ALERT from '../assets/img/sensor_types/temperature/alert.svg'
import TEMPERATURE_GOOD from '../assets/img/sensor_types/temperature/good.svg'
import TEMPERATURE_INACTIVE from '../assets/img/sensor_types/temperature/inactive.svg'
import TEMPERATURE_WARNING from '../assets/img/sensor_types/temperature/warning.svg'

import UNKNOWN_SENSOR from '../assets/img/unknown.svg'

import WATER_DETECTOR_ALERT from '../assets/img/sensor_types/water_detector/alert.svg'
import WATER_DETECTOR_GOOD from '../assets/img/sensor_types/water_detector/good.svg'
import WATER_DETECTOR_INACTIVE from '../assets/img/sensor_types/water_detector/inactive.svg'
import WATER_DETECTOR_WARNING from '../assets/img/sensor_types/water_detector/warning.svg'

import INSTALLATION_ALERT from '../assets/img/categories/installation/alert.svg'
import INSTALLATION_GOOD from '../assets/img/categories/installation/good.svg'
import INSTALLATION_INACTIVE from '../assets/img/categories/installation/inactive.svg'
import INSTALLATION_WARNING from '../assets/img/categories/installation/warning.svg'

import SMOKE_DETECTOR_ALERT from '../assets/img/sensor_types/smoke_detector/alert.svg'
import SMOKE_DETECTOR_GOOD from '../assets/img/sensor_types/smoke_detector/good.svg'
import SMOKE_DETECTOR_INACTIVE from '../assets/img/sensor_types/smoke_detector/inactive.svg'
import SMOKE_DETECTOR_WARNING from '../assets/img/sensor_types/smoke_detector/warning.svg'

export const svgConstants = {
  CATEGORY_FEELING_ALERT,
  CATEGORY_FEELING_GOOD,
  CATEGORY_FEELING_INACTIVE,
  CATEGORY_FEELING_WARNING,

  CATEGORY_PERFORMANCE_ALERT,
  CATEGORY_PERFORMANCE_GOOD,
  CATEGORY_PERFORMANCE_INACTIVE,
  CATEGORY_PERFORMANCE_WARNING,

  CATEGORY_SECURITY_ALERT,
  CATEGORY_SECURITY_GOOD,
  CATEGORY_SECURITY_INACTIVE,
  CATEGORY_SECURITY_WARNING,

  CO2_ALERT,
  CO2_GOOD,
  CO2_INACTIVE,
  CO2_WARNING,

  CONSUMPTION_ALERT,
  CONSUMPTION_GOOD,
  CONSUMPTION_INACTIVE,
  CONSUMPTION_WARNING,

  CORRELATION_ALERT,
  CORRELATION_GOOD,
  CORRELATION_INACTIVE,
  CORRELATION_WARNING,

  DOOR_CLOSED_ALERT,
  DOOR_CLOSED_GOOD,
  DOOR_CLOSED_INACTIVE,
  DOOR_CLOSED_WARNING,

  DOOR_LOCKED_ALERT,
  DOOR_LOCKED_GOOD,
  DOOR_LOCKED_INACTIVE,
  DOOR_LOCKED_WARNING,

  DOOR_OPEN_ALERT,
  DOOR_OPEN_GOOD,
  DOOR_OPEN_INACTIVE,
  DOOR_OPEN_WARNING,

  ELEVATOR_ALERT,
  ELEVATOR_GOOD,
  ELEVATOR_INACTIVE,

  FEELING_ALERT,
  FEELING_GOOD,
  FEELING_INACTIVE,
  FEELING_WARNING,

  HUMIDITY_ALERT,
  HUMIDITY_GOOD,
  HUMIDITY_INACTIVE,
  HUMIDITY_WARNING,

  KARMA_LOGO,

  LIGHT_ALERT,
  LIGHT_GOOD,
  LIGHT_INACTIVE,
  LIGHT_WARNING,

  LIFTING_STATION_ALERT,
  LIFTING_STATION_GOOD,
  LIFTING_STATION_INACTIVE,
  LIFTING_STATION_WARNING,

  MEDIUM_ENERGY,
  MEDIUM_HEATING,
  MEDIUM_WATER,

  PERFORMANCE_ALERT,
  PERFORMANCE_GOOD,
  PERFORMANCE_INACTIVE,
  PERFORMANCE_WARNING,

  SECURITY_ALERT,
  SECURITY_GOOD,
  SECURITY_INACTIVE,
  SECURITY_WARNING,

  TEMPERATURE_ALERT,
  TEMPERATURE_GOOD,
  TEMPERATURE_INACTIVE,
  TEMPERATURE_WARNING,

  UNKNOWN_SENSOR,

  WATER_DETECTOR_ALERT,
  WATER_DETECTOR_GOOD,
  WATER_DETECTOR_INACTIVE,
  WATER_DETECTOR_WARNING,

  INSTALLATION_ALERT,
  INSTALLATION_GOOD,
  INSTALLATION_INACTIVE,
  INSTALLATION_WARNING,

  SMOKE_DETECTOR_ALERT,
  SMOKE_DETECTOR_GOOD,
  SMOKE_DETECTOR_INACTIVE,
  SMOKE_DETECTOR_WARNING,
}
