// modules
import { Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import {
  FILTER_ACTIVE_SENSOR,
  FILTER_INACTIVE_SENSOR,
  FILTER_MAIN_SENSOR,
  FILTER_SUB_SENSOR,
  getRelativeDate,
  userLocales,
  DDMMYYHHMM,
} from '../../../api'
import { useSensorPool } from '../../../hooks'

export const SensorPoolStat = () => {
  const { data, fetchDate } = useSensorPool()

  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1, mb: 2 }}>
        <Typography>Poolgröße</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{data.length}</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>aktiviert</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{data.filter(FILTER_ACTIVE_SENSOR).length}</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>deaktiviert</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{data.filter(FILTER_INACTIVE_SENSOR).length}</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Provider</Typography>
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          title={[...new Set(data.map(i => i.attributes.provider))]
            .sort()
            .join(', ')}
        >
          <Typography>
            {[...new Set(data.map(i => i.attributes.provider))].length}
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Sensor Typen</Typography>
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          title={[...new Set(data.map(i => i.attributes.sensor_type))]
            .sort()
            .join(', ')}
        >
          <Typography>
            {[...new Set(data.map(i => i.attributes.sensor_type))].length}
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Sensor Targets</Typography>
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          title={[...new Set(data.map(i => i.attributes.sensor_target))]
            .sort()
            .join(', ')}
        >
          <Typography>
            {[...new Set(data.map(i => i.attributes.sensor_target))].length}
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Sub Sensor Targets</Typography>
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          title={[...new Set(data.map(i => i.attributes.sub_sensor_target))]
            .sort()
            .join(', ')}
        >
          <Typography>
            {[...new Set(data.map(i => i.attributes.sub_sensor_target))].length}
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Hauptzähler</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{data.filter(FILTER_MAIN_SENSOR).length}</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1 }}>
        <Typography>Unterzähler</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{data.filter(FILTER_SUB_SENSOR).length}</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right', pr: 1, mt: 2 }}>
        <Typography>gefetcht</Typography>
      </Grid>
      <Grid item xs={6} sx={{ mt: 2 }}>
        <Typography>
          {new Date(fetchDate).toLocaleString(userLocales, DDMMYYHHMM)}
          <br />
          {getRelativeDate(fetchDate)}
        </Typography>
      </Grid>
    </Grid>
  )
}
