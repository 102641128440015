import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { newsActions } from '../actions'
import { FILTER_TYPE_CONTRACT } from '../api'

export const useNews = id => {
  const dispatch = useDispatch()

  const [data, setData] = useState(null)
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const { oneNews } = useSelector(state => state.news)

  useEffect(
    () => {
      if (Number(id) > 0) {
        setLoading(true)
        dispatch(newsActions.getById(id, ['contracts']))
      }
    },
    [dispatch, id]
  )

  useEffect(
    () => {
      const { error, item } = oneNews

      if (item) {
        const { data, included } = item
        const { attributes } = data

        setData({
          ...attributes,
          contracts: included
            ? included
                .filter(FILTER_TYPE_CONTRACT)
                .map(contract => contract.attributes)
            : [],
        })
        setLoading(false)
      }

      if (error) {
        const { message } = error

        setError(message)
        setLoading(false)
      }
    },
    [oneNews]
  )

  return { isLoading, isError, data }
}
