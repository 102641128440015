import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { consumptionActions } from '../actions'

export const useMeterReadingByFolderId = (folderId, fromDate, toDate) => {
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isFactorError, setFactorError] = useState(null)
  const [factorErrorSensor, setFactorErrorSensor] = useState('')
  const [isLoading, setLoading] = useState(true)

  const { meterReading } = useSelector(state => state.consumption)

  useEffect(
    () => {
      if (Number(folderId) > 0) {
        setLoading(true)
        dispatch(
          consumptionActions.getAllMeterReadingByFolderId(
            folderId,
            fromDate,
            toDate
          )
        )
        setLoading(true)
      }
    },
    [dispatch, folderId, fromDate, toDate]
  )

  useEffect(
    () => {
      if (meterReading) {
        const { error, item } = meterReading

        if (item) {
          const { data, factorError, sensor } = item
          if (factorError) {
            setFactorError(true)
            setFactorErrorSensor(sensor)
            setLoading(false)
          }

          if (data) {
            setData(
              data.map(item => ({
                ...item.attributes,
                entity_id: Number(item.id),
              }))
            )
            setLoading(false)
          }
        }

        if (error) {
          const { message } = error

          setError(message)
          setLoading(false)
        }
      }
    },
    [meterReading]
  )

  return { isLoading, isFactorError, factorErrorSensor, isError, data }
}
