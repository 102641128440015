export const ASC = 'asc'
export const DESC = 'desc'

export const SORT = (a, b) => {
  if (a < b) return -1
  if (a > b) return 1

  return 0
}

export const SORT_ATTR_FIRSTNAME = (a, b) => {
  const nameA = String(a.attributes.firstname).toUpperCase()
  const nameB = String(b.attributes.firstname).toUpperCase()

  if (nameA < nameB) return -1
  if (nameA > nameB) return 1

  return 0
}

export const SORT_ATTR_NAME = (a, b) => {
  const nameA = String(a.attributes.name).toUpperCase()
  const nameB = String(b.attributes.name).toUpperCase()

  if (nameA < nameB) return -1
  if (nameA > nameB) return 1

  return 0
}

export const SORT_NAME = (a, b) => {
  const nameA = String(a.name).toUpperCase()
  const nameB = String(b.name).toUpperCase()

  if (nameA < nameB) return -1
  if (nameA > nameB) return 1

  return 0
}

export const SORT_WEEKDAY = (a, b) => {
  if (a.day_of_week > b.day_of_week) return 1
  if (a.day_of_week < b.day_of_week) return -1

  return 0
}

export const SORT_TIMEPOINT = (a, b) =>
  new Date(a.timepoint) - new Date(b.timepoint)
