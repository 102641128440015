import { CancelTwoTone, DeleteTwoTone } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const DialogDelete = ({
  caption = 'Bestätigung erforderlich',
  id,
  onCancel,
  onConfirm,
  open,
  serviceName,
  severity = 'warning',
  title = 'ACHTUNG!',
  ...rest
}) => {
  const theme = useTheme()
  const { white: color } = theme.palette

  return (
    <Dialog onClose={onCancel} open={open} {...rest}>
      <DialogTitle>{caption}</DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Alert severity={severity}>
          <AlertTitle>{title}</AlertTitle>

          <Typography>
            {serviceName} ID: {id} wirklich löschen?
          </Typography>
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button color={'error'} onClick={onConfirm} sx={{ color }}>
          <DeleteTwoTone sx={{ mr: 1 }} /> ID: {id} löschen
        </Button>

        <Button onClick={onCancel} sx={{ color }}>
          <CancelTwoTone sx={{ mr: 1 }} /> Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogDelete.propTypes = {
  caption: PropTypes.string,
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  serviceName: PropTypes.string,
  severity: PropTypes.string,
  title: PropTypes.string,
}
