// modules
import {
  MemoryTwoTone,
  NavigateBeforeTwoTone,
  NavigateNextTwoTone,
  StarBorderTwoTone,
  StarTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  Badge,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
// scripts
import {
  FILTER_SUB_SENSOR,
  sensorType,
  sensorUnit,
  userLocales,
} from '../../../api'
import { useFolder } from '../../../hooks'

const MainSensor = ({ ...rest }) => (
  <Chip
    avatar={<StarTwoTone />}
    sx={{ mr: 1, mb: 1 }}
    variant={'outlined'}
    {...rest}
  />
)

const SubSensor = ({ ...rest }) => (
  <Chip
    avatar={<StarBorderTwoTone />}
    sx={{ mr: 1 }}
    variant={'outlined'}
    {...rest}
  />
)

// Test Hauptzähler

const MainMeterTest = ({ data, medium, onResult }) => {
  const history = useHistory()
  const isMainSensor = sensor => sensor.parent_id === null
  const mainSensors = data.filter(sensor => isMainSensor(sensor))
  const count = mainSensors.length
  const severity = count > 0 ? 'success' : 'error'

  onResult({ color: severity, count })

  return (
    <Stack>
      <Alert severity={'info'}>
        <Typography>
          &bull; wenn das Attribut <code>parent_id</code> eines Sensors{' '}
          <code>null</code> oder <code>&apos;&apos;</code> ist, wird er als
          Hauptzähler klassifiziert
        </Typography>

        <Typography>
          &bull; pro Medium ist als Referenz für Vertragsinformationen
          mindestens 1 Hauptzähler erforderlich
        </Typography>
      </Alert>

      <Divider />

      <Alert severity={severity}>
        <Typography sx={{ fontWeight: 700 }}>
          {count} {medium} Hauptzähler gefunden
        </Typography>

        {mainSensors.map((sensor, key) => {
          const { entity_id: id } = sensor
          const editSensor = () => history.push(`/sensors/${id}`)

          return (
            <MainSensor
              key={key}
              label={id}
              onClick={editSensor}
              sx={{ mt: 2, mr: 1, mb: 1 }}
            />
          )
        })}
      </Alert>
    </Stack>
  )
}

MainMeterTest.propTypes = {
  data: PropTypes.array.isRequired,
  medium: PropTypes.string.isRequired,
  onResult: PropTypes.func.isRequired,
}

// Test Unterzähler

const SubMeterTest = ({ data, medium, onResult }) => {
  const history = useHistory()
  const isMainSensor = sensor => sensor.parent_id === null
  const mainSensors = data.filter(sensor => isMainSensor(sensor))
  const subSensors = data.filter(FILTER_SUB_SENSOR)
  const msIds = mainSensors.map(sensor => sensor.entity_id)
  const misconfigured = f => !msIds.includes(f.parent_id)
  const severity =
    subSensors.filter(misconfigured).length === 0 ? 'success' : 'error'

  onResult({ color: severity, count: subSensors.length })

  return (
    <Stack>
      <Alert severity={'info'}>
        <Typography>
          &bull; wenn das Attribut <code>parent_id</code> eines Sensors eine{' '}
          <em>SensorID</em> enthält, wird er als Unterzähler klassifiziert
        </Typography>

        <Typography>
          &bull; Unterzähler müssen einem Hauptzähler <em>desselben</em> Gebäude
          zugeordnet sein
        </Typography>
      </Alert>

      <Divider />

      <Alert severity={severity}>
        <Typography sx={{ fontWeight: 700, mb: 2 }}>
          {subSensors.length} {medium} Unterzähler gefunden
        </Typography>

        {subSensors.map((sensor, key) => {
          const { entity_id: id } = sensor
          const editSensor = () => history.push(`/sensors/${id}`)

          return (
            <SubSensor
              key={key}
              label={id}
              onClick={editSensor}
              sx={{ mr: 1, mb: 1 }}
            />
          )
        })}

        <Typography sx={{ mt: 2 }}>
          Unterzähler, die keinem der {mainSensors.length} {medium} Hauptzähler
          zugeordnet sind:
        </Typography>

        {subSensors.filter(misconfigured).map((sensor, key) => {
          const { entity_id: id, parent_id } = sensor

          // handler

          const editSensor = () => history.push(`/sensors/${id}`)

          const editParentSensor = () => history.push(`/sensors/${parent_id}`)

          return (
            <Stack direction={'row'} key={key} sx={{ mb: 1 }}>
              <SubSensor label={id} onClick={editSensor} />

              <Typography sx={{ mx: 1 }}>zugeordnet</Typography>

              <MainSensor label={parent_id} onClick={editParentSensor} />
            </Stack>
          )
        })}

        {subSensors.filter(misconfigured).length === 0 && (
          <Typography sx={{ fontWeight: 700 }}>keine</Typography>
        )}
      </Alert>
    </Stack>
  )
}

SubMeterTest.propTypes = {
  data: PropTypes.array.isRequired,
  medium: PropTypes.string.isRequired,
  onResult: PropTypes.func.isRequired,
}

// Test Synchonizität

const SynchronicityTest = ({ folderId, onResult }) => {
  const history = useHistory()

  const { data: fData, isLoading: isFolderDataLoading } = useFolder(folderId)

  if (isFolderDataLoading) return <LinearProgress />

  const isInSync = String(fData.sensorIds) === String(fData.contract.sensorIds)
  const severity = isInSync ? 'success' : 'error'

  onResult({ color: severity, isInSync })

  return (
    <Stack>
      <Alert severity={'info'}>
        <Typography>
          &bull; die Zuordnung von Sensoren im Gebäude und Sensoren im Vertrag
          muss synchron sein
        </Typography>

        <Typography>
          &bull; beide müssen <em>dieselben</em> Sensoren enthalten
        </Typography>
      </Alert>

      <Divider />

      <Alert severity={severity}>
        <Typography sx={{ mt: 2, mb: 1 }}>
          Sensoren im Gebäude: {fData.sensorIds.length}
        </Typography>

        {fData.sensorIds.map((item, key) => {
          const editSensor = () => history.push(`/sensors/${item}`)

          return <MainSensor key={key} label={item} onClick={editSensor} />
        })}

        <Typography sx={{ mt: 2, mb: 1 }}>
          Sensoren im Vertrag: {fData.contract.sensorIds.length}
        </Typography>

        {fData.contract.sensorIds.map((item, key) => {
          const editSensor = () => history.push(`/sensors/${item}`)

          return <MainSensor key={key} label={item} onClick={editSensor} />
        })}
      </Alert>
    </Stack>
  )
}

SynchronicityTest.propTypes = {
  folderId: PropTypes.number.isRequired,
  onResult: PropTypes.func.isRequired,
}

// Test Vertragsinformationen

const ContractTest = ({ folderId, onResult }) => {
  const { data, isLoading } = useFolder(folderId)

  if (isLoading) return <LinearProgress />

  const { date_begin, date_expire, status } = data.contract
  const severity = status === 'aktiv' ? 'success' : 'error'
  const options = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }

  onResult({ color: severity, status })

  return (
    <Stack>
      <Alert severity={'info'}>
        <Typography>
          &bull; der Vertrag definiert den Zeitraum, in welchem Messdaten
          erfasst und/oder dargestellt werden
        </Typography>
      </Alert>

      <Divider />

      <Alert severity={severity}>
        <Typography sx={{ fontWeight: 700 }}>Status: {status}</Typography>

        <Typography>
          Laufzeit:{' '}
          {new Date(date_begin)
            .toLocaleDateString(userLocales, options)
            .replace('Invalid Date', 'unbekannt')}{' '}
          bis{' '}
          {new Date(date_expire)
            .toLocaleDateString(userLocales, options)
            .replace('Invalid Date', 'unbekannt')}
        </Typography>
      </Alert>
    </Stack>
  )
}

ContractTest.propTypes = {
  folderId: PropTypes.number.isRequired,
  onResult: PropTypes.func.isRequired,
}

// Test Daten Quantität

const QuantityTest = ({ allSensors, medium, onResult }) => {
  const history = useHistory()

  const noDataSensors = allSensors
    .filter(f => f.consumption.values.length === 0)
    .map(item => Number(item.entity_id))
    .sort()

  const severity = noDataSensors.length > 0 ? 'error' : 'success'

  onResult({ color: severity })

  return (
    <Stack>
      <Alert severity={'info'}>
        <Typography>
          &bull; es wird erwartet, dass sämtliche Sensoren innerhalb des
          Zeitfensters Daten liefern, abhängig von ihrem Sendeintervall
        </Typography>
      </Alert>

      <Divider />

      <Alert severity={severity}>
        <Typography sx={{ fontWeight: 700, mb: 1 }}>
          Sensoren, die keine Daten für das Zeitfenster liefern:
        </Typography>

        {noDataSensors.length > 0 &&
          noDataSensors.map((sensorId, key) => {
            let parent_id

            const issuer = allSensors.find(
              f => f.entity_id === sensorId && f.sensor_type === medium
            )
            if (issuer) parent_id = issuer.parent_id

            const editSensor = () => history.push(`/sensors/${sensorId}`)

            return !parent_id ? (
              <MainSensor key={key} label={sensorId} onClick={editSensor} />
            ) : (
              <SubSensor key={key} label={sensorId} onClick={editSensor} />
            )
          })}

        {noDataSensors.length === 0 && <Typography>keine</Typography>}
      </Alert>
    </Stack>
  )
}

QuantityTest.propTypes = {
  allSensors: PropTypes.array.isRequired,
  medium: PropTypes.string.isRequired,
  onResult: PropTypes.func.isRequired,
}

// Test Daten Dichte

const DensityTest = ({ medium, fillUpSensors, onResult }) => {
  const history = useHistory()
  const severity = fillUpSensors.length > 0 ? 'warning' : 'success'

  onResult({ color: severity })

  return (
    <Stack>
      <Alert severity={'info'}>
        <Typography>
          &bull; die Daten Dichte <em>kann</em> variieren, denn Sensoren haben
          mitunter verschiedene Sendezeiten und -intervalle
        </Typography>

        <Typography>
          &bull; für das Diagramm ist pro Sensor dieselbe Anzahl an Messdaten
          erforderlich - um ein Gitter zu bilden
        </Typography>

        <Typography>
          &bull; nicht vorhandene Messdaten werden ggfs. durch einen Verbrauch
          von 0 interpoliert
        </Typography>

        <Typography>
          &bull; haben alle Sensoren die gleiche Anzahl an Messinformationen,
          liegt die Daten Dichte bei 100%
        </Typography>

        <Typography>
          &bull; liegen keine Messdaten vor, liegt die Daten Dichte ebenfalls
          bei 100%
        </Typography>
      </Alert>

      <Divider />

      <Alert severity={severity}>
        {fillUpSensors.length === 0 && (
          <Typography sx={{ fontWeight: 700 }}>100%</Typography>
        )}

        {fillUpSensors.length > 0 &&
          fillUpSensors.map((sensor, key) => {
            const { entity_id: id, amount } = sensor
            const editSensor = () => history.push(`/sensors/${id}`)

            return (
              <Stack direction={'row'} key={key} sx={{ mt: 1 }}>
                <Button onClick={editSensor}>{id}</Button>{' '}
                <Typography>
                  hat {amount} Messdaten weniger und wurde mit {amount}{' '}
                  Einträgen à 0 {sensorUnit(medium)} Verbrauch ergänzt
                </Typography>
              </Stack>
            )
          })}
      </Alert>
    </Stack>
  )
}

DensityTest.propTypes = {
  fillUpSensors: PropTypes.array.isRequired,
  medium: PropTypes.string.isRequired,
  onResult: PropTypes.func.isRequired,
}

//

export const MediumDiagnostics = ({
  open,
  onClose,
  allSensors,
  folderId,
  fillUpSensors,
  title,
  medium,
}) => {
  const theme = useTheme()
  const { white: color } = theme.palette

  const mediumTitle = sensorType(medium)

  const [tab, setTab] = useState(0)

  // badge color refs
  const mcStatus = useRef({ color: 'info', count: 0 })
  const scStatus = useRef({ color: 'info', count: 0 })
  const syncStatus = useRef({ color: 'info', isInSync: 0 })
  const contractStatus = useRef({ color: 'info', status: 'invalid' })
  const quantityStatus = useRef({ color: 'info', status: 'todo' })
  const densityStatus = useRef({ color: 'info', status: 'todo' })

  const data = [
    { color: mcStatus.current.color, label: `${mediumTitle} Hauptzähler` },
    { color: scStatus.current.color, label: `${mediumTitle} Unterzähler` },
    { color: syncStatus.current.color, label: 'Synchronizität' },
    { color: contractStatus.current.color, label: 'Vertrag' },
    { color: quantityStatus.current.color, label: 'Daten Quantität' },
    { color: densityStatus.current.color, label: 'Daten Dichte' },
  ]

  return (
    <Dialog maxWidth={'lg'} open={open} onClose={onClose}>
      <DialogTitle>
        Diagnose für Medium {mediumTitle} | {title}
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Tabs
          indicatorColor={'primary'}
          onChange={(event, tab) => setTab(tab)}
          textColor={'primary'}
          value={tab}
        >
          {data.map((item, key) => {
            const { color, label } = item

            return (
              <Tab
                icon={
                  <Badge color={color} variant={'dot'}>
                    <MemoryTwoTone />
                  </Badge>
                }
                key={key}
                label={label}
                value={key}
              />
            )
          })}
        </Tabs>

        <Divider />

        <Collapse in={tab === 0}>
          <MainMeterTest
            data={allSensors}
            medium={mediumTitle}
            onResult={result => (mcStatus.current = result)}
          />
        </Collapse>

        <Collapse in={tab === 1}>
          <SubMeterTest
            data={allSensors}
            medium={mediumTitle}
            onResult={result => (scStatus.current = result)}
          />
        </Collapse>

        <Collapse in={tab === 2}>
          <SynchronicityTest
            folderId={folderId}
            onResult={result => (syncStatus.current = result)}
          />
        </Collapse>

        <Collapse in={tab === 3}>
          <ContractTest
            folderId={folderId}
            onResult={result => (contractStatus.current = result)}
          />
        </Collapse>

        <Collapse in={tab === 4}>
          <QuantityTest
            allSensors={allSensors}
            medium={medium}
            onResult={result => (quantityStatus.current = result)}
          />
        </Collapse>

        <Collapse in={tab === 5}>
          <DensityTest
            fillUpSensors={fillUpSensors}
            medium={medium}
            onResult={result => (densityStatus.current = result)}
          />
        </Collapse>
      </DialogContent>

      <DialogActions>
        <IconButton
          disabled={tab === 0}
          onClick={() => setTab(prev => prev - 1)}
          sx={{ color }}
        >
          <NavigateBeforeTwoTone />
        </IconButton>

        <IconButton
          disabled={tab === 5}
          onClick={() => setTab(prev => prev + 1)}
          sx={{ color }}
        >
          <NavigateNextTwoTone />
        </IconButton>
      </DialogActions>
    </Dialog>
  )
}

MediumDiagnostics.propTypes = {
  allSensors: PropTypes.array.isRequired,
  fillUpSensors: PropTypes.array,
  folderId: PropTypes.number.isRequired,
  medium: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}
