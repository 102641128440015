import {
  BarChartTwoTone,
  ShowChartTwoTone,
  TimelineTwoTone,
} from '@mui/icons-material'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import React from 'react'

export const ChartTypeSelector = ({ ...rest }) => {
  const data = [
    {
      icon: <BarChartTwoTone />,
      tooltip: 'Säulendiagramm',
      type: 'ColumnChart',
    },
    {
      icon: <ShowChartTwoTone />,
      tooltip: 'Bereichsdiagramm',
      type: 'AreaChart',
    },
    {
      icon: <TimelineTwoTone />,
      tooltip: 'Liniendiagramm',
      type: 'LineChart',
    },
  ]

  return (
    <ToggleButtonGroup exclusive {...rest}>
      {data.map((item, key) => {
        const { icon, tooltip, type } = item

        return (
          <ToggleButton key={key} value={type}>
            <Tooltip title={tooltip}>{icon}</Tooltip>
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}
