import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sensorActions } from '../actions'
import {
  FILTER_TYPE_CONTRACT,
  FILTER_TYPE_FOLDER,
  FILTER_TYPE_INSTALLATION,
  FILTER_TYPE_PERIOD,
  FILTER_TYPE_USER,
} from '../api'

export const useSensor = id => {
  const dispatch = useDispatch()

  const [data, setData] = useState(null)
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const { oneSensor } = useSelector(state => state.sensor)

  useEffect(
    () => {
      if (Number(id) > 0) {
        setLoading(true)
        dispatch(
          sensorActions.getById(id, [
            'contracts',
            'folders',
            'periods',
            'installations',
            'users',
          ])
        )
      }
    },
    [dispatch, id]
  )

  useEffect(
    () => {
      const { error, item } = oneSensor

      if (item) {
        const { data, included } = item
        const { attributes } = data
        setData({
          ...attributes,
          contracts: included
            ? included
                .filter(FILTER_TYPE_CONTRACT)
                .map(contract => contract.attributes)
            : [],
          folders: included
            ? included
                .filter(FILTER_TYPE_FOLDER)
                .map(folder => folder.attributes)
            : [],
          periods: included
            ? included.filter(FILTER_TYPE_PERIOD).map(period => ({
                period_start: period.period.period_start,
                period_end: period.period.period_end,
                sensor_id: period.period.sensor_id,
                factor: period.period.factor,
              }))
            : [],
          installation: included
            ? included.filter(FILTER_TYPE_INSTALLATION).map(installation => ({
                entity_id: installation.installation.attributes.installation_id,
              }))
            : [],
          users: included
            ? included.filter(FILTER_TYPE_USER).map(user => Number(user.id))
            : [],
        })
        setLoading(false)
      }

      if (error) {
        const { message } = error

        setError(message)
        setLoading(false)
      }
    },
    [oneSensor]
  )

  return { isLoading, isError, data }
}
