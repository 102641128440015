import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { systemActions } from '../actions'

const payloadContext = createContext({})

export const ProvidePayloadPool = ({ children }) => {
  const value = usePayloads()

  return (
    <payloadContext.Provider value={value}>{children}</payloadContext.Provider>
  )
}
ProvidePayloadPool.propTypes = { children: PropTypes.any }

export const usePayloadPool = () => useContext(payloadContext)

const usePayloads = () => {
  const dispatch = useDispatch()
  const { allPayload } = useSelector(state => state.system)
  const { isAdmin } = useSelector(state => state.user.oneUser)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(
    () => {
      if (isAdmin) {
        dispatch(systemActions.getAllPayload())
        setLoading(true)
      }
    },
    [dispatch, isAdmin]
  )

  useEffect(
    () => {
      if (allPayload) {
        const { error, fetchDate, items } = allPayload

        if (items) {
          const { data } = items

          if (data) {
            setData(data)
            setLoading(false)
            setFetchDate(fetchDate)
          }
        }

        if (error) {
          setError(error)
          setLoading(false)
          setFetchDate(fetchDate)
        }
      }
    },
    [allPayload]
  )

  return { data, isError, isLoading, fetchDate }
}
