import { DeleteTwoTone, DynamicFeedTwoTone } from '@mui/icons-material'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const AboRecipients = ({ data, onDelete }) => {
  const theme = useTheme()
  const borderColor = theme.palette.lightgray.dark
  const border = `solid 1px ${borderColor}`

  const onClick = key => onDelete(data.filter((f, index) => index !== key))

  return (
    <Card sx={{ border, borderRadius: '10px' }}>
      <CardHeader avatar={<DynamicFeedTwoTone />} title={'Empfänger-Liste'} />

      <Divider />

      <CardContent>
        <List>
          {data.map((recipient, key) => (
            <ListItem key={key}>
              <ListItemAvatar>
                <Avatar>{key + 1}</Avatar>
              </ListItemAvatar>

              <ListItemText>{recipient.email}</ListItemText>

              <ListItemSecondaryAction>
                <IconButton onClick={() => onClick(key)}>
                  <Tooltip placement={'bottom-start'} title={'Löschen'}>
                    <DeleteTwoTone />
                  </Tooltip>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        {data.length === 0 && <Typography>Liste leer</Typography>}
      </CardContent>
    </Card>
  )
}

AboRecipients.propTypes = {
  data: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
}
