import { useEffect, useState, useRef } from 'react'
import { authHeader } from '../api'
import { API } from 'aws-amplify'
import { useDispatch } from 'react-redux'

export const useDataPointsByPanelIds = (loadTrigger, dashboardID, panels) => {
  const dispatch = useDispatch()
  const [isError, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const dataPointsRef = useRef({ items: [] })
  const [numPendingRequests, setNumPendingRequests] = useState(0)

  useEffect(
    () => {
      if (loadTrigger) {
        setIsLoading(true)
        dataPointsRef.current = { items: [] }
        setNumPendingRequests(panels.length)

        const fetchDataPoints = async panelID => {
          let result = 'unset'
          const API_NAME = 'karmaApi'
          const endpoint = `/dashboard/${dashboardID}/panel/${panelID}/datapoint`
          const options = { method: 'GET', headers: authHeader() }

          result = await API.get(API_NAME, endpoint, options)
            .then(response => {
              return response
            })
            .catch(error => setError(error))

          if (result.data) {
            const newItems = { ...dataPointsRef.current.items }
            const sensorIds = result.data.map(
              point => point.attributes.sensor_id
            )
            newItems[panelID] = sensorIds
            dataPointsRef.current = { items: newItems }
          }

          setNumPendingRequests(prev => prev - 1)
        }

        panels.forEach(function(panel) {
          fetchDataPoints(panel.id)
        })
      }
    },
    [dashboardID, dispatch, loadTrigger, panels]
  )

  useEffect(
    () => {
      if (numPendingRequests === 0) {
        setIsLoading(false)
      }
    },
    [numPendingRequests]
  )

  return { isLoading, isError, dataPoints: dataPointsRef.current }
}
