// sessionStorage

export const getSession = key => JSON.parse(sessionStorage.getItem(key))

export const setSession = (key, value) =>
  sessionStorage.setItem(key, JSON.stringify(value))

export const removeSession = key => sessionStorage.removeItem(key)

// localStorage

export const getStorage = key => JSON.parse(localStorage.getItem(key))

export const setStorage = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value))

export const removeStorage = key => localStorage.removeItem(key)

// initial react chart config for session storage

const _endDate = new Date()
const _startDate = new Date()
_startDate.setDate(_startDate.getDate() - 6)
const start = _startDate.toISOString().split('T')[0]
const end = _endDate.toISOString().split('T')[0]

export const initialChartConfig = {
  chartType: 'ColumnChart',
  diagnosis: false,
  granularity: 'm',
  fromDate: start,
  isStacked: true,
  showSettings: false,
  toDate: end,
}
