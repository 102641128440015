import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { newsActions } from '../actions'
import { A_MONTH_AGO } from '../api'

const alertsPoolContext = createContext({})

export const ProvideAlertsPool = ({ children }) => {
  const value = useAlerts()

  return (
    <alertsPoolContext.Provider value={value}>
      {children}
    </alertsPoolContext.Provider>
  )
}
ProvideAlertsPool.propTypes = { children: PropTypes.any }

export const useAlertsPool = () => useContext(alertsPoolContext)

const useAlerts = () => {
  const dispatch = useDispatch()
  const { allAlerts } = useSelector(state => state.news)

  const [data, setData] = useState([])
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [fetchDate, setFetchDate] = useState(null)

  useEffect(
    () => {
      const alertsMax = 5
      const mask = 7

      dispatch(
        newsActions.getAllAlerts(
          `?page=1&limit=${alertsMax}&status=red&mask=${mask}`
        )
      )
      setLoading(true)
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (allAlerts) {
        const { error, fetchDate, items, loading } = allAlerts

        setLoading(loading)
        setFetchDate(fetchDate)

        if (items) {
          const { data, message } = items

          if (data)
            setData(
              data.filter(f => new Date(f.attributes.timestamp) > A_MONTH_AGO)
            )

          if (message) setError(message)
        }

        if (error) setError(error)
      }
    },
    [allAlerts]
  )

  return { data, isError, isLoading, fetchDate }
}
