import { alertConstants as AC } from '../constants'

export const alert = (state = {}, action) => {
  const { message, type } = action

  switch (type) {
    case AC.CLEAR:
      return {}

    case AC.INFO:
      return { type: 'info', message: message, pl: 'tr' }

    case AC.PRIMARY:
      return { type: 'primary', message: message, pl: 'tr' }

    case AC.ERROR:
      return { type: 'error', message: message, pl: 'tr' }

    case AC.SUCCESS:
      return { type: 'success', message: message, pl: 'tr' }

    case AC.WARNING:
      return { type: 'warning', message: message, pl: 'tr' }

    default:
      return state
  }
}
