import { alertConstants as AC } from '../constants'

const clear = () => ({ type: AC.CLEAR })
const error = message => ({ type: AC.ERROR, message })
const info = message => ({ type: AC.INFO, message })
const primary = message => ({ type: AC.PRIMARY, message })
const success = message => ({ type: AC.SUCCESS, message })
const warning = message => ({ type: AC.WARNING, message })

export const alertActions = {
  clear,
  error,
  info,
  primary,
  success,
  warning,
}
