import { AddTwoTone } from '@mui/icons-material'
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { PropTypes } from 'prop-types'
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useSortBy, useTable } from 'react-table'
import { sensorFactorActions as SFA } from '../../../actions'
import { DDMMYYYY, normalizedSensorType, userLocales } from '../../../api'
import { HStack, KTextField } from '../../../components'
import { useContractPool, useSensorFactor } from '../../../hooks'

const GermanDate = data => {
  return new Date(data.value).toLocaleDateString(userLocales, DDMMYYYY)
}

export const SensorFactors = sensor => {
  const dispatch = useDispatch()
  const { data: contractPool } = useContractPool()

  const {
    entity_id,
    type_in_folder,
    sensor_type,
    sub_sensor_target,
    unit,
  } = sensor.sensor

  const { contractId } = sensor

  const contractData = contractPool.find(
    contract => Number(contract.id) === Number(contractId)
  )

  let date_begin = '2018-01-01'
  let date_expire = new Date(Date.now()).toISOString().split('T')[0]

  if (contractData && contractData.attributes) {
    date_begin = contractData.attributes.date_begin
    date_expire = contractData.attributes.date_expire
  }

  // limit date selection to contract lifetime
  const boundaries = date =>
    date < date_begin ? date_begin : date > date_expire ? date_expire : date

  const sanitize = date => boundaries(date)

  const { data: sensorFactors, isLoading } = useSensorFactor(entity_id)
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(false)
  const [newSensorFactor, setNewSensorFactor] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [warningText, setWarningText] = useState(false)

  const basicSensorFactor = {
    period_start: sanitize(date_begin),
    period_end: sanitize(date_expire),
    basic_charge_per_year: null,
    price_per_unit: null,
    pulse_value: 1,
    caloric_value: 1,
    conversion_factor: 1,
    transformer_ratio: 1,
    co2_factor: null,
    number_of_employees: null,
    area_in_sqm: null,
    sensor_id: entity_id,
    entity_id: null,
  }

  const [sensorFactor, setSensorFactor] = useState(basicSensorFactor)

  const germanNotation = value => value && value.toString().replace(',', '.')

  const isEnergySensor =
    normalizedSensorType(sensor_type, type_in_folder, sub_sensor_target) ===
    'energy'

  const isWaterSensor =
    normalizedSensorType(sensor_type, type_in_folder, sub_sensor_target) ===
    'water'

  useEffect(
    () => {
      if (isLoading) return

      if (sensorFactors.length >= 1) {
        const lastSensorFactor = sensorFactors[sensorFactors.length - 1]
        lastSensorFactor.attributes.entity_id = lastSensorFactor.id
        setSensorFactor(lastSensorFactor.attributes)
      }
    },
    [isLoading, sensorFactors]
  )

  useEffect(
    () => {
      if (isLoading || sensorFactors.length < 2) return

      const findGap = data => {
        data.sort(
          (a, b) =>
            new Date(a.attributes.period_start) -
            new Date(b.attributesperiod_start)
        )

        for (let i = 0; i < data.length - 1; i++) {
          const currentEnd = new Date(data[i].attributes.period_end)
          const nextStart = new Date(data[i + 1].attributes.period_start)
          const DAY_IN_MS = 86400000

          if (currentEnd.getTime() + DAY_IN_MS !== nextStart.getTime())
            return true
        }

        return false
      }

      const findOverlap = data => {
        data.sort(
          (a, b) =>
            new Date(a.attributes.period_start) -
            new Date(b.attributes.period_start)
        )

        for (let i = 1; i < data.length; i++)
          if (
            new Date(data[i].attributes.period_start) <
            new Date(data[i - 1].attributes.period_end)
          )
            return true

        return false
      }

      console.log(findGap(sensorFactors), findOverlap(sensorFactors))

      setShowWarning(false)

      if (findOverlap(sensorFactors)) {
        setShowWarning(true)
        setWarningText(
          'Es gibt Überschneidungen zwischen den gesetzten Zeitäumen'
        )
      }

      if (findGap(sensorFactors)) {
        setShowWarning(true)
        setWarningText('Es gibt Lücken zwischen den gesetzten Zeitäumen')
      }
    },
    [isLoading, sensorFactors]
  )

  const handleTableClick = rowInfo => {
    setOpen(true)
    setNewSensorFactor(false)
    setSensorFactor(rowInfo.original)
    setIndex(rowInfo.index)
  }

  const saveSensorFactor = sensor =>
    newSensorFactor === true
      ? dispatch(SFA.save(sensor))
      : dispatch(SFA.update(sensor, sensor.entity_id))

  const deleteSensorFactor = sensor =>
    dispatch(SFA.deleteSensorFactor(sensor.entity_id, entity_id))

  const data = useMemo(() => sensorFactors.map(sensor => sensor.attributes), [
    sensorFactors,
  ])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: useMemo(
        () => {
          const columns = [
            {
              accessor: 'period_start',
              Cell: GermanDate,
              filterAll: true,
              Header: (
                <Tooltip title={'Beginn des Zeitraums festlegen'}>
                  <Typography>Von</Typography>
                </Tooltip>
              ),
            },
            {
              accessor: 'period_end',
              Cell: GermanDate,
              filterAll: true,
              Header: (
                <Tooltip title={'Ende des Zeitraums festlegen'}>
                  <Typography>Bis</Typography>
                </Tooltip>
              ),
            },
            {
              accessor: 'basic_charge_per_year',
              Header: (
                <Tooltip
                  title={'Die gesamten Fixkosten des Zeitraums festlegen'}
                >
                  <Typography>Fixkosten</Typography>
                </Tooltip>
              ),
            },
            {
              accessor: 'price_per_unit',
              Header: (
                <Tooltip title={'Die variablen Kosten des Zeitraums festlegen'}>
                  <Typography>Variable Kosten {String(unit)}</Typography>
                </Tooltip>
              ),
            },
            {
              accessor: 'pulse_value',
              Header: (
                <Tooltip title={'Den Sensor Faktor des Zeitraums festlegen'}>
                  <Typography>Impulsfaktor</Typography>
                </Tooltip>
              ),
            },
            {
              accessor: 'caloric_value',
              Header: (
                <Tooltip title={'Den Brennwert Faktor des Zeitraums festlegen'}>
                  <Typography>Brennwert</Typography>
                </Tooltip>
              ),
            },
            {
              accessor: 'transformer_ratio',
              Header: (
                <Tooltip
                  title={'Den Wandlerfaktor Faktor des Zeitraums festlegen'}
                >
                  <Typography>Wandlerfaktor</Typography>
                </Tooltip>
              ),
            },
            {
              accessor: 'conversion_factor',
              Header: (
                <Tooltip
                  title={
                    'Den Faktor für die Zustandszahl des Zeitraums festlegen'
                  }
                >
                  <Typography>Zustandszahl</Typography>
                </Tooltip>
              ),
            },
            {
              accessor: 'co2_factor',
              Header: (
                <Tooltip title={'Den CO₂ Faktor für den Zeitraum festlegen'}>
                  <Typography>CO₂ Faktor in kg pro kWh</Typography>
                </Tooltip>
              ),
            },
            {
              accessor: 'number_of_employees',
              Header: (
                <Tooltip
                  title={
                    'Die Anzahl der Mitarbeiter für den Zeitraum festlegen'
                  }
                >
                  <Typography>Anzahl Mitarbeiter</Typography>
                </Tooltip>
              ),
            },
            {
              accessor: 'area_in_sqm',
              Header: (
                <Tooltip title={'Die Gebäudefläche für den Zeitraum festlegen'}>
                  <Typography>Fläche in m²</Typography>
                </Tooltip>
              ),
            },
          ]

          const updateColumns = data =>
            isEnergySensor
              ? data.filter(
                  item =>
                    item.accessor !== 'caloric_value' &&
                    item.accessor !== 'conversion_factor'
                )
              : isWaterSensor
                ? data.filter(
                    item =>
                      item.accessor !== 'co2_factor' &&
                      item.accessor !== 'caloric_value' &&
                      item.accessor !== 'conversion_factor'
                  )
                : data

          return updateColumns(columns)
        },
        [isEnergySensor, isWaterSensor, unit]
      ),
      data,
      initialState: { pageSize: data.length },
    },
    useSortBy
  )

  const onCloseDialog = () => {
    setOpen(false)
    setIndex(0)
  }

  const onChangePeriodStart = event => {
    const period_start = event.target.value

    setSensorFactor(prev => ({ ...prev, period_start }))
  }

  const onChangePeriodEnd = event => {
    const period_end = event.target.value

    setSensorFactor(prev => ({ ...prev, period_end }))
  }

  const onChangePulseValue = event => {
    const pulse_value = event.target.value

    setSensorFactor(prev => ({ ...prev, pulse_value }))
  }

  const onChangeNumberOfEmployees = event => {
    const number_of_employees = event.target.value

    setSensorFactor(prev => ({ ...prev, number_of_employees }))
  }

  const onChangeAreaInSqm = event => {
    const area_in_sqm = event.target.value

    setSensorFactor(prev => ({ ...prev, area_in_sqm }))
  }

  const onChangeCaloricValue = event => {
    const caloric_value = event.target.value

    setSensorFactor(prev => ({ ...prev, caloric_value }))
  }

  const onChangeConversionFactor = event => {
    const conversion_factor = event.target.value

    setSensorFactor(prev => ({ ...prev, conversion_factor }))
  }

  const onChangeTransformerRatio = event => {
    const transformer_ratio = event.target.value

    setSensorFactor(prev => ({ ...prev, transformer_ratio }))
  }

  const onChangePricePerUnit = event => {
    const price_per_unit = event.target.value

    setSensorFactor(prev => ({ ...prev, price_per_unit }))
  }

  const onChangeCo2Factor = event => {
    const co2_factor = event.target.value

    setSensorFactor(prev => ({ ...prev, co2_factor }))
  }

  const onChangeBasicChargePerYear = event => {
    const basic_charge_per_year = event.target.value

    setSensorFactor(prev => ({ ...prev, basic_charge_per_year }))
  }

  const onCancel = () => {
    setOpen(false)
    setIndex(0)
  }

  const onDelete = () => {
    setOpen(false)
    setIndex(0)
    deleteSensorFactor(sensorFactor)
  }

  const onSave = () => {
    setOpen(false)
    setIndex(0)
    saveSensorFactor(sensorFactor)
  }

  const onAdd = () => {
    setOpen(true)
    setNewSensorFactor(true)
  }

  return (
    <>
      <Dialog maxWidth={'lg'} open={open} onClose={onCloseDialog}>
        <DialogTitle>
          Faktoren für Zeitraum{' '}
          {newSensorFactor === true ? 'erfassen' : 'bearbeiten'}
        </DialogTitle>

        <DialogContent>
          <TextField
            fullWidth
            inputProps={{
              min: sanitize(date_begin),
              max: sanitize(date_expire),
            }}
            label={'Beginn Zeitraum'}
            onChange={onChangePeriodStart}
            type={'date'}
            value={sensorFactor.period_start}
          />

          <TextField
            fullWidth
            inputProps={{
              min: sanitize(date_begin),
              max: sanitize(date_expire),
            }}
            label={'Ende Zeitraum'}
            onChange={onChangePeriodEnd}
            type={'date'}
            value={sensorFactor.period_end}
          />

          <KTextField
            error={!sensorFactor.pulse_value}
            inputProps={{ min: 0 }}
            label={'Impulswert Multiplikator'}
            onChange={onChangePulseValue}
            type={'number'}
            value={germanNotation(sensorFactor.pulse_value)}
          />

          <KTextField
            label={'Mitarbeiter'}
            onChange={onChangeNumberOfEmployees}
            type={'number'}
            value={germanNotation(sensorFactor.number_of_employees)}
          />

          <KTextField
            label={'Fläche'}
            onChange={onChangeAreaInSqm}
            type={'number'}
            unit={'in m²'}
            value={germanNotation(sensorFactor.area_in_sqm)}
          />

          <KTextField
            disabled={isEnergySensor || isWaterSensor}
            label={'Brennwert'}
            onChange={onChangeCaloricValue}
            value={germanNotation(sensorFactor.caloric_value) || ''}
          />

          <KTextField
            disabled={isEnergySensor || isWaterSensor}
            label={'Zustandszahl'}
            onChange={onChangeConversionFactor}
            value={germanNotation(sensorFactor.conversion_factor) || ''}
          />

          <KTextField
            label={'Wandlerfaktor'}
            onChange={onChangeTransformerRatio}
            value={germanNotation(sensorFactor.transformer_ratio) || ''}
          />

          <KTextField
            disabled={isWaterSensor}
            label={'CO₂ Faktor'}
            onChange={onChangeCo2Factor}
            unit={'kg pro kWh'}
            value={germanNotation(sensorFactor.co2_factor)}
          />

          <HStack>
            <KTextField
              label={`variable Kosten pro ${unit}`}
              onChange={onChangePricePerUnit}
              unit={'€'}
              value={germanNotation(sensorFactor.price_per_unit)}
            />

            <KTextField
              label={`Fixkosten pro Jahr`}
              onChange={onChangeBasicChargePerYear}
              unit={'€'}
              value={germanNotation(sensorFactor.basic_charge_per_year)}
            />
          </HStack>
        </DialogContent>

        <DialogActions>
          <Button disabled={index < 1} onClick={onDelete}>
            Löschen
          </Button>
          <Button onClick={onSave}>Speichern</Button>
          <Button onClick={onCancel}>Abbrechen</Button>
        </DialogActions>
      </Dialog>

      <Collapse in={showWarning}>
        <Alert severity={'warning'}>{`${warningText}`}</Alert>
      </Collapse>

      <TableContainer sx={{ border: 'solid 1px #DDD', borderRadius: 2 }}>
        <Table sx={{ width: 1 }} {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, innerKey) => (
                  <TableCell
                    key={innerKey}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {rows.map((row, key) => {
              prepareRow(row)

              return (
                <TableRow
                  key={key}
                  onClick={() => handleTableClick(row)}
                  sx={{ cursor: 'pointer' }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, innerKey) => (
                    <TableCell key={innerKey} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <IconButton onClick={onAdd}>
        <AddTwoTone />
      </IconButton>
    </>
  )
}

SensorFactors.propTypes = {
  sensor: PropTypes.object,
  contractId: PropTypes.number,
}
