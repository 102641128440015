import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { DDMMYYYYHHMMSS, getRelativeDate, userLocales } from '../api'

export const ToggleDateType = ({ date }) => {
  const [isDateRelative, setMode] = useState(true)

  return (
    <span
      onClick={() => setMode(prev => !prev)}
      style={{ cursor: 'pointer', ml: '5px' }}
    >
      {isDateRelative === false &&
        new Date(date).toLocaleString(userLocales, DDMMYYYYHHMMSS)}
      {isDateRelative === true && getRelativeDate(date)}
    </span>
  )
}

ToggleDateType.propTypes = {
  date: PropTypes.instanceOf(Date),
}
