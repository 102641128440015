import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { SensorIcon, sensorType } from '../../../api'
import { useOperator } from '../../../hooks'

export const DropdownItem = ({ value, color = 'neutral' }) => (
  <MenuItem value={value}>
    <Stack direction={'row'}>
      <ListItemIcon>
        <Box
          alt={''}
          component={'img'}
          src={SensorIcon(value, color)}
          sx={{ width: 25 }}
        />
      </ListItemIcon>
      <ListItemText>{sensorType(value)}</ListItemText>
    </Stack>
  </MenuItem>
)

DropdownItem.propTypes = {
  value: PropTypes.string,
  color: PropTypes.string,
}

export const CorrelationConsumptionItem = ({
  color,
  data,
  onClick,
  isLoading,
  selected,
  consumptionSensorId,
}) => {
  const { device_id, entity_id, name, type_in_folder } = data
  const { data: operatorData } = useOperator()

  return (
    <ListItem
      button
      //disabled={isLoading}
      onClick={onClick}
      selected={selected}
    >
      <ListItemAvatar>
        <Tooltip
          title={
            operatorData && operatorData.isAdmin
              ? `Device ID:${device_id} | Sensor ID:${entity_id}`
              : ''
          }
        >
          {consumptionSensorId === entity_id && isLoading ? (
            <CircularProgress size={32} />
          ) : (
            <Avatar src={SensorIcon(type_in_folder, color)} />
          )}
        </Tooltip>
      </ListItemAvatar>

      <ListItemText primary={name} />
      <Checkbox color={'primary'} checked={selected} />
    </ListItem>
  )
}

CorrelationConsumptionItem.propTypes = {
  color: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  selected: PropTypes.bool,
  consumptionSensorId: PropTypes.number,
}

export const CorrelationFeelingItem = ({
  data,
  onClick,
  isLoading,
  selected,
  feelingSensorId,
}) => {
  const { device_id, entity_id, name, sensor_type, status } = data
  const { data: operatorData } = useOperator()

  return (
    <ListItem
      button
      //disabled={isLoading}
      onClick={onClick}
      selected={selected}
    >
      <ListItemAvatar>
        <Tooltip
          title={
            operatorData && operatorData.isAdmin
              ? `Device ID:${device_id} | Sensor ID:${entity_id}`
              : ''
          }
        >
          {feelingSensorId === entity_id && isLoading ? (
            <CircularProgress size={32} />
          ) : (
            <Avatar src={SensorIcon(sensor_type, status)} />
          )}
        </Tooltip>
      </ListItemAvatar>

      <ListItemText primary={name} />
      <Checkbox color={'primary'} checked={selected} />
    </ListItem>
  )
}

CorrelationFeelingItem.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  selected: PropTypes.bool,
  feelingSensorId: PropTypes.number,
}

export const CorrelationSecurityItem = ({
  data,
  onClick,
  isLoading,
  selected,
  feelingSensorId,
}) => {
  const { device_id, entity_id, name, sensor_type, status } = data
  const { data: operatorData } = useOperator()

  return (
    <ListItem
      button
      //disabled={isLoading}
      onClick={onClick}
      selected={selected}
    >
      <ListItemAvatar>
        <Tooltip
          title={
            operatorData && operatorData.isAdmin
              ? `Device ID:${device_id} | Sensor ID:${entity_id}`
              : ''
          }
        >
          {feelingSensorId === entity_id && isLoading ? (
            <CircularProgress size={32} />
          ) : (
            <Avatar src={SensorIcon(sensor_type, status)} />
          )}
        </Tooltip>
      </ListItemAvatar>

      <ListItemText primary={name} />
      <Checkbox color={'primary'} checked={selected} />
    </ListItem>
  )
}

CorrelationSecurityItem.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  selected: PropTypes.bool,
  feelingSensorId: PropTypes.number,
}
