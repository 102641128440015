import {
  AddCircleTwoTone,
  ExpandMoreTwoTone,
  FilterListTwoTone,
  RemoveCircleTwoTone,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  ListSubheader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import matchSorter from 'match-sorter'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { isFilterMatch, SORT_ATTR_NAME, SVGStatus } from '../../../api'
import { useContractPool, useFolderPool, useSensorPool } from '../../../hooks'
import {
  CellBoilerplate,
  CellIsActive,
  DefaultColumnFilter,
  FilterStatus,
  HStack,
} from '../../../components'

const FolderName = ({ row }) => {
  return <span>{row.folder_name || '-'}</span>
}

FolderName.propTypes = {
  row: PropTypes.string,
}

const CellStatus = ({ row }) => {
  const { sensor_target, status } = row.original

  return (
    <Box sx={{ width: 30 }}>
      <SVGStatus type={sensor_target} status={status} />
    </Box>
  )
}
CellStatus.propTypes = {
  row: PropTypes.object,
}

const CellAssignment = ({ row }) => {
  const { is_selected } = row.original

  return <Checkbox checked={is_selected} />
}
CellAssignment.propTypes = {
  row: PropTypes.object,
}

export const UserSensorAssignments = ({ config, setConfig, user, setUser }) => {
  const { data: folderPool } = useFolderPool()
  const { data: sensorPool, isLoading: isSensorPoolLoading } = useSensorPool()
  const { data: contractPool } = useContractPool()

  const { assignedSensorsOnly, assignmentSection, filter } = config
  const { folders, sensors } = user

  const [folderSensorsPool, setFolderSensorsPool] = useState([])
  const [sensorIdsInFolderPool, setSensorIdsInFolderPool] = useState([])

  const showActiveOnly = true

  useEffect(
    () => {
      if (!folderPool) return

      let pool = folderPool.map(folder => folder.attributes.folder_sensors)
      pool = Object.keys(pool)
        .reduce((arr, key) => arr.concat(pool[key]), [])
        .map(item => item.sensor_id)
        .sort((a, b) => a - b)
      pool = [...new Set(pool)]

      setFolderSensorsPool(pool)
    },
    [folderPool]
  )

  useEffect(
    () => {
      // 1. get all sensor ids of currently selected folders
      const folderIds = folders.map(folder => folder.entity_id)

      // 2. get all folder names to update contracts accordingly to folders
      const folderNames = folders.map(folder => folder.name)

      const contractIds = contractPool
        .filter(f => folderNames.find(name => name === f.attributes.name))
        .map(item => item.attributes)

      setUser(prev => ({ ...prev, contracts: contractIds }))

      // 3. get all sensorIds of folderIds
      let sensorIds = folderPool.filter(f =>
        folderIds.find(id => Number(id) === Number(f.id))
      )

      sensorIds = sensorIds.map(item => item.attributes.folder_sensors)
      sensorIds = Object.keys(sensorIds).reduce(
        (arr, key) => arr.concat(sensorIds[key]),
        []
      )
      sensorIds = sensorIds.map(item => item.sensor_id)
      sensorIds = [...new Set(sensorIds.sort((a, b) => a - b))]

      // 4. update state
      setSensorIdsInFolderPool(sensorIds)
      setUser(prev => ({ ...prev, sensorIds: sensorIds }))
    },
    [contractPool, folderPool, folders, sensorPool, setUser]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageCount,
  } = useTable(
    {
      columns: useMemo(
        () => [
          {
            accessor: 'entity_id',
            Header: 'Sensor ID',
            sortType: (a, b) =>
              Number(a.original.entity_id) - Number(b.original.entity_id),
          },
          {
            accessor: 'is_active',
            Cell: CellIsActive,
            disableFilters: true,
            disableSortBy: true,
            Header: 'aktiviert',
          },
          { accessor: 'name', Header: 'Name' },
          { accessor: 'device_id', Header: 'Device ID' },
          { accessor: 'folder_name', Cell: CellBoilerplate, Header: 'Gebäude' },
          { accessor: 'sensor_type', Header: 'Typ' },
          { accessor: 'provider', Header: 'Provider' },
          {
            accessor: 'status',
            Cell: CellStatus,
            filterMethod: (filter, row) => {
              if (filter.value === 'all') return true
              if (filter.value === 'green') return row[filter.id] === 'green'
              if (filter.value === 'yellow') return row[filter.id] === 'yellow'

              return row[filter.id] === 'red'
            },
            Filter: FilterStatus,
            Header: 'Status',
          },
          {
            accessor: 'targets',
            Cell: ({ row }) => {
              const { sensor_target, sub_sensor_target } = row.original

              return `${sensor_target}::${sub_sensor_target}`
            },
            disableFilters: true,
            disableSortBy: true,
            Header: 'Targets',
          },
          {
            Cell: CellAssignment,
            disableFilters: true,
            disableSortBy: true,
            Header: 'Zuweisung',
          },
        ],
        []
      ),
      data: useMemo(
        () => {
          const FILTER_ASSIGNED_SENSORS_ONLY = f =>
            assignedSensorsOnly ? sensors.find(id => id === Number(f.id)) : f

          const FILTER_IS_FOLDER_SENSOR = f =>
            folderSensorsPool.find(id => id === Number(f.id))

          const FILTER_IS_ACTIVE_SENSOR = f =>
            showActiveOnly ? f.attributes.is_active === 1 : f

          const FILTER_SEARCH = f =>
            filter
              ? isFilterMatch(filter, f.attributes.name) ||
                isFilterMatch(filter, f.attributes.device_id) ||
                isFilterMatch(filter, f.id)
              : f

          const result = sensorPool
            .filter(FILTER_IS_ACTIVE_SENSOR)
            .filter(FILTER_IS_FOLDER_SENSOR)
            .filter(FILTER_ASSIGNED_SENSORS_ONLY)
            .filter(f => sensorIdsInFolderPool.includes(Number(f.id)))
            .filter(FILTER_SEARCH)
            .sort(SORT_ATTR_NAME)
            .map(sensor => {
              const folder_name =
                folderPool.find(
                  folder => Number(folder.id) === sensor.attributes.folder_id
                )?.attributes?.name ?? '-'

              const is_selected = sensors.includes(
                Number(sensor.attributes.entity_id)
              )

              return { ...sensor.attributes, folder_name, is_selected }
            })

          return result
        },
        [
          sensorPool,
          folderPool,
          sensorIdsInFolderPool,
          sensors,
          assignedSensorsOnly,
          folderSensorsPool,
          filter,
          showActiveOnly,
        ]
      ),
      defaultColumn: useMemo(() => ({ Filter: DefaultColumnFilter }), []),
      filterTypes: useMemo(
        () => ({
          text: (rows, id, filterValue) =>
            matchSorter(rows, filterValue, { keys: [id] }),

          statusFilter: (rows, id, filterValue) => {
            if (filterValue === 'all') return rows
            return rows.filter(row => row.values[id] === filterValue)
          },
        }),
        []
      ),
      initialState: {
        sortBy: [{ id: config?.orderby, desc: config?.order !== 'ASC' }],
        filters: [{ id: 'folder_id', value: 'true' }],
        pageSize: config.maxResults ?? 10,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  // Handle row click (Auswahl)
  const handleRowClick = rowInfo => {
    const { entity_id } = rowInfo.original
    const selected = sensors.includes(Number(entity_id))

    setUser(prev => ({
      ...prev,
      sensors: !selected
        ? [...sensors, Number(entity_id)]
        : sensors.filter(f => f !== Number(entity_id)),
    }))
  }

  return (
    <Collapse in={!isSensorPoolLoading} sx={{ mb: 2 }}>
      <Accordion
        expanded={assignmentSection === 2}
        onChange={() => setConfig(prev => ({ ...prev, assignmentSection: 2 }))}
      >
        <AccordionSummary expandIcon={<ExpandMoreTwoTone />}>
          <Typography>
            Sensoren ({sensors.length}/{sensorIdsInFolderPool.length}) in{' '}
            {folders.length} Gebäude
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <ListSubheader>
            <Tooltip title={'Alle Sensoren entziehen'}>
              <IconButton
                onClick={() => setUser(prev => ({ ...prev, sensors: [] }))}
              >
                <RemoveCircleTwoTone />
              </IconButton>
            </Tooltip>

            <Tooltip title={'Alle Sensoren zuweisen'}>
              <IconButton
                onClick={() =>
                  setUser(prev => ({
                    ...prev,
                    sensors: sensorPool
                      .filter(f =>
                        sensorIdsInFolderPool.find(id => id === Number(f.id))
                      )
                      .map(item => Number(item.id)),
                  }))
                }
              >
                <AddCircleTwoTone />
              </IconButton>
            </Tooltip>

            <Tooltip title={'Nur zugewiesene'}>
              <IconButton
                sx={{ backgroundColor: assignedSensorsOnly ? '#eee' : 'none' }}
                onClick={() =>
                  setConfig(prev => ({
                    ...prev,
                    assignedSensorsOnly: !prev.assignedSensorsOnly,
                  }))
                }
              >
                <FilterListTwoTone />
              </IconButton>
            </Tooltip>
          </ListSubheader>

          <TableContainer sx={{ border: 'solid 1px #DDD', borderRadius: 2 }}>
            <Table sx={{ width: 1 }} {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup, key) => (
                  <>
                    <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, innerKey) => (
                        <TableCell
                          key={innerKey}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                        </TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      {headerGroup.headers.map((column, innerKey) => (
                        <TableCell
                          key={innerKey}
                          sx={{ p: 0 }}
                          {...column.getHeaderProps()}
                        >
                          {column.canFilter ? column.render('Filter') : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {page.map((row, key) => {
                  prepareRow(row)
                  return (
                    <TableRow
                      key={key}
                      onClick={() => handleRowClick(row)}
                      sx={{ cursor: 'pointer' }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell, innerKey) => (
                        <TableCell key={innerKey} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider sx={{ my: 2 }} />

          {/* Pagination Controls */}
          <HStack>
            <Button disabled={pageIndex === 0} onClick={() => previousPage()}>
              Zurück
            </Button>
            <Button
              disabled={pageIndex >= pageCount - 1}
              onClick={() => nextPage()}
            >
              Weiter
            </Button>

            <TablePagination
              component={'div'}
              count={pageCount}
              onPageChange={(event, newPage) => gotoPage(newPage)}
              onRowsPerPageChange={event =>
                setPageSize(Number(event.target.value))
              }
              page={pageIndex}
              rowsPerPage={Number(pageSize)}
              rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
            />
          </HStack>
        </AccordionDetails>
      </Accordion>
    </Collapse>
  )
}

UserSensorAssignments.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  config: PropTypes.object,
  setConfig: PropTypes.func,
}
