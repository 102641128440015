import { userConstants as UC } from '../constants'

const initial = {
  userRoles: { loading: true },
  userPool: { loading: true },
  userById: { loading: true },
  fileUpload: { fileUploading: true, loading: true },
  oneUser: { loading: true, isAdmin: false },
}

export const user = (state = initial, action) => {
  const { error, file, type, user, userById, userPool, userRoles } = action

  switch (type) {
    // delete user
    case UC.USER_DELETE_ERROR:
      return { ...state, userById: { error, loading: false } }

    case UC.USER_DELETE_REQUEST:
      return { ...state, userById: { loading: true } }

    case UC.USER_DELETE_SUCCESS:
      return { ...state, userById: { loading: true } }

    // get roles
    case UC.USER_GET_ALL_ROLE_ERROR:
      return { ...state, userRoles: { error, loading: true } }

    case UC.USER_GET_ALL_ROLE_REQUEST:
      return { ...state, userRoles: { loading: true } }

    case UC.USER_GET_ALL_ROLE_SUCCESS:
      return { ...state, userRoles: { items: userRoles, loading: false } }

    // get users
    case UC.USER_GET_ALL_USERS_ERROR:
      return {
        ...state,
        userPool: {
          error,
          loading: false,
        },
      }

    case UC.USER_GET_ALL_USERS_REQUEST:
      return {
        ...state,
        userPool: {
          loading: true,
        },
      }

    case UC.USER_GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        userPool: {
          items: userPool,
          loading: false,
          fetchDate: Date.now(),
        },
      }

    // get user
    case UC.USER_GET_BY_ID_ERROR:
      return { ...state, userById: { error, loading: true } }

    case UC.USER_GET_BY_ID_REQUEST:
      return { ...state, userById: { loading: true } }

    case UC.USER_GET_BY_ID_SUCCESS:
      return { ...state, userById: { item: userById, loading: false } }

    // get operator
    case UC.USER_GET_CURRENT_USER_ERROR:
      return {
        ...state,
        oneUser: { error, loading: true, isAdmin: false },
      }

    case UC.USER_GET_CURRENT_USER_REQUEST:
      return { ...state, oneUser: { loading: true, isAdmin: false } }

    case UC.USER_GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        oneUser: {
          item: user,
          loading: false,
          isAdmin:
            user &&
            (user.data.attributes.role_id === 1 ||
              user.data.attributes.role_id === 3),
        },
      }

    // add user
    case UC.USER_SAVE_ERROR:
      return { ...state, userById: { error, loading: true } }

    case UC.USER_SAVE_REQUEST:
      return { ...state, userById: { loading: true } }

    case UC.USER_SAVE_SUCCESS:
      return { ...state, userById: { loading: true } }

    // update user
    case UC.USER_UPDATE_ERROR:
      return { ...state, userById: { error, loading: true } }

    case UC.USER_UPDATE_REQUEST:
      return { ...state, userById: { loading: true } }

    case UC.USER_UPDATE_SUCCESS:
      return { ...state, userById: { loading: true } }

    // update operator
    case UC.USER_CURRENT_USER_UPDATE_ERROR:
      return {
        ...state,
        oneUser: { error, loading: true, isAdmin: false },
      }

    case UC.USER_CURRENT_USER_UPDATE_REQUEST:
      return { ...state, oneUser: { loading: true, isAdmin: false } }

    case UC.USER_CURRENT_USER_UPDATE_SUCCESS:
      return { ...state, oneUser: { loading: true, isAdmin: false } }

    // upload
    case UC.USER_DATA_UPLOAD_ERROR:
      return {
        ...state,
        fileUpload: { error, fileUploading: true, loading: true },
      }

    case UC.USER_DATA_UPLOAD_REQUEST:
      return { ...state, fileUpload: { fileUploading: true, loading: true } }

    case UC.USER_DATA_UPLOAD_SUCCESS:
      return {
        ...state,
        fileUpload: { fileUpload: file, fileUploading: false, loading: false },
      }

    // set users by sensor id
    case UC.USER_SET_SENSORID_BY_USERS_ERROR:
      return { ...state, sensorById: { error, loading: true } }

    case UC.USER_SET_SENSORID_BY_USERS_REQUEST:
      return { ...state, sensorById: { loading: true } }

    case UC.USER_SET_SENSORID_BY_USERS_SUCCESS:
      return { ...state, sensorById: { loading: false } }

    default:
      return state
  }
}
