// modules
import {
  AddTwoTone,
  InfoTwoTone,
  MoreVertTwoTone,
  NotificationsTwoTone,
  SearchTwoTone,
  HighlightOffTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// scripts
import { ASC, DESC, getStorage, isFilterMatch, setStorage } from '../../api'
import {
  AM,
  AMItem,
  HStack,
  KPage,
  KTextField,
  Preloader,
} from '../../components'
import { useFolderPool, useNotificationPool, useSensorPool } from '../../hooks'
import {
  NotificationPoolStat,
  NotificationSortSelector,
  NotificationTile,
} from './components'

export const NotificationAll = () => {
  const history = useHistory()

  const {
    data: folderPool,
    isLoading: isFolderPoolLoading,
    isError: isFolderPoolError,
  } = useFolderPool()

  const {
    data: notificationPool,
    isLoading: isNotificationPoolLoading,
  } = useNotificationPool()

  const { data: sensorPool, isLoading: isSensorPoolLoading } = useSensorPool()

  const PAGE_CONFIG = 'settings_notifications_all'
  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  const [actionMenu, setActionMenu] = useState(null)

  const [notificationList, setNotificationList] = useState([])

  const inputFilter = notification =>
    config.filter
      ? isFilterMatch(config.filter, notification.email) ||
        isFilterMatch(config.filter, notification.name) ||
        isFilterMatch(config.filter, notification.sensorName) ||
        Number(config.filter) === Number(notification.id)
      : notification

  const sortFilter = (a, b) => {
    const { order, orderby } = config

    if (
      orderby === 'name' &&
      a[orderby].toLowerCase() < b[orderby].toLowerCase()
    )
      return order === DESC ? 1 : -1

    if (orderby === 'id' && Number(a.id) < Number(b.id))
      return order === DESC ? 1 : -1

    return order === DESC ? -1 : 1
  }

  useEffect(() => setStorage(PAGE_CONFIG, config), [config])

  const isPageLoading =
    isFolderPoolLoading || isNotificationPoolLoading || isSensorPoolLoading

  useEffect(
    () => {
      if (!isPageLoading)
        setNotificationList(
          notificationPool.map(notification => {
            const {
              bcc,
              cc,
              email,
              email_topic,
              id,
              folder_id,
              consumption_type,
              time_sent,
              sensor_id,
              min_threshold,
              max_threshold,
              from_date,
              to_date,
              period,
              identifier,
            } = notification

            const folderObj = folderPool.find(
              f => Number(f.id) === Number(folder_id)
            )
            const { image_url, name } = folderObj.attributes

            const sensorObj = sensorPool.find(
              f => Number(f.id) === Number(consumption_type)
            )
            const sensorName = sensorObj
              ? sensorObj.attributes.name
              : 'namenlos'

            return {
              bcc,
              cc,
              email,
              email_topic,
              id,
              image_url,
              name,
              sensorName,
              time_sent,
              sensor_id,
              min_threshold,
              max_threshold,
              from_date,
              to_date,
              period,
              identifier,
            }
          })
        )
    },
    [isPageLoading, folderPool, notificationPool, sensorPool]
  )

  const changeOrderByFilter = (event, orderby) => {
    const { order } = config
    if (orderby !== null) setConfig(prev => ({ ...prev, orderby }))
    else {
      const newOrder = order === DESC ? ASC : DESC
      setConfig(prev => ({ ...prev, order: newOrder }))
    }
  }

  return (
    <>
      {/* preloader */}
      <Preloader error={isFolderPoolError} isLoading={isPageLoading} />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Benachrichtigungen'}
        open={Boolean(actionMenu)}
        onClose={() => setActionMenu(null)}
        historyUrlTarget={'notifications'}
      >
        <AMItem
          caption={'Neu'}
          icon={<AddTwoTone />}
          onClick={() => history.push('/notifications/new')}
        />

        <AMItem
          caption={'Info'}
          icon={<InfoTwoTone />}
          onClick={() => setConfig(prev => ({ ...prev, showStats: true }))}
        />
      </AM>
      {/* action menu end */}

      {/* pool stat */}
      <Dialog
        open={config.showStats || false}
        onClose={() => setConfig(prev => ({ ...prev, showStats: false }))}
      >
        <DialogTitle>Benachrichtigungen Pool Info</DialogTitle>
        <DialogContent>
          <NotificationPoolStat />
        </DialogContent>
      </Dialog>
      {/* pool stat end */}

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={event => setActionMenu(event.currentTarget)}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar>
              <NotificationsTwoTone />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={`Benachrichtigungen`}
        >
          <HStack justifyContent={'space-between'} sx={{ px: 1 }}>
            <Stack />
            <NotificationSortSelector
              onChange={changeOrderByFilter}
              order={config.order}
              orderby={config.orderby}
            />
          </HStack>

          {/* filter */}
          <KTextField
            icon={<SearchTwoTone />}
            onChange={event => {
              const filter = event.target.value

              setConfig(prev => ({ ...prev, filter }))
            }}
            unit={
              config.filter && (
                <Tooltip title={'Filter löschen'}>
                  <IconButton
                    onClick={() => setConfig(prev => ({ ...prev, filter: '' }))}
                  >
                    <HighlightOffTwoTone />
                  </IconButton>
                </Tooltip>
              )
            }
            value={config.filter}
          />

          {/* hit hint */}
          <Alert severity={'info'} sx={{ mx: 1 }}>
            <Stack direction={'row'}>
              <Typography>
                <strong>{notificationList.filter(inputFilter).length}</strong>{' '}
                Benachrichtigung(en) entsprechen dem Suchfilter{' '}
                <strong>
                  &quot;
                  {config.filter}
                  &quot;
                </strong>
              </Typography>
            </Stack>
          </Alert>

          {/* notificationlist */}
          <List
            sx={{
              m: 1,
              maxHeight: 200,
              minHeight: `calc(100vh - 560px)`,
              overflow: 'auto',
            }}
          >
            {notificationList
              .filter(inputFilter)
              .sort(sortFilter)
              .map((notification, key) => (
                <NotificationTile key={key} notification={notification} />
              ))}
          </List>
        </KPage>
      )}
    </>
  )
}
