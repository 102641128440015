import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import axios from 'axios'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { usePagination, useTable } from 'react-table'
import { sensorActions } from '../../actions'
import { authHeader } from '../../api'
import { KPage, KTextField, Preloader } from '../../components'

const { REACT_APP_API_URL } = process.env

const dataColumns = [
  {
    accessor: 'entity_id',
    filterAll: true,
    Header: 'entity_id',
    id: 'entity_id',
  },
  {
    accessor: 'sensor_id',
    filterAll: true,
    Header: 'Sensor ID',
    id: 'sensor_id',
  },
  {
    accessor: 'value',
    filterAll: true,
    Header: 'Wert',
    id: 'value',
  },
  {
    accessor: 'time',
    filterAll: true,
    Header: 'Zeit',
    id: 'time',
  },
  {
    accessor: 'timestamp',
    filterAll: true,
    Header: 'Zeitstempel',
    id: 'timestamp',
  },
  {
    accessor: 'timestamp_sensor',
    filterAll: true,
    Header: 'Zeitstempel Sensor',
    id: 'timestamp_sensor',
  },
]

export const RecalculateSensorData = () => {
  const dispatch = useDispatch()

  const defaultDataStructure = {
    data: {
      first_sensor_message: {},
      missing_data: [],
      second_sensor_message: {},
    },
  }

  const textFieldMaxWidth = '250px'

  const [sensorID, setSensorID] = useState('')
  const [entityID1, setEntityID1] = useState('')
  const [entityID2, setEntityID2] = useState('')
  const [timestep, setTimestep] = useState('')
  const [previewData, setPreviewData] = useState(defaultDataStructure)
  const [previewGenerated, setPreviewGenerated] = useState(false)
  const [tableModal, setTabeModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formatPreviewData = data => {
    const missing_data = data.data.missing_data.map((item, key) => ({
      ...item,
      entity_id: key + 1,
    }))

    missing_data.unshift(data.data.first_sensor_message)
    missing_data.push(data.data.second_sensor_message)

    return missing_data
  }

  const renderPreview = () => {
    const fetchCalculation = async (
      sensorID,
      entityID1,
      entityID2,
      timestep
    ) => {
      const config = {
        headers: authHeader(),
        method: 'GET',
        url: `${REACT_APP_API_URL}/iot/missing-data`,
        params: {
          sensor: sensorID,
          entity1: entityID1,
          entity2: entityID2,
          timestep: timestep,
        },
      }

      try {
        const response = await axios(config)
        const { data } = response

        if (data) {
          setPreviewData(data)
          setIsLoading(false)
          setTabeModal(true)
          setPreviewGenerated(true)
        }
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    }

    fetchCalculation(sensorID, entityID1, entityID2, timestep)
  }

  const enablePreviewButton = () =>
    sensorID !== '' && entityID1 !== '' && entityID2 !== '' && timestep !== ''

  const saveRecalculationStart = () =>
    dispatch(sensorActions.insertMissingSensorData(previewData.data))

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    //state: { pageSize },
  } = useTable(
    {
      columns: useMemo(() => dataColumns, []),
      data: useMemo(() => formatPreviewData(previewData), [previewData]),
      initialState: { pageSize: 50 },
    },
    usePagination
  )

  return (
    <>
      <Preloader isLoading={isLoading} />

      <KPage>
        <Typography component={'h4'} variant={'h4'} align={'center'}>
          Daten
        </Typography>

        <KTextField
          label={'Sensor ID'}
          onChange={event => {
            setSensorID(event.target.value)
            setPreviewGenerated(false)
          }}
          value={sensorID}
          type={'number'}
          style={{ maxWidth: textFieldMaxWidth }}
        />

        <KTextField
          label={'Entity ID 1'}
          onChange={event => {
            setEntityID1(event.target.value)
            setPreviewGenerated(false)
          }}
          value={entityID1}
          type={'number'}
          style={{ maxWidth: textFieldMaxWidth }}
        />

        <KTextField
          label={'Entity ID 2'}
          onChange={event => {
            setEntityID2(event.target.value)
            setPreviewGenerated(false)
          }}
          value={entityID2}
          type={'number'}
          style={{ maxWidth: textFieldMaxWidth }}
        />

        <KTextField
          label={'Zeitintervall'}
          onChange={event => {
            setTimestep(event.target.value)
            setPreviewGenerated(false)
          }}
          value={timestep}
          type={'number'}
          style={{ maxWidth: textFieldMaxWidth }}
        />

        <Button
          disabled={!enablePreviewButton()}
          onClick={() => {
            setPreviewData(defaultDataStructure)
            setIsLoading(true)
            renderPreview()
          }}
          sx={{ mr: '30px' }}
        >
          Vorschau
        </Button>

        <Button
          disabled={!previewGenerated}
          onClick={() => saveRecalculationStart()}
        >
          Start
        </Button>

        <Dialog
          open={tableModal}
          onClose={() => setTabeModal(false)}
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogTitle onClose={() => setTabeModal(false)}>
            <Typography align={'center'}>Vorschau</Typography>
          </DialogTitle>

          <DialogContent>
            <TableContainer sx={{ border: 'solid 1px #DDD', borderRadius: 2 }}>
              <Table {...getTableProps()} style={{ width: '100%' }}>
                <TableHead>
                  {headerGroups.map((headerGroup, key) => (
                    <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, innerKey) => (
                        <TableCell key={innerKey} {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>

                <TableBody {...getTableBodyProps()}>
                  {rows.map((row, key) => {
                    prepareRow(row)
                    return (
                      <TableRow key={key} {...row.getRowProps()}>
                        {row.cells.map((cell, innerKey) => (
                          <TableCell key={innerKey} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      </KPage>
    </>
  )
}
