import { sensorFactorConstants as SFC } from '../constants'

const initialState = { allSensorFactor: { loading: true } }

export const sensorFactor = (state = initialState, action) => {
  const { error, sensorfactor, type } = action

  switch (type) {
    case SFC.DELETE_SENSOR_FACTOR_REQUEST:
      return { ...state, deleteSensorFactor: { loading: true } }

    case SFC.DELETE_SENSOR_FACTOR_SUCCESS:
      return {
        ...state,
        deleteSensorFactor: { item: sensorfactor, loading: false },
      }

    case SFC.DELETE_SENSOR_FACTOR_ERROR:
      return {
        ...state,
        deleteSensorFactor: { error, loading: true },
      }

    case SFC.GET_ALL_REQUEST:
      return { ...state, allSensorFactor: { loading: true } }

    case SFC.GET_ALL_SUCCESS:
      return {
        ...state,
        allSensorFactor: { item: sensorfactor, loading: false },
      }

    case SFC.GET_ALL_ERROR:
      return {
        ...state,
        allSensorFactor: { error, loading: true },
      }

    case SFC.SAVE_SENSOR_FACTOR_REQUEST:
      return { ...state, save: { loading: true } }

    case SFC.SAVE_SENSOR_FACTOR_SUCCESS:
      return {
        ...state,
        save: { item: sensorfactor, loading: false },
      }

    case SFC.SAVE_SENSOR_FACTOR_ERROR:
      return {
        ...state,
        save: { error, loading: true },
      }

    default:
      return state
  }
}
