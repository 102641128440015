import { missingDataConstants as MDC } from '../constants'

const initialState = { allMissingData: { loading: true } }

export const missingData = (state = initialState, action) => {
  const { error, missingData, type } = action

  switch (type) {
    case MDC.GET_ALL_ERROR:
      return { ...state, allMissingData: { error, loading: true } }

    case MDC.GET_ALL_REQUEST:
      return { ...state, allMissingData: { loading: true } }

    case MDC.GET_ALL_SUCCESS:
      return { ...state, allMissingData: { item: missingData, loading: false } }

    case MDC.GET_BY_ID_ERROR:
      return { ...state, oneMissingData: { error, loading: true } }

    case MDC.GET_BY_ID_REQUEST:
      return { ...state, oneMissingData: { loading: true } }

    case MDC.GET_BY_ID_SUCCESS:
      return { ...state, oneMissingData: { item: missingData, loading: false } }

    default:
      return state
  }
}
