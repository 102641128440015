/*

testen, ob sensorIds teil der url werden können, um schnelleren aufruf zu ermöglichen, bzgl. gleich bei step 3 zu landen

const queryParams = new URLSearchParams({
  sensorIds: reportSensors,
}).toString()

history.push(`?${queryParams}`)

*/

import {
  ArrowLeftTwoTone,
  ArrowRightTwoTone,
  AssignmentTwoTone,
  MoreVertTwoTone,
} from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Avatar,
  Badge,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  IconButton,
  List,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { reportsActions, sensorFactorActions } from '../../actions'
import { categoryByType, getStorage, setStorage, userLocales } from '../../api'
import { AM, DialogReportResult, KPage, Preloader } from '../../components'
import {
  useFolderPool,
  useOperator,
  useSensorPool,
  useContractPool,
} from '../../hooks'
import {
  AboSection,
  FactorWarning,
  FolderSelector,
  HintSelectFolder,
  HintSelectSensor,
  Preflight,
  ReportNotAvailable,
  SensorItem,
  AdHoc,
} from './components'

export const ReportGenerator = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { allSensorFactor } = useSelector(state => state.sensorFactor)

  const theme = useTheme()
  const backgroundColor = theme.palette.success.main

  const borderColor = theme.palette.lightgray.dark
  const border = `solid 1px ${borderColor}`

  const { folder_id } = useParams()
  const { status } = useSelector(state => state.report)

  const PAGE_CONFIG = 'settings_report_generator'
  const [config, setConfig] = useState(getStorage(PAGE_CONFIG))
  const [actionMenu, showActionMenu] = useState(null)

  // stepper wizard
  const STEP1_FOLDER = 0
  const STEP2_SENSORS = 1
  const STEP3_MODE = 2

  const [step, setStep] = useState(STEP1_FOLDER)

  // STEP 1 (folder)
  const [folderId, setFolderId] = useState(!folder_id ? 0 : folder_id)
  const [folderName, setFolderName] = useState('')
  const [consumptionSensors, setConsumptionSensors] = useState([])

  // STEP 2 (sensors)
  const [reportSensors, setReportSensors] = useState([])

  // STEP 3 (mode: ad-hoc | abo)
  const MODE_ADHOC = 0
  const MODE_ABO = 1

  const [mode, setMode] = useState(MODE_ADHOC)

  // ad-hoc related
  const [reportDate, setReportDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  )

  const options = { month: 'long', year: 'numeric' }
  const period = reportDate.toLocaleString(userLocales, options)
  const [showDownload, setShowDownload] = useState(false)

  // contract related
  const [contractBegin, setContractBegin] = useState(null)
  const [contractExpire, setContractExpire] = useState(null)

  // abo related
  const [aboData, setAboData] = useState(null)
  const [recipientsList, setRecipientsList] = useState([])

  const [sensorFactors, setSensorFactors] = useState([])
  const [problematic, setProblematic] = useState([])
  const [newP, setNewP] = useState([])

  // #######################################
  // # hooks section (sort alphabetically) #
  // #######################################

  const {
    data: contractPool,
    isLoading: isContractPoolLoading,
    isError: isContractPoolError,
  } = useContractPool()

  const {
    data: folderPool,
    isLoading: isFolderPoolLoading,
    isError: isFolderPoolError,
  } = useFolderPool()

  const {
    data: operatorData,
    isLoading: operatorLoading,
    isError: operatorError,
  } = useOperator()

  const {
    data: sensorPool,
    isLoading: isSensorPoolLoading,
    isError: isSensorPoolError,
  } = useSensorPool()

  // ###################
  // # helpers section #
  // ###################

  const NO_FOLDER_SELECTED = Boolean(folderId === 0)
  const NO_CONSUMPTION_SENSORS = consumptionSensors.length === 0
  const NO_REPORT_SENSORS = reportSensors.length === 0
  const NO_ABO = aboData === null
  const NO_ABO_RECIPIENTS = recipientsList.length === 0

  const INVALID_FOLDERID = !folderPool.find(
    f => Number(f.id) === Number(folderId)
  )

  const isPageLoading =
    isFolderPoolLoading ||
    isSensorPoolLoading ||
    isContractPoolLoading ||
    operatorLoading

  const isPageError =
    isFolderPoolError ||
    isSensorPoolError ||
    isContractPoolError ||
    operatorError

  const isStep1 = step === STEP1_FOLDER
  const isStep2 = step === STEP2_SENSORS
  const isStep3 = step === STEP3_MODE

  const isAdHocMode = mode === MODE_ADHOC
  const isAboMode = mode === MODE_ABO

  // ###########################################
  // # functions section (sort alphabetically) #
  // ###########################################

  const generateSelected = list =>
    dispatch(
      reportsActions.generateSelected(
        folderId,
        reportDate.getMonth() + 1,
        reportDate.getFullYear(),
        list
      )
    )

  const gotoNextStep = () => setStep(prev => prev + 1)
  const gotoPrevStep = () => setStep(prev => prev - 1)

  const onChangeFolder = folderId => {
    setFolderId(folderId)
    history.push(`/reports/${folderId}`)
  }

  const onChangeTab = (event, tab) => {
    setMode(tab)
    setConfig(prev => ({ ...prev, tab }))
  }

  const onCloseActionMenu = () => showActionMenu(null)

  const onDeleteAbo = () => {
    dispatch(reportsActions.deleteReportSettings(folderId))
    setRecipientsList([])
    setAboData(null)
  }

  const onShowActionMenu = event => showActionMenu(event.currentTarget)

  const onUpdateAbo = () => {
    const create = () =>
      dispatch(
        reportsActions.createReportSettings(folderId, recipients, sensorIds)
      )
    const update = () =>
      dispatch(
        reportsActions.updateReportSettings(folderId, recipients, sensorIds)
      )

    const recipients = recipientsList
      .map(recipient => recipient.email)
      .join(',')
    const sensorIds = reportSensors.join(',')

    NO_ABO ? create() : update()
  }

  // ######################
  // # useEffects section #
  // ######################

  useEffect(() => setStorage(PAGE_CONFIG, config), [config])

  // update sensor factors on report sensors change
  useEffect(
    () => {
      const NO_REPORT_SENSORS = reportSensors.length === 0

      if (NO_REPORT_SENSORS || step !== STEP3_MODE) return

      reportSensors.forEach(sensor =>
        dispatch(sensorFactorActions.getAll(sensor))
      )
    },
    [dispatch, reportSensors, history, step]
  )

  // update sensor factors on allSensorFactor change
  useEffect(
    () => {
      if (!allSensorFactor?.item) return

      setSensorFactors(prev => [...prev, allSensorFactor.item.data])
      setProblematic([])
    },

    [allSensorFactor]
  )

  // update folder name on folder change
  useEffect(
    () => {
      const updateFolderName = (id, pool) => {
        const FILTER_ID = f => Number(f.id) === Number(id)
        const record = pool.find(FILTER_ID)
        if (!record) return

        setFolderName(record.attributes.name)
      }

      updateFolderName(folderId, folderPool)
    },
    [folderPool, folderId]
  )

  // todo: splitting sensor & abo preparation
  useEffect(
    () => {
      const NO_SENSORS = sensorPool.length === 0

      if (!operatorData || NO_SENSORS) return

      const SORTBY_TYPE_IN_FOLDER = (a, b) =>
        a.type_in_folder > b.type_in_folder ? 1 : -1
      const CONSUMPTION_SENSOR = f =>
        f.sensor_target === 'consumption' ||
        f.sensor_target === 'performance' ||
        categoryByType(f.sensor_type, f.type_in_folder) === 'performance'
      const CURRENT_FOLDER = f => f.folder_id === Number(folderId)
      const REMOVE_UNDEFINED = f => f !== undefined
      const FLATTEN = f => f.attributes

      const prepareSensors = () => {
        const { reports, sensors: sensorIds } = operatorData

        const prepareConsumptionSensors = sensorIds => {
          if (!sensorIds) return

          const sensors = [
            ...new Set(
              sensorIds.map(sensorId =>
                sensorPool.find(f => Number(f.id) === sensorId)
              )
            ),
          ]
            .filter(REMOVE_UNDEFINED)
            .map(FLATTEN)
            .filter(CURRENT_FOLDER)
            .filter(CONSUMPTION_SENSOR)
            .sort(SORTBY_TYPE_IN_FOLDER)

          setConsumptionSensors(sensors)
          setReportSensors(sensors.map(sensor => Number(sensor.entity_id)))
        }
        prepareConsumptionSensors(sensorIds)

        const prepareReports = reports => {
          if (!reports) return

          const record = reports.find(CURRENT_FOLDER)

          if (!record) {
            setAboData(null)
            return
          }

          setAboData(record)

          const recipients = record.mailto.split(',').map(email => ({ email }))
          setRecipientsList(recipients)

          setMode(MODE_ABO)
        }
        prepareReports(reports)

        setProblematic([])
      }

      prepareSensors()
    },
    [operatorData, sensorPool, folderId]
  )

  // update contract period on folder change
  useEffect(
    () => {
      const NO_CONTRACTS = contractPool.length === 0
      const UNKNOWN_FOLDER = folderName === ''

      const setContractPeriod = (name, pool) => {
        const FILTER_NAME = f => f.attributes.name === name
        const record = pool.find(FILTER_NAME)
        if (!record) return

        const begin = String(record?.attributes?.date_begin).split('-')
        const expire = String(record?.attributes?.date_expire).split('-')

        setContractBegin(new Date(begin[0], begin[1] - 1, begin[2]))
        setContractExpire(new Date(expire[0], expire[1] - 1, expire[2]))
      }

      if (UNKNOWN_FOLDER || NO_CONTRACTS) return

      setContractPeriod(folderName, contractPool)
    },
    [folderName, contractPool]
  )

  // if status includes the "https://"" we have a pdf download link: show that link
  useEffect(
    () => {
      if (!status?.message?.includes('https://')) return

      setShowDownload(true)
    },
    [status.message]
  )

  // hide pdf download link when report date changes or step changes
  useEffect(() => setShowDownload(false), [reportDate, step])

  // #############
  // # component #
  // #############

  return (
    <>
      {/* preloader */}
      <Preloader error={isPageError} isLoading={isPageLoading} />
      {/* preloader end */}

      {/* preloader */}
      <Preloader
        caption={'Bericht wird generiert ...'}
        error={status.error && status.error}
        isLoading={status.message === 'Bericht wird generiert...'}
      />
      {/* preloader end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Berichte'}
        open={Boolean(actionMenu)}
        onClose={onCloseActionMenu}
      >
        <Divider />
      </AM>
      {/* action menu end */}

      {!isPageLoading && (
        <KPage
          action={
            <IconButton onClick={onShowActionMenu}>
              <MoreVertTwoTone />
            </IconButton>
          }
          avatar={
            <Avatar>
              <AssignmentTwoTone />
            </Avatar>
          }
          isLoading={isPageLoading}
          title={'Berichte'}
        >
          <Stepper activeStep={step} orientation={'vertical'} sx={{ mb: 10 }}>
            {['Gebäude', 'Sensoren', 'Modus'].map((item, key) => (
              <Step key={key}>
                <StepLabel>
                  {item} {isStep1 && key === 0 && 'wählen ...'}
                  {isStep1 &&
                    key === 0 &&
                    (isFolderPoolLoading || isSensorPoolLoading) && (
                      <CircularProgress />
                    )}
                  {step > STEP1_FOLDER && key === 0 && folderName}
                  {isStep2 && key === 1 && 'wählen ...'}
                  {step > STEP1_FOLDER &&
                    key === 1 && (
                      <Chip
                        label={`${reportSensors.length} / ${
                          consumptionSensors.length
                        }`}
                      />
                    )}
                </StepLabel>

                <StepContent>
                  {/* Gebäude */}
                  {isStep1 && (
                    <Collapse in={isStep1} sx={{ my: 1 }}>
                      <Stack>
                        <FolderSelector
                          folderId={Number(folderId)}
                          onChange={onChangeFolder}
                        />

                        {!(isFolderPoolLoading || isSensorPoolLoading) && (
                          <Stack>
                            <Collapse
                              in={INVALID_FOLDERID && !NO_FOLDER_SELECTED}
                              sx={{ mx: 1, mb: 1 }}
                            >
                              <Alert severity={'error'}>
                                <AlertTitle>
                                  Berichterstellung nicht verfügbar
                                </AlertTitle>
                                Das Gebäude mit der ID{' '}
                                <strong>{folderId}</strong> wurde nicht
                                gefunden.
                              </Alert>
                            </Collapse>

                            <ReportNotAvailable
                              folderName={folderName}
                              condition={
                                !NO_FOLDER_SELECTED &&
                                NO_CONSUMPTION_SENSORS &&
                                !INVALID_FOLDERID
                              }
                            />

                            <Collapse in={NO_FOLDER_SELECTED}>
                              <HintSelectFolder />
                            </Collapse>
                          </Stack>
                        )}
                      </Stack>
                    </Collapse>
                  )}

                  {/* Sensoren */}
                  {isStep2 && (
                    <Collapse in={isStep2}>
                      {isSensorPoolLoading ? (
                        <CircularProgress />
                      ) : (
                        <Stack>
                          <List
                            sx={{
                              border,
                              borderRadius: '10px',
                              m: 1,
                              maxHeight: 200,
                              minHeight: `calc(100vh - 600px)`,
                              overflow: 'auto',
                            }}
                            value={folderId || ''}
                          >
                            {consumptionSensors.map((sensor, key) => {
                              const sensorId = Number(sensor.entity_id)
                              const isActive = reportSensors.includes(sensorId)

                              const onToggleSensor = id =>
                                setReportSensors(
                                  prev =>
                                    prev.includes(id)
                                      ? prev.filter(f => Number(f) !== id)
                                      : [...prev, id]
                                )

                              return (
                                <SensorItem
                                  isActive={isActive}
                                  isAdmin={operatorData?.isAdmin}
                                  key={key}
                                  onClick={() => onToggleSensor(sensorId)}
                                  sensor={sensor}
                                />
                              )
                            })}
                          </List>

                          <Collapse in={NO_REPORT_SENSORS}>
                            <HintSelectSensor />
                          </Collapse>
                        </Stack>
                      )}
                    </Collapse>
                  )}

                  {/* Modus */}
                  {isStep3 && (
                    <Collapse in={isStep3}>
                      {isContractPoolLoading ? (
                        <CircularProgress />
                      ) : (
                        <Stack>
                          <Tabs
                            centered
                            indicatorColor={'primary'}
                            onChange={onChangeTab}
                            sx={{ mb: 2 }}
                            textColor={'primary'}
                            value={mode}
                          >
                            <Tab label={'ad-hoc Report'} value={0} />
                            <Tab
                              label={
                                <Badge
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  badgeContent={aboData ? 'aktiv' : null}
                                  classes={{
                                    badge: { backgroundColor },
                                  }}
                                  color={'secondary'}
                                  overlap={'circular'}
                                >
                                  Abonnement
                                </Badge>
                              }
                              value={1}
                            />
                          </Tabs>

                          {/* ad-hoc */}
                          <Collapse in={isAdHocMode}>
                            <AdHoc
                              reportDate={reportDate}
                              contractBegin={contractBegin}
                              contractExpire={contractExpire}
                              onChange={date => setReportDate(date)}
                            />

                            <Collapse in={true}>
                              <Preflight
                                reportDate={reportDate}
                                sensors={reportSensors}
                                factors={sensorFactors}
                                onChange={problematic => setNewP(problematic)}
                              />
                            </Collapse>

                            {problematic?.map((sensorId, key) => (
                              <FactorWarning
                                key={key}
                                reportDate={reportDate}
                                sensorFactors={sensorFactors}
                                sensorId={sensorId}
                              />
                            ))}

                            {/* Status */}
                            <Collapse
                              in={
                                !isPageLoading &&
                                status !== undefined &&
                                !NO_FOLDER_SELECTED &&
                                step > STEP2_SENSORS
                              }
                              sx={{ mt: 5 }}
                            >
                              {status && (
                                <>
                                  {showDownload === true &&
                                  status.message &&
                                  status.message.includes('https://') ? (
                                    <DialogReportResult
                                      severity={'success'}
                                      status={status.message}
                                      title={`Ad-hoc Bericht für ${period} für ${folderName} erstellt.`}
                                    />
                                  ) : null}
                                  {status.error && (
                                    <DialogReportResult
                                      severity={'error'}
                                      status={status.error}
                                      title={`Report ${folderName} für ${period} konnte nicht erstellt werden. Bitte wenden Sie sich an Ihren Coach.`}
                                    />
                                  )}
                                </>
                              )}
                            </Collapse>
                          </Collapse>

                          {/* abonnement */}
                          <AboSection
                            data={recipientsList}
                            mode={mode}
                            setRecipientsList={setRecipientsList}
                          />
                        </Stack>
                      )}
                    </Collapse>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>

          <Container
            maxWidth={'xl'}
            sx={{ position: 'absolute', bottom: '100px', right: '60px' }}
          >
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <Button disabled={isStep1} onClick={gotoPrevStep}>
                <ArrowLeftTwoTone /> Zurück
              </Button>

              {step !== STEP3_MODE && (
                <Button
                  disabled={
                    NO_FOLDER_SELECTED ||
                    NO_CONSUMPTION_SENSORS ||
                    NO_REPORT_SENSORS ||
                    isStep3
                  }
                  onClick={gotoNextStep}
                  sx={{ ml: 1 }}
                >
                  Weiter <ArrowRightTwoTone />
                </Button>
              )}

              {isStep3 &&
                isAdHocMode && (
                  <Button
                    color={'success'}
                    disabled={
                      !reportDate ||
                      !folderId ||
                      NO_REPORT_SENSORS ||
                      newP.length === reportSensors.length
                    }
                    onClick={() => generateSelected(reportSensors)}
                    sx={{ ml: 1 }}
                  >
                    Generieren
                  </Button>
                )}

              {isStep3 &&
                isAboMode && (
                  <Button
                    color={'error'}
                    disabled={NO_ABO}
                    onClick={onDeleteAbo}
                    sx={{ ml: 1 }}
                  >
                    Abo kündigen
                  </Button>
                )}

              {isStep3 &&
                isAboMode && (
                  <Button
                    color={'success'}
                    disabled={NO_ABO_RECIPIENTS}
                    onClick={onUpdateAbo}
                    sx={{ ml: 1 }}
                  >
                    {NO_ABO ? 'Abonnieren' : 'Abo aktualisieren'}
                  </Button>
                )}
            </Stack>
          </Container>
        </KPage>
      )}
    </>
  )
}
