import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { authHeader } from '../api'
import { API } from 'aws-amplify'

export const usePanels = dashboardID => {
  const dispatch = useDispatch()

  const [data, setData] = useState(null)
  const [isError, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(
    () => {
      if (Number(dashboardID) > 0) {
        setLoading(true)
        const fetchPanels = async dashboardId => {
          let result = 'unset'

          const API_NAME = 'karmaApi'
          const endpoint = `/dashboard/${dashboardId}/panel/`
          const options = { method: 'GET', headers: authHeader() }

          result = await API.get(API_NAME, endpoint, options)
            .then(response => {
              return response
            })
            .catch(error => {
              const { response } = error
              const { data, status } = response
              const { message } = data
              if (status === 400 && message.includes('Bad Request')) {
                const sensor = message.split('for')[1].replace(/.$/, '')
                setLoading(false)
                return {
                  factorError: true,
                  sensor: sensor,
                }
              }
              setError(error)
            })
          if (result && result.data) {
            const modifiedData = result.data.map(panel => {
              return {
                ...panel,
              }
            })
            setData(modifiedData)
            setLoading(false)
          } else {
            setData([])
            setLoading(false)
          }
        }
        fetchPanels(dashboardID)
      }
    },
    [dispatch, dashboardID]
  )

  return { isLoading, isError, data }
}
