// modules
import { TroubleshootTwoTone } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
// scripts
import {
  getStatusColor,
  normalizedSensorType,
  SensorIcon,
  sensorType,
} from '../../../api'
import { ToggleDateType } from '../../../components'
import { IntervalAlert } from './'

export const RoomSensor = ({ data, onClick }) => {
  const theme = useTheme()
  const success = theme.palette.success.main
  const error = theme.palette.error.main

  const {
    is_active,
    sensor_type,
    status,
    sub_sensor_target,
    time,
    type_in_folder,
    value,
    unit,
  } = data

  const nst = normalizedSensorType(
    sensor_type,
    type_in_folder,
    sub_sensor_target
  )

  let image_url = SensorIcon(nst, status)
  if (!image_url)
    image_url = SensorIcon(
      type_in_folder,
      [error, success][is_active],
      sub_sensor_target
    )

  // human readable
  const hrType = sensorType(sensor_type, type_in_folder, sub_sensor_target)
  const hrUnit = unit ? unit : ''

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar src={image_url} width={80} />
      </ListItemAvatar>

      <ListItemText
        primary={hrType}
        secondary={<ToggleDateType date={new Date(Date.parse(time))} />}
        sx={{ width: 0.7 }}
      />

      <ListItemText
        disableTypography
        primary={
          <>
            <strong>{value}</strong>
            &nbsp;
            {hrUnit}
          </>
        }
        sx={{
          display: 'flex',
          fontWeight: 'bold',
          justifyContent: 'flex-end',
          mr: 2,
          width: 0.3,
        }}
      />

      <Divider flexItem orientation={'vertical'} sx={{ mr: 2 }} />

      <ListItemSecondaryAction>
        <Tooltip title={`Details ${hrType}`}>
          <IconButton onClick={onClick}>
            <TroubleshootTwoTone />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

RoomSensor.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
}

export const FeelingRoom = ({ folderId, title, sensors }) => {
  const history = useHistory()
  const roomSensors = [...new Set(sensors.filter(f => f.name === title))].sort()

  const avatar = (
    <Avatar>
      <Box
        alt={''}
        component={'img'}
        src={SensorIcon('feeling', getStatusColor(roomSensors))}
        sx={{ width: 40 }}
      />
    </Avatar>
  )

  return (
    <Card variant={'feeling'}>
      <CardHeader
        avatar={avatar}
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
        variant={'feeling'}
      />
      <CardContent sx={{ p: '0 !important' }}>
        {/* display interval alert, if applicable  */}
        <IntervalAlert title={title} sensors={sensors} />

        <List>
          {roomSensors.map((item, key) => {
            const {
              entity_id,
              sensor_type,
              sub_sensor_target,
              type_in_folder,
            } = item
            const nst = normalizedSensorType(
              sensor_type,
              type_in_folder,
              sub_sensor_target
            )
            const target = `/folder/${folderId}/feeling/${nst}/${entity_id}`

            return (
              <RoomSensor
                data={item}
                key={key}
                onClick={() => history.push(target)}
              />
            )
          })}
        </List>
      </CardContent>
    </Card>
  )
}

FeelingRoom.propTypes = {
  folderId: PropTypes.number,
  title: PropTypes.string,
  sensors: PropTypes.array,
}

export const DropDownFeelingItem = ({ label, icon, size = 25 }) => {
  return (
    <Stack direction={'row'}>
      <ListItemIcon>
        <Box alt={''} component={'img'} src={icon} sx={{ width: size }} />
      </ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </Stack>
  )
}

DropDownFeelingItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.number,
}
