import { API } from 'aws-amplify'
import axios from 'axios'
import { authHeader } from '../api'

const API_NAME = 'karmaApi'

const { REACT_APP_API_URL } = process.env

const getAll = async () => {
  const config = {
    headers: authHeader(),
    method: 'GET',
    url: `${REACT_APP_API_URL}/notification/admin/notification`,
  }

  try {
    const response = await axios(config)
    const { data } = response
    if (response) {
      const { status } = response
      if (status === 204) {
        return {
          data: [],
        }
      } else {
        return data
      }
    }
  } catch (error) {
    console.error('notificationService.getAll', error)
  }
}

const remove = async id => {
  const config = {
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    url: `${REACT_APP_API_URL}/notification/admin/notification/${id}`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('notificationService.remove', error)
  }
}

const save = async body => {
  const endpoint = '/notification/admin/notification'
  const options = {
    body,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    method: 'POST',
  }

  return await API.post(API_NAME, endpoint, options)
}

export const save2 = async body => {
  const config = {
    body,
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    method: 'POST',
    url: `${REACT_APP_API_URL}/notification/admin/notification`,
  }

  try {
    const response = await axios(config)
    const { data } = response

    return data
  } catch (error) {
    console.error('notificationService.save', error)
  }
}

export const notificationService = {
  getAll,
  remove,
  save,
}
