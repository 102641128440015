import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useFolder } from '../../../../hooks'

export const FromDateSelector = ({
  folderId,
  label = 'von',
  onChange,
  type = 'date',
  variant = 'standard',
  ...rest
}) => {
  const { data, isLoading } = useFolder(folderId)
  if (isLoading) return null

  const date_begin = data?.contract?.date_begin
  const date_expire = data?.contract?.date_expire

  // disable selection for future dates
  const limitNowAsMax = date =>
    date > new Date(Date.now()).toISOString().split('T')[0]
      ? new Date(Date.now()).toISOString().split('T')[0]
      : date

  // limit date selection to contract lifetime
  const boundaries = date =>
    date < date_begin ? date_begin : date > date_expire ? date_expire : date

  const sanitize = date => limitNowAsMax(boundaries(date))

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      inputProps={{
        onChange: event => {
          const result = event
          result.target.value = sanitize(event.target.value)

          onChange(result)
        },
        min: sanitize(date_begin),
        max: sanitize(date_expire),
      }}
      label={label}
      type={type}
      variant={variant}
      {...rest}
    />
  )
}

FromDateSelector.propTypes = {
  folderId: PropTypes.number.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  variant: PropTypes.string,
}
