import { DangerousTwoTone } from '@mui/icons-material'
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import { PropTypes } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { sensorType, SensorIcon, getStatusColor } from '../../../../api'
import { useSensorPool } from '../../../../hooks'

// filter

const ENERGY_SENSORS = f =>
  f.attributes.sensor_target === 'performance' &&
  (f.attributes.sub_sensor_target === 'energy' ||
    f.attributes.sub_sensor_target === 'energy_detail')

const WATER_SENSORS = f =>
  f.attributes.sensor_target === 'performance' &&
  (f.attributes.sub_sensor_target === 'water' ||
    f.attributes.sub_sensor_target === 'water_detail')

const GAS_SENSORS = f =>
  f.attributes.sensor_target === 'performance' &&
  (f.attributes.sub_sensor_target === 'gas' ||
    f.attributes.sub_sensor_target === 'gas_detail')

// component

export const MediumSelector = ({
  consumptionData,
  folderId,
  label = 'Energiemedium',
  size = 25,
  variant = 'standard',
  ...rest
}) => {
  const { data: sensorPool } = useSensorPool()

  const [mediumTypes, setMediumTypes] = useState([])
  const [groupStatus, setGroupStatus] = useState([])

  // exceptions
  const noMedia = mediumTypes.length === 0

  useEffect(
    () => {
      const folderSensors = sensorPool.filter(
        f => f.attributes.folder_id === folderId
      )

      const fsIds = folderSensors.map(sensor => Number(sensor.id))

      const mediumTypes = [
        ...new Set(
          consumptionData
            // remove consumptionData not assigned to operator sensors
            .filter(f => fsIds.includes(f.entity_id))

            // get sensor types in consumptionData for available medium types
            .map(sensor => sensor.type_in_folder)

            // merge main & sub sensor types (there is no distinction in UI)
            .map(sensor => sensor.replace('_detail', ''))
        ),
      ]

      setMediumTypes(mediumTypes)

      setGroupStatus(
        folderSensors.filter(f => fsIds.includes(f.attributes.entity_id))
      )
    },
    [consumptionData, folderId, sensorPool]
  )

  return (
    <TextField label={label} select variant={variant} {...rest}>
      {noMedia && (
        <MenuItem disabled>
          <Stack direction={'row'}>
            <ListItemIcon>
              <DangerousTwoTone color={'error'} />
            </ListItemIcon>

            <ListItemText>Kein Medium</ListItemText>
          </Stack>
        </MenuItem>
      )}

      {mediumTypes.map((medium, key) => {
        let status

        switch (medium) {
          case 'energy':
            status = groupStatus
              .filter(ENERGY_SENSORS)
              .map(sensor => ({ ...sensor, ...sensor.attributes }))
            break

          case 'water':
            status = groupStatus
              .filter(WATER_SENSORS)
              .map(sensor => ({ ...sensor, ...sensor.attributes }))
            break

          case 'gas':
            status = groupStatus
              .filter(GAS_SENSORS)
              .map(sensor => ({ ...sensor, ...sensor.attributes }))
            break

          default:
            console.error('unhandled medium:', medium)
            break
        }

        const color = getStatusColor(status)

        return (
          <MenuItem key={key} value={medium}>
            <Stack direction={'row'}>
              <ListItemIcon>
                <Box
                  component={'img'}
                  src={SensorIcon(medium, color)}
                  sx={{ width: size }}
                />
              </ListItemIcon>

              <ListItemText>{sensorType(medium)}</ListItemText>
            </Stack>
          </MenuItem>
        )
      })}
    </TextField>
  )
}

MediumSelector.propTypes = {
  consumptionData: PropTypes.array.isRequired,
  folderId: PropTypes.number.isRequired,
  label: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.string,
}
