import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  info: { backgroundColor: '#00D3EE' },
  primary: { backgroundColor: '#AF2CC5' },
  error: { backgroundColor: '#EB3573' },
  //error: { backgroundColor: '#F55A4E' },
  success: { backgroundColor: '#5CB860' },
  warning: { backgroundColor: '#FFA21A' },

  spacing: 10,

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },
        body: {
          backgroundColor: '#EEEEEE',
          color: '#3C4858',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontSize: '14px',
          fontWeight: 300,
          margin: 0,
          overflow: 'auto',
        },
        a: {
          color: '#9c27b0',
          textDecoration: 'none',
        },
        img: {
          border: 0,
          verticalAlign: 'middle',
        },
        'input[type="file"]': {
          display: 'none',
        },
        code: {
          backgroundColor: 'lightgray',
          padding: '3px',
          borderRadius: '5px',
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          // borderRadius: ({ shape }) => shape.borderRadius,
          borderRadius: 3,
          border: '1px solid',
        },
        standardSuccess: {
          borderColor: theme => theme.palette.success.main,
        },
        standardWarning: {
          borderColor: theme => theme.palette.warning.main,
        },
        standardError: {
          borderColor: theme => theme.palette.error.main,
        },
        standardInfo: {
          borderColor: theme => theme.palette.info.main,
        },
      },
    },

    MuiPaper: {
      defaultProps: { elevation: 5 },
    },

    MuiButton: {
      defaultProps: { variant: 'contained' },
      styleOverrides: { root: { borderRadius: 0 } },
    },

    MuiCard: {
      defaultProps: { elevation: 0 },
      styleOverrides: {},
      variants: [
        {
          props: { variant: 'performance' },
          style: ({ theme }) => ({
            backgroundColor: '#EEEEEE',
            border: 'solid 1px #DDD',
            //minHeight: '100%',
            marginBottom: theme.spacing(5),
          }),
        },
        {
          props: { variant: 'feeling' },
          style: () => ({
            backgroundColor: '#EEEEEE',
            border: 'solid 1px #DDD',
            minHeight: '100%',
          }),
        },
        {
          props: { variant: 'security' },
          style: () => ({
            backgroundColor: '#EEEEEE',
            border: 'solid 1px #DDD',
            minHeight: '100%',
          }),
        },
        {
          props: { variant: 'installation' },
          style: ({ theme }) => ({
            backgroundColor: '#EEEEEE',
            border: 'solid 1px #DDD',
            minHeight: '100%',
            marginBottom: theme.spacing(5),
          }),
        },
        {
          props: { variant: 'correlation' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            border: 'solid 1px #DDD',
            minHeight: '100%',
            marginBottom: theme.spacing(2),
          }),
        },
        {
          props: { variant: 'office_hours' },
          style: ({ theme }) => ({
            backgroundColor: '#D3D3D3',
            border: 'solid 1px #DDD',
            minHeight: '100%',
            marginBottom: theme.spacing(2),
          }),
        },
      ],
    },

    MuiCardHeader: {
      variants: [
        {
          props: { variant: 'dialog' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white,
          }),
        },
        {
          props: { variant: 'performance' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'feeling' },
          style: ({ theme }) => ({
            backgroundColor: '#D3D3D3',
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'security' },
          style: ({ theme }) => ({
            backgroundColor: '#D3D3D3',
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'correlation' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'installation_overview' },
          style: ({ theme }) => ({
            backgroundColor: '#D3D3D3',
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'installation' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
            borderBottom: 'solid 1px #B9B9B9',
          }),
        },
        {
          props: { variant: 'karma' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.color,
          }),
        },
      ],
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: '#4D0573',
          color: 'white',
        },
      },
      variants: [
        {
          props: { variant: 'preloader' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
          }),
        },
      ],
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: '#4D0573',
          color: 'white',
        },
      },
      variants: [
        {
          props: { variant: 'preloader' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white,
            color: theme.palette.black,
          }),
        },
      ],
    },

    MuiDialogContent: {
      variants: [
        {
          props: { variant: 'profilePicture' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.black,
            padding: 0,
          }),
        },
        {
          props: { variant: 'inline' },
          style: () => ({ margin: 1, padding: 1 }),
        },
      ],
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          /*
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.16)',
            },
          },
          */
        },
      },
    },

    MuiSelect: {
      defaultProps: { variant: 'outlined' },
      styleOverrides: { root: { margin: 10 } },
    },

    MuiTextField: {
      defaultProps: { variant: 'outlined' },
      styleOverrides: { root: { margin: 10 } },
    },

    MuiAccordion: {
      defaultProps: { elevation: 0 },
      styleOverrides: {
        root: {
          border: 'solid 1px #DDD',
          '&:before': { backgroundColor: '#707070' },
          '&:after': { backgroundColor: '#707070' },
        },
      },
    },
  },

  palette: {
    // dark mode
    mode: 'light',

    primary: { main: '#4D0573' },

    secondary: { main: '#FE58FA' },

    success: { main: '#9ECF67' },

    warning: { main: '#F8E71C' },

    error: { main: '#D0021B' },

    info: { main: '#8F8F8F' },

    correlation: { main: '#00AEF6' },

    lightgray: { main: '#DDDDDE', light: '#EEEEEE', dark: '#CBCBCC' },

    black: '#000',
    white: '#FFF',
  },

  props: {
    MuiButton: { color: 'primary' },

    MuiCheckbox: { color: 'primary' },

    MuiRadio: { color: 'primary' },

    MuiSelect: { color: 'primary' },

    MuiSwitch: { color: 'primary' },

    MuiTextField: { color: 'primary' },
  },

  /*
  shape: {
    borderRadius: 10,
  },
  */

  typography: {
    button: { textTransform: 'uppercase' },
  },
})
