import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { userConstants as UC } from '../../../constants'
import {
  AdminPanelSettingsTwoTone,
  EmojiPeopleTwoTone,
  PersonTwoTone,
  SchoolTwoTone,
} from '@mui/icons-material'

export const UserRoleSelector = ({
  data = [
    {
      icon: <EmojiPeopleTwoTone sx={{ mr: 1 }} />,
      label: UC.USER_ROLE_GUEST,
      value: UC.USER_ROLE_GUEST,
    },
    {
      icon: <PersonTwoTone sx={{ mr: 1 }} />,
      label: UC.USER_ROLE_USER,
      value: UC.USER_ROLE_USER,
    },
    {
      icon: <SchoolTwoTone sx={{ mr: 1 }} />,
      label: UC.USER_ROLE_COACH,
      value: UC.USER_ROLE_COACH,
    },
    {
      icon: <AdminPanelSettingsTwoTone sx={{ mr: 1 }} />,
      label: UC.USER_ROLE_ADMIN,
      value: UC.USER_ROLE_ADMIN,
    },
  ],
  onChange,
  value,
}) => (
  <ToggleButtonGroup onChange={onChange} value={value}>
    {data.map((role, key) => (
      <Tooltip title={`${role.label}`} key={key}>
        <ToggleButton key={key} value={role.value}>
          {role.icon} {role.label}
        </ToggleButton>
      </Tooltip>
    ))}
  </ToggleButtonGroup>
)

UserRoleSelector.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
}
