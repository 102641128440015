import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const HStack = ({
  alignItems = 'center',
  children,
  direction = 'row',
  spacing = 1,
  ...rest
}) => (
  <Stack
    alignItems={alignItems}
    direction={direction}
    spacing={spacing}
    {...rest}
  >
    {children}
  </Stack>
)

HStack.propTypes = {
  alignItems: PropTypes.string,
  children: PropTypes.node,
  direction: PropTypes.string,
  spacing: PropTypes.number,
}
