import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { VisibilityTwoTone, VisibilityOffTwoTone } from '@mui/icons-material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

export const KTextField = ({
  icon,
  max,
  min,
  readOnly,
  type,
  unit,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePassword = () => setShowPassword(!showPassword)
  const stopBubbling = event => event.stopPropagation()

  return (
    <FormControl fullWidth>
      <TextField
        InputProps={{
          startAdornment: icon && (
            <InputAdornment position={'start'}>{icon}</InputAdornment>
          ),

          endAdornment: (
            <InputAdornment position={'end'}>
              {type === 'password' && (
                <IconButton onClick={togglePassword} onMouseDown={stopBubbling}>
                  {showPassword ? (
                    <VisibilityTwoTone />
                  ) : (
                    <VisibilityOffTwoTone />
                  )}
                </IconButton>
              )}
              {unit && unit}
            </InputAdornment>
          ),
          readOnly,
          inputProps: { min, max },
        }}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        {...rest}
      />
    </FormControl>
  )
}

KTextField.propTypes = {
  icon: PropTypes.object,
  type: PropTypes.string,
  unit: PropTypes.any,
  readOnly: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
}
