import { CloseTwoTone } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DDMMYYYYHHMMSS, getRelativeDate, userLocales } from '../../../api'
import { useAlertsPool, useAppConfig, useSensorPool } from '../../../hooks'

export const DashboardAlerts = () => {
  const { global, setGlobal } = useAppConfig()
  const {
    alertsAcknowledged,
    alertsMax,
    mask,
    salutationMode,
    timeMode,
  } = global
  const { data, isError } = useAlertsPool(alertsMax, mask)

  const [operatorSensorIds, setOperatorSensorIds] = useState([])

  const { data: sensorPool } = useSensorPool()

  useEffect(
    () => {
      const ids = sensorPool.map(item => Number(item.id))

      setOperatorSensorIds(ids)
    },
    [sensorPool]
  )

  const statusToSeverity = status => {
    switch (status) {
      case 'green':
        return 'success'
      case 'yellow':
        return 'warning'
      case 'red':
        return 'error'
      default:
        console.error('unhandled statusToSeverity:', status)
        break
    }
  }

  return (
    <Box sx={{ my: 5 }}>
      <Typography
        component={'h5'}
        sx={{ textAlign: 'center', mb: 5 }}
        variant={'h5'}
      >
        In {salutationMode === 'default' ? 'Ihrer' : 'Deiner'} Abwesenheit gab
        es folgende relevante Ereignisse:
      </Typography>
      {data
        .filter(f => operatorSensorIds.includes(Number(f.attributes.sensor_id)))
        .slice(0, alertsMax)
        .map((alert, key) => {
          const { sensor_id, status, text, timestamp, title } = alert.attributes

          return (
            <Collapse in={!alertsAcknowledged.includes(alert.id)} key={key}>
              <Alert
                action={
                  <IconButton
                    onClick={() =>
                      setGlobal(prev => ({
                        ...prev,
                        alertsAcknowledged: [...alertsAcknowledged, alert.id],
                      }))
                    }
                  >
                    <CloseTwoTone />
                  </IconButton>
                }
                severity={statusToSeverity(status)}
                sx={{ mb: 2 }}
              >
                <AlertTitle>
                  <Link to={`/sensors/${sensor_id}`}>{title}</Link>
                </AlertTitle>

                <Typography>{text}</Typography>

                <Typography>
                  {timeMode === 'relative'
                    ? getRelativeDate(timestamp)
                    : new Date(timestamp).toLocaleDateString(
                        userLocales,
                        DDMMYYYYHHMMSS
                      )}
                </Typography>
              </Alert>
            </Collapse>
          )
        })}

      {data.slice(0, alertsMax).filter(f => !alertsAcknowledged.includes(f.id))
        .length === 0 && (
        <Alert severity={'success'}>
          <AlertTitle>Alles im grünen Bereich</AlertTitle>
          Aktuell liegen keine Alerts vor.
        </Alert>
      )}

      {isError && (
        <Alert severity={'error'}>
          <AlertTitle>Alerts konnten nicht geladen werden</AlertTitle>
          {String(isError)}
        </Alert>
      )}
    </Box>
  )
}
