// modules
import {
  FunctionsTwoTone,
  KeyboardDoubleArrowDownTwoTone,
  KeyboardDoubleArrowUpTwoTone,
  SettingsTwoTone,
  Star,
} from '@mui/icons-material'
import {
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
// scripts
import { germanDecimals, sensorUnit, userLocales } from '../../../api'

// 1000000 -> 1.000.000
const digitGrouping = digit =>
  Number(digit).toLocaleString(userLocales, germanDecimals)

export const ConsumptionTable = ({
  colors,
  cols,
  data,
  granularity,
  medium,
  roomsMode,
  ...rest
}) => {
  const [selectedSheet, selectSheet] = useState(0)
  const [open, setOpen] = useState(true)

  const headerData = [
    { align: 'left', label: 'Datum', accessor: 'date' },
    {
      align: 'right',
      label: 'IST-Verbrauch',
      accessor: 'actual_consumption',
    },
    { align: 'right', label: 'Trend', accessor: 'trend' },
    {
      align: 'right',
      label: 'CO₂-Emissionen',
      accessor: 'emissions',
    },
    {
      align: 'right',
      label: 'Verbrauch / m²',
      accessor: 'area_consumption',
    },
    {
      align: 'right',
      label: `Verbrauch / ${roomsMode}`,
      accessor: 'employee_consumption',
    },
    {
      align: 'right',
      label: 'IST-Kosten',
      accessor: 'actual_costs',
    },
    {
      align: 'right',
      label: 'Kosten / m²',
      accessor: 'area_costs',
    },
    {
      align: 'right',
      label: 'Benchmark',
      accessor: 'benchmark',
    },
    {
      align: 'right',
      label: 'Zählerstand',
      accessor: 'counter_reading',
    },
    {
      align: 'right',
      label: `Kosten / ${roomsMode}`,
      accessor: 'employee_costs',
    },
  ]

  const sheetNames = Object.keys(data)

  useEffect(
    () => {
      if (selectedSheet !== 0 && selectedSheet + 1 > sheetNames.length) {
        selectSheet(sheetNames.length - 1)
      }
    },
    [sheetNames, selectedSheet]
  )

  return (
    <Stack direction={'row'}>
      {sheetNames.map((item, key) => {
        return key === selectedSheet ? (
          <Paper key={key}>
            <Toolbar sx={{ backgroundColor: '#EEE' }}>
              <Stack
                alignItems={'center'}
                direction={'row'}
                justifyContent={'space-between'}
                sx={{ width: 1 }}
              >
                <Stack alignItems={'center'} direction={'row'}>
                  <IconButton onClick={() => setOpen(prev => !prev)}>
                    {open ? (
                      <KeyboardDoubleArrowDownTwoTone />
                    ) : (
                      <KeyboardDoubleArrowUpTwoTone />
                    )}
                  </IconButton>

                  <ToggleButtonGroup
                    exclusive
                    sx={{ ml: 2 }}
                    value={item || 'Gesamtverbrauch'}
                  >
                    {sheetNames.map((item, key) => {
                      const changeSheet = () => selectSheet(key)

                      return (
                        <ToggleButton
                          key={key}
                          onClick={changeSheet}
                          value={item}
                        >
                          <Tooltip title={item}>
                            {item === 'Gesamtverbrauch' ? (
                              <FunctionsTwoTone />
                            ) : (
                              <Star sx={{ color: colors[item] }} />
                            )}
                          </Tooltip>

                          {sheetNames.length <= 4 && (
                            <Typography sx={{ fontSize: 14, ml: 1 }}>
                              {item}
                            </Typography>
                          )}
                        </ToggleButton>
                      )
                    })}
                  </ToggleButtonGroup>

                  <Typography sx={{ fontSize: 18, fontWeight: 700, ml: 2 }}>
                    {item}
                  </Typography>

                  <Typography sx={{ fontSize: 18, fontWeight: 700, ml: 1 }}>
                    {granularity}
                  </Typography>
                </Stack>

                <IconButton {...rest}>
                  <SettingsTwoTone />
                </IconButton>
              </Stack>
            </Toolbar>

            <Divider />

            <Collapse in={open}>
              <TableContainer>
                <Table sx={{ width: '100%', tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      {headerData.map((cell, key) => {
                        const { accessor, align, label } = cell

                        return cols[accessor] ? (
                          <TableCell align={align} key={key}>
                            {label}
                          </TableCell>
                        ) : null
                      })}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data[item].map((row, key) => {
                      return (
                        <TableRow key={key}>
                          {[
                            {
                              accessor: 'date',
                              align: 'left',
                              sx: null,
                              value:
                                typeof row[0] === 'string'
                                  ? row[0]
                                  : new Date(row[0]).toLocaleString(
                                      userLocales,
                                      granularity === 'Stündlich'
                                        ? {
                                            weekday: 'short',
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                          }
                                        : granularity === 'Täglich'
                                          ? {
                                              weekday: 'short',
                                              day: '2-digit',
                                              month: '2-digit',
                                              year: '2-digit',
                                            }
                                          : granularity === 'Monatlich'
                                            ? {
                                                month: 'long',
                                                year: 'numeric',
                                              }
                                            : {
                                                weekday: 'short',
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: '2-digit',
                                              }
                                    ),
                            },
                            {
                              accessor: 'actual_consumption',
                              align: 'right',
                              sx: null,
                              value:
                                typeof row[1] !== 'string'
                                  ? `${digitGrouping(
                                      row[1].toFixed(2)
                                    )} ${sensorUnit(medium)}`
                                  : `${row[1]}`,
                            },
                            {
                              accessor: 'trend',
                              align: 'right',
                              sx: {
                                color: row[2].includes('▲')
                                  ? 'red'
                                  : row[2].includes('▼')
                                    ? 'green'
                                    : 'black',
                              },
                              value: row[2],
                            },
                            {
                              accessor: 'emissions',
                              align: 'right',
                              sx: null,
                              value: row[3],
                            },
                            {
                              accessor: 'area_consumption',
                              align: 'right',
                              sx: null,
                              value: row[4],
                            },
                            {
                              accessor: 'employee_consumption',
                              align: 'right',
                              sx: null,
                              value: row[5],
                            },
                            {
                              accessor: 'actual_costs',
                              align: 'right',
                              sx: null,
                              value: row[6],
                            },
                            {
                              accessor: 'area_costs',
                              align: 'right',
                              sx: null,
                              value: row[7],
                            },
                            {
                              accessor: 'benchmark',
                              align: 'right',
                              sx: {
                                color: row[8].includes('▲')
                                  ? 'red'
                                  : row[8].includes('▼')
                                    ? 'green'
                                    : 'black',
                              },
                              value: row[8],
                            },
                            {
                              accessor: 'counter_reading',
                              align: 'right',
                              sx: null,
                              value: row[9],
                            },
                            {
                              accessor: 'employee_costs',
                              align: 'right',
                              sx: null,
                              value: row[10],
                            },
                          ].map((cell, key) => {
                            const { accessor, align, sx, value } = cell

                            return cols[accessor] ? (
                              <TableCell align={align} key={key} sx={sx}>
                                {value}
                              </TableCell>
                            ) : null
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Paper>
        ) : (
          ''
        )
      })}
    </Stack>
  )
}

ConsumptionTable.propTypes = {
  colors: PropTypes.array.isRequired,
  cols: PropTypes.object,
  data: PropTypes.array.isRequired,
  granularity: PropTypes.string,
  medium: PropTypes.string,
  roomsMode: PropTypes.string,
}
