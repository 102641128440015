// modules
import {
  Avatar,
  AvatarGroup,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  useTheme,
} from '@mui/material'
import { PropTypes } from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
// scripts
import { DDMMYYHHMM, getInitials, userLocales } from '../../../api'
import { useUserPool } from '../../../hooks'

export const NotificationTile = ({ notification }) => {
  const theme = useTheme()
  const primary = theme.palette.primary.main

  const history = useHistory()
  const { data: userPool } = useUserPool()

  const {
    bcc,
    cc,
    email,
    email_topic,
    from_date,
    to_date,
    id,
    identifier,
    min_threshold,
    max_threshold,
    period,
    sensorName,
    sensor_id,
    image_url,
    name,
    time_sent,
  } = notification

  const SENT_AT = new Date(time_sent).toLocaleString(userLocales, DDMMYYHHMM)

  const bccAvatars = data => (
    <AvatarGroup>
      {data !== null &&
        data.split(',').map((item, key) => {
          let tooltip = item
          let image_url
          let caption = key + 1

          // IF email address in userPool, pimp avatar with picture and first + last name
          const record = userPool.find(
            f => f.attributes.email.toLowerCase() === String(item).toLowerCase()
          )
          if (record) {
            tooltip = `${record.attributes.firstname} ${
              record.attributes.lastname
            }`
            if (record.attributes.image_url) {
              image_url = record.attributes.image_url
            }
            caption = getInitials(tooltip)
          }

          return (
            <Tooltip key={key} title={tooltip}>
              <Avatar
                src={image_url}
                sx={{ bgcolor: record ? primary : '#666' }}
              >
                {caption}
              </Avatar>
            </Tooltip>
          )
        })}
    </AvatarGroup>
  )

  return (
    <ListItem button onClick={() => history.push(`/notifications/${id}/edit`)}>
      <ListItemAvatar>
        <Tooltip title={`Alert ID: ${id}`}>
          <Avatar src={image_url} width={80} />
        </Tooltip>
      </ListItemAvatar>
      <ListItemText primary={`Gebäude`} secondary={name} />
      <ListItemText primary={`Sensor: ${sensorName}`} secondary={sensor_id} />
      <ListItemText primary={`Topic`} secondary={email_topic} />
      <ListItemText primary={`Identifier`} secondary={identifier} />
      <ListItemText primary={`Period`} secondary={period} />
      <ListItemText
        primary={'Threshold'}
        secondary={`min:${min_threshold || ''} | max:${max_threshold || ''}`}
      />
      <ListItemText primary={'from'} secondary={from_date} />
      <ListItemText primary={'to'} secondary={to_date} />
      <ListItemText primary={'Empfänger'} secondary={bccAvatars(email)} />
      <ListItemText primary={`CC`} secondary={bccAvatars(cc)} />
      <ListItemText primary={`BCC`} secondary={bccAvatars(bcc)} />
      <ListItemText primary={`gesendet`} secondary={SENT_AT} />
    </ListItem>
  )
}

NotificationTile.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    bcc: PropTypes.string,
    cc: PropTypes.string,
    email: PropTypes.string,
    email_topic: PropTypes.string,
    from_date: PropTypes.string,
    to_date: PropTypes.string,
    min_threshold: PropTypes.string,
    max_threshold: PropTypes.string,
    identifier: PropTypes.string,
    image_url: PropTypes.string,
    name: PropTypes.string,
    period: PropTypes.string,
    sensorName: PropTypes.string,
    sensor_id: PropTypes.string,
    time_sent: PropTypes.string,
    percent: PropTypes.string,
  }),
}
