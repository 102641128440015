// modules
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  Switch,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import {
  AccountBalanceTwoTone,
  AddTwoTone,
  AttachMoneyTwoTone,
  CancelTwoTone,
  CheckTwoTone,
  DeleteTwoTone,
  EditLocationTwoTone,
  FaceTwoTone,
  GroupTwoTone,
  MemoryTwoTone,
  MoreVertTwoTone,
  PublishTwoTone,
  SaveTwoTone,
  ScheduleTwoTone,
} from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
// scripts
import {
  alertActions,
  contractActions,
  folderActions,
  sensorActions,
} from '../../actions'
import {
  categoryByType,
  isValidLength,
  normalizedSensorType,
  SensorIcon,
  sensorType,
  getInitials,
  getCorrelationStatusColorDetailPage,
  FILTER_ACTIVE_USER,
  FILTER_COACH_ROLE,
  FILTER_TYPE_SENSOR,
  FILTER_TYPE_USER,
  FILTER_TYPE_INSTALLATION,
  SORT_ATTR_FIRSTNAME,
  SORT,
} from '../../api'
import {
  AM,
  AMItem,
  KHeadline,
  KPage,
  KSelect,
  KTextField,
} from '../../components'
import { userConstants as UC } from '../../constants'
// hooks
import {
  useFolder,
  useFolderPool,
  useSensorPool,
  useUserPool,
} from '../../hooks'
// assets
import defaultImage from '../../assets/img/image_placeholder.jpg'

export const FolderEdit = () => {
  const theme = useTheme()
  const { white: color } = theme.palette

  const dispatch = useDispatch()
  const history = useHistory()
  const { id, section } = useParams()

  const { oneFolder } = useSelector(state => state.folder)

  const { data: folderPool } = useFolderPool()
  const {
    data: sensorPool,
    isError: isSensorPoolError,
    isLoading: isSensorPoolLoading,
  } = useSensorPool()
  const {
    data: userPool,
    isError: isUserPoolError,
    isLoading: isUserPoolLoading,
  } = useUserPool()

  const editMode = id ? true : false
  const hasFetchedFolderData = useRef(false)

  const [tab, setTab] = useState(Number(section) || 0)
  const [folderId, setFolderId] = useState('')
  const [folderSensors, setFolderSensors] = useState([])
  const [folderPushSensors, setFolderPushSensors] = useState([])
  const [folderUsers, setFolderUsers] = useState([])

  const [folder, setFolder] = useState({
    elevator: 1,
    escalator: 1,
    name: '',
    name_short: '',
    street: '',
    zip: '',
    city: '',
    country: '',
    countrySelect: null,
    latitude: '',
    longitude: '',
    parent_id: [],
    image_url: '',
    first_aid_kit: '',
    extinguisher: '',
    area_in_sqm: '',
    number_of_employees: '',
    usable_area: '',
    hired_out_area: '',
    temperature_controlled_area: '',
    none_temperature_controlled_area: '',
    rooms_mode: '',
    roomsModeSelect: null,
    kind_of_use: '',
    air_conditioning: '',
    pushSensors: [],
    target_value_annual_electricity_consumption: '',
    target_value_annual_gas_consumption: '',
    energy_benchmark: '',
    water_benchmark: '',
    gas_benchmark: '',
  })
  const [officeHours, setOfficeHours] = useState([
    { day_of_week: 1, start_time: '00:00:00', end_time: '00:00:00', closed: 1 },
    { day_of_week: 2, start_time: '00:00:00', end_time: '00:00:00', closed: 1 },
    { day_of_week: 3, start_time: '00:00:00', end_time: '00:00:00', closed: 1 },
    { day_of_week: 4, start_time: '00:00:00', end_time: '00:00:00', closed: 1 },
    { day_of_week: 5, start_time: '00:00:00', end_time: '00:00:00', closed: 1 },
    { day_of_week: 6, start_time: '00:00:00', end_time: '00:00:00', closed: 1 },
    { day_of_week: 7, start_time: '00:00:00', end_time: '00:00:00', closed: 1 },
  ])

  const [installations, setInstallations] = useState([])

  const [exceptionDate, setExceptionDate] = useState([])

  const [contractId, setContractId] = useState(null)

  const { oneSensor } = useSelector(state => state.sensor)

  const [contractData, setContractData] = useState({ isLoading: editMode })

  const [status, setStatus] = useState('')
  const [duration, setDuration] = useState(0)

  const [coachPool, setCoachPool] = useState([])

  const [providerList, setProviderList] = useState([])

  const [providerFilter, setProviderFilter] = useState('')
  const [sensorTypeFilter, setSensorTypeFilter] = useState('')
  const [deviceIdFilter, setDeviceIdFilter] = useState('')

  const [showModal, setModal] = useState(false)

  const [roleFilter, setRoleFilter] = useState('')
  const [displayFilter, setDisplayFilter] = useState('')

  // color status of categories
  const [colorStatus, setColorStatus] = useState([
    { type: 'performance', status: 'neutral' },
    { type: 'installation', status: 'neutral' },
    { type: 'correlation', status: 'neutral' },
    { type: 'security', status: 'neutral' },
    { type: 'feeling', status: 'neutral' },
  ])

  const getSeverity = status => {
    switch (status) {
      case 'aktiv':
        return 'success'

      case 'abgelaufen':
      case 'geplant':
        return 'warning'

      case 'ungültig':
        return 'error'

      default:
        return 'info'
    }
  }

  // some filters
  const selectedProvider = item =>
    Boolean(item.attributes.provider === providerFilter)

  const selectedDeviceId = item =>
    Boolean(
      deviceIdFilter !== ''
        ? item.attributes.device_id === deviceIdFilter
        : item
    )

  const selectedSensorType = item =>
    Boolean(
      sensorTypeFilter !== ''
        ? item.attributes.sensor_type === sensorTypeFilter
        : item
    )

  useEffect(
    () => {
      const { item, loading } = oneFolder
      if (editMode && !loading && hasFetchedFolderData.current) {
        const { data, included } = item
        const { attributes, id } = data
        const {
          city,
          country,
          elevator,
          escalator,
          image_url,
          latitude,
          longitude,
          name,
          name_short,
          parent_id,
          street,
          zip,
          area_in_sqm,
          number_of_employees,
          rooms_mode,
          usable_area,
          hired_out_area,
          temperature_controlled_area,
          none_temperature_controlled_area,
          kind_of_use,
          air_conditioning,
          target_value_annual_electricity_consumption,
          target_value_annual_gas_consumption,
          energy_benchmark,
          water_benchmark,
          gas_benchmark,
          sensor_group_status: sgs,
        } = attributes

        const _officeHours = []
        const exceptions = []
        const pushSensors = []

        included.map(item => {
          const {
            closed,
            date_exception,
            day_of_week,
            end_time,
            exception,
            name,
            start_time,
          } = item.attributes

          switch (item.type) {
            case 'office_hours':
              if (exception === 0)
                _officeHours.push({
                  date_exception,
                  day_of_week,
                  entity_id: item.id,
                  exception,
                  folder_id: id,
                  name,
                  start_time: start_time.padStart(8, '0'),
                  end_time: end_time.padStart(8, '0'),
                  closed,
                })
              else
                exceptions.push({
                  date_exception,
                  day_of_week,
                  entity_id: item.id,
                  exception,
                  folder_id: id,
                  name,
                  start_time: start_time.padStart(8, '0'),
                  end_time: end_time.padStart(8, '0'),
                  closed,
                })
              break

            default:
              break
          }

          return ''
        })

        const parentFolder = folderPool
          .filter(f => f.id === String(parent_id))
          .map(folder => ({
            value: folder.id,
            label: folder.attributes.name,
          }))

        const newOfficeHourState = _officeHours.map((offHour, day) => {
          const newoffHour = _officeHours.map(item => {
            const { closed, day_of_week, end_time, start_time } = item

            if (offHour.day_of_week === day_of_week) {
              return { day_of_week, start_time, end_time, closed }
            } else return offHour
          })

          if (typeof newoffHour[day] === 'undefined') return offHour

          return newoffHour[day]
        })

        const installations = included
          .filter(FILTER_TYPE_INSTALLATION)
          .map(installation => ({
            entity_id: installation.attributes.entity_id,
            name: installation.attributes.name,
          }))

        let countries = ''
        countries = require('i18n-iso-countries')
        countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

        const roomModes = mode => {
          switch (mode) {
            case 'rooms':
              return 'Zimmer'
            case 'employees':
              return 'Mitarbeiter'
            default:
              return 'Mitarbeiter'
          }
        }

        setFolderId(id)
        setFolder({
          elevator,
          escalator,
          name,
          name_short,
          street,
          zip,
          city,
          country,
          countrySelect: {
            value: country,
            label: countries.getName(country, 'de'),
          },
          latitude,
          longitude,
          parent_id:
            parentFolder.length > 0 && typeof parentFolder[0] !== 'undefined'
              ? parentFolder[0]
              : '',
          image_url,
          number_of_employees,
          rooms_mode: rooms_mode ? rooms_mode : 'employees',
          roomsModeSelect: {
            value: rooms_mode,
            label: roomModes(rooms_mode),
          },
          area_in_sqm,
          usable_area,
          hired_out_area,
          temperature_controlled_area,
          none_temperature_controlled_area,
          kind_of_use: kind_of_use ? kind_of_use : 'nicht definiert',
          air_conditioning,
          push_sensors: pushSensors,
          target_value_annual_electricity_consumption,
          target_value_annual_gas_consumption,
          energy_benchmark,
          water_benchmark,
          gas_benchmark,
        })

        if (sgs) {
          const performance = sgs.find(f => f.type === 'status_performance')
          const security = sgs.find(f => f.type === 'status_security')
          const feeling = sgs.find(f => f.type === 'status_well_being')
          const installations = sgs.find(f => f.type === 'status_installation')
          setColorStatus({
            performance: performance.status,
            security: security.status,
            feeling: feeling.status,
            correlation: getCorrelationStatusColorDetailPage(
              included.filter(FILTER_TYPE_SENSOR),
              folderId
            )
              ? 'green'
              : 'neutral',
            installation: installations.status,
          })
        }

        setOfficeHours(newOfficeHourState)
        //setOfficeHours(_officeHours)
        setExceptionDate(exceptions)

        setFolderSensors(included.filter(FILTER_TYPE_SENSOR))
        setFolderPushSensors(pushSensors)
        setFolderUsers(included.filter(FILTER_TYPE_USER))
        setInstallations(installations)
        setImagePreviewUrl(image_url || defaultImage)
      }
    },
    [id, editMode, folderPool, oneFolder, folderId]
  )

  useEffect(
    () => {
      if (!folderSensors[0]) return

      const { id } = folderSensors[0]

      dispatch(sensorActions.getById(id, ['contracts']))
    },
    [dispatch, folderSensors]
  )

  useEffect(
    () => {
      const { error, item, loading } = oneSensor

      if (loading === true || !item) return
      const { included } = item

      if (included) {
        const contract = included.find(f => f.type === 'contract')
        if (!contract) return

        setContractId(contract.id)
      }

      if (error) dispatch(alertActions.error(error))
    },
    [dispatch, oneSensor]
  )

  useEffect(
    () => {
      if (!editMode || hasFetchedFolderData.current) return
      hasFetchedFolderData.current = true
      dispatch(
        folderActions.getById(id, [
          'users',
          'office_hours',
          'exception',
          'news',
          'help_locations',
          'sensors',
          'push_sensors',
          'installations',
          'contracts',
        ])
      )
    },
    [dispatch, id, editMode]
  )

  const {
    data: folderData,
    isError: folderError,
    isLoading: folderLoading,
  } = useFolder(folderId)

  useEffect(
    () => {
      if (editMode && folderData && hasFetchedFolderData.current)
        setContractData({ ...folderData.contract, isLoading: folderLoading })

      if (folderError) dispatch(alertActions.error(`Contract ${folderError}`))
    },
    [dispatch, folderData, folderLoading, folderError, editMode]
  )

  useEffect(
    () => {
      if (!isUserPoolLoading && userPool)
        setCoachPool(userPool.filter(FILTER_COACH_ROLE))

      if (isUserPoolError)
        dispatch(alertActions.error(`Users ${isUserPoolError}`))
    },
    [dispatch, userPool, isUserPoolError, isUserPoolLoading]
  )

  useEffect(
    () => {
      if (!isSensorPoolLoading && sensorPool)
        setProviderList(
          [...new Set(sensorPool.map(i => i.attributes.provider))].sort()
        )

      if (isSensorPoolError)
        dispatch(alertActions.error(`Sensors ${isSensorPoolError}`))
    },
    [dispatch, sensorPool, isSensorPoolLoading, isSensorPoolError]
  )

  useEffect(
    () => {
      if (!folderData?.contract) return

      const date_begin = folderData?.contract?.date_begin
      const date_expire = folderData?.contract?.date_expire
      const isValid = date => date instanceof Date && !isNaN(date)

      const BEGIN = new Date(date_begin)
      const EXPIRE = new Date(date_expire)
      const NOW = Date.now()
      let status

      if (NOW < BEGIN) status = 'geplant'
      if (NOW > EXPIRE) status = 'abgelaufen'
      if (NOW > BEGIN && NOW < EXPIRE) status = 'aktiv'
      if (BEGIN > EXPIRE) status = 'ungültig'
      if (!isValid(BEGIN) || !isValid(EXPIRE)) status = 'ungültig'

      setStatus(status)
      setDuration((EXPIRE.getTime() - BEGIN.getTime()) / (24 * 60 * 60 * 1000))
    },
    [folderData]
  )

  const onSubmit = action => {
    const record = {
      exceptionDate: exceptionDate,
      folder: {
        ...folder,
        sensor_id: folderSensors.map(item => ({ value: item.id })),
        user_id: folderUsers.map(item => ({ value: item.id })),
        push_sensors: folderPushSensors,
        folder_installations: installations,
      },
      folderId: folderId,
      officeHours: officeHours,
    }

    const {
      date_begin,
      date_expire,
      description,
      name,
      primary_coach_id,
    } = contractData

    const contractRecord = {
      name,
      description,
      date_begin,
      date_expire,
      primary_coach_id: +primary_coach_id,
      sensor_id: folderSensors.map(item => ({ value: item.id })),
      user_id: folderUsers.map(item => ({ value: item.id })),
    }

    switch (action) {
      case 'save':
        dispatch(folderActions.save(record, contractRecord))
        break

      case 'update':
        dispatch(folderActions.update(record))
        dispatch(contractActions.update(contractId, contractRecord))
        break

      case 'delete':
        dispatch(folderActions.remove(record))
        dispatch(contractActions.remove(contractId))
        break

      default:
        break
    }
  }

  const [actionMenu, setActionMenu] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [openProfilePicture, setOpenProfilePicture] = useState(false)

  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    folder.image_url || defaultImage
  )

  const deleteInstallation = (item, key) => {
    const newInstallation = installations.slice()
    newInstallation[key]['delete_installation'] = 1
    setInstallations(newInstallation)
  }

  const changeOfficeHours = (record, key) => {
    let _officeHours = [...officeHours, (officeHours[key] = record)]
    _officeHours = [
      ...new Map(_officeHours.map(item => [item.day_of_week, item])).values(),
    ]
    setOfficeHours(_officeHours)
  }

  let ref = useRef(null)

  const isPageLoading = isUserPoolLoading || (editMode && oneFolder.loading)
  if (isPageLoading) return <Box />

  const NO_SENSOR_IN_OBJECT = !oneFolder.loading && folderSensors.length === 0
  return (
    <>
      {/* profile picture */}
      <Dialog
        open={openProfilePicture}
        onClose={() => setOpenProfilePicture(false)}
      >
        <DialogTitle>Profilbild von {folder.name}</DialogTitle>

        <DialogContent variant={'profilePicture'}>
          <Box alt={''} component={'img'} src={imagePreviewUrl} />
        </DialogContent>

        <DialogActions>
          <Tooltip title={'Profilbild hochladen'}>
            <IconButton onClick={() => ref.click()}>
              <PublishTwoTone />
            </IconButton>
          </Tooltip>

          <input
            accept={'image/*'}
            hidden
            onChange={event => {
              const image_url = event.target.files[0]

              event.preventDefault()

              const reader = new FileReader()
              reader.readAsDataURL(image_url)
              reader.onloadend = () => {
                setFolder(prev => ({ ...prev, image_url }))
                setImagePreviewUrl(reader.result)
              }
            }}
            ref={r => (ref = r)}
            type={'file'}
          />
          <Tooltip title={'Profilbild löschen'}>
            <span>
              <IconButton
                disabled={!folder.image_url && folder.image_url === ''}
                onClick={() => {
                  setImagePreviewUrl(defaultImage)
                  setFolder(prev => ({ ...prev, image_url: defaultImage }))
                  ref.value = null
                }}
              >
                <DeleteTwoTone />
              </IconButton>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
      {/* profile picture end */}

      {/* delete confirmation */}
      <Dialog
        maxWidth={'md'}
        onClose={() => setConfirmDialog(false)}
        open={confirmDialog}
      >
        <DialogTitle>
          Löschen von Gebäude ID:
          {id} bestätigen
        </DialogTitle>
        <DialogContent />
        <DialogActions>
          <Button autoFocus onClick={() => setConfirmDialog(false)}>
            Abbrechen
          </Button>
          <Button onClick={() => onSubmit('delete')}>Löschen</Button>
        </DialogActions>
      </Dialog>

      {/* add sensor */}
      <Dialog
        fullWidth={true}
        onClose={() => setModal(!showModal)}
        open={showModal}
      >
        <DialogTitle>Sensor hinzufügen</DialogTitle>
        <DialogContent>
          <KSelect
            icon={<MemoryTwoTone />}
            label={'Provider'}
            onChange={event => setProviderFilter(event.target.value)}
            value={providerFilter}
          >
            <MenuItem value={''}>Provider wählen ...</MenuItem>
            {providerList.map((item, key) => (
              <MenuItem key={key} value={item}>
                {item}
              </MenuItem>
            ))}
          </KSelect>

          <KSelect
            disabled={providerFilter === ''}
            icon={<MemoryTwoTone />}
            label={'Device ID'}
            onChange={event => setDeviceIdFilter(event.target.value)}
            value={deviceIdFilter}
          >
            <MenuItem value={''}>Device ID wählen ...</MenuItem>
            {[
              ...new Set(
                sensorPool
                  .filter(selectedProvider)
                  .filter(selectedSensorType)
                  .map(item => item.attributes.device_id)
              ),
            ]
              .sort()
              .map((item, key) => {
                return (
                  <MenuItem key={key} value={item}>
                    {item}
                  </MenuItem>
                )
              })}
          </KSelect>

          <KSelect
            disabled={providerFilter === ''}
            icon={<MemoryTwoTone />}
            label={'Sensor Typ'}
            onChange={event => setSensorTypeFilter(event.target.value)}
            value={sensorTypeFilter}
          >
            <MenuItem value={''}>Sensor Typ wählen ...</MenuItem>
            {[
              ...new Set(
                sensorPool
                  .filter(selectedProvider)
                  .filter(selectedDeviceId)
                  .map(item => item.attributes.sensor_type)
              ),
            ]
              .sort()
              .map((item, key) => (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              ))}
          </KSelect>

          <KSelect
            disabled={providerFilter === ''}
            icon={<MemoryTwoTone />}
            label={'Sensor'}
            onChange={event =>
              setFolderSensors(prev => [...prev, event.target.value])
            }
            value={folderSensors}
          >
            <MenuItem disabled>Sensor wählen ...</MenuItem>
            {sensorPool
              //.filter(FILTER_ACTIVE_SENSOR)
              .filter(selectedProvider)
              .filter(selectedSensorType)
              .filter(selectedDeviceId)
              .map((sensor, key) => {
                const {
                  device_id,
                  is_active,
                  name,
                  provider,
                  sensor_type,
                } = sensor.attributes

                return (
                  folderSensors
                    .map(item => Number(item.id))
                    .filter(f => f !== Number(sensor.id)) && (
                    <MenuItem
                      key={key}
                      value={sensor}
                      disabled={is_active === 0}
                    >
                      [{provider} :: {sensor_type} :: {device_id} :: {name}]
                    </MenuItem>
                  )
                )
              })}
          </KSelect>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setModal(false)} sx={{ color }}>
            <CheckTwoTone />
          </IconButton>
        </DialogActions>
      </Dialog>
      {/* add sensor end */}

      {/* action menu */}
      <AM
        anchorEl={actionMenu}
        caption={'Gebäude'}
        open={Boolean(actionMenu)}
        onClose={() => setActionMenu(null)}
        historyUrlTarget={'folder'}
      >
        <AMItem
          caption={'Neu'}
          icon={<AddTwoTone />}
          onClick={() => history.push('/folder/new')}
        />

        <AMItem
          caption={'Speichern'}
          disabled={
            status === 'ungültig' ||
            !folder.name ||
            !contractData.primary_coach_id
          }
          icon={<SaveTwoTone />}
          onClick={() => onSubmit(!editMode ? 'save' : 'update')}
        />

        <AMItem
          caption={'Löschen'}
          disabled={status === 'ungültig' || !folder.name}
          icon={<DeleteTwoTone />}
          onClick={() => setConfirmDialog(true)}
        />
        <Divider />
        <AMItem
          caption={'Leistung'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('performance', colorStatus.performance)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/performance`)}
        />
        <AMItem
          caption={'Anlagen'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('installation', colorStatus.installation)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/installation`)}
        />
        <AMItem
          caption={'Korrelation'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('correlation', colorStatus.correlation)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/correlation`)}
        />
        <AMItem
          caption={'Sicherheit'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('security', colorStatus.security)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/security`)}
        />
        <AMItem
          caption={'Wohlbefinden'}
          icon={
            <Box
              alt={''}
              component={'img'}
              src={SensorIcon('feeling', colorStatus.feeling)}
              sx={{ width: 25 }}
            />
          }
          onClick={() => history.push(`/folder/${folderId}/home/feeling`)}
        />
      </AM>
      {/* action menu end */}

      <KPage
        avatar={
          <Avatar
            onClick={() => setOpenProfilePicture(true)}
            src={folder.image_url ? String(folder.image_url) : defaultImage}
            sx={{ cursor: 'pointer' }}
          >
            <AccountBalanceTwoTone />
          </Avatar>
        }
        action={
          <IconButton onClick={event => setActionMenu(event.currentTarget)}>
            <MoreVertTwoTone />
          </IconButton>
        }
        isLoading={isPageLoading}
        subheader={
          !editMode
            ? 'Neues Gebäude'
            : !folder.isLoading
              ? `ID:${folderId}`
              : 'Gebäude laden'
        }
        title={`${folder.name}`}
      >
        <Tabs
          centered
          indicatorColor={'primary'}
          onChange={(event, newTab) => {
            setTab(newTab)
            /* history.push(`/folder/${editMode ? id : 'new'}/edit/${newTab}`) */
          }}
          sx={{ mt: 5, mb: 5 }}
          textColor={'primary'}
          value={tab}
        >
          <Tab
            disabled={oneFolder.loading && editMode && !NO_SENSOR_IN_OBJECT}
            icon={
              oneFolder.loading && editMode ? (
                <CircularProgress size={20} />
              ) : (
                <AccountBalanceTwoTone />
              )
            }
            label={'Stammdaten'}
          />
          <Tab
            disabled={isSensorPoolLoading}
            icon={
              isSensorPoolLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Badge
                  badgeContent={
                    !oneFolder.loading ? String(folderSensors.length) : null
                  }
                  color={'secondary'}
                  overlap={'circular'}
                >
                  <MemoryTwoTone />
                </Badge>
              )
            }
            label={'Sensoren'}
          />
          <Tab
            disabled={isUserPoolLoading}
            icon={
              isUserPoolLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Badge
                  badgeContent={
                    !oneFolder.loading ? String(folderUsers.length) : null
                  }
                  color={'secondary'}
                  overlap={'circular'}
                >
                  <GroupTwoTone />
                </Badge>
              )
            }
            label={'Benutzer'}
          />
          <Tab
            disabled={oneFolder.loading}
            icon={
              editMode && oneFolder.loading ? (
                <CircularProgress size={20} />
              ) : (
                <Badge
                  badgeContent={
                    officeHours
                      ? String(officeHours.filter(f => f.closed === 0).length)
                      : null
                  }
                  color={'secondary'}
                  overlap={'circular'}
                >
                  <ScheduleTwoTone />
                </Badge>
              )
            }
            label={'Öffnungszeiten'}
          />
          <Tab label={'Benchmarks'} icon={<AttachMoneyTwoTone />} />
        </Tabs>

        {/* Stammdaten */}
        <Collapse in={tab === 0}>
          {NO_SENSOR_IN_OBJECT && (
            <Alert severity={'warning'}>
              Bitte mindestens 1 Sensor im Gebäude speichern, um mit der
              Konfiguration fortzufahren.
            </Alert>
          )}
          {editMode && oneFolder.loading ? (
            <LinearProgress />
          ) : (
            <>
              <KHeadline>Meta</KHeadline>

              <KTextField
                error={!isValidLength(folder.name, 3)}
                label={'Name'}
                onChange={event => {
                  const name = event.target.value

                  setFolder(prev => ({ ...prev, name }))
                  setContractData(prev => ({ ...prev, name }))
                }}
                type={'text'}
                value={folder.name || ''}
              />

              <KTextField
                label={'Kurzname'}
                onChange={event => {
                  const name_short = event.target.value

                  setFolder(prev => ({ ...prev, name_short }))
                }}
                type={'text'}
                value={folder.name_short || ''}
              />

              <KSelect
                icon={<AccountBalanceTwoTone />}
                label={`übergeordnetes Gebäude (ID:${folder.parent_id.value ||
                  ''})`}
                onChange={event => {
                  const parent_id = event.target.value

                  setFolder(prev => ({ ...prev, parent_id }))
                }}
                value={folder.parent_id.value || ''}
              >
                <MenuItem value={''}>Kein übergeordnetes Gebäude</MenuItem>
                {folderPool.filter(f => f.id !== id).map((item, key) => {
                  const { name } = item.attributes

                  return (
                    <MenuItem key={key} value={item.id}>
                      {name}
                    </MenuItem>
                  )
                })}
              </KSelect>

              <KHeadline>Coach</KHeadline>

              <KSelect
                error={!contractData.primary_coach_id}
                icon={<FaceTwoTone />}
                label={`(ID:${contractData.primary_coach_id ||
                  'Kein Coach gewählt'})`}
                onChange={event => {
                  const primary_coach_id = event.target.value

                  setContractData(prev => ({ ...prev, primary_coach_id }))
                }}
                value={contractData.primary_coach_id}
              >
                {coachPool.filter(FILTER_ACTIVE_USER).map((item, key) => {
                  const { firstname, lastname, is_active } = item.attributes
                  const USER_DEACTIVATED = ' (deaktiviert)'

                  return (
                    <MenuItem
                      disabled={is_active === 0}
                      key={key}
                      value={item.id}
                    >
                      {firstname} {lastname}{' '}
                      {is_active === 0 && USER_DEACTIVATED}
                    </MenuItem>
                  )
                })}
              </KSelect>

              <KHeadline>Notizen</KHeadline>

              <KTextField
                label={'Notizen'}
                multiline
                onChange={event => {
                  const description = event.target.value

                  setContractData(prev => ({ ...prev, description }))
                }}
                minRows={5}
                value={contractData.description || ''}
              />

              <KHeadline>Gebäudebestandteile</KHeadline>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={folder.elevator === 1}
                    onClick={() => {
                      const elevator = folder.elevator === 0 ? 1 : 0

                      setFolder(prev => ({ ...prev, elevator }))
                    }}
                  />
                }
                label={'Aufzug'}
                labelPlacement={'start'}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={folder.escalator === 1}
                    onClick={() => {
                      const escalator = folder.escalator === 0 ? 1 : 0

                      setFolder(prev => ({ ...prev, escalator }))
                    }}
                  />
                }
                label={'Rolltreppe'}
                labelPlacement={'start'}
              />

              <KHeadline>Adresse</KHeadline>

              <KTextField
                icon={<EditLocationTwoTone />}
                label={'Anschrift'}
                onChange={event => {
                  const street = event.target.value

                  setFolder(prev => ({ ...prev, street }))
                }}
                type={'text'}
                value={folder.street}
              />

              <KTextField
                icon={<EditLocationTwoTone />}
                label={'PLZ'}
                onChange={event => {
                  const zip = event.target.value

                  setFolder(prev => ({ ...prev, zip }))
                }}
                type={'number'}
                value={folder.zip}
              />

              <KTextField
                icon={<EditLocationTwoTone />}
                label={'Ort'}
                onChange={event => {
                  const city = event.target.value

                  setFolder(prev => ({ ...prev, city }))
                }}
                type={'text'}
                value={folder.city}
              />

              <KSelect
                icon={<EditLocationTwoTone />}
                label={'Land'}
                onChange={event => {
                  const country = event.target.value

                  setFolder(prev => ({ ...prev, country }))
                }}
                value={folder.country || ''}
              >
                <MenuItem value={'DEU'}>Deutschland</MenuItem>
                <MenuItem value={'AUS'}>Österreich</MenuItem>
                <MenuItem value={'CHE'}>Schweiz</MenuItem>
              </KSelect>

              <KHeadline>Geographische Koordinaten</KHeadline>

              <Stack direction={'row'} justifyContent={'space-evenly'}>
                <KTextField
                  icon={<EditLocationTwoTone />}
                  label={'Breitengrad'}
                  onChange={event => {
                    const latitude = event.target.value

                    setFolder(prev => ({ ...prev, latitude }))
                  }}
                  type={'number'}
                  unit={'°'}
                  value={folder.latitude || ''}
                />

                <KTextField
                  icon={<EditLocationTwoTone />}
                  label={'Längengrad'}
                  onChange={event => {
                    const longitude = event.target.value

                    setFolder(prev => ({ ...prev, longitude }))
                  }}
                  type={'number'}
                  unit={'°'}
                  value={folder.longitude || ''}
                />
              </Stack>

              <KHeadline>Mitarbeiter</KHeadline>

              <KTextField
                label={'Anzahl'}
                onChange={event => {
                  const number_of_employees = event.target.value

                  setFolder(prev => ({ ...prev, number_of_employees }))
                }}
                type={'number'}
                unit={'Personen'}
                value={folder.number_of_employees || ''}
              />

              <KSelect
                label={'Mitarbeiter / Zimmer'}
                onChange={event => {
                  setFolder(prev => ({
                    ...prev,
                    rooms_mode: event.target.value,
                    roomsModeSelect: event.target.value,
                  }))
                }}
                value={folder.rooms_mode || ''}
              >
                <MenuItem value={'employees'}>Mitarbeiter</MenuItem>
                <MenuItem value={'rooms'}>Zimmer</MenuItem>
              </KSelect>

              <KHeadline>Flächendaten</KHeadline>

              <KTextField
                label={'Fläche'}
                onChange={event => {
                  const area_in_sqm = event.target.value

                  setFolder(prev => ({ ...prev, area_in_sqm }))
                }}
                type={'number'}
                unit={'m²'}
                value={folder.area_in_sqm || ''}
              />

              <KTextField
                label={'Allgemeine Nutzungsfläche'}
                onChange={event => {
                  const usable_area = event.target.value

                  setFolder(prev => ({ ...prev, usable_area }))
                }}
                type={'number'}
                unit={'m²'}
                value={folder.usable_area || ''}
              />

              <KTextField
                label={'Vermietete Nutzungsfläche'}
                onChange={event => {
                  const hired_out_area = event.target.value

                  setFolder(prev => ({ ...prev, hired_out_area }))
                }}
                type={'number'}
                unit={'m²'}
                value={folder.hired_out_area || ''}
              />

              <KTextField
                label={'Unklimatisiert'}
                onChange={event => {
                  const none_temperature_controlled_area = event.target.value

                  setFolder(prev => ({
                    ...prev,
                    none_temperature_controlled_area,
                  }))
                }}
                type={'number'}
                unit={'m²'}
                value={folder.none_temperature_controlled_area || ''}
              />

              <KTextField
                label={'Klimatisiert'}
                onChange={event => {
                  const temperature_controlled_area = event.target.value

                  setFolder(prev => ({ ...prev, temperature_controlled_area }))
                }}
                type={'number'}
                unit={'m²'}
                value={folder.temperature_controlled_area || ''}
              />

              <KHeadline>
                Anlagen{' '}
                <IconButton
                  onClick={() =>
                    setInstallations([
                      ...installations,
                      { name: '', entity_id: 0 },
                    ])
                  }
                >
                  <AddTwoTone />
                </IconButton>
              </KHeadline>

              {installations.map((item, key) => {
                const { delete_installation } = item

                if (delete_installation === undefined)
                  return (
                    <Stack direction={'row'} key={key}>
                      <KTextField
                        label={'Name'}
                        onChange={event => {
                          const newInstallation = installations.slice()
                          newInstallation[key]['name'] = event.target.value

                          setInstallations(newInstallation)
                        }}
                        value={item.name}
                      />

                      <IconButton onClick={() => deleteInstallation(item, key)}>
                        <Tooltip placement={'bottom-start'} title={'Löschen'}>
                          <CancelTwoTone />
                        </Tooltip>
                      </IconButton>
                    </Stack>
                  )

                return true
              })}

              <KTextField
                label={'Nutzungsart'}
                onChange={event => {
                  const kind_of_use = event.target.value

                  setFolder(prev => ({ ...prev, kind_of_use }))
                }}
                value={folder.kind_of_use || ''}
              />

              <KSelect
                label={'Klimatisierung'}
                onChange={event => {
                  const air_conditioning = event.target.value

                  setFolder(prev => ({ ...prev, air_conditioning }))
                }}
                value={folder.air_conditioning || ''}
              >
                <MenuItem value={'temperature_controlled'}>
                  Klimatisiert
                </MenuItem>
                <MenuItem value={'not_temperature_controlled'}>
                  Unklimatisiert
                </MenuItem>
              </KSelect>

              {/*           

              <KHeadline>Sollwert Jahresverbrauch</KHeadline>

              <KTextField
                label={'Strom'}
                onChange={e => {
                  const target_value_annual_electricity_consumption =
                    event.target.value

                  setFolder(prev => ({
                    ...prev,
                    target_value_annual_electricity_consumption,
                  }))
                }}
                type={'number'}
                unit={'kWh'}
                value={folder.target_value_annual_electricity_consumption || ''}
              />

              <KTextField
                label={'Gas'}
                onChange={e => {
                  const target_value_annual_gas_consumption = event.target.value

                  setFolder(prev => ({
                    ...prev,
                    target_value_annual_gas_consumption,
                  }))
                }}
                type={'number'}
                unit={'kWh'}
                value={folder.target_value_annual_gas_consumption || ''}
              /> */}

              <KHeadline>Vertragslaufzeit</KHeadline>

              <Stack direction={'row'}>
                <KTextField
                  label={'Beginn'}
                  onChange={event => {
                    const date_begin = event.target.value

                    setContractData(prev => ({ ...prev, date_begin }))
                  }}
                  type={'date'}
                  value={contractData.date_begin || ''}
                />

                <KTextField
                  label={'Ablauf'}
                  onChange={event => {
                    const date_expire = event.target.value

                    setContractData(prev => ({ ...prev, date_expire }))
                  }}
                  type={'date'}
                  value={contractData.date_expire || ''}
                />
              </Stack>

              <KTextField
                disabled
                error={status === 'ungültig'}
                label={'Laufzeit'}
                unit={'Tage'}
                value={String(duration)}
              />

              <Alert severity={getSeverity(status)}>
                Vertragsstatus: {status}
              </Alert>
            </>
          )}
        </Collapse>

        {/* Sensoren */}
        <Collapse in={tab === 1}>
          {isSensorPoolLoading ? (
            <LinearProgress />
          ) : (
            <>
              <KHeadline>
                {`${
                  [...new Set(folderSensors.map(i => i.attributes.device_id))]
                    .length
                } Geräte | ${folderSensors.length} Sensoren`}{' '}
                <IconButton onClick={() => setModal(true)}>
                  <AddTwoTone />
                </IconButton>
              </KHeadline>

              <Grid container spacing={5}>
                {[...new Set(folderSensors.map(i => i.attributes.name))]
                  .sort(SORT)
                  .map((item, key) => {
                    const sensorTypes = [
                      ...new Set(
                        folderSensors.filter(f => f.attributes.name === item)
                      ),
                    ].sort()

                    let provider = folderSensors.find(
                      f => f.attributes.name === item
                    )
                    provider = provider.attributes.provider || ''

                    let device_id = folderSensors.find(
                      f => f.attributes.name === item
                    )
                    device_id = device_id.attributes.device_id || ''

                    let sensor_type = folderSensors.find(
                      f => f.attributes.name === item
                    )
                    sensor_type = sensor_type.attributes.sensor_type || ''

                    let type_in_folder = folderSensors.find(
                      f => f.attributes.name === item
                    )
                    type_in_folder =
                      type_in_folder.attributes.type_in_folder || ''

                    return (
                      <Grid item key={key} xs={12} sm={6} lg={4}>
                        <Card sx={{ backgroundColor: 'lightgray' }}>
                          <CardHeader
                            avatar={
                              <Avatar>
                                <Box
                                  alt={''}
                                  component={'img'}
                                  src={SensorIcon(
                                    categoryByType(sensor_type, type_in_folder)
                                  )}
                                  sx={{ width: 40 }}
                                  title={'Sicherheit'}
                                />
                              </Avatar>
                            }
                            action={
                              <IconButton>
                                <MoreVertTwoTone />
                              </IconButton>
                            }
                            subheader={`${provider} | ${device_id}`}
                            title={item}
                          />

                          <CardContent>
                            <List>
                              {sensorTypes.map((type, key) => {
                                const {
                                  is_active,
                                  entity_id,
                                  sensor_type,
                                  status,
                                  type_in_folder,
                                } = type.attributes

                                const icon = SensorIcon(
                                  normalizedSensorType(
                                    sensor_type,
                                    type_in_folder
                                  ),
                                  status
                                )

                                return (
                                  <ListItem key={key}>
                                    <ListItemAvatar>
                                      <IconButton
                                        onClick={() =>
                                          history.push(`/sensors/${entity_id}`)
                                        }
                                      >
                                        <Box
                                          alt={''}
                                          component={'img'}
                                          src={icon}
                                          width={30}
                                        />
                                      </IconButton>
                                    </ListItemAvatar>

                                    <ListItemText
                                      primary={`${sensorType(
                                        normalizedSensorType(
                                          sensor_type,
                                          type_in_folder
                                        )
                                      )}`}
                                      secondary={`Sensor ID: ${entity_id} | ${
                                        ['deaktiviert', 'aktiviert'][is_active]
                                      }`}
                                    />

                                    <ListItemSecondaryAction>
                                      <IconButton
                                        edge={'end'}
                                        onClick={() => {
                                          setFolderSensors(
                                            folderSensors.filter(
                                              f =>
                                                Number(f.id) !==
                                                Number(entity_id)
                                            )
                                          )
                                        }}
                                      >
                                        <DeleteTwoTone />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              })}
                            </List>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                  })}
              </Grid>
            </>
          )}
        </Collapse>

        {/* Benutzer */}
        <Collapse in={tab === 2}>
          {isUserPoolLoading ? (
            <LinearProgress />
          ) : (
            <>
              <KHeadline>{`${folderUsers.length} Benutzer`}</KHeadline>

              <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 5 }}>
                {folderUsers.sort(SORT_ATTR_FIRSTNAME).map((user, key) => {
                  const {
                    firstname,
                    image_url,
                    label,
                    lastname,
                  } = user.attributes

                  return (
                    <Chip
                      avatar={
                        image_url ? (
                          <Avatar src={image_url} />
                        ) : (
                          <Avatar>
                            {getInitials(`${firstname} ${lastname}`)}
                          </Avatar>
                        )
                      }
                      color={roleFilter === label ? 'secondary' : 'primary'}
                      key={key}
                      label={`${firstname} ${lastname}`}
                      onDelete={() =>
                        setFolderUsers(fu => fu.filter(f => f.id !== user.id))
                      }
                      onMouseDown={event => event.stopPropagation()}
                      sx={{ m: 1 }}
                    />
                  )
                })}
              </Box>

              <FormControl
                fullWidth
                sx={{ alignItems: 'center', justifyContent: 'center', mb: 5 }}
                variant={'outlined'}
              >
                <ToggleButtonGroup
                  onChange={(event, value) => setRoleFilter(value[0])}
                >
                  <ToggleButton selected={roleFilter === ''} value={''}>
                    Alle
                  </ToggleButton>
                  <ToggleButton
                    selected={roleFilter === UC.USER_ROLE_ADMIN}
                    value={UC.USER_ROLE_ADMIN}
                  >
                    {UC.USER_ROLE_ADMIN}
                  </ToggleButton>
                  <ToggleButton
                    selected={roleFilter === UC.USER_ROLE_COACH}
                    value={UC.USER_ROLE_COACH}
                  >
                    {UC.USER_ROLE_COACH}
                  </ToggleButton>
                  <ToggleButton
                    selected={roleFilter === UC.USER_ROLE_USER}
                    value={UC.USER_ROLE_USER}
                  >
                    {UC.USER_ROLE_USER}
                  </ToggleButton>
                  <ToggleButton
                    selected={roleFilter === UC.USER_ROLE_GUEST}
                    value={UC.USER_ROLE_GUEST}
                  >
                    {UC.USER_ROLE_GUEST}
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>

              <KTextField
                label={'Benutzer oder Email suchen'}
                onChange={event => setDisplayFilter(event.target.value)}
                value={displayFilter}
              />

              <FormControl sx={{ mb: 2 }} fullWidth variant={'outlined'}>
                <List height={420} sx={{ maxHeight: 420, overflow: 'auto' }}>
                  {userPool
                    .filter(
                      f => (roleFilter ? f.attributes.label === roleFilter : f)
                    )
                    .filter(
                      f =>
                        displayFilter
                          ? String(
                              `${f.attributes.firstname} ${
                                f.attributes.lastname
                              }`
                            )
                              .toLowerCase()
                              .includes(displayFilter.toLowerCase()) ||
                            String(f.attributes.email)
                              .toLowerCase()
                              .includes(displayFilter.toLowerCase())
                          : f
                    )
                    .filter(FILTER_ACTIVE_USER)
                    .map((user, key) => {
                      const {
                        email,
                        firstname,
                        image_url,
                        label,
                        lastname,
                      } = user.attributes

                      return (
                        <ListItem
                          button
                          key={key}
                          onClick={() => {
                            if (folderUsers.map(u => u.id).includes(user.id))
                              setFolderUsers(fu =>
                                fu.filter(f => f.id !== user.id)
                              )
                            else setFolderUsers([...folderUsers, user])
                          }}
                        >
                          <ListItemAvatar>
                            {image_url ? (
                              <Avatar alt={''} src={image_url} />
                            ) : (
                              <Avatar alt={''}>
                                {getInitials(firstname + ' ' + lastname)}
                              </Avatar>
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${firstname} ${lastname}`}
                            secondary={`ID: ${user.id} | ${label} | ${email}`}
                          />
                          <ListItemSecondaryAction>
                            <Checkbox
                              checked={folderUsers
                                .map(u => u.id)
                                .includes(user.id)}
                              edge={'end'}
                              onChange={() => {
                                if (
                                  folderUsers.map(u => u.id).includes(user.id)
                                )
                                  setFolderUsers(fu =>
                                    fu.filter(f => f.id !== user.id)
                                  )
                                else setFolderUsers([...folderUsers, user])
                              }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })}
                </List>
              </FormControl>
            </>
          )}
        </Collapse>

        {/* Öffnungszeiten */}
        <Collapse in={tab === 3}>
          {oneFolder.loading ? (
            <LinearProgress />
          ) : (
            <>
              <KHeadline>Öffnungszeiten</KHeadline>
              {[
                'Montag',
                'Dienstag',
                'Mittwoch',
                'Donnerstag',
                'Freitag',
                'Samstag',
                'Sonntag',
              ].map((item, key) => {
                const record =
                  officeHours && officeHours[key]
                    ? officeHours[key]
                    : {
                        closed: 1,
                        day_of_week: key + 1,
                        end_time: '00:00:00',
                        start_time: '00:00:00',
                      }

                const IS_OPEN = record.closed === 0
                const IS_CLOSED = record.closed === 1

                return (
                  <Stack alignItems={'center'} direction={'row'} key={key}>
                    <Typography sx={{ width: 0.25 }}>{item}</Typography>
                    <KTextField
                      disabled={IS_CLOSED}
                      label={'von'}
                      onChange={event => {
                        record.start_time = event.target.value
                        changeOfficeHours(record, key)
                      }}
                      type={'time'}
                      value={record.start_time}
                    />
                    <KTextField
                      disabled={IS_CLOSED}
                      label={'bis'}
                      onChange={event => {
                        record.end_time = event.target.value
                        changeOfficeHours(record, key)
                      }}
                      type={'time'}
                      value={record.end_time}
                    />
                    <Switch
                      checked={IS_OPEN}
                      onChange={() => {
                        record.closed = IS_CLOSED ? 0 : 1
                        changeOfficeHours(record, key)
                      }}
                    />
                  </Stack>
                )
              })}
            </>
          )}
        </Collapse>
        <Collapse in={tab === 4}>
          {oneFolder.loading ? (
            <LinearProgress />
          ) : (
            <>
              {/* Benchmarks */}
              <KHeadline>Benchmarks</KHeadline>
              <Grid container justifyContent={'center'}>
                <Grid item xs={12} sm={4}>
                  <KTextField
                    label={`Benchmark Strom`}
                    onChange={event => {
                      const energy_benchmark = event.target.value

                      setFolder(prev => ({ ...prev, energy_benchmark }))
                    }}
                    type={'number'}
                    value={folder.energy_benchmark || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <KTextField
                    label={`Benchmark Wasser`}
                    onChange={event => {
                      const water_benchmark = event.target.value

                      setFolder(prev => ({ ...prev, water_benchmark }))
                    }}
                    type={'number'}
                    value={folder.water_benchmark || ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <KTextField
                    label={`Benchmark Gas`}
                    onChange={event => {
                      const gas_benchmark = event.target.value

                      setFolder(prev => ({ ...prev, gas_benchmark }))
                    }}
                    type={'number'}
                    value={folder.gas_benchmark || ''}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Collapse>
      </KPage>
    </>
  )
}
